import { Color3, StandardMaterial } from "@babylonjs/core"
import { API_BASE_URL, DEFAULT_GLTF_MATERIAL, DEFAULT_MATERIAL_PROPERTIES } from "../../../constants"
import { createMaterialManifest, matTextureToOutput } from "../../../utils/ui.util"

const InitHelperMethods = {
  
  // This method breaks apart a model location into its respective path and file
  getPathAndFileForModel: (location) => {
    const pathArr = location.split('/')
    const filename = pathArr[pathArr.length-1]
    pathArr.splice(pathArr.length-1, 1)
    const path = pathArr.join("/") + "/"
    return {path, filename}
  },

  // This method loops through the provided parts and returns a list of materials used in the scene with their associated part
  handleSampleParts: (parts, defaultMaterials) => {
    console.log('handleSampleParts')

    return new Promise(async resolve => {
      // We'll check to see which parts have valid materials on them to import
      const sampled = {}
      const sampledArr = []
      for (let i = 0; i < parts.length; i++) {
        const p = parts[i]
        if (p.material && !sampled[p.material.id] && p.material.id.indexOf(DEFAULT_GLTF_MATERIAL) < 0 && !p.material.external && defaultMaterials[p.id]) {
          let thumb
          const raw = defaultMaterials[p.id]
          try {
            thumb = await matTextureToOutput('dataUrl', '', 'jpg', raw.albedoTexture, raw.albedoColor)
          }
          catch (e) {
            console.error("Error generating thumbnail: ", e)
          }
          
          sampled[p.material.id] = {material: p.material, part: p.name, thumb, raw}
        }
      }

      if (Object.keys(sampled).length > 0) {
        Object.keys(sampled).forEach((key) => {
          sampledArr.push(sampled[key])
        })
      }

      resolve(sampledArr)
    })
  },

  saveDefaultMaterialsToCollection: (token, model_id, items) => {
    return new Promise(async (resolve, reject) => {
      let files = []
      const materials = []

      try {
        
        for (let i = 0; i < items.length; i++) {
          const materialRes = await createMaterialManifest(items[i].raw, false)
          materials.push(materialRes.manifest)
          files = [...files, ...materialRes.files]
        }

        console.log("Save materials from model...")
        const formData = new FormData()
        files.forEach((f) => formData.append(f.name, f))
        formData.append('materials', JSON.stringify(materials))
        formData.append('model_id', model_id)

        // Add
        const createdMaterialsRes = await fetch(`${API_BASE_URL}collections`, {
          method: "POST",
          body: formData,
          headers: {
            authorization: `Bearer ${token}`
          }
        }).then((r) => r.json())

        if (createdMaterialsRes?.data === "INSUFFICIENT") {
          console.error("Failed to create materials: ", createdMaterialsRes)
          reject(createdMaterialsRes)
          return
        }

        // Update model
        await fetch(`${API_BASE_URL}models/${model_id}`, {
          method: "PATCH",
          body: JSON.stringify({
            status: "ready"
          }),
          headers: {
            'Content-Type': "application/json",
            Authorization: `Bearer ${token}`
          }
        })

        // Done
        resolve(createdMaterialsRes.data)
      }
      catch (e) {
        console.error("Error saving default materials: ", e)
        reject(e)
      }
    })
  },

  // This method extracts parts and materials found in a scene
  getPartsAndMaterialsInScene: (scene, createHighlights) => {
    const defaultMaterials = {}
    const children = scene.meshes.filter((m) => m.name !== "__root__")
    const parts = []
    let hlMaterial
    let highlights = []

    if (createHighlights) {
      hlMaterial = new StandardMaterial('_hl_mat', scene)
      hlMaterial.diffuseColor = new Color3(0.7, 0.2, 0.9)
    }

    children.forEach((m) => {

      // Add material
      defaultMaterials[m.id] = m.material
      
      // Push part
      let partMat = false
      if (m.material) {
        partMat = {
          name: m.material.name, 
          id: m.material.id, 
          external: false, 
          alpha: m.material.alpha ? m.material.alpha : (DEFAULT_MATERIAL_PROPERTIES.alpha/100),
          metallic: m.material.metallic ? m.material.metallic : (DEFAULT_MATERIAL_PROPERTIES.metallic/100),
          roughness: m.material.roughness ? m.material.roughness : (DEFAULT_MATERIAL_PROPERTIES.roughness/100),
          normalIntensity: m.material.normalIntensity ? m.material.normalIntensity : (DEFAULT_MATERIAL_PROPERTIES.normalIntensity/100),
          directIntensity: m.material.directIntensity ? m.material.directIntensity : (DEFAULT_MATERIAL_PROPERTIES.directIntensity/100)
        }
      }

      parts.push({type: 'part', id: m.id, name: m.name, material: partMat})

      if (createHighlights) {
        const clone = m.clone("_hl_" + m.id)
        clone.id = "_hl_" + m.id
        clone.skeleton = null
        clone.material = hlMaterial
        clone.visibility = 0
        highlights.push(clone)
      }
    })

    return {parts, defaultMaterials, highlights}
  },

  handleAutogroupParts: (parts, metaLocation) => {
    return new Promise(async (resolve) => {

      // Group / name parts if we have the appropriate meta info
      let meta = false
      if (metaLocation) {
        try {
          meta = await fetch(metaLocation).then((r) => r.json())
        }
        catch (e) {
          console.log("meta.json not available...")
          meta = false
        }
      }

      let didUpdate = false
      let updatedParts = []
      const groups = {}

      if (meta && Object.keys(meta).length > 0) {
        updatedParts = []

        // First, we'll compile top level parent
        const rootKey = Object.keys(meta).find((key) => meta[key].parents && meta[key].parents.length > 0)
        if (!rootKey) {
          return resolve({didUpdate})
        }

        const rootParent = meta[rootKey].parents[0]

        Object.keys(meta).forEach((key) => {
          const item = meta[key]

          // Update name of part
          const existingPart = parts.find((p) => p.id.toString() === meta[key].uid.toString())
          if (existingPart) {
            // Set name
            const thePart = JSON.parse(JSON.stringify(existingPart))
            thePart.name = item.name

            // If it's not a group, and we have a parent, let's place it with the parent
            if (thePart.type !== 'group') {
              if (item.parents && item.parents.length > 0 && item.parents.indexOf(rootParent) > -1) {

                // If we have one parent, we'll add to root
                if (item.parents.length === 1) {
                  updatedParts.push(thePart)
                }
                else {
                  // If we have more than one parent, we'll add the last parent as a group
                  const lastParentId = item.parents[item.parents.length - 1]

                  if (groups[lastParentId]) {
                    groups[lastParentId].parts.push(thePart)
                  }
                  else {
                    const name = meta[lastParentId].name
                    const newGroup = {id: lastParentId, name, type: 'group', parts: [thePart]}
                    groups[lastParentId] = newGroup
                  }
                }
              }
            }
            else {
              // Push to arr
              updatedParts.push(thePart)
            }
          }
          else {
            // console.warn("Existing part not found: ", key)
            // Check if group exists
            if (!groups[item.uid]) {
              // Let's make a new group
              const newGroup = {id: item.uid, name: item.name, type: 'group', parts: []}
              groups[item.uid] = newGroup
            }
          }
        })

        // Now push groups that have items
        Object.keys(groups).forEach((gk) => {
          if (groups[gk].parts && groups[gk].parts.length > 0) {
            updatedParts.push(groups[gk])
          }
        })

        if (updatedParts.length > 0) {
          didUpdate = true
        }
      }
      
      resolve({didUpdate, updatedParts})
    })
  }
}

export default InitHelperMethods