import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { threeVerticalDots } from "../../assets"
import { EVENTS } from "../../constants"
import { useEditProjectMutation } from "../../redux/api.redux.slice"
import { setProjectSaving, setToast } from "../../redux/ui.redux.slice"
import ContextMenuComponent from "../context-menu/context-menu.component"
import EnterKeyInputComponent from "../enter-key-input/enter-key-input.component"
import "../material-viewer/material-viewer.component.css"

const VariationsComponent = (props) => {
  const { projectId, variations, selectedVariationId, onSelectVariation, onUpdateVariations, disableEditing } = props
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [renamingItem, setRenamingItem] = useState(false)
  const [workingItems, setWorkingItems] = useState([])
  const [updateProjectRequest] = useEditProjectMutation()

  // Set working items on load
  useEffect(() => {
    setWorkingItems([...variations])
  }, [variations])

  const handleClick = (e, vid) => {

    switch (e.detail) {
      case 1:
        if (selectedVariationId !== vid) {
          onSelectVariation(vid)
        }

        break
      case 2:
        if (!disableEditing) {
          handleStartRenameItem(vid)
        }
        
        break
      default: break
    }
  }

  const handleStartRenameItem= (vid) => {
    setRenamingItem(vid)
    setShowMenu(false)
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: false}}))
  }

  const handleFinalizeItemNameChange = async (vid, value) => {
    setRenamingItem(false)
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: true}}))
    const anItem = workingItems.find((i) => i.id === vid)
    if (value === anItem.name) {
      return
    }

    setLoading(true)
    dispatch(setProjectSaving({saving: true, caller: "variations"}))

    // Update value in api
    try {
      
      const updated = [...workingItems].map((i) => {
        if (i.id === vid) {
          return {...i, name: value}
        }

        return i
      })

      await updateProjectRequest({projectId, body: {variations: JSON.stringify(updated)}}).unwrap()
      setWorkingItems(updated)

      // Notify project handler 
      onUpdateVariations(updated)
      setLoading(false)
      dispatch(setProjectSaving({saving: false, caller: "variations"}))
    }
    catch (e) {
      console.error("Error updating variation name: ", e)
      dispatch(setToast({message: "Uh oh. There was an error updating your variation. Please try again.", isError: true}))
      dispatch(setProjectSaving({saving: false, caller: "variations"}))
    }
  }

  const handleDeleteVariation = async (vid) => {
    setLoading(true)
    dispatch(setProjectSaving({saving: true, caller: "variations"}))

    try {
      // Delete variation
      const updated = [...workingItems].filter((i) => i.id !== vid)
      await updateProjectRequest({projectId, body: {variations: JSON.stringify(updated)}}).unwrap()
      setWorkingItems(updated)

      // Notify project handler
      onUpdateVariations(updated)
      setLoading(false)
      dispatch(setProjectSaving({saving: false, caller: "variations"}))
    }
    catch (e) {
      setLoading(false)
      dispatch(setToast({message: "Uh oh. There was an error deleting your variation. Please try again.", isError: true}))
      dispatch(setProjectSaving({saving: false, caller: "variations"}))
    }
  }

  const handleShowMenu = (e, vid) => {
    e.stopPropagation()

    setShowMenu(
      {position: {
        left: e.clientX + 'px', top: e.clientY + 'px'
      },
      items: [
        {id: 'rename', label: <><span className="icon-edit-2"></span> Rename</>, action: () => handleStartRenameItem(vid)},
        {id: 'delete', className: "delete-btn", label: <><span className="icon-trash"></span> Delete</>, action: () => handleDeleteVariation(vid)}
      ]
    })
  }

  return (
    <div className={loading ? "part-viewer-container threedy-skeleton" : "part-viewer-container" }>
      {/* Menu */}
      { showMenu && <ContextMenuComponent items={showMenu.items} position={showMenu.position} onClose={() => setShowMenu(false)} /> }

      {/* Variations List */}
      <ul>
        { workingItems && workingItems.length > 0 && workingItems.map((v) => {
          return (
            <li key={v.id} className={selectedVariationId === v.id ? "variation-item selected" : "variation-item"} onClick={(e) => handleClick(e, v.id)}>
              {renamingItem === v.id ? (
                <EnterKeyInputComponent value={v.name} placeholder="Material..." onValueChange={(val) => handleFinalizeItemNameChange(v.id, val)} />
              ) : (
                <div className="name-label extended part-view-element-wrapper">
                  {v.name} {v.isAritizeVariation && <i><img src="/images/aritize-logo.svg" alt="ARitize3D" /> {v.aritizeParams && v.aritizeParams.option_id ? v.aritizeParams.option_id : v.aritizeSku}</i>}
                </div>
              )}
              <div className="button-group">
                {!disableEditing && <button onClick={(e) => handleShowMenu(e, v.id)}><span>{threeVerticalDots}</span></button>}
              </div>
            </li>
          )
        })}
      </ul>

      {/* No variations */}
      { (!workingItems || workingItems.length < 1) && (
        <div className="no-materials">
          <h5>No variations yet...</h5>
        </div>
      )}
    </div>
  )
}

export default VariationsComponent