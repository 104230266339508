import { useGoogleLogin } from "@react-oauth/google"
import "./google-login-button.component.css"
import { useCreateSocialUserMutation, useLoginSocialUserMutation } from "../../redux/api.redux.slice"
import { T } from "../../constants"


const GoogleLoginButtonComponent = (props) => {
    const { btnClass, buttonOnly, buttonText, afterGoogleLogin, setError, mode } = props
    const [createSocialUser] = useCreateSocialUserMutation()
    const [loginSocialUser] = useLoginSocialUserMutation()

    const loginGoogle = useGoogleLogin({
        ux_mode: 'popup',
        onSuccess: async ({ access_token }) => {
            switch (mode) {
                case T.LOGIN:
                    try {
                        const loginUserRes = await loginSocialUser({ token: access_token, provider: T.GOOGLE }).unwrap()
                        const { tokens } = loginUserRes.login
                        afterGoogleLogin(tokens)
                    } catch (e) {
                        if (e.status === 400) {
                            return setError(e.data?.data)
                        }
                        return setError("Uh oh. We had an issue log in your account. Please try again.")
                    }
                    break
                case T.SIGNUP:
                    try {
                        const newUserRes = await createSocialUser({ token: access_token, provider: T.GOOGLE }).unwrap()
                        const { tokens } = newUserRes.data.create
                        afterGoogleLogin(tokens)
                    } catch (e) {
                        if (e.originalStatus === 409 || e.data?.error === '409') { // Email already exists
                            return setError("Uh oh. Looks like this email is already in use. Please login")
                        }
                        if (e.status === 400) {
                            return setError(e.data?.data)
                        }
                        return setError("Uh oh. We had an issue creating your account. Please try again.")
                    }
                    break
                default:
                    break
            }

        },
        onError: (error) => {
            return setError("Uh oh. Looks like there is an error with Google login. Please refresh the page and try again")
        },
        onNonOAuthError: (nonError) => {
            if (nonError && nonError?.type === 'popup_failed_to_open') {
                return setError("Uh oh. Looks like popup's got blocked. Please allow popup for Google to see login popup")
            }
        }
    })

    const button = (
        <button onClick={() => loginGoogle()} className={`toggle-primary-btn rectangular ${btnClass ?? ''}`}>
            <span className="supp-icon-google"></span> {buttonText ?? `Continue with Google`}
        </button>
    )

    return buttonOnly ? button :
        (
            <div className="google-btn-container">{button}</div>
        )
}

export default GoogleLoginButtonComponent