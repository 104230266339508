import { useCallback, useEffect, useRef, useState } from "react"
import MiscHelperMethods from "../../helper-methods/misc.helpers"
import "./element-configuration.component.css"
import { DEFAULT_GLTF_MATERIAL, EVENTS } from "../../../../constants"
import { awaitToJs, getCanvasForTextureImage } from "../../../../utils/ui.util"
import ElementTexturePane from "./panes/element-texture-pane.component"
import ElementColorPane from "./panes/element-color-pane.component"
import { useDispatch } from "react-redux"
import { setToast } from "../../../../redux/ui.redux.slice"
import * as photon from 'aritize-photon-rs'
import { v4 as uuidv4 } from 'uuid'
import ElementDecalsPane from "./panes/element-decals-pane.component"
import ElementSettingsPane from "./panes/element-settings-pane.component"
import ElementMaterialLibrary from "../element-material-library/element-material-library.component"

const ElementConfigurationComponent = (props) => {
  const {elements, materials, apiCache, project, onSaveMaterialSettings, onSetMaterial, onCacheMaterial, onSaveMaterial, onQueueColorTransfer, onDeleteDecal, onDropDecal} = props
  const [appliedMaterial, setAppliedMaterial] = useState()
  const [showMaterialLibrary, setShowMaterialLibrary] = useState()
  const [tab, setTab] = useState("textures")
  const elementRef = useRef()
  const hasPendingColorTransferRef = useRef()
  const dispatch = useDispatch()

  // Clear CT queue dispatcher
  const clearColorTransferQueue = useCallback(() => {
    console.log('clearColorTransferQueue')
    hasPendingColorTransferRef.current = null
    document.dispatchEvent(new CustomEvent(EVENTS.CLEAR_COLOR_TRANSFER_QUEUE))
  }, [])

  // Set applied material on element change
  useEffect(() => {

    if (!elements || elements.length < 1 || !materials || !apiCache) { return }

    const getAppliedMaterial = async () => {
      const element = elements[0]
      elementRef.current = element

      if (element.material && element.material.id !== DEFAULT_GLTF_MATERIAL) {
        const {personal, id} = element.material
        const [err, mat] = await awaitToJs(MiscHelperMethods.getProjectMaterialForPart(materials, id, apiCache))

        if (!err && mat) {
          const {name, map_urls, resolutions, maps, meta} = mat
          setAppliedMaterial({name, id, thumb: MiscHelperMethods.getThumbnailForMaterial(mat), map_urls, resolutions, maps, meta, personal})
        }
        else {
          setAppliedMaterial(null)
        }
      }
      else {
        setAppliedMaterial(null)
      }
    }

    getAppliedMaterial()

    // Check + Reset CT ref
    if (hasPendingColorTransferRef.current && hasPendingColorTransferRef.current !== elements[0].id) {
      clearColorTransferQueue()
    }

  }, [elements, materials, apiCache, clearColorTransferQueue])

  const handleUpdateSettings = (meta) => {
    onSaveMaterialSettings(elementRef.current.id, appliedMaterial.id, meta, false, true)
  }

  const handleColorTransfer = async (color) => {

    const baseMaterial = appliedMaterial

    if (!baseMaterial) {
      console.log("Material to transfer not found")
      dispatch(setToast({message: "Uh oh. We couldn't find the material to apply a color on, please try again.", isError: true}))
      return
    }

    const { textureCanvas, textureContext } = await getCanvasForTextureImage(baseMaterial)
    const textureImage = photon.open_image(textureCanvas, textureContext)
    const resultCanvas = document.createElement('canvas')
    resultCanvas.__skipFilterPatch = true // Disable polyfill
    const resultContext = resultCanvas.getContext('2d')
    resultCanvas.width = textureCanvas.width
    resultCanvas.height = textureCanvas.height
    const {r, g, b} = color

    // Do color transfer
    const photonResult = photon.color_transfer_color2im(textureImage, r, g, b, false)

    // Save
    const { data, width, height } = photonResult.get_image_data()
    resultContext.putImageData(new ImageData(data, width, height), 0, 0)
    resultCanvas.toBlob(async (blob) => {
      const result = URL.createObjectURL(blob)
      try {

        // Extend base material with new color map
        const copyId = uuidv4()
        const copyName = `${baseMaterial.name} (${r}${g}${b})`
        const originalId = baseMaterial.id
        const copy = JSON.parse(JSON.stringify(baseMaterial))
        copy.id = copyId
        copy.name = copyName
        const targetRes = Object.keys(baseMaterial.map_urls)[0]
        copy.map_urls[targetRes].color[targetRes] = result
        copy.thumbnail = result
        
        // Set meta
        const original_material_id = baseMaterial.meta && baseMaterial.meta.original_material_id ? baseMaterial.meta.original_material_id : originalId
        const original_material_category = baseMaterial.meta && baseMaterial.meta.original_material_category ? baseMaterial.meta.original_material_category : baseMaterial.category
        const isPersonal = baseMaterial.meta && baseMaterial.meta.personal !== undefined ? baseMaterial.meta.personal : (baseMaterial.personal ? true : false) 
        const userVisible = isPersonal ? baseMaterial.user_visible : true
        const metaParams = {color_rgb: color, original_material_id, personal: isPersonal, original_material_user_visible: userVisible, original_material_category}
        copy.meta = metaParams
        copy.personal = true
        copy.external = true

        // Preview + cache
        onCacheMaterial(copy)
        onSetMaterial(elementRef.current, copy, false, undefined, true)
        onSaveMaterial(copy, false, false, false, false, true, false, true)

        // Queue to save
        const newMaterial = {material: copy, colorMap: blob, originalId, color}

        // Add to queue
        onQueueColorTransfer({materialId: copyId, material: newMaterial, partId: elementRef.current.id})
        hasPendingColorTransferRef.current = elementRef.current.id
      }
      catch (e) {
        console.error("Error creating material: ", e)
        dispatch(setToast({message: "Uh oh. We had an error transferring color to the selected material. Please try again", isError: true}))
      }
    })
  } 

  const handleSetMaterial = (mat, shouldCache) => {
    if (shouldCache) {
      onCacheMaterial(mat)
    }

    // Preview + save
    onSetMaterial(elementRef.current, mat, false, undefined, true)
    onSaveMaterial(mat)
  }

  return (
    <>
      <div className="toggle-element-configuration">
        <ul className="toggle-element-configuration-menu">
          <li className={tab === "textures" ? "selected" : ""} onClick={() => setTab("textures")}><h6>Textures</h6></li>
          <li className={tab === "color" ? "selected" : ""} onClick={() => setTab("color")}><h6>Color</h6></li>
          {elementRef.current && elementRef.current.type !== "group" && <li className={tab === "decals" ? "selected" : ""} onClick={() => setTab("decals")}><h6>Decals</h6></li>}
        </ul>
        {tab === "textures" && <ElementTexturePane appliedMaterial={appliedMaterial} isShowingMaterialLibrary={showMaterialLibrary} onShowMaterialLibrary={setShowMaterialLibrary} />}
        {tab === "color" && <ElementColorPane appliedMaterial={appliedMaterial} onColorTransfer={handleColorTransfer} onClearQueue={clearColorTransferQueue} />}
        {tab === "decals" && <ElementDecalsPane element={elementRef.current} project={project} onDeleteDecal={onDeleteDecal} onDropDecal={onDropDecal} />}
        <ElementSettingsPane tab={tab} element={elementRef.current} appliedMaterial={appliedMaterial} projectMeta={project.meta} onUpdateSettings={handleUpdateSettings} />
      </div>
      {showMaterialLibrary && <ElementMaterialLibrary appliedMaterial={appliedMaterial} onClose={() => setShowMaterialLibrary(false)} apiCache={apiCache} onSetMaterial={handleSetMaterial} /> }
    </>
  )
}

export default ElementConfigurationComponent