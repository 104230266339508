import ModalComponent from "../modal/modal.component"
import "./invalid-zip-file-format-modal.component.css"

const InvalidZipFileFormatModalComponent = (props) => {
  const { zipValidationResult } = props
  const { errors, validMasterFiles, invalidMasterFiles, invalidOtherFiles, validOtherFiles } = zipValidationResult
  const modalContent = (
    <>
      <h5 className="mb-5">Following are the errors found with the uploaded zip.</h5>
      <div className="error-messages">
        <ul>
          {errors.length > 0 && errors.map((x, index) => {
            return <li key={index}><span className="icon-chevron-right"></span>&nbsp;{x}</li>
          })}
        </ul>
      </div>
      <hr className="divider" />
      <h5 className="mb-5">Following are the list of files found in the uploaded zip.</h5>
      <div className="files-list">
        <div>
            {validMasterFiles.length > 0 && validMasterFiles.map((x, index) => {
              return <div key={index} className="valid"><span className="icon-check"></span>&nbsp;{x}</div>
            })}
            {invalidMasterFiles.length > 0 && invalidMasterFiles.map((x, index) => {
              return <div key={index} className="invalid"><span className="icon-x"></span>&nbsp;{x}</div>
            })}
        </div>
        <div>
            {invalidOtherFiles.length > 0 && invalidOtherFiles.map((x, index) => {
              return <div key={index} className="invalid"><span className="icon-x"></span>&nbsp;{x}</div>
            })}
            {validOtherFiles.length > 0 && validOtherFiles.map((x, index) => {
              return <div key={index} className="valid"><span className="icon-check"></span>&nbsp;{x}</div>
            })}
        </div>
      </div>
    </>
  )
  const actions = (
    <button className="primary-btn btn-default" onClick={() => props.close()}>Close</button>
  )
  return (
    <ModalComponent title="Invalid ZIP File"
      close={() => props.close()}
      content={modalContent}
      className="medium"
      actions={actions} />
  )
}

export default InvalidZipFileFormatModalComponent