import UpgradeToProModalComponent from "./upgrade-to-pro-modal.component"

const NoLongerAProModalComponent = (props) => {

  const modalContent = (
    <>
      <p class="content-text">You are no longer able to make more materials on a free plan. Would you like to upgrade to pro?</p>
    </>
  )
  return (
    <UpgradeToProModalComponent
      close={() => props.close()}
      modalContent={modalContent}
    />
  )
}

export default NoLongerAProModalComponent