import { useRef, useCallback, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { APP_VIEW_URL, APP_URL } from "../../constants"
import { setShowViewerShare } from "../../redux/ui.redux.slice"

const ViewerShareModalComponent = () => {
  
  const dispatch = useDispatch()
  const selectedProject = useSelector((state) => state.ui.selectedProject)
  const viewRef = useRef()
  const elRef = useRef()

  const handleCopyLink = (e) => {
    const { target } = e
    target.innerText = "Copied!"
    const val = viewRef.current.value
    const defaultText = "Copy Link"

    if (!navigator.clipboard) {
      copyPasteOldWay(target)
    }
    else {
      navigator.clipboard.writeText(val).then(() => {

        setTimeout(() => {
          target.innerText = defaultText
        }, 1000)

      }).catch(() => {
        copyPasteOldWay(target, defaultText)
      })
    }
  }

  const copyPasteOldWay = (target, defaultText) => {
    viewRef.current.select()
    document.execCommand("copy")

    setTimeout(() => {
      target.innerText = defaultText
    }, 1000)
  }

  const handleCloseSharing = useCallback(() => {
    dispatch(setShowViewerShare(false))
  }, [dispatch])

  // Handle click outside
  useEffect(() => {
    const handleClick = (e) => {
      if (elRef.current && !elRef.current.contains(e.target)) {
        handleCloseSharing()
      }
    }

    document.addEventListener('click', handleClick)

    return () => document.removeEventListener('click', handleClick)

  }, [handleCloseSharing])

  const shareLink = window.location.pathname.indexOf("share/") > -1 ? `${APP_URL}${window.location.pathname}${window.location.search}` : `${APP_VIEW_URL}${window.location.pathname}${window.location.search}`

  return (
    <div className="toggle-sharing-modal-component less-margin" ref={elRef} onClick={(e) => e.stopPropagation()}>
      <div className="toggle-sharing-modal-main-header">
        <h3>Share <span className="fw-500">{selectedProject.name}</span></h3>
        <button onClick={handleCloseSharing}><span className="icon-x"></span></button>
      </div>
      <div className="toggle-sharing-modal-content p-20">
        Copy this link to share your 3D experience
        <div className="toggle-sharing-identity mt-20">
          <div className="toggle-sharing-value">
            <input type="text" ref={viewRef} disabled value={shareLink} />
            <button className="outline-btn" onClick={(e) => handleCopyLink(e)}>Copy Link</button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ViewerShareModalComponent