import { API_BASE_URL, APP_URL, AUTH0 } from "../constants"
import moment from "moment"
import jwtDecode from "jwt-decode"

class Session {
  static read = () => {
    const item = window.localStorage.getItem("threedy-lab-store")
    if (item) {
      const json = JSON.parse(item)
      return json
    }

    return false
  }

  static write = (item) => {
    window.localStorage.setItem("threedy-lab-store", JSON.stringify(item))
  }

  static clear = () => {
    window.localStorage.removeItem("threedy-lab-store")
    window.location.href = `${AUTH0.DOMAIN}/v2/logout?client_id=${AUTH0.CLIENT_ID}&returnTo=${APP_URL}`
  }

  static forceLogin = (destination) => {
    const to = destination ? destination : '/dashboard'
    window.location.href = `${APP_URL}/login?returnTo=${encodeURIComponent(to)}`
  }

  static getCookie = (key) => {
    if (!document.cookie) {
      return false
    }

    const b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)")
    return b ? b.pop() : false
  }

  static setCookie = (key, value, numberOfMonths) => {
    const expiration = moment().add(numberOfMonths, "months").toString()
    document.cookie = `${key} = ${value}; expires = ${expiration}`
  }

  static getAccessTokenFromRefreshToken = () => {
    console.log('getaccesstoken...from refresh token')
    return new Promise(async (resolve, reject) => {

      const session = Session.read()
      if (!session || !session.refresh_token) {
        return reject("refresh_token does not exist")
      }

      try {
        const tokenRes = await fetch(`${API_BASE_URL}user/refresh`, {
          method: "POST",
          headers: {
            'content-type': 'application/json'
          },
          body: JSON.stringify({
            token: session.refresh_token,
            redirect: `${APP_URL}/callback`
          })
        }).then((r) => r.json())

        if (tokenRes.data && tokenRes.data.access_token) {
          const { access_token, id_token, expires} = tokenRes.data
          const user = jwtDecode(id_token)
          Session.write({id_token, access_token, refresh_token: session.refresh_token, exp: moment().add(expires, "s").valueOf()})
          resolve({id_token, access_token, user})
        }
        else {
          reject(tokenRes.error ? tokenRes.error : 'invalid/expired refresh token')
        }
      }
      catch (e) {
        reject(e)
      }
    })
  }
}

export default Session