import { Route, Routes, useNavigate } from "react-router-dom"
import WorkspacePage from "./pages/workspace/workspace.page"
import RequireAuth from './components/require-auth/require-auth.component'
import DashboardPage from "./pages/dashboard/dashboard.page"
import { useLocation } from "react-router-dom"
import { useCallback, useEffect, useState } from "react"
import { ALL_TOURS, COOKIE_ACCEPTANCE_KEY, HIDDEN_SIDEBAR_PATHS, JOYRIDE_LOCALE, JOYRIDE_STEPS, JOYRIDE_STYLE, JOYRIDE_TOOLTIP_STYLES, STRIPE_PK, STRIPE_PLANS, T } from "./constants"
import SidebarComponent from "./components/sidebar/sidebar.component"
import ProjectsPage from "./pages/projects/projects.page"
import { useDispatch, useSelector } from "react-redux"
import NewProjectModalComponent from "./components/new-project-modal/new-project-modal.component"
import ProjectSharingModalComponent from "./components/project-sharing-modal/project-sharing-modal.component"
import PublicViewerPage from "./pages/public-viewer/public-viewer.page"
import ProjectThumbModalComponent from "./components/project-thumb-modal/project-thumb-modal.component"
import HelpPage from "./pages/help/help.page"
import SettingsPage from "./pages/settings/settings.page"
import { useEditUserMutation, useLazyGetMaterialsQuery, useLazyGetPersonalCollectionsQuery, useLazyGetSearchUserLibraryQuery, useLazyGetUserInfoQuery } from "./redux/api.redux.slice"
import { setMaterials, setPersonalCollection, setRunTour, setTourStepIndex, setActiveTour, setFinishedTours, setUserSearchLibrary } from "./redux/ui.redux.slice"
import { login, setExternalApplications, setIsVerified, setUserSubscription } from "./redux/auth.redux.slice"
import { loadStripe } from "@stripe/stripe-js";
import Session from "./utils/session.util"
import ToasterComponent from "./components/toaster/toaster.component"
import PlanLimitComponent from "./components/plan-limit/plan-limit.component"
import VerificationModalComponent from "./components/verification-modal/verification-modal.component"
import AdminPage from "./pages/admin/admin.page"
import LoginPage from "./pages/login/login.page"
import Editor from "./components/editor/editor.component"
import CookiePolicyModalComponent from "./components/cookie-policy-modal/cookie-policy-modal.component"
import SignUpPage from "./pages/sign-up/sign-up.page"
import ReactJoyride, { ACTIONS, EVENTS, STATUS } from "react-joyride"
import SampleTourComponent from "./components/sample-tour/sample-tour.component"
import Aritize3dViewerPage from "./pages/aritize3d-viewer/aritize3d-viewer.page"
import NotificationsPage from "./pages/notifications/notifications.page"
import useAutomaticExportDownload from "./hooks/useAutomaticExportDownload"
import ToggleUIPage from "./toggle-ui.page"
import TutorialsPage from "./pages/tutorials/tutorials.page"


import HeaderComponent from "./components/header/header.component"

const Toggle = () => {

  const [showPlanLimit, setShowPlanLimit] = useState(false)
  const [showCookiePolicy, setShowCookiePolicy] = useState(false)
  const { showNewProjectModal, showProjectSharingModal, showProjectThumbnailModal, runTour, tourStepIndex, activeTour } = useSelector((state) => state.ui)
  const { user, isVerified } = useSelector((state) => state.auth)
  const [getMaterials] = useLazyGetMaterialsQuery()
  const [getUserInfo] = useLazyGetUserInfoQuery()
  const [updateUserRequest] = useEditUserMutation()
  const [fetchCollectionRequest] = useLazyGetPersonalCollectionsQuery()
  const [getSearchUserLibrary] = useLazyGetSearchUserLibraryQuery()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [stripePromise, setStripePromise] = useState(false) 
  useAutomaticExportDownload()

  useEffect(() => {
    console.log(">>>> Rendering toggle: " + window.location.hostname + " <<<<")
    setStripePromise(loadStripe(STRIPE_PK))
  }, [])

  // Conditionally show sidebar / settings sidebar
  useEffect(() => {
    setShowPlanLimit(location.pathname.indexOf("editor") < 0)
  }, [location])

  // Get materials & categories once user is authenticated
  useEffect(() => {
    let isMounted = true

    if (!user) {
      return
    }
    
    // Fetch user subscription details on load
    const fetchUserInfo = async () => {
      try {
        const userRes = await getUserInfo().unwrap()
        console.log(userRes)
        if (userRes.stripe_plan_id && STRIPE_PLANS.find((p) => p.id === userRes.stripe_plan_id)) {
          if (isMounted) {
            // If we're in a good state, set to the appropriate plan, otherwise, fall back to free
            const plan = (userRes.stripe_status === 'active' || userRes.stripe_status === 'trialing') ? STRIPE_PLANS.find((p) => p.id === userRes.stripe_plan_id) : STRIPE_PLANS.find((p) => p.type === 'free')
            dispatch(setUserSubscription({ plan, role: userRes.role, status: userRes.stripe_status, quota: userRes.quota, didTrial: userRes.didTrial, used_space_in_mb: userRes.used_space_in_mb }))
          }
        }
        else {
          console.error("Stripe plan not found --- fallback to free");
          
          // Revert to free
          dispatch(setUserSubscription({ plan: STRIPE_PLANS.find((p) => p.type === 'free'), status: 'active', quota: userRes.quota ? userRes.quota : false, didTrial: false, used_space_in_mb: userRes ? userRes.used_space_in_mb : null }))
        }

        // Set verification status
        dispatch(setIsVerified(userRes.isVerified))

        // Set external applications
        dispatch(setExternalApplications(userRes.external_applications))
      }
      catch (e) {
        console.error("Error fetching user info: ", e)

        // Fall back to free
        const plan = STRIPE_PLANS.find((p) => p.type === 'free')
        dispatch(setUserSubscription({ plan, status: 'active', quota: 0, used_space_in_mb: null }))
      }
    }

    // Fetch materials and categories on load
    const getMaterialCategories = async () => {
      try {
        const materialsRes = await getMaterials().unwrap() 

        if (isMounted) {
          dispatch(setMaterials({categories: materialsRes}))
        }
      }
      catch (e) {
        console.log("Error fetching material categories: ", e)
      }
    }

    // Fetch user library for search 
    const getUserSearchLibrary = async () => {
      try {
        const library = await getSearchUserLibrary().unwrap()
        console.log(library)
        dispatch(setUserSearchLibrary(library))
      }
      catch (e) {
        console.log("Error fetching user search library")
      }
    }

    // Fetch personal collection on load
    const getPersonalCollection = async () => {
      try {
        const collection = await fetchCollectionRequest().unwrap()
        dispatch(setPersonalCollection(collection))
      }
      catch (e) {
        console.log("Error fetching personal collection")
      }
    }
    getMaterialCategories()
    getPersonalCollection()
    getUserSearchLibrary()
    fetchUserInfo()

    return () => isMounted = false

  }, [user, dispatch, getUserInfo, getMaterials, fetchCollectionRequest, getSearchUserLibrary])

  // The below effect will show the cookie policy if the user has not seen it yet
  useEffect(() => {
    const cookie = Session.getCookie(COOKIE_ACCEPTANCE_KEY)
    if (!cookie) {
      // Show 
      setShowCookiePolicy(true)
    }
  }, [])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.get('addClasses')) {
      const rootEl = document.querySelector(".toggle-root-container")
      if (rootEl) {
        const classNames = params.get('addClasses').split(",")
        classNames.forEach((c) => {
          rootEl.classList.add(c)
        })
      } 
    }
  }, [])


  const updateFinishedTours = async (tours) => {
    try {
      const md = user['https://thdy/user_md'] ? {...user['https://thdy/user_md']} : {}
      md.finishedTours = tours
      if(ALL_TOURS.every(tour => tours.includes(tour))) {
        md.onboarded = true
      }
      const params = {user_metadata: md}
      await updateUserRequest({body: params}).unwrap()

      // Update session
      Session.getAccessTokenFromRefreshToken().then((res) => {
        const { access_token, user } = res
        dispatch(login({user, access_token}))
      }, (e) => {
        console.log('Error updating session: ', e)
      })
    } 
    catch (e) {
      console.log("Error updating user: ", e)

      // We won't show the user as it is not something they should worry about
    }
  }

  const joyrideCallback = (data) => {
    const { action, index, status, type, lifecycle } = data;
    /*
      Lifecycle on clicking NEXT button:
      1. "step:after" --> currentIndex/Step --> "next"
      2. "step:before" --> nextIndex/Step --> "next"
      3. "tooltip" --> nextIndex/Step --> "update"
      4. REPEAT
    */
    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Navigate to the projects page and resume it in the projects page
      if (activeTour === 'TOUR1' && type === 'step:after' && index === 1 && action === 'next'){
        dispatch(setRunTour(false))
        navigate('/projects')
        return
      }
      // Update state to advance the tour
      dispatch(setTourStepIndex(index + (action === ACTIONS.PREV ? -1 : 1)))
    }
    // If skipped or finished or user clicked somewhere else during tour, then stop the tour
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || (type === 'beacon' && lifecycle === 'beacon')) {
      // Add Tour1 to Finished Tours + Inactivate Tour1
      if (activeTour === 'TOUR1'){
        dispatch(setFinishedTours(['TOUR1'])) // Finish TOur1
        updateFinishedTours([...(user['https://thdy/user_md'].finishedTours || []), 'TOUR1']) // Update in the backend
        dispatch(setActiveTour(false)) // No active tour
        navigate('/dashboard')
      }
      // Need to set our running state to false, so we can restart if we click start again.
      dispatch(setRunTour(false))
      dispatch(setTourStepIndex(0)) // Reset tour step
    }
  }

  const workSpaceWrapper = (
    <SampleTourComponent>
      <WorkspacePage />
    </SampleTourComponent>
  )

  const shouldShowSidebar = useCallback(() => {
    for (let i = 0; i < HIDDEN_SIDEBAR_PATHS.length; i++) {
      if (location.pathname.indexOf(HIDDEN_SIDEBAR_PATHS[i]) > -1) {
        return false
      }
    }

    return true

  }, [location.pathname])

  return (
    <div className={user && user['https://thdy/user_md'] && user['https://thdy/user_md'].darkMode ? `toggle-root-container dark` : `toggle-root-container`}>
      <ToasterComponent />
      {
        runTour && activeTour === 'TOUR1' && (
          <ReactJoyride
            steps={JOYRIDE_STEPS.TOUR1}
            run={runTour}
            continuous={true}
            callback={joyrideCallback}
            showSkipButton={true}
            hideBackButton={true}
            hideCloseButton={true}
            disableScrollParentFix={true}
            stepIndex={tourStepIndex}
            styles={JOYRIDE_STYLE}
            locale={JOYRIDE_LOCALE}
            floaterProps={JOYRIDE_TOOLTIP_STYLES}
          />
        )
      }
      { showNewProjectModal && <NewProjectModalComponent /> }
      { showProjectSharingModal && <ProjectSharingModalComponent /> }
      { showProjectThumbnailModal && <ProjectThumbModalComponent project={showProjectThumbnailModal} /> }
      { showCookiePolicy && <CookiePolicyModalComponent onClose={() => setShowCookiePolicy(false)} /> }
      { !isVerified && <VerificationModalComponent /> }
      { isVerified && (
        <>
          {user && <HeaderComponent />}
          { showPlanLimit && <PlanLimitComponent /> }
          <div className="toggle-page-container">
            {user && shouldShowSidebar() && <SidebarComponent location={location.pathname} /> }
            <div className="toggle-page-content-wrapper">
              <div className={shouldShowSidebar() ? "toggle-page-content" : "toggle-page-content editor"}>
                { (window.location.host.split('.')[0] === 'view' || window.location.host.split('.')[0] === 'view-test') ? (
                  <Routes>
                    <Route path=":project_id/latest" element={<PublicViewerPage />} />
                  </Routes>
                ) : (
                  <Routes>
                    <Route path="/editor" element={<RequireAuth><Editor /></RequireAuth>} />
                    <Route path="/editor/:id" element={<RequireAuth>{workSpaceWrapper}</RequireAuth>} />
                    <Route path="/dashboard" element={<RequireAuth><DashboardPage stripePromise={stripePromise} /></RequireAuth>} />
                    <Route path="/projects" element={<RequireAuth><ProjectsPage /></RequireAuth>} />
                    <Route path="/share/:public_link" element={<PublicViewerPage />} />
                    <Route path="/integrations/aritize3d/:job_id" element={<RequireAuth><Aritize3dViewerPage /></RequireAuth>} />
                    <Route path="/help" element={<RequireAuth><HelpPage /></RequireAuth>} />
                    <Route path="/tutorials" element={<RequireAuth><TutorialsPage /></RequireAuth>} />
                    <Route path="/settings/:page" element={<RequireAuth><SettingsPage stripePromise={stripePromise} /></RequireAuth>} />
                    <Route path="/admin" element={<RequireAuth><AdminPage /></RequireAuth>} />
                    <Route path="/login" element={<LoginPage />} />
                    <Route path="/sign-up" element={<SignUpPage stripePromise={stripePromise} />} />
                    <Route path="/notifications" element={<RequireAuth><NotificationsPage /></RequireAuth>} />
                    <Route path="/ui" element={<ToggleUIPage />} />
                    <Route path="*" element={<LoginPage />} />
                  </Routes>
                )}
             </div>
            </div>
          </div>
        </>
      )}
    </div>
  )
}

export default Toggle
