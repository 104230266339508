import ModalComponent from "../modal/modal.component"

const FileUploadLimitMaximumModalComponent = (props) => {

  let sizeInMB = 4
  if (props.sizeInMB) {
    sizeInMB = props.sizeInMB
  }

  const modalContent = (
    <p>Please select a file less than or equal to {sizeInMB} MB in size</p>
  )
  const actions = (
    <button className="toggle-default-btn" onClick={() => props.close()}>Close</button>
  )
  return (
    <ModalComponent title="Larger File Selected"
      close={() => props.close()}
      content={modalContent}
      className="small"
      actions={actions} />
  )
}

export default FileUploadLimitMaximumModalComponent