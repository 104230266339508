import {useEffect, useCallback} from 'react'
import MiscHelperMethods from '../helper-methods/misc.helpers'

const useKeyBindings = (projectRef, publicView, keysEnabled, handleToggleListMenu, isHoldingShiftRef,
    isHoldingCtrlRef, isHoldingCmdRef, setSpecialAction, setSelectionMenu,
    handlePaste, handleCopy, selectedParts, copyRef, sceneRef, lastClickOrigin,
    isRightClick
) => {

    const disableDefaults = (e) => {
        e.preventDefault()
        e.stopPropagation()
    }

    const handleKeyUp = useCallback((e) => {
        // Handle shift key up
        const { key, keyCode, shiftKey, metaKey, ctrlKey } = e
        if (key === 'Shift' || keyCode === 16 || shiftKey) {
            isHoldingShiftRef(false)
        }
    
        if (key === 'Meta' || keyCode === 91 || metaKey || key === "Control" || keyCode === 17 || ctrlKey) {
            isHoldingCtrlRef(false)
            isHoldingCmdRef(false)
            setSpecialAction(false)
    
            if (selectedParts.length > 0 && lastClickOrigin === 'scene' && isRightClick && sceneRef?.pointerX && sceneRef?.pointerY) {
                setSelectionMenu({left: sceneRef.pointerX, top: sceneRef.pointerY})
            }
        }
    }, [selectedParts.length, lastClickOrigin, isRightClick, setSelectionMenu, setSpecialAction, sceneRef?.pointerX, sceneRef?.pointerY, isHoldingShiftRef, isHoldingCtrlRef, isHoldingCmdRef])

    const handleKeyPress = useCallback((e) => {
        const { key, keyCode, shiftKey, ctrlKey, metaKey } = e
        
        // Check for disabled flag
        if (!keysEnabled.current || publicView) {
            return
        }

        if (key === 'e' && MiscHelperMethods.isConfiguratorOr3dOrVariationsProject(projectRef)) {
            handleToggleListMenu('parts')
        }
        else if (key === 'o' && MiscHelperMethods.isConfiguratorProject(projectRef)) {
            handleToggleListMenu('configure')
        }
        else if (key === 'l') {
            handleToggleListMenu('lighting')
        }
        else if (key === 'm' && MiscHelperMethods.isMaterialsProject(projectRef)) {
            handleToggleListMenu('material')
        }
        else if (!metaKey && !ctrlKey && key === 'v' && MiscHelperMethods.isVariationsProject(projectRef)) {
            handleToggleListMenu('variations')
        }
        else if (key === 'Shift' || keyCode === 16 || shiftKey) {
            isHoldingShiftRef(true)
        }
        else if ((metaKey || ctrlKey) && selectedParts.length > 0 && key === 'c') {
            disableDefaults(e)

            // Execute copy
            handleCopy("copyAll", selectedParts[0])
        }
        else if ((metaKey || ctrlKey) && selectedParts.length > 0 && copyRef && copyRef.partId !== selectedParts[0] && key === 'v') {
            disableDefaults(e)
    
            // Execute paste
            handlePaste(selectedParts[0])
        }
        else if (key === 'Meta' || keyCode === 91 || metaKey) {
            isHoldingCmdRef(true)
            setSpecialAction("Command: Continue to select multiple parts")
            setSelectionMenu(false)
        }  
        else if (key === "Control" || keyCode === 17 || ctrlKey) {
            isHoldingCtrlRef(true)
            setSpecialAction("Control: Continue to select multiple parts")
            setSelectionMenu(false)
        }
        else {
            isHoldingShiftRef(false)
            isHoldingCmdRef(false)
            setSpecialAction(false)
        }
    }, [projectRef, handleToggleListMenu, publicView, selectedParts, handleCopy, handlePaste, keysEnabled, copyRef, setSelectionMenu, setSpecialAction, isHoldingShiftRef, isHoldingCtrlRef, isHoldingCmdRef])
    
    useEffect(() => {

        document.addEventListener('keydown', handleKeyPress)
        document.addEventListener('keyup', handleKeyUp)
        document.addEventListener("contextmenu", disableDefaults)
        
        return () => {
          document.removeEventListener('keydown', handleKeyPress)
          document.removeEventListener('keyup', handleKeyUp)
          document.removeEventListener('contextmenu', disableDefaults)
        }
    
    }, [handleKeyUp, handleKeyPress])
}

export default useKeyBindings