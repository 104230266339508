import { useState } from "react"
import ModalComponent from "../../modal/modal.component"
import { useAdminGenerateUserReportMutation } from "../../../redux/api.redux.slice"
import DropdownComponent from "../../dropdown/dropdown.component"

const GenerateReportModalComponent = (props) => {

  const [generateReport, {isLoading}] = useAdminGenerateUserReportMutation()
  const [email, setEmail] = useState("")
  const [period, setPeriod] = useState({id: "allTime", label: "All time"})
  const [error, setError] = useState(false)

  const handleGenerate = async () => {
    setError(false)

    try {
      const params = {email, period: period.id}
      await generateReport(params).unwrap()
      props.onComplete()
    }
    catch (e) {
      setError("Uh oh, there was an issue generating your report. Please try again")
    }
  }

  const options = [
    {id: "allTime", label: "Any time"},
    {id: "thisMonth", label: "This month"},
    {id: "lastMonth", label: "Last month"},
    {id: "lastThreeMonths", label: "3 months ago"},
    {id: "lastSixMonths", label: "6 months ago"},
    {id: "thisYear", label: "This year"}
  ]

  const handleUpdateOption = (e, dropdownId, valueId) => {
    const selectedOption = options.find((o) => o.id === valueId)
    if (selectedOption) {
      setPeriod({id: valueId, label: selectedOption.label})
    }
  }

  const modalContent = (
    <div className={isLoading ? "threedy-skeleton" : ""}>
      <h5 className="primary-color">Please let us know where to send the report</h5>
      <div className="threedy-lab-flex">
        <div className="threedy-lab-text-input">
          <h4>Email:</h4>
          <input type="email" autoFocus disabled={isLoading} placeholder="Email..." value={email} onChange={(e) => setEmail(e.target.value)} />
        </div>
        <div className="threedy-lab-text-input">
          <h4>Since:</h4>
          <DropdownComponent label="Select Period" id="period" placeholder="Select" value={period.id} valueLabel={period.label} onChange={handleUpdateOption} options={options}></DropdownComponent>
        </div>
      </div>
      {error && <div className="error margin-top centered">{error}</div>}
    </div>
  )

  const modalActions = (
    <>
      <button className="toggle-default-btn" onClick={props.onClose}>Cancel</button>
      <button className="toggle-primary-btn" disabled={isLoading || !email} onClick={handleGenerate}>Create</button>
    </>
  )

  return <ModalComponent title="Generate a user report" content={modalContent} actions={modalActions} close={props.onClose} />
}

export default GenerateReportModalComponent