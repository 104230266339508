import { createSlice } from "@reduxjs/toolkit"
import { validateLastUsedMaterials } from "../utils/ui.util"
import { T } from "../constants"

const initialState = {
  collapsedSidebar: false,
  showNewProjectModal: false,
  showProjectSharingModal: false,
  showProjectThumbnailModal: false,
  selectedProject: false,
  materials: false,
  personalCollection: false,
  searchLibrary: false,
  lastUsedMaterials: [],
  toast: false,
  showVerification: false,
  projectSaving: {
    saveQueue: {},
    saving: false
  },
  selectedProjectMaterials: false,
  showViewerHelp: false,
  showViewerShare: false,
  showViewerAR: false,
  runTour: false,
  tourStepIndex: 0,
  activeTour: false, // Can be 'TOUR1' --> Dashboard Tour, 'TOUR3' --> Material Tour, 'TOUR2' --> Sample Tour
  finishedTours: [],
  observeExportCompletion: false,
  ongoingExports: [],
  hasUnreadExport: false
}

const uiSlice = createSlice({
  name: 'ui',
  initialState,
  reducers: {
    toggleSidebar: (state, action) => {
      const collapseIt = action.payload !== undefined ? action.payload : !state.collapsedSidebar
      state.collapsedSidebar = collapseIt
    },
    showNewProjectModal: (state) => {
      state.showNewProjectModal = true
    },
    hideNewProjectModal: (state) => {
      state.showNewProjectModal = false
    },
    showProjectSharingModal: (state, action) => {
      state.showProjectSharingModal = true
    },
    hideProjectSharingModal: (state) => {
      state.showProjectSharingModal = false
    },
    showProjectThumbnailModal: (state, action) => {
      state.showProjectThumbnailModal = action.payload
    },
    hideProjectThumbnailModal: (state) => {
      state.showProjectThumbnailModal = false
    },
    setSelectedProject: (state, action) => {
      state.selectedProject = action.payload
    },
    setSelectedProjectProperty: (state, action) => {
      const {property, value} = action.payload
      state.selectedProject = {...state.selectedProject, [property]: value}
    },
    setSelectedProjectMaterials: (state, action) => {
      state.selectedProjectMaterials = action.payload
    },
    setMaterials: (state, action) => {
      state.materials = action.payload
    },
    setPersonalCollection: (state, action) => {
      state.personalCollection = action.payload
    },
    setUserSearchLibrary: (state, action) => {
      state.searchLibrary = action.payload
    },
    addToPersonalCollection: (state, action) => {
      const payload = Array.isArray(action.payload) ? action.payload : [action.payload]
      if (state.personalCollection && Array.isArray(state.personalCollection)) {
        state.personalCollection = [...payload, ...state.personalCollection]
      }
      else {
        state.personalCollection = [...payload]
      }
    },
    setToast: (state, action) => {
      state.toast = action.payload
    },
    setShowVerification: (state, action) => {
      state.showVerification = action.payload
    },
    setProjectSaving: (state, action) => {
      const {caller, saving} = action.payload

      if (saving) {
        state.projectSaving.saveQueue[caller] = true
        state.projectSaving.saving = true
      }
      else {
        if (state.projectSaving.saveQueue[caller]) {
          delete state.projectSaving.saveQueue[caller]
        }
      }

      state.projectSaving.saving = Object.keys(state.projectSaving.saveQueue).length > 0
    },
    setShowViewerHelp: (state, action) => {
      state.showViewerHelp = action.payload
    },
    setShowViewerShare: (state, action) => {
      state.showViewerShare = action.payload
    },
    setShowViewerAR: (state, action) => {
      state.showViewerAR = action.payload
    },
    setRunTour: (state, action) => {
      state.runTour = action.payload
    },
    setTourStepIndex: (state, action) => {
      state.tourStepIndex = action.payload
    },
    setActiveTour: (state, action) => {
      state.activeTour = action.payload
    },
    setFinishedTours: (state, action) => {
      state.finishedTours.push(...action.payload)
    },
    setObserveCompletion: (state, action) => {
      state.observeExportCompletion = action.payload
    },
    setOngoingExports: (state, action) => {
      const payload = Array.isArray(action.payload) ? action.payload : [action.payload]
      const removeDuplicates = new Set([...state.ongoingExports, ...payload])
      state.ongoingExports = [...removeDuplicates]
    },
    setOngoingExportsEmpty: (state, action) => {
      const removeID = action.payload ? action.payload : null
      state.ongoingExports = removeID ? [...state.ongoingExports.filter(exportItemID => exportItemID !== removeID)] : []
    },
    setUnreadExport: (state, action) => {
      state.hasUnreadExport = action.payload
    },
    setLastUsedMaterials: (state, action) => {
      if (Array.isArray(action.payload)) {
        state.lastUsedMaterials = [...action.payload]
        return
      }
      const validatedRes = validateLastUsedMaterials(action.payload, state.lastUsedMaterials)
      if (validatedRes === false) { return } 
      if (validatedRes === T.MOVE_TO_TOP) { 
        state.lastUsedMaterials = [action.payload, ...state.lastUsedMaterials.filter(mat => mat.id !== action.payload.id)]
        return
      }
      if (validatedRes === T.LIMIT_EXCEEDED) {
        state.lastUsedMaterials.pop()
      }
      state.lastUsedMaterials = [action.payload, ...state.lastUsedMaterials]
    }
  }
})

export const {
  addToPersonalCollection,
  setPersonalCollection, setUserSearchLibrary, setShowViewerAR, setShowViewerShare, setShowViewerHelp, setSelectedProjectMaterials,
  setProjectSaving, toggleSidebar, setShowVerification, showNewProjectModal, hideNewProjectModal, showProjectSharingModal,
  hideProjectSharingModal, showProjectThumbnailModal, hideProjectThumbnailModal, setSelectedProject, setMaterials,
  setToast, setRunTour, setTourStepIndex, setActiveTour, setFinishedTours, setSelectedProjectProperty, setObserveCompletion,
  setOngoingExports, setOngoingExportsEmpty, setUnreadExport, setLastUsedMaterials
} = uiSlice.actions

export default uiSlice.reducer