import { useEffect, useRef, useState } from "react"
import { MapInteractionCSS } from 'react-map-interaction';
import { upload } from "../../assets"
import SpinnerComponent from "../spinner/spinner.component"
import "./reference-photo.component.css"
import { useEditProjectReferencePhotoMutation } from "../../redux/api.redux.slice"
import { useDispatch } from "react-redux"
import { setToast } from "../../redux/ui.redux.slice"
import { Rnd } from "react-rnd"
import FileUploadLimitMaximumModalComponent from "../alerts/file-upload-limit-maximum-modal.component"
import SupportedImageTypesModalComponent from "../alerts/supported-image-types-modal.component"
import useRefPhotos from "../../pages/workspace/hooks/useRefPhotos";
import { REFERENCE_PHOTO_STATUSES, T } from "../../constants";
import useRefPanelPosOnWindowResized from "../../hooks/useRefPanelPosOnWindowResized";

const ReferencePhotoComponent = (props) => {
  const defaultPos = useRef({x: window.innerWidth - 300, y: window.innerHeight - 55})
  const { photo, projectId, onSaveDimensions, projectType } = props
  const [collapsed, setCollapsed] = useState(true)
  const [step, setStep] = useState(false)
  const [isEditing, setIsEditing] = useState(false)
  const [showHelpTip, setShowHelpTip] = useState(false)
  const [position, setPosition] = useState({x: window.innerWidth - 455, y: window.innerHeight - 285, width: 300, height: 240})
  const elRef = useRef()
  const fileInputRef = useRef()
  const workingPhoto = useRef()
  const [doUploadRequest] = useEditProjectReferencePhotoMutation()
  const dispatch = useDispatch()
  const [showUploadLimitMaxModal, setShowUploadLimitMaxModal] = useState(false)
  const [showSupportedImageModal, setShowSupportedImageModal] = useState(false)
  const [curPhotoIndex, setCurPhotoIndex] = useState(0)
  const [refImgZooming, setRefImgZooming] = useState({
    scale: 1,
    translation: { x: 0, y: 0 }
  })
  const debounceRef = useRef()
  const {refPhotos, uploading, refPhotosStatus, addUploadedPhotos, deleteRefPhotos} = useRefPhotos()
  useRefPanelPosOnWindowResized(defaultPos, setPosition)

  useEffect(() => {
    if (projectType !== T.MATERIAL || [REFERENCE_PHOTO_STATUSES.ADDED_FROM_REF_COMP, REFERENCE_PHOTO_STATUSES.INIT, REFERENCE_PHOTO_STATUSES.DELETED].includes(refPhotosStatus)) { return }
    if (workingPhoto.current && workingPhoto.current.photos) {
      workingPhoto.current = {...workingPhoto.current, photos: refPhotos}
    } else {
      workingPhoto.current = {photos: refPhotos}
    }

    setStep(refPhotosStatus === REFERENCE_PHOTO_STATUSES.DELETED ? 'edit' : 'existing')
    setCurPhotoIndex(refPhotos.length-1)
  }, [refPhotos, projectType, refPhotosStatus])

  useEffect(() => {
    if (projectType !== T.MATERIAL) { return }
    if (uploading) { setStep('loading') }
    else {
      setStep(workingPhoto.current && workingPhoto.current.photos && workingPhoto.current.photos.length > 0 ? 'existing' : 'initial')
    }
  }, [uploading, projectType])

  useEffect(() => {

    if ((photo && photo.photos && photo.photos.length > 0) || workingPhoto.current) {
      setStep("existing")
      const fromPhoto = workingPhoto.current && workingPhoto.current.photos ? workingPhoto.current : photo
      if (!workingPhoto.current) {
        workingPhoto.current = photo
      }

      if (fromPhoto.x !== undefined && fromPhoto.y !== undefined) {
        const width = fromPhoto.width ? parseInt(fromPhoto.width.toString().replace("px","")) : 300
        const height = fromPhoto.height ? parseInt(fromPhoto.height.toString().replace("px","")) : 240
        setPosition({x: fromPhoto.x, y: fromPhoto.y, width, height})
        setRefImgZooming(prev => ({...prev, scale: fromPhoto.scale ? +fromPhoto.scale : 1}))
      }
    }
    else {
      setStep("initial")
    }

  }, [photo])

  const handleToggleCollapsed = () => {
    if (collapsed) {
      setCollapsed(false)
    }
    else {

      // Save state if we're editing
      if (isEditing) {
        handleToggleResize()
      }

      setCollapsed(true)

      if ((photo && photo.photos?.length > 0) || (workingPhoto.current && workingPhoto.current.photos.length > 0)) {
        setStep("existing")
      }
    }
  }

  const handleUploadPhoto = async (imgFile) => {
    if (projectType === T.MATERIAL && workingPhoto.current && workingPhoto.current.photos && workingPhoto.current.photos.find(photo => photo.name === imgFile.src.name)) {
      return
    }
    try {
      setStep("loading")
      const formData = new FormData()
      formData.append("file", imgFile.src)
      formData.append("position", JSON.stringify(position))
      formData.append("scale", refImgZooming.scale)
      const res = await doUploadRequest({projectId, body: formData}).unwrap()
      const { src, srcUrl } = res.data
      const updatedPhotos = workingPhoto.current && workingPhoto.current.photos ? [...workingPhoto.current.photos] : []
      updatedPhotos.push({src, srcUrl, name: imgFile.src.name})
      if (projectType === T.MATERIAL) {
        addUploadedPhotos({src, srcUrl, name: imgFile.src.name})
      }
      workingPhoto.current = {photos: updatedPhotos, ...position, scale: refImgZooming.scale}
      setCurPhotoIndex(updatedPhotos.length-1)
      setStep("existing")
    }
    catch (e) {
      console.log(e)
      setStep("initial")
      dispatch(setToast({message: "Uh oh. We had an issue storing your photo, please try again.", isError: true}))
    }
  }

  const handleDeletePhoto = (index) => {
    const updatedPhotos = [...workingPhoto.current.photos].filter((p, i) => i !== index)
    const params = {...workingPhoto.current, photos: updatedPhotos}

    if (updatedPhotos.length > 0) {
      // Update index
      if (!updatedPhotos[curPhotoIndex]) {
        setCurPhotoIndex(updatedPhotos.length - 1)
      }
    }
    else {
      setStep("initial")
    }

    if (projectType === T.MATERIAL) {
      deleteRefPhotos(index)
    }
    workingPhoto.current = params
    onSaveDimensions(params)
  }

  const updateRefImgScale = (scale) => {
    const params = {...workingPhoto.current, scale}
    onSaveDimensions(params)
  }

  const handleImgZooming = (value) => {
    setRefImgZooming(value)
    if (debounceRef.current) {
      clearTimeout(debounceRef.current)
    }

    debounceRef.current = setTimeout(() => {
      updateRefImgScale(value.scale)
    }, 500)
  }
  

  const handleToggleResize = () => {
    if (collapsed || step === "initial") {
      return
    }

    if (isEditing) {
      // Save updates
      const params = {...position}

      if (workingPhoto.current.photos) {
        params.photos = workingPhoto.current.photos
      }

      workingPhoto.current = {...workingPhoto.current, ...position}
      onSaveDimensions(params)
      setIsEditing(false)
    }
    else {
      setIsEditing(true)
    }
  }

  const handleEditPhotos = () => {
    setStep("edit")
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.currentTarget.classList.add("drop-section")
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.currentTarget.classList.remove("drop-section")
  }

  const handleResize = (e, direction, ref, delta, toPosition) => {
    setPosition({...position, width: ref.style.width, height: ref.style.height, ...toPosition})
  }

  const handleDragStop = (e, d) => {
    setPosition({...position, x: d.x, y: d.y})
  }

  const getContent = () => {
    let content = null

    switch (step) {
      default: {
        break
      }
      case "loading": {
        content = (
          <div className="threedy-lab-spinner-wrapper medium">
            <SpinnerComponent inline />
          </div>
        )
        break
      }
      case "initial": {
        content = (
          <>
            <div className="toggle-reference-photo-content upload-content">
              <h4 className="mb-10">Upload reference photo</h4>
              {
                <div className="upload-image-section" onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleFileDrop}>
                  <p>Choose a reference photo to upload to the scene as you design</p>
                  <span className="upload-icon">{upload}</span>
                  <br/> {imageUploadButton()}
                </div>
              }
            </div>
          </>
        )

        break
      }
      case "edit": {
        content = (
          <>
            <div className="toggle-reference-photo-content toggle-reference-photo-edit-content">
              {(!workingPhoto.current.photos || workingPhoto.current.photos.length < 1) && <h5 className="text-center">You haven't added any photos yet...</h5>}
              {workingPhoto.current.photos && workingPhoto.current.photos.length > 0 && (
                <ul>
                  {
                    workingPhoto.current.photos.map((p, i) => {
                      return (
                        <li key={p.src}><h5>{p.name ? p.name : 'Image ' + (i+1)}</h5><button className="delete-btn" onClick={() => handleDeletePhoto(i)}><span className="icon-trash"></span></button></li>
                      )
                    })
                  }
                </ul>
              )}
              <div className="reference-button-group">
                <button disabled={workingPhoto.current.photos && workingPhoto.current.photos.length > 9} onClick={() => setStep("initial")}>Add Photo</button>
                <button onClick={() => setStep("existing")}>Done</button>
              </div>
            </div>
          </>
        )

        break
      }
      case "existing": {
        content = (
          <>
            <div className="toggle-reference-photo-pagination">
              {
                workingPhoto.current.photos && workingPhoto.current.photos.length > 1 && workingPhoto.current.photos.map((p, i) => {
                  return <button key={p.src} className={curPhotoIndex === i ? "selected" : ""} onClick={() => setCurPhotoIndex(i)}></button>
                })
              }
            </div>
            <div className="toggle-reference-photo-content">
              {
                workingPhoto.current.photos[curPhotoIndex] && workingPhoto.current.photos[curPhotoIndex].srcUrl && (
                  <MapInteractionCSS value={refImgZooming} onChange={handleImgZooming}>
                    <img src={workingPhoto.current.photos[curPhotoIndex].srcUrl} alt="user-reference-foto" />
                  </MapInteractionCSS>
              )}
            </div>
          </>
        )
      }
    }

    return content
  }

  const imageUploadButton = (buttonLabel = 'Choose File') => (
    <div className="image-upload-container">
      <input style={{display: 'none'}} ref={fileInputRef} type="file" accept=".jpeg,.jpg,.png" onChange={handleFileChange} />
      <button onClick={() => fileInputRef.current.click()}>{buttonLabel}</button>
    </div>
  )
  
  const handleFileChange = (e, droppedImage = null) => {
    const file = droppedImage ? droppedImage : e.target.files[0]
    const maxFileSize = 5 * 1024 * 1024  // 5mb max

    if (file.size > maxFileSize) {
      e.target.value = null
      setShowUploadLimitMaxModal(true)
      return
    }

    const reader = new FileReader()
    reader.onload = async (e) => {
      const thumb = e.target.result
      const imgFile = {src: file, name: file.name.replace(/[\W_.]+/g, "_"), thumb}
      await handleUploadPhoto(imgFile)
    }
    reader.readAsDataURL(file)
  }

  const handleFileDrop = (e) => {
    e.preventDefault()
    e.currentTarget.classList.remove("drop-section")
    const image = e.dataTransfer.files[0]
    const acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg']
    if (acceptedTypes.includes(image.type)) {
      handleFileChange(false, image)
    } else {
      setShowSupportedImageModal(true)
    }
  }

  const getClasses = () => {
    if (collapsed) {
      return "toggle-reference-photo collapsed"
    }

    if (step === "initial" || step === "loading") {
      return "toggle-reference-photo fixed-layout"
    }

    if (isEditing) {
      return "toggle-reference-photo is-editing"
    }
    
    return "toggle-reference-photo"
  }

  const showZoomPercentage = () => {
    if (1-refImgZooming.scale < 0) {
      return `${(100 + Math.abs(1-refImgZooming.scale)*100).toFixed(0)}%`
    }
    if (1-refImgZooming.scale > 0) {
      return `${(1-refImgZooming.scale*100).toFixed(0)}%`
    }
    return '100%'
  }
  
  const handleZooming = (type) => {
    const zoomRate = type === 'dec' ? -0.1 : 0.1
    const newScaleValue = refImgZooming.scale + zoomRate
    setRefImgZooming(prev => ({...prev, scale: newScaleValue}))
    updateRefImgScale(newScaleValue)
  }  

  return (
    <Rnd
      onDoubleClick={handleToggleResize}
      style={{zIndex: 2}}
      enableResizing={isEditing}
      disableDragging={!isEditing}
      bounds={'.threedy-lab-workspace'}
      size={{ width: collapsed ? "auto" : position.width, height: collapsed ? "auto" : position.height }}
      position={{ x: collapsed ? defaultPos.current.x : position.x, y: collapsed ? defaultPos.current.y : position.y }}
      onDragStop={handleDragStop}
      onResizeStop={handleResize}
    >
      <div className={getClasses()} ref={elRef}>
        <div className="toggle-reference-photo-header">
          {collapsed && <button onClick={handleToggleCollapsed} className="expand-collapse-btn"><span className="icon-plus"></span> Reference Photos</button> }
          {!collapsed && <h4>Reference Photos</h4>}
          {!collapsed && <button className="update-photo-btn" disabled={step === "initial" || step === "loading" || step === 'edit'} onClick={handleEditPhotos}>Edit Photos</button>}
          {!collapsed && <button onClick={handleToggleCollapsed} className="close-btn"><span className="icon-x"></span></button>}
        </div>
        {!collapsed && getContent()}
        {!collapsed && step === 'existing' && (
          <div className="toggle-reference-photo-help">
            <span className="icon-help-circle" onMouseEnter={() => setShowHelpTip(true)} onMouseLeave={() => setShowHelpTip(false)}></span>
            {showHelpTip && (
              <div className="toggle-reference-photo-help-tip">
                Tip: Double click the box to move or resize it and then double click again to freeze the box in place
              </div>
            )}
            <li className="zoom-controls-wrapper">
              <div className="zoom-wrapper">
                <button className="zoom-btn" onClick={e => handleZooming('dec')}><span >&#8722;</span></button>
                <div className="zoom-percentage">
                  { showZoomPercentage() }
                </div>
                <button className="zoom-btn" onClick={e => handleZooming('inc')}><span >&#43;</span></button>
              </div>
            </li>
          </div>
        )}
      </div>
      {showUploadLimitMaxModal &&
        <FileUploadLimitMaximumModalComponent sizeInMB={5} close={() => setShowUploadLimitMaxModal(false)} />}
      {showSupportedImageModal &&
        <SupportedImageTypesModalComponent close={() => setShowSupportedImageModal(false)} />}
    </Rnd>
  )
}

export default ReferencePhotoComponent