import { useState } from "react"
import MaterialSettingsComponent from "../../../../../components/material-settings/material-settings.component"

const ElementSettingsPane = (props) => {

  const {appliedMaterial, element, projectMeta, onUpdateSettings, tab} = props
  const [showAdvancedSettings, setShowAdvancedSettings] = useState(false)

  return (
    tab === "decals" ? null : (
      <div className="toggle-element-configuration-section">
        <button className="section-btn" onClick={() => setShowAdvancedSettings(!showAdvancedSettings)}><h6>Advanced Settings <span className={showAdvancedSettings ? "icon-expand_less" : "icon-expand_more"} /></h6></button>
        {showAdvancedSettings && (
          <div className="toggle-element-advanced-settings">
            <MaterialSettingsComponent
              tab={tab}
              material={appliedMaterial}
              part={element}
              onSaveMaterialSettings={onUpdateSettings}
              projectMeta={projectMeta}
            />
          </div>
        )}
      </div>
    )
  )
} 

export default ElementSettingsPane