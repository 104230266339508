import { equalizer } from '../../assets'
import { doArrow } from '../../utils/ui.util'

const SampleTourStepComponent = (props) => {
    const {currentStep, totalStep, title, children} = props
  
    return (
      <div>
        <h4 style={{fontSize: '18px', marginBottom: '10px'}}>
          <div className="tour-step-title-wrapper text-white">
            <span>{title}</span>
            <span>Step {currentStep}/{totalStep}</span>
          </div>
        </h4>
        {children}
      </div>
    )
  }

 export const SAMPLE_TOUR_STEPS = [
    {
      selector: '.pseudo-model-wrapper',
      content: (
        <SampleTourStepComponent currentStep="1" totalStep="9" title="Learning the Basics">
          <div className="tour-step-content-wrapper text-white">
            Welcome! Let's start by learning how to move your model in the scene.
          </div>
          <div className="tour-step-content-wrapper text-white">
            To Rotate, click and drag your mouse.
          </div>
          <div className="tour-step-content-wrapper text-white">
            To zoom in and out, scroll your mouse wheel/pinch your touchpad.
          </div>
          <div className="tour-step-content-wrapper text-white">
            To pan, click and drag with two fingers on a touchpad or hold control & left click while dragging your mouse.
          </div>
        </SampleTourStepComponent>
      ),
      position: 'left',
      styles: {    
        highlightedArea: (base, { x, y, width, height }) => ({
          ...base,
          display: 'block',
          stroke: 'var(--primaryColor)',
          strokeWidth: 2,
          width: width + 8,
          height: height + 8,
          rx: 45,
          x: x - 4,
          y: y - 4,
          pointerEvents: 'none',
        }),
     }
    },
    {
      selector: '.threedy-lab-workspace .material-tour-selector.threedy-lab-workspace-list.parts-viewer-opened',
      content: (
        <SampleTourStepComponent currentStep="2" totalStep="9" title="Grouping Parts">
          <div className="tour-step-content-wrapper text-white">
            Once you import a file, all the parts are found in the Elements panel. Here you can group, rename and organize your parts. Let’s try it!
          </div>
          <div className="tour-step-content-wrapper text-white">
            Select two parts by holding “shift” on your keyboard and select “ + Group”
          </div>
        </SampleTourStepComponent>
      ),
      position: 'left',
    },
    {
      selector: '.threedy-lab-workspace .threedy-lab-workspace-list .grouped-part',
      content: (
        <SampleTourStepComponent currentStep="3" totalStep="9" title="Renaming Elements">
          <div className="tour-step-content-wrapper text-white">
            Great! Now that you have a group, let's give it a name.
          </div>
          <div className="tour-step-content-wrapper text-white">
            Select the three dotes on the right and give your group a new name.
          </div>
        </SampleTourStepComponent>
      ),
      position: 'left',
      highlightedSelectors: ['.threedy-lab-workspace .threedy-lab-workspace-list .grouped-part', '.toggle-context-menu'],
      mutationObservables: ['.threedy-lab-workspace .threedy-lab-workspace-list .grouped-part', '.toggle-context-menu'],
      resizeObservables: ['.threedy-lab-workspace .threedy-lab-workspace-list .grouped-part', '.toggle-context-menu'],
    },
    {
      selector: '.threedy-lab-workspace .material-tour-selector.threedy-lab-workspace-list.parts-viewer-opened',
      content: (
        <SampleTourStepComponent currentStep="4" totalStep="9" title="Editing Materials">
          <div className="tour-step-content-wrapper text-white">
            Now it's time to edit materials. Select any element from the list, then select “Edit material”
          </div>
        </SampleTourStepComponent>
      ),
      position: 'bottom',
      styles: {
        popover: (base, state) => ({
          ...base,
          padding: '15px',
          borderRadius: '5px',
          backgroundColor: 'var(--primaryColor)',
          ...doArrow(state.position, state.verticalAlign, state.horizontalAlign, 260),
        }),
      }
    },
    {
      selector: '.configurator-builder-flex.configurator-builder-materials',
      content: (
        <SampleTourStepComponent currentStep="5" totalStep="9" title="Selecting New Material">
          <div className="tour-step-content-wrapper text-white">
            In the Materials Panel you can choose a texture and a color for your new material.
          </div>
          <div className="tour-step-content-wrapper text-white">
            Start by browsing through the texture categories and select a texture. Press “Next” when you find one you like.
          </div>
        </SampleTourStepComponent>
      ),
      position: 'right',
      highlightedSelectors: ['.configurator-builder-flex.configurator-builder-materials', '.configurator-builder-flex.configurator-builder-material-settings'],
      mutationObservables: ['.configurator-builder-flex.configurator-builder-materials', '.configurator-builder-flex.configurator-builder-material-settings'],
      styles: {    
        popover: (base, state) => ({
          ...base,
          padding: '15px',
          borderRadius: '5px',
          backgroundColor: 'var(--primaryColor)',
          top: '-40px',
          ...doArrow(state.position, state.verticalAlign, state.horizontalAlign, 85),
        }),
     }
    },
    {
      selector: '.configurator-builder-flex.configurator-builder-materials',
      content: (
        <SampleTourStepComponent currentStep="6" totalStep="9" title="Changing Color">
          <div className="tour-step-content-wrapper text-white">
            Great choice! If you wish, you can now change the color of your texture.
          </div>
          <div className="tour-step-content-wrapper text-white">
            Select the “Colors” tab and choose a new color. After you are happy with your color select “Next”.
          </div>
        </SampleTourStepComponent>
      ),
      position: 'right',
      highlightedSelectors: ['.configurator-builder-flex.configurator-builder-materials', '.configurator-builder-flex.configurator-builder-material-settings'],
      mutationObservables: ['.configurator-builder-flex.configurator-builder-materials', '.configurator-builder-flex.configurator-builder-material-settings'],
      styles: {    
        popover: (base, state) => ({
          ...base,
          padding: '15px',
          borderRadius: '5px',
          backgroundColor: 'var(--primaryColor)',
          top: '-40px',
          ...doArrow(state.position, state.verticalAlign, state.horizontalAlign, 85),
        }),
     }
    },
    {
      selector: '.configurator-builder-flex.configurator-builder-materials',
      content: (
        <SampleTourStepComponent currentStep="7" totalStep="9" title="Advanced Settings">
          <div className="tour-step-content-wrapper text-white">
            If you wish to tweak the material further, select &nbsp;{equalizer}&nbsp;. Here you can adjust the material properties and pattern.​
          </div>
          <div className="tour-step-content-wrapper text-white">
            Go ahead and try the advanced settings.
          </div>
        </SampleTourStepComponent>
      ),
      position: 'right',
      highlightedSelectors: ['.configurator-builder-flex.configurator-builder-materials', '.configurator-builder-flex.configurator-builder-material-settings'],
      mutationObservables: ['.configurator-builder-flex.configurator-builder-materials', '.configurator-builder-flex.configurator-builder-material-settings'],
      styles: {    
        popover: (base, state) => ({
          ...base,
          padding: '15px',
          borderRadius: '5px',
          backgroundColor: 'var(--primaryColor)',
          top: '-40px',
          ...doArrow(state.position, state.verticalAlign, state.horizontalAlign, 85),
        }),
     }
    },
    {
      selector: '.material-tour-selector.threedy-lab-workspace-list.top-145',
      content: (
        <SampleTourStepComponent currentStep="8" totalStep="9" title="Lighting Environment">
          <div className="tour-step-content-wrapper text-white">
            Great! Now you know how to add or edit materials on your model.​
          </div>
          <div className="tour-step-content-wrapper text-white">
            Last tip, don’t forget that materials look different in different lighting environments. You can change the lighting here.
          </div>
        </SampleTourStepComponent>
      ),
      position: 'left',
    },
    {
      selector: '.threedy-lab-workspace .material-tour-selector.threedy-lab-workspace-list.parts-viewer-opened',
      content: (
        <SampleTourStepComponent currentStep="9" totalStep="9" title="Give it a try!">
          <div className="tour-step-content-wrapper text-white">
            Congratulations on texturing your first part - It is looking great! Now continue adding textures to the rest of your sample model. Remember we are always here to help if you have further questions!
          </div>
          <div className="tour-step-content-wrapper text-white">
            Good luck with your 3D designs!
          </div>
        </SampleTourStepComponent>
      ),
      position: 'left',
    },
  ]