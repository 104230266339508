import "./toggle-button.component.css"

import React from 'react'

const ToggleButtonComponent = (props) => {
    const { onClick, isToggleOn, inlineText, disabled } = props
    return (
        <div
            onClick={onClick}
            className={disabled ? "toggle-toggle disabled" : (isToggleOn ? "toggle-toggle selected" : "toggle-toggle")}>
            <span>
                <i><b>On</b> <b>Off</b></i>
            </span>
            <h6>{inlineText}</h6>
        </div>
    )
}
export default ToggleButtonComponent