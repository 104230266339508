import "./admin.user.sidebar.css"
import moment from "moment"
import { useEffect, useState } from "react"
import { useLazyAdminGetUserByIdQuery } from "../../redux/api.redux.slice"
import { useDispatch } from "react-redux"
import { setToast } from "../../redux/ui.redux.slice"

const AdminUserSidebar = (props) => {
  const {user, onClose} = props
  const dispatch = useDispatch()
  const [tab, setTab] = useState("success")
  const [models, setModels] = useState([])
  const [loading, setLoading] = useState(true)
  const [data, setData] = useState(false)
  const [fetchUserQuery] = useLazyAdminGetUserByIdQuery()

  useEffect(() => {
    setLoading(true)

    const getUser = async () => {
      try {
        const userRes = await fetchUserQuery(user.id).unwrap()
        setData(userRes)
        setLoading(false)
        setTab("success")
        setModels(userRes.models && userRes.models.success ? userRes.models.success : [])
      }
      catch (e) {
        console.error(e)
        setLoading(false)
        dispatch(setToast({message: "Uh oh. We had an error fetching that user. Please try again", isError: true}))
      }
    }

    getUser()

  }, [fetchUserQuery, dispatch, user.id])

  const handleSetTab = (aTab) => {
    if (aTab === tab) {
      return
    }

    setTab(aTab)
    setModels(data.models[aTab] ? data.models[aTab] : [])
  }

  return (
    <div className={loading ? "toggle-admin-user-sidebar threedy-skeleton" : "toggle-admin-user-sidebar"}>
      <div className="toggle-admin-user-sidebar-header">
        <button className="close-btn" onClick={onClose}><span className="icon-x"></span></button> 
        <h4>{user.id}</h4>
      </div>
      <div className="toggle-admin-user-sidebar-info">
        <div className="col">
          <h5><span>Created</span>{`${moment(user.created_ts).fromNow()}`}</h5>
        </div>
        <div className="col">
          <h5><span># Projects</span>{user.num_projects_created}</h5>
        </div>
        <div className="col">
          <h5><span># Models</span>{user.num_models_imported}</h5>
        </div>
      </div>
      <div className="toggle-admin-user-sidebar-info no-border">
        <div className="col">
          <h5><span>User</span>{data.name}</h5>
        </div>
      </div>
      <div className="toggle-admin-user-sidebar-info no-border">
        <div className="col">
          <h5><span>Position</span>{data.position}</h5>
        </div>
        <div className="col">
          <h5><span>Onboarded</span>{data.onboarded ? "Yes" : "No"}</h5>
        </div>
      </div>
      <div className="toggle-admin-user-sidebar-info no-border">
        <div className="col">
          <h5><span># Logins</span>{data.logins_count}</h5>
        </div>
        <div className="col">
          <h5><span>Last Login</span>{moment(data.last_login).fromNow()}</h5>
        </div>
      </div>
      <div className="toggle-admin-user-sidebar-models">
        <ul className="toggle-admin-user-sidebar-models-tabs">
          <li onClick={() => handleSetTab('success')} className={tab === "success" ? "selected" : ""}><h5>Successful Uploads</h5><h4>{data.models && data.models.success.length}</h4></li>
          {data.models && data.models.failed.length > 0 && <li onClick={() => handleSetTab('failed')} className={tab === "failed" ? "selected delete" : "delete"}><h5>Failed Uploads</h5><h4>{data.models && data.models.failed.length}</h4></li>}
        </ul>
        {models.length > 0 && (
          <ul className="toggle-admin-user-sidebar-models-list">
            <li>
              <div className="col">Name</div>
              <div className="col">Size</div>
              <div className="col">Uploaded</div>
              <div className="col">File</div>
            </li>
            {!loading && models.map((m) => {
              return (
                <li key={m.id}>
                  <div className="col">{m.name}</div>
                  <div className="col">{m.size} MB</div>
                  <div className="col">{moment(m.created_ts).fromNow()}</div>
                  <div className="col"><a href={m.land_location_url} target="_blank" rel="noreferrer">Download</a></div>
                </li>
              )
            })}
          </ul>
        )}
      </div>
    </div>
  )
}

export default AdminUserSidebar