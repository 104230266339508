import { useCallback, useEffect, useRef, useState } from "react"
import { DEBOUNCE_DELAY, DEFAULT_MATERIAL_COLOR_PROPERTIES, EVENTS, SETTINGS_MIN_MAX } from "../../constants"
import { mapPatternChanges, validateMatSettingsInput } from "../../utils/ui.util"
import { useReducer } from "react"

const MaterialColorAdjusterComponent = (props) => {
  const [workingMeta, setWorkingMeta] = useState(false)
  const debounceRef = useRef()
  const sliderDelayRef = useRef()
  const [colorInputs, updateColorInputs] = useReducer((prev, next) => {
    const newColorInputs = {...prev, ...next}
    newColorInputs.brightness = validateMatSettingsInput(newColorInputs.brightness, 'brightness')
    newColorInputs.contrast = validateMatSettingsInput(newColorInputs.contrast, 'contrast')
    newColorInputs.saturation = validateMatSettingsInput(newColorInputs.saturation, 'saturation')
    return newColorInputs
  }, {
    showBrightnessInput: false, brightness: 0,
    showContrastInput: false, contrast: 0,
    showSaturationInput: false, saturation: 0,
  })
  const { onSave, part } = props

  useEffect(() => {
    if (!part) {
      return
    }

    // console.log("load mat:", material.id, material.meta)
    const meta = part.meta ? {...DEFAULT_MATERIAL_COLOR_PROPERTIES, ...part.meta} : DEFAULT_MATERIAL_COLOR_PROPERTIES
    setWorkingMeta(meta)

  }, [part])

  const handleSliderChange = (target) => {
    const {name, value} = target
    const updatedMeta = {...workingMeta, [name]: parseInt(value)}
    setWorkingMeta(updatedMeta)

    if (sliderDelayRef.current) {
      clearTimeout(sliderDelayRef.current)
      sliderDelayRef.current = null
    }

    // Save after debounce
    if (onSave) {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current)
        debounceRef.current = null
      }

      debounceRef.current = setTimeout(() => {
        onSave(updatedMeta)
      }, DEBOUNCE_DELAY)
    }
  }

  const handleSetDefaults = useCallback(() => {
    const updated = DEFAULT_MATERIAL_COLOR_PROPERTIES
    setWorkingMeta(updated)

    // Save after debounce
    if (onSave) {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current)
        debounceRef.current = null
      }

      debounceRef.current = setTimeout(() => {
        onSave(updated)
      }, DEBOUNCE_DELAY)
    }
  }, [onSave])

  // Listen for reset event
  useEffect(() => {
    document.addEventListener(EVENTS.RESET_MAT_COLOR_SETTINGS, handleSetDefaults)
    return () => document.removeEventListener(EVENTS.RESET_MAT_COLOR_SETTINGS, handleSetDefaults)
  }, [handleSetDefaults])

  // Clean up debounce
  useEffect(() => {
    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current)
        debounceRef.current = null
      }
    }
  }, [])

  const handleBlur = (setting) => {
    updateColorInputs({...mapPatternChanges(setting, false)})
    if (workingMeta[setting] !== colorInputs[setting]) {
      let newValue = colorInputs[setting]
      handleSliderChange({name: setting, value: newValue || DEFAULT_MATERIAL_COLOR_PROPERTIES[setting]})
    }
  }

  const handleKeyDown = (e) => {
    const { key, keyCode, code } = e
    if (key === 'Enter' || keyCode === 13 || code === 'Enter') {
      e.target.blur()
    }
  }

  const handleEditColorSettings = (setting) => {
    let currValue = workingMeta[setting]
    const editableInputs = {...mapPatternChanges(setting, true), [setting]: currValue}
    updateColorInputs(editableInputs)
  }

  const colorSettingsHeaders = {
    brightness: (
      <h6>
        Brightness: {
          colorInputs.showBrightnessInput ? (
            <input
              className="uv-settings-input"
              type="number"
              value={colorInputs.brightness}
              onChange={e => updateColorInputs({brightness: e.target.value})}
              onKeyDown={handleKeyDown}
              onBlur={() => handleBlur('brightness')}
              autoFocus
            />
          ) : <strong className="pattern-value">{workingMeta.brightness}<span onClick={() => handleEditColorSettings('brightness')} className="icon-edit-2"></span></strong>
        }
      </h6>
    ),
    contrast: (
      <h6>
        Contrast: {
          colorInputs.showContrastInput ? (
            <input
              className="uv-settings-input"
              type="number"
              value={colorInputs.contrast}
              onChange={e => updateColorInputs({contrast: e.target.value})}
              onKeyDown={handleKeyDown}
              onBlur={() => handleBlur('contrast')}
              autoFocus
            />
          ) : <strong className="pattern-value">{workingMeta.contrast}<span onClick={() => handleEditColorSettings('contrast')} className="icon-edit-2"></span></strong>
        }
      </h6>
    ),
    saturation: (
      <h6>
        Saturation: {
          colorInputs.showSaturationInput ? (
            <input
              className="uv-settings-input"
              type="number"
              value={colorInputs.saturation}
              onChange={e => updateColorInputs({saturation: e.target.value})}
              onKeyDown={handleKeyDown}
              onBlur={() => handleBlur('saturation')}
              autoFocus
            />
          ) : <strong className="pattern-value">{workingMeta.saturation}<span onClick={() => handleEditColorSettings('saturation')} className="icon-edit-2"></span></strong>
        }
      </h6>
    ),
  }

  if (!workingMeta) {
    return null
  } 

  return (
    <div className="material-adjuster-settings">
      <div className="toggle-popup-setting">
        {colorSettingsHeaders.brightness}
        <input className="slider" type="range" min={SETTINGS_MIN_MAX.brightness.min} max={SETTINGS_MIN_MAX.brightness.max} step="1" name="brightness" value={workingMeta.brightness} onChange={(e) => handleSliderChange(e.target)} />
        <div className="slider-percentage">
          <span>{SETTINGS_MIN_MAX.brightness.min}%</span>
          <span>{SETTINGS_MIN_MAX.brightness.max}%</span>
        </div>
      </div>
      <div className="toggle-popup-setting">
        {colorSettingsHeaders.contrast}
        <input className="slider" type="range" min={SETTINGS_MIN_MAX.contrast.min} max={SETTINGS_MIN_MAX.contrast.max} step="1" name="contrast" value={workingMeta.contrast} onChange={(e) => handleSliderChange(e.target)} />
        <div className="slider-percentage">
          <span>{SETTINGS_MIN_MAX.contrast.min}%</span>
          <span>{SETTINGS_MIN_MAX.contrast.max}%</span>
        </div>
      </div>
      <div className="toggle-popup-setting">
        {colorSettingsHeaders.saturation}
        <input className="slider" type="range" min={SETTINGS_MIN_MAX.saturation.min} max={SETTINGS_MIN_MAX.saturation.max} step="1" name="saturation" value={workingMeta.saturation} onChange={(e) => handleSliderChange(e.target)} />
        <div className="slider-percentage">
          <span>{SETTINGS_MIN_MAX.saturation.min}%</span>
          <span>{SETTINGS_MIN_MAX.saturation.max}%</span>
        </div>
      </div>
    </div>
  )
}

export default MaterialColorAdjusterComponent