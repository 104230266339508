import { createSlice, current } from "@reduxjs/toolkit"
import { EVENTS } from "../constants"

const initialState = {
  queue: [],
  redoQueue: []
}

const historySlice = createSlice({
  name: 'history',
  initialState,
  reducers: {
    addToHistory: (state, action) => {
      state.queue.push(action.payload)
      state.redoQueue = []
    },
    undo: (state) => {
      const el = state.queue.pop()
      state.redoQueue.push(el)
      const val = JSON.parse(JSON.stringify(current(el)))
      setTimeout(() => document.dispatchEvent(new CustomEvent(EVENTS.HISTORY_UNDO, {detail: {historyAction: EVENTS.HISTORY_UNDO, ...val}})))
    },
    redo: (state) => {
      const el = state.redoQueue.pop()
      state.queue.push(el)
      const val = JSON.parse(JSON.stringify(current(el)))
      setTimeout(() => document.dispatchEvent(new CustomEvent(EVENTS.HISTORY_REDO, {detail: {historyAction: EVENTS.HISTORY_REDO, ...val}})))
    }
  }
})

export const { addToHistory, undo, redo } = historySlice.actions
export default historySlice.reducer