import { useCallback, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ReactSortable } from "react-sortablejs"
import moment from 'moment'
import { threeHorizontalLines, threeVerticalDots } from "../../assets"
import { DEFAULT_SPHERE_MAT_ID, DEFAULT_SPHERE_MESH_ID, EVENTS } from "../../constants"
import { useDeletePersonalCollectionItemMutation, useEditProjectMutation, useLazyGetPersonalMaterialByIdQuery, useUpdatePersonalCollectionItemMutation } from "../../redux/api.redux.slice"
import { setSelectedProject, setSelectedProjectMaterials, setToast } from "../../redux/ui.redux.slice"
import { createNewMaterial } from "../../utils/ui.util"
import ContextMenuComponent from "../context-menu/context-menu.component"
import EnterKeyInputComponent from "../enter-key-input/enter-key-input.component"
import MaterialAdjusterComponent from "../material-adjuster/material-adjuster.component"
import "./material-viewer.component.css"
import SpinnerComponent from "../spinner/spinner.component"

const MaterialViewerComponent = (props) => {

  const dispatch = useDispatch()
  const [materialToAdjust, setMaterialToAdjust] = useState(false)
  const [materialToPreviewId, setMaterialToPreviewId] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [renamingItem, setRenamingItem] = useState(false)
  const [showMenu, setShowMenu] = useState(false)
  const [getPersonalMaterialQuery] = useLazyGetPersonalMaterialByIdQuery()
  const [updateCollectionItem] = useUpdatePersonalCollectionItemMutation()
  const [deleteCollectionItem] = useDeletePersonalCollectionItemMutation()
  const {selectedProject, selectedProjectMaterials} = useSelector((state) => state.ui)
  const [updateProject] = useEditProjectMutation()

  useEffect(() => {

    const fetchMaterials = async (matIds) => {
      const matArr = []

      for (let i = 0; i < matIds.length; i++) {
        const matId = matIds[i]

        try {
          const mat = await getPersonalMaterialQuery(matId).unwrap()
          matArr.push(mat)
        }
        catch (e) {
          // Fail silently as the material was probably deleted
          console.error("Error fetching created material: ", e)
        }
      }

      dispatch(setSelectedProjectMaterials(matArr))
      setIsLoading(false)
    }

    if (selectedProject && selectedProject.material_ids && selectedProject.material_ids.length > 0 && !selectedProjectMaterials) {
      setIsLoading(true)
      fetchMaterials(selectedProject.material_ids)
    }

  }, [selectedProject, getPersonalMaterialQuery, dispatch, selectedProjectMaterials])

  const handleResetSphere = useCallback(() => {
    const defaultMaterial = props.scene.materials.find((m) => m.id === DEFAULT_SPHERE_MAT_ID)
    const sphere = props.scene.meshes.find((m) => m.id === DEFAULT_SPHERE_MESH_ID)

    if (sphere && defaultMaterial) {
      console.log("Reset working sphere material...")
      sphere.material = defaultMaterial
    }
  }, [props.scene])

  const updateSortedProjectMaterials = async (materialIds) => {
    const updates = {material_ids: materialIds}
    // Update
    try {
      await updateProject({projectId: selectedProject.id, body: updates}).unwrap()
      dispatch(setSelectedProject({...selectedProject, ...updates, updated_ts: moment().valueOf()}))
    }
    catch (e) {
      dispatch(setToast({message: "Uh oh. We had an issue updating your project, please try again.", isError: true}))
    }
  }

  if (!selectedProject) {
    return null
  }

  const handleClick = (e, pid) => {
    switch (e.detail) {
      case 2:
        handleStartRenameItem(pid)
        break
      default: break
    }
  }

  const handleStartRenameItem= (itemId) => {
    setRenamingItem(itemId)
    setShowMenu(false)
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: false}}))
  }

  const handleFinalizeItemNameChange = async (mid, value) => {
    setRenamingItem(false)
    setIsLoading(true)
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: true}}))

    // Update value in api
    try {
      const params = {name: value}
      await updateCollectionItem({id: mid, body: params}).unwrap()

      // Update value in store
      dispatch(setSelectedProjectMaterials([...selectedProjectMaterials].map((m) => {
        if (m.id === mid) {
          return {...m, ...params}
        }

        return m
      })))

      setIsLoading(false)
    }
    catch (e) {
      console.error("Error updating material name: ", e)
      dispatch(setToast({message: "Uh oh. There was an error updating your material. Please try again.", isError: true}))
    }
  }

  const handleShowMenu = (e, mid) => {
    e.stopPropagation()

    setShowMenu(
      {position: {
        left: e.clientX + 'px', top: e.clientY + 'px'
      },
      items: [
        {id: 'rename', label: <><span className="icon-edit-2"></span> Rename</>, action: () => handleStartRenameItem(mid)},
        {id: 'delete', className: "delete-btn", label: <><span className="icon-trash"></span> Delete</>, action: () => handleDeleteMaterial(mid)}
      ]
    })
  }

  const handlePreviewMaterial = (mid) => {

    return new Promise(async (resolve, reject) => {

      const mat = selectedProjectMaterials.find((m) => m.id === mid)
      const sphere = props.scene.meshes.find((m) => m.id === DEFAULT_SPHERE_MESH_ID)

      if (mat && sphere) {
        try {
          const raw = await createNewMaterial(props.scene, props.createdMaterials, mat, {}, props.albedoMaps, props.armMaps, props.normalMaps)
          sphere.material = raw
          setMaterialToPreviewId(mid)
          resolve({raw, meta: mat.meta})
        }
        catch (e) {
          dispatch(setToast({message: "Uh oh. There was an error loading that material. Please try again.", isError: true}))
          reject(e)
        }
      }
    })
  }

  // const handleAdjustMaterial = async (mid) => {

  //   // Preview
  //   try {

  //     const {raw, meta} = await handlePreviewMaterial(mid)

  //     // Adjust
  //     setMaterialToAdjust({raw, meta, id: mid})
  //   }
  //   catch (e) {
  //     // Nothing to handle here
  //   }
  // }

  const handleDeleteMaterial = async (mid) => {
    setIsLoading(true)

    try {
      // Delete material
      await deleteCollectionItem({ materialId: mid }).unwrap()
      dispatch(setSelectedProjectMaterials([...selectedProjectMaterials].filter((m) => m.id !== mid)))

      // If material is being previewed/adjusted, reset
      if (materialToPreviewId === mid) {
        handleResetSphere()
      }

      setIsLoading(false)
    }
    catch (e) {
      setIsLoading(false)
      dispatch(setToast({message: "Uh oh. There was an error deleting your material. Please try again.", isError: true}))
    }
  }

  const handleSaveAdjustment = async (meta) => {
    setIsLoading(true)
    const matId = materialToAdjust.id
    setMaterialToAdjust(false)

    // Update
    try {
      await updateCollectionItem({id: matId, body: {meta: JSON.stringify(meta)}}).unwrap()
      dispatch(setSelectedProjectMaterials([...selectedProjectMaterials].map((m) => {
        if (m.id === matId) {
          return {...m, meta}
        }

        return m
      })))

      setIsLoading(false)
    }
    catch (e) {
      dispatch(setToast({message: "Uh oh. There was an error updating your material. Please try again.", isError: true}))
      setIsLoading(false)
    }
  }

  const handleCancelAdjustment = () => {
    setMaterialToAdjust(false)
  }

  // Sorting the options/variations
  let updatedProjectMaterialsAfterSort = null // Temporarily keep the data that is to be updated after sorting
  const handleSortingEnd = e => {
    if (e.newIndex !== e.oldIndex) {
      const sortedMaterialIds = updatedProjectMaterialsAfterSort.map(({id}) => id)
      updateSortedProjectMaterials(sortedMaterialIds)
    }
  }
  
  // Changes needed for the react-sortable library to work
  // Needed a "chosen" attribute to be mutable
  const sortedSelectedProjectMaterials = Array.isArray(selectedProjectMaterials) ? selectedProjectMaterials.map(spm => ({...spm, chosen: true})) : selectedProjectMaterials
  const setSortedSelectedProjectMaterials = (spm) => {
    updatedProjectMaterialsAfterSort = spm.map(({chosen, ...rest}) => ({...rest}))
    dispatch(setSelectedProjectMaterials(updatedProjectMaterialsAfterSort))
  }

  return (
    <div className={isLoading ? "threedy-skeleton toggle-material-viewer" : "toggle-material-viewer" }>
      { showMenu && <ContextMenuComponent items={showMenu.items} position={showMenu.position} onClose={() => setShowMenu(false)} /> }
      { materialToAdjust && <MaterialAdjusterComponent hideTitle fixed material={materialToAdjust} onCancel={handleCancelAdjustment} onSave={handleSaveAdjustment} /> }
      {selectedProjectMaterials && selectedProjectMaterials.length > 0 && (
        <ReactSortable tag="ul" list={sortedSelectedProjectMaterials} setList={setSortedSelectedProjectMaterials}
          swap
          animation={200}
          delayOnTouchStart={true}
          delay={2}
          handle=".sorting-handle"
          onEnd={handleSortingEnd}
        >
          {selectedProjectMaterials.map((m) => {
            return (
                <li key={m.id} className={materialToPreviewId === m.id ? "variation-item selected" : "variation-item"} onClick={() => handlePreviewMaterial(m.id)}>
                    <span className="sorting-handle">{threeHorizontalLines}</span>
                    <div className="variant-thumb" style={{backgroundImage: `url(${m.thumbnail})`}}></div> 
                    {renamingItem === m.id ? (
                      <EnterKeyInputComponent value={m.name} placeholder="Material..." onValueChange={(val) => handleFinalizeItemNameChange(m.id, val)} />
                    ) : <div className="name-label" onClick={(e) => handleClick(e, m.id)}><span>{m.name ? m.name : <i>Untitled</i>}</span></div>}
                    <div className="button-group">
                      {/* <button disabled={materialToAdjust || showMenu} className={materialToPreviewId === m.id ? "variant-preview-btn selected" : "variant-preview-btn"} onClick={() => handlePreviewMaterial(m.id)}><span className="icon-chevron-right"></span></button>
                      <button disabled={materialToAdjust || showMenu} className="variation-more-btn" onClick={(e) => handleAdjustMaterial(m.id)}><span className="icon-sliders"></span></button> */}
                      <button disabled={materialToAdjust || showMenu} onClick={(e) => handleShowMenu(e, m.id)}><span>{threeVerticalDots}</span></button>
                    </div>
                </li>
            )
          })}
        </ReactSortable>
      )}
      {(!selectedProjectMaterials || selectedProjectMaterials.length < 1) && !isLoading && (<div className="no-materials">
        <h5>No created materials yet...</h5>
      </div>)}
      {isLoading && !selectedProjectMaterials && (<div className="no-materials">
        <div className="threedy-lab-spinner-wrapper small">
          <SpinnerComponent inline />
        </div>
      </div>)}
    </div>
  )
}

export default MaterialViewerComponent