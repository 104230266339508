import { useEffect, useState } from "react"

const EnterKeyInputComponent = (props) => {

  const [workingValue, setWorkingValue] = useState("")

  useEffect(() => {
    setWorkingValue(props.value)
  }, [props.value])

  const handleComplete = () => {
    props.onValueChange(workingValue)
  }

  const handleKeyDown = (e) => {
    const { key, keyCode } = e
    if (key === 'Enter' || keyCode === 13) {
      e.target.blur()
    }
  }

  const handleClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  return (
    <input type="text" className="toggle-text-input" onClick={handleClick} value={workingValue} placeholder={props.placeholder ? props.placeholder : "..."} onKeyDown={handleKeyDown} onBlur={handleComplete} autoFocus onChange={(e) => setWorkingValue(e.target.value)} />
  )
}

export default EnterKeyInputComponent