import { useState } from "react"
import { useSelector } from "react-redux"
import "./element-material-library-category-dropdown.component.css"

const ElementMaterialLibraryCategoryDropdown = (props) => {
  const {category, onSelectCategory} = props
  const {materials} = useSelector((state) => state.ui)
  const [expanded, setExpanded] = useState()

  const handleSelectCategory = (c) => {
    setExpanded(false)
    onSelectCategory(c)
  }

  return (
    <div className="toggle-material-library-categories-dropdown">
      <button className="toggle-material-library-categories-dropdown-btn" onClick={() => setExpanded(!expanded)}><h5>{category ? category.name : "All Categories"} <span className={!expanded ? "icon-expand_more" : "icon-expand_less"} /></h5></button>
      {expanded && (
        <div className="toggle-material-library-categories-expanded">
          <div className="toggle-material-library-categories-expanded-header">
            <h5>Texture Categories</h5>
            <button onClick={() => setExpanded(false)}><span className="icon-close" /></button>
          </div>
          <ul>
            <li className={!category ? "selected" : ""} onClick={() => handleSelectCategory(null)}><button>All Categories</button></li>
            {materials.categories.map((c) => {
              return (
                <li key={c.name} className={category && category.name === c.name ? "selected" : ""}><button onClick={() => handleSelectCategory(c)}>{c.name} {c.id} ({c.materials.length})</button></li>
              )
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default ElementMaterialLibraryCategoryDropdown