import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { image } from "../../assets"
import { DEFAULT_MATERIAL_PROPERTIES, DEFAULT_SPHERE_MAT_ID, DEFAULT_SPHERE_MESH_ID, KLAVIYO_METRICS } from "../../constants"
import { useAddToPersonalCollectionsMutation } from "../../redux/api.redux.slice"
import { addToPersonalCollection, setSelectedProject, setSelectedProjectMaterials, setToast } from "../../redux/ui.redux.slice"
import { createMaterialManifest } from "../../utils/ui.util"
import MaterialColorCreateComponent from "../material-color-create/material-color-create.component"
import MaterialImageCreateComponent from "../material-image-create/material-image-create.component"
import MaterialTextureExpandComponent from "../material-texture-expand/material-texture-expand.component"
import SpinnerComponent from "../spinner/spinner.component"
import "./material-builder.component.css"

const MaterialBuilderComponent = (props) => {

  const workingMaterial = useRef(false)
  const [step, setStep] = useState("initial")
  const [params, setParams] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [addToCollection] = useAddToPersonalCollectionsMutation()
  const selectedProject = useSelector((state) => state.ui.selectedProject)
  const { scene, onDone, onRequestScreenshot, saveMaterialSettings } = props
  const dispatch = useDispatch()

  // Dispose any created materials on load
  useEffect(() => {
    console.log("Dispose created materials")
    scene.materials.forEach((m) => {
      if (m.id !== DEFAULT_SPHERE_MAT_ID) {
        m.dispose(true, true)
      }
    })
  }, [scene])

  // Set working mat on load
  useEffect(() => {
    if (!workingMaterial.current) {
      const sphere = scene.meshes.find((m) => m.id === DEFAULT_SPHERE_MESH_ID)
      const mat = scene.materials.find((m) => m.id === DEFAULT_SPHERE_MAT_ID)

      if (mat && sphere) {
        // Make a clone
        const clone = mat.clone()
        clone.id = clone.name = `${mat.id}-clone-initial`
        workingMaterial.current = {
          raw: clone,
          default: mat,
          sphere: sphere,
          meta: DEFAULT_MATERIAL_PROPERTIES
        }

        sphere.material = clone
      }
    }

    // Handle cloned / working material cleanup
    return () => {
      if (workingMaterial.current.sphere && workingMaterial.current.raw && workingMaterial.current.default) {
        console.log("Dispose working sphere material...")
        workingMaterial.current.sphere.material = workingMaterial.current.default
        workingMaterial.current.raw.dispose(true, true)
      }
    }

  }, [scene])

  if (!selectedProject) {
    return null
  }

  const handleResetMaterial = () => {
    // Reset
    if (workingMaterial.current.raw && workingMaterial.current.sphere && workingMaterial.current.default) {
      console.log("Reset working material")
      workingMaterial.current.sphere.material = workingMaterial.current.default
      workingMaterial.current.raw.dispose(true, true)
      const clone = workingMaterial.current.default.clone()
      clone.id = clone.name = `${workingMaterial.current.default.id}-clone`
      workingMaterial.current.raw = clone
      workingMaterial.current.sphere.material = clone
    }

    setParams({})
    setStep("initial")
  }

  const handleSelectCreateMethod = (method) => {
    setStep(`create.${method}`)
    setParams({...params, method})
  }
  
  const handleBack = () => {

    // Reset sphere
    handleResetMaterial()

    // Go back to initial step
    setStep("initial")
  }

  const handleSaveMaterial = async (material, meta, originAsset = false, referenceMaterial = false) => {
    setIsLoading(true)
    let files = []
    let materials = []
    
    try {
      
      // Set name
      const materialRes = await createMaterialManifest(material, false, true)
      materials = materials.concat(materialRes.manifest)
      materials = materials.map((m) => {
        return {...m, user_visible: true}
      })
      files = files.concat(materialRes.files)
      const formData = new FormData()
      files.forEach((f) => formData.append(f.name, f))
      formData.append('materials', JSON.stringify(materials))
      formData.append('project_id', selectedProject.id)
      formData.append('meta', JSON.stringify(meta))

      if (originAsset) {

        // Add asset
        const originalBlob = await (await fetch(originAsset)).blob()
        const originalAsset = new File([originalBlob], `__toggle__${materialRes.manifest.name}__3d-original-asset.jpg`, {type:"image/jpeg"})
        formData.append('original_asset', originalAsset)
      }

      if (referenceMaterial) {

        // Add reference material
        formData.append('reference_material', JSON.stringify(referenceMaterial))
      }

      // Add
      const record = await addToCollection(formData).unwrap()

      // Close
      onDone()

      const [newMat] = record.data
      dispatch(addToPersonalCollection(newMat))

      // Dispatch
      const updatedMatIds = selectedProject.material_ids && selectedProject.material_ids.length > 0 ? [...selectedProject.material_ids, record.data[0].id] : [record.data[0].id]
      dispatch(setSelectedProject({...selectedProject, material_ids: updatedMatIds}))

      window.klaviyo.track(KLAVIYO_METRICS.created_material)

      // Clear existing selected materials cache
      dispatch(setSelectedProjectMaterials(false))
      
      // Update project screenshot
      onRequestScreenshot()
    }
    catch (e) {
      console.error("Error saving collection: ", e)
      dispatch(setToast({message: "Uh oh, we had an issue saving your material. Please try again", isError: true}))
      setIsLoading(false)
    }
  }

  return (
    <div className={isLoading ? "threedy-skeleton toggle-material-builder" : "toggle-material-builder" }>
      {isLoading && (
        <div className="threedy-lab-spinner-wrapper full-cover medium">
          <SpinnerComponent inline />
        </div>
      )}
      <div className="material-selection-container">
        {/* <div className="build-material-option" onClick={() => handleSelectCreateMethod("color")}>
          <h4>{raindrop}Create From a Color</h4>
          <hr />
          <p>Select a solid color to generate a material</p>
        </div> */}
        <div className="build-material-option" onClick={() => handleSelectCreateMethod("image")}>
          <h4>{image}Create From a Texture</h4>
          <hr />
          <p>Upload a full sized texture to generate a PBR material</p>
        </div>
        <div className="build-material-option" onClick={() => handleSelectCreateMethod("texture")}>
          <h4>{image}Create From a Reference Photo</h4>
          <hr />
          <p>Upload an image from which you want to crop and expand a texture to make a PBR material</p>
        </div>
        {step === "create.color" && (
          <MaterialColorCreateComponent 
            onSave={handleSaveMaterial} 
            showMaterialSelection={handleBack} 
            title="Create From a Color" 
            showPreviewScene />         
        )}
        {step === "create.image" && (
          <MaterialImageCreateComponent 
            onSave={handleSaveMaterial} 
            showMaterialSelection={handleBack} 
            title="Create From a Texture" 
            showPreviewScene 
            onMetaChange={saveMaterialSettings}
          />
        )}
        {step === 'create.texture' && (
          <MaterialTextureExpandComponent 
            onSave={handleSaveMaterial} 
            showMaterialSelection={handleBack}
            title="Create From a Reference Photo" 
          />
        )}
      </div>
    </div>
  )
}

export default MaterialBuilderComponent