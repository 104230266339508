import "./public-configurator-options.component.css"
import { API_BASE_URL, APP_RESOURCES_URL, EVENTS } from "../../constants"
import { useLazyGetPublicMaterialByIdQuery, useLazyGetPublicPersonalMaterialByIdQuery } from "../../redux/api.redux.slice"
import { useDispatch } from "react-redux"
import { setToast } from "../../redux/ui.redux.slice"
import { useEffect, useRef, useState, useCallback } from "react"

const PublicConfiguratorOptionsComponent = (props) => {
  const dispatch = useDispatch()
  const apiCacheRef = useRef({})
  const [expanded, setExpanded] = useState(false)
  const {configurations, onPreviewOption, rawMaterials, defaultCollapsed} = props
  const [getMaterialByIdQuery] = useLazyGetPublicMaterialByIdQuery()
  const [getPersonalMaterialByIdQuery] = useLazyGetPublicPersonalMaterialByIdQuery()

  const handleSelectOption = useCallback(async (item, option) => {

    try {
      const {id, personal, external} = option
      let mat 

      if (apiCacheRef.current[id]) {
        console.log("utilizing cache")
        mat = apiCacheRef.current[id]
      }
      else {
        if (external) {
          if (!personal) {
            mat = await getMaterialByIdQuery(id).unwrap()
          }
          else {
            mat = await getPersonalMaterialByIdQuery(id).unwrap()
          }
        }
        else {
          const targetItemId = item.type === 'group' ? item.parts[0].id : item.id
          if (rawMaterials[targetItemId]) {
            mat = {raw: true, itemId: targetItemId}
          }
        }

        apiCacheRef.current[id] = mat
      }

      if (!mat) {
        dispatch(setToast({message: "Uh oh. We couldn't find the material to select, please try again.", isError: true}))
        return
      }

      // Update url
      const params = new URLSearchParams(window.location.search)
      params.set(`option-${item.id}`, id)
      window.history.replaceState({}, '', `${window.location.pathname}?${params}`)

      // Set material
      onPreviewOption(item, mat)
    }
    catch (e) {
      console.error("Error previewing option: ", e)
      dispatch(setToast({message: "Uh oh. We couldn't find the material to select, please try again.", isError: true}))
    }
  }, [dispatch, getMaterialByIdQuery, getPersonalMaterialByIdQuery, onPreviewOption, rawMaterials])

  // Set option after scene loads
  useEffect(() => {
    const setOptions = async () => {
      const params = new URLSearchParams(window.location.search)
      const entries = Array.from(params.entries())
      for (let i = 0; i < entries.length; i++) {
        const [key, val] = entries[i]
        if (key.indexOf("option-") > -1) {
          const configId = key.replace("option-", "")
          const config = configurations.find((c) => c.id === configId)
          if (config) {
            const option = config.options.find((o) => o.id === val)
            if (option) {
              console.log("Set option: ", config.id, option.id)
              await handleSelectOption(config, option)
            }
          }
        }
      }

      // Dispatch scene configured event
      document.dispatchEvent(new CustomEvent(EVENTS.SCENE_CONFIGURED))
    }

    // Set expanded
    setExpanded(defaultCollapsed ? false : true)
    document.addEventListener(EVENTS.SCENE_LOADED, setOptions)
    return () => document.removeEventListener(EVENTS.SCENE_LOADED, setOptions)

  }, [configurations, handleSelectOption, defaultCollapsed])

  return (
    <div className="toggle-public-configurator-options">
      {expanded && (
        <ul>
          {configurations.map((config) => {
            return (
              <li key={config.id} className="toggle-option">
                <h4>{config.name}</h4>
                <ul>
                  {config.options.map((o, i) => {
                    let src
                    let thumbClassName = "option-thumb"
                    let optionClassName = ""
                    
                    if (props.selectedOptions && props.selectedOptions[config.id] && props.selectedOptions[config.id] === o.id) {
                      optionClassName = "selected"
                    }
                    else if (props.selectedOptions && !props.selectedOptions[config.id] && i === 0) {
                      optionClassName = "selected"
                    }

                    if (o.external) {
                      if (o.personal) {
                        src = `${API_BASE_URL}public/collections/${o.id}/thumb`
                      }
                      else {
                        src = `${APP_RESOURCES_URL}material-thumbs/${o.id}/thumb.png`
                        thumbClassName += " matlib"
                      }
                    }
                    else {
                      src = o.thumbnail
                    }
                    return  (
                      <li key={o.id} className={optionClassName} onClick={() => handleSelectOption(config, o)}>
                        {src && <div className={thumbClassName} style={{backgroundImage: `url(${src})`}}></div>}
                        {!src && <div className="option-thumb no-thumb"></div>}
                        <h5>{o.name}</h5>
                      </li>
                    )
                  })}
                </ul>
              </li>
            )
          })}
        </ul>
      )}
      {expanded && <button className="toggle-expand-btn" onClick={() => setExpanded(false)}><span className="icon-x"></span></button> }
      {!expanded && <button className="toggle-expand-btn expand-btn" onClick={() => setExpanded(true)}><span className="icon-chevron-down"></span> Show Options</button> }
    </div>
  )
}

export default PublicConfiguratorOptionsComponent