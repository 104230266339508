import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { createConfigurator, createMaterial, threeDModelTemplateImage, variationsTemplate } from '../../assets'
import { KLAVIYO_METRICS, T } from '../../constants'
import { useAddNewProjectMutation } from '../../redux/api.redux.slice'
import { modifyQuota } from '../../redux/auth.redux.slice'
import { setSelectedProject, setToast } from '../../redux/ui.redux.slice'
import HeaderComponent from '../header/header.component'
import FreeLimitExhaustedModalComponent from '../upgrade-to-pro/free-limit-exhausted-modal.component'
import NotAvailableForFreeModalComponent from '../upgrade-to-pro/not-available-for-free-modal.component'
import "./editor.component.css"

const Editor = (props) => {

    const navigate = useNavigate()
    const dispatch = useDispatch()
    const { plan, quota } = useSelector((state) => state.auth)
    const [addProject] = useAddNewProjectMutation()
    const [exhausted, setExhausted] = useState(false)
    const [showFreeLimitExhaustedModal, setshowFreeLimitExhaustedModal] = useState(false)
    const [showNotAvailableForFreeModal, setShowNotAvailableForFreeModal] = useState(false)

    const handleCreateProject = async (projectType) => {
        if (projectType === T.MATERIAL && plan && plan.type !== 'pro') {
            setShowNotAvailableForFreeModal(true)
            return false
        }
        if ([T.CONFIGURATOR, T._3D_MODEL, T.VARIATIONS].includes(projectType) && plan && plan.type !== 'pro' && quota.numProjects >= plan.features.numProjects) {
            setshowFreeLimitExhaustedModal(true)
            return false
        }
        const projectName = projectType === 'material' ? 'New Material' : 'Untitled'
        const params = {name: projectName, project_type: projectType}
        try {
            const res = await addProject(params).unwrap()

            window.klaviyo.track(KLAVIYO_METRICS.created_project)

            // Increment quota
            dispatch(modifyQuota(1))

            // Navigate to project
          navigate(`/editor/${res.data.id}`)
        }
        catch (e) {
          console.log("Error adding project", e)
          dispatch(setToast({message: "Uh oh. We had an issue creating a new project, please try again.", isError: true}))
        }
    }

    // Upon load, we'll clear out the selected project
    useEffect(() => {
        dispatch(setSelectedProject(false))
    }, [dispatch])

    useEffect(() => {
        if (plan && quota && plan.type !== 'pro' && quota.numProjects >= plan.features.numProjects) {
            setExhausted(true)
        }
    }, [plan, quota])

    return (
        <>
        <div className="editor-component-wrapper">
            <HeaderComponent shared={props.shared} publicView={props.publicView} editorView exhausted={exhausted} />
            <div className="threedy-lab-page-content">
                <h2>Pick a template to get started!</h2>
                <div className="templates">
                    <div className="editor-template-wrapper" onClick={() => handleCreateProject('3D model')}>
                        <div className="editor-template">
                            <span>{threeDModelTemplateImage}</span>
                        </div>
                        <h5>Texture a 3D Model</h5>
                    </div>
                    <div className="editor-template-wrapper" onClick={() => handleCreateProject('configurator')}>
                        <div className="editor-template">
                            <span>{createConfigurator}</span>
                        </div>
                        <h5>Build a Configurator</h5>
                    </div>
                    <div className="editor-template-wrapper" onClick={() => handleCreateProject('variations')}>
                        <div className="editor-template">
                            <span className="scale-up">{variationsTemplate}</span>
                        </div>
                        <h5>Create Model Variations</h5>
                    </div>
                    <div className="editor-template-wrapper " onClick={() => handleCreateProject('material')}>
                        <div className={"editor-template " + (plan && plan.type !== 'pro' ? 'locked' : '')}>
                            {(plan && plan.type !== 'pro') && <span className="icon-lock right-top"></span>}
                            <span>{createMaterial}</span>
                        </div>
                        <h5>Create a Material</h5>
                    </div>
                </div>
            </div>
        </div>
            {showFreeLimitExhaustedModal &&
                <FreeLimitExhaustedModalComponent close={() => setshowFreeLimitExhaustedModal(false)} />}
            {showNotAvailableForFreeModal &&
                <NotAvailableForFreeModalComponent close={() => setShowNotAvailableForFreeModal(false)} />}
        </>
    )
}

export default Editor