import { TourProvider } from '@reactour/tour'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useEditUserMutation } from '../../redux/api.redux.slice'
import { login } from '../../redux/auth.redux.slice'
import { setFinishedTours } from '../../redux/ui.redux.slice'
import Session from '../../utils/session.util'
import { doArrow } from '../../utils/ui.util'
import { SAMPLE_TOUR_STEPS } from './sample-tour-step.component'
import './sample-tour.component.css'
  
  const SAMPLE_TOUR_STYLES = {
    popover: (base, state) => ({
      ...base,
      padding: '15px',
      borderRadius: '5px',
      backgroundColor: 'var(--primaryColor)',
      ...doArrow(state.position, state.verticalAlign, state.horizontalAlign),
    }),
    maskWrapper: (base) => ({ ...base, color: 'transparent' }),
    clickArea: (base, { windowWidth, windowHeight, clipID }) => ({
      ...base,
      x: 100 // Expose the sidebar in the sample tour
    }),
    highlightedArea: (base, { x, y, width, height }) => ({
      ...base,
      display: 'block',
      stroke: 'var(--primaryColor)',
      strokeWidth: 2,
      width: width + 8,
      height: height + 8,
      rx: 5,
      x: x - 4,
      y: y - 4,
      pointerEvents: 'none',
    }),
  }
  
  // Disable background click
  const SAMPLE_TOUR_BG_CLICK = (props) => null
  const REACT_TOUR_PADDING = {
    mask: 10, 
    popover: [15, 10], 
    wrapper: 0 
  }
  const observerConfig = {childList: true, subtree: true}

  const isValidSteps = (currentStep) => {
    switch(currentStep) {
      case 1:
        const isGroupCreated = document.querySelector('.part-viewer-container .scrollable-container .grouped-part')
        return isGroupCreated ? true : false

      case 3:
        const isConfiguratorOpened = document.querySelector('.configurator-builder .configurator-builder-flex.configurator-builder-materials')
        return isConfiguratorOpened ? true : false

      case 6:
        const isMaterialSettingsOpened = document.querySelector('.configurator-builder .configurator-builder-flex-container .material-settings-container')
        return isMaterialSettingsOpened ? true : false
      
      default:
        return true
  
    }

  }

  const actionsAfter = (currentStep) => {
    switch(currentStep) {
      case 0:
        const openPartsBtn = document.querySelector('.threedy-lab-workspace-menu.actions-menu .open-parts-btn')
        if (!openPartsBtn.classList.contains('selected')) {
          openPartsBtn.click()
        }
        return
        
        case 6:
          document.querySelector('.threedy-lab-workspace-menu.actions-menu .open-lighting-btn').click()
          document.querySelector('.configurator-builder-flex.configurator-builder-materials .material-actions button:last-child').click()
          return

        case 7:
          document.querySelector('.threedy-lab-workspace-menu.actions-menu .open-parts-btn').click()
          return

        default:
          return

    }
  }

  const ContentComponent = (props) => {
    const { currentStep, steps, setIsOpen, setCurrentStep } = props
    const [updateUserRequest] = useEditUserMutation()
    const dispatch = useDispatch()
    const {user} = useSelector((state) => state.auth)
    const [disableNext, setDisableNext] = useState(false)

    const isLastStep = currentStep === steps.length - 1
    const content = steps[currentStep].content

    useEffect(() => {
      // Validate on each step
      setDisableNext(!isValidSteps(currentStep))

      const handleInteractions = () => {
        if (currentStep === 1) {
          const isGroupCreated = document.querySelector('.part-viewer-container .scrollable-container .grouped-part')
          setDisableNext(isGroupCreated ? false : true)
          return
        }
        if (currentStep === 3) {
          const isConfiguratorOpened = document.querySelector('.configurator-builder .configurator-builder-flex.configurator-builder-materials')
          setDisableNext(isConfiguratorOpened ? false : true)
          return
        }
        if (currentStep === 6) {
          const isMaterialSettingsOpened = document.querySelector('.configurator-builder .configurator-builder-flex-container .material-settings-container')
          setDisableNext(isMaterialSettingsOpened ? false : true)
        }
      }
      
      // Observe Step Interactions on each step
      const currentObserver = new MutationObserver(handleInteractions);
      const observeStepInteractions = (currentStep) => {
        currentObserver.disconnect()
        let observerTarget = null
        if (currentStep === 1) {
          observerTarget = document.querySelector('.part-viewer-container .scrollable-container')
          currentObserver.observe(observerTarget, observerConfig);
          return
        }
        if (currentStep === 3) {
          observerTarget = document.querySelector('.threedy-lab-page')
          currentObserver.observe(observerTarget, observerConfig);
          return
        }
        if (currentStep === 6) {
          observerTarget = document.querySelector('.threedy-lab-page')
          currentObserver.observe(observerTarget, observerConfig);
        }
      }

      observeStepInteractions(currentStep)
    
      return () => {
        currentObserver.disconnect()
      }
    }, [currentStep])
    

    const updateFinishedTours = async (tours) => {
      try {
        const md = user['https://thdy/user_md'] ? {...user['https://thdy/user_md']} : {}
        md.finishedTours = tours
  
        const params = {user_metadata: md}
        await updateUserRequest({body: params}).unwrap()
  
        // Update session
        Session.getAccessTokenFromRefreshToken().then((res) => {
          const { access_token, user } = res
          dispatch(login({user, access_token}))
        }, (e) => {
          console.log('Error updating session: ', e)
        })
      } 
      catch (e) {
        console.log("Error updating user: ", e)
  
        // We won't show the user as it is not something they should worry about
      }
    }

    const finishAndUpdateTourStatus = () => {
      setIsOpen(false)
      setCurrentStep(0)
      dispatch(setFinishedTours(['TOUR2'])) // Finish Tour2
      const validateFinishedTours = new Set([...(user['https://thdy/user_md'].finishedTours || []), 'TOUR2'])
      updateFinishedTours([...validateFinishedTours]) // Update in the backend
    }
  
    const moveNext = () => {

      actionsAfter(currentStep)
  
      if (isLastStep) {
        finishAndUpdateTourStatus()
      } else {
        setCurrentStep((s) => (s === steps.length - 1 ? 0 : s + 1))
      }
    }
    
    const renderContent = () => {
      if (typeof content === 'function') {
        return content({ ...props })
      }
      return content
    }
  
    return (
      <>
        {/* Check if the step.content is a function or a string */}
        {renderContent()}
        <div style={{display: 'flex', justifyContent: isLastStep ? 'flex-end' : 'space-between', paddingTop: '10px'}}>
          {
            !isLastStep && (
              <button onClick={finishAndUpdateTourStatus} className="reactTour-skip-btn text-white">
                Skip Tour
              </button>
            )
          }
          <button onClick={moveNext} className="reactTour-next-btn" disabled={disableNext}>
            {isLastStep ? 'Done' : 'Next'}
          </button>
        </div>
      </>
    )
  }

const SampleTourComponent = ({children}) => {

  return (
    <TourProvider
      steps={SAMPLE_TOUR_STEPS}
      ContentComponent={ContentComponent}
      styles={SAMPLE_TOUR_STYLES}
      onClickMask={SAMPLE_TOUR_BG_CLICK}
      padding={REACT_TOUR_PADDING}
      disableKeyboardNavigation={true}
    >
      {children}
    </TourProvider>
  )
}

export default SampleTourComponent