import {useEffect, useRef} from 'react'

const useRefPanelPosOnWindowResized = (defaultPos, setPosition) => {
    const timerRef = useRef(null)

    useEffect(() => {
        const onWindowResized = () => {
          if (timerRef.current) {
              clearTimeout(timerRef.current)
          }
          timerRef.current = setTimeout(() => {
              defaultPos.current = {x: window.innerWidth - 300, y: window.innerHeight - 55}
              setPosition({x: window.innerWidth - 455, y: window.innerHeight - 285, width: 300, height: 240})
          }, 500)
        }
      
        window.addEventListener('resize', onWindowResized)
        return () => {
          window.removeEventListener('resize', onWindowResized)
          clearTimeout(timerRef.current)
        }
    }, [setPosition, defaultPos])
}

export default useRefPanelPosOnWindowResized