import { useSelector, useDispatch } from "react-redux"
import { useEditUserMutation } from "../../../redux/api.redux.slice"
import Session from "../../../utils/session.util"
import { login } from "../../../redux/auth.redux.slice"
import { setToast } from "../../../redux/ui.redux.slice"
import ToggleButtonComponent from "../../../components/forms/toggle-button.component"

const PreferencesSettingsSection = () => {
  const user = useSelector((state) => state.auth.user)
  const dispatch = useDispatch()

  const [updateUserRequest] = useEditUserMutation()

  const isDarkMode = user && user['https://thdy/user_md'] && user['https://thdy/user_md'].darkMode
  const handleToggleDarkMode = async () => {
    try {
      const newMeta = { ...user['https://thdy/user_md'], darkMode: !isDarkMode }
      const params = {user_metadata: newMeta}
      await updateUserRequest({body: params}).unwrap()

      Session.getAccessTokenFromRefreshToken().then((res) => {
        const { access_token, user } = res
        dispatch(login({user, access_token}))
      }, (e) => {
        console.log('Error updating session: ', e)
      })
    } 
    catch (e) {
      console.log("Error updating user: ", e)
      dispatch(setToast({message: "Uh oh. We had an issue updating your preferences, please try again.", isError: true}))
    }
  }

  return (
    <ToggleButtonComponent onClick={handleToggleDarkMode} isToggleOn={isDarkMode} inlineText="Dark Mode" />
  )
}

export default PreferencesSettingsSection