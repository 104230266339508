import UpgradeToProModalComponent from "./upgrade-to-pro-modal.component"

const FreeLimitExhaustedModalComponent = (props) => {

  const modalContent = (
    <>
      <p class="content-text">You have exhausted your projects. Upgrade to PRO?</p>
    </>
  )
  return (
    <UpgradeToProModalComponent
      close={() => props.close()}
      modalContent={modalContent}
    />
  )
}

export default FreeLimitExhaustedModalComponent