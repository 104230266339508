import { useEffect, useRef } from 'react'
import './spinner.component.css'
import gsap from 'gsap'

const SpinnerComponent = (props) => {

  const logoRef = useRef()
  const intervalRef = useRef()

  useEffect(() => {
    const rightMain = logoRef.current.querySelector(".right-main")
    const leftMain = logoRef.current.querySelector(".left-main")
    const topTail = logoRef.current.querySelector(".top-tail")
    const rightTail = logoRef.current.querySelector(".right-tail")
    const leftTail = logoRef.current.querySelector(".left-tail")

    const animateIn = () => {
      gsap.to([rightMain, leftTail], {
        ease: "none",
        strokeDashoffset: 0,
        duration: 0.5
      })

      gsap.to([leftMain, topTail], {
        ease: "none",
        strokeDashoffset: 0,
        duration: 0.4,
        delay: 0.4,
        onComplete: () => {
          gsap.to([topTail, leftMain], {
            fill: "#571FFF",
            duration: 0.3
          })

          gsap.to([leftTail, rightTail, rightMain], {
            fill: "#A12AFC",
            duration: 0.2,
            delay: 0.2
          })
        }
      })

      gsap.to(rightTail, {
        ease: "none",
        strokeDashoffset: 0,
        duration: 0.5,
        delay: 0.2
      })

      // Animate out 
      setTimeout(animateOut, 2000)
    }

    const animateOut = () => {
      gsap.to([topTail, leftMain], {
        fill: "none",
        duration: 0
      })

      gsap.to([leftTail, rightTail, rightMain], {
        fill: "none",
        duration: 0,
        delay: 0.1
      })

      gsap.to([rightMain, leftTail], {
        ease: "none",
        strokeDashoffset: 250,
        duration: 0.3
      })

      gsap.to([leftMain, topTail], {
        ease: "none",
        strokeDashoffset: 250,
        duration: 0.3,
        delay: 0.2
      })

      gsap.to(rightTail, {
        ease: "none",
        strokeDashoffset: 250,
        duration: 0.3,
        delay: 0.3
      })
    }

    animateIn()
    intervalRef.current = setInterval(animateIn, 3500)

    return () => {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }

  }, [])

  return (
    <div className={props.inline ? "threedy-lab-spinner-container inline" : "threedy-lab-spinner-container"}>
      <div className="toggle-animated-logo" ref={logoRef}>
        <svg version="1.0" id="Layer_1" x="0px" y="0px" viewBox="0 0 270 180">
          <polyline className="svg-edge right-main" points="127.2,137.3 147.8,124.9 147.8,81.4 127.2,69.2 127.2,137.3 "/>
          <polygon className="svg-edge right-tail" points="158.7,74.8 180.3,61.8 180.3,87.2 158.7,99.8 "/>
          <polyline className="svg-edge left-tail" points="95.8,74.8 74.1,61.7 74.1,87.3 95.8,99.9 95.8,74.8 "/>
          <polygon className="svg-edge top-tail" points="158.7,74.8 127.2,56.1 95.8,74.8 74.1,61.7 127.2,31.1 180.3,61.8 "/>
          <polygon className="svg-edge left-main" points="127.2,69.2 106.6,81.5 106.6,124.9 127.2,137.3 "/>
        </svg>
      </div>
    </div>
  )
}

export default SpinnerComponent