import { useEffect, useState } from "react"
import { useCreateUserMutation, useLazyCreateUserPaymentIntentQuery, useRefreshCodeForUserMutation, useVerifyUserMutation } from "../../redux/api.redux.slice"
import { useDispatch } from "react-redux"
import jwtDecode from "jwt-decode"
import { login } from "../../redux/auth.redux.slice"
import { useNavigate, Link, createSearchParams } from "react-router-dom"
import Session from "../../utils/session.util"
import moment from "moment"
import { KLAVIYO_METRICS, APP_RESOURCES_URL, IS_DEBUG_ENV, MOBILE_WINDOW_HEIGHT, MOBILE_WINDOW_WIDTH, STRIPE_PLANS, T } from "../../constants"
import SpinnerComponent from "../../components/spinner/spinner.component"
import { Elements } from "@stripe/react-stripe-js"
import CheckoutFormComponent from "../../components/plan-modal/checkout.component"
import PricingComponent from "../../components/pricing/pricing.component"
import { setToast } from "../../redux/ui.redux.slice"
import "./sign-up.page.css"
import SplashPage from "../splash/splash.page"
import GoogleLoginButtonComponent from "../../components/forms/google-login-button.component"
import HorizontalOrDividerComponent from "../../components/forms/horizontal-or-divider.component"

const SignUpPage = (props) => {
  const [tab, setTab] = useState('initial')
  const [newUser, setNewUser] = useState({ email: '', password: '', name: '', position: '', company_name: '' })
  const [verificationCode, setVerificationCode] = useState("")
  const [error, setError] = useState(false)
  const [showMobileMenu, setShowMobileMenu] = useState(false)
  const [canRequestNewCode, setCanRequestNewCode] = useState(false)
  const [passwordValid, setPasswordValid] = useState(true)
  const [termsChecked, setTermsChecked] = useState(false)
  const [verifyError, setVerifyError] = useState(false)
  const [tempCreds, setTempCreds] = useState(false)
  const [createUser, { isLoading }] = useCreateUserMutation()
  const [getIntent, { isLoading: isIntentLoading }] = useLazyCreateUserPaymentIntentQuery()
  const [verifyUser, { isLoading: isVerifyLoading }] = useVerifyUserMutation()
  const [refreshCode, { isLoading: isCodeLoading }] = useRefreshCodeForUserMutation()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [clientSecret, setClientSecret] = useState(false)
  const [redirectParams, setRedirectParams] = useState({})
  const [isInternal, setIsInternal] = useState(false)

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.get("r") && params.get("r") === "t3d") {
      setIsInternal(true)
    }
    if (window.innerWidth <= MOBILE_WINDOW_WIDTH || window.innerHeight <= MOBILE_WINDOW_HEIGHT) {
      setRedirectParams((r) => { return { ...r, isMobile: "yes" } })
    }
  }, [])

  const handleInputUpdate = (target) => {
    setNewUser({ ...newUser, [target.name]: target.value })

    if (target.name === 'password') {
      validate(target.value)
    }
  }

  const validate = (val) => {

    // Validate lowercase
    const lowerCaseLetters = /[a-z]/g;
    if (!val.match(lowerCaseLetters)) {
      setPasswordValid(false)
      return
    }

    // Validate capital letters
    const upperCaseLetters = /[A-Z]/g;
    if (!val.match(upperCaseLetters)) {
      setPasswordValid(false)
      return
    }

    // Validate numbers
    const numbers = /[0-9]/g;
    if (!val.match(numbers)) {
      setPasswordValid(false)
      return
    }

    // Validate special chars
    const specialChars = /[$&+,:;=?@#|'<>.^*()%!-]/g;
    if (!val.match(specialChars)) {
      setPasswordValid(false)
      return
    }

    // Validate length
    if (val.length < 8) {
      setPasswordValid(false)
      return
    }

    setPasswordValid(true)
  }

  const handleCreateUser = async () => {
    try {
      const newUserRes = await createUser(newUser).unwrap()
      const { tokens } = newUserRes.data.create
      if (tokens) {
        const user = jwtDecode(tokens.id_token)
        setTempCreds({ tokens, user })
        // Track with Klaviyo
        window.klaviyo.identify({ $email: newUser.email, $first_name: newUser.name }).then(() => {
          console.log('Identify has been completed')
          window.klaviyo.track(KLAVIYO_METRICS.signup_wo_verification)
        });
        // Continue to verification
        setError(false)
        setTab("verification")
      }
      else {
        console.error("Not enough data to proceed")
        setError("Uh oh. We had an issue trying to create your account. Please try again")
      }
    }
    catch (e) {
      console.error("Error creating user: ", e)

      if (e.originalStatus === 409) {
        console.log("Email already in use")
        setError("Uh oh. Looks like this email is already in use.")
      }
      else {
        dispatch(setToast({ message: "Uh oh. We had an issue creating your account, please try again.", isError: true }))
      }
    }
  }

  const handleSelectPlan = (selectedPlan) => {
    // For free, we'll continue to dashboard
    // Otherwise, we'll gather their payment info
    if (selectedPlan === 'free') {
      window.klaviyo.identify({ $email: newUser.email, Plan: 'Free' })
      navigate({
        pathname: '/dashboard',
        search: `?${createSearchParams(redirectParams)}`,
      });
      return
    }

    setTab("payment")

    try {
      // The user should have a plan at this point
      // Create user in backend with valid plan
      setTimeout(async () => {
        const proPlan = STRIPE_PLANS.find((p) => p.type === 'pro')
        const intentRes = await getIntent({ item: proPlan.id }).unwrap()
        window.klaviyo.identify({ $email: newUser.email, Plan: 'Pro' }).then(() => {
          window.klaviyo.track(KLAVIYO_METRICS.signup_with_pro_verification)
        })
        setClientSecret(intentRes.client_secret)
      }, 2000)
    }
    catch (e) {
      window.klaviyo.identify({ $email: newUser.email, Plan: 'Free' })
      console.log(e);
      setError(e)
    }
  }

  const handleVerifyUser = async () => {
    setVerifyError(false)
    setCanRequestNewCode(false)

    try {
      // Verify user
      await verifyUser({
        email: newUser.email,
        code: verificationCode
      }).unwrap()

      window.klaviyo.track(KLAVIYO_METRICS.signup_and_verified)
      window.klaviyo.track(KLAVIYO_METRICS.welcome_email)
      // Login
      const { access_token, refresh_token, id_token, expires } = tempCreds.tokens
      Session.write({ id_token, access_token, refresh_token, exp: moment().add(expires, "s").valueOf() })
      dispatch(login({ user: tempCreds.user, access_token, refresh_token }))

      // For non debug/test we'll fire conversion events
      if (!IS_DEBUG_ENV) {
        // Fire Google event
        if (window.gtag) {
          window.gtag('event', 'conversion', { 'send_to': 'AW-657375758/km6kCNicpYcYEI6Eu7kC' })
        }

        // Add FB code
        if (window.fbq) {
          window.fbq('track', 'CompleteRegistration')
        }
      }

      // Move onto plan
      setTab("plan")
    }
    catch (e) {
      console.log(e);
      console.error("Error verifying user: ", e)

      if (e.data && e.data.data && e.data.data.canRenew) {
        setVerifyError("Uh oh. It looks like your code has expired - please request a new code.")
        setCanRequestNewCode(true)
      }
      else {
        setVerifyError("Uh oh. We had an error verifying your account. Please check your code and try again.")
      }
    }
  }

  const handleRequestNewCode = async () => {
    setVerifyError(false)

    try {
      // Refresh code
      await refreshCode({
        email: newUser.email,
        code: verificationCode
      }).unwrap()

      // Once we're done, we'll reset
      setCanRequestNewCode(false)
      setVerificationCode("")
    }
    catch (e) {
      console.error("Error requesting new code: ", e)
      dispatch(setToast({ message: "Uh oh, we had an issue generating a new code. Please try again.", isError: true }))
    }
  }

  const options = {
    clientSecret,
    appearance: {
      theme: 'stripe' // use "night" for dark
    }
  };
  const afterLogin = (tokens) => {
    if (tokens) {
      const user = jwtDecode(tokens.id_token)
      // Login
      const { access_token, refresh_token, id_token, expires } = tokens
      Session.write({ id_token, access_token, refresh_token, exp: moment().add(expires, "s").valueOf() })
      dispatch(login({ user: user, access_token, refresh_token }))
      setTab("plan")
    }
  }

  const googleAuthError = (message) => {
    return dispatch(setToast({ message, isError: true }))
  }

  const modalContent = (
    <div className={isLoading ? "threedy-skeleton toggle-sign-up-form" : "toggle-sign-up-form"}>
      {tab === 'initial' && (
        <>
          <h5 className="sub-heading">Get started absolutely free. Forever.</h5>
          <GoogleLoginButtonComponent btnClass="large-button" mode={T.SIGNUP} afterGoogleLogin={afterLogin} setError={googleAuthError} />
          <HorizontalOrDividerComponent className={`m-30`} />
          <div className="threedy-lab-text-input" id="signup-form">
            <h6>Name:</h6>
            <input name="name" type="text" autoFocus placeholder="My name..." value={newUser.name} onChange={(e) => handleInputUpdate(e.target)} />
          </div>
          <div className="threedy-lab-text-input">
            <h6>Position:</h6>
            <input name="position" type="text" placeholder="My position..." value={newUser.position} onChange={(e) => handleInputUpdate(e.target)} />
          </div>
          <div className="threedy-lab-text-input">
            <h6>Company Name:</h6>
            <input name="company_name" type="text" placeholder="My company name..." value={newUser.company_name} onChange={(e) => handleInputUpdate(e.target)} />
          </div>
          <div className="threedy-lab-text-input">
            <h6>Email:</h6>
            <input name="email" type="email" placeholder="My email..." value={newUser.email} onChange={(e) => handleInputUpdate(e.target)} />
          </div>
          <div className="threedy-lab-text-input">
            <h6>Password:</h6>
            <input className={!passwordValid ? "error-border" : ""} name="password" type="password" placeholder="Password..." value={newUser.password} onChange={(e) => handleInputUpdate(e.target)} />
          </div>
          <div className="threedy-lab-text-input">
            <h6 className="terms-conditions"><input type="checkbox" value={termsChecked} onClick={() => setTermsChecked(!termsChecked)} /> By signing up, you are agreeing to Nextech’s <a href="https://www.nextechar.com/ar-solutions-terms" target="_blank" rel="noreferrer">Terms and Conditions</a></h6>
          </div>
          {error && <h6 className="error">{error}</h6>}
          {!passwordValid && <h6 className="error">Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character and at least 8 characters long</h6>}
          <div className="toggle-sign-up-actions">
            <Link to="/" disabled={isLoading}>Cancel</Link>
            <button className="toggle-primary-btn rectangular large-button" disabled={isLoading || !termsChecked || !passwordValid || !newUser.name || !newUser.position || !newUser.email} onClick={handleCreateUser}>Continue</button>
          </div>
        </>
      )}
      {tab === 'verification' && (
        <>
          <h5 className="mt-10 mb-10">Almost there. Time to verify your email</h5>
          <p>Please check your email for an activation code from <strong>hey@mail.toggle3d.com</strong></p>
          <div className="threedy-lab-text-input">
            <h6>Activation Code:</h6>
            <input type="text" placeholder="Code" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />
          </div>
          {verifyError && <div className="error">{verifyError}</div>}
          {canRequestNewCode && <button className="toggle-secondary-btn centered" disabled={!verificationCode || isVerifyLoading || isCodeLoading} onClick={handleRequestNewCode}>Request new code</button>}
          <div className="toggle-sign-up-actions">
            <button className="toggle-primary-btn rectangular large-button" disabled={!verificationCode || isVerifyLoading || canRequestNewCode} onClick={handleVerifyUser}>Verify</button>
          </div>
        </>
      )}
      {tab === 'plan' && (
        <>
          <h6 className="mt-10 mb-10 text-center">Select the plan that fits you</h6>
          <PricingComponent hideHeader hideSubheader onSelectPlan={handleSelectPlan} />
        </>
      )}
      {tab === 'payment' && (
        <>
          {(isIntentLoading || !clientSecret) ? (
            <>
              <h4 className="mt-20">Loading...</h4>
              <div className="threedy-lab-spinner-wrapper">
                <SpinnerComponent inline />
              </div>
              {error && <h5 className="error">{error}</h5>}
            </>
          ) : (
            <div className="mt-20">
              <Elements options={options} stripe={props.stripePromise}>
                <CheckoutFormComponent redirect="dashboard" />
              </Elements>
            </div>
          )}
        </>
      )}
    </div>
  )

  const handleScrollTop = () => {
    setShowMobileMenu(false)
    const el = document.querySelector(".toggle-splash")
    el.scrollIntoView({ behavior: 'smooth', block: 'start' })
  }

  return (
    <div className={isInternal ? "toggle-splash toggle-sign-up" : "toggle-splash toggle-sign-up toggle-sign-up-external"}>
      <header>
        <ul>
          <li className='header-logo'>
            <div>
              <a href="/"><img src="/images/Toggle3D_Horizontal_Dark.png" alt="Toggle3D" /></a>
            </div>
            <div className="desktop-only">
              <span className="header-toggle-public-beta">public beta</span>
            </div>
          </li>
          <li className="desktop-only login ml-auto"><Link to="/login">Log In</Link></li>
          <li className="desktop-only sign-up"><button className="toggle-primary-btn rectangular medium-button" onClick={handleScrollTop}>Get Started {!isInternal && "For Free"}</button></li>
          <li className="mobile-only"><button onClick={() => setShowMobileMenu(true)}><span className="icon-menu"></span></button></li>
        </ul>
        {showMobileMenu && (
          <div className="toggle-mobile-menu">
            <button className="mobile-close-btn" onClick={() => setShowMobileMenu(false)}><span className="icon-x"></span></button>
            <ul>
              <li><Link to="/login">Log In</Link></li>
              <li><button className="toggle-primary-btn rectangular medium-button" onClick={handleScrollTop}>Get Started {!isInternal && "For Free"}</button></li>
            </ul>
          </div>
        )}
      </header>
      <div className="toggle-sign-up-content">
        {!isInternal && (
          <div className="toggle-sign-up-feature">
            <h1>Toggle3D is your web-based design studio for creating, customizing, and publishing in 3D made easy for the whole team.</h1>
            <img src={`${APP_RESOURCES_URL}splash/car-model.png`} alt="rendered car" />
          </div>
        )}
        <div className={(tab !== 'plan' ? "toggle-sign-up-div" : "toggle-sign-up-div plan")}>
          <h5 className="heading">Create your Toggle3D account</h5>
          {modalContent}
        </div>
      </div>
      {!isInternal && tab === 'initial' && <SplashPage embeddedInSignUp excludeHeader />}
    </div>
  )
}

export default SignUpPage