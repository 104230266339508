import moment from "moment"
import EnterKeyInputComponent from "../enter-key-input/enter-key-input.component"
import { getProjectIcon } from "../../utils/ui.util"
import { T } from "../../constants"

const MaterialTileComponent = (props) => {
  const { material, editable, editedUploadId, onUpdateName, onMoreClick } = props

  return (
    <li key={material.id} className="no-action background-grayed-dark">
      <div className="grid-container">
        <div className="grid-thumb">
          <img src={material.thumbnail} alt={material.name} />
        </div>
        <div className="grid-action">
          <div className="grid-info">
            {editedUploadId !== material.id && <h5>{material.name}</h5>}
            {editable && editedUploadId === material.id && (
              <EnterKeyInputComponent value={material.name} onValueChange={onUpdateName} />
            )}
            <h6>{<>{getProjectIcon(T.MATERIAL)} Material</>}</h6>
            <h6>{material.updated_ts ? `Last updated ${moment(material.updated_ts).fromNow()}` : `Created ${moment(material.created_ts).fromNow()}`}</h6>
          </div>
          {editable && <button className="more-btn" onClick={(e) => onMoreClick(e, material.id)}><span className="icon-more-vertical"></span></button>}
        </div>
      </div>
    </li>
  )
}

export default MaterialTileComponent