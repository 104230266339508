import { useNavigate } from "react-router-dom"
import ModalComponent from "../modal/modal.component"

const ExportsPageNavigateModal = (props) => {
    const navigate = useNavigate()

    const modalContent = (
        <>
          <p>Your export has been kicked off! When it's ready, it will automatically download.</p>
          <p>In the meantime, you can continue working on your project or wait for your export to finish. It will only take a few moments! Keep track of it in the Notifications Page.</p>
        </>
      )

    const actions = (
        <>  
            <button className="toggle-default-btn" onClick={() => props.close()}>Continue working</button>
            <button className="toggle-primary-btn" onClick={() => navigate('/projects?d=exp')}>See export progress</button>
        </>
    )
    return (
        <ModalComponent 
            title="Export in Progress"
            close={() => props.close()}
            content={modalContent}
            className="medium"
            actions={actions} />
    )
}

export default ExportsPageNavigateModal