import { useNavigate } from "react-router-dom"
import DropdownLinksComponent from "../../dropdown-links/dropdown-links.component"
import { useDispatch, useSelector } from "react-redux"
import { useCallback, useEffect, useState } from "react"
import FreeLimitExhaustedModalComponent from '../../upgrade-to-pro/free-limit-exhausted-modal.component'
import { EVENTS, HISTORY_EVENT_TYPES, MAX_PROJECT_NAME, T } from "../../../constants"
import {useAddNewProjectMutation, useEditProjectMutation} from "../../../redux/api.redux.slice"
import { modifyQuota } from "../../../redux/auth.redux.slice"
import { setSelectedProject, setToast, showProjectSharingModal } from "../../../redux/ui.redux.slice"
import { undo, redo, addToHistory } from "../../../redux/history.redux.slice"
import MiscHelperMethods from "../../../pages/workspace/helper-methods/misc.helpers"
import ExportModalComponent from "../../export-modal/export-modal.component"
import ModelImportModalComponent from "../../model-import-modal/model-import-modal.component"
import ExportsPageNavigateModal from "../../alerts/exports-page-navigator-modal.component"
import EnterKeyInputComponent from "../../enter-key-input/enter-key-input.component"
import moment from "moment"

const ProjectHeader = (props) => {
  const navigate = useNavigate()
  const {project} = props
  const dispatch = useDispatch()
  const [addProject] = useAddNewProjectMutation()
  const [updateProject] = useEditProjectMutation()
  const {plan, quota} = useSelector((state) => state.auth)
  const {projectSaving} = useSelector((state) => state.ui)
  const {queue, redoQueue} = useSelector((state) => state.history)
  const [showFreeWarning, setShowFreeWarning] = useState()
  const [showModelImport, setShowModelImport] = useState()
  const [showExport, setShowExport] = useState()
  const [showExportStartedAlert, setShowExportStartedAlert] = useState()
  const [newProjectName, setNewProjectName] = useState()
  const [isEditingProjectName, setIsEditingProjectName] = useState()

  const menuItems = [
    {id: "back-to-projects", label: "Back to Projects", icon: 'icon-arrow_forward', highlight: true, onSelect: () => navigate('/projects')},
    {id: "file", label: "File", rightIcon: 'icon-arrow_forward_ios', subItems: [
      {id: "new-project", label: "New Project", onSelect: () => handleCreateNewProject()}, 
      {id: "open-new-tab", label: "Open in New Tab", onSelect: () => handleOpenNewTab()},
      {id: "dupe", label: "Duplicate", onSelect: () => handleDuplicate()},
      {id: "export", label: "Export", disabled: !project.model_id || project.model_status !== 'ready', onSelect: () => handleExport()},
    ]},
    {id: "import", label: "Import", rightIcon: 'icon-arrow_forward_ios', subItems: [
      {id: "new-file", label: "New 3D File", onSelect: () => handleImport()},
    ]},
  ]

  const iconMenuBtn = (
    <div className="toggle-project-header-dropdown">
      <img src="/images/Toggle3D_Icon.png" alt="Toggle3D" />
      <span className="icon-expand_more" />
    </div>
  )

  const hasQuota = useCallback(() => {
    if (!plan || !quota) { return false}

    return quota && plan && quota.numProjects < plan.features.numProjects

  }, [plan, quota])

  const handleCreateNewProject = async () => {
    if (!hasQuota()) {
      setShowFreeWarning(true)
      return 
    }

    const project_type = T._3D_MODEL
    const name = 'Untitled'
    const params = {name, project_type}
    
    try {
      const res = await addProject(params).unwrap()

      // Increment quota
      dispatch(modifyQuota(1))

      // Load project window
      window.open(`/editor/${res.data.id}`, '_self')
    }
    catch (e) {
      console.log("Error creating project", e)
      dispatch(setToast({message: "Uh oh. We had an issue creating a new project, please try again.", isError: true}))
    }
  }

  const handleOpenNewTab = () => {
    window.open(`/editor/${project.id}`, "_blank")
  }

  const handleDuplicate = async () => {
    if (!hasQuota()) {
      setShowFreeWarning(true)
      return
    }

    // Make a copy of selected project 
    const copy = MiscHelperMethods.copyAndSanitizeDuplicateProject(project)

    // Create
    try {
      const res = await addProject(copy).unwrap()

      // Reset selected project
      dispatch(setSelectedProject(false))

      // Increment quota
      dispatch(modifyQuota(1))

      // Navigate to new project
      navigate(`/editor/${res.data.id}`)

      // Show toast
      setTimeout(() => {
        dispatch(setToast({message: "Your project has been duplicated. You are now working in the duplicated file"}))
      }, 3000)
    }
    catch (e) {
      dispatch(setToast({message: "Uh oh. We had an issue duplicating your project, please try again.", isError: true}))
    }
  }

  const handleExport = () => {
    setShowExport(true)
  }

  const handleImport = () => {
    setShowModelImport(true)
  }

  const handleShare = () => {
    dispatch(showProjectSharingModal())
  }

  const handleUndo = () => {
    dispatch(undo())
  }

  const handleRedo = () => {
    dispatch(redo())
  }

  const handleProjectNameDoubleClick = () => {
    if (props.shared) {
      return
    }

    setNewProjectName(project.name)
    setIsEditingProjectName(true)
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: false}}))
  }

  const handleProjectNameChange = async (newName) => {
    setIsEditingProjectName(false)
    setNewProjectName("")
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: true}}))

    if (newName === project.name) { return }

    // Update project
    try {
      const updates = {name: newName}
      await updateProject({projectId: project.id, body: updates}).unwrap()
      dispatch(setSelectedProject({...project, ...updates, updated_ts: moment().valueOf()}))

      // History
      dispatch(addToHistory({
        type: HISTORY_EVENT_TYPES.RENAME_PROJECT,
        params: {previousName: project.name, destinationName: newName}
      }))
    }
    catch (e) {
      dispatch(setToast({message: "Uh oh. We had an issue updating your project, please try again.", isError: true}))
    }
  }

  // Open model import on load
  useEffect(() => {
    if (MiscHelperMethods.isConfiguratorOr3dOrVariationsProject(project) && !props.shared && !project.model_id) {
      setShowModelImport(true)
    }

  }, [project, props.shared])

  // Listen for export started event
  useEffect(() => {

    const handleStart = () => {
      setShowExportStartedAlert(true)
    }

    document.addEventListener(EVENTS.EXPORT_STARTED, handleStart)

    return () => document.removeEventListener(EVENTS.EXPORT_STARTED, handleStart)
  }, [])

  return (
    <>
      {showExportStartedAlert && <ExportsPageNavigateModal close={() => setShowExportStartedAlert(false)} />}
      {showFreeWarning && <FreeLimitExhaustedModalComponent /> }
      {showExport && <ExportModalComponent onClose={() => setShowExport(false)} />}
      {showModelImport && <ModelImportModalComponent onClose={() => setShowModelImport(false)} />}
      <div className="toggle-project-header-container toggle-project-header-left">
        <DropdownLinksComponent element={iconMenuBtn} items={menuItems} position="center" />
        <div className="toggle-undo-redo-btns">
          <button disabled={queue.length < 1} onClick={handleUndo}><span className="icon-undo" /></button>
          <button disabled={redoQueue.length < 1} onClick={handleRedo}><span className="icon-redo" /></button>
        </div>
      </div>
      <div className="toggle-project-header-container toggle-project-header-middle">
        {isEditingProjectName && <EnterKeyInputComponent onValueChange={handleProjectNameChange} value={newProjectName} />}
        {!isEditingProjectName && <h5 onDoubleClick={handleProjectNameDoubleClick}>{project.name.length > MAX_PROJECT_NAME ? project.name.substring(0, MAX_PROJECT_NAME).concat(`...`) : project.name}</h5>}
      </div>
      <div className="toggle-project-header-container toggle-project-header-right">
        <span className={projectSaving.saving ? "project-save-state icon-cloud_upload_drop" : "project-save-state icon-cloud_done"} />
        {/* <button className="toggle-outline-btn">Preview</button> */}
        <button className="toggle-primary-btn" onClick={handleShare}><span className="icon-ios_share" /> Share</button>
      </div>
    </>
  )
}

export default ProjectHeader