import { errorMessages, ZIP_FILE_RULE_SET } from "../constants"

class ZipValidator {
    extractedFiles = null
    masterFiles = []
    otherFiles = []
    directories = []
    mustExtensions = []
    optionalExtensions = []
    masterFileExtension = null
    validateMustIfAssetsAvailable = true

    // returns
    invalidMasterFiles = []
    validMasterFiles = []
    invalidOtherFiles = []
    validOtherFiles = []
    errors = []
    excludeFoldersToRead = [
        '__MACOSX', // created by few Mac's while creating zip file
    ] 

    constructor(extractedFiles) {
        this.extractedFiles = extractedFiles
    }
    validatePath(path) {
        return this.excludeFoldersToRead.every(excludedPath => {
            return !path.startsWith(excludedPath)
        })
    }
    separateFiles() {

        this.extractedFiles.forEach(async (path, unZippedFile) => {
            if (!this.validatePath(path)) {
                return false
            }
            const unZipFileExtension = path.toLowerCase().split(".").pop()
            if (ZIP_FILE_RULE_SET.hasOwnProperty(unZipFileExtension)) {
                this.masterFiles.push(path)
            } else if (unZippedFile.dir === false) { // other file
                this.otherFiles.push(path)
            } else { // if its a directory
                this.directories.push(path)
            }
        })
        return this
    }

    setMasterFile() {
        if (this.masterFiles.length > 0) {
            this.masterFileExtension = this.masterFiles[0].toLowerCase().split(".").pop()
        }
        return this
    }

    fillOptionalAndMustExtensions() {
        if (this.masterFileExtension && ZIP_FILE_RULE_SET.hasOwnProperty(this.masterFileExtension)) {
            this.mustExtensions.push(...ZIP_FILE_RULE_SET[this.masterFileExtension].must)
            this.optionalExtensions.push(...ZIP_FILE_RULE_SET[this.masterFileExtension].optional)
            this.validateMustIfAssetsAvailable = ZIP_FILE_RULE_SET[this.masterFileExtension].validateMustIfAssetsAvailable
        }
        return this
    }

    validateMasterFileDirectories() {
        // Master files must be in root directory
        this.directories.forEach((d) => {
            if (this.masterFiles.filter(m => m.includes(d))) {
                this.errors.push(errorMessages.INVALID_DIRECTORY)
                return false
            }
        })
        return this
    }

    validateMasterFileCount() {
        // There must be only 1 master file in the ZIP
        if (this.masterFiles.length > 1) {
            this.errors.push(errorMessages.MULTIPLE_MASTER_FILES)
        } else if (this.masterFiles < 1) {
            this.errors.push(errorMessages.ZERO_MASTER_FILES)
        }
        return this
    }

    validateMasterFileExtension() {
        if (this.masterFileExtension) {
            this.masterFiles.forEach(m => {
                const ext = m.toLowerCase().split(".").pop()
                if (ext === this.masterFileExtension) {
                    this.validMasterFiles.push(m)
                } else {
                    this.invalidMasterFiles.push(m)
                }
            })
        }
        return this
    }
    validateMasterFiles() {
        this
            //.validateMasterFileDirectories()
            .validateMasterFileCount()
            .validateMasterFileExtension()
        return this
    }

    validateOtherFiles() {
        const mustFiles = []
        const optionalFiles = []
        const invalidFiles = []
        let validateMust = true

        this.otherFiles.forEach(o => {
            const ext = o.toLowerCase().split(".").pop()
            if (this.mustExtensions.includes(ext)) {
                mustFiles.push(o)
            } else if (this.optionalExtensions.includes(ext)) {
                optionalFiles.push(o)
            } else {
                invalidFiles.push(o)
            }
        })

        if (this.validateMustIfAssetsAvailable && optionalFiles.length < 1) {
            validateMust = false
        }
        if (validateMust) {
            let mustExtensionMap = new Map()
            this.mustExtensions.forEach(m => {
                mustExtensionMap.set(m, 0)
            })
            mustFiles.forEach(m => {
                const ext = m.toLowerCase().split(".").pop()
                if (mustExtensionMap.has(ext)) {
                    mustExtensionMap.set(ext, mustExtensionMap.get(ext) + 1)
                }
            })
            mustExtensionMap.forEach((found, ext) => {
                if (found === 0) {
                    this.errors.push(`'.${ext}' ${errorMessages.MUST_FILE_MISSING}`)
                }
            })
        }

        if (invalidFiles.length > 0) {
            this.invalidOtherFiles.push(...invalidFiles)
            this.errors.push(errorMessages.INVALID_FILES_FOUND)
        }
        if (optionalFiles.length > 0) {
            this.validOtherFiles.push(...optionalFiles)
        }
        return this
    }

    process() {
        this.separateFiles()
            .setMasterFile()
            .fillOptionalAndMustExtensions()
        return this
    }

    validate() {
        this
            .validateMasterFiles()
            .validateOtherFiles()
        return this
    }
    getResults() {
        return {
            invalidMasterFiles: this.invalidMasterFiles,
            validMasterFiles: this.validMasterFiles,
            invalidOtherFiles: this.invalidOtherFiles,
            validOtherFiles: this.validOtherFiles,
            errors: this.errors
        }
    }
    getErrors() {
        return {
            errors: this.errors
        }
    }
}

export default ZipValidator