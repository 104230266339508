import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useEditProjectMutation } from "../../redux/api.redux.slice"
import { setSelectedProject, setToast } from "../../redux/ui.redux.slice"
import moment from "moment"
import "./lighting-panel.component.css"

const LIGHTING_VARIATIONS = [
    { name: "Neutral Room", value: "default" },
    { name: "No Additional Lighting", value: "none" },
    { name: "Bright Studio", value: "studio" },
    { name: "Morning", value: "bcktNeutral" },
    { name: "Bright Room", value: "kitchen" },
    { name: "Very Bright Room", value: "hallway" },
    { name: "Lit Room", value: "room" },
    { name: "Dim Studio", value: "studio2" },
    { name: "Dark Studio", value: "none2" },
    { name: "Sun Rise", value: "bcktSunrise" },
    { name: "Outdoors", value: "outdoors" },
    { name: "Nile - Default", value: "nileDefault" },
    { name: "Nile - Metallic Color", value: "nileMetallicColor", roles: ['internal', 'admin'] },
    { name: "Nile - Metallic Value", value: "nileMetallicValue", roles: ['internal', 'admin'] },
    { name: "Nile - Normal/Roughness Values", value: "nileARM", roles: ['internal', 'admin'] },
]

const LightingPanelComponent = (props) => {

    const [updateProject] = useEditProjectMutation()
    const [selectedProject, role] = useSelector((state) => [state.ui.selectedProject, state.auth.role])
    const [selectedEnvironment, setSelectedEnvironment] = useState(false)
    const dispatch = useDispatch()
  
    useEffect(() => {
      if (selectedProject && selectedProject.meta && selectedProject.meta.lighting) {
        setSelectedEnvironment(selectedProject.meta.lighting)
      }
      else {
        setSelectedEnvironment('default')
      }
    }, [selectedProject])
  
    const handleSelectEnvironment = async (env) => {
      setSelectedEnvironment(env)
  
      // Update project scene
      props.onUpdateLighting(env)
  
      // Update project
      try {
        const params = {...selectedProject.meta, lighting: env}
        await updateProject({projectId: selectedProject.id, body: {meta: JSON.stringify(params)}}).unwrap()
        dispatch(setSelectedProject({...selectedProject, meta: params, updated_ts: moment().valueOf()}))
      }
      catch (e) {
        // Failed to update project
        dispatch(setToast({message: "Uh oh. We had an issue saving your project's lighting. Please try again", isError: true}))
      }
    }

    const renderLigtingOptions = () => {
        const ligthingList = LIGHTING_VARIATIONS.map((opt, index) => {
            const lightingOptionClass = selectedEnvironment === opt.value ? 'selected' : ''
            const selectOption = () => handleSelectEnvironment(opt.value)
            if (opt.roles) {
                if (opt.roles.indexOf(role) > -1) {
                    return (
                        <li key={index} className={lightingOptionClass} onClick={selectOption}>
                            {opt.name}
                        </li>
                    )
                }

                return null
            }

            return (
                <li key={index} className={lightingOptionClass} onClick={selectOption}>
                    {opt.name}
                </li>
            )
        });

        return <ul>{ligthingList}</ul>
    }

    return <div className="toggle-lighting-panel">{renderLigtingOptions()}</div>
}

export default LightingPanelComponent;
