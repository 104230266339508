import { useState } from "react"
import { MATERIAL_PRESETS } from "../../constants"
import { updateMaterialMeta } from "../../utils/ui.util"
import "../material-image-create/material-image-create.component.css"
import MaterialAdjusterComponent from "../material-adjuster/material-adjuster.component"
import ColorPickerComponent from "../color-picker/color-picker.component"
import withMaterialBuilderModal from "../material-builder/material-builder-modal/material-builder-modal.component"
import { Color3 } from "@babylonjs/core"

const MaterialColorCreateComponent = (props) => {

  const [params, setParams] = useState({})
  const [matMeta, setMatMeta] = useState(false)
  const [step, setStep] = useState(1)
  const { material } = props

  const getNextBtnDisabledState = () => {
    if (step === 1) {
      return !params.color
    }
    else if (step === 2) {
      return false
    }
    else if (step === 3) {
      return matMeta === false || !matMeta.name
    }

    return true
  }

  const handleMetaChange = (meta) => {
    setMatMeta(meta)
  }

  const handleColorChange = (color) => {
    setParams({...params, color})
    const {r, g, b} = color.rgb

    // Update material
    material.albedoColor = new Color3(r/255,g/255,b/255).toLinearSpace()
  }

  const handleSelectPreset = (p) => {
    setParams({...params, selectedPreset: p})
    updateMaterialMeta(props.material, p.props, props.albedoMaps, props.armMaps, props.normalMaps)
    setMatMeta({...matMeta, ...p.props})
  }

  const handleDeclineFinish = () => {
    // Remove the selected presets from params
    const {selectedPreset, ...other} = params
    setParams({...other})
    updateMaterialMeta(props.material, {}, props.albedoMaps, props.armMaps, props.normalMaps)
    if (matMeta) {
      setMatMeta(false)
    }
  }

  const handlePreviousBtnClick = () => {
    if (step === 1) {
      props.showMaterialSelection()
    }
    else if (step === 2) {
      setStep(1)
    }
    else if (step === 3) {
      setStep(2)
    }
  }

  const handleNextBtnClick = () => {
    if (step === 1) {
      setStep(2)
    } 
    else if (step === 2) {
      if (params.selectedPreset) {
        setStep(4)
      } else {
        updateMaterialMeta(props.material, {}, props.albedoMaps, props.armMaps, props.normalMaps)
      }
      setStep(3)
    }
    else if (step === 3) {
      props.onSave(props.material, {...matMeta, fromColor: params.color.rgb})
    }
  }
  
  return (
    <div className="toggle-material-image-create">
      <div className="color-step-container">
        {step === 1 && (
          <>
            <ColorPickerComponent startingColor={params.color && params.color.rgb} hideUI onChange={handleColorChange} />
            <div className="threedy-lab-swatch material-builder-swatch" style={{backgroundColor: params.color && params.color.hex ? params.color.hex : "#333333"}}></div>
          </>
        )}
        {step === 2 && (
          <>
            <p>Are you looking for a finish?</p>
            <ul>
              <li className={!params.selectedPreset ? "full selected" : "full"} onClick={handleDeclineFinish}><h5>None</h5></li>
              {
                MATERIAL_PRESETS.map((p) => {
                  return (
                    <li key={p.id} className={params.selectedPreset && params.selectedPreset.id === p.id ? "selected full" : "full"} onClick={() => handleSelectPreset(p)}><h5>{p.name}</h5></li>
                  )
                })
              }
            </ul>
          </>
        )}
        {step === 3 && (
          <>
            <MaterialAdjusterComponent inline includeName hideUI material={{raw: props.material, meta: matMeta}} onChange={handleMetaChange} selectedPresetName={params.selectedPreset?.name} />
          </>
        )}
      </div>
      <div className="material-color-create-footer">
        <button onClick={handlePreviousBtnClick} className="back-btn"><span className="icon-arrow-left"></span> Back</button>
        <button onClick={handleNextBtnClick} disabled={getNextBtnDisabledState()} className="next-btn">{step !== 3 ? "Next" : "Save"}</button>
      </div>
    </div>
  )
}

export default withMaterialBuilderModal(MaterialColorCreateComponent)

