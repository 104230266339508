import { useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { EVENTS, KLAVIYO_METRICS } from "../../constants"
import { useAddNewModelMutation, useAddNewProjectMutation, useEditProjectMutation, useEditProjectReferencePresignedPhotosMutation, useLazyGetAritize3DModelQuery, useLazyGetAritize3DModelsQuery, useLazyGetModelsQuery, useLazyGetSampleModelsQuery } from "../../redux/api.redux.slice"
import ModalComponent from "../modal/modal.component"
import TabBarComponent from "../tab-bar/tab-bar.component"
import moment from "moment"
import { setToast } from "../../redux/ui.redux.slice"
import { useNavigate } from "react-router-dom"
import { modifyQuota } from "../../redux/auth.redux.slice"
import "./model-import-modal.component.css"
import { exclamationCircle, upload } from "../../assets"
import JSZip from "jszip"
import SpinnerComponent from "../spinner/spinner.component"
import FileUploadLimitMaximumModalComponent from "../alerts/file-upload-limit-maximum-modal.component"
import UnacceptedFileFormatModalComponent from "../alerts/unaccepted-file-format-modal.component"
import InvalidZipFileFormatModalComponent from "../alerts/invalid-zip-file-format-modal.component"
import ZipValidator from "../../utils/zipValidator.util"
import NoSpaceAvailableModalComponent from "../upgrade-to-pro/no-space-available.component"
import OutdatedModelModalComponent from "../outdated-model-modal/outdated-model-modal.component"

const ModelImportModalComponent = (props) => {

  const [tab, setTab] = useState("new")
  const [loading, setLoading] = useState(false)
  const [updateProject] = useEditProjectMutation()
  const [uploadModel] = useAddNewModelMutation()
  const [fetchAritizeModel] = useLazyGetAritize3DModelQuery()
  const [selectedModel, setSelectedModel] = useState(false)
  const [selectedProject, plan, user, externalApps] = useSelector((state) => [state.ui.selectedProject, state.auth.plan, state.auth.user, state.auth.external_applications])
  const [fetchModels] = useLazyGetModelsQuery()
  const [fetchSampleModels] = useLazyGetSampleModelsQuery()
  const [fetchAritize3dModels] = useLazyGetAritize3DModelsQuery()
  const [existingModelSelection, setExistingModelSelection] = useState(false)
  const [uploadFileLabel, setuploadFileLabel] = useState("No file selected")
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [addProject] = useAddNewProjectMutation()
  const [showUploadLimitMaxModal, setShowUploadLimitMaxModal] = useState(false)
  const [unAcceptableFileFormat, setUnAcceptableFileFormat] = useState(false)
  const [zipValidationResult, setZipValidationResult] = useState(null)
  const [showZipValidationResultModal, setShowZipValidationResultModal] = useState(false)
  const [showNoSpaceAvailableModal, setShowNoSpaceAvailableModal] = useState(false)
  const [showOutdatedModelModal, setShowOutdatedModelModal] = useState(false)
  const [search, setSearch] = useState("")
  const [viewModels, setViewModels] = useState([])
  const cacheRef = useRef({})
  const debounceRef = useRef()
  const inputRef = useRef(null)
  const [doUploadPhotosRequest] = useEditProjectReferencePresignedPhotosMutation()


  const handleTabChange = async (itemKey) => {
    setTab(itemKey)
    setExistingModelSelection(false)

    if (loading) {
      return
    }

    try {

      setLoading(true)
      setSearch("")
      let data

      if (itemKey === 'existing') {
        // Check cache
        if (cacheRef.current.existing && moment().isBefore(moment(cacheRef.current.existing.exp))) {
          data = cacheRef.current.existing.data
        }
        else {
          data = await fetchModels().unwrap()
          cacheRef.current.existing = {
            exp: moment().add(1, "minute"),
            data
          }
        }
      }
      else if (itemKey === 'sample') {
        // Check cache
        if (cacheRef.current.sample && moment().isBefore(moment(cacheRef.current.sample.exp))) {
          data = cacheRef.current.sample.data
        }
        else {
          data = await fetchSampleModels().unwrap()
          cacheRef.current.sample = {
            exp: moment().add(1, "minute"),
            data
          }
        }
      }
      else if (itemKey === 'aritize3D') {
        // Check cache
        if (cacheRef.current.aritize3D && moment().isBefore(moment(cacheRef.current.aritize3D.exp))) {
          data = cacheRef.current.aritize3D.data
        }
        else {
          data = await fetchAritize3dModels().unwrap()
          cacheRef.current.aritize3D = {
            exp: moment().add(1, "minute"),
            data
          }
        }
      }

      setViewModels(data)
      setLoading(false)
    }
    catch (e) {
      console.error("Error fetching content: ", e)
    }
  }

  const initialTabItems = [
    {
      id: 'new',
      label: 'Upload a New File'
    },
    {
      id: 'existing',
      label: 'Select an Existing File'
    },
    {
      id: 'sample',
      label: 'Sample Assets'
    }
  ]

  const aritizeTab = {id: 'aritize3D', label: "ARitize3D", logo: "/images/aritize-logo.svg"}

  const tabItems = externalApps && externalApps.aritize3D ? [...initialTabItems, aritizeTab] : initialTabItems

  const resetFileInput = () => {
    if (inputRef.current) {
      inputRef.current.value = null;
    }
  }
  const processFile = async (file) => {
    if (!file) {
      setuploadFileLabel("No file selected")
      return
    }
    setuploadFileLabel(file.name)

    const planMax = plan.features.uploadSize
    const maxFileSize = planMax * 1024 * 1024  // 20 or 75 Mb depending on plan
    const extension = file.name.toLowerCase().split(".").pop()

    if (file.size > maxFileSize) {
      resetFileInput()
      setShowUploadLimitMaxModal(true)
      setSelectedModel(false)
      return
    }

    const acceptedTypes = /(\.glb|\.stp|\.step|\.obj|\.fbx|\.dae|\.zip)$/i;
    if (!acceptedTypes.exec(file.name)) {
      resetFileInput()
      setUnAcceptableFileFormat(true)
      setuploadFileLabel("No file selected")
      return
    }

    if (extension === 'zip') {
      const zip = new JSZip()
      const extractedFiles = await zip.loadAsync(file)

      const {
        errors,
        invalidMasterFiles,
        validMasterFiles,
        invalidOtherFiles,
        validOtherFiles
      } = (new ZipValidator(extractedFiles)).process().validate().getResults()
      if (errors.length > 0) {
        setZipValidationResult(prevState => ({
          ...prevState,
          errors: errors,
          invalidMasterFiles: invalidMasterFiles,
          validMasterFiles: validMasterFiles,
          invalidOtherFiles: invalidOtherFiles,
          validOtherFiles: validOtherFiles,
        }))
        setSelectedModel(false)
        setShowZipValidationResultModal(true)
        resetFileInput()
        return false
      }
    }
    setSelectedModel({ src: file, name: file.name.replace(/[\W_.]+/g, "_") })
  }

  const handleFileChange = (e) => {
    e.preventDefault()
    const file = e.target.files[0]
    processFile(file)
  }

  const handleFileDrop = (e) => {
    e.preventDefault()
    e.currentTarget.classList.remove("drop-section")
    const file = e.dataTransfer.files[0]
    processFile(file)
  }

  const handleLoad = async () => {

    setLoading(true)
    let modelId
    let referencePhotos = []

    if (['existing', 'sample'].includes(tab) && existingModelSelection) {
      modelId = existingModelSelection
    }
    else if (tab === 'aritize3D' && existingModelSelection) {
      // Fetch model from ARitize & set modelId
      try {
        const arRes = await fetchAritizeModel(existingModelSelection).unwrap()
        if (arRes.projectId) {

          // Check if model is out of date
          if (arRes.isOutOfDate && arRes.lastUpload && arRes.modelId) {
            setShowOutdatedModelModal({projectId: arRes.projectId, modelId: arRes.modelId, lastUpload: arRes.lastUpload})
            setLoading(false)
            return
          }

          // Navigate to project w/ this model already loaded
          props.onClose()
          navigate(`/editor/${arRes.projectId}`)
          return
        }

        modelId = arRes.model.id

        if (arRes.referencePhotos && arRes.referencePhotos.length > 0) {
          referencePhotos = arRes.referencePhotos
        }
      }
      catch (e) {
        console.log("Error fetching ARitize model: ", e)
        const error = e.originalStatus && e.originalStatus === 403 ? "Uh oh. It looks like the job you selected has been started by another user. Please select another job and try again." : "Uh oh. It looks like that job does not have a mesh attached to it yet, please try again."
        dispatch(setToast({message: error, isError: true}))
        setLoading(false)
        return
      }
    }
    else {
      // Create form
      const hash = btoa(plan.id)
      const data = new FormData()
      data.set("model", selectedModel.src)
      data.set(user.sub, hash)

      try {
        // Upload model
        const modelRes = await uploadModel(data).unwrap()
        modelId = modelRes.data.id
        window.klaviyo.track(KLAVIYO_METRICS.model_uploaded)
      }
      catch (e) {
        console.log("Error uploading model: ", e)
        if (e.data?.data === 'INSUFFICIENT') {
          setShowNoSpaceAvailableModal(true)
        } else {
          dispatch(setToast({ message: "Uh oh. We had an issue loading that file, please try again.", isError: true }))
        }
        setLoading(false)
        return
      }
    }

    let projectID = selectedProject.id || null
    // If no project is selected yet, create a project unless we have a prop telling us not to
    if (!projectID && !props.disableNewProject) {
      const params = {name: 'Untitled', project_type: '3D model'}
      try {
        const res = await addProject(params).unwrap()

        window.klaviyo.track(KLAVIYO_METRICS.created_project)
  
        // Increment quota
        dispatch(modifyQuota(1))
        projectID = res.data.id
      }
      catch (e) {
        console.log("Error adding project", e)
        dispatch(setToast({message: "Uh oh. We had an issue creating a new project, please try again.", isError: true}))
      }
    }

    if (!modelId) {
      setLoading(false)
      dispatch(setToast({message: "Please select a model", isError: true}))
      return
    }

    if (projectID) {
      // Associate to project
      try {
        const params = {model_id: modelId, parts: '[]', variations: '[]', meta: '{}'}
        await updateProject({projectId: projectID, body: params}).unwrap()
        
        // Upload reference photos as needed
        if (referencePhotos && referencePhotos.length > 0) {
          await handleUploadReferencePhotos(projectID, referencePhotos)
        }

        const redirectLocation = `/editor/${projectID}`
        if (window.location.pathname === redirectLocation) {
          // Update scene
          document.dispatchEvent(new CustomEvent(EVENTS.REFRESH_SCENE))
        }
  
        // Done
        props.onClose()
        if (window.location.pathname !== redirectLocation) {
          navigate(redirectLocation)
        }
      }
      catch (e) {
        console.log("Error associating model to project: ", e)
        dispatch(setToast({message: "Uh oh. We had an issue loading that file, please try again.", isError: true}))
      }
    }
    else {
      props.onClose()
    }

    setLoading(false)
  }

  const handleUploadReferencePhotos = (projectId, photos) => {
    return new Promise(async (resolve, reject) => {
      try {
        await doUploadPhotosRequest({projectId, body: {photos}}).unwrap()
        resolve()
      }
      catch (e) {
        reject(e)
      }
    })
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.currentTarget.classList.add("drop-section")
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.currentTarget.classList.remove("drop-section")
  }

  const handleCancelOrClose = () => props.onClose(false)

  const handleSearchUpdate = (val) => {
    setSearch(val)

    if (debounceRef.current) {
      clearTimeout(debounceRef.current)
    }

    debounceRef.current = setTimeout(() => doSearch(val), 250)
  }

  const doSearch = (val) => {
    const term = val.toLowerCase()
    const filtered = [...cacheRef.current.aritize3D.data].filter((m) => {
      return m.name.toLowerCase().indexOf(term) > -1 || m.status.toLowerCase().indexOf(term) > -1 || (m.artist && m.artist.toLowerCase().indexOf(term) > -1)
    })

    setViewModels(filtered)
  }
  
  const handleClearSearch = () => {
    setSearch("")
    setViewModels(cacheRef.current.aritize3D.data)
  }
  
  const handleFocus = () => {
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: false}}))
  }

  const handleBlur = () => {
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: true}}))
  }

  const handleCompleteOutdatedPrompt = (forceReload) => {
    const redirectLocation = `/editor/${showOutdatedModelModal.projectId}`
    navigate(redirectLocation)
    props.onClose()
    
    if (forceReload && window.location.pathname === redirectLocation) {
      // Update scene
      document.dispatchEvent(new CustomEvent(EVENTS.REFRESH_SCENE))
    }
  }

  const content = (
    <>
    <div className={loading ? "toggle-model-import loading" : "toggle-model-import" }>
      {!props.disableExisting && <TabBarComponent items={tabItems} onSelection={handleTabChange} default="new" />}
      {['existing', 'sample', 'aritize3D'].includes(tab) && (
        <div className="toggle-existing-import">
          {viewModels && (viewModels.length > 0 || tab === 'aritize3D') ? (
            <>
              {tab === 'aritize3D' && (
                <div className="toggle-flex-container">
                  <input type="text" className="toggle-text-input" onFocus={handleFocus} onBlur={handleBlur} disabled={loading} value={search} onChange={(e) => handleSearchUpdate(e.target.value)} placeholder={loading ? "" : "Search Jobs"} />
                  {search.length > 0 && <button className="toggle-outline-btn auto-width" onClick={handleClearSearch}>Clear</button>}
                </div>
              )}
              {tab === 'aritize3D' && !loading && viewModels.length < 1 && <div className="delete-btn mt-20 centered">No results matching your search...</div>} 
              <ul className="toggle-content-list">
                {tab === 'aritize3D' ? (
                  viewModels.length > 0 && <li className="fw-600 fw-14 no-action"><div className="big">Name</div><div>Status</div><div>Workflow</div><div className="medium">Assignee</div><div>Updated</div></li>
                ) : (
                  <li className="fw-600 fw-14 no-action"><div className="big">Name</div><div>Status</div><div>Size</div><div>Uploaded</div></li>
                )}
                {viewModels.map((m) => {

                  return (
                    tab === 'aritize3D' ? (
                      <li onClick={() => setExistingModelSelection(m.id)} className={existingModelSelection === m.id ? "selected actionable" : "actionable"} key={m.id}>
                        <div>{m.name}</div>
                        <div><span className="capitalize">{m.status}</span></div>
                        <div>{m.workflow}</div>
                        <div className="nowrap medium">{m.artist ? m.artist : <i>n/a</i>}</div>
                        <div>{moment(m.created_ts).fromNow()}</div>
                      </li>
                    ) : (
                      <li onClick={() => setExistingModelSelection(m.id)} className={m.status !== 'ready' && m.status !== "readyForMaterialImport" ? 'disabled' : existingModelSelection === m.id ? "selected actionable" : "actionable"} key={m.id}>
                        <div>{m.name}</div>
                        <div><span className="capitalize">{m.status === 'readyForMaterialImport' ? "ready" : m.status}</span></div>
                        <div>{m.size && `${m.size} MB`}</div>
                        <div>{moment(m.created_ts).fromNow()}</div>
                      </li>
                    )
                  )
                })}
              </ul>
            </>
          ) : !loading ? (
            <div className="toggle-existing-import"><h5>{tab === 'existing' ? "You haven't uploaded any models yet..." : (tab === 'aritize3D' ? "You don't have any assigned models yet..." : "No sample models available...")}</h5></div>
          ) : (
            <div className="toggle-existing-import threedy-lab-spinner-wrapper"><SpinnerComponent inline /></div>
          )}
        </div>
      )}
      {tab === 'new' && (
        <div className="toggle-new-import">
          <h6>Please select a .glb, .step, .stp, .obj, .fbx, .dae less than or equal to {plan && plan.features && plan.features.uploadSize} MB. If your model has texture files please zip the model with the textures.<span className="tooltip">{exclamationCircle}<span className="tooltiptext">Please organize your zip file so that the model is in the root of the file, the textures can be in a folder or also part of the root. You can also upload a GTLF in this zip format</span></span></h6>
          <div className="uploader-image-search" onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleFileDrop}>
            <div>{upload}</div>
            <div><p>Drag a file here or</p></div>
            <div>
              <input type="file" ref={inputRef} id="upload-image-to-search" className="hidden" placeholder="Choose File" accept=".glb,.stp,.step,.obj,.fbx,.dae,.zip" onChange={handleFileChange} />
              <label htmlFor="upload-image-to-search" className="hidden-input-label">Choose File</label>
            </div>
            <div><p>{uploadFileLabel}</p></div>
          </div>
        </div>
      )}
    </div>
    {showNoSpaceAvailableModal && <NoSpaceAvailableModalComponent plan={plan} close={() => setShowNoSpaceAvailableModal(false)} />}
    {showUploadLimitMaxModal && <FileUploadLimitMaximumModalComponent sizeInMB={plan.features.uploadSize} close={() => setShowUploadLimitMaxModal(false)} />}
    {unAcceptableFileFormat && <UnacceptedFileFormatModalComponent close={() => setUnAcceptableFileFormat(false)} />}
    {showZipValidationResultModal && <InvalidZipFileFormatModalComponent close={() => setShowZipValidationResultModal(false)} zipValidationResult={zipValidationResult} />}
    {showOutdatedModelModal && <OutdatedModelModalComponent done={handleCompleteOutdatedPrompt} modelId={showOutdatedModelModal.modelId} lastUpload={showOutdatedModelModal.lastUpload} close={() => setShowOutdatedModelModal(false)} />}
    </>
  )

  const actions = (
    <div className="toggle-btn-group">
      <button className="toggle-outline-filled-btn" disabled={loading} onClick={handleCancelOrClose}>Cancel</button>
      {tab === 'new' && <button className="toggle-primary-btn" disabled={(!selectedModel.src && !existingModelSelection) || loading} onClick={handleLoad}>{loading ? "Uploading..." : "Upload"}</button>}
      {['existing', 'sample', 'aritize3D'].includes(tab) && (
        <button className="toggle-primary-btn" disabled={loading || (!selectedModel.src && !existingModelSelection)} onClick={handleLoad}>{loading ? "Opening..." : "Open"}</button>
      )}
    </div>
  )

  return (
    <ModalComponent title={props.title ? props.title : "Import 3D File"} content={content} actions={actions} close={handleCancelOrClose} />
  )
}

export default ModelImportModalComponent