import { useEffect, useState, useRef } from "react"
import { DEFAULT_GLTF_MATERIAL, EVENTS } from "../../constants"
import "./predictive-search.component.css"

const PredictiveSearchComponent = (props) => {

  const [search, setSearch] = useState("")
  const timer = useRef(null)
  const [originItems, setOriginItems] = useState([])
  const [filteredItems, setFilteredItems] = useState([])

  const doSearch = (term) => {
    setFilteredItems([...originItems].filter((i) => {
      return i.name.toLowerCase().indexOf(term) > -1
    }))
  }

  const handleSearchUpdate = (newVal) => {
    setSearch(newVal)
    if (timer.current) {
      window.clearTimeout(timer.current)
    }

    timer.current = window.setTimeout(() => { doSearch(newVal.toLowerCase()) }, 250)
  }

  useEffect(() => {
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: false}}))

    return () => {
      document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: true}}))
    }
  }, [])

  useEffect(() => {
    // Filter items by those that have non default materials if specified
    let filtered
    if (props.exludeDefaultMaterials) {
      filtered = [...props.items].filter((i) => (i.type !== 'group' && i.material.id.indexOf(DEFAULT_GLTF_MATERIAL) < 0) || (i.type === 'group' && i.parts.length > 0 && i.parts[0].material && i.parts[0].material.id.indexOf(DEFAULT_GLTF_MATERIAL) < 0))
    }
    else {
      filtered = props.items
    }

    setOriginItems([...filtered])
    setFilteredItems([...filtered])

    return () => {
      if (timer.current) {
        window.clearTimeout(timer.current)
        timer.current = null
      }
    }
  }, [props.items, props.exludeDefaultMaterials])

  return (
    <div className="threedy-predictive-search" style={{
        top: props.position.top, 
        left: props.position.left
      }}>
      {props.title && <h4>{props.title}</h4>}
      {originItems.length > 3 && !props.hideSearch && <input type="text" autoFocus value={search} onChange={(e) => handleSearchUpdate(e.target.value)} placeholder={`Search ${originItems.length} items...`} /> }
      {originItems.length < 1 && <h5>{props.noItemsLabel ? props.noItemsLabel : "No available items"}</h5>}
      <ul>
        {
          filteredItems && filteredItems.length > 0 && filteredItems.map((i, index) => {
            if (index > 2 && !props.hideSearch) {
              return null
            }

            return (
              <li key={i.id}><button onClick={() => props.onSelect(i.id)}>{props.icon && <span className={props.icon}></span>}{i.name}</button></li>
            )
          })
        }
      </ul>
      {!props.hideCancel && <button className="btn-default" onClick={props.onClose}>{originItems.length > 0 ? "Cancel" : "OK"}</button>}
    </div>
  )
}

export default PredictiveSearchComponent