import moment from "moment"
import EnterKeyInputComponent from "../enter-key-input/enter-key-input.component"

const FolderTileComponent = (props) => {
  const {folder, editable, editedFolderId, onUpdateName, onMoreClick} = props

  return (
    <li key={folder.id} onClick={props.onClick}>
      <div className="grid-container background-grayed-dark">
        <div className="grid-thumb gray bigger">
          <span className="icon-folder"></span>
        </div>
        <div className="grid-action">
          <div className="grid-info">
            {editedFolderId !== folder.id && <h5>{folder.name}</h5> }
            {editable && editedFolderId === folder.id  && (
              <EnterKeyInputComponent value={folder.name} onValueChange={onUpdateName} />
            )}
            <h6><span className="icon-folder"></span> Project Folder</h6>
            <h6>{folder.updated_ts ? `Last updated ${moment(folder.updated_ts).fromNow()}` : `Created ${moment(folder.created_ts).fromNow()}`}</h6>
          </div>
          {editable && <button className="more-btn" onClick={(e) => onMoreClick(e, folder.id)}><span className="icon-more-vertical"></span></button>}
        </div>
      </div>
    </li>
  )
}

export default FolderTileComponent