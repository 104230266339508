import { useNavigate } from "react-router-dom"
import ModalComponent from "../modal/modal.component"

const UpgradeToProModalComponent = (props) => {

  const navigate = useNavigate()

  const redirectToPlans = () => {
    navigate("/settings/plans")
    return false
  }

  const actions = (
    <>
      <button className="toggle-default-btn" onClick={() => props.close()}>Cancel</button>
      <button className="toggle-primary-btn" onClick={() => redirectToPlans()}>Upgrade</button>
    </>
  )
  return (
    <ModalComponent title="Upgrade to Pro"
      close={() => props.close()}
      content={props.modalContent}
      className="small"
      actions={actions} />
  )
}

export default UpgradeToProModalComponent