import "./dropdown-links.component.css"

import React, { useState } from 'react'

const DropdownLinksComponent = (props) => {
  const [showLinks, setShowLinks] = useState(false)
  const { items, element, position, fullWidth } = props

  const handleSelect = (e, item) => {
    e.preventDefault()
    e.stopPropagation()

    if (!item.onSelect) { return }

    item.onSelect() 
    handleBlur()
  }

  const handleBlur = () => {
    setShowLinks(false)
  }

  let linksWrapperClass = 'dropdown-links'
  if (position) {
    linksWrapperClass += ` ${position}`
  }
  if (fullWidth) {
    linksWrapperClass += ` full-width`
  }

  return (
    <div className="dropdown-links-container">
      <div role="button" tabIndex={0} onBlur={handleBlur} onClick={() => setShowLinks(!showLinks)} className='dropdown-element'>
        {element}
      </div>
      {showLinks &&
        <div className={linksWrapperClass}>
          <ul>
            {items.map(i => {
              return (
                <li key={i.id} className={i.disabled ? "each-dropdown-link disabled" : (i.highlight ? "each-dropdown-link highlighted" : "each-dropdown-link")} onMouseDown={(e) => handleSelect(e, i)}>
                  {i.icon && <span className={`dropdown-link-icon ${i.icon}`} ></span>}
                  <span className="dropdown-link-text">{i.label}</span>
                  {i.rightIcon && <span className={`dropdown-link-icon right-icon ${i.rightIcon}`} ></span>}
                  {i.subItems && (
                    <ul>
                      {i.subItems.map((s) => {
                        return (
                          <li key={s.id} className="each-dropdown-link" onMouseDown={(e) => handleSelect(e, s)}><span className="dropdown-link-text">{s.label}</span></li>
                        )
                      })}
                    </ul>
                  )}
                </li>
              )
            })}
          </ul>
        </div>
      }
    </div>
  )
}

export default DropdownLinksComponent
