import { useState, useEffect, useRef } from "react"
import { PAUSE_DURATION } from "../../../constants"
import { useEditUserMutation, useLazyVerifyOldPasswordQuery } from "../../../redux/api.redux.slice"

const SecuritySettingsSection = () => {


  // const user = useSelector((state) => state.auth.user)
  const [passwordUpdate, setPasswordUpdate] = useState({oldPassword: '', password: '', confirmPassword: ''})
  const [isValid, setIsValid] = useState({all: false, lowercase: false, uppercase: false, number: false, specialCharacter: false, length: false})
  const [responseText, setResponseText] = useState('')
  const [disablePasswordChange, setDisablePasswordChange] = useState(true)
  const [updateUserRequest, {isLoading}] = useEditUserMutation()
  const [verifyOldPassword, { isFetching: verifying }] = useLazyVerifyOldPasswordQuery();

  const timerRef = useRef()
  

  useEffect(() => {
    const getPasswordVerified = async (password) => {
      const res = await verifyOldPassword({body: {oldPassword: password}})
      setDisablePasswordChange(!res.data.isValidOldPassword)
    }

    // Minimum Password length is 8
    if (passwordUpdate.oldPassword && passwordUpdate.oldPassword.length > 7) {
      clearTimeout(timerRef.current)
      timerRef.current = setTimeout(() => {
          getPasswordVerified(passwordUpdate.oldPassword)
      }, PAUSE_DURATION)
    }
  
    // Remove timer whenever this component unmounts, for saving memory leak
    return () => {
        clearTimeout(timerRef.current)
    }

  }, [passwordUpdate.oldPassword, verifyOldPassword])

  const handleTextChange = (e) => {
    const {id, value} = e.target
    setPasswordUpdate({...passwordUpdate, [id]: value})

    if (id === 'password') {
      validate(value)
    }
  }

  const validate = (val) => {
    const validity = {all: true};

    // Validate lowercase
    const lowerCaseLetters = /[a-z]/g;
    if (val.match(lowerCaseLetters)) {
      validity.lowercase = true
    } 
    else {
      validity.lowercase = false
      validity.all = false
    }

    // Validate capital letters
    const upperCaseLetters = /[A-Z]/g;
    if (val.match(upperCaseLetters)) {
      validity.uppercase = true
    } 
    else {
      validity.uppercase = false
      validity.all = false
    }

    // Validate numbers
    const numbers = /[0-9]/g;
    if (val.match(numbers)) {
      validity.number = true
    } 
    else {
      validity.number = false
      validity.all = false
    }

    // Validate special chars
    const specialChars = /[$&+,:;=?@#|'<>.^*()%!-]/g;
    if (val.match(specialChars)) {
      validity.specialCharacter = true
    } 
    else {
      validity.specialCharacter = false
      validity.all = false
    }

    // Validate length
    if (val.length >= 8) {
      validity.length = true
    }
    else {
      validity.length = false
      validity.all = false
    }

    setIsValid(validity)
  }

  const handleUpdateUser = async () => {

    try {
      const params = {password: passwordUpdate.password}
      await updateUserRequest({body: params}).unwrap()

      // Reset
      setPasswordUpdate({oldPassword: '', password: '', confirmPassword: ''})
      setIsValid({all: false, lowercase: false, uppercase: false, number: false, specialCharacter: false, length: false})
      setResponseText("Password updated successfully!")
      setTimeout(() => setResponseText(''), 4000)
    } 
    catch (e) {
      console.log("Error updating user password: ", e)
      setResponseText("Uh oh, there was an error updating your password. Please try again")
      setTimeout(() => setResponseText(''), 4000)
    }
  }

  const disableUpdate = !isValid.all || (passwordUpdate.password !== passwordUpdate.confirmPassword) || disablePasswordChange || verifying

  return (
    <div className={isLoading ? "threedy-skeleton" : "toggle-in-page-container"}>
      <div className="settings-wrapper">
        <div className="left-container flex-basis-50">
          <div className="settings-card no-border">
            <div className="settings-interface">
              <p className="heading">Password must contain the following:</p>
              <ul className="sub-heading">
                <li><span className={isValid.lowercase && isValid.uppercase ? "icon-check_small primary-tag" : "icon-check_small outline-tag"}></span> Upper case and lower case letter</li>
                <li><span className={isValid.number ? "icon-check_small primary-tag" : "icon-check_small outline-tag"}></span> Numeric character (0-9)</li>
                <li><span className={isValid.specialCharacter ? "icon-check_small primary-tag" : "icon-check_small outline-tag"}></span> Special character (!, @, #, $, etc.)</li>
                <li><span className={isValid.length ? "icon-check_small primary-tag" : "icon-check_small outline-tag"}></span> A minimum of 8 characters</li>
                <li><span className={passwordUpdate.password && passwordUpdate.password === passwordUpdate.confirmPassword ? "icon-check_small primary-tag" : "icon-check_small outline-tag"}></span> Passwords must match</li>
              </ul>
            </div>
          </div>
        </div>
        <div className="right-container flex-basis-50">
          <div className="settings-card">
            <div className="each-setting">
              <span className="heading mb-25">Update Password</span>
              <div className="form-input">
                <span className="sub-heading">Old Password</span>
                <input type="password" id="oldPassword" value={passwordUpdate.oldPassword} autoFocus placeholder="Old Password..." onChange={handleTextChange} />
                {
              passwordUpdate.oldPassword && disablePasswordChange && !verifying && (
                <div className="cross-svg-container">
                  <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
                    width="16.000000pt" height="16.000000pt" viewBox="0 0 512.000000 512.000000"
                    preserveAspectRatio="xMidYMid meet">

                    <g transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
                    fill="red" stroke="none">
                    <path d="M2325 5109 c-500 -51 -945 -229 -1345 -537 -124 -96 -363 -340 -462
                    -472 -261 -349 -423 -735 -490 -1165 -31 -200 -31 -550 0 -750 87 -556 329
                    -1034 726 -1430 210 -209 416 -360 662 -483 605 -303 1297 -352 1949 -137 843
                    277 1496 998 1689 1865 80 364 80 757 0 1120 -232 1043 -1103 1836 -2168 1975
                    -148 19 -442 27 -561 14z m-463 -1564 c18 -10 183 -166 365 -348 l333 -332
                    342 341 c356 354 369 364 449 364 111 0 219 -108 219 -219 0 -80 -10 -93 -364
                    -448 l-340 -343 340 -343 c354 -355 364 -368 364 -448 0 -111 -108 -219 -219
                    -219 -80 0 -93 10 -449 364 l-342 341 -333 -332 c-182 -182 -346 -338 -363
                    -347 -83 -41 -171 -28 -239 35 -74 68 -92 165 -49 253 9 17 165 181 347 364
                    l332 332 -332 333 c-182 182 -338 346 -347 363 -23 47 -29 105 -16 153 14 55
                    81 127 135 147 47 17 120 12 167 -11z"/>
                    </g>
                  </svg>  
                </div>
              )
                }
                {
                  passwordUpdate.oldPassword && verifying && <span className="verify-loader"></span>
                }
              </div>
              <div className="form-input">
                <span className="sub-heading">New Password</span>
                <input type="password" id="password" value={passwordUpdate.password} placeholder="New Password..." onChange={handleTextChange} disabled={disablePasswordChange} />
              </div>
              <div className="form-input">
                <span className="sub-heading">Confirm New Password</span>
                <input type="password" id="confirmPassword" value={passwordUpdate.confirmPassword} placeholder="Confirm Password..." onChange={handleTextChange} disabled={disablePasswordChange} />
              </div>
              <div className="form-input">
                {responseText && <p>{responseText}</p>}
                <button className="toggle-primary-btn update-password-btn" disabled={disableUpdate} onClick={handleUpdateUser}>
                  Update Password
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default SecuritySettingsSection