import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import "./plan-limit.component.css"

const PlanLimitComponent = () => {
  const {plan, quota} = useSelector((state) => state.auth)

  if (!plan || plan.type === 'pro' || !quota || quota.numProjects === 0) {
    return null
  }

  const numLeft = plan.features.numProjects - quota.numProjects
  const message = numLeft > 0 ? `You have ${numLeft} ${numLeft === 1 ? 'project' : 'projects'} left in your free plan` : "You have ran out of projects in your free plan."
  const showUpgradeButton = numLeft <= 0

  return (
    <div className="plan-limit-component">
      <div className="plan-limit-component-content">
        <span className="icon-hexagon"></span> {message} {showUpgradeButton && <Link to="/settings/plans">Upgrade today</Link>}
      </div>
    </div>
  )
}

export default PlanLimitComponent