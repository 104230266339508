import { useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setToast } from "../../redux/ui.redux.slice"
import "./toaster.component.css"

const ToasterComponent = () => {
  const toast = useSelector((state) => state.ui.toast)
  const toastRef = useRef(false)
  const dispatch = useDispatch()

  useEffect(() => {
    let isMounted = true
    let timerRef = false
    if (toast) {
      // Show
      setTimeout(() => {
        if (isMounted && toastRef.current) {
          toastRef.current.classList.add("show")
          timerRef = window.setTimeout(() => {
            // Hide
            toastRef.current.classList.remove("show")
            timerRef = window.setTimeout(() => {
              dispatch(setToast(false))
            }, 300)

          }, 4000)
        }
      }, 100)
    }

    return () => {
      if (timerRef) {
        window.clearTimeout(timerRef)
        timerRef = null
      }
    }

  }, [toast, dispatch])


  return (
    toast ? <div ref={toastRef} className={toast.isError ? "threedy-toaster error" : "threedy-toaster"}>{toast.message}</div> : null
  )
}

export default ToasterComponent