import { useCallback, useEffect, useState } from "react"
import "./element-material-library.component.css"
import { useDispatch, useSelector } from "react-redux"
import MiscHelperMethods from "../../helper-methods/misc.helpers"
import ElementMaterialLibraryCategoryDropdown from "./components/element-material-library-category-dropdown.component"
import { setToast } from "../../../../redux/ui.redux.slice"
import { KLAVIYO_METRICS } from "../../../../constants"
import useLastUsedMaterials from "../../../../hooks/useLastUsedMaterials"
import ElementMaterialLibrarySearch from "./components/element-material-library-search.component"

const ElementMaterialLibrary = (props) => {

  const {onClose, appliedMaterial, onSetMaterial, apiCache} = props
  const {materials, lastUsedMaterials} = useSelector((state) => state.ui)
  const token = useSelector((state) => state.auth.access_token)
  const [materialCollection, setMaterialCollection] = useState([])
  const [selectedCategory, setSelectedCategory] = useState()
  const [isLoadingMatId, setIsLoadingMatId] = useState()
  const dispatch = useDispatch()
  const noResults = <li><h5 className="toggle-no-items">😞 Uh oh, we couldn't find anything matching your search...</h5></li>
  const [addAndUpdateLastUsedMaterial] = useLastUsedMaterials()

  // Set mat collection on load
  useEffect(() => {
    if (!materials || !materials.categories || materials.categories.length < 1) { return}

    setMaterialCollection(materials.categories)
  }, [materials])

  const handleSelectMaterial = async (material) => {
    if (appliedMaterial && appliedMaterial.id === material.id) {
      return
    }

    try {
      // We'll only show the loader if the material is not cached. We also want it to show for a minimum of 1s
      let mat
      let t1
      let shouldCache = true
      if (apiCache[material.id]) {
        const isMatInLastUsedMats = lastUsedMaterials.find(mat => mat.id === material.id)
        console.log('-- using api mat cache --')
        mat = isMatInLastUsedMats ? isMatInLastUsedMats : apiCache[material.id]
        shouldCache = false
      }
      else {
        setIsLoadingMatId(material.id)
        t1 = performance.now()
        mat = await MiscHelperMethods.getMaterialById(material, false, token)
      }

      addAndUpdateLastUsedMaterial(mat)
      window.klaviyo.track(KLAVIYO_METRICS.applied_material)
      onSetMaterial(mat, shouldCache)

      if (t1) {
        const t2 = performance.now()
        const timerVal = 1000 - (t2 - t1)
        setTimeout(() => {
          setIsLoadingMatId(false)
        }, timerVal)
      }
    }
    catch (e) {
      console.error("Error selecting material: ", e)
      dispatch(setToast({ message: "Uh oh. We had an issue generating that material, please try again.", isError: true }))
      setIsLoadingMatId(false)
    }
  }

  const handleSearchResults = useCallback((reset, results) => {
    if (selectedCategory) {
      setSelectedCategory(reset ? [...materials.categories].find((c) => c.name === selectedCategory.name) : results)
    }
    else {
      setMaterialCollection(reset ? [...materials.categories] : results)
    }

  }, [materials.categories, selectedCategory])

  const getTiles = () => {
    if (!selectedCategory) {
      if (materialCollection.length < 1) {
        return noResults
      }

      return (
        <ul className="toggle-material-library-materials">
          {materialCollection.map((c) => {
            const firstMat = c.materials[0]

            return (
              <li key={c.name} onClick={() => setSelectedCategory(c)}>
                <button><img src={MiscHelperMethods.getThumbnailForMaterial(firstMat)} alt={firstMat.name} /> <h6>{c.name} ({c.materials.length})</h6></button>
              </li>
            )
          })}
        </ul>
      )
    }

    if (selectedCategory.materials.length < 1) {
      return noResults
    }

    return (
      <ul className="toggle-material-library-materials">
        {selectedCategory.materials.map((m) => {
          const className = isLoadingMatId === m.id ? "loading" : (appliedMaterial && appliedMaterial.id === m.id ? "selected" : "")
          return (
            <li key={m.id} className={className}>
              <button onClick={() => handleSelectMaterial(m)}><img src={MiscHelperMethods.getThumbnailForMaterial(m)} alt={m.name} /> <h6>{m.name}</h6></button>
            </li>
          )
        })}
      </ul>
    )
  }

  return (
    <div className="toggle-material-library">
      <div className="toggle-material-library-top-bar">
        <div className="toggle-material-library-top-bar-left">
          <ElementMaterialLibraryCategoryDropdown category={selectedCategory} onSelectCategory={setSelectedCategory} />
          <ElementMaterialLibrarySearch category={selectedCategory} onSelectCategory={setSelectedCategory} onSearchResults={handleSearchResults} />
        </div>
        <div className="toggle-material-library-top-bar-right">
          <button onClick={onClose}><span className="icon-close" /></button>
        </div>
      </div>
      {getTiles()}
    </div>
  )
}

export default ElementMaterialLibrary