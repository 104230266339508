import "./checkbox.css"

const Checkbox = (props) => {

    return (
        <label className={props.disabled ? "custom-container-input disabled" : "custom-container-input"}>
            <input type="checkbox" readOnly checked={props.checked} onChange={props.onChange} />
            <span className="checkmark"></span>
        </label>
    )
}

export default Checkbox