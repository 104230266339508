import { createSlice } from "@reduxjs/toolkit"

const initialState = {
  materials: {}
}

const materialsSlice = createSlice({
  name: 'materials',
  initialState,
  reducers: {
    addMaterials: (state, action) => {
      state.materials = {...state.materials, ...action.payload}
    }
  }
})

export const { addMaterials } = materialsSlice.actions
export default materialsSlice.reducer