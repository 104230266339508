import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useEditModelMutation, useMakePersonalCollectionItemsVisibleMutation } from "../../redux/api.redux.slice"
import ModalComponent from "../modal/modal.component"
import { setSelectedProject, setToast, addToPersonalCollection } from "../../redux/ui.redux.slice"
import "./material-import-modal.component.css"
import Checkbox from "../forms/checkbox"
import useJustImportedVisibleMaterials from "../../hooks/useJustImportedVisibleMaterials"

const MaterialImportModalComponent = (props) => {

  const [makeMaterialsVisibleQuery] = useMakePersonalCollectionItemsVisibleMutation()
  const [updateModelQuery] = useEditModelMutation()
  const selectedProject = useSelector((state) => state.ui.selectedProject)
  const [visibleMaterials, setVisibleMaterials] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const dispatch = useDispatch()
  const { setMatIDs: setVisibleMatIDs } = useJustImportedVisibleMaterials()

  if (!props.items || props.items.length < 1) {
    return null
  }

  const toggleMaterialVisibility = (matId) => {
    const updated = {...visibleMaterials}
    if (visibleMaterials[matId]) {
      delete updated[matId]
    }
    else {
      updated[matId] = true
    }

    setVisibleMaterials(updated)
  }

  const handleSave = async () => {

    setIsLoading(true)
    const materialIds = []
    const materials = []

    try {
      
      for (let i = 0; i < props.items.length; i++) {
        const matId = props.items[i].material.id
        const visible = visibleMaterials[matId] ? true : false
        if (visible) {
          materialIds.push(matId)
          // Make the materials visible in the redux
          const material = {...props.items[i].material, user_visible: true }
          materials.push(material)
        }
      }

      // Set visible
      if (materialIds.length > 0) {
        await makeMaterialsVisibleQuery({body: {materialIds}}).unwrap()
        setVisibleMatIDs(new Set(materialIds))
        dispatch(setToast({message: "Your collection has been updated successfully!"}))
        dispatch(addToPersonalCollection(materials))
      }

      // Mark done on model
      await updateModelQuery({modelId: props.modelId, body: {did_import_materials: true}})

      // Update redux
      dispatch(setSelectedProject({...selectedProject, did_import_materials: true}))

      // Close
      props.onClose()
    }
    catch (e) {
      console.error("Error saving collection: ", e)
      setIsLoading(false)
      
      if (materialIds.length > 0) {
        dispatch(setToast({message: "Uh oh, we had an issue saving your materials. Please try again", isError: true}))
      }
    }
  }

  const handleSelectAll = (event) => {
    if (event.target.checked === true) {

      const anObj = {}
      props.items.forEach((i) => {
        anObj[i.material.id] = true
      })

      setVisibleMaterials(anObj)

    } 
    else if (event.target.checked === false) {
      setVisibleMaterials({})
    }
  }

  const content = (
    <div className={isLoading ? "threedy-skeleton" : "" }>
      <h4 className="fw-400 fs-14">We found <span className="fw-600">{props.items.length}</span> {props.items.length === 1 ? 'material' : 'materials'} in your model. Select a material to save it to your collection.</h4>
      <div>
        <ul className="threedy-list">
          <li className="no-hover">
            <div className="small">
              <Checkbox onChange={handleSelectAll} />
            </div>
            <div className="normal">Name</div><div className="normal">Element</div></li>
          {props.items.map((p) => {
            return (
              <li key={p.material.id} onClick={() => toggleMaterialVisibility(p.material.id)}>
                <div className="small no-action">
                  <Checkbox
                    readOnly
                    checked={visibleMaterials[p.material.id] ? "checked" : ""}
                  />
                </div>
                <div className="normal"><span className="variant-thumb" style={{backgroundImage: `url(${p.thumb})`}}></span>  {p.material.name}</div>
                <div className="normal">{p.part}</div>
              </li>
            )
          })}
        </ul>
      </div>
    </div>
  )

  const modalActions = (
    <>
      <button disabled={isLoading} className="cancel-btn btn-default" onClick={props.onClose}>Don't Import</button>
      <button className="outline-btn btn-default" onClick={handleSave} disabled={isLoading || Object.keys(visibleMaterials).length < 1}>{isLoading ? "Saving..." : "Import"}</button>
    </>
  )

  return (
    <ModalComponent title="Import Materials to Your Uploads" content={content} actions={modalActions} close={props.onClose} headerClassName="fw-600 fs-22" />
  )
}

export default MaterialImportModalComponent