import { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import SpinnerComponent from "../../../components/spinner/spinner.component"
import { useAttachExternalApplicationMutation, useLazyGetAvailableIntegrationsQuery, useRevokeExternalApplicationMutation } from "../../../redux/api.redux.slice"
import { setExternalApplications } from "../../../redux/auth.redux.slice"
import { setToast } from "../../../redux/ui.redux.slice"

const IntegrationsSettingsSection = () => {

  const dispatch = useDispatch()
  const [getIntegrationsRequest] = useLazyGetAvailableIntegrationsQuery()
  const [addAppRequest] = useAttachExternalApplicationMutation()
  const [revokeAppRequest] = useRevokeExternalApplicationMutation()
  const [loading, setLoading] = useState(true)
  const [availableApps, setAvailableApps] = useState([])
  const externalApps = useSelector((state) => state.auth.external_applications)
  const isFirstLoadRef = useRef(true)

  // Get integrations on load
  useEffect(() => {
    const fetchIntegrations = async () => {
      try {
        const apps = await getIntegrationsRequest().unwrap()
        setAvailableApps(apps)
        setLoading(false)
      }
      catch (e) {
        dispatch(setToast({message: "Uh oh. We had an issue fetching the available integrations. Please try again", isError: true}))
        setLoading(false)
      }
    }

    fetchIntegrations()

  }, [dispatch, getIntegrationsRequest])

  const addAritizePlugin = useCallback(async (code, url, redirect) => {

    try {
      const params = {
        type: "aritize3D",
        code,
        url,
        redirect
      }

      await addAppRequest(params).unwrap()
      
      // Update external apps
      const updated = {...externalApps}
      updated.aritize3D = true
      dispatch(setExternalApplications(updated))

      // Check for redirect
      const redir = window.sessionStorage.getItem("toggle-aritize-redirect")
      if (redir) {
        window.sessionStorage.removeItem("toggle-aritize-redirect")
        window.location.href = redir
      }
      else {
        // Update url
        window.history.replaceState({}, '', `${window.location.pathname}`)

        // Done
        setLoading(false)
      }
    }
    catch (e) {
      console.error("Error adding plugin: ", e)
      setLoading(false)
      dispatch(setToast({message: "Uh oh. We had an issue connecting to your integration. Please try again", isError: true}))
    }
  }, [dispatch, addAppRequest, externalApps])

  // Handle callbacks
  useEffect(() => {

    const urlParams = new URLSearchParams(window.location.search)
    const cb = urlParams.get("cb")
    const state = urlParams.get("state")
    const code = urlParams.get("code")

    if (isFirstLoadRef.current && cb === 'true' && state === 't3d' && code.length > 0) {
      setLoading(true)
      const {origin, pathname} = window.location
      const redirect = `${origin}${pathname}?cb=true`
      const arPlugin = availableApps.find((a) => a.key === "aritize3D")

      if (arPlugin) {
        addAritizePlugin(code, arPlugin.baseUrl, redirect)
        isFirstLoadRef.current = false
      }
    }

  }, [addAritizePlugin, availableApps])

  const handleAddApp = (url) => {
    window.location.href = url
  }

  const handleRevokeApp = async (type) => {
    setLoading(true)

    try {
      const params = {type}
      await revokeAppRequest(params).unwrap()
      
      // Update external apps
      const updated = {...externalApps}
      delete updated[type]
      dispatch(setExternalApplications(updated))

      // Done
      setLoading(false)
    }
    catch (e) {
      console.error("Error revoking plugin: ", e)
      setLoading(false)
      dispatch(setToast({message: "Uh oh. We had an issue revoking your integration. Please try again", isError: true}))
    }
  }

  return (
    <div className={loading ? "threedy-lab-page-styled threedy-skeleton" : "threedy-lab-page-styled"}>
      {loading && <SpinnerComponent />}
      <div className="settings-wrapper">
        <div className="settings-details">
          <h4>Integrations</h4>
        </div>
      </div>
      <ul className="toggle-integrations-list">
        {
          availableApps.map((i) => {
            return (
              <li key={i.key}>
                <img src={i.logo} alt={i.title} /> 
                <h5>{i.title}</h5>
                {externalApps && externalApps[i.key] ? 
                  <button className="delete-btn" onClick={() => handleRevokeApp(i.key)}>Revoke</button> 
                  : 
                  <button className="outline-btn" onClick={() => handleAddApp(i.consentUrl)}>Connect</button>
                }
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default IntegrationsSettingsSection