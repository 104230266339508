import { Engine } from "@babylonjs/core"
import { useEffect, useRef, useState } from "react"
import { createBaseScene, createLightingEnvironment, createMaterialScene, prepareCamera } from "../../../utils/ui.util"
import ModalComponent from "../../modal/modal.component"
import "./material-builder-modal.component.css"

const withMaterialBuilderModal = (Component) => {

  const WrappedComponent = (props) => {

    const {title, showMaterialSelection, showPreviewScene, albedoMaps, armMaps, normalMaps} = props
    const [ready, setReady] = useState(false)
    const canvasRef = useRef()
    const sceneRef = useRef()
    const matRef = useRef()
    const engineRef = useRef()

    useEffect(() => {

      const createScene = async () => {
        engineRef.current = new Engine(canvasRef.current, true)
        sceneRef.current = createBaseScene(engineRef.current)
        await createMaterialScene(sceneRef.current, albedoMaps, armMaps, normalMaps)

        // Lighting + camera
        matRef.current = sceneRef.current.materials[0]
        await createLightingEnvironment(sceneRef.current)
        prepareCamera(sceneRef.current)

        // Run
        engineRef.current.runRenderLoop(() => {
          sceneRef.current.render()
        })

        setReady(true)
      }

      // Setup scene as needed
      if (showPreviewScene) {
        createScene()
      }
      else {
        setReady(true)
      }
      
      const onWindowResized = () => {
        if (engineRef) {
          engineRef.current.resize()
        }
      }

      window.addEventListener('resize', onWindowResized)
    
      return () => {

        window.removeEventListener('resize', onWindowResized)

        if (matRef.current) {
          matRef.current.dispose(true, true)
          matRef.current = null
        }
        
        if (sceneRef.current) {
          sceneRef.current.dispose()
          sceneRef.current = null
        }

        if (engineRef.current) {
          engineRef.current.dispose()
          engineRef.current = null
        }
      }

    }, [showPreviewScene, albedoMaps, armMaps, normalMaps])

    const handleSwapMaterial = (newMat) => {
      matRef.current.dispose(true, true)
      matRef.current = newMat
      sceneRef.current.meshes[0].material = newMat
    }

    const content = (
      <div className="material-builder-modal-content">
        {showPreviewScene && (
          <div className="material-builder-modal-sphere">
            <canvas id="previewScene" touch-action="none" ref={canvasRef}/>
          </div>
        )}
        <div className="material-builder-modal-interface">
          {ready && <Component {...props} material={matRef.current} onSwapMaterial={handleSwapMaterial} scene={sceneRef.current}  />}
        </div>
      </div>
    )

    return <ModalComponent content={content} modalClassName="full-screen" title={title} close={showMaterialSelection}></ModalComponent>
  }

  return WrappedComponent
}

export default withMaterialBuilderModal