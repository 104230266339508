import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { EVENTS } from "../../constants"
import { useEditProjectImageMutation } from "../../redux/api.redux.slice"
import { hideProjectThumbnailModal, setToast } from "../../redux/ui.redux.slice"
import ModalComponent from "../modal/modal.component"
import "./project-thumb-modal.component.css"

const ProjectThumbModalComponent = (props) => {

  const dispatch = useDispatch()
  const [imageProp, setImageProp] = useState({src: false, name: false})
  const [updateProjectImage, {isLoading}] = useEditProjectImageMutation()


  const handleClose = () => {
    dispatch(hideProjectThumbnailModal())
  }

  useEffect(() => {
    setImageProp({src: props.project.image, name: false})
  }, [props.project])

  const handleSaveImage = async () => {
    const response = await fetch(imageProp.src)
    const blobData = await response.blob()
    const file = new File([blobData], imageProp.name.replace(/[\W_.]+/g, "_"))
    const data = new FormData()
    data.set("image", file)
    data.set("udf_image", "true")

    try {
      await updateProjectImage({projectId: props.project.id, body: data}).unwrap()
      document.dispatchEvent(new CustomEvent(EVENTS.PROJECT_THUMB_UPDATED))
      handleClose()
    }
    catch (e) {
      console.log("Error updating project image: ", e)
      dispatch(setToast({message: "Uh oh. We had an issue updating your project's thumbnail, please try again.", isError: true}))
    }
  }

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    const src = URL.createObjectURL(file)
    setImageProp({src: src, name: file.name})
  }

  const content = (
    <>
      <div className={isLoading ? "toggle-image-input threedy-skeleton" : "toggle-image-input"}>
        <input type="file" accept="image/*" id="image" onChange={handleFileChange} />
        <label htmlFor="image">Select an image</label>
        {imageProp.src && <img alt={props.project.name} src={imageProp.src} />}
        {!imageProp.src && <p>You haven't selected an image yet</p>}
      </div>
    </>
  )

  const modalActions = (
    <>
      <button className="toggle-default-btn" onClick={handleClose}>Cancel</button>
      <button className="toggle-primary-btn" disabled={isLoading || !imageProp.src || !imageProp.name} onClick={handleSaveImage}>Save</button>
    </>
  )

  return (
    <ModalComponent title={`Update Image: ${props.project.name}`} content={content} actions={modalActions} close={handleClose} />
  )
}

export default ProjectThumbModalComponent