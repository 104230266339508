import "./help-chat-window.css"
const HelpChatWindow = (props) => {

    return (
        <div id="help-chat-window-container">
            <div className="window-area">
                <div className="chat-window">
                    <header className="header">
                        <div className="header-row">
                            <h1 className="title">Help & Feedback</h1>
                            <button onClick={props.hideChatWindow} className="primary-btn minimize-button" type="button" aria-label="Minimize widget">
                                <svg width="16" height="16" viewBox="0 0 16 16" className="minimize-button-icon">
                                    <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M3 8h10"></path>
                                </svg>
                            </button>
                        </div>
                    </header>
                    <section className="chat-content">
                        <div id="help-chat-form" className="help-chat-form"></div>
                    </section>
                </div>
            </div>
        </div>
    )
}

export default HelpChatWindow