import { useCallback, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import DropdownComponent from "../dropdown/dropdown.component"
import SpinnerComponent from "../spinner/spinner.component"
import "./export-modal.component.css"
import { EVENTS, EXPORT_FILE_FORMATS } from "../../constants"
import Checkbox from "../forms/checkbox"
import MiscHelperMethods from "../../pages/workspace/helper-methods/misc.helpers"
import ModalComponent from "../modal/modal.component"
import ToggleButtonComponent from "../forms/toggle-button.component"

const ExportModalComponent = (props) => {

  const { onClose } = props
  const {selectedProject, projectSaving} = useSelector((state) => state.ui)
  const { plan } = useSelector((state) => state.auth)
  const [tab, setTab] = useState(1)
  const [exportOptions, setExportOptions] = useState({})
  const [resolutions, setResolutions] = useState([])
  const timerRef = useRef()

  // Disable editor keyboard listener
  useEffect(() => {
    
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: false}}))

    // Resume keyboard events
    return () => document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: true}}))

  }, [])

  const handleUpdateOption = (e, prop, val) => {
    e.stopPropagation()
    setExportOptions({...exportOptions, [prop]: val})
  }

  const getResolutions = useCallback((projectId) => {
    return new Promise (async (resolve, reject) => {
      try {
        console.log('getResolutions')
        const {resolutions} = await MiscHelperMethods.getProjectResolutions(projectId)
        setResolutions(resolutions)

        // If we have any pending resolutions, let's auto refresh the resolutions
        const hasPending = resolutions.find((r) => r.disabled)

        if (timerRef.current) {
          clearTimeout(timerRef.current)
          timerRef.current = null
        }

        if (hasPending) {
          timerRef.current = setTimeout(() => {
            console.log('refresh project resolutions')
            getResolutions(projectId)
          }, 5000)
        }

        resolve(resolutions)
      }
      catch (e) {
        console.error("Error fetching project resolutions: ", e)
        reject()
      }
    })
  }, [])

  useEffect(() => {
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
    }
  }, [])

  // Check to see which resolutions are available
  useEffect(() => {

    const populateFirstResolution = async () => {
      try {
        const resolutions = await getResolutions(selectedProject.id)

        // Set resolution as first non-disabled one
        const firstAvail = resolutions.find((r) => !r.disabled)
        if (firstAvail) {
          setExportOptions({name: selectedProject.name, formats: {}, bake: false, resolution: firstAvail.id})
        }
        else {
          setExportOptions({name: selectedProject.name, formats: {}, bake: false, resolution: false})
        }
      }
      catch (e) {
        console.error("Error fetching project resolutions: ", e)
      }
    }

    populateFirstResolution()
  
    // Continue
    setTab(2)

  }, [selectedProject, getResolutions])

  const handleExport = async () => {
    setTab(3)
    document.dispatchEvent(new CustomEvent(EVENTS.START_EXPORT, {detail: {...exportOptions, destination: "download"}}))
  }

  const initialLoadingContent = (
    <>
      <div className="threedy-lab-spinner-wrapper small">
        <SpinnerComponent inline />
        <div className="threedy-lab-spinner-copy">Please wait - we're loading some settings.</div>
      </div>
    </>
  )
  
  const handleSelectAll = (e) => {
    // Map's perf. is better than Object for frequent add and delete ops
    const updated = new Map(Object.entries(exportOptions.formats))
    EXPORT_FILE_FORMATS.forEach((f) => {
      if (e.currentTarget.checked) {
        updated.set(f.id, true)
      } else {
        updated.delete(f.id)
      }
    })

    setExportOptions({...exportOptions, formats: Object.fromEntries(updated)})
  }

  const handleToggleOption = (key) => {
    const updated = {...exportOptions.formats}
    if (updated[key]) {
      delete updated[key]
    }
    else {
      updated[key] = true
    }

    setExportOptions({...exportOptions, formats: updated})
  }

  const settingsContent = (
    <>
      <h5>Settings</h5>
      {projectSaving.saving && <h5>Wrapping up your project settings...</h5>}
      <h6>File Formats</h6>
      <ul className="toggle-content-list">
        <li>
          <div className="initial">
            <Checkbox disabled={projectSaving.saving} onChange={handleSelectAll} />
          </div>
          <div className="not-justified">Format</div>
        </li>
        {exportOptions.formats && EXPORT_FILE_FORMATS.map((f) => {
          return (
            <li key={f.id} className={projectSaving.saving ? "disabled" : "actionable"} onMouseDown={() => handleToggleOption(f.id)}>
              <div className="initial">
                <Checkbox
                  disabled={projectSaving.saving}
                  readOnly
                  checked={exportOptions.formats[f.id] ? "checked" : ""}
                />
              </div>
              <div className="not-justified">{f.label}</div>
            </li>
          )})
        }
      </ul>
      {resolutions.length > 1 && (
        <DropdownComponent disabled={projectSaving.saving} stopPropagation label="Resolution" id="resolution" value={exportOptions.resolution} onChange={handleUpdateOption} options={resolutions}></DropdownComponent>
      )}
      {plan && plan.type === 'pro' && (
        <ToggleButtonComponent onClick={() => setExportOptions({...exportOptions, bake: !exportOptions.bake})} isToggleOn={exportOptions.bake} inlineText="Combine textures into one material" />
      )}
      <div className="toggle-flex-container">
        <h6>Export as</h6>
        <input className="toggle-text-input" type="text" disabled={projectSaving.saving} placeholder="Name of export..." value={exportOptions.name ? exportOptions.name : ""} onBlur={(e) => e.stopPropagation()} onClick={(e) => e.stopPropagation()} onFocus={(e) => e.stopPropagation()} onChange={(e) => handleUpdateOption(e, 'name', e.target.value)} />
      </div>
    </>
  )

  const isExportingContent = (
    <>
      <h6>Please wait - your model is being prepared.</h6>
      <div className="threedy-lab-spinner-wrapper small">
        <SpinnerComponent inline />
      </div>
    </>
  )

  // Listen for export started event
  useEffect(() => {
    document.addEventListener(EVENTS.EXPORT_STARTED, onClose)

    return () => document.removeEventListener(EVENTS.EXPORT_STARTED, onClose)
  }, [onClose])

  const content = (
    <div className="toggle-export-container">
      <div>
        {tab === 1 ? initialLoadingContent : tab === 2 ? settingsContent : isExportingContent}
      </div>
    </div>
  )

  const actions = (
    <div className="toggle-btn-group">
      <button className="toggle-outline-filled-btn" onClick={onClose}>Cancel</button>
      <button className="toggle-primary-btn" disabled={tab === 3 || !exportOptions.name || Object.keys(exportOptions.formats).length < 1} onClick={handleExport}>Download</button>
    </div>
  )

  return (
    <ModalComponent title="Export" content={content} close={onClose} actions={actions} />
  )
}

export default ExportModalComponent