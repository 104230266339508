import { useState } from "react"
import { useDispatch } from "react-redux"
import { closeCircle, upload } from "../../assets"
import { setToast } from "../../redux/ui.redux.slice"
import FileUploadLimitMaximumModalComponent from "../alerts/file-upload-limit-maximum-modal.component"
import SimpleAlertModalComponent from "../alerts/simple-alert-modal.component"
import "./material-image-search.component.css"
import ModalComponent from "../modal/modal.component"

const MaterialImageSearchComponent = (props) => {

  const {setImageSearchResult, onClose} = props
  const dispatch = useDispatch()
  const [imageFile, setImageFile] = useState(false)
  const [showUploadLimitMaxModal, setShowUploadLimitMaxModal] = useState(false)
  const [showMultipleFilesUploadedModal, setshowMultipleFilesUploadedModal] = useState(false)
  const [isLoading, setIsLoading] = useState()

  const handleFileChange = (e) => {
    const file = e.target.files[0]
    handleFileload(file)
    e.target.value = null
  }

  const handleFileload = (file) => {
    const maxFileSize = 4 * 1024 * 1024  // 4mb max
    if (file.size > maxFileSize) {
      setShowUploadLimitMaxModal(true)
      setImageFile(false)
      return
    }

    const reader = new FileReader()
    reader.onload = (e) => {
      const thumb = e.target.result
      setImageFile({ src: file, name: file.name, thumb })
    }
    reader.readAsDataURL(file)
  }

  const handleLoad = async () => {

    // Create form
    setIsLoading(true)
    const data = new FormData()
    data.set("query_img", imageFile.src)

    try {
      // Upload model
      const materialRes = await fetch("https://py.toggle3d.com/api/ims/image_search", {
        method: "POST",
        body: data
      }).then((r) => r.json())
      // Filter results
      setIsLoading(false)
      setImageSearchResult(materialRes.results, imageFile.name)
    }
    catch (e) {
      console.log("Error uploading image: ", e)
      dispatch(setToast({message: "Uh oh. We had an issue loading that file, please try again.", isError: true}))
      setIsLoading(false)
    }
  }

  const handleResetUpload = async () => {
    setImageFile(false)
    setIsLoading(false)
    return
  }
  
  const dropHandler = (ev) => {
    ev.preventDefault();
    ev.currentTarget.classList.remove("drop-section")
    if (ev.dataTransfer.items) {
      if (ev.dataTransfer.items.length > 1) {
        setshowMultipleFilesUploadedModal(true)
        return false
      }
      [...ev.dataTransfer.items].forEach((item, i) => {
        if (item.kind === 'file') {
          const file = item.getAsFile()
          handleFileload(file)
        }
      })
    }
  }
  
  const dragOverHandler = (ev) => {
    ev.preventDefault();
    ev.currentTarget.classList.add('drop-section')
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.currentTarget.classList.remove("drop-section")
  }

  const actions = (
    <div className="toggle-btn-group">
      <button className="toggle-outline-filled-btn" onClick={onClose}>Cancel</button>
      <button className="toggle-primary-btn" disabled={!imageFile || isLoading} onClick={handleLoad}>{isLoading ? "Searching..." : "Search"}</button>
    </div>
  )

  const content = (
    <>
      <div className={isLoading ? "toggle-material-search loading" : "toggle-material-search"}>

        {!imageFile &&
          <div className="uploader-image-search" onDrop={dropHandler} onDragLeave={handleDragLeave} onDragOver={dragOverHandler}>
            <div>{upload}</div>
            <div>
              <p className="fw-400 fs-12">Drag an image here or</p>
            </div>
            <div>
              <input type="file" id="upload-image-to-search" className="hidden" onChange={handleFileChange} accept=".jpeg,.jpg,.png" />
              <label htmlFor="upload-image-to-search" className="hidden-input-label fw-400 fs-12 border-dark">Choose File</label>
            </div>
          </div>
        }
        {imageFile && imageFile.src && (
          <>
            <div className="after-upload-image-container">
              <div className="left">
                <img src={imageFile.thumb} alt="preview thumb" />
                <span className="reset-upload-button" onClick={handleResetUpload}> {closeCircle}</span>
              </div>
              <div className="right">
                <h6>{imageFile.name}</h6>
                <input type="file" id="upload-image-to-search-again" className="hidden" onChange={handleFileChange} accept=".jpeg,.jpg,.png" />
                <label htmlFor="upload-image-to-search-again" className="hidden-input-label fw-400 fs-12 border-dark">Choose a New File</label>
              </div>
            </div>
          </>
          )}
      </div>
      {showUploadLimitMaxModal && <FileUploadLimitMaximumModalComponent close={() => setShowUploadLimitMaxModal(false)} />}
      {showMultipleFilesUploadedModal && (
        <SimpleAlertModalComponent
          title="Not Allowed"
          subTitle="You are trying to upload many images to search. Upload multiple images not allowed!"
          close={() => setshowMultipleFilesUploadedModal(false)} />
      )}
    </>
  )

  return <ModalComponent title="Texture Image Search" close={onClose} content={content} actions={actions} />
}

export default MaterialImageSearchComponent