import moment from "moment"
import SpinnerComponent from "../../../components/spinner/spinner.component"
import { useRef, useState } from "react"
import { useAdminGetUsersQuery, useAdminSearchUserByEmailMutation } from "../../../redux/api.redux.slice"
import AdminUserSidebar from "../admin.user.sidebar"

const AdminUserListSection = () => {
  const [search, setSearch] = useState("")
  const [emptySearchResults, setEmptySearchResults] = useState(false)
  const [searching, setSearching] = useState(false)
  const [selectedUser, setSelectedUser] = useState(false)
  const debounceRef = useRef()
  const {data: users, isLoading} = useAdminGetUsersQuery()
  const [searchUserQuery] = useAdminSearchUserByEmailMutation()

  const handleSelectUser = (user) => {
    setSelectedUser(user)
  }

  const handleSearchUpdate = (term) => {
    setSearch(term)

    // Debounce search
    if (debounceRef.current) {
      clearTimeout(debounceRef.current)
      debounceRef.current = null
    }

    debounceRef.current = setTimeout(() => handleDoSearch(term), 500)
  }

  const handleDoSearch = async (term) => {
    try {
      setSearching(true)
      setEmptySearchResults(false)
      const result = await searchUserQuery({email: term}).unwrap()
      setEmptySearchResults(!result)
      setSearching(false)
      if (result) {
        const aUser = users.find((u) => u.id === result.user_id)
        setSelectedUser(aUser)
      }
    }
    catch (e) {
      console.error(e)
      setSearching(false)
      setEmptySearchResults(true)
    }
  }

  const handleClearSearch = () => {
    setSearch("")
    setEmptySearchResults(false)
    setSelectedUser(false)
    setSearching(false)
  }

  return (
    <>
      <h4 className="margin-top">Users</h4>
      {!isLoading && users && users.length < 1 && <h5>No users just yet...</h5>}
      {isLoading && (
        <div className="threedy-lab-spinner-wrapper">
          <SpinnerComponent inline />
        </div>
      )}
      { selectedUser && <AdminUserSidebar user={selectedUser} onClose={() => setSelectedUser(false)} /> }
      {!isLoading && users.length > 0 && (
        <div className="toggle-admin-list-search">
          <h5>Search by email</h5>
          <input type="text" value={search} onChange={(e) => handleSearchUpdate(e.target.value)} placeholder="Email address..." />
          {searching && <h5>Searching...</h5> }
          {!searching && search.length > 0 && <button onClick={handleClearSearch}>Clear</button>}
          {emptySearchResults && <span className="delete-btn">No results matching your search...</span>}
        </div>
      )}
      <ul className="toggle-admin-list">
        {
          users && users.map((user) => {
            return (
              <li key={user.id} className={selectedUser.id === user.id ? "selected" : ""} onClick={() => handleSelectUser(user)}>
                <div className="col">
                  <h4>{user.id}</h4>
                  <div className="admin-info-tag-set">
                    <div className="admin-info-tag">
                      <span className="icon-trello"></span> {user.num_projects_created} {parseInt(user.num_projects_created) === 1 ? "project" : "projects"}
                    </div>
                    {user.last_project_update_ts && (
                      <div className="admin-info-tag invert">
                        <span className="icon-upload-cloud"></span> {moment(user.last_project_update_ts).fromNow()}
                      </div>
                    )}
                  </div>
                </div>
                <div className="col">
                  {user.num_models_imported > 0 && (
                    <>
                      <div className="admin-info-tag-set">
                        <div className="admin-info-tag green">
                          <span className="icon-layers"></span> {user.num_models_imported} {parseInt(user.num_models_imported) === 1 ? "model" : "models"} imported
                        </div>
                        {user.num_models_imported_error > 0 && (
                          <div className="admin-info-tag red">
                            <span className="icon-code"></span> {user.num_models_imported_error} {parseInt(user.num_models_imported_error) === 1 ? "error" : "errors"}
                          </div>
                        )}
                      </div>
                    </>
                  )}
                </div>
                <div className="col"><h5>Created {`${moment(user.created_ts).fromNow()}`}</h5></div>
              </li>
            )
          })
        }
      </ul>
    </>
  )
}

export default AdminUserListSection