import { useSelector } from 'react-redux'
import './header.component.css'
import NonProjectHeader from './components/non-project-header.component'
import ProjectHeader from './components/project-header.component'


const HeaderComponent = (props) => {
  const selectedProject = useSelector((state) => state.ui.selectedProject)

  return (
    <header className="toggle-header">
      {selectedProject ? <ProjectHeader project={selectedProject} /> : <NonProjectHeader />}    
    </header>
  )

  // const navigate = useNavigate()
  // const {user, plan, quota} = useSelector((state) => state.auth)
  // const {selectedProject, projectSaving, showProjectSharingModal:isShowingShare, showViewerShare:isShowingViewerShare} = useSelector((state) => state.ui)
  // const {queue, redoQueue} = useSelector((state) => state.history)
  // const [showUserMenu, setShowUserMenu] = useState(false)
  // const dispatch = useDispatch()
  // const [isEditingProjectName, setIsEditingProjectName] = useState(false)
  // const [newProjectName, setNewProjectName] = useState('')
  // const [updateProject, {isLoading}] = useEditProjectMutation()
  // const [addProject, {isLoading:addProjectLoading}] = useAddNewProjectMutation()
  // const [showModelImport, setShowModelImport] = useState(false)
  // const [showingDebugPanel, setShowingDebugPanel] = useState(false)
  // const [showExportModal, setShowExportModal] = useState(false)
  // const [showExportStartedAlert, setShowExportStartedAlert] = useState(false)
  // const { shared, publicView, exhausted } = props





  // const handleDebugProject = () => {
  //   const newValue = !showingDebugPanel
  //   setShowingDebugPanel(newValue)
  //   document.dispatchEvent(new CustomEvent(EVENTS.SHOW_DEBUG, {detail: {enabled: newValue}}))
  // }

  // const handleCloseExport = (closePanel) => {
  //   setShowExportModal(false)

  //   if (closePanel) {
  //     setShowUserMenu(false)
  //   }
  // }

  // const handleHelpClick = () => {
  //   dispatch(setShowViewerHelp(true))
  // }

  // const handleShareClick = (e) => {
  //   e.stopPropagation()
  //   dispatch(setShowViewerShare(true))
  // }

  // const handleArClick = () => {
  //   dispatch(setShowViewerAR(true))
  // }




  // return (
  //   <header className={props.shared || (user && !isLoading && !addProjectLoading) ? "threedy-lab-header" : "threedy-lab-header threedy-skeleton"}>
  //     
  //     {shared ? <img src="/images/Toggle3D_Horizontal_Dark.png" alt="toggle logo" /> : null }
  //     {!shared && (
  //       <>
  //         <button disabled={showUserMenu || !plan || exhausted} className="header-action-btn" onClick={(e) => handleShowFileMenu(e)}>
  //           File
  //           <span className="icon-chevron-down"></span>
  //         </button>
  //         <button disabled={showUserMenu || queue.length < 1} className="header-action-btn" onClick={handleUndo}>
  //           Undo
  //         </button>
  //         <button disabled={showUserMenu || redoQueue.length < 1} className="header-action-btn" onClick={handleRedo}>
  //           Redo
  //         </button>
  //       </>
  //     )}     
  //     {
  //       !props.editorView && (
  //         <div className="header-save-info">
  //           {selectedProject && !isEditingProjectName && !props.shared && (
  //             <div className="save-info-indicator">
  //               <img className={projectSaving.saving ? 'is-saving' : ''} src={`/images/cloud-${projectSaving.saving ? 'saving' : 'saved'}.png`} alt="cloud status" />
  //             </div>
  //           )}
  //           {selectedProject && !isEditingProjectName && (
  //             <>
  //               <h4>
  //                 <span></span>
  //                 {!props.shared && !props.publicView && <span className='edit-project-name icon-edit-2' onClick={handleProjectNameDoubleClick}></span>}
  //               </h4>
  //             </>
  //           )}
  //           {!props.shared && selectedProject && isEditingProjectName && <input type="text" autoFocus value={newProjectName} maxLength={90} onBlur={handleFinishEditingName} onKeyDown={(e) => handleKeyDown(e)} placeholder="Project Name..." onChange={(e) => setNewProjectName(e.target.value)} />}
  //         </div>
  //       )
  //     } 
  //     <div className="header-publish-info">
  //       {selectedProject && MiscHelperMethods.isConfiguratorOr3dOrVariationsProject(selectedProject) && !props.shared && selectedProject.model_id && selectedProject.model_status === 'ready' && (
  //         <>
  //           {IS_DEBUG_ENV && <button className={showingDebugPanel ? "header-outline-btn selected" : "header-outline-btn"} onClick={handleDebugProject}>Debug</button>}
  //           <button disabled={isShowingShare} className="header-outline-btn" onClick={handleShowProjectSharing}>Share</button>
  //         </>
  //       )}
  //     </div>

  //     {publicView && (
  //       <div className="header-public-menu">
  //         <button onClick={handleHelpClick}><span className="icon-help-circle"></span></button>
  //         <button onClick={handleShareClick} disabled={isShowingViewerShare}><span className="icon-share"></span></button>
  //         <button onClick={handleArClick}><img src="/images/ar-icon.png" alt="View in AR" /> View in AR</button>
  //       </div>
  //     )}
  //   </header>
  // )
}

export default HeaderComponent