const profileTab = [
    {
        id: 'settings',
        link: 'settings/profile',
        name: 'Settings',
        meta: "settings, update",
        breadcrumbs: ["Settings"]
    },
    {
        id: 'darkMode',
        link: 'settings/profile',
        name: 'Dark Mode',
        meta: "theme, dark mode, darker, night mode, appearance, brightness",
        breadcrumbs: ["Settings", "Profile", "Profile Details"]
    },
    {
        id: 'lightMode',
        link: 'settings/profile',
        name: 'Light Mode',
        meta: "theme, light mode, lighter, morning, light mode, appearance, brightness",
        breadcrumbs: ["Settings", "Profile", "Profile Details"]
    },
    {
        id: 'name',
        link: 'settings/profile',
        name: 'Full Name',
        meta: "update name, full name, profile",
        breadcrumbs: ["Settings", "Profile", "Profile Details", "Full Name"]
    },
    {
        id: 'email',
        link: 'settings/profile',
        name: 'Email',
        meta: "update email, email, profile",
        breadcrumbs: ["Settings", "Profile", "Profile Details", "Email"]
    },
    {
        id: 'company_name',
        link: 'settings/profile',
        name: 'Company Name',
        meta: "company name, designation, working",
        breadcrumbs: ["Settings", "Profile", "Profile Details", "Company Name"]
    },
    {
        id: 'usage',
        link: 'settings/profile',
        name: 'Usage',
        meta: "usage, used space",
        breadcrumbs: ["Settings", "Profile", "Usage"]
    },
    {
        id: 'material_time_size',
        link: 'settings/profile',
        name: 'Material Tile Size',
        meta: "material tile size, thumb size",
        breadcrumbs: ["Settings", "Profile", "Material Tile Size"]
    },
]
const securityTab = [
    {
        id: 'password',
        link: 'settings/security',
        name: 'Update Password',
        meta: "update password, security",
        breadcrumbs: ["Settings", "Security", "Update Password"]
    },
]
const plansTab = [
    {
        id: 'plans',
        link: 'settings/plans',
        name: 'Manage Subscription',
        meta: "manage subscription, plans, free plan, pro plan, upcoming invoice, monthly",
        breadcrumbs: ["Settings", "Plans", "Manage Subscription"]
    },
]
const helpPage = [
    {
        id: 'help',
        link: 'help',
        name: 'Help & Support',
        meta: "help and support, videos, topic, learn, how to",
        breadcrumbs: ["Help"]
    },
]
const tutorialsPage = [
    {
        id: 'tutorials',
        link: 'tutorials',
        name: 'Tutorials',
        meta: "help and support, videos, topic, learn, how to",
        breadcrumbs: ["Tutorials"]
    },
    {
        id: 'uploading_a_file',
        link: 'tutorials',
        name: 'Uploading a File',
        meta: "Uploading a File,how to learn how to upload a CAD file or 3D model, help, support, videos, topic, learn ",
        breadcrumbs: ["Tutorials", "Uploading a File"]
    },
    {
        id: 'scene_navigation',
        link: 'tutorials',
        name: 'Scene Navigation',
        meta: "scene navigation, learn how to navigate the scene, help, support, videos, topic, learn",
        breadcrumbs: ["Tutorials", "Scene Navigation"]
    },
    {
        id: 'grouping_elements',
        link: 'tutorials',
        name: 'Grouping Elements',
        meta: "grouping elements, learn how to group elements on a model, help, support, videos, topic, learn",
        breadcrumbs: ["Tutorials", "Grouping Elements"]
    },
    {
        id: 'select-editing-elements',
        link: 'tutorials',
        name: 'Selecting/Editing Elements',
        meta: "selecting editing elements, learn how to select and rename elements on a model, help, support, videos, topic, learn",
        breadcrumbs: ["Tutorials", "Grouping Elements"]
    },
    {
        id: 'updating-materials',
        link: 'tutorials',
        name: 'Updating Materials',
        meta: "updating materials, learn how to update/edit materials on a model, help, support, videos, topic, learn",
        breadcrumbs: ["Tutorials", "Updating Materials"]
    },
    {
        id: 'how-to-edit-patterns',
        link: 'tutorials',
        name: 'How to edit patterns',
        meta: "how to edit patterns, learn how to adjust the UV settings for a mesh, help, support, videos, topic, learn",
        breadcrumbs: ["Tutorials", "How to edit patterns"]
    },
    {
        id: 'updating-the-lighting',
        link: 'tutorials',
        name: 'Updating the Lighting',
        meta: "updating the lighting, learn how to change the lighting in your design scene, help, support, videos, topic, learn",
        breadcrumbs: ["Tutorials", "Updating the Lighting"]
    },
    {
        id: 'advanced-material-settings',
        link: 'tutorials',
        name: 'Advanced Material Settings',
        meta: "advanced material settings, learn how to customize properties of a material, help, support, videos, topic, learn",
        breadcrumbs: ["Tutorials", "Advanced Material Settings"]
    },
    {
        id: 'creating-a-configurator',
        link: 'tutorials',
        name: 'Creating a Configurator',
        meta: "creating a configurator, learn how to create a configuration of a model, help, support, videos, topic, learn",
        breadcrumbs: ["Tutorials", "Creating a Configurator"]
    },
    {
        id: 'material-image-search',
        link: 'tutorials',
        name: 'Material Image Search',
        meta: "material image search, learn how to search for a material by uploading a reference image, help, support, videos, topic, learn",
        breadcrumbs: ["Tutorials", "Material Image Search"]
    },
]
const dashboardPage = [
    {
        id: 'dashboard',
        link: 'dashboard',
        name: 'Dashboard',
        meta: "dashboard, home, root",
        breadcrumbs: ["Dashboard"]
    },
]
const exportsPage = [
    {
        id: 'exports',
        link: 'notifications',
        name: 'Exports',
        meta: "exports, notifications",
        breadcrumbs: ["Exports", "Notifications"]
    },
]
const editorPage = [
    {
        id: 'editor-page',
        link: 'editor',
        name: 'Editor',
        meta: "editor, create, configurator",
        breadcrumbs: ["Editor"]
    },
    {
        id: 'editor-page-texture3d',
        link: 'editor',
        name: 'Texture a 3D Model',
        meta: "Texture a 3D Model, editor, create, configurator",
        breadcrumbs: ["Editor", "Texture a 3D Model"]
    },
    {
        id: 'editor-build-a-Configurator',
        link: 'editor',
        name: 'Build a Configurator',
        meta: "Build a Configurator, editor, create, configurator, build",
        breadcrumbs: ["Editor", "Build a Configurator"]
    },
    {
        id: 'editor-build-create-model-variations',
        link: 'editor',
        name: 'Create Model Variations',
        meta: "Create Model Variations, editor, create, configurator, variations",
        breadcrumbs: ["Editor", "Create Model Variations"]
    },
    {
        id: 'editor-create-a-material',
        link: 'editor',
        name: 'Create a Material',
        meta: "create a material, editor, create, configurator, material",
        breadcrumbs: ["Editor", "Create a Material"]
    },
]
const projectsPage = [
    {
        id: 'to-projects',
        link: 'projects',
        name: 'Projects',
        meta: "projects",
        breadcrumbs: ["Projects"]
    },
    {
        id: 'to-folders',
        link: 'projects?to=folders',
        name: 'Folders',
        meta: "folders, projects",
        breadcrumbs: ["Projects", "Folders"]
    },
    {
        id: 'to-designs',
        link: 'projects?to=designs',
        name: 'Designs',
        meta: "designs, projects",
        breadcrumbs: ["Projects", "Designs"]
    },
    {
        id: 'to-uploads',
        link: 'projects?to=uploads',
        name: 'Uploads',
        meta: "uploads, projects, models",
        breadcrumbs: ["Projects", "Uploads"]
    },
    {
        id: 'to-my-materials',
        link: 'projects?to=my_materials',
        name: 'My Materials',
        meta: "my materials, projects",
        breadcrumbs: ["Projects", "My Materials"]
    },
]
const settingPage = [
    ...profileTab,
    ...securityTab,
    ...plansTab,
]

export const systemPaths = [
    ...dashboardPage,
    ...editorPage,
    ...projectsPage,
    ...settingPage,
    ...tutorialsPage,
    ...helpPage,
    ...exportsPage
]