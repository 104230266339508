import { useEffect, useState, useCallback, useRef } from "react"
import { useSelector } from "react-redux"
import { useParams } from "react-router-dom"
import SpinnerComponent from "../../components/spinner/spinner.component"
import { useLazyGetAvailableIntegrationsQuery, useLazyGetProjectByAritizeJobIdQuery } from "../../redux/api.redux.slice"
import WorkspacePage from "../workspace/workspace.page"
import OutdatedModelModalComponent from "../../components/outdated-model-modal/outdated-model-modal.component"

const Aritize3dViewerPage = () => {
  const { job_id } = useParams()
  const {user, external_applications} = useSelector((state) => state.auth)
  const authFlowRef = useRef()
  const [project, setProject] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [showOutdatedModelModal, setShowOutdatedModelModal] = useState(false)
  const [getIntegrationsRequest] = useLazyGetAvailableIntegrationsQuery()
  const [getAritizeJobRequest] = useLazyGetProjectByAritizeJobIdQuery()

  const navigateToAritizeIntegration = useCallback(async () => {
    try {
      const apps = await getIntegrationsRequest().unwrap()
      const aritize = apps && apps.find((a) => a.key === "aritize3D")

      if (!aritize) {
        setIsError("There was an error fetching this project. Please try again")
        setIsLoading(false)
        return
      }

      // Store job_id in session storage, then allow user to consent. After consent, we'll redirect them
      window.sessionStorage.setItem("toggle-aritize-redirect", window.location.href)

      // Now redirect to consent
      window.location.href = aritize.consentUrl
    }
    catch (e) {
      setIsLoading(false)
      setIsError("There was an error fetching this project. Please try again")
    }

  }, [getIntegrationsRequest])

  useEffect(() => {

    // todo: add check for user having external apps / aritize
    if (!job_id || !user || !external_applications || authFlowRef.current) {
      return
    }

    if (Object.keys(external_applications).length < 1) {
      // Redirect user to settings
      navigateToAritizeIntegration()
      authFlowRef.current = true
      return
    }

    const getProjectByJobId = async () => {
      try {
        const {isOutOfDate, lastUpload, modelId, project} = await getAritizeJobRequest(job_id).unwrap()
        setProject(project)
        setIsLoading(false)

        // Check if model is out of date
        if (isOutOfDate && lastUpload && modelId) {
          setShowOutdatedModelModal({modelId, lastUpload})
        }
      }
      catch (e) {
        const error = e.originalStatus && e.originalStatus === 403 ? "Uh oh. It looks like the job you selected has been started by another user. Please select another job and try again." : "There was an error fetching this project. Please try again"
        console.error(e)
        setIsError(error)
        setIsLoading(false)
      }
    }

    getProjectByJobId()

  }, [job_id, user, external_applications, getAritizeJobRequest, navigateToAritizeIntegration])

  const handleCompleteOutdatedPrompt = async (forceReload) => {
    setShowOutdatedModelModal(false)
    
    if (forceReload) {
      window.location.reload()
    }
  }

  return (
    !project ? (
      <div className={isLoading ? "threedy-lab-page-content threedy-skeleton" : "threedy-lab-page-content"}>
        { isError && <h5 className="error">{isError}</h5> }
        { !isError && <SpinnerComponent />}
      </div>
    ) : (!isError && project) ? (
      <>
        <WorkspacePage project={project} isAritize={true} />
        {showOutdatedModelModal && <OutdatedModelModalComponent done={handleCompleteOutdatedPrompt} modelId={showOutdatedModelModal.modelId} lastUpload={showOutdatedModelModal.lastUpload} close={() => setShowOutdatedModelModal(false)} />}
      </>
    ) : null
  )
}

export default Aritize3dViewerPage