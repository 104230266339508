import ModalComponent from "../modal/modal.component"

const SwitchToDesktopModalComponent = (props) => {

  const modalContent = (
    <>
      <p className="content-text">To proceed with Toggle3D please switch to a desktop computer as we work on optimizing the mobile experience. </p>
      <br />
      <p className="content-text">Thank you for your understanding.</p>
    </>
  )
  const actions = (
    <>
      <button className="toggle-default-btn" onClick={() => props.close()}>Close</button>
    </>
  )
  return (
    <ModalComponent title="Switch to Desktop"
      close={() => props.close()}
      content={modalContent}
      actions={actions} />
  )
}

export default SwitchToDesktopModalComponent