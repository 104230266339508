import { useRef, useState, useEffect } from "react"
import ModalComponent from "../../components/modal/modal.component"
import { APP_RESOURCES_URL, TUTORIAL_LINKS } from "../../constants"
import "./tutorials.page.css"
import ContextMenuComponent from "../../components/context-menu/context-menu.component"
import HeaderGroupComponent from "../../components/header-group/header-group.component"

const TutorialsPage = () => {
  const DEFAULT_SORT = { name: 'Sort', key: 'order', value: 'asc' }
  const [sortBy, setSortBy] = useState(DEFAULT_SORT)
  const listSortBy = [
    { name: 'A-Z', key: 'title', value: 'asc' },
    { name: 'Z-A', key: 'title', value: 'desc' },
    { name: 'Recent', key: 'order', value: 'asc' },
  ]
  const [videos, setVideos] = useState(TUTORIAL_LINKS)
  const [showVideoModal, setShowVideoModal] = useState(false)
  const [itemContextMenu, setItemContextMenu] = useState(false)
  const [searchKeyword, setSearchKeyword] = useState("")
  const searchInputReference = useRef(null);
  const sortListRef = useRef(null)
  const sortArrow = sortBy.name !== DEFAULT_SORT.name ? (sortBy.value === DEFAULT_SORT.value ? <span className="icon-arrow_drop_up"></span> : <span className="icon-arrow_drop_down"></span>) : null
  const sortList = [DEFAULT_SORT, ...listSortBy]
  const clearSearch = () => {
    setSearchKeyword("")
    searchInputReference.current.focus()
    search("")
  }

  const handleSortBy = (sortData) => {
    if (sortData.name === sortBy.name && sortData.value === sortBy.value) { return }
    setSortBy(sortData)
    toggleDropdown('sort')
    switch (sortData.key) {
      case 'title':
        if (sortData.value === 'asc') {
          setVideos([...TUTORIAL_LINKS.sort((a, b) => { return a.title.localeCompare(b.title) })])
        } else {
          setVideos([...TUTORIAL_LINKS.sort((a, b) => { return b.title.localeCompare(a.title) })])
        }
        break
      case 'order':
        setVideos([...TUTORIAL_LINKS.sort((a, b) => { return a.order - b.order })])
        break
      default:
        break
    }
  }

  const renderSortList = () => {
    return (

      <ul className="dropdown-items">
        {
          sortList.map((lfItem, index) => {
            const itemClass = lfItem.name === sortBy.name && lfItem.value === sortBy.value ? 'selected-item' : null
            const itemArrow = lfItem.name !== DEFAULT_SORT.name ? (lfItem.value === DEFAULT_SORT.value ? <span className="icon-arrow_drop_up"></span> : <span className="icon-arrow_drop_down"></span>) : null
            return <li key={index} title={lfItem.name} className={itemClass} onClick={() => handleSortBy(lfItem)}><span className="ellipsis-container">{lfItem.name}</span>&nbsp;{itemArrow}</li>
          })
        }
      </ul>
    )
  }
  const toggleDropdown = (type) => {
    switch (type) {
      case 'sort':
        sortListRef.current.classList.toggle('list-ops-active')
        break
      case 'sort_onBlur':
        sortListRef.current.classList.remove('list-ops-active')
        break
      default:
        break
    }
  }
  const handleShowClip = (video) => {
    const params = {
      title: video.title,
      src: `${APP_RESOURCES_URL}${video.videoLink}`,
    }
    setShowVideoModal(params)
  }
  const modalContent = (
    <div className="video-wrapper">
      <video controls muted src={showVideoModal.src} autoPlay />
    </div>
  )

  const modalActions = <button onClick={() => setShowVideoModal(false)} className="outline-btn">Close</button>

  const search = async (keyword) => {
    const sanitizedKeyWord = keyword.replace(/[^a-zA-Z0-9 ]/gi, '') // only numbers(0-9), letters(a-Z) and spaces
    setSearchKeyword(sanitizedKeyWord)
    const lKeyword = sanitizedKeyWord.toLowerCase()
    setVideos([...TUTORIAL_LINKS.filter((t) => {
      if (t.name.toLowerCase().includes(lKeyword) || t.title.toLowerCase().includes(lKeyword) || t.subTitle.toLowerCase().includes(lKeyword)) {
        return true
      }
      return false
    })])
  }

      // Handle state params on load
      useEffect(() => {

        const params = new URLSearchParams(window.location.search)
        const d = params.get("d")
        if (!d) {
          return
        }
  
        if (d === "uploading") {
          setTimeout(() => {
            handleShowClip(TUTORIAL_LINKS[0])
            window.history.replaceState({}, '', window.location.pathname)
          }, 50)
          return
        }
  
        if (d === "Navigation") {
          setTimeout(() => {
            handleShowClip(TUTORIAL_LINKS[1])
            window.history.replaceState({}, '', window.location.pathname)
          }, 50)
          return
        }
  
        if (d === "grouping") {
          setTimeout(() => {
            handleShowClip(TUTORIAL_LINKS[2])
            window.history.replaceState({}, '', window.location.pathname)
          }, 50)
          return
        }
  
        if (d === "renaming") {
          setTimeout(() => {
            handleShowClip(TUTORIAL_LINKS[3])
            window.history.replaceState({}, '', window.location.pathname)
          }, 50)
          return
        }
    
      }, [])

  return (
    <>
      <HeaderGroupComponent header="Tutorials" level="4" icon="icon-layers" headerClass="darkerText" />
      {showVideoModal && <ModalComponent title={showVideoModal.title} close={() => setShowVideoModal(false)} content={modalContent} actions={modalActions} />}
      {itemContextMenu && <ContextMenuComponent wide={itemContextMenu.wide} position={itemContextMenu.position} items={itemContextMenu.items} onClose={() => setItemContextMenu(false)} />}
      <div className="tutorial-container">
        <div className="toggle-content-group">
          <div className="search-filter-container">
            <span className="left-container">
              <div className="search-input-container">
                <span className="icon-search"></span>
                <input type="text" className="search-input-element"
                  placeholder="Search..."
                  onChange={(e) => search(e.target.value)}
                  value={searchKeyword}
                  ref={searchInputReference}
                  autoFocus
                />
                {searchKeyword.length > 0 && <span className="icon-close close-btn" onClick={() => clearSearch()}></span>}
              </div>
            </span>
            <span className="right-container">
              <div tabIndex="-1" ref={sortListRef} className="list-ops" onBlur={() => toggleDropdown('sort_onBlur')}>
                <div className="list-ops-content" onClick={() => toggleDropdown('sort')}>
                  <h6 title={sortBy.name}><span className="ellipsis-container">{sortBy.name}</span>&nbsp;{sortArrow}</h6>
                  <span className="icon-expand_more"></span>
                </div>
                {renderSortList()}
              </div>
            </span>
          </div>
          <div className="toggle-content-blocks unlimited-height">
            {videos.map(v => {
                return (
                  <div className="each-video-block" key={v.id} onClick={() => handleShowClip(v)}>
                    <div className="upper-container" role="button">
                      <span className="icon-play_circle play-button"></span>
                    </div>
                    <div className="lower-container">
                      <div className="tile-title">
                        <span>{v.title}</span>
                      </div>
                      <div className="tile-subtitle">{v.subTitle}</div>
                    </div>
                  </div>
                )
              })}

          </div>
        </div>
      </div>
    </>
  )
}

export default TutorialsPage
