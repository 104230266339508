import "./tile.component.css";

const TileComponent = (props) => {
    const { tileHeader, tileDesc, onTileActionsClick, tileLink, tileLinkDisplay, tileHeaderOnly, tileImage, tileSvg, tileTime, onTileClick, projectImage, tileImageClassName, tileStatus, tileWrapperClassName, icon } = props
    
    let wrapperClass = 'tile-component-wrapper'
    if (onTileClick) {
        wrapperClass += ' tile-component-wrapper-clickable'
    }

    if (tileWrapperClassName) {
        wrapperClass += ' ' + tileWrapperClassName
    }

    const handleTileImage = () => {
        let imgWrapperClasses = ['tile-img-container']
        if (tileImageClassName) {
            imgWrapperClasses.push(tileImageClassName)
        }

        if (tileImage || projectImage) {
            imgWrapperClasses.push('tile-img-container-grey')
            return <div className={imgWrapperClasses.join(" ")} onClick={onTileClick}>{tileImage ? <img src={tileImage} alt={tileHeader} /> : <div className="tile-img-no-image"><span className={icon} /></div>}</div>
        }

        return <div className={imgWrapperClasses.join(" ")} onClick={onTileClick}>{tileSvg}</div>
    }
    const lowerSection = () => {
        return tileHeaderOnly ? (
            <div className="tile-lower-section tile-lower-section-blue" onClick={onTileClick}>
                <div className="tile-header-only">{tileHeaderOnly}</div>
            </div>
        ) : (
            <div className="tile-lower-section tile-lower-section-white">
                <div className="tile-header-and-actions">
                    <h6 title={tileHeader}>{tileHeader}</h6>
                    <span className="icon-more_vert" onClick={onTileActionsClick}></span>
                </div>
                <div className="tile-desc">
                    <p>{tileDesc} {tileTime}</p>
                </div>
                {
                    tileLink && (
                        <div className="tile-link">
                            <a href={tileLink}>{tileLinkDisplay}</a>
                        </div>
                    )
                }
                {tileStatus && <div className="tile-status">{tileStatus}</div>}
            </div>
        )
    }
    return (
        <section className={wrapperClass}>
            {handleTileImage()}
            {lowerSection()}
        </section>
    );
};

export default TileComponent;
