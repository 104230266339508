import { useEffect } from "react"
import SpinnerComponent from "../spinner/spinner.component"
import { useDispatch, useSelector } from "react-redux"
import Session from "../../utils/session.util"
import moment from "moment"
import { login } from "../../redux/auth.redux.slice"
import jwtDecode from "jwt-decode"

const RequireAuth = (props) => {
  const {children} = props
  const {user, access_token, isLoggingOut} = useSelector((state) => state.auth)
  const dispatch = useDispatch()

  useEffect(() => {

    if (isLoggingOut) {
      return
    }

    // Check for a session
    const session = Session.read()
    if (!user || !access_token) {
      
      // If we have a session, let's try to establish a user/token
      if (session && session.access_token && session.id_token && session.refresh_token) {
        console.log("Re-establishing session")
        const user = jwtDecode(session.id_token)
        if (moment().isAfter(moment(session.exp))) {
          console.log("Requesting new access_token")
          Session.getAccessTokenFromRefreshToken().then((res) => {
            const { access_token, refresh_token, id_token } = res
            const user = jwtDecode(id_token)
            dispatch(login({user, access_token, refresh_token}))

          }, (e) => {
            console.log("Error getting updated access_token: ", e)
            Session.forceLogin()
          })
        }
        else {
          console.log("Using existing access token")
          dispatch(login({user, access_token: session.access_token, refresh_token: session.refresh_token}))
        }
      } 
      else {
        console.log("User is not authenticated")
        Session.forceLogin(window.location.pathname)
      }
    }
  }, [user, access_token, isLoggingOut, dispatch])
  
  return user && access_token ? children : <SpinnerComponent />
}

export default RequireAuth