import { useEffect, useState , useRef} from 'react'
import { DEBOUNCE_DELAY } from '../../../constants'

const useSceneResizer = () => {
    const [engineRef, setEngineRef] = useState(false)
    const timerRef = useRef(null)
    

    useEffect(() => {
        const onWindowResized = () => {
            if (engineRef) {
                if (timerRef.current) {
                    clearTimeout(timerRef.current)
                }
                timerRef.current = setTimeout(() => {
                    engineRef.resize()
                }, DEBOUNCE_DELAY)
            }
        }

        // TODO: Need to avoid resizing on first load
        // Suggestion: Can be collapsed as per the route rather than after loading in the workspace page
        const ro = new ResizeObserver(entries => {
            onWindowResized()
        });
          
        // Observe one or multiple elements
        if (engineRef) {
            const sidebarEl = document.querySelector('.threedy-lab-sidebar')
            const resizeEl = sidebarEl || document.body
            ro.observe(resizeEl)
        }
    
        window.addEventListener('resize', onWindowResized)
        return () => {
            window.removeEventListener('resize', onWindowResized)
            clearTimeout(timerRef.current)
            ro.disconnect()
        }
    }, [engineRef])

    return { setEngineRef }
}

export default useSceneResizer
