import { useState, useEffect, useCallback, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import ContextMenuComponent from "../../components/context-menu/context-menu.component"
import TabBarComponent from "../../components/tab-bar/tab-bar.component"
import { useEditModelMutation, useLazyGetFoldersQuery, useEditProjectMutation, useDeleteProjectMutation, useLazyGetProjectsQuery, useLazyGetModelsQuery, useLazyGetPersonalCollectionsQuery, useAddNewFolderMutation, useEditFolderMutation, useDeleteFolderMutation, useAddToFolderMutation, useLazyGetFolderByIdQuery, useAddNewProjectMutation, useDeleteModelMutation, useLazyGetProjectCountByModelQuery, useLazyGetProjectOrModelCountByMaterialQuery, useDeletePersonalCollectionItemMutation, useLazyGetExportsListQuery, useRetryExportMutation } from "../../redux/api.redux.slice"
import { setToast, showProjectThumbnailModal, setRunTour, setTourStepIndex } from "../../redux/ui.redux.slice"
import { modifyQuota } from "../../redux/auth.redux.slice"
import ProjectTileComponent from "../../components/project-tile/project-tile.component"
import SpinnerComponent from "../../components/spinner/spinner.component"
import { addFolder, createConfigurator, createMaterial, importFile, openInNew, projectFolder, threeDModelTemplateImage, variationsTemplate } from "../../assets"
import FolderTileComponent from "../../components/folder-tile/folder-tile.component"
import UploadTileComponent from "../../components/upload-tile.component.js/upload-tile.component"
import { Link, useLocation, useNavigate } from "react-router-dom"
import ModelImportModalComponent from "../../components/model-import-modal/model-import-modal.component"
import { EVENTS, KLAVIYO_METRICS, T } from "../../constants"
import NotAvailableForFreeModalComponent from "../../components/upgrade-to-pro/not-available-for-free-modal.component"
import FreeLimitExhaustedModalComponent from "../../components/upgrade-to-pro/free-limit-exhausted-modal.component"
import DeleteConfirmModalComponent from "../../components/alerts/delete-confirm-modal.component"
import SimpleAlertModalComponent from "../../components/alerts/simple-alert-modal.component"
import MaterialTileComponent from "../../components/upload-tile.component.js/material-tile.component"
import ListUIComponent from "../../components/list-ui/list-ui.component"
import TileComponent from "../../components/tile-ui/tile.component"
import moment from "moment"
import HeaderGroupComponent from "../../components/header-group/header-group.component"
import { createDownloadLink } from "../../utils/ui.util"
import TableRow from "../../components/table-row/table-row.component"
import './projects.page.css'

const projectFilters = [
  { name: 'Configurator', key: 'project_type', value: 'configurator' },
  { name: 'Material', key: 'project_type', value: 'material' },
  { name: '3D Model', key: 'project_type', value: '3D model' },
]
const projectSort = [
  { name: 'Name', key: 'name', value: 'asc' },
  { name: 'Name', key: 'name', value: 'desc' },
  { name: 'Created By', key: 'created_ts', value: 'asc', type: 'date' },
  { name: 'Created By', key: 'created_ts', value: 'desc', type: 'date' },
]
const uploadSort = [
  { name: 'Name', key: 'name', value: 'asc' },
  { name: 'Name', key: 'name', value: 'desc' },
  { name: 'Created By', key: 'created_ts', value: 'asc', type: 'date' },
  { name: 'Created By', key: 'created_ts', value: 'desc', type: 'date' },
  { name: 'Size', key: 'size', value: 'asc', type: 'number' },
  { name: 'Size', key: 'size', value: 'desc', type: 'number' },
]
const uploadFilters = [
  { name: 'Ready', key: 'status', value: 'ready' },
  { name: 'Queued', key: 'status', value: 'queued' },
  { name: 'Error', key: 'status', value: 'error' },
  { name: 'Ready for Material Import', key: 'status', value: 'readyForMaterialImport' },
]
const exportFilters = [
  { name: 'Success', key: 'status', value: 'Success' },
  { name: 'In Progress', key: 'status', value: 'In Progress' },
  { name: 'Failed', key: 'status', value: 'Failed' },
]
const exportSort = [
  { name: 'Name', key: 'name', value: 'asc' },
  { name: 'Name', key: 'name', value: 'desc' },
  { name: 'Created By', key: 'created_ts', value: 'asc', type: 'date' },
  { name: 'Created By', key: 'created_ts', value: 'desc', type: 'date' },
]
const exportTableHeaders = [
  {name: 'Name', key: 'name'},
  {name: 'Status', key: 'status'},
  {name: 'Download', key: 'src'},
  {name: 'Modified', key: 'updated_ts', width: "5%"},
]

const ProjectsPage = () => {

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const createFolderRef = useRef()
  const {user, plan, quota} = useSelector((state) => state.auth)
  const { activeTour } = useSelector((state) => state.ui)
  const [itemContextMenu, setItemContextMenu] = useState(false)
  const [editedItemId, setEditedItemId] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [tab, setTab] = useState("designs")
  const [exports, setExports] = useState([])
  const [projects, setProjects] = useState([])
  const [folders, setFolders] = useState([])
  const [uploads, setUploads] = useState([])
  const [myMaterials, setMyMaterials] = useState([])
  const [breadcrumbs, setBreadcrumbs] = useState([{id: "all", label: "All Folders"}])
  const [selectedFolder, setSelectedFolder] = useState(false)
  const [showImportFile, setShowImportFile] = useState(false)
  const [showFreeLimitExhaustedModal, setshowFreeLimitExhaustedModal] = useState(false)
  const [showNotAvailableForFreeModal, setShowNotAvailableForFreeModal] = useState(false)
  const [retryExportQuery] = useRetryExportMutation()
  const [fetchExports] = useLazyGetExportsListQuery()
  const [getProjects] = useLazyGetProjectsQuery()
  const [getFolders] = useLazyGetFoldersQuery()
  const [getModels] = useLazyGetModelsQuery()
  const [getPersonalMaterials] = useLazyGetPersonalCollectionsQuery()
  const [getProjectCountByModel] = useLazyGetProjectCountByModelQuery()
  const [getProjectOrModelCountByMaterial] = useLazyGetProjectOrModelCountByMaterialQuery()
  const [updateProject] = useEditProjectMutation()
  const [updateFolder] = useEditFolderMutation()
  const [editModel] = useEditModelMutation()
  const [deleteProject] = useDeleteProjectMutation()
  const [deleteFolder] = useDeleteFolderMutation()
  const [deleteModel] = useDeleteModelMutation()
  const [deletePersonalCollectionItem] = useDeletePersonalCollectionItemMutation()
  const [createFolder] = useAddNewFolderMutation()
  const [addToFolder] = useAddToFolderMutation()
  const [addProject] = useAddNewProjectMutation()
  const [getFolderContents] = useLazyGetFolderByIdQuery()
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false)
  const [showModelCannotbeDeletedModal, setShowModelCannotbeDeletedModal] = useState(false)
  const [deleteWarnText, setDeleteWarnText] = useState("")
  const deleteItemRef = useRef(null)
  const location = useLocation()

  useEffect(() => {
    if (location.search && location.search.includes("?to=")) {
      const tabName = location.search.replace("?to=", "")
      setTab(tabName ?? "folders")
    }
  }, [location])

  const doGetFoldersQuery = useCallback(async () => {
    try {
      const foldersRes = await getFolders(null, true).unwrap()
      setFolders(foldersRes)
      setIsLoading(false)
    }
    catch {
      dispatch(setToast({message: "Uh oh. We had an error fetching your folders. Please try again", isError: true}))
      setIsLoading(false)
    }
  }, [dispatch, getFolders])

  const doGetProjectsQuery = useCallback(async () => {
    try {
      const projectsRes = await getProjects(null, true).unwrap()
      setProjects(projectsRes)
      setIsLoading(false)
    }
    catch {
      dispatch(setToast({message: "Uh oh. We had an error fetching your designs. Please try again", isError: true}))
      setIsLoading(false)
    }
  }, [dispatch, getProjects])

  const doGetExportsQuery = useCallback(async () => {
    try {
      const exportsRes = await fetchExports().unwrap()
      setExports(exportsRes)
      setIsLoading(false)
    }
    catch {
      dispatch(setToast({message: "Uh oh. We had an error fetching your exports. Please try again", isError: true}))
      setIsLoading(false)
    }
  }, [dispatch, fetchExports])

  const doGetUploadsQuery = useCallback(async () => {
    try {
      const models = await getModels(null, true).unwrap()
      setUploads([...models].sort((a, b) => a.created_ts > b.created_ts ? -1 : 1))
      setIsLoading(false)
    }
    catch {
      dispatch(setToast({message: "Uh oh. We had an error fetching your uploads. Please try again", isError: true}))
      setIsLoading(false)
    }
  }, [dispatch, getModels])

  const doGetMyMaterialsQuery = useCallback(async () => {
    try {
      const materials = await getPersonalMaterials(null, true).unwrap()
      setMyMaterials([...materials].sort((a, b) => a.created_ts > b.created_ts ? -1 : 1))
      setIsLoading(false)
    }
    catch {
      dispatch(setToast({ message: "Uh oh. We had an error fetching your materials. Please try again", isError: true }))
      setIsLoading(false)
    }
  }, [dispatch, getPersonalMaterials])

  // Fetch folders on load
  useEffect(() => {
    if (tab === 'folder') {
      return
    }

    setIsLoading(true)

    if (tab === 'folders') {
      doGetFoldersQuery()
    }
    else if (tab === 'designs') {
      doGetProjectsQuery()
    }
    else if (tab === 'my_materials') {
      doGetMyMaterialsQuery()
    }
    else if (tab === 'exports') {
      doGetExportsQuery()
    }
    else {
      doGetUploadsQuery()
    }

  }, [tab, doGetFoldersQuery, doGetProjectsQuery, doGetUploadsQuery, doGetMyMaterialsQuery, doGetExportsQuery])

  const hasTimedOut = (exportDetails) => {
    const createdEpoch = parseInt(exportDetails.created_ts)
    const now = moment()
    const diff = now.diff(moment(createdEpoch), "minutes")
    return diff >= 15
}

const handleRetry = async (exportId) => {
  setIsLoading(true)
  try {
      const res = await retryExportQuery(exportId).unwrap()
      setExports([...exports].map((e) => {
          if (e.id === exportId) {
              return {...e, ...res.data}
          }

          return e
      }).sort((a, b) => a.created_ts > b.created_ts ? -1 : 1))

      setIsLoading(false)
  }
  catch (e) {
      console.error("Error retrying export: ", e)
      setIsLoading(false)
  }
}

  const handleExportMoreClick = (e, exportData) => {
    e.preventDefault()
    e.stopPropagation()

    let label = "Download"
    let disableBtn = true
    let didSucceed = false
    let className = "primary-btn"
    let itemIcon = <span className="icon-download"></span>
    const didTimeout = hasTimedOut(exportData)
    
    if (exportData.status_code === 200) {
        label = "Download again"
        disableBtn = false
        didSucceed = true
    }
    else if ((exportData.status_code === 100 && didTimeout) || exportData.status_code === 500) {
        // Try again
        label = "Try again"
        disableBtn = false
        className = "outline-btn"
    }

    const handleButtonClick = () => {
        if (!disableBtn) {
            if (didSucceed) {
                createDownloadLink(exportData.src, exportData.name)
            }
            else {
                handleRetry(exportData.id)
            }
        }
    }

    const items = [
      {id: 'download', className, label: <>{itemIcon} {label}</>, disabled: disableBtn, action: handleButtonClick},
    ]

    setItemContextMenu({
      position: {
        left: e.clientX + 'px',
        top: e.clientY + 'px'
      },
      items: items
    })
  }

  const handleProjectMoreClick = (e, projectId) => {
    e.preventDefault()
    e.stopPropagation()

    const items = [
      { id: 'openInNewTab', label: <>{openInNew} Open in new tab</>, action: () => window.open(`/editor/${projectId}`, "_blank") },
      {id: 'rename', label: <><span className="icon-file"></span> Rename</>, action: () => setEditedItemId(projectId)},
      {id: 'thumb', label: <><span className="icon-image"></span> Update Image</>, action: () => handleProjectThumbnailRequest(projectId)}
    ]

    if (folders.length > 0 && tab !== 'folder') {
      items.push({id: 'move', label: <><span className="icon-chevron-right"></span> Move to</>, subItems: folders, onSubItemSelection: (folderId) => handleMoveProjectToFolder(folderId, projectId)})
    }
    
    // Delete should always be at the last
    items.push({ id: 'delete', className: "delete-btn", label: <><span className="icon-trash"></span> Delete</>, action: () => showDeleteProjectModal(projectId) })

    setItemContextMenu({
      position: {
        left: e.clientX + 'px',
        top: e.clientY + 'px'
      },
      items: items
    })
  }

  const handleMaterialMoreClick = (e, materialId) => {
    e.preventDefault()
    e.stopPropagation()

    const items = [
      { id: 'delete', className: "delete-btn", label: <><span className="icon-trash"></span> Delete</>, action: () => checkAndDeleteMaterial(materialId) }
    ]

    setItemContextMenu({
      position: {
        left: e.clientX + 'px',
        top: e.clientY + 'px'
      },
      items: items
    })
  }
  const handleUploadMoreClick = (e, uploadId) => {
    e.preventDefault()
    e.stopPropagation()

    const items = [
      { id: 'create3d', label: <><span className="icon-chevron-right"></span> Texture 3D Model</>, action: () => handleCreateProjectByModel(T._3D_MODEL, uploadId) },
      { id: 'createConfigurator', label: <><span className="icon-chevron-right"></span> Build Configurator</>, action: () => handleCreateProjectByModel(T.CONFIGURATOR, uploadId) },
      { id: 'rename', label: <><span className="icon-file"></span> Rename</>, action: () => setEditedItemId(uploadId) },
      { id: 'delete', className: "delete-btn", label: <><span className="icon-trash"></span> Delete</>, action: () => checkAndDeleteUploadModel(uploadId) }
    ]

    setItemContextMenu({
      position: {
        left: e.clientX + 'px',
        top: e.clientY + 'px'
      },
      items: items
    })
  }
  const handleMoveProjectToFolder = async (folderId, projectId) => {
    try {
      await addToFolder({folderId, body: {projectId}}).unwrap()

      // Refresh projects
      setTimeout(() => {
        doGetProjectsQuery()
      }, 1500)
    }
    catch (e) {
      dispatch(setToast({message: "Uh oh. We had an issue adding that item to your folder, please try again.", isError: true}))
    }
  }

  const handleFolderMoreClick = (e, folderId) => {
    e.preventDefault()
    e.stopPropagation()

    const folder = folders.find((f) => f.id === folderId)
    if (!folder) {
      return
    }

    const items = [
      {id: 'rename', label: <><span className="icon-file"></span> Rename</>, action: () => setEditedItemId(folderId)},
      { id: 'delete', className: "delete-btn", label: <><span className="icon-trash"></span> Delete</>, action: () => showDeleteFolderModal(folderId) }
    ]

    setItemContextMenu({
      position: {
        left: e.clientX + 'px',
        top: e.clientY + 'px'
      },
      items: items
    })
  }

  const handleCreateNewFolder = useCallback(async (folderName) => {
    try {
      setIsLoading(true)
      const name = folderName ? folderName : `New folder ${folders.length + 1}`
      const createFoldersRes = await createFolder({name}).unwrap()
      setFolders([createFoldersRes, ...folders])
      setIsLoading(false)
    }
    catch {
      dispatch(setToast({message: "Uh oh. We had an error creating your new folder. Please try again", isError: true}))
      setIsLoading(false)
    }
  }, [dispatch, createFolder, folders])

  const handleShowAddFolder = useCallback((e) => {
    if (e) {
      e.stopPropagation()
      e.preventDefault()
    }

    const rect = createFolderRef.current.getBoundingClientRect()
    setItemContextMenu(
      {position: {
        left: rect.left + 'px', top: rect.top + 'px', padding: 0,
      },
      wide: true,
      items: [
        {id: 'name', isInput: true, icon: addFolder, itemClassName: "m-0", label: "Create a Project Folder", sublabel: "Enter a name for your folder", placeholder: "Folder Name", action: handleCreateNewFolder}
      ]
    })
  }, [handleCreateNewFolder])

  const handleDeleteProject = async (projectId) => {
    if (!projectId) {
      return
    }
    setShowDeleteConfirmationModal(false)
    try {
      await deleteProject(projectId).unwrap()

      // Refresh projects
      setTimeout(() => {
        doGetProjectsQuery()
      }, 1500)

      if (selectedFolder) {
        setSelectedFolder({...selectedFolder, contents: [...selectedFolder.contents].filter((i) => i.project_id !== projectId)})
      }

      // Decrement quota
      dispatch(modifyQuota(-1))
    }
    catch (e) {
      console.log("Error deleting project: ", e)
      dispatch(setToast({message: "Uh oh. We had an issue deleting your project, please try again.", isError: true}))
    }
  }
  const showDeleteProjectModal = (projectId) => {
    setDeleteWarnText("")
    deleteItemRef.current = { id: projectId, type: "project" }
    setShowDeleteConfirmationModal(true)
  }
  const showDeleteFolderModal = (folderId) => {
    setDeleteWarnText("")
    deleteItemRef.current = { id: folderId, type: "folder" }
    setShowDeleteConfirmationModal(true)
  }
  const checkAndDeleteMaterial = async (materialId) => {
    try {
      const attachments = await getProjectOrModelCountByMaterial(materialId).unwrap()
      if (attachments.models_count > 0) {
        setDeleteWarnText("This material is part of a Model.")
      } else if (attachments.projects_count > 0) {
        setDeleteWarnText("This material is part of a Project.")
      }
      deleteItemRef.current = { id: materialId, type: "material" }
      setShowDeleteConfirmationModal(true)
    } catch (e) {
      console.log("Error deleting material: ", e)
      dispatch(setToast({ message: "Uh oh. We had an issue deleting your material, please try again.", isError: true }))
    }
  }
  const checkAndDeleteUploadModel = async (uploadId) => {
    try {
      const projectsWithThisModel = await getProjectCountByModel(uploadId).unwrap()
      if (projectsWithThisModel > 0) {
        setShowModelCannotbeDeletedModal(true)
        return
      }
      showDeleteUploadModal(uploadId)
    } catch (e) {
      console.log("Error deleting upload: ", e)
      dispatch(setToast({ message: "Uh oh. We had an issue deleting your upload, please try again.", isError: true }))
    }

  }
  const showDeleteUploadModal = (uploadId) => {
    setDeleteWarnText("")
    deleteItemRef.current = { id: uploadId, type: "upload" }
    setShowDeleteConfirmationModal(true)
  }

  const handleDeleteFolder = async (folderId) => {
    if (!folderId) {
      return
    }
    setShowDeleteConfirmationModal(false)

    try {
      await deleteFolder(folderId).unwrap()

      // Refresh folder + projects
      setTimeout(() => {
        doGetFoldersQuery()
        doGetProjectsQuery()
      }, 1500)
    }
    catch (e) {
      console.log("Error deleting foolder: ", e)
      dispatch(setToast({ message: "Uh oh. We had an issue deleting your folder, please try again.", isError: true }))
    }
  }

  const handleDeleteMaterial = async (materialId) => {
    if (!materialId) {
      return
    }
    setShowDeleteConfirmationModal(false)
    try {
      await deletePersonalCollectionItem({ materialId: materialId }).unwrap()
      setTimeout(() => {
        doGetMyMaterialsQuery()
      }, 1500)
    }
    catch (e) {
      console.log("Error deleting this material: ", e)
      dispatch(setToast({ message: "Uh oh. We had an issue deleting this material, please try again.", isError: true }))
    }
  }

  const handleDeleteUpload = async (uploadId) => {
    if (!uploadId) {
      return
    }
    setShowDeleteConfirmationModal(false)

    try {

      await deleteModel(uploadId).unwrap()

      // Refresh folder + projects
      setTimeout(() => {
        doGetUploadsQuery()
      }, 1500)
    }
    catch (e) {
      console.log("Error deleting this upload: ", e)
      dispatch(setToast({ message: "Uh oh. We had an issue deleting this upload, please try again.", isError: true }))
    }
  }
  const handleDeleteByType = async () => {
    const { id, type } = deleteItemRef.current
    if (!type) {
      return
    }
    switch (type) {
      case "project":
        handleDeleteProject(id)
        break
      case "folder":
        handleDeleteFolder(id)
        break
      case "upload":
        handleDeleteUpload(id)
        break
      case "material":
        handleDeleteMaterial(id)
        break
      default:
        break
    }
  }
  const handleCreateProjectByModel = (projectType, modelId) => {
    const params = { model_id: modelId, parts: '[]', variations: '[]', meta: '{}' }
    handleCreateProject(projectType, params)
  }

  const handleProjectThumbnailRequest = (projectId) => {
    const project = selectedFolder ? selectedFolder.contents.find((p) => p.id === projectId) : projects.find((p) => p.id === projectId)
    if (!project) {
      return
    }

    dispatch(showProjectThumbnailModal(project))
  }

  const handleUpdateProjectName = async (value) => {
    const requestedProject = selectedFolder ? selectedFolder.contents.find((p) => p.id === editedItemId) : projects.find((p) => p.id === editedItemId)
    if (value && requestedProject.name !== value) {
      await updateProject({projectId: editedItemId, body: {name: value}})

      if (selectedFolder) {
        setSelectedFolder({...selectedFolder, contents: [...selectedFolder.contents].map((p) => {
          if (p.id === editedItemId) {
            return {...p, name: value}
          }

          return p
        })})
      }
    }

    setEditedItemId(false)
  }

  const handleUpdateUploadName = async (value) => {
    const requestedUpload = uploads.find((u) => u.id === editedItemId)
    if (value && requestedUpload.name !== value) {
      await editModel({ modelId: editedItemId, body: { name: value } })

      setUploads([...uploads].map((u) => {
        if (u.id === editedItemId) {
          return { ...u, name: value }
        }
        return u
      }))
    }
    setEditedItemId(false)
  }

  const handleUpdateFolderName = async (value) => {
    const requestedFolder = folders.find((f) => f.id === editedItemId)
    if (value && requestedFolder.name !== value) {
      await updateFolder({folderId: editedItemId, body: {name: value}})
      setFolders([...folders].map((f) => {
        if (f.id === editedItemId) {
          return {...f, name: value}
        }

        return f
      }))
    }

    setEditedItemId(false)
  }

  const getGridClass = () => {
    if (!user || isLoading) {
      return "threedy-lab-page-content project-page-wrapper threedy-skeleton"
    }

    return "threedy-lab-page-content project-page-wrapper"
  }

  const handleTabChange = (itemKey) => {
    setTab(itemKey)

    if (breadcrumbs.length > 1) {
      setBreadcrumbs([...breadcrumbs].filter((a, i) => i < 1))
    }
  }

  const tabItems = [{
  //   id: 'folders',
  //   label: `Folders`
  // }, 
  // {
    id: 'designs',
    label: `Designs`
  }, {
    id: 'exports',
    label: `Exports`
  }, {
    id: 'uploads',
    label: `Uploads`
    }, 
    // {
    //   id: 'my_materials',
    //   label: `My Materials`
    // }
]

  const getEmptyLabel = () => {
    let label
    let subLabel = null
    const buttons = []

    if (tab === 'folders') {
      label = "You haven't created any folders yet..."
      buttons.push({id: 'createFolder', action: handleShowAddFolder, label: "Create a Project Folder", image: projectFolder})
    }
    else if (tab === 'designs') {
      label = "You haven't started on any designs yet,"
      subLabel = 'Select a creative tool to get started!'

      // todo: only add if they have enough designs to add in their plan + pro users for material
      buttons.push({ id: 'create3d', className: (plan && plan.type === 'pro') || (plan && quota && quota.numProjects < plan.features.numProjects) ? '' : 'locked', action: () => handleCreateProject(T._3D_MODEL), label: "Texture 3D Model", span: (plan && plan.type !== 'pro' && quota.numProjects >= plan.features.numProjects) && <span className="icon-lock right-top"></span>, image: threeDModelTemplateImage })
      buttons.push({id: 'createConfigurator', className: (plan && plan.type === 'pro') || (plan && quota && quota.numProjects < plan.features.numProjects) ? '' : 'locked', action: () => handleCreateProject("configurator"), label: "Create a Configurator", span: (plan && plan.type !== 'pro' && quota.numProjects >= plan.features.numProjects) && <span className="icon-lock right-top"></span>, image: createConfigurator})
      buttons.push({ id: 'createVariations', className: (plan && plan.type === 'pro') || (plan && quota && quota.numProjects < plan.features.numProjects) ? '' : 'locked', action: () => handleCreateProject(T.VARIATIONS), label: "Create Model Variations", span: (plan && plan.type !== 'pro' && quota.numProjects >= plan.features.numProjects) && <span className="icon-lock right-top"></span>, image: variationsTemplate })
      buttons.push({id: 'createMaterial', className: (plan && plan.type !== 'pro') ? 'locked' : '', action: () => handleCreateProject("material"), label: "Create a Material", span: (plan && plan.type !== 'pro') && <span className="icon-lock right-top"></span>, image: createMaterial})
    }
    else if (tab === 'folder') {
      label = "You haven't moved any designs in here yet..."
    }
    else if (tab === 'exports') {
      label = "You haven't exported any models yet..."
    }
    else if (tab === 'my_materials') {
      label = "You haven't added any materials yet..."
    }
    else {
      label = "You haven't uploaded any files yet,"
      subLabel = 'Upload your first file'
      buttons.push({id: 'importFile', disabled: showImportFile, action: () => setShowImportFile(true), label: "Import a 3D File", image: importFile})
    }

    return (
      <div className="empty-white-wrapper">
        <div className="toggle-empty-label">
          <h5>{label}</h5>
          <h6>{subLabel}</h6>
            <div className="toggle-content-blocks empty-content-blocks">
              {buttons.length > 0 && (
                buttons.map((b) => {
                  return (
                    <TileComponent key={b.id} tileHeaderOnly={b.label} tileSvg={b.image} onTileClick={b.action} />
                    // <button key={b.id} disabled={b.disabled} className={b.className} onClick={b.action}>{b.image}{b.label}{b.span}</button>
                  )
                })
              )}
            </div>
        </div>
      </div>
    )
  }

  const getFolderBreadcrumbs = () => {
    return (
      <div className="toggle-breadcrumbs">
        {breadcrumbs.map((b, i) => <button key={b.id} onClick={() => onSelectFolder(b.id)} className={i === breadcrumbs.length-1 ? "selected" : ""}>{b.label}</button>)}
      </div>
    )
  }

  const onSelectFolder = useCallback(async (folderId) => {
    try {

      if (folderId === 'all') {
        setSelectedFolder(false)
        setTab("folders")
        setBreadcrumbs([breadcrumbs[0]])
        return
      }

      const folder = folders.find((f) => f.id === folderId)
      const contents = await getFolderContents(folderId).unwrap()
      setSelectedFolder({...folder, contents})
      setBreadcrumbs([breadcrumbs[0], {
        id: folderId,
        label: folder.name
      }])
      setTab("folder")
    }
    catch (e) {
      dispatch(setToast({message: "Uh oh. We had an issue opening your folder, please try again.", isError: true}))
    }
  }, [dispatch, breadcrumbs, getFolderContents, folders])
  const getCurrentTabCount = (tab) => {
    let count = 0
    switch (tab) {
      case 'folders':
        count = folders.length
        break
      case 'designs':
        count = projects.length
        break
      case 'folder':
        count = selectedFolder.contents.length
        break
      case 'uploads':
        count = uploads.length
        break
      case 'my_materials':
        count = myMaterials.length
        break
      case 'exports':
        count = exports.length
        break
      default:
        break
    }
    return count
  }

  const projectItem = (project) => {
    const updatedRecently = project.updated_ts ? `Updated ${moment(project.updated_ts).fromNow()}` : `Created ${moment(project.created_ts).fromNow()}`
    const projectLink = `/editor/${project.id}`
    return (
      <TileComponent
        tileHeader={project.name}
        tileImage={project.image}
        tileDesc={project.project_type}
        tileTime={updatedRecently}
        onTileClick={() => navigate(projectLink)}
        onTileActionsClick={e => handleProjectMoreClick(e, project.id)}
        projectImage
        icon="icon-deployed_code"
        tileImageClassName="tile-img-container-project-image"
      />
    )
  }

  const exportItem = (exportData) => {
    const getLocalTime = (created_ts) => {
        const epoch = parseInt(created_ts)
        return moment(epoch).fromNow()
    }
    const updatedRecently = exportData.updated_ts ? `Updated ${getLocalTime(exportData.updated_ts)}` : `Created ${getLocalTime(exportData.created_ts)}`
    const didTimeout = hasTimedOut(exportData)
    const exportStatus = () => {
      if ((exportData.status_code === 100 && didTimeout) || exportData.status_code === 500) {
        return <div className="toggle-label red">Failed</div>
      }

      if (exportData.status_code === 200) {
        return <div className="toggle-label green">{exportData.status}</div>
      }

      return <div className="toggle-label">{exportData.status}</div>
    }

    const downloadCell = () => {
      switch (exportData.status_code) {
        case 100:
          return <div className="toggle-label exporting">Download</div>
        case 200:
          return <div className="toggle-label exported" onClick={() => createDownloadLink(exportData.src, exportData.name)}>Download <span className="icon-download"></span></div>
        default:
          return <div className="toggle-label failed" onClick={() => handleRetry(exportData.id)}>Try again</div>
      }
    }

    const tableItems = exportTableHeaders.map(cell => {
      switch (cell.name) {
        case 'Name':
          const linkTo = `/editor/${exportData.id}`
          return <Link to={linkTo}>{exportData[cell.key]} <span className="icon-open_in_new"></span></Link>
        case 'Status':
          return <>{exportStatus()}</>
        case 'Modified':
          return updatedRecently
        case 'Download':
          return <>{downloadCell()}</>
        default:
          return exportData[cell.key]
      }
    })

    return (
      <TableRow tableItems={tableItems} tableImage={exportData.image || null} tableHeaders={exportTableHeaders} />
      // <TileComponent
      //   tileHeader={exportData.name}
      //   tileTime={updatedRecently}
      //   tileStatus={exportStatus()}
      //   onTileActionsClick={e => handleExportMoreClick(e, exportData)}
      // />
    )
  }
  
  const uploadItem = (upload) => {
    const updatedRecently = upload.updated_ts ? `Updated ${moment(upload.updated_ts).fromNow()}` : `Created ${moment(upload.created_ts).fromNow()}`
    return (
      <TileComponent
        tileHeader={upload.name}
        tileTime={updatedRecently}
        projectImage
        icon="icon-drive_folder_upload"
        onTileActionsClick={e => handleUploadMoreClick(e, upload.id)}
      />
    )
  }

  const getContent = () => {
    // Check for empty
    let content
    let count = getCurrentTabCount(tab)

    if (tab === 'folders') {
      content = (
        <>
          {getFolderBreadcrumbs()}
          <ul className="toggle-grid-list">
            {folders.map((folder) => {
              return (
                <FolderTileComponent onClick={() => onSelectFolder(folder.id)} key={folder.id} editable folder={folder} editedFolderId={editedItemId} onUpdateName={handleUpdateFolderName} onMoreClick={handleFolderMoreClick} />
              )
            })}
          </ul>
        </>
      )
    }
    else if (tab === 'folder') {
      content = (
        <>
          {getFolderBreadcrumbs()}
          {selectedFolder.contents.length > 0 && (
            <ul className="toggle-grid-list">
              {selectedFolder.contents.map((project) => {
                return (
                  <ProjectTileComponent key={project.id} editable project={project} editedProjectId={editedItemId} onUpdateName={handleUpdateProjectName} onMoreClick={handleProjectMoreClick} />
                )
              })}
            </ul>
          )}
        </>
      )
    }
    else if (tab === 'designs') {
      content = <ListUIComponent list={projects} titleKey="name" listItem={projectItem} listFilters={projectFilters} listSortBy={projectSort} />
        // <ul className="toggle-grid-list">
        //   {projects.map((project) => {
        //     return (
        //       <ProjectTileComponent key={project.id} editable project={project} editedProjectId={editedItemId} onUpdateName={handleUpdateProjectName} onMoreClick={handleProjectMoreClick} />
        //     )
        //   })}
        // </ul>
    }
    else if (tab === 'exports') {
      content = <ListUIComponent list={exports} titleKey="name" listItem={exportItem} listFilters={exportFilters} listSortBy={exportSort} tableHeaders={exportTableHeaders} listUIType="table" />
        // <ul className="toggle-grid-list">
        //   {projects.map((project) => {
        //     return (
        //       <ProjectTileComponent key={project.id} editable project={project} editedProjectId={editedItemId} onUpdateName={handleUpdateProjectName} onMoreClick={handleProjectMoreClick} />
        //     )
        //   })}
        // </ul>
    }
    else if (tab === 'my_materials') {
      content =
        <ul className="toggle-grid-list">
          {myMaterials.map((m) => {
            return (
              <MaterialTileComponent key={m.id} editable material={m} editedUploadId={editedItemId} onMoreClick={handleMaterialMoreClick} />
            )
          })}
        </ul>
    }
    else {
      content = <ListUIComponent list={uploads} titleKey="name" listItem={uploadItem} listFilters={uploadFilters} listSortBy={uploadSort} />
        // <ul className="toggle-grid-list">
        //   {uploads.map((upload) => {
        //     return (
        //       <UploadTileComponent key={upload.id} editable={!upload.maps} upload={upload} editedUploadId={editedItemId} onMoreClick={handleUploadMoreClick} onUpdateName={handleUpdateUploadName} />
        //     )
        //   })}
        // </ul>
    }

    return (
      <div className="content-wrapper">
        {count === 0 ? getEmptyLabel() : content}
      </div>
    )
  }

  const handleCreateProject = async (projectType, additionalParams = null) => {
    if (projectType === T.MATERIAL && plan && plan.type !== 'pro') {
      setShowNotAvailableForFreeModal(true)
      return
    }

    if ([T.CONFIGURATOR, T._3D_MODEL].includes(projectType) && plan && plan.type !== 'pro' && quota && quota.numProjects >= plan.features.numProjects) {
      setshowFreeLimitExhaustedModal(true)
      return false
    }

    let params = { name: 'Untitled', project_type: projectType }
    if (additionalParams) {
      params = {
        ...additionalParams,
        ...params,
      }
    }
    try {
      const res = await addProject(params).unwrap()

      window.klaviyo.track(KLAVIYO_METRICS.created_project)

       // Increment quota
       dispatch(modifyQuota(1))

      // Navigate to project
      navigate(`/editor/${res.data.id}`)
    }
    catch (e) {
      console.log("Error creating a project", e)
      dispatch(setToast({message: "Uh oh. We had an issue creating a new design, please try again.", isError: true}))
    }
  }

  // Listen for thumb update
  useEffect(() => {

    const handleRefreshProjectImage = () => {
      console.log("update project image")

      if (tab === 'folder' && selectedFolder) {
        setTimeout(() => onSelectFolder(selectedFolder.id), 250)
      }
      else if (tab === 'designs') {
        setTimeout(doGetProjectsQuery, 250)
      }
    }

    document.addEventListener(EVENTS.PROJECT_THUMB_UPDATED, handleRefreshProjectImage)

    return () => document.removeEventListener(EVENTS.PROJECT_THUMB_UPDATED, handleRefreshProjectImage)
  }, [selectedFolder, onSelectFolder, doGetProjectsQuery, tab])

  // Handle state params on load
  useEffect(() => {

    const params = new URLSearchParams(window.location.search)
    const d = params.get("d")
    if (!d) {
      return
    }


    if (d === "cf") {
      setTimeout(() => {
        handleShowAddFolder()
        window.history.replaceState({}, '', window.location.pathname)
      }, 50)
    }
    else if (d === "if") {
      setTimeout(() => {
        setShowImportFile(true)
        window.history.replaceState({}, '', window.location.pathname)
      }, 50)
    }
    else if (d === 'ap') {
      setTab('designs')
      window.history.replaceState({}, '', window.location.pathname)
    }
    else if (d === 'exp') {
      setTab('exports')
      window.history.replaceState({}, '', window.location.pathname)
    }

  }, [handleShowAddFolder])

  const handleImportFileModalClose = (isImported = true) => {
    setShowImportFile(false)
    if (isImported) {
      handleTabChange('uploads')
      // Refresh folder + projects
      setTimeout(() => {
        doGetUploadsQuery()
      }, 1500)
    }
  }

  // Handle Tour
  useEffect(() => {
    if (!isLoading && activeTour === 'TOUR1') {
      dispatch(setRunTour(true)) // Start Tour 
      dispatch(setTourStepIndex(2)) // Resume TOUR1
    }
  }, [isLoading, activeTour, dispatch])
  
  return (
    <>
    <div className="toggle-content-group">
      {showImportFile && <ModelImportModalComponent title="Import a 3D File" disableExisting disableNewProject onClose={handleImportFileModalClose} />}
      {showNotAvailableForFreeModal && <NotAvailableForFreeModalComponent close={() => setShowNotAvailableForFreeModal(false)} />}
      {showFreeLimitExhaustedModal && <FreeLimitExhaustedModalComponent close={() => setshowFreeLimitExhaustedModal(false)} />}
        {/* <div className="projects-page-actions">
          <button ref={createFolderRef} onClick={handleShowAddFolder} className="outline-btn background-white">&nbsp; {addFolder} &nbsp;</button>
          <button disabled={showImportFile} className="outline-btn background-white pl-25 pr-25" onClick={() => setShowImportFile(true)}>Import a 3D File</button>
        </div> */}
      <HeaderGroupComponent header="Projects" level="4" icon="icon-dashboard" />
      { itemContextMenu && <ContextMenuComponent wide={itemContextMenu.wide} position={itemContextMenu.position} items={itemContextMenu.items} onClose={() => setItemContextMenu(false)} /> }
      <TabBarComponent items={tabItems} default="folders" activeTab={tab} onSelection={handleTabChange} />
      {isLoading ? (
        <div className="threedy-lab-spinner-wrapper">
          <SpinnerComponent inline />
        </div>
      ) : getContent()}
    </div>
      {showDeleteConfirmationModal &&
        <DeleteConfirmModalComponent
        title={deleteItemRef.current?.type}
        close={() => setShowDeleteConfirmationModal(false)}
        note={deleteWarnText}
        delete={() => handleDeleteByType()}
        />}
      {showModelCannotbeDeletedModal &&
        <SimpleAlertModalComponent
          title="Not Allowed"
          subTitle="You cannot delete this as a design is holding this model"
          close={() => setShowModelCannotbeDeletedModal(false)} />
      }
      
    </>
  )
}

export default ProjectsPage