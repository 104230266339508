import { useDispatch, useSelector } from 'react-redux'
import { useEditProjectMutation } from '../redux/api.redux.slice'
import { validateLastUsedMaterials } from '../utils/ui.util'
import { setLastUsedMaterials, setToast } from '../redux/ui.redux.slice'
import { T } from '../constants'

const useLastUsedMaterials = () => {
    const [lastUsedMaterials, selectedProject] = useSelector((state) => [state.ui.lastUsedMaterials, state.ui.selectedProject])
    const [updateProject] = useEditProjectMutation()
    const dispatch = useDispatch()

    const updateGlobalAndBackend = async (mat, matList) => {
      dispatch(setLastUsedMaterials(mat))
      try {
        const body = {last_used_materials: JSON.stringify(matList)}
        await updateProject({projectId: selectedProject.id, body}).unwrap()
      } catch (err) {
        console.log(err)
        dispatch(setToast({message: "Uh oh. We had an issue updating your project, please try again.", isError: true}))
      }
    }

    // Updates the list
    const addAndUpdateLastUsedMaterial = (mat) => {
        // dispatch(setLastUsedMaterials(mat))

        const validatedRes = validateLastUsedMaterials(mat, lastUsedMaterials)
        if (validatedRes === false) { return }
        let updatedMaterials = [...lastUsedMaterials]
        if (validatedRes === T.MOVE_TO_TOP) { 
          updatedMaterials = [...lastUsedMaterials.filter(existingMat => existingMat.id !== mat.id)]   
        } else if (validatedRes === T.LIMIT_EXCEEDED) {
          updatedMaterials.pop()
        }

        updateGlobalAndBackend(mat, [mat, ...updatedMaterials])
    }

    // Updates the material's meta
    const updateLastUsedMaterialMeta = (matIndex, matMeta) => {
      console.log('MATERIAL TO BE UPDATED: ', lastUsedMaterials[matIndex])
      console.log('MATERIAL`S META: ', matMeta)

      const mat = {...lastUsedMaterials[matIndex]}
      mat.meta = mat.meta ? {...mat.meta, ...matMeta} : {...matMeta}
      const newLastUsedMats = [...lastUsedMaterials]
      newLastUsedMats[matIndex] = mat

      updateGlobalAndBackend(newLastUsedMats, newLastUsedMats)
    }

    return [addAndUpdateLastUsedMaterial, updateLastUsedMaterialMeta]
}

export default useLastUsedMaterials