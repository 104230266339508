import { useNavigate } from "react-router-dom"
import { useDispatch, useSelector } from "react-redux"
import { useEffect, useState } from "react"
import { setToast } from "../../redux/ui.redux.slice"
import CreateUserModalComponent from "../../components/admin/create-user-modal/create-user-modal.component"
import "./admin.page.css"
import TabBarComponent from "../../components/tab-bar/tab-bar.component"
import AdminUserListSection from "./sections/admin.user-list.section"
import AdminDashboardSection from "./sections/admin.dashboard.section"
import GenerateReportModalComponent from "../../components/admin/create-user-modal/generate-report-modal.component"
import { useAdminClearMaterialCacheMutation } from "../../redux/api.redux.slice"

const AdminPage = () => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const role = useSelector((state) => state.auth.role)
  const [showNewUser, setShowNewUser] = useState(false)
  const [showGenerateReport, setShowGenerateReport] = useState(false)
  const [tab, setTab] = useState("dashboard")
  const [isLoading, setIsLoading] = useState(false)
  const [fetchClearMaterialCache] = useAdminClearMaterialCacheMutation()

  useEffect(() => {
    // Make sure user is an admin
    if (role && role !== 'admin') {
      // Redirect
      navigate('/dashboard')
    }
  }, [role, navigate])

  const handleCompleteNewUser = () => {
    dispatch(setToast({message: "User created successfully!"}))
    handleCloseNewUser()
  }

  const handleCompleteGenerateReport = () => {
    dispatch(setToast({message: "Report generated successfully - please check your email soon!"}))
    handleCloseGenerateReport()
  }
  
  const handleCloseGenerateReport = () => {
    setShowGenerateReport(false)
  }

  const handleCloseNewUser = () => {
    setShowNewUser(false)

    if (tab === 'users') {
      // Refresh list
      setTab("users")
    }
  }

  const handleTabChange = (tab) => {
    setTab(tab)
  }

  const handleClearMaterialCache = async () => {
    setIsLoading(true)

    try {
      await fetchClearMaterialCache().unwrap()
      dispatch(setToast({message: "Material cache cleared successfully!"}))
      setIsLoading(false)
    }
    catch (e) {
      console.error(e);
      dispatch(setToast({message: "Uh oh. We had an issue clearing the cache. Please try again", isError: true}))
      setIsLoading(false)
    }
  }

  const tabItems = [{
    id: 'dashboard',
    label: 'Dashboard'
  }, {
    id: 'users',
    label: 'Users',
  }]

  return (
    <div className="threedy-lab-page-content">
      { showNewUser && <CreateUserModalComponent onComplete={handleCompleteNewUser} onClose={handleCloseNewUser} /> }
      { showGenerateReport && <GenerateReportModalComponent onComplete={handleCompleteGenerateReport} onClose={handleCloseGenerateReport} /> }

      <div className="threedy-lab-page-header">
        <h2>Admin</h2>
        { tab === 'dashboard' && <button className="primary-btn" onClick={handleClearMaterialCache} disabled={isLoading}><span className="icon-image"></span> {isLoading ? "Clearing" : "Clear"} Material Cache</button> }
        { tab === 'users' && <button className="primary-btn" onClick={() => setShowNewUser(true)}><span className="icon-plus"></span> Invite a New User</button> }
        { tab === 'users' && <button className="outline-btn" onClick={() => setShowGenerateReport(true)}><span className="icon-columns"></span> Generate Report</button> }
      </div>
      <TabBarComponent items={tabItems} default={tab} onSelection={handleTabChange} />
      { tab === "users" ? <AdminUserListSection /> : <AdminDashboardSection /> }
    </div>
  )
}

export default AdminPage