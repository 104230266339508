import {useEffect} from 'react'
import { useDispatch } from 'react-redux'
import { setOngoingExports, setToast, setUnreadExport } from '../../../redux/ui.redux.slice'
import { exportModel } from '../../../utils/ui.util'
import { EVENTS } from '../../../constants'

const useCreateExport = (sceneRef, projectRef, access_token, handleSelectVariation) => {
  const dispatch = useDispatch()
  
  useEffect(() => {

    const exportHandler = async (e) => {
      const params = e.detail

      try {
        await doExport(params)
      }
      catch (e) {
        dispatch(setToast({message: "Uh oh. We had an issue exporting your model, please try again.", isError: true}))
      }

      document.dispatchEvent(new CustomEvent(EVENTS.EXPORT_STARTED))
    }

    const aritizeExportHandler = async (e) => {
      const params = e.detail
      const model_id = projectRef.model_id

      // Handle variations projects
      if (projectRef.variations && projectRef.variations.length > 0) {

        // Sort variations
        const variations = [...projectRef.variations].sort((a, b) => a.created_ts > b.created_ts ? -1 : 1)

        // Make sure to submit the last version of the variation tied to the option id (if there's multiple)
        const variationKeys = []
        for (let i = 0; i < variations.length; i++) {
          const v = variations[i]

          if (v.aritizeParams && v.aritizeParams.id && variationKeys.indexOf(v.aritizeParams.id) < 0) {
            variationKeys.push(v.aritizeParams.id)
            const vParams = {...params, 
              externalMeta: {
                model_id,
                job_id: v.aritizeParams.id
              } 
            }
            
            try {
              await handleSelectVariation(v.id)
              await doExport(vParams)
            }
            catch (e) {
              dispatch(setToast({message: "Uh oh. We had an issue exporting your model, please try again.", isError: true}))
            }
          }
        }
      }
      else {
        // Handle 3d model projects
        const fullParams = {...params, 
          externalMeta: {
            model_id,
            job_id: projectRef.external_meta.model_id
          } 
        }

        try {
          await doExport(fullParams)
        }
        catch (e) {
          dispatch(setToast({message: "Uh oh. We had an issue exporting your model, please try again.", isError: true}))
        }
      }

      // Done
      document.dispatchEvent(new CustomEvent(EVENTS.EXPORT_STARTED))
      document.dispatchEvent(new CustomEvent(EVENTS.ARITIZE3D_EXPORT_STARTED))
    }

    const doExport = (params) => {
      return new Promise(async (resolve, reject) => {
        try {
          const currentlyExported = await exportModel(sceneRef, projectRef, access_token, params)
          dispatch(setOngoingExports(currentlyExported.id))
          dispatch(setUnreadExport(currentlyExported.id))
          resolve()
        }
        catch (e) {
          console.error("Error exporting! ", e)
          reject(e)
        }
      })
    }

    document.addEventListener(EVENTS.START_EXPORT, exportHandler)
    document.addEventListener(EVENTS.START_ARITIZE3D_EXPORT, aritizeExportHandler)

    return () => {
      document.removeEventListener(EVENTS.START_EXPORT, exportHandler)
      document.removeEventListener(EVENTS.START_ARITIZE3D_EXPORT, aritizeExportHandler)
    }
    
  }, [dispatch, access_token, sceneRef, projectRef, handleSelectVariation])
}

export default useCreateExport