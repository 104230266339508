import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useRefreshCodeForUserMutation, useVerifyUserMutation } from "../../redux/api.redux.slice"
import { logout, setIsVerified } from "../../redux/auth.redux.slice"
import ModalComponent from "../modal/modal.component"
import SpinnerComponent from "../spinner/spinner.component"
import { setToast } from "../../redux/ui.redux.slice"

const VerificationModalComponent = () => {
  const dispatch = useDispatch()
  const [verifyUser, {isLoading: isVerifyLoading}] = useVerifyUserMutation()
  const [refreshCode, {isLoading: isCodeLoading}] = useRefreshCodeForUserMutation()
  const user = useSelector((state) => state.auth.user)
  const [verificationCode, setVerificationCode] = useState("")
  const [verifyError, setVerifyError] = useState(false)
  const [canRequestNewCode, setCanRequestNewCode] = useState(false)

  const handleVerifyUser = async () => {
    setVerifyError(false)
    setCanRequestNewCode(false)

    try {
      // Verify user
      await verifyUser({
        email: user.email,
        code: verificationCode
      }).unwrap()

      // All done
      dispatch(setIsVerified(true))
    }
    catch (e) {
      console.error("Error verifying user: ", e)
      if (e.data && e.data.data && e.data.data.canRenew) {
        setVerifyError("Uh oh. It looks like your code has expired - please request a new code.")
        setCanRequestNewCode(true)
      }
      else {
        setVerifyError("Uh oh. We had an error verifying your account. Please check your code and try again.")
      }
    }
  }

  const handleRequestNewCode = async () => {
    setVerifyError(false)

    try {
      // Refresh code
      await refreshCode({
        email: user.email,
        code: verificationCode
      }).unwrap()

      // Once we're done, we'll reset
      setCanRequestNewCode(false)
      setVerificationCode("")
    }
    catch (e) {
      console.error("Error requesting new code: ", e)
      dispatch(setToast({message: "Uh oh, we had an issue generating a new code. Please try again.", isError: true}))
    }
  } 

  const handleLogout = () => {
    dispatch(logout())
  }

  const modalContent = user ? (
    <>
      <p>Before continuing, let's verify your account. Enter your verification code sent by <strong>hey@mail.toggle3d.com</strong></p>
      <div className="threedy-lab-text-input mini">
        <input type="text" placeholder="Code" value={verificationCode} onChange={(e) => setVerificationCode(e.target.value)} />
      </div>
      { verifyError && <div className="error centered">{verifyError}</div> }
      { canRequestNewCode && <button className="secondary-btn centered" disabled={!verificationCode || isVerifyLoading || isCodeLoading} onClick={handleRequestNewCode}>Request new code</button> }
      <button className="primary-btn centered" disabled={!verificationCode || isVerifyLoading || canRequestNewCode} onClick={handleVerifyUser}>Verify</button>
      <button className="delete-btn centered" onClick={handleLogout}>Cancel & Logout</button>
    </>
  ) : (
    <div className="threedy-lab-spinner-wrapper">
      <div className="threedy-lab-spinner-container inline"><SpinnerComponent /></div>
    </div>
  )

  return (
    <ModalComponent title="Please verify your account" hideClose content={modalContent} />
  )
}

export default VerificationModalComponent