import { useEffect, useRef, useState } from "react"
import MiscHelperMethods from "../helper-methods/misc.helpers"
import "./tools-menu.component.css"

const ToolsMenuComponent = (props) => {
  const { project, selectedToolId, onChangeTool, tools } = props
  const [items, setItems] = useState([])
  const [selectedTool, setSelectedTool] = useState()

  useEffect(() => {
    if (!project) { return}

    const arr = []

    if (MiscHelperMethods.isConfiguratorOr3dOrVariationsProject(project)) {
      arr.push({id: "elements", icon: "layers", label: "Elements"})
    }

    // Commented out for launch
    // if (MiscHelperMethods.isConfiguratorProject(project)) {
    //   arr.push({id: "options", icon: "", label: "Options"})
    // }

    // if (MiscHelperMethods.isVariationsProject(project)) {
    //   arr.push({id: "variations", icon: "", label: "Variations"})
    // }

    arr.push({id: "lighting", icon: "light_mode", label: "Lighting"})

    setItems(arr)

  }, [project])

  useEffect(() => {
    if (!selectedToolId) {
      setSelectedTool(null)
      return
    }

    const selected = items.find((i) => i.id === selectedToolId)
    setSelectedTool(selected)
  }, [selectedToolId, items])

  return (
    <div className="toggle-editor-tools">
      <ul className="toggle-editor-tools-menu">
        {items.map((i) => {
          return (
            <li key={i.id}><button className={selectedToolId === i.id ? "selected" : ""} onClick={() => onChangeTool(i.id)}><span className={`icon-${i.icon}`} /></button></li>
          )
        })}
      </ul>
      { selectedTool && (
        <div className="toggle-editor-tools-panel">
          <div className="toggle-editor-tools-panel-header">
            <h6><span className={`icon-${selectedTool.icon}`} />{selectedTool.label}</h6>
            <button className="close-btn" onClick={() => onChangeTool(false)}><span className="icon-close"></span></button>
          </div>
          <div className="toggle-editor-tools-panel-content">
            {selectedToolId === "elements" && tools.elements }
            {selectedToolId === "lighting" && tools.lighting }
          </div>
        </div>
      )}
    </div>
  )
}

export default ToolsMenuComponent