import { configureStore } from "@reduxjs/toolkit"
import authReduxSlice from "./auth.redux.slice"
import uiReduxSlice from "./ui.redux.slice"
import apiReduxSlice from "./api.redux.slice"
import historyReduxSlice from "./history.redux.slice"
import materialsReduxSlice from "./materials.redux.slice"

const reduxStore = configureStore({
  reducer: {
    auth: authReduxSlice,
    ui: uiReduxSlice,
    history: historyReduxSlice,
    materials: materialsReduxSlice,
    [apiReduxSlice.reducerPath]: apiReduxSlice.reducer
  },
  middleware: getDefaultMiddleware => getDefaultMiddleware({
    immutableCheck: false,
    serializableCheck: false
  }).concat(apiReduxSlice.middleware),
})

export default reduxStore