import { useDispatch } from "react-redux"
import { setShowViewerHelp } from "../../redux/ui.redux.slice"
import ModalComponent from "../modal/modal.component"
import "./viewer-help-modal.component.css"

const ViewerHelpModalComponent = () => {
  
  const dispatch = useDispatch()

  const handleClose = () => {
    dispatch(setShowViewerHelp(false))
  }

  const modalContent = (
    <>
      <ul className="threedy-list no-header spacing">
        <li className="no-action">
          <div className="smallest">
            <h4>Rotate</h4>
            <br />
            <img className="small-image invert" src="/images/help-rotate.png" alt="Rotate" />
          </div>
          <div className="normal">Left click and drag your mouse/touchpad to rotate the model 360*</div>
        </li>
        <li className="no-action">
          <div className="smallest">
            <h4>Zoom in/out</h4>
            <br />
            <img className="small-image invert" src="/images/help-zoom.png" alt="Zoom" />
          </div>
          <div className="normal">To zoom in and out, scroll your mouse wheel / pinch your touchpad</div>
        </li>
        <li className="no-action">
          <div className="smallest">
            <h4>Pan</h4>
            <br />
            <img className="small-image invert" src="/images/help-pan.png" alt="Pan" />
          </div>
          <div className="normal">Hold control & left click while dragging your mouse/touchpad to pan</div>
        </li>
      </ul>
    </>
  )
  const actions = (
    <button className="primary-btn centered" onClick={handleClose}>Got it!</button>
  )
  return (
    <ModalComponent title="How to Use the Design Scene" close={handleClose} content={modalContent} className="small viewer-help-modal" actions={actions} />
  )
}

export default ViewerHelpModalComponent