import { useDispatch } from "react-redux"
import { setShowViewerAR } from "../../redux/ui.redux.slice"
import ModalComponent from "../modal/modal.component"
import QRCode from "qrcode"
import { useEffect, useState } from "react"
import { APP_VIEW_URL } from "../../constants"

const ViewerARModalComponent = () => {
  
  const dispatch = useDispatch()
  const [qr, setQr] = useState(false)

  const handleClose = () => {
    dispatch(setShowViewerAR(false))
  }

  useEffect(() => {

    const createCode = async () => {
      const append = window.location.search.length > 0 ? "&loadAR=true" : "?loadAR=true"
      const url = await QRCode.toDataURL(`${APP_VIEW_URL}${window.location.pathname}${window.location.search}${append}`)
      setQr(url)
    }

    createCode()

  }, [])

  const modalContent = (
    <div className="centered">
      <h2>Scan the QR code to view in AR</h2>
      <p>Use your mobile device camera app to scan the code below. Tap the code that appears to launch this object in Augmented Reality.</p>
      <img src={qr} alt="QR Code" className="toggle-qr-code" />
      <div className="toggle-note">
        <span><strong>Note:</strong> May not be compatible with older devices</span>
      </div>
    </div>
  )

  const actions = (
    <button className="primary-btn centered" onClick={handleClose}>Got it!</button>
  )
  
  if (!qr) {
    return null
  }

  return (
    <ModalComponent title="" close={handleClose} content={modalContent} actions={actions} />
  )
}

export default ViewerARModalComponent