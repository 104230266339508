import { useNavigate, useParams } from "react-router-dom"
import PlansSettingsSection from "./sections/plans.settings.section"
import ProfileSettingsSection from "./sections/profile.settings.section"
import IntegrationsSettingsSection from "./sections/integrations.settings.section"
import SecuritySettingsSection from "./sections/security.settings.section"
import TabBarComponent from "../../components/tab-bar/tab-bar.component"
import meta from "../../meta.json"
import "./settings.page.css"
import { useSelector } from "react-redux"
import HeaderGroupComponent from "../../components/header-group/header-group.component"

const SettingsPage = (props) => {

  const navigate = useNavigate()
  const {page} = useParams()
  const role = useSelector((state) => state.auth.role)
  const getSection = () => {
    switch (page) {
      case "profile":
        return <ProfileSettingsSection />
      case "security":
        return <SecuritySettingsSection />
      case "plans":
        return <PlansSettingsSection stripePromise={props.stripePromise} />
      case "integrations":
        return <IntegrationsSettingsSection />
      default: 
        return <ProfileSettingsSection />
    }
  }

  const handleTabChange = (inTab) => {
    if (inTab !== page) {
      navigate(`/settings/${inTab}`)
    }
  }

  const tabItems = (role && (role === 'admin' || role === 'internal')) ? [{
    id: 'profile',
    label: 'Profile',
    icon: 'icon-user'
  }, 
  {
    id: 'security',
    label: 'Security',
    icon: 'icon-lock'
  }, 
  {
    id: 'plans',
    label: 'Plans',
    icon: 'icon-columns'
  }, 
  {
    id: 'integrations',
    label: 'Integrations',
    icon: 'icon-code'
  }] : [{
    id: 'profile',
    label: 'Profile',
    icon: 'icon-user'
  }, 
  {
    id: 'security',
    label: 'Security',
    icon: 'icon-lock'
  }, 
  {
    id: 'plans',
    label: 'Plans',
    icon: 'icon-columns'
  }]

  return (
    <>
      <div className="toggle-content-group">
        <HeaderGroupComponent header="Account Settings" level="4" icon="icon-settings" headerClass="darkerText" />
        <TabBarComponent items={tabItems} default={page} onSelection={handleTabChange} />
        {getSection()}
      </div>
    </>
  )
}

export default SettingsPage