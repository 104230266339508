import { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { APP_URL, APP_VIEW_URL, EVENTS, KLAVIYO_METRICS } from "../../constants"
import { useEditProjectMutation, usePublishProjectMutation } from "../../redux/api.redux.slice"
import { hideProjectSharingModal, setProjectSaving, setSelectedProject, setToast } from "../../redux/ui.redux.slice"
import "./project-sharing-modal.component.css"
import moment from "moment"
import NotAvailableForFreeModalComponent from "../upgrade-to-pro/not-available-for-free-modal.component"
import ModalComponent from "../modal/modal.component"
import ToggleButtonComponent from "../forms/toggle-button.component"

const ProjectSharingModalComponent = () => {
  
  const dispatch = useDispatch()
  const shareLinkRef = useRef()
  const viewRef = useRef()
  const embedRef = useRef()
  const [shareProps, setShareProps] = useState({shared: false, public_link: ''})
  const [updateProjectQuery] = useEditProjectMutation()
  const [publishProjectQuery] = usePublishProjectMutation()
  const [selectedProject, plan, projectSaving] = useSelector((state) => [state.ui.selectedProject, state.auth.plan, state.ui.projectSaving])
  const [isLoading, setIsLoading] = useState(false)
  const [showNotAvailableForFreeModal, setShowNotAvailableForFreeModal] = useState(false)

  const handleCloseSharing = useCallback(() => {
    dispatch(hideProjectSharingModal())
  }, [dispatch])

  // Disable key handler / enable on dispose
  useEffect(() => {
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: false}}))
    return () => document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: true}}))
  }, [])

  useEffect(() => {
    setShareProps({shared: selectedProject.shared, public_link: selectedProject.public_link})
  }, [selectedProject])

  const handleToggleSharing = async () => {
    setIsLoading(true)
    const params = {shared: false, public_link: ''}

    if (!shareProps.shared) {
      params.shared = true
      params.public_link = `sh-${new Date().valueOf().toString()}-${selectedProject.id}`
    }

    try {
      await updateProjectQuery({projectId: selectedProject.id, body: params}).unwrap()
      setShareProps(params)

      // Update selected project
      dispatch(setSelectedProject({...selectedProject, ...params}))

      setIsLoading(false)
    }
    catch (e) {
      console.log("Error updating project sharing: ", e)
      dispatch(setToast({message: "Uh oh. We had an issue updating your project's sharing settings, please try again.", isError: true}))
      setIsLoading(false)
    }
  }

  const handleCopyLink = (e, type) => {
    const { target } = e
    target.innerText = "Copied!"
    let val 
    let defaultText = "Copy Link"
    let theRef

    if (type === 'share') {
      val = shareLinkRef.current.value
      theRef = shareLinkRef
    }
    else if (type === 'view') {
      val = viewRef.current.value
      theRef = viewRef
    }
    else {
      val = embedRef.current.value
      defaultText = "Copy Embed"
      theRef = embedRef
    }
    
    if (!navigator.clipboard) {
      copyPasteOldWay(target, defaultText, theRef)
    }
    else {
      navigator.clipboard.writeText(val).then(() => {

        setTimeout(() => {
          target.innerText = defaultText
        }, 1000)

      }).catch(() => {
        copyPasteOldWay(target, defaultText, theRef)
      })
    }
  }

  const copyPasteOldWay = (target, defaultText, theRef) => {
    theRef.current.select()
    document.execCommand("copy")

    setTimeout(() => {
      target.innerText = defaultText
    }, 1000)
  }
  
  const handleTogglePublished = async () => {
    if (!isPro()) {
      return false
    }
    // We'll simply enable or disable "is_published" flag
    const is_published = !selectedProject.is_published
    dispatch(setProjectSaving({saving: true, caller: "shareModal"}))
    setIsLoading(true)

    try {

      let body = {is_published}

      // If we're enabling but don't yet have a snapshot, let's create one as well
      if (is_published && !selectedProject.last_snapshot_id) {
        const snapshotRes = await publishProject()
        body = {...body, ...snapshotRes}
      }

      // Update
      await updateProjectQuery({projectId: selectedProject.id, body}).unwrap()

      // Update selected project
      dispatch(setSelectedProject({...selectedProject, ...body}))
      setIsLoading(false)
      dispatch(setProjectSaving({saving: false, caller: "shareModal"}))
    }
    catch (e) {
      console.log("Error updating project is_published: ", e)
      dispatch(setToast({message: "Uh oh. We had an issue updating your project's settings, please try again.", isError: true}))
      setIsLoading(false)
    }
  }

  const handlePublishProject = async () => {
    if (!isPro()) {
      return false
    }
    dispatch(setProjectSaving({saving: true, caller: "shareModal"}))
    setIsLoading(true)

    try {
      const snapshotRes = await publishProject()
      window.klaviyo.track(KLAVIYO_METRICS.shared_project)
      // Update selected project
      dispatch(setSelectedProject({...selectedProject, ...snapshotRes}))
      dispatch(setProjectSaving({saving: false, caller: "shareModal"}))
      setIsLoading(false)
    }
    catch (e) {
      console.log("Error publishing project: ", e)
      dispatch(setToast({message: "Uh oh. We had an issue publishing your model, please try again.", isError: true}))
      setIsLoading(false)
      dispatch(setProjectSaving({saving: false, caller: "shareModal"}))
    }
  }
  
  const publishProject = () => {
    // Create a snapshot
    return new Promise(async (resolve, reject) => {
      try {
        const snapshot = await publishProjectQuery(selectedProject.id).unwrap()
        resolve(snapshot)
      }
      catch (e) {
        reject(e)
      }
    })
  }

  const isPro = () => {
    if (plan && plan.type !== 'pro') {
      setShowNotAvailableForFreeModal(true)
      return false
    }
    return true
  }

  const handlePublishToAritize = async() => {
    dispatch(setProjectSaving({saving: true, caller: "shareModal"}))
    setIsLoading(true)

    try {

      // First, make an export & check for specific settings for this client
      const clientRules = selectedProject.external_meta.export_rules ? selectedProject.external_meta.export_rules : {}
      const exportOptions = {name: selectedProject.name, formats: {glb: true}, resolution: "2k", bake: false, destination: "aritize3D", ...clientRules}

      // Dispatch event
      document.dispatchEvent(new CustomEvent(EVENTS.START_ARITIZE3D_EXPORT, {detail: {...exportOptions}}))
    }
    catch (e) {
      console.log("Error publishing project to ARitize3D: ", e)
      dispatch(setToast({message: "Uh oh. We had an issue publishing your model to ARitize3D, please try again.", isError: true}))
      setIsLoading(false)
      dispatch(setProjectSaving({saving: false, caller: "shareModal"}))
    }
  }

  // Listen for export started event
  useEffect(() => {
    const handleExportStarted = () => {
      setIsLoading(false)
      dispatch(setProjectSaving({saving: false, caller: "shareModal"}))
    }

    document.addEventListener(EVENTS.ARITIZE3D_EXPORT_STARTED, handleExportStarted)

    return () => document.removeEventListener(EVENTS.ARITIZE3D_EXPORT_STARTED, handleExportStarted)
  }, [dispatch])

  const content = (
    <>
    <div className={isLoading ? "toggle-sharing-modal-component loading" : "toggle-sharing-modal-component" }>
      {projectSaving.saving && <h5 className="primary-color">Wrapping up your project settings...</h5>}
      <div className="toggle-sharing-modal-content">
        <h5>Private Link</h5>
        <ToggleButtonComponent disabled={projectSaving.saving} onClick={handleToggleSharing} isToggleOn={shareProps.shared} inlineText="Enable Access" />
        {shareProps.shared && shareProps.public_link && (
          <div className="toggle-sharing-value">
            <input type="text" className="toggle-text-input" ref={shareLinkRef} disabled value={`${APP_URL}/share/${shareProps.public_link}`} />
            <button disabled={!shareProps.shared || !shareProps.public_link} className="toggle-primary-btn" onClick={(e) => handleCopyLink(e, "share")}>Copy Link</button>
          </div>
        )}
      </div>
      {selectedProject.model_id && (
        <div className="toggle-sharing-modal-content">
          <h5>Public Link</h5>
          {(plan && plan.type !== 'pro') && <span className="icon-lock right-top"></span>}
          <h6>{selectedProject.last_snapshot_ts ? `Last published on ${moment(selectedProject.last_snapshot_ts).format("MM/DD/YYYY")} at ${moment(selectedProject.last_snapshot_ts).format("hh:mm a")}` : "Your model is not currently published"}</h6>
          <ToggleButtonComponent disabled={projectSaving.saving} onClick={handleTogglePublished} isToggleOn={selectedProject.is_published} inlineText="Enable Access" />
          {selectedProject.is_published && (
            <>
              <button disabled={isLoading} className="toggle-outline-btn auto-width" onClick={handlePublishProject}>Publish Latest Changes</button>
              <h5>View</h5>
              <div className="toggle-sharing-value">
                <input type="text" className="toggle-text-input" ref={viewRef} disabled value={`${APP_VIEW_URL}/${selectedProject.id}/latest`} />
                <button disabled={!selectedProject.last_snapshot_id || !selectedProject.is_published} className="toggle-primary-btn" onClick={(e) => handleCopyLink(e, "view")}>Copy Link</button>
              </div>
              <h5>Embed</h5>
              <div className="toggle-sharing-value">
                <input type="text" className="toggle-text-input" ref={embedRef} disabled value={`<iframe style='overflow: hidden; margin: 0; padding: 0; border:0; height:100vh; width:100%;' src='${APP_VIEW_URL}/${selectedProject.id}/latest'></iframe>`} />
                <button disabled={!selectedProject.last_snapshot_id || !selectedProject.is_published} className="toggle-primary-btn" onClick={(e) => handleCopyLink(e, "embed")}>Copy Embed</button>
              </div>
            </>
          )}
        </div>
      )}
      {selectedProject.external_meta && selectedProject.external_meta.type === "aritize3D" && (
        <div className="toggle-sharing-modal-content">
          <h5>Publish to ARitize3D</h5>
          <a href={selectedProject.external_meta.model_url} target="_blank" rel="noreferrer">View on ARitize3D</a>
          {selectedProject.external_meta.last_published_ts && <h6>Last Published {moment(selectedProject.external_meta.last_published_ts).format("MM/DD/YYYY hh:mm a")}</h6>}
          <button className="toggle-outline-btn auto-width" disabled={projectSaving.saving} onClick={handlePublishToAritize}><img src="/images/aritize-logo.svg" alt="ARitize3D" /> Publish</button>
        </div>
      )}
    </div>
      {showNotAvailableForFreeModal &&
        <NotAvailableForFreeModalComponent close={() => setShowNotAvailableForFreeModal(false)} />}
    </>
  )

  return <ModalComponent title="Share" content={content} close={handleCloseSharing} />
}

export default ProjectSharingModalComponent