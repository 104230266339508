import './splash.page.css'
import { Link, useNavigate } from 'react-router-dom'
import { useCallback, useEffect, useRef, useState } from 'react'
import { gsap } from 'gsap'
import { ScrollTrigger, MotionPathPlugin } from 'gsap/all'
import Session from '../../utils/session.util'
import moment from 'moment'
import { MOBILE_WINDOW_HEIGHT, MOBILE_WINDOW_WIDTH, APP_RESOURCES_URL } from '../../constants'

gsap.registerPlugin(ScrollTrigger)
gsap.registerPlugin(MotionPathPlugin)

const SplashPage = (props) => {

  const navigate = useNavigate()
  const firstSplashRef = useRef()
  const thirdSplashRef = useRef()
  const fourthSplashRef = useRef()
  const sectionsRef = useRef([])
  const timerRef = useRef()
  const animationsRef = useRef([])
  const session = Session.read()
  const [content, setContent] = useState()

  const createSectionRefs = (section, index) => {
    sectionsRef.current[index] = section
  }

  const handleScrollTop = () => {
    const el = document.querySelector(".toggle-splash")
    if (el) {
      el.scrollIntoView({behavior: 'smooth', block: 'start'})
    }
  }

  useEffect(() => {

    // Redirecting to dashboard if already session available for user
    // Adding exp check as well
    if (session && session.access_token && session.refresh_token && moment(session.exp).isAfter(moment())) {
      navigate(`/dashboard`, { replace: true })
      return
    }

  }, [navigate, session])

  const handleSplashAnimations = useCallback(() => {
    // If we're on mobile or within signup, we'll abort
    if (props.embeddedInSignUp || window.innerWidth <= MOBILE_WINDOW_WIDTH || window.innerHeight <= MOBILE_WINDOW_HEIGHT) {
      return
    }

    const scrollHint = sectionsRef.current[0].querySelector(".scroll-hint")
    const getStartedButton = sectionsRef.current[0].querySelector(".primary-btn")
    const getStartedButtonSpan = sectionsRef.current[0].querySelector(".primary-btn span.fill")
    const buttonRect = getStartedButton.getBoundingClientRect()
    const buttonOffset = buttonRect.width + buttonRect.left
    const wireframeCar = sectionsRef.current[0].querySelector(".wireframe")
    const wireframeCarRect = wireframeCar.getBoundingClientRect()
    const renderedCar = sectionsRef.current[0].querySelector(".rendered")
    const renderedCarRect = renderedCar.getBoundingClientRect()
    const renderedCarOffset = renderedCarRect.width + renderedCarRect.left
    const circles = sectionsRef.current[0].querySelector(".circles")

    // Show scroll hint
    const animations = []
    animations.push(gsap.to(scrollHint, {
      ease: "power3",
      transform: "none",
      opacity: 1,
      duration: 0.5,
      delay: 1,
      onEnd: () => {

        // Change color 
        gsap.to(scrollHint, {
          ease: "power3",
          backgroundColor: "#8005ff",
          transform: 'scale(1.05)',
          duration: 0.5,
          delay: 1,
          onEnd: () => {
            // Change color back 
            gsap.to(scrollHint, {
              ease: "power3",
              backgroundColor: "black",
              transform: 'none',
              duration: 0.5,
              delay: 0.5
            })
          }
        })
      }
    }))
    
    // Landing
    animations.push(gsap.to(sectionsRef.current[1], {
      xPercent: -100,
      ease: "none",
      scrollTrigger: {
        trigger: firstSplashRef.current,
        pin: true,
        scrub: 1,
        snap: {
          delay: 0.4,
          snapTo: 1,
          duration: {min: 0.1, max: 0.3}
        },
        onUpdate: (self) => {
          if (self.progress > 0.1) {
            gsap.to(scrollHint, {
              ease: "power3",
              opacity: 0,
              transform: 'scale(0.9) translateY(5vh)',
              duration: 0.5
            })

            gsap.to(circles, {
              filter: "none",
              duration: 0
            })
          }
          else {
            gsap.to(circles, {
              filter: "invert(0.6)",
              duration: 0
            })
          }
        },
        end: () => "+=" + (firstSplashRef.current ? firstSplashRef.current.offsetWidth : 0),
        invalidateOnRefresh: true
      },
      onUpdate: () => {
        if (sectionsRef.current && sectionsRef.current.length > 1 && sectionsRef.current[1]) {
          const pageRect = sectionsRef.current[1].getBoundingClientRect()
          const buttonRelativeOffset = Math.max(buttonOffset - pageRect.left, 0)
          const carRelativeOffset = Math.min(Math.max(renderedCarOffset - pageRect.left, 0), wireframeCarRect.width)

          // Animate
          gsap.to(getStartedButtonSpan, {right: `${buttonRelativeOffset}px`, duration: 0})
          gsap.to(renderedCar, {width: `${carRelativeOffset}px`, duration: 0})

          if (buttonRelativeOffset > 0) {
            gsap.to(getStartedButton, {color: '#000', duration: 0})
          }
          else {
            gsap.to(getStartedButton, {color: '#fff', duration: 0})
          }
        }
      }
    }))

    // Animate load btn and cursor in to simulate "CAD load"
    const loadBtn = sectionsRef.current[3].querySelector(".load-btn")
    const cursor = sectionsRef.current[3].querySelector(".cursor")

    // 1. Load btn
    animations.push(gsap.to(loadBtn, {
      scrollTrigger: {
        trigger: sectionsRef.current[2],
        scrub: true,
        start: "bottom bottom-=100%",
        end: "bottom end-=110%"
      },
      motionPath: {
        curviness: 2,
        path: [
          {opacity: 0, yPercent: 0},
          {opacity: 1, yPercent: 0},
          {opacity: 0, yPercent: 40}
        ],
        alignOrigin: [0.5, 0.5]
      }
    }))

    // 2. Cursor
    animations.push(gsap.to(cursor, {
      scrollTrigger: {
        trigger: sectionsRef.current[2],
        scrub: true,
        start: "bottom bottom-=100%",
        end: "bottom end-=110%"
      },
      motionPath: {
        curviness: 2,
        path: [
          {opacity: 0},
          {opacity: 1},
          {opacity: 0}
        ],
        alignOrigin: [0.5, 0.5]
      }
    }))

    // Animate car model in, starting with Cad -> Mesh, then add color
    // 1. Car CAD
    const carCad = sectionsRef.current[3].querySelector(".car-model-cad")
    animations.push(gsap.to(carCad, {
      scrollTrigger: {
        trigger: sectionsRef.current[2],
        scrub: true,
        start: "bottom bottom-=80%",
        end: "bottom end-=110%"
      },
      motionPath: {
        curviness: 2,
        path: [
          {opacity: 0, scale: 0},
          {opacity: 1, scale: 1}
        ],
        alignOrigin: [0.5, 0.5]
      }
    }))

    // 2. Car Mesh
    const carMesh = sectionsRef.current[3].querySelector(".car-model-mesh")
    animations.push(gsap.to(carMesh, {
      scrollTrigger: {
        trigger: sectionsRef.current[2],
        scrub: true,
        start: "bottom bottom-=140%",
        end: "bottom end-=190%"
      },
      motionPath: {
        curviness: 2,
        path: [
          {opacity: 0},
          {opacity: 1}
        ],
        alignOrigin: [0.5, 0.5]
      }
    }))

    // 3. Car Rendered
    const carRendered = sectionsRef.current[3].querySelector('.car-model-rendered')
    animations.push(gsap.to(carRendered, {
      scrollTrigger: {
        trigger: sectionsRef.current[2],
        scrub: true,
        start: "bottom bottom-=1000%",
        end: "bottom end-=1000%"
      },
      motionPath: {
        curviness: 2,
        path: [
          {opacity: 0},
          {opacity: 1}
        ],
        alignOrigin: [0.5, 0.5]
      }
    }))

    // Third section
    const thirdh1 = sectionsRef.current[3].querySelector("h1")
    const ul = sectionsRef.current[3].querySelector("ul")
    animations.push(gsap.to(sectionsRef.current[3], {
      xPercent: -100,
      opacity: 1,
      ease: "none",
      scrollTrigger: {
        trigger: sectionsRef.current[2],
        pin: true,
        start: 'bottom center',
        scrub: 1,
        invalidateOnRefresh: true,
        onUpdate: (self) => {
          const progress = self.progress
          gsap.to(thirdh1, {
            xPercent: progress * -100,
            duration: 0
          })

          gsap.to(ul, {
            xPercent: progress * -100,
            duration: 0
          })
        }
      }
    }))

    // Fourth: Animate shaded car to purple 
    const thirdText = sectionsRef.current[3].querySelector('.third-text')
    const fifthH1 = sectionsRef.current[5].querySelector('h1')
    const fifthP = sectionsRef.current[5].querySelector('p')
    const carShaded = sectionsRef.current[3].querySelector('.car-model-shading')
    const carRenderedLighten = sectionsRef.current[3].querySelector('.car-model-rendered-lighten')
    const fourthUl = sectionsRef.current[4].querySelector('ul')
    const colorPicker = sectionsRef.current[4].querySelector('.color-picker')
    const colorPickerPointer = sectionsRef.current[4].querySelector('.color-picker-pointer')
    const purpleMaterial = sectionsRef.current[4].querySelector('.purple-material')
    const slider = sectionsRef.current[5].querySelector('.slider')
    const topSlider = sectionsRef.current[5].querySelector('.slider-bar span')
    const grid = thirdSplashRef.current.querySelector(".circles-grid")

    // Purple material added to car
    animations.push(gsap.to(purpleMaterial, {
      scrollTrigger: {
        trigger: thirdSplashRef.current,
        scrub: true,
        start: "bottom end-=600%",
        end: "bottom end-=" + thirdSplashRef.current.offsetWidth*1.3,
        onUpdate: (self) => {
          if (self.progress >= 0.9) {

            // Clear swatch
            gsap.to(purpleMaterial, {
              scale: 0,
              duration: 0.1
            })
          }
          else {
            gsap.to(purpleMaterial, {
              scale: 1,
              duration: 0.1
            })
          }
        }
      },
      motionPath: {
        curviness: 2,
        path: [
          {x: 200, y: -50}, 
          {x: 200, y: 0},
        ],
        alignOrigin: [0.5, 0.5]
      }
    }))

    // Color picker -> Purple to Light Purple and back
    animations.push(gsap.to(colorPickerPointer, {
      scrollTrigger: {
        trigger: thirdSplashRef.current,
        scrub: true,
        start: "bottom end-=600%",
        end: "bottom end-=" + thirdSplashRef.current.offsetWidth*2,
      },
      motionPath: {
        curviness: 2,
        path: [
          {x: 0},
          {x: 100}
        ],
        alignOrigin: [0.5, 0.5]
      }
    }))

    // Handle Car Color Slider
    animations.push(gsap.to(topSlider, {
      scrollTrigger: {
        trigger: thirdSplashRef.current,
        scrub: true,
        start: "bottom end-=1500%",
        end: "bottom end-=" + thirdSplashRef.current.offsetWidth*3,
      },
      motionPath: {
        curviness: 2,
        path: [
          {x: 0},
          {x: 80}
        ],
        alignOrigin: [0.5, 0.5]
      }
    }))

    // Car -> Purple to Light Purple and back
    animations.push(gsap.to(carRenderedLighten, {
      scrollTrigger: {
        trigger: thirdSplashRef.current,
        scrub: true,
        start: "bottom end-=1500%",
        end: "bottom end-=" + thirdSplashRef.current.offsetWidth*3,
      },
      motionPath: {
        curviness: 2,
        path: [
          {opacity: 0}, 
          {opacity: 1}
        ],
        alignOrigin: [0.5, 0.5]
      }
    }))

    // VP3 -> 5
    animations.push(gsap.to([sectionsRef.current[4], sectionsRef.current[5]], {
      xPercent: -200,
      ease: "none",
      scrollTrigger: {
        trigger: thirdSplashRef.current,
        pin: true,
        scrub: 1,
        snap: false,
        end: () => "+=" + (thirdSplashRef.current ? (3*thirdSplashRef.current.offsetWidth) : 0),
        invalidateOnRefresh: true,
        onUpdate: (self) => {

          const percentage = self.progress * 100

          gsap.to(fourthUl, {
            ease: 'none',
            xPercent: -2.5 * percentage,
            duration: 0
          })

          gsap.to(colorPicker, {
            ease: 'none',
            xPercent: 0.1 * percentage,
            duration: 0
          })

          gsap.to(ul, {
            right: (2 * percentage) + '%',
            duration: 1
          })

          gsap.to(thirdText, {
            xPercent: -1 * percentage,
            duration: 0
          })
          
          gsap.to(carShaded, {
            clipPath: 'polygon(' + (100 - (2*percentage)) + '% 100%, 100% 100%, 100% 0, ' + (100 - (2*percentage)) + '% 0)',
            duration: 0.4
          })

          gsap.to(fifthP, {
            ease: 'none',
            xPercent: -1 * percentage,
            duration: 0
          })

          gsap.to(fifthH1, {
            ease: 'none',
            xPercent: -1 * percentage,
            duration: 0
          })
        }
      }
    }))

    // Animate VP5 car / grid out
    animations.push(gsap.to([carRendered, carRenderedLighten, carShaded, carMesh, carCad], {
      scrollTrigger: {
        trigger: fourthSplashRef.current,
        scrub: true,
        start: "top top+=100%",
        end: "top start+=5%"
      },
      motionPath: {
        curviness: 2,
        path: [
          {yPercent: 0}, 
          {yPercent: -100}
        ],
        alignOrigin: [0.5, 0.5]
      }
    }))

    animations.push(gsap.to(slider, {
      scrollTrigger: {
        trigger: fourthSplashRef.current,
        scrub: true,
        start: "top top+=100%",
        end: "top start+=5%"
      },
      motionPath: {
        curviness: 2,
        path: [
          {opacity: 1, yPercent: 0}, 
          {opacity: 0, yPercent: -100}
        ],
        alignOrigin: [0.5, 0.5]
      }
    }))

    animations.push(gsap.to(grid, {
      scrollTrigger: {
        trigger: fourthSplashRef.current,
        scrub: true,
        start: "top top+=100%",
        end: "top start+=5%"
      },
      motionPath: {
        curviness: 2,
        path: [
          {opacity: 0.2}, 
          {opacity: 0}
        ],
        alignOrigin: [0.5, 0.5]
      }
    }))

    // Handle Comment Bubbles + Publish (3)
    // Bubble 1
    const bubbleOne = sectionsRef.current[7].querySelector(".bubble-1")
    animations.push(gsap.to(bubbleOne, {
      scrollTrigger: {
        trigger: sectionsRef.current[6],
        scrub: true,
        start: "bottom bottom-=130%",
        end: "bottom end-=100%"
      },
      motionPath: {
        curviness: 2,
        path: [
          {opacity: 0, scale: 0},
          {opacity: 1, scale: 1}
        ],
        alignOrigin: [0.5, 0.5]
      }
    }))

    // Bubble 2
    const bubbleTwo = sectionsRef.current[7].querySelector(".bubble-2")
    animations.push(gsap.to(bubbleTwo, {
      scrollTrigger: {
        trigger: sectionsRef.current[6],
        scrub: true,
        start: "bottom bottom-=140%",
        end: "bottom end-=110%"
      },
      motionPath: {
        curviness: 2,
        path: [
          {opacity: 0, scale: 0},
          {opacity: 1, scale: 1}
        ],
        alignOrigin: [0.5, 0.5]
      }
    }))

    // Publish
    const publishBtn = sectionsRef.current[7].querySelector(".publish-btn")
    animations.push(gsap.to(publishBtn, {
      scrollTrigger: {
        trigger: sectionsRef.current[6],
        scrub: true,
        start: "bottom bottom-=150%",
        end: "bottom end-=120%"
      },
      motionPath: {
        curviness: 2,
        path: [
          {opacity: 0, scale: 0},
          {opacity: 1, scale: 1}
        ],
        alignOrigin: [0.5, 0.5]
      }
    }))

    // Scale car for ecomm
    const lastCar = fourthSplashRef.current.querySelector(".car-model-rendered")
    animations.push(gsap.to(lastCar, {
      scrollTrigger: {
        trigger: sectionsRef.current[7],
        scrub: true,
        start: "bottom bottom-=240%",
        end: "bottom end-=200%"
      },
      motionPath: {
        curviness: 2,
        path: [
          {scale: 1},
          {scale: 0.6}
        ],
        alignOrigin: [0.5, 0.5]
      }
    }))

    // Remove grid
    const lastGrid = fourthSplashRef.current.querySelector(".circles-grid")
    animations.push(gsap.to(lastGrid, {
      scrollTrigger: {
        trigger: sectionsRef.current[7],
        scrub: true,
        start: "bottom bottom-=200%",
        end: "bottom end-=160%"
      },
      motionPath: {
        curviness: 2,
        path: [
          {opacity: 0.2},
          {opacity: 0}
        ],
        alignOrigin: [0.5, 0.5]
      }
    }))

    // VP 6 -> 8
    const sixH1 = sectionsRef.current[6].querySelector("h1")
    const sixP = sectionsRef.current[6].querySelector("p")
    const ar = sectionsRef.current[6].querySelector(".ar-icon")
    const street = sectionsRef.current[6].querySelector(".street")
    const eComm = sectionsRef.current[8].querySelector(".ecomm")
    animations.push(gsap.to([sectionsRef.current[7], sectionsRef.current[8]], {
      yPercent: -200,
      ease: "none",
      scrollTrigger: {
        trigger: fourthSplashRef.current,
        pin: true,
        scrub: 1,
        snap: false,
        end: () => "+=400%",
        invalidateOnRefresh: true,
        onUpdate: (self) => {

          const percentage = self.progress * 100

          gsap.to(sixH1, {
            ease: 'none',
            yPercent: -8 * percentage,
            duration: 0
          })

          gsap.to(sixP, {
            ease: 'none',
            yPercent: -3 * percentage,
            duration: 0
          })

          gsap.to(street, {
            ease: 'none',
            yPercent: -4 * percentage,
            duration: 0
          })

          gsap.to(ar, {
            ease: 'none',
            yPercent: -13 * percentage,
            duration: 0
          })

          gsap.to(eComm, {
            ease: 'none',
            yPercent: -1 * percentage,
            duration: 0
          })
        }
      }
    }))

    animationsRef.current = animations
  }, [props.embeddedInSignUp])

  const getContent = useCallback(() => {
    const content = (
      <div className="toggle-splash-container">
        <div className="second-splash-content splash-content">
          <div className="section-list-container">
            <ul ref={(e) => createSectionRefs(e, 2)}>
              <li>
                <img src={`${APP_RESOURCES_URL}splash/web.png`} alt="web app" />
                <p className='desktop-only'>No desktop downloads. <br/>Get started instantly</p>
                <p className='mobile-only text-center'>No desktop downloads</p>
              </li>
              <li>
                <img src={`${APP_RESOURCES_URL}splash/experience.png`} alt="no experience needed" />
                <p className='desktop-only'>No technical or design <br/>experience required</p>
                <p className='mobile-only text-center'>All experience levels</p>
              </li>
              <li>
                <img src={`${APP_RESOURCES_URL}splash/templates.png`} alt="easy templates to start with" />
                <p className='desktop-only'>Templates for scalability <br/>and ease</p>
                <p className='mobile-only text-center'>Easy templates</p>
              </li>
            </ul>
          </div>
        </div>
        <div className="third-splash-content splash-content" ref={thirdSplashRef}>
          <img className="circles-grid" src={`${APP_RESOURCES_URL}splash/circles-grid.png`} alt="circles "/>
          <section className="third light" ref={(e) => createSectionRefs(e, 3)}>
            <div className="third-text">
              <h1>Start from<br/> what you have</h1>
              <p>Convert CAD files into lightweight models for instant prototyping on the web. No CAD? Start from 3D formats.</p>
            </div>
            <button className="primary-btn load-btn">Load</button>
            <ul>
              <li><img src={`${APP_RESOURCES_URL}splash/layer-icon.png`} alt="layers "/></li>
              <li><img src={`${APP_RESOURCES_URL}splash/variations-icon.png`} alt="variations "/></li>
              <li><img src={`${APP_RESOURCES_URL}splash/lighting-icon.png`} alt="lighting "/></li>
            </ul>
            <div className="cursor"><span className="icon-mouse-pointer"></span></div>
            <div className="car-model car-model-cad">
              <img src={`${APP_RESOURCES_URL}splash/car-CAD.png`} alt="CAD model" />
            </div>
            <div className="car-model car-model-mesh">
              <img src={`${APP_RESOURCES_URL}splash/car-wireframe.png`} alt="wireframed car" />
            </div>
            <div className="car-model car-model-shading">
              <img src={`${APP_RESOURCES_URL}splash/car-shading.png`} alt="shaded car" />
            </div>
            <div className="car-model car-model-rendered">
              <img src={`${APP_RESOURCES_URL}splash/car-model.png`} alt="rendered car" />
            </div>
            <div className="car-model car-model-rendered-lighten">
              <img src={`${APP_RESOURCES_URL}splash/car-model-no-shadows.png`} alt="rendered car" />
            </div>
          </section>
          <section className="fourth light" ref={(e) => createSectionRefs(e, 4)}>
            <div className="fourth-text">
              <h1>Explore pre-built tool libraries</h1>
              <p>Plug and play with over 1000 PBR materials, lighting pre-sets, and more ready at your fingertips.</p>
            </div>
            <ul>
              <li><img src={`${APP_RESOURCES_URL}splash/Blue_material.png`} alt="blue "/></li>
              <li className="purple-material"><img src={`${APP_RESOURCES_URL}splash/Purple_material.png`} alt="purple "/></li>
              <li><img src={`${APP_RESOURCES_URL}splash/Pink_material.png`} alt="pink "/></li>
              <li><img src={`${APP_RESOURCES_URL}splash/Coal_material.png`} alt="coal "/></li>
            </ul>
            <div className="car-model car-model-shading mobile-only">
              <img src={`${APP_RESOURCES_URL}splash/car-shading.png`} alt="shaded car" />
            </div>
            <div className="color-picker">
              <img src={`${APP_RESOURCES_URL}splash/Color_selector.png`} alt="color picker "/>
              <div className="color-picker-pointer"></div>
            </div>
          </section>
          <section className="fifth light" ref={(e) => createSectionRefs(e, 5)}>
            <div className="fifth-text">
              <h1>Easy, professional, high-quality</h1>
              <p>Toggle3D eliminates the learning curve while providing the right number of fine-tuning features to make visions come to life.</p>
            </div>
            <div className="slider">
              <div className="slider-bar"><span></span></div>
              <div className="slider-bar"><span></span></div>
            </div>
            <img className="lighting" src={`${APP_RESOURCES_URL}splash/lighting.png`} alt="lighting" />
            <div className="car-model car-model-rendered mobile-only">
              <img src={`${APP_RESOURCES_URL}splash/car-model.png`} alt="rendered car" />
            </div>
          </section>
        </div>
        <div className="fourth-splash-content splash-content" ref={fourthSplashRef}>
          <img className="circles-grid" src={`${APP_RESOURCES_URL}splash/circles-grid.png`} alt="circles "/>
          <div className="car-model car-model-rendered">
            <img src={`${APP_RESOURCES_URL}splash/car-model.png`} alt="rendered car" />
          </div>
          <section className="six light" ref={(e) => createSectionRefs(e, 6)}>
            <div className="six-text">
              <h1>Build in the digital, see in the physical</h1>
              <p>Explore different materials, textures, and colors in real time 3D as you design and engage with WebAR.</p>
            </div>
            <img className="ar-icon" src={`${APP_RESOURCES_URL}splash/AR-icon.png`} alt="ar icon" />
            <img className="street" src={`${APP_RESOURCES_URL}splash/street.png`} alt="street" />
          </section>
          <section className="seven light" ref={(e) => createSectionRefs(e, 7)}>
            <div className="seven-text">
              <h1>No more miscommunication </h1>
              <p>Share a design link with suppliers, manufacturers, or retailers for easy engagement and decision-making.</p>
            </div>
            <img className="bubble bubble-1" src={`${APP_RESOURCES_URL}splash/bubble-1.png`} alt="bubble 1" />
            <img className="bubble bubble-2" src={`${APP_RESOURCES_URL}splash/bubble-2.png`} alt="bubble 2" />
            <span className="publish-btn">Publish</span>
            <div className="car-model car-model-rendered mobile-only">
              <img src={`${APP_RESOURCES_URL}splash/car-model.png`} alt="rendered car" />
            </div>
          </section>
          <section className="eight light" ref={(e) => createSectionRefs(e, 8)}>
            <div className="eight-text">
              <h1>Personalize the online experience</h1>
              <p>Post configurators online for customers to mix and match design options as they engage, explore and buy.</p>
              {!props.embeddedInSignUp && <Link to="/sign-up?r=t3d" className="primary-btn mobile-only">Get Started For Free</Link>}
              {props.embeddedInSignUp && <button className="primary-btn  mobile-only" onClick={handleScrollTop}>Get Started For Free</button>}
            </div>
            <img className="ecomm" src={`${APP_RESOURCES_URL}splash/ecommerce.png`} alt="ecomm website" />
            <div className="car-model car-model-rendered mobile-only">
              <img src={`${APP_RESOURCES_URL}splash/car-model.png`} alt="rendered car" />
            </div>
            {!props.embeddedInSignUp && <Link to="/sign-up?r=t3d" className="primary-btn desktop-only signup-btn">Get Started For Free</Link>}
          </section>
        </div>
      </div>
    )

    return content
  }, [props.embeddedInSignUp])

  useEffect(() => {
    
    setContent(getContent())
    setTimeout(handleSplashAnimations, 250)

    const handleResize = () => {
      console.log('handleResize')
      setContent(null)
      setTimeout(() => {
        setContent(getContent())
        setTimeout(handleSplashAnimations, 250)
      })
    }

    const startTimer = () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }

      timerRef.current = setTimeout(handleResize, 250)
    }

    // Handle resize
    window.addEventListener("resize", startTimer)


    return () => {
      gsap.killTweensOf(".toggle-splash")
      window.removeEventListener("resize", startTimer)
    }

  }, [getContent, handleSplashAnimations])

  return (
    <div className={props.embeddedInSignUp ? "toggle-splash no-animations" : "toggle-splash"}>
      {!props.excludeHeader && <header>
        <ul>
        <li className='header-logo'>
            <div>
              <img src="/images/Toggle3D_Horizontal_Dark.png" alt="Toggle3D" />
            </div>
            <div>
              <span className="header-toggle-public-beta">public beta</span>
            </div>
          </li>
          <li className="investors ml-auto"><a href="https://investors.toggle3d.com/">Investors</a></li>
          <li className="login"><Link to="/login">Log In</Link></li>
          <li className="sign-up"><Link to="/sign-up?r=t3d" className="primary-btn">Get Started</Link></li>
        </ul>
      </header> } 
      {content}
      <footer>
        <div>
          <ul>
            <li><a href="https://www.nextechar.com/ar-solutions-terms" target="_blank" rel="noreferrer">Terms & Conditions</a></li>
            <li><a href="https://www.nextechar.com/nextech-ar-solutions-privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a></li>
          </ul>
          Copyright &copy; 2022 Nextech AR Solutions Inc.
        </div>
      </footer>
    </div>
  )
}

export default SplashPage