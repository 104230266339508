import { API_BASE_URL, APP_RESOURCES_URL, DROP_DUPLICATE_PROPS, T } from "../../../constants"
import { getResolutionLabel } from "../../../utils/ui.util"

const MiscHelperMethods = {
  parts: new Map(),
  storeParts: function(parts) {
    if (this.parts.size > 0) { this.parts = new Map() }
    parts.forEach(part => this.parts.set(part.id, part))
  },

  isConfiguratorOr3dOrVariationsProject: (project) => {
    if (!project || !project.project_type) {
      return false
    }

    return [T.CONFIGURATOR, T._3D_MODEL, T.VARIATIONS].indexOf(project.project_type) > -1
  },

  isConfiguratorOr3dProject: (project) => {
    if (!project || !project.project_type) {
      return false
    }

    return [T.CONFIGURATOR, T._3D_MODEL].indexOf(project.project_type) > -1
  },

  isConfiguratorProject: (project) => {
    if (!project || !project.project_type) {
      return false
    }

    return project.project_type === T.CONFIGURATOR
  },

  is3dProject: (project) => {
    if (!project || !project.project_type) {
      return false
    }

    return project.project_type === T._3D_MODEL
  },

  isVariationsProject: (project) => {
    if (!project || !project.project_type) {
      return false
    }

    return project.project_type === T.VARIATIONS
  },

  isMaterialsProject: (project) => {
    if (!project || !project.project_type) {
      return false
    }

    return project.project_type === T.MATERIAL
  },

  getMaterialById: (mat, shared, token) => {
    return new Promise(async (resolve, reject) => {
      let path
      const headers = {}
      
      try {
        if (token && !shared) {
          headers.authorization = `Bearer ${token}`
        }
        
        if (mat.personal) {
          path = shared ? `public/collections/${mat.id}` : `collections/${mat.id}`
        }
        else {
          path = shared ? `public/materials/${mat.id}` : `materials/${mat.id}`
        }

        const res = await fetch(`${API_BASE_URL}${path}`, {
          method: "GET",
          headers
        }).then((r) => r.json())

        resolve({...res.data, external: true})
      }
      catch (e) {
        console.log('reject!')
        reject(e)
      }
    })
  },

  getProjectMaterialForPart: (materials, matId, apiCache) => {
    return new Promise((resolve, reject) => {
      if (!materials || materials.length < 1) {
        return reject("No materials found")
      }

      let match = materials.find((m) => m.id === matId)

      if (!match) {
        // Check cache
        match = apiCache[matId]
      }
      
      if (!match) {
        reject("Matching material not found")
      }
      else {
        resolve(match)
      }
    })
  },

  getProjectResolutions: (projectId) => {
    return new Promise(async (resolve) => {
      try {
        
        const availableResolutions = []
        const {data} = await fetch(`${API_BASE_URL}projects/public/${projectId}/resolutions`).then((r) => r.json())
        const {resolutions, pending_resolutions, active_materials}  = data

        resolutions.forEach((r) => {
          let label = getResolutionLabel(r)
          const isPending = pending_resolutions.find((p) => p === r)

          if (!availableResolutions.find((r) => r.id === label)) {

            if (isPending) {
              label += ' (preparing)'
            }

            availableResolutions.push({label, id: label, r, disabled: isPending})
          }
        })

        pending_resolutions.forEach((r) => {
          let label = getResolutionLabel(r) + ' (preparing)'

          if (!availableResolutions.find((r) => r.id === label)) {
            availableResolutions.push({label, id: label, r, disabled: true})
          }
        })

        resolve({resolutions: availableResolutions.sort((a, b) => a.r > b.r ? 1 : -1), materials: active_materials})
      }
      catch (e) {
        // Fail silently
        console.error("Error fetching project material resolutions: ", e)
        resolve({resolutions: [], materials: []})
      }
    })
  },

  hasInternalRole: (role) => {
    return role && ['internal', 'admin'].indexOf(role) > -1
  },

  convertObjectToArray: (obj) => {
    if (typeof obj !== "object") { return [] }

    const arr = []
    Object.keys(obj).forEach((k) => {
      arr.push(obj[k])
    })

    return arr
  },

  getThumbnailForMaterial: (mat) => {
    const thumb = mat.personal ? `${API_BASE_URL}public/collections/${mat.id}/thumb` : `${APP_RESOURCES_URL}material-thumbs/${mat.id}/thumb.png`
    return mat.thumbnail ? mat.thumbnail : thumb
  },

  copyAndSanitizeDuplicateProject: (project) => {
    const copy = JSON.parse(JSON.stringify(project))

    // Update name
    copy.name = `${copy.name} - Copy`

    // Delete id + model details + created/last updated + sharing, etc + sanitize any json attributes
    for (let key in copy) {
      if (DROP_DUPLICATE_PROPS.indexOf(key) > -1) {
        delete copy[key]
        continue
      }
      // null is an object and it makes that a string which causes an error in backend
      if (copy[key] !== null && typeof copy[key] === 'object' && copy[key]) {
        copy[key] = JSON.stringify(copy[key])
      }
    }

    return copy
  }
}

export default MiscHelperMethods