import { T } from "../../constants"
import SimpleAlertModalComponent from "../alerts/simple-alert-modal.component"
import UpgradeToProModalComponent from "./upgrade-to-pro-modal.component"

const NoSpaceAvailableModalComponent = (props) => {
  const { plan } = props
  const modalContent = (
    <>
      <p class="content-text">You do not have enough space to make uploads. Upgrade to PRO?</p>
    </>
  )
  return (
    plan && plan.type === T.FREE ?
    <UpgradeToProModalComponent
      close={() => props.close()}
      modalContent={modalContent}
    />
      :
      <SimpleAlertModalComponent
        title="No Space Available"
        subTitle="Oh oh, looks like you have used up all your storage. Please delete files from your uploads and materials library to make more room to save new files."
        buttonTitle="Okay"
        close={() => props.close()} />
  )
}

export default NoSpaceAvailableModalComponent