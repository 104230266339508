import HeaderGroupComponent from "./components/header-group/header-group.component"

const ToggleUIPage = () => {
  return (
    <>
      <HeaderGroupComponent header="Toggle UI Example Page" level="1" icon="icon-image" />
      <div className="toggle-content-group">
        <h4>Toggle UI Content Blocks</h4>
        <div className="toggle-content-blocks">
          <div className="toggle-content-block">
            Content block #1
          </div>
          <div className="toggle-content-block">
            Content block #2
          </div>
          <div className="toggle-content-block">
            Content block #3
          </div>
          <div className="toggle-content-block">
            Content block #4
          </div>
          <div className="toggle-content-block">
            Content block #5
          </div>
          <div className="toggle-content-block">
            Content block #6
          </div>
          <div className="toggle-content-block">
            Content block #7
          </div>
          <div className="toggle-content-block">
            Content block #8
          </div>
        </div>
      </div>
      <div className="toggle-content-group">
        <h4>Toggle UI Content List</h4>
        <ul className="toggle-content-list">
          <li><div>Item 1</div><div>Item 1 info</div></li>
          <li><div>Item 2</div><div>Item 2 info</div></li>
          <li><div>Item 3</div><div>Item 3 info</div></li>
        </ul>
      </div>
      <div className="toggle-content-group">
        <h4>Toggle UI Headers</h4>
        <h1>This is a H1</h1>
        <h2>This is a H2</h2>
        <h3>This is a H3</h3>
        <h4>This is a H4</h4>
        <h5>This is a H5</h5>
        <h6>This is a H6</h6>
        <HeaderGroupComponent header="This is a H1 group w/ secondary button" level="1" action={{label: "View All", path: "/ui", className: "toggle-secondary-btn"}} />
        <HeaderGroupComponent header="This is a H2 group w/ primary button" level="2" action={{label: "View All", path: "/ui", className: "toggle-primary-btn"}} />
        <HeaderGroupComponent header="This is a H3 group w/ disabled primary button" level="3" action={{label: "View All", path: "/ui", className: "toggle-primary-btn", disabled: true}} />
        <HeaderGroupComponent header="This is a H4 group w/ disabled secondary button" level="4" action={{label: "View All", path: "/ui", className: "toggle-secondary-btn", disabled: true}} />
        <HeaderGroupComponent header="This is a H5 group w/ icon" level="5" icon="icon-codepen" />
        <HeaderGroupComponent header="This is a H6 group w/ icon" level="6" icon="icon-archive" />
      </div>
      <div className="toggle-content-group">
        <h4>Toggle UI Colors</h4>
        <h6>Standard Text Color</h6>
        <h6 className="toggle-color-light-text">Light Text Color</h6>
        <h6 className="toggle-color-dark-text">Dark Text Color</h6>
        <h6 className="toggle-color-brand-primary">Brand Primary Color</h6>
        <h6 className="toggle-color-brand-secondary">Brand Secondary Color</h6>
        <h6 className="toggle-color-brand-tertiary">Brand Tertiary Color</h6>
        <h6 className="toggle-color-brand-pink">Brand Pink Color</h6>
        <h6 className="toggle-color-brand-light">Brand Light Color</h6>
      </div>
      <div className="toggle-content-group">
        <h4>Toggle UI Labels</h4>
        <div className="toggle-label">In Progress</div>
        <div className="toggle-label green">Completed</div>
        <div className="toggle-label red">Error</div>
      </div>
    </>
  )
}

export default ToggleUIPage