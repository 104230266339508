import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import ContextMenuComponent from "../context-menu/context-menu.component"
import { logout } from "../../redux/auth.redux.slice"
import "./sidebar.component.css"
import { Link } from "react-router-dom"
import { toggleSidebar } from "../../redux/ui.redux.slice"
import { T } from "../../constants"
import ModalComponent from "../modal/modal.component"
import HelpChatWindow from "../forms/help-chat-window"
import UpgradeCardComponent from "../upgrade-card/upgrade-card.component"

const SidebarComponent = (props) => {

  const dispatch = useDispatch()
  const { user, role, isVerified, plan } = useSelector((state) => state.auth)
  const [showUserMenu, setShowUserMenu] = useState(false)
  const {collapsedSidebar, hasUnreadExport} = useSelector((state) => state.ui)
  const [willShowUpgrade, setWillShowUpgrade] = useState(false)
  const [showAlert, setShowAlert] = useState(false)
  const [showChatWindow, setShowChatWindow] = useState(false)

  const toggleShowChatWindow = () => {
    if (showChatWindow === true) {
      setShowChatWindow(false)
      // setShowHelpButton(true)
      return
    }
    loadChatWindow()
  }

  const loadChatWindow = () => {
    setShowChatWindow(true)
    // setShowHelpButton(false)
    window.hbspt.forms.create({
      region: "na1",
      portalId: "4391972",
      formId: "811e1bf4-db9c-4fee-8ddc-b7e79c75df74",
      target: "#help-chat-form",
      onBeforeFormInit: (ctx) => {
      },
      onFormReady: (form) => {
        if (form.hasChildNodes()) {
          // loop and remove div that are having empty class names
          // for (var i = 0; i < form.children.length; i++) {
          //   let child = form.children[i];
          //   if (!child.className && child.nodeName === 'DIV') {
          //     form.removeChild(child)
          //   }
          // } // loop ends
          let nameField = form.querySelector("input[name=name]")
          if (nameField) {
            nameField.value = user.name
            nameField.focus()
          }
          let emailField = form.querySelector("input[name=email]")
          if (emailField) {
            emailField.value = user.email
            emailField.focus()
          }
          let descriptionField = form.querySelector("textarea[name=message]")
          if (descriptionField) {
            descriptionField.focus()
          }
        }
      }
    })
  }

  const handleShowUserMenu = (e) => {
    e.stopPropagation()

    setShowUserMenu(
      {position: {
        left: e.clientX + 'px', top: e.clientY + 'px'
      },
      items: [
        {id: 'logOut', itemClassName: "", label: <>Log out</>, action: handleLogout}
      ]
    })
  }

  const handleLogout = () => {
    dispatch(logout())
  }

  const handleToggleCollapsed = () => {
    dispatch(toggleSidebar())
  }

  const getSidebarClasses = () => {
    const list = ['toggle-sidebar']
    if (collapsedSidebar) {
      list.push('collapsed')
    }
    if (!user || !isVerified) {
      list.push('threedy-skeleton')
    }

    return list.join(" ")
  }

  useEffect(() => {
    setWillShowUpgrade(!collapsedSidebar && plan && plan.type === T.FREE)
  }, [collapsedSidebar, plan])

  if (props.location.includes('editor')) {
    return 
  }

  return (
    <div className={getSidebarClasses()}>
      <div className="sidebar-upper-content">
        <div className="sidebar-menu-header">
          <h6>Menu</h6>
          <span onClick={handleToggleCollapsed}><span className="icon-contract-menu"></span></span>
        </div>
        <ul className="toggle-sidebar-menu">
          <li className={props.location === '/dashboard' ? 'selected' : ''}><Link to="/dashboard"><span className="icon-home"></span> <span className="sidebar-text-label">Home</span></Link></li>
          <li className={props.location === '/projects' ? 'selected' : ''}><Link to="/projects"><span className="icon-dashboard"></span> <span className="sidebar-text-label">Projects</span></Link></li>
          <li className={props.location === '/tutorials' ? 'selected' : ''}><Link to="/tutorials"><span className="icon-layers"></span> <span className="sidebar-text-label">Tutorials</span></Link></li>
          {/* <li className={props.location.includes('settings') ? 'selected' : ''}><Link to="/settings/profile"><span className="icon-settings"></span> <span className="sidebar-text-label">Settings</span></Link></li> */}
          { role === 'admin' && <li className={props.location === '/admin' ? 'selected' : ''}><Link to="/admin"><span className="icon-users"></span> <span className="sidebar-text-label">Admin</span></Link></li> }
        </ul>
      </div>
      <div className="sidebar-lower-content">
        {user && isVerified && (
          <>
            {willShowUpgrade && <UpgradeCardComponent />} 
          </>
        )}
        <ul className="toggle-sidebar-lower-menu">
          <li onClick={toggleShowChatWindow} className="menu-item-without-link"><span className="icon-help"></span> <span className="sidebar-text-label">Help & Feedback</span></li>
        </ul>
      </div>
      {showChatWindow && <HelpChatWindow hideChatWindow={toggleShowChatWindow} />}
    </div>
  )
}

export default SidebarComponent