import { useEffect } from "react"

const useUnload = (isSaving) => {

  useEffect(() => {
    const handleUnload = (e) => {
      if (isSaving) {
        e.returnValue = "saving"
        return "saving"
      }
    }

    window.addEventListener("beforeunload", handleUnload)
    return () => window.removeEventListener("beforeunload", handleUnload)
  }, [isSaving])
}

export default useUnload