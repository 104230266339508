import { useEffect, useState, useCallback, useRef } from "react"
import { Engine, PointerEventTypes, ActionManager, ExecuteCodeAction, CreateScreenshotAsync, Color3, HighlightLayer, Vector3, MeshUVSpaceRenderer } from "@babylonjs/core"
import '@babylonjs/loaders'
import '@babylonjs/core/Debug/debugLayer'
import '@babylonjs/inspector'
import "./workspace.page.css"
import SelectionMenuComponent from "../../components/selection-menu/selection-menu.component"
import SpinnerComponent from "../../components/spinner/spinner.component"
import { useParams } from "react-router-dom"
import { useColorTransferExistingMaterialMutation, useEditProjectImageMutation, useEditProjectMutation, useEditUserMutation, useLazyGetPersonalMaterialByIdQuery, useLazyGetProjectByAritizeJobIdQuery, useLazyGetProjectByIdQuery } from "../../redux/api.redux.slice"
import { useDispatch, useSelector } from "react-redux"
import { 
  hideProjectSharingModal, setActiveTour, setProjectSaving, setRunTour, setSelectedProject,
  setSelectedProjectMaterials, setShowViewerHelp, setToast, setFinishedTours, setTourStepIndex, addToPersonalCollection, setLastUsedMaterials
} from "../../redux/ui.redux.slice"
import PartViewerComponent from "../../components/part-viewer/part-viewer.component"
import ConfiguratorViewerComponent from "../../components/configurator-viewer/configurator-viewer.component"
import ConfiguratorBuilderComponent from "../../components/configurator-builder/configurator-builder.component"
import { ALL_TOURS, DEFAULT_GLTF_MATERIAL, DEFAULT_SPHERE_MESH_ID, DEFAULT_UV_PROPERTIES, EVENTS, HISTORY_EVENT_TYPES, JOYRIDE_LOCALE, JOYRIDE_STEPS, JOYRIDE_STYLE, JOYRIDE_TOOLTIP_STYLES, T } from "../../constants"
import moment from "moment"
import { createConfiguratorScene, createBaseScene, createNewMaterial, updateMaterialMeta, createMaterialScene, prepareCamera, resetTheProject, getProjectIcon, createLightingEnvironment, awaitToJs, moveActiveCamera, addDecalToMesh, disposeMaterial } from "../../utils/ui.util"
import MaterialImportModalComponent from "../../components/material-import-modal/material-import-modal.component"
import ViewerHelpModalComponent from "../../components/viewer-help-modal/viewer-help-modal.component"
import MaterialBuilderComponent from "../../components/material-builder/material-builder.component"
import MaterialViewerComponent from "../../components/material-viewer/material-viewer.component"
import PublicConfiguratorOptionsComponent from "../../components/public-configurator-options/public-configurator-options.component"
import { createdMaterials, lighting, materialBuilder, replay } from "../../assets"
import ViewerShareModalComponent from "../../components/viewer-share-modal/viewer-share-modal.component"
import ViewerARModalComponent from "../../components/viewer-ar-modal/viewer-ar-modal.component"
import ARLoaderComponent from "../../components/ar-loader/ar-loader.component"
import ModelProcessingComponent from "../../components/model-processing/model-processing.component"
import ReferencePhotoComponent from "../../components/reference-photo/reference-photo.component"
import ReactJoyride, { ACTIONS, STATUS, EVENTS as JOYRIDE_EVENTS } from "react-joyride"
import Session from "../../utils/session.util"
import { login } from "../../redux/auth.redux.slice"
import MiscHelperMethods from "./helper-methods/misc.helpers"
import PartEditingLabelComponent from "../../components/part-editing-label/part-editing-label.component"
import { useTour } from '@reactour/tour'
import { addToHistory } from "../../redux/history.redux.slice"
import useWorkspaceHistory from "./hooks/useWorkspaceHistory"
import useUnload from "./hooks/useUnload"
import VariationsComponent from "../../components/variations/variations.component"
import AddVariationModalComponent from "../../components/add-variation-modal/add-variation-modal.component"
import useSceneResizer from "./hooks/useSceneResizer"
import useKeyBindings from "./hooks/useKeyBindings"
import useCreateExport from "./hooks/useCreateExport"
import useLastUsedMaterials from "../../hooks/useLastUsedMaterials"
import NoSpaceAvailableModalComponent from "../../components/upgrade-to-pro/no-space-available.component"
import InitHelperMethods from "./helper-methods/init.helpers"
import useHighResMaterialFetching from "./hooks/useHighResMaterialFetching"
import PreviewResolutionDropdownComponent from "../../components/preview-resolution-dropdown/preview-resolution-dropdown.component"
import ToolsMenuComponent from "./components/tools-menu.component"
import LightingPanelComponent from "../../components/lighting-panel/lighting-panel.component"
import ElementConfigurationComponent from "./components/element-configuration/element-configuration.component"
import useDecalDropper from "../../hooks/useDecalDropper"
import useDecalMover from "../../hooks/useDecalMover"

const WorkspacePage = (props) => {

  const dispatch = useDispatch()
  const { id } = useParams()
  const [getProject] = useLazyGetProjectByIdQuery()
  const [updateProject] = useEditProjectMutation()
  const [getPersonalMaterialQuery] = useLazyGetPersonalMaterialByIdQuery()
  const [getAritizeProjectQuery] = useLazyGetProjectByAritizeJobIdQuery()
  const [
    access_token, user, selectedProjectSync, showViewerHelpSync, showViewerShareSync, showViewerArSync,
    finishedTours, activeTour, runTour, tourStepIndex, projectSaving, lastUsedMaterials
  ] = useSelector((state) => [
    state.auth.access_token, state.auth.user, state.ui.selectedProject, state.ui.showViewerHelp, state.ui.showViewerShare, state.ui.showViewerAR,
    state.ui.finishedTours, state.ui.activeTour, state.ui.runTour, state.ui.tourStepIndex, state.ui.projectSaving, state.ui.lastUsedMaterials
  ])
  const [plan] = useSelector((state) => [state.auth.plan])
  const [updateUserRequest] = useEditUserMutation()
  const selectedOptions = useRef({})
  const projectRef = useRef(null)
  const canvasRef = useRef(null)
  const engineRef = useRef(null)
  const cameraRef = useRef(null)
  const sceneRef = useRef(null)
  const lastZoomRef = useRef(6)
  const hoverPartRef = useRef(null)
  const hlRef = useRef(null)
  const isHoldingShiftRef = useRef(false)
  const isHoldingCmdRef = useRef(false)
  const isHoldingCtrlRef = useRef(false)
  const defaultMaterialsRef = useRef({})
  const initialMaterialsRef = useRef({})
  const createdMaterialsRef = useRef({})
  const apiCacheRef = useRef({})
  const zoomMeta = useRef({})
  const keysEnabled = useRef(true)
  const hlClones = useRef(false)
  const lightRefs = useRef(false)
  const processingCheckTimer = useRef(false)
  const isRightClick = useRef(false)
  const lastMeshClick = useRef(false)
  const lastClickOrigin = useRef(false)
  const decalsRef = useRef([])
  const colorTransferQueueRef = useRef([])
  const customizedMaterialsRef = useRef({})
  const [loading, setLoading] = useState(true)
  const [zoom, setZoom] = useState({level: 100, caller: 'parent'})
  const [parts, setParts] = useState([])
  const [showListMenu, setShowListMenu] = useState(false)
  const [selectedParts, setSelectedParts] = useState([])
  const [hoveredPart, setHoveredPart] = useState(false)
  const [selectionMenu, setSelectionMenu] = useState(false)
  const [specialAction, setSpecialAction] = useState(false)
  const [invisible, setInvisible] = useState([])
  const [configurations, setConfigurations] = useState([])
  const [selectedConfiguration, setSelectedConfiguration] = useState(false)
  const [selectedPartForEditMaterial, setSelectedPartForEditMaterial] = useState(false)
  const [didError, setDidError] = useState(false)
  const [showNoSpaceAvailableModal, setShowNoSpaceAvailableModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [stillProcessing, setStillProcessing] = useState(false)
  const [lastProcessingCheck, setLastProcessingCheck] = useState(false)
  const [showMaterialImport, setShowMaterialImport] = useState(false)
  const [cameraReady, setCameraReady] = useState(false)
  const [updateProjectImage] = useEditProjectImageMutation()
  const [isZoomInEnabled, setIsZoomInEnabled] = useState(true)
  const [isZoomOutEnabled, setIsZoomOutEnabled] = useState(true)
  const [showArLoader, setShowArLoader] = useState(false)
  const [isPreviewing, setIsPreviewing] = useState(false)
  const [decalPicker, setDecalPicker] = useState(false)
  const [showCannotRemovePartModal, setShowCannotRemovePartModal] = useState(false)
  const [askToRestartSampleTour, setAskToRestartSampleTour] = useState(false)
  const [showNewVariation, setShowNewVariation] = useState(false)
  const [selectedVariationId, setSelectedVariationId] = useState(false)
  const [disableZoom, setDisableZoom] = useState(false)
  const [doColorTransferInCollection] = useColorTransferExistingMaterialMutation()
  const partViewerRef = useRef()
  const copyRef = useRef()
  const { setIsOpen } = useTour()
  const [newColorMaterial, setNewColorMaterial] = useState(false)
  const [addAndUpdateLastUsedMaterial, updateLastUsedMaterialMeta] = useLastUsedMaterials()
  const { setEngineRef } = useSceneResizer()

  // Show AR blocker if needed
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get("loadAR")) {
      setShowArLoader(true)
    }
  }, [])

  // Clear project sharing modal
  useEffect(() => {
    return () => dispatch(hideProjectSharingModal())
  }, [dispatch])

  useEffect(() => {
    if (!user) { return }
    
    const isUserOnboarded = user['https://thdy/user_md'] && (user['https://thdy/user_md']?.onboarded || false)
    const isTour3Finished = isUserOnboarded === false && (user['https://thdy/user_md'].hasOwnProperty('finishedTours') ? user['https://thdy/user_md'].finishedTours.includes('TOUR3') : false)
    const canStartTour3 = isUserOnboarded === false && isTour3Finished === false && !loading && !finishedTours.includes('TOUR3') && activeTour === false && projectRef.current?.project_type === T.MATERIAL
    if (canStartTour3) {
      dispatch(setActiveTour('TOUR3')) // Make Tour3 as active
      dispatch(setRunTour(true)) // Start Tour
    }
  }, [user, loading, finishedTours, activeTour, dispatch])

  useEffect(() => {
    if (!user) { return }
    const isTour2Finished = user['https://thdy/user_md'].hasOwnProperty('finishedTours') ? user['https://thdy/user_md'].finishedTours.includes('TOUR2') : false
    const canRestart = isTour2Finished && !loading && projectRef.current?.project_type === T._3D_MODEL && projectRef.current?.is_sample === true
    if (canRestart) {
      setAskToRestartSampleTour(true)
    }
    const canStartTour2 = isTour2Finished === false && !loading && activeTour === false && projectRef.current?.project_type === T._3D_MODEL && projectRef.current?.is_sample === true
    if (canStartTour2) {
      setIsOpen(true)
    }
  }, [activeTour, setIsOpen, loading, user, setAskToRestartSampleTour])
  

  const radiusToZoom = useCallback((val) => {
    const lerp = (x, y, a) => x * (1 - a) + y * a;
    const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
    const invlerp = (x, y, a) => clamp((a - x) / (y - x));

    const x1 = zoomMeta.current[300]
    const y1 = zoomMeta.current[10]
    const x2  = 300
    const y2 = 10
    const output = Math.round(lerp(x2, y2, invlerp(x1, y1, val)))
    return output
  }, [])

  const zoomToRadius = useCallback((val) => {
    const lerp = (x, y, a) => x * (1 - a) + y * a;
    const clamp = (a, min = 0, max = 1) => Math.min(max, Math.max(min, a));
    const invlerp = (x, y, a) => clamp((a - x) / (y - x));

    if (zoomMeta.current[val]) {
      return zoomMeta.current[val]
    }

    const x2 = zoomMeta.current[300]
    const y2 = zoomMeta.current[10]
    const x1  = 300
    const y1 = 10
    const output = Math.round(lerp(x2, y2, invlerp(x1, y1, val)))
    return output
  }, [])

  const handleZoomUpdate = useCallback((radius) => {
    const sanitizedZoom = radiusToZoom(radius)
    setZoom((z) => { if (sanitizedZoom === z.level) { return z; } return {level: sanitizedZoom, caller: 'scene'}})
  }, [radiusToZoom])

  const handleToggleListMenu = useCallback((type) => {
    console.log("handleToggleListMenu")
    setShowListMenu((t) => { return t === type ? false : type })

    // Close selection menu as well + selected parts + configurator builder
    // setSelectionMenu(false)
    // setSelectedParts([])
    // closeConfiguratorBuilder()
  }, [])

  const handleZoom = (type) => {
    let roundedZoom = cameraRef.current.radius
    const ZOOM_EVERY = 1 // increase radius by 1%
    const zoomSize = (cameraRef.current.lowerRadiusLimit + cameraRef.current.upperRadiusLimit) * (ZOOM_EVERY / 100)

    setIsZoomOutEnabled(true)
    setIsZoomInEnabled(true)
    switch (type) {
      case 'increment':
        if (roundedZoom <= zoomMeta.current[300]) {
          setIsZoomInEnabled(false)
          return
        }
        roundedZoom -= zoomSize
        break
      case 'decrement':
        if (roundedZoom >= zoomMeta.current[10]) {
          setIsZoomOutEnabled(false)
          return;
        }
        roundedZoom += zoomSize
        break
      default:
        break
    }
    if (lastZoomRef.current !== roundedZoom) {
      lastZoomRef.current = roundedZoom
      cameraRef.current.radius = roundedZoom
      handleZoomUpdate(roundedZoom)
    }
  }
  // Zoom handler
  useEffect(() => {
    if (cameraRef.current && canvasRef.current && zoom.caller === 'parent') {
      const newRadius = zoomToRadius(zoom.level)
      // console.log(newRadius)
      cameraRef.current.radius = newRadius
    }
  }, [zoom, zoomToRadius])

  // Listen for key handler toggle + debug
  useEffect(() => {

    const handleKeyToggle = (e) => {
      keysEnabled.current = e.detail.enabled
    }

    const handleShowDebug = (e) => {

      if (sceneRef.current) {
        if (e.detail && e.detail.enabled) {
          sceneRef.current.debugLayer.show()
        }
        else {
          sceneRef.current.debugLayer.hide()
        }
      }
      else {
        console.error("Scene ref not available")
      }
    }

    document.addEventListener(EVENTS.TOGGLE_KEY_HANDLER, handleKeyToggle)
    document.addEventListener(EVENTS.SHOW_DEBUG, handleShowDebug)

    return () => {
      document.removeEventListener(EVENTS.TOGGLE_KEY_HANDLER, handleKeyToggle)
      document.removeEventListener(EVENTS.SHOW_DEBUG, handleShowDebug)
    }

  }, [])

  const handleGenerateScreenshot = useCallback(() => {
    return new Promise(async (resolve, reject) => {
      try {
        const screenshot = await CreateScreenshotAsync(engineRef.current, sceneRef.current.activeCamera, 1000, "image/jpeg")

        // Convert b64 to file
        const file = await fetch(screenshot).then((res) => res.arrayBuffer()).then((buf) => new File([buf], "thumb.jpg", {type: "image/jpeg"}))
        const data = new FormData()
        data.set("image", file)

        // Upload
        await updateProjectImage({projectId: projectRef.current.id, body: data}).unwrap()
        resolve()
      }
      catch (e) {
        console.error("Error generating screenshot: ", e)
        
        // We will fail silently as not having a thumb is not the end of the world
        resolve()
      }
    })
  }, [updateProjectImage])

  const applyFirstMaterial = useCallback(async (project) => {
    const firstMaterialID = project.material_ids && project.material_ids[0]
    if (!firstMaterialID) { return }
    
    try {
      const material = await getPersonalMaterialQuery(firstMaterialID).unwrap()
      const sphere = sceneRef.current.meshes.find((m) => m.id === DEFAULT_SPHERE_MESH_ID)
      const raw = await createNewMaterial(sceneRef.current, createdMaterialsRef.current, material, {})
      sphere.material = raw
    } 
    catch (e) {
      // Fail silently
      console.error("Error pre-selecting first material:", e)
    } 
  }, [getPersonalMaterialQuery])
  
  const handleUpdateProject = useCallback((params, skipScreenshot, caller = "workspace") => {

    return new Promise(async resolve => {
      // Update
      try {

        // Update cloud icon
        dispatch(setProjectSaving({saving: true, caller}))

        // If the project doesn't have an image, let's generate one
        if (!projectRef.current.udf_image && !skipScreenshot) {
          // console.log("Generate screenshot")
          await handleGenerateScreenshot()
        }

        const json = {}
        Object.keys(params).forEach((pk) => {
          json[pk] = JSON.stringify(params[pk])
        })

        await updateProject({projectId: projectRef.current.id, body: json}).unwrap()
        const updatedChangeCount = parseInt(projectRef.current.change_count) + 1
        const updates = {...params, change_count: updatedChangeCount, updated_ts: moment().valueOf()}
        dispatch(setSelectedProject({...projectRef.current, ...updates}))
        projectRef.current = {...projectRef.current, ...updates}

        dispatch(setProjectSaving({saving: false, caller}))
        resolve()
      }
      catch (e) {
        dispatch(setToast({message: "Uh oh. We had an issue updating your project, please try again.", isError: true}))
        resolve()
      }
    })
  }, [dispatch, handleGenerateScreenshot, updateProject])

  const getPartOrGroup = useCallback((partId, returnPartOnly = true) => {

    const part = MiscHelperMethods.parts.get(partId)
    if (part) {
      return part
    }

    // Scan groups
    const groups = parts.filter((p) => p.type === 'group')
    if (groups.length > 0) {
      let partGroup = false
      groups.forEach((g) => {
        let found = g.parts.find((p) => p.id === partId)
        if (!partGroup && found) {
          partGroup = returnPartOnly ? found : g
        }
      })

      return partGroup
    }

    return false
  }, [parts])

  // Handle disable zoom event
  useEffect(() => {
    const handleDisableZoom = (e) => {
      setDisableZoom(e.detail)
    }

    document.addEventListener(EVENTS.DISABLE_ZOOM, handleDisableZoom)
    return () => document.removeEventListener(EVENTS.DISABLE_ZOOM, handleDisableZoom)

  }, [])

  // Disable zoom 
  useEffect(() => {
    if (!cameraRef.current) {
      return
    }

    if (disableZoom) {
      cameraRef.current.upperRadiusLimit = cameraRef.current.radius
      cameraRef.current.lowerRadiusLimit = cameraRef.current.radius
    }
    else {
      cameraRef.current.upperRadiusLimit = zoomMeta.current['10']
      cameraRef.current.lowerRadiusLimit = zoomMeta.current['300']
    }

  }, [disableZoom])

  // Timer check
  const handleRefreshProject = useCallback(async () => {
    try {

      let project
      if (projectRef.current.external_meta && projectRef.current.external_meta.model_id) {
        const arRes = await getAritizeProjectQuery(projectRef.current.external_meta.model_id).unwrap()
        project = arRes.project
      }
      else {
        project = await getProject(id).unwrap()
      }

      setLastProcessingCheck(moment().valueOf())
      projectRef.current = project

      if (project.model_status !== 'ready' && project.model_status !== 'readyForMaterialImport')  {
        // Refresh in 10s
        processingCheckTimer.current = window.setTimeout(handleRefreshProject, 10000)
        console.log("Re-checking in 10s")
      }
      else {
        console.log("Refreshing scene...")

        // Update scene
        document.dispatchEvent(new CustomEvent(EVENTS.REFRESH_SCENE))
      }
    }
    catch (e) {
      console.error("Error fetching project: ", e)
    }
  }, [getProject, getAritizeProjectQuery, id])

  const handleCheckForPreBaked = useCallback((parts) => {
    console.log('handleCheckForPreBaked')

    return new Promise(async resolve => {

      // Check if all parts share one single material
      let lastMatId
      let preBaked = true
      for (let i = 0; i < parts.length; i++) {
        const p = parts[i]
        if (p.type === 'part' && p.material) {
          if (!lastMatId) {
            lastMatId = p.material.id
          }

          if (lastMatId !== p.material.id) {
            preBaked = false
            break
          }
        }
        else if (p.parts) {
          for (let j = 0; j < p.parts.length; j++) {
            const sp = p.parts[j]
            if (sp.type === 'part' && sp.material) {
              if (!lastMatId) {
                lastMatId = sp.material.id
              }

              if (lastMatId !== sp.material.id) {
                preBaked = false
                break
              }
            }
          }
        }
      }

      if (preBaked && lastMatId.indexOf(DEFAULT_GLTF_MATERIAL) >= 0) {
        preBaked = false
      }

      // Update
      const updates = {meta: {...projectRef.current.meta, didCheckForPreBake: true, preBaked}}
     
      // Update project
      try {
        await handleUpdateProject(updates, true)
      }
      catch (e) {
        console.error("Error handleCheckForPreBaked: ", e)

        // Fail silently, we'll retry the next time the project loads
      }

      resolve()
    })

  }, [handleUpdateProject])

  const handleUpdateLighting = async (env) => {
    lightRefs.current = await createLightingEnvironment(sceneRef.current, lightRefs.current, env)
    const newMeta = projectRef.current.meta ? {...projectRef.current.meta, lighting: env} : {lighting: env}
    projectRef.current = {...projectRef.current, meta: newMeta}
  }

  

  const createScene = useCallback((engine) => {
    console.log('create scene')
    return new Promise(async (resolve, reject) => {
      
      const scene = createBaseScene(engine)

      // Fetch project
      let project
      try {
        if (projectRef.current && props.project && props.isAritize) {
          project = projectRef.current
        }
        else if (props.project) {
          project = props.project
        }
        else {
          project = await getProject(id).unwrap()
        }

        console.log("project: ", project)
        projectRef.current = project

        if (project.project_type === 'material') {
          applyFirstMaterial(project)
        }

        dispatch(setSelectedProject(project))
        dispatch(setSelectedProjectMaterials(false))
        dispatch(setLastUsedMaterials(projectRef.current.last_used_materials))
      }
      catch (e) {
        console.error("Error fetching project: ", e)
        return reject(e)
      }

      try {
        const returnParams = {
          project_type: project.project_type,
          scene
        }

        // Create project specific scene
        if (MiscHelperMethods.isConfiguratorOr3dOrVariationsProject(project) && project.model_id) {
          const { partsToUse, updateProjectStatus, sampledMaterialsToUse, createdMaterialsToUse, configurationsToUse, stillProcessingToUse, doingMaterialImport, defaultMaterialsToUse, hlClonesToUse } = await createConfiguratorScene(access_token, scene, project, props.publicView)
          setParts(partsToUse)
          setConfigurations(configurationsToUse)
          setStillProcessing(stillProcessingToUse)
          defaultMaterialsRef.current = defaultMaterialsToUse
          initialMaterialsRef.current = defaultMaterialsToUse
          hlClones.current = hlClonesToUse
          returnParams.partsToUse = partsToUse
          returnParams.configurationsToUse = configurationsToUse
          returnParams.sampledMaterialsToUse = sampledMaterialsToUse
          returnParams.createdMaterialsToUse = createdMaterialsToUse

          if (stillProcessingToUse) {
            setLastProcessingCheck(moment().valueOf())

            if (!doingMaterialImport) {
              // Begin our autocheck timer
              processingCheckTimer.current = window.setTimeout(handleRefreshProject, 10000)
            }
          }
          else if (updateProjectStatus) {
            console.log("Updating project status...")
            dispatch(setSelectedProject({...projectRef.current, model_status: "ready"}))
            projectRef.current = {...projectRef.current, model_status: "ready"}
          }
        }
        else if (project.project_type === 'material') {
          
          // Create our scene
          await createMaterialScene(scene)
          
          if (project.material_ids === null && !props.publicView) {

            // Open material builder pane by default
            handleToggleListMenu('material')
          }
        }

        // Set camera
        const {zoom, camera} = prepareCamera(scene)
        cameraRef.current = camera
        MiscHelperMethods.initialCameraSettings = camera

        if (zoom) {
          zoomMeta.current = zoom
        }

        // Done
        resolve(returnParams)
      }
      catch (e) {
        console.error("Error setting up project type specific scene: ", e)
        return reject(e)
      }
    })
  }, [dispatch, getProject, id,  props.project, handleRefreshProject, handleToggleListMenu, props.publicView, props.isAritize, access_token, applyFirstMaterial])


  const createHighLightLayer = async (scene) => {
    return new Promise((resolve, reject) => {
      try {
        hlRef.current = new HighlightLayer("hl1", scene, {
          isStroke: true, mainTextureRatio: 1.5,
          blurHorizontalSize: 1, blurVerticalSize: 1
        })
        hlRef.current.innerGlow = true
        hlRef.current.outerGlow = false
        resolve("Highlight applied successfully")
      } catch (e) {
        reject(e)
      }
    })
  }
  // Clear timer
  useEffect(() => {
    return () => {
      if (processingCheckTimer.current) {
        window.clearTimeout(processingCheckTimer.current)
        processingCheckTimer.current = null
      }
    }
  }, [])

  const handleSetMaterial = useCallback(async (item, material, providedMeta, skipMeta, takeScreenshot = false) => {

    // console.log('handleSetMaterial', item, material)

    // Set selected options
    const isArray = Array.isArray(item)
    const theId = isArray ? item[0].id : item.id
    const theMeta = isArray ? item[0].meta : item.meta
    selectedOptions.current = {...selectedOptions.current, [theId]: material.id}

    // Reset any selected variant
    setSelectedVariationId(false)

    return new Promise((resolve, reject) => {

      if (!item) {
        console.error("Missing item")
        return reject("Missing item")
      }

      let partIdsArr = []
      if (item.itemPartIds) {
        partIdsArr = item.itemPartIds
      }
      else {
        if (Array.isArray(item)) {
          item.forEach((i) => {
            if (i.type === 'group') {
              partIdsArr = partIdsArr.concat(i.parts.map((p) => p.id))
            }
            else {
              partIdsArr.push(i.id)
            }
          })
        }
        else {
          partIdsArr = item.type === 'group' ? item.parts.map((p) => p.id) : [item.id]
        }
      }

      createNewMaterial(sceneRef.current, createdMaterialsRef.current, material, defaultMaterialsRef.current).then(async (mat) => {
        
        let theMat = mat
        // Apply customizations if we have them
        const meta = providedMeta ? providedMeta : theMeta
        if (!skipMeta && meta) {
          const { mat: customizedMat, metaHash } = await updateMaterialMeta(sceneRef.current, mat, meta, customizedMaterialsRef.current)
          theMat = customizedMat
          if (metaHash) {
            customizedMaterialsRef.current[metaHash] = customizedMat
          }
        }
        sceneRef.current.meshes.forEach((m) => {
          if (partIdsArr.indexOf(m.id) > -1) {
            // console.log("Set material for: ", m.id, mat)
            m.material = theMat
            // m.material.decalMap.smoothAlpha = true
            // m.material.decalMap.isEnabled = true
          }
        })

        // capture screenshot
        if (takeScreenshot) {
          await handleGenerateScreenshot()
        }

        // Set selected materials (filtered by those being used in scene currently)
        const filteredCacheRef = Object.entries(apiCacheRef.current).filter(([key]) => sceneRef.current.meshes.find((m) => m.material && m.material.id === key)).map(([mId, mV]) => { return {id: mV.id, personal: mV.personal} })
        dispatch(setSelectedProjectMaterials(filteredCacheRef))

        // Done
        resolve(mat)
      }, (err) => {
        console.error("err creating mat", err)
        reject(err)
      })
    })
  }, [dispatch, handleGenerateScreenshot])

  const addProjectDecals = useCallback((decalsObj) => {

    Object.keys(decalsObj).forEach(async (key) => {
      const decal = decalsObj[key]
      if (decal.id && decal.mesh) {
        const mesh = sceneRef.current.meshes.find((m) => m.id === decal.mesh)
        if (mesh) {
          const newDecal = await addDecalToMesh(sceneRef.current, mesh, decal, null, projectRef.current.id)

          // setTimeout(() => {
            // addDecalToMesh(sceneRef.current, mesh, decal, newDecal.texture)
          // }, 250)

          decalsRef.current = [...decalsRef.current, newDecal]
        }
      }
    })

  }, [])
  
  const handleSaveMaterial = useCallback((material, isMaterialChanged = false, partOverride = false, skipHistory = false, skipUpdate = false, skipSave = false, closePanel = false, isColorTransfer = false, metaOverride = false) => {
    console.log('HANDLE SAVE MATERIAL: ', {material})
    return new Promise(async (resolve, reject) => {
      const thePart = partOverride ? partOverride : selectedPartForEditMaterial

      if (!thePart) {
        console.error("selectedPartForEditMaterial not available")
        reject()
        return
      }

      // console.log("save material for part:", material)
      const isArray = Array.isArray(thePart)
      const partIds = isArray ? thePart.map((p) => p.id) : thePart.id
      let previousMaterialId
      let updatedParts = [...parts]
      if (updatedParts.find((p) => partIds.indexOf(p.id) > -1)) {
        updatedParts = [...parts].map((p) => {
          if (partIds.indexOf(p.id) > -1) {
            if (p.material && p.material.id) {
              previousMaterialId = p.material.id
            }
            
            const {id, external, personal} = material
            const updatedPart = {...p, material: {id, external, personal}}

            if (metaOverride) {
              updatedPart.meta = metaOverride
            }

            return updatedPart
          }

          return p
        })
      }
      else {
        console.log("Add new part")
        updatedParts.push({...thePart, material: material})
      }
      
      console.log({updatedParts})
      
      // Set material
      if (createdMaterialsRef.current[material.id]) {
        let itemParts = []
        if (isArray) {
          thePart.forEach((sp) => {
            if (sp.type === 'group') {
              itemParts = itemParts.concat(sp.parts.map((p) => p.id))
            }
            else {
              itemParts.push(sp.id)
            }
          })
        }
        else {
          itemParts =  thePart.type === 'group' ? thePart.parts.map((p) => p.id) : [thePart.id]
        }
      
        itemParts.forEach((id) => {
          defaultMaterialsRef.current[id] = createdMaterialsRef.current[material.id]
        })

        // Add to api cache
        apiCacheRef.current[material.id] = material

        // Set selected materials (filtered by those being used in scene currently)
        const filteredCacheRef = Object.entries(apiCacheRef.current).filter(([key]) => sceneRef.current.meshes.find((m) => m.material && m.material.id === key)).map(([mId, mV]) => { return {id: mV.id, personal: mV.personal} })
        dispatch(setSelectedProjectMaterials(filteredCacheRef))
      }

      // Update selected part
      if (selectedPartForEditMaterial && !closePanel) {
        setSelectedPartForEditMaterial(updatedParts.filter((u) => partIds.indexOf(u.id) > -1))
      }

      // Add to history
      const historyEvent = {
        type: HISTORY_EVENT_TYPES.EDIT_MATERIAL,
        params: {partIds, previousMaterialId, destinationMaterialId: material.id, isColorTransfer}
      }

      if (!skipHistory) {
        dispatch(addToHistory(historyEvent))
      }

      // Update project
      let projectUpdates = {parts: updatedParts}
      if (projectRef.current.meta && projectRef.current.meta.preBaked && isMaterialChanged) {
        projectUpdates = {...projectUpdates, meta: {...projectRef.current.meta, preBaked: false}}
      }

      // Set parts
      if (!skipUpdate) {
        setParts(updatedParts)
      }

      // Save
      if (!skipSave) {
        await handleUpdateProject(projectUpdates)
      }
      
      // Return history obj + parts
      resolve({historyEvent, updatedParts})
    })

  }, [dispatch, handleUpdateProject, parts, selectedPartForEditMaterial])

  const handleInit = useCallback(async () => {
    console.log('init scene')

    // Reset everything
    defaultMaterialsRef.current = {}
    createdMaterialsRef.current = {}
    initialMaterialsRef.current = {}

    if (sceneRef.current) {
      console.log('dispose existing scene')
      sceneRef.current.dispose()
    }

    try {
      engineRef.current = new Engine(canvasRef.current, true)
      setEngineRef(engineRef.current)
      const sceneParams = await createScene(engineRef.current)

      // Set scene
      sceneRef.current = sceneParams.scene

      // Set HightLight Layer
      await createHighLightLayer(sceneRef.current)

      // Setup lighting
      lightRefs.current = await createLightingEnvironment(sceneRef.current, lightRefs.current, projectRef.current.meta && projectRef.current.meta.lighting ? projectRef.current.meta.lighting : false)

      // Handle project specific functions
      if (MiscHelperMethods.isConfiguratorOr3dOrVariationsProject(sceneParams) && sceneParams.partsToUse && projectRef.current.model_status === "ready") {

        setLoading(true)

        // Set default parts
        let partsToUse = sceneParams.partsToUse
        let didAutogroup
        if (!props.shared && projectRef.current.model_id && partsToUse.length > 0) {
          // Check to see if we've checked if this project model is pre-baked
          if (!projectRef.current.meta.didCheckForPreBake) {
            await handleCheckForPreBaked(partsToUse)
          }
          // Check to see if we've autogrouped before (contained in meta)
          if (!projectRef.current.meta.autogrouped) {
            const { didUpdate, updatedParts } = await InitHelperMethods.handleAutogroupParts(partsToUse, projectRef.current.model_meta_location)
            if (didUpdate) {
              partsToUse = updatedParts
              didAutogroup = true
            }
          }
          
          // show elements window on model load, but not in cas of Sample Tour
          if (!(projectRef.current?.project_type === T._3D_MODEL && projectRef.current?.is_sample === true)) {
            setShowListMenu('elements')
          }
        }

        // Set parts
        const updatedParts = JSON.parse(JSON.stringify(partsToUse))

        // Fetch & update materials in personal collection (downscale to 1k)
        let didReplaceMaterials = false
        if (!props.shared && projectRef.current.model_id) {

          let modelMaterials
          if (sceneParams.createdMaterialsToUse) {
            console.log("Replace default materials in scene...")
            modelMaterials = sceneParams.createdMaterialsToUse
          }

          // Check to see if we've sampled materials before
          if (!projectRef.current.did_import_materials && sceneParams.sampledMaterialsToUse && sceneParams.sampledMaterialsToUse.length > 0) {
            const updatedSceneSample = sceneParams.sampledMaterialsToUse.map((sm) => {
              // Replace material with material from model
              const modelMaterialsMatch = modelMaterials.find((mm) => mm.name === sm.material.id || mm.name === sm.material.name)
              return {...sm, material: modelMaterialsMatch}
            })
            // Do not ask to import material if the model is a prebaked
            if (projectRef.current.meta && projectRef.current.meta.preBaked === false) {
              setShowMaterialImport({items: updatedSceneSample, modelId: projectRef.current.model_id})
            }
          }

          // todo: At some point, I'd like to not have to make this call each time the project loads.
          /// we should only make the call if the model hasn't been retextured in this project
          ///// for now, we'll only replace parts w/o external maps
          if (modelMaterials && modelMaterials.length > 0) {
            const partsWithoutExternalMats = partsToUse.filter((p) => p.material && !p.material.external)
            for (let i = 0; i < partsWithoutExternalMats.length; i++) {
              const p = partsWithoutExternalMats[i]
              const matReplacement = modelMaterials.find((m) => m.name === p.material.name || m.name === p.material.id)
              if (matReplacement) {
                const updatedPart = updatedParts.find((up) => p.id === up.id)
                // console.log("Set part with material: ", p.id, matReplacement)
                const rawMat = await handleSetMaterial(p, matReplacement, matReplacement.meta)
                updatedPart.material = matReplacement
                didReplaceMaterials = true
                defaultMaterialsRef.current[p.id] = rawMat
                initialMaterialsRef.current[p.id] = rawMat

                if (!apiCacheRef.current[matReplacement.id]) {
                  apiCacheRef.current[matReplacement.id] = matReplacement
                }
              }
            }
          }
        }

        // Update any parts that reference external materials
        const partsWithExternalMats = partsToUse.filter((p) => p.material && p.material.external)
        let didError = false
        const materialsFailedToLoad = []

        for (let i = 0; i < partsWithExternalMats.length; i++) {
          const p = partsWithExternalMats[i]

          // Fetch and set materials
          const [err, mat] = await awaitToJs(MiscHelperMethods.getProjectMaterialForPart(projectRef.current.materials, p.material.id, apiCacheRef.current))

          if (err) {
            didError = true
            materialsFailedToLoad.push(p.material.name)
            continue
          } 
          
          if (mat) {
            apiCacheRef.current[mat.id] = mat
            const [err, rawMat] = await awaitToJs(handleSetMaterial(p, mat))

            if (err) {
              didError = true
              console.error(err)
              continue
            }

            // Update default material
            defaultMaterialsRef.current[p.id] = rawMat
            initialMaterialsRef.current[p.id] = rawMat
          } 
        }

        if (didError) {
          const errMsg = (
            <div>
              The following material(s) in your project failed to load. We’ll use default materials for now. Feel free to try reloading the project.
              <ul>{materialsFailedToLoad.map((mat, ind) => <li key={ind}>{ind + 1}: {mat}</li>)}</ul>
            </div>
          )
          dispatch(setToast({message: errMsg, isError: "true"}))
        }

        // Update parts
        if (partsWithExternalMats.length > 0 || didAutogroup || didReplaceMaterials) {
          console.log("Setting partsWithExternalMats / downscale and/or autogrouping")
          setParts(updatedParts)
        }

        // If project parts are empty, let's set a baseline
        if (projectRef.current.parts.length < 1 || didAutogroup) {
          console.log("Saving default parts for project / autogrouping")
          const updates = {parts: updatedParts}

          if (didAutogroup) {
            const meta = projectRef.current.meta ? {...projectRef.current.meta} : {}
            meta.autogrouped = true
            updates.meta = meta
          }

          try {
            await handleUpdateProject({parts: updatedParts}, true)
          }
          catch (e) {
            console.error(e)
          }
        }

        // Update cache ref
        const filteredCacheRef = Object.entries(apiCacheRef.current).filter(([key]) => sceneRef.current.meshes.find((m) => m.material && m.material.id === key)).map(([mId, mV]) => { return {id: mV.id, personal: mV.personal} })
        dispatch(setSelectedProjectMaterials(filteredCacheRef)) 
      
        // Add any decals
        if (projectRef.current.decals && Object.keys(projectRef.current.decals).length > 0) {
          setTimeout(() => {
            addProjectDecals(projectRef.current.decals)
          }, 500)
        }
      }

      engineRef.current.runRenderLoop(() => {
        sceneRef.current.render()
      })

      sceneRef.current.onAfterRenderCameraObservable.add(() => {
        const roundedZoom = cameraRef.current.radius
        if (lastZoomRef.current !== roundedZoom) {
          lastZoomRef.current = roundedZoom
          handleZoomUpdate(roundedZoom)
        }
      })

      // Add pointer observable
      if (!props.publicView) {
        sceneRef.current.onPointerObservable.add((e) => {
          if (e.type === PointerEventTypes.POINTERTAP && e.event.button === 0) {
            if (!lastMeshClick.current) {
              console.log("Reset lastMeshClick")
              setSelectedParts([])
              setSelectionMenu(false)
              setSelectedPartForEditMaterial(false)

              // Close list menu
              if (lastClickOrigin.current === 'scene') {
                setShowListMenu(false)
              }
            }
          }
        })
      }

      // Dispatch done event
      if (props.shared && sceneParams.configurationsToUse && sceneParams.configurationsToUse.length > 0) {
        // Scene loaded
        document.dispatchEvent(new CustomEvent(EVENTS.SCENE_LOADED))
      }
      else {
        // Scene loaded + scene configured
        document.dispatchEvent(new CustomEvent(EVENTS.SCENE_LOADED))
        document.dispatchEvent(new CustomEvent(EVENTS.SCENE_CONFIGURED))
      }

      // Done
      setLoading(false)
      setDidError(false)
      setErrorMessage("")
      setCameraReady(true)
    }
    catch (e) {
      console.error("Error during init: ", e)
      if (e.code) {
        setErrorMessage(e.message)
      } else if (e.data && e.data === "INSUFFICIENT") {
        setShowNoSpaceAvailableModal(true)
      } else {
        setErrorMessage("Sorry, there was an error loading your project and/or model. Please try again")
      }
      setDidError(true)
      setLoading(false)
    }
  }, [createScene, handleUpdateProject, dispatch, handleZoomUpdate, handleSetMaterial, handleCheckForPreBaked, props.shared, props.publicView, setEngineRef, addProjectDecals])

  // Setup scene on load
  useEffect(() => {

    // Disable zoom outside of canvas
    document.addEventListener('wheel', handleWheelEvent, { passive: false })

    // Listen for refresh event
    document.addEventListener(EVENTS.REFRESH_SCENE, handleInit)

    // Let's go
    setTimeout(handleInit, 250)

    return () => {
      console.log("Dispose scene")
      document.removeEventListener('wheel', handleWheelEvent)
      document.removeEventListener(EVENTS.REFRESH_SCENE, handleInit)

      if (sceneRef.current) {
        console.log("Dispose control handlers")

        if (cameraRef.current){
          cameraRef.current.detachControl()
        }

        if (sceneRef.current.meshes.length > 0) {
          sceneRef.current.meshes.forEach((m) => {
            if (m.actionManager) {
              // console.log("Dispose action manager for: ", m.id)
              m.actionManager.dispose()
            }
          })
        }

        // Dispose scene
        sceneRef.current.dispose()
        MeshUVSpaceRenderer.Dispose()
      }

      // Dispose engine
      if (engineRef.current) {
        engineRef.current.dispose()
      }
    }

  }, [handleInit])

  // Handle highlights of items based on hovered parts + selection states
  useEffect(() => {

    if (!sceneRef.current || !sceneRef.current.meshes || props.publicView || isPreviewing) {
      return
    }

    let hlPartIds = []
    let editingParts = []

    const selectedMeshIds = []
    if (selectedParts.length > 0) {
      selectedParts.forEach((pid) => {
        const part = getPartOrGroup(pid)
        if (part.type === 'group') {
          part.parts.forEach((sp) => selectedMeshIds.push(sp.id))
        }
        else {
          selectedMeshIds.push(pid)
        }
      })
    }

    if (hoveredPart) {
      if (selectedPartForEditMaterial) {
        selectedPartForEditMaterial.forEach(s => {
          if (s.type === 'group') {
            s.parts.forEach((sp) => editingParts.push(sp.id))
          } else {
            editingParts.push(s.id)
          }
        })
      }
      const item = getPartOrGroup(hoveredPart)
      const partIds = item.type === 'group' ? item.parts.map((p) => p.id) : [item.id]
      hlPartIds = partIds.map((pid) => {
        return (selectedMeshIds.indexOf(pid) > -1 || editingParts.indexOf(pid) > -1) ? false : `${pid}`
      })
    }
    const outLineColor = new Color3(0.7, 0.2, 0.9)
    sceneRef.current.meshes.forEach((m) => {
      // const isHighlight = m.id.indexOf("_hl_") > -1
      
      // if (isHighlight) {
      //   if (hlPartIds.indexOf(m.id) > -1) {
      //     m.visibility = 0.2
      //   }
      //   else {
      //     m.visibility = 0
      //   }
      // }
      // else {
      //   const hlId = "_hl_" + m.id
      //   if (m.visibility !== 0) {
      //     if ((hlPartIds.length < 1 && selectedParts.length < 1) || selectedMeshIds.indexOf(m.id) > -1 || hlPartIds.indexOf(hlId) > -1 || editingParts.indexOf(m.id) > -1) {
      //       m.visibility = 1
      //     }
      //     else {
      //       // m.visibility = 0.2
      //     }
      //   }
      // }

      if (selectedMeshIds.indexOf(m.id) > -1 || hlPartIds.indexOf(m.id) > -1) {
        hlRef.current.addMesh(m, outLineColor)
      } else {
        hlRef.current.removeMesh(m)
      }
    })
  }, [hoveredPart, getPartOrGroup, selectedParts, props.publicView, isPreviewing, selectedPartForEditMaterial])

  const handlePartClick = useCallback((partId, originHandler, isRightClickEvent, returnPartOnly = true) => {
    lastClickOrigin.current = originHandler
    isRightClick.current = isRightClickEvent

    // Check if this part contains decal(s), if so we'll show the gizmos for each one
    const mesh = sceneRef.current.meshes.find((m) => m.id === partId)
    if (!mesh) {
      return
    }

    // Check if part id is actually an array of ids
    let isArray = false
    let item
    if (Array.isArray(partId)) {
      isArray = true
    }
    else {
      // Check to see if this part id is part of a group
      item = getPartOrGroup(partId, returnPartOnly)
      if (!item) {
        console.log("clear")
        setSelectedParts([])
        setSelectionMenu(false)

        // Close list menu
        if (originHandler === 'scene') {
          setShowListMenu(false)
        }

        return
      }
    }

    if (originHandler === 'scene') {
      // If a menu is open, let's close it if we're registering a scene right click
      if (isRightClickEvent) {
        setShowListMenu(false)
      }
      else {// Show parts menu
        setShowListMenu('elements')
      }
      // scroll to the part that got clicked on the mesh
      if (item && partViewerRef.current) {
        partViewerRef.current.scrollToPartOrGroup(item.id)
      }
    }
  
    if (isArray) {
      if (partId === null) {
        setSelectedParts([])
      }
      else {
        setSelectedParts(partId)
      }
    }
    else {
      let updatedParts = []
      // let partIds
      if (selectedParts.indexOf(item.id) < 0) {
        // partIds = item.type === 'group' ? item.parts.map((p) => p.id) : [item.id]
        if ((isHoldingCtrlRef.current || isHoldingCmdRef.current) && selectedParts.length > 0) {
          console.log("add array")
          console.log(selectedParts)
          updatedParts = [...selectedParts, item.id]
          // console.log(updatedParts)
          // selectedParts.forEach((i) => {
          //   const item = getPartOrGroup(i)
          //   if (item) {
          //     const a = item.type === 'group' ? item.parts.map((p) => p.id) : [item.id]
          //     partIds = partIds.concat(a)
          //   }
          // })
        }
        else {
          updatedParts = [item.id]
        }
      } else {
        updatedParts = selectedParts.filter((partName) => { return partName !== item.id })
      }

      // console.log(updatedParts)
      // If we have an item being edited (material wise) - we should switch it to the currently selected part
      if (selectedPartForEditMaterial) {
        const allSelectedParts = parts.filter((p) => updatedParts.indexOf(p.id) > -1)
        if (allSelectedParts.length > 0) {
          setSelectedPartForEditMaterial(allSelectedParts)
        }
      }
      else {
        const selectedPart = parts.find((p) => p.id === updatedParts[0])
        setSelectedPartForEditMaterial([selectedPart])
      }

      setSelectedParts(updatedParts)

      if (originHandler === 'partViewer') {
        handleZoomToPart(updatedParts)
      }
    }


  }, [selectedParts, getPartOrGroup, selectedPartForEditMaterial, parts])

  const handleMeshClick = useCallback((e) => {

    const {source, sourceEvent} = e

    if (!specialAction && sourceEvent.button === 2 && !props.shared) {
      setSelectionMenu({left: sceneRef.current.pointerX, top: sceneRef.current.pointerY})
    }
    else {
      setSelectionMenu(false)
    }

    // console.log('on part click: ', source.id)
    handlePartClick(source.id, 'scene', sourceEvent.button === 2, false)

  }, [handlePartClick, specialAction, props.shared])

  const handleDeleteDecal = (decalId) => {

    // Update project
    const updated = {...projectRef.current.decals}
    delete updated[decalId]

    // Dispose meshes
    const mesh = sceneRef.current.meshes.find((m) => m.id === decalId)
    if (mesh) {
      mesh.dispose(true, true)
    }

    // Update ref
    decalsRef.current = [...decalsRef.current].filter((d) => d.id !== decalId)
    handleUpdateProject({decals: updated})
  }

  const handleMeshHoverOut = useCallback((e) => {

    lastMeshClick.current = false
    hoverPartRef.current = null
    setHoveredPart(null)
  }, [])

  const handleMeshHover = useCallback((e, providedSourceId) => {

    let sourceId
    if (e) {
      const {source} = e
      sourceId = source.id
    }
    else if (providedSourceId) {
      sourceId = providedSourceId
    }
    else {
      hoverPartRef.current = false
      setHoveredPart(false)
      lastMeshClick.current = false
      return
    }

    // Get item or group
    const isDecal = decalsRef.current && decalsRef.current.find((d) => d.id === sourceId) ? true : false
    if (isDecal) {
      return
    }

    const item = getPartOrGroup(sourceId, false)
    if (!item) {
      console.error("Part/Group not found on handleMeshHover:", sourceId)
      hoverPartRef.current = false
      setHoveredPart(false)
      lastMeshClick.current = false
      return
    }

    lastMeshClick.current = sourceId
    if (item.id !== hoverPartRef.current) {
      hoverPartRef.current = item.id
      setHoveredPart(item.id)
    }

  }, [getPartOrGroup])

  // When our parts change, we need to add their actions
  useEffect(() => {

    if (!sceneRef.current || !cameraRef.current || !projectRef.current || !cameraReady) {
      console.log("Camera or scene not available")
      return
    }

    if (!MiscHelperMethods.isConfiguratorOr3dOrVariationsProject(projectRef.current) || props.publicView) {
      return
    }
    
    // console.log("RUN ACTION EFFECT")
    // todo: revisit the below
    // const meshes = []
    // let computedMeshHash = sceneRef.current.meshes.map((m) => m.id)

    // parts.forEach((p) => {
    //   computedMeshHash.push(p.id)

    //   if (p.type === 'part') {
    //     const mesh = sceneRef.current.meshes.find((m) => m.id === p.id)
    //     if (mesh) {
    //       meshes.push(mesh)
    //     }
    //   }
    //   else {
    //     p.parts.forEach((sp) => {
    //       const mesh = sceneRef.current.meshes.find((m) => m.id === sp.id)
    //       if (mesh) {
    //         meshes.push(mesh)
    //       }
    //     })
    //   }
    // })


    // Order arr + hash
    // computedMeshHash = computedMeshHash.sort((a, b) => a > b ? 1 : -1).map((i) => HASH_STRING(i)).join("")

    // Make sure hash has changed before updating listeners
    // if (meshListHash.current !== computedMeshHash) {
      // meshes.forEach((m) => {
      sceneRef.current.meshes.forEach((m) => {
        if (m.id.indexOf("__decal-") < 0) {
          if (m.actionManager) {
            m.actionManager.dispose()
          }

          m.actionManager = new ActionManager(sceneRef.current)
          m.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOverTrigger, handleMeshHover))
          m.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPointerOutTrigger, handleMeshHoverOut))
          m.actionManager.registerAction(new ExecuteCodeAction(ActionManager.OnPickDownTrigger, handleMeshClick))
        }
      })

      // meshListHash.current = computedMeshHash
      // console.log("Create action managers")
    // }

  }, [handleMeshHover, handleMeshHoverOut, handleMeshClick, cameraReady, props.publicView])


  // Handle visibility request
  useEffect(() => {
    if (!sceneRef.current || !sceneRef.current.meshes) {
      return
    }

    sceneRef.current.meshes.forEach((m) => {
      if (invisible.indexOf(m.id) > -1 || m.id.indexOf("_hl_") > -1) {
        m.visibility = 0
        m.isPickable = false
      }
      else {
        m.visibility = 1
        m.isPickable = true
      }
    })

  }, [invisible])

  const didAttach = useRef(false)
  useEffect(() => {
    if (cameraRef.current && canvasRef.current && sceneRef.current && !didAttach.current) {
      console.log('attach controls')
      cameraRef.current.attachControl(canvasRef.current, true)
      didAttach.current = true
    }

  }, []) 

  const handleWheelEvent = (event) => {
    const { ctrlKey } = event
    if (ctrlKey) {
      event.preventDefault()
      return
    }
  }

  const handleVisibilityRequest = (item) => {
    console.log("handleVisibilityRequest")
    setSelectionMenu(false)
    setSelectedParts([])

    if (item.visible) {
      setInvisible([...invisible].filter((i) => item.parts.indexOf(i) < 0))
      return
    }

    setInvisible([...invisible].concat(item.parts))
  }

  useEffect(() => {
    MiscHelperMethods.storeParts(parts)
  }, [parts])

  const handleGroup = () => {
    console.log("handleGroup")
    // We'll pluck parts in the order they were selected
    const plucked = selectedParts.map(pid => MiscHelperMethods.parts.get(pid))

    // We'll need to join groups if necessary
    const groupParts = []
    plucked.forEach((p) => {
      groupParts.push(...(p.type === 'group' ? p.parts : [p]))
    })

    // We'll grab the first material on individual parts/groups
    const [firstPluckedPart] = plucked
    const hasGroupMaterial = firstPluckedPart.material && firstPluckedPart.material.external ? firstPluckedPart.material : false
    // const hasGroupUv = firstPluckedPart.usesCustomUV ? { scale: firstPluckedPart.scale, rotation: firstPluckedPart.rotation, uOffset: firstPluckedPart.uOffset, vOffset: firstPluckedPart.vOffset } : false
    
    let newItem = {
      type: 'group',
      id: plucked.reduce((acc, curr) => acc ? `${acc}-${curr.id}` : curr.id, ''),
      name: 'New Group',
      parts: groupParts
    }

    // Apply material if it exists and is external on any group items
    if (hasGroupMaterial) {
      newItem.material = hasGroupMaterial
      const rawGroupMaterial = sceneRef.current.materials.find((m) => m.id === hasGroupMaterial.id)
      groupParts.forEach((g) => {
        handleSetMaterial(g, hasGroupMaterial)

        if (rawGroupMaterial) {
          defaultMaterialsRef.current[g.id] = rawGroupMaterial
        }
      })
    }

    // Get project updates ready
    const projectUpdates = {}

    // Check to see if we need to update a configuration (i.e this group contains parts that have existing configs)
    if (configurations.length > 0) {
      const toDeleteConfigs = []
      let options = []
      const curOptionsIds = {}
      const isAffected = configurations.filter((c) => selectedParts.indexOf(c.id) > -1)
      isAffected.forEach((a) => {
        toDeleteConfigs.push(a.id)
        const optionsToAdd = []
        if (a.options && a.options.length > 0) {
          a.options.forEach((o) => {
            if (!curOptionsIds[o.id]) {
              optionsToAdd.push(o)
              curOptionsIds[o.id] = true
            }
          })

          if (optionsToAdd.length > 0) {
            options = [...options].concat(optionsToAdd)
          }
        }
      })
      
      // Now update configurations  
      if (toDeleteConfigs.length > 0) {
        const newConfig = {id: newItem.id, type: 'group', parts: groupParts, name: newItem.name, options, material: hasGroupMaterial}
        const updatedConfig = [...configurations, newConfig].filter((c) => {
          if (toDeleteConfigs.indexOf(c.id) > -1) {
            return false
          }

          return true
        })

        setConfigurations(updatedConfig)
        projectUpdates.variations = updatedConfig
      }
    }
    
    const updatedParts = [...parts.filter((p) => selectedParts.indexOf(p.id) < 0), newItem].sort((a, b) => a.name > b.name ? 1 : -1)
    setParts(updatedParts)
    setSelectedParts([])
    setSelectionMenu(false)
    setSelectedConfiguration(false)
    setSelectedPartForEditMaterial(false)

    // Update project
    handleUpdateProject({...projectUpdates, parts: updatedParts})
  }

  const handleZoomToPart = (partId) => {
    const camera = cameraRef.current
    if (partId.length === 1 && MiscHelperMethods.parts.get(partId[0]).type === 'part') {
      const destinationPart = sceneRef.current.meshes.find((p) => p.id === partId[0])
      const newPos = destinationPart.getBoundingInfo().boundingBox.centerWorld

      const radius = destinationPart.getBoundingInfo().boundingSphere.radiusWorld;
      const aspectRatio = engineRef.current.getAspectRatio(camera);
      let halfMinFov = camera.fov / 2;
      if (aspectRatio < 1) {
          halfMinFov = Math.atan( aspectRatio * Math.tan(camera.fov / 2) );
      }
      const viewRadius = Math.abs( radius / Math.sin(halfMinFov) );

      moveActiveCamera(sceneRef.current, { target: newPos, radius: viewRadius })

      setTimeout(() => {
        cameraRef.current.framingBehavior.zoomOnMesh(destinationPart)
      }, 300)

    } else {

      const worldExtends = sceneRef.current.getWorldExtends(
        (mesh) => mesh.isVisible && mesh.isEnabled()
      );
      const worldSize = worldExtends.max.subtract(worldExtends.min);
      const worldCenter = worldExtends.min.add(worldSize.scale(0.5));
      const radius = worldSize.length() * 1.5;
      const target = worldCenter || Vector3.Zero()

      moveActiveCamera(sceneRef.current, { target, radius, alpha: MiscHelperMethods.initialCameraSettings.alpha, beta: MiscHelperMethods.initialCameraSettings.beta })
    }
  }

  const resetModelToDefaults = (itemId) => {

    let itemParts = []

    if (Array.isArray(itemId)) {
      itemId.forEach((id) => {
        const item = MiscHelperMethods.parts.get(id)
        if (item) {
          itemParts.push(...(item.type === 'group' ? item.parts.map(ip => ip.id) : [item.id]))
        }
      })
    }
    else {
      const item = MiscHelperMethods.parts.get(itemId)
      if (!item) {
        return
      }

      itemParts = item.type === 'group' ? item.parts.map((p) => p.id) : [item.id]
    }
    
    sceneRef.current.meshes.forEach((m) => {
      if (itemParts.indexOf(m.id) > -1) {
        console.log('reset mat for: ', m.id)
        // Handle remaps/groups
        if (defaultMaterialsRef.current[itemId]) {
          m.material = defaultMaterialsRef.current[itemId]
        }
        else if (defaultMaterialsRef.current[m.id]) {
          m.material = defaultMaterialsRef.current[m.id]
        }
      }
    })
  }

  const handleUngroup = (itemId) => {
    console.log("handleUngroup")
    const group = MiscHelperMethods.parts.get(itemId)
    if (!group) {
      return
    }

    const updateParams = {}
    setSelectedParts([])
    let groupParts = group.parts

    // Copy over materials to each part if a material has been assigned
    if (group.material && group.material.external) {
      groupParts = groupParts.map((p) => {
        return {...p, material: group.material}
      })
    }

    // Update parts
    const updatedParts = [...parts.filter((p) => p.id !== itemId), ...groupParts].sort((a, b) => a.name > b.name ? 1 : -1)
    setParts(updatedParts)
    updateParams.parts = updatedParts

    // Copy configurations over to each part if they exist
    const existingConfig = configurations.find((c) => c.id === group.id)
    if (existingConfig) {
      console.log("Copy variations to individual parts")
      const updatedConfig = [...configurations.filter((c) => c.id !== itemId)]
      groupParts.forEach((p) => {
        const params = {id: p.id, type: 'part', parts: [], name: p.name, material: p.material, options: [...existingConfig.options]}
        updatedConfig.push(params)
      })

      setConfigurations(updatedConfig)
      updateParams.variations = updatedConfig
    }
    setSelectionMenu(false)
    setSelectedConfiguration(false)
    setSelectedPartForEditMaterial(false)
    // Update project
    handleUpdateProject(updateParams)
  }

  const handleRemovePartFromGroup = (groupId, partId) => {
    console.log("handleRemovePartFromGroup", groupId, partId)
    let group = MiscHelperMethods.parts.get(groupId)
    if (!group) {
      return
    }
    let partToRemove = group.parts.find((p) => p.id === partId)
    if (!partToRemove) {
      return
    }
    const otherPartsInGroup = group.parts.filter((p) => p.id !== partId)
    if (otherPartsInGroup.length < 1) {
      setShowCannotRemovePartModal(true)
      return false
    }
    const updateParams = {}
    setSelectedParts([])
    // Copy over materials to each part if a material has been assigned
    if (group.material && group.material.external) {
      partToRemove = { ...partToRemove, material: group.material }
    }
    group = {
      ...group,
      id: otherPartsInGroup.map((p) => p.id).join("-"),
      parts: otherPartsInGroup
    }

    // Update parts
    const updatedParts = [...parts.filter((p) => p.id !== groupId), partToRemove, group].sort((a, b) => a.name > b.name ? 1 : -1)
    setParts(updatedParts)
    updateParams.parts = updatedParts

    // Copy configurations over to each part if they exist
    const existingConfig = configurations.find((c) => c.id === partId)
    if (existingConfig) {
      console.log("Copy variations to ungrouped part")
      const updatedConfig = [...configurations.filter((c) => c.id !== partId)]
      updatedConfig.push({
        id: partId,
        type: 'part',
        parts: [],
        name: partToRemove.name,
        material: partToRemove.material,
        options: [...existingConfig.options]
      })
      setConfigurations(updatedConfig)
      updateParams.variations = updatedConfig
    }
    // Update project
    handleUpdateProject(updateParams)
  }

  const handleUpdateItem = (itemId, params) => {
    const updatedParts = [...parts].map((i) => {
      if (i.id === itemId) {
        return {...i, ...params}
      }

      return i
    })

    setParts(updatedParts)

    // Update project
    handleUpdateProject({parts: updatedParts})
  }

  // const handleSelectConfiguration = (configId) => {
  //   const existing = configurations.find((i) => i.id === configId)
  //   if (!existing) {
  //     return
  //   }

  //   // Toggle 
  //   if (selectedConfiguration && selectedConfiguration.id === configId) {
  //     setSelectedConfiguration(false)
  //   }
  //   else {
  //     // If we have another config selected, let's make sure to reset the model
  //     resetModelToDefaults(selectedConfiguration.id)
  //     setSelectedConfiguration(existing)
  //     setSelectedPartForEditMaterial(false)
  //   }
  // }

  const handleEditMaterial = async (itemId) => {
    // Close menu
    setSelectionMenu(false)
    // setSelectedParts([])
    setSelectedConfiguration(false)

    // Open
    // const partsArr = itemId.map(id => MiscHelperMethods.parts.get(id))

    // setSelectedPartForEditMaterial(partsArr)
  }

  const handleDeleteConfiguration = (itemId) => {
    const updatedConfigurations = [...configurations].filter((c) => c.id !== itemId)
    setConfigurations(updatedConfigurations)

    // Update project
    handleUpdateProject({variations: updatedConfigurations})
  }

  const handleDeleteConfigurationOption = (itemId, optionId) => {
    let updatedConfigurations = [...configurations].map((c) => {
      if (c.id === itemId) {
        return {...c, options: [...c.options].filter((o) => o.id !== optionId)}
      }

      return c
    })

    // If we only have one option left, and it's the default, let's remove that too
    const affectedConfig = updatedConfigurations.find((c) => c.id === itemId)
    if (affectedConfig.options.length === 1 && affectedConfig.options[0].optionId === 'default') {
      console.log("Remove default option")
      updatedConfigurations = [...updatedConfigurations].map((c) => {
        if (c.id === itemId) {
          return {...c, options: []}
        }
  
        return c
      })
      // Reset to the part's material... if we delete all the options(variations)
      // handleEditMaterial(selectedParts)
    }

    const updatedConfig = updatedConfigurations.find((c) => c.id === itemId)
    setSelectedConfiguration(updatedConfig)
    setConfigurations(updatedConfigurations)
    resetModelToDefaults(updatedConfig.id) // Reset

    // Update project
    handleUpdateProject({variations: updatedConfigurations})
  }

  const handleUpdateConfiguration = (itemId, updates) => {
    const updatedConfigurations = [...configurations].map((c) => {
      if (c.id === itemId) {
        return {...c, ...updates}
      }

      return c
    })

    setConfigurations(updatedConfigurations)

    // Update selected config
    if (selectedConfiguration && selectedConfiguration.id === itemId) {
      setSelectedConfiguration(updatedConfigurations.find((c) => c.id === itemId))
    }

    // Update project
    handleUpdateProject({variations: updatedConfigurations})
  }

  const handleCreateConfiguration = (itemId) => {
    // Close menu
    setSelectionMenu(false)

    // Check to see if this config already exists
    const existing = configurations.find((i) => i.id === itemId)
    if (existing) {
      // Open existing 
      console.log("Open existing config")
      setSelectedConfiguration(existing)
      return
    }
    else {
      const item = getPartOrGroup(itemId)
      if (!item) { return}

      // todo: We shouldn't need to store a reference to the material anymore...
      // let material
      // if (item.material) {
      //   material = item.material
      // }
      // else if (item.type === 'group' && item.parts.length > 0 && item.parts[0].material) {
      //   material = item.parts[0].material
      // }
      // else {
      //   console.error("Error generating configuration --- no default material found")
      // }

      // const params = {id: itemId, type: item.type, parts: item.parts, name: item.name, material, options: []}
      const itemPartIds = item.type === 'group' ? item.parts.map((p) => p.id) : [item.id]
      const params = {id: itemId, itemPartIds, name: item.name, options: []}
      const updatedConfig = [...configurations, params]
      setConfigurations(updatedConfig)
      setSelectedConfiguration(params)

      // Update project
      handleUpdateProject({variations: updatedConfig})
    }

    // setSelectedPartForEditMaterial(false)
  }

  const saveSortedParts = (sortedPartsArray) => {
    // Update project
    handleUpdateProject({ parts: sortedPartsArray })
    setParts(sortedPartsArray)
  }

  const handleCreateOption = useCallback((configurationId, option) => {
    if (!configurationId) {
      return
    }

    const aConfiguration = configurations.find((c) => c.id === configurationId)
    if (!aConfiguration) {
      // todo: show error
      return
    }

    const updated = {...aConfiguration}
    updated.options = [...updated.options, option]
    const updatedConfig = [...configurations].map((config) => {
      if (config.id === aConfiguration.id) {
        return updated
      }

      return config
    })

    setConfigurations(updatedConfig)

    // Set option
    selectedOptions.current = {...selectedOptions.current, [configurationId]: option.id}

    // Update project
    handleUpdateProject({variations: updatedConfig})
  }, [configurations, handleUpdateProject])

  // const handleResetMaterials = (workingItem) => {
  //   console.log('handle reset materials', workingItem)
  //   const idArr = Array.isArray(workingItem) ? workingItem.map((w) => w.id) : workingItem.id
  //   resetModelToDefaults(idArr)
  //   setSelectedConfiguration(false)
  //   setSelectedPartForEditMaterial(false)
  // }

  const listHeader = {
    parts: {label: 'Elements', icon: getProjectIcon(T._3D_MODEL)}, 
    configure: {label: 'Options', icon: getProjectIcon(T.CONFIGURATOR)}, 
    variations: {label: 'Variations', icon: getProjectIcon(T.VARIATIONS)}, 
    tools: {label: 'Scene Tools', icon: <span className="icon-zap"></span>},
    lighting: {label: 'Lighting', icon: <span>{lighting}</span>},
    material: {label: 'Material Builder', icon: <span>{materialBuilder}</span>},
    createdMaterials: { label: 'Created Materials', icon: <span>{createdMaterials()}</span> }
  }

  const handleCloseListMenu = () => {
    // Show the created materials list after creating the material
    handleToggleListMenu('createdMaterials')
  }


  const handleSaveMaterialSettings = useCallback((partId, matToUpdateId, settings, skipHistory = false, updateDirectly = false, providedParts = false) => {

    return new Promise(async (resolve, reject) => {
      console.log("handleSaveMaterialSettings: ", partId)
      
      // Update parts
      let fullMeta
      let prevMeta
      let thePart
      let updatedParts = providedParts ? providedParts : parts
      updatedParts = [...updatedParts].map((p) => {
        if (p.id === partId) {
          prevMeta = p.meta ? JSON.parse(JSON.stringify(p.meta)) : {}
          fullMeta = {...prevMeta, ...settings}
          thePart = {...p, meta: fullMeta}
          return thePart
        }

        return p
      })

      const isInLastUsedMaterial = lastUsedMaterials.findIndex(mat => mat.id === matToUpdateId)
      if (isInLastUsedMaterial > -1) {
        updateLastUsedMaterialMeta(isInLastUsedMaterial, fullMeta)
      }

      // History
      const historyEvent = {
        type: HISTORY_EVENT_TYPES.EDIT_MATERIAL_SETTINGS,
        params: {materialId: matToUpdateId, partId, previousSettings: prevMeta, destinationSettings: fullMeta}
      }

      if (!skipHistory) { 
        dispatch(addToHistory(historyEvent))
      }

      if (selectedPartForEditMaterial && thePart) {
        setSelectedPartForEditMaterial([thePart])
      }

      if (updateDirectly) {
        const mat = sceneRef.current.materials.find((m) => m.id === matToUpdateId)
        if (mat) {
          const {mat: customizedMat, metaHash} = await updateMaterialMeta(sceneRef.current, mat, fullMeta, customizedMaterialsRef.current)
          if (metaHash) {
            customizedMaterialsRef.current[metaHash] = customizedMat
          }

          const part = getPartOrGroup(partId)
          const meshIds = part.type === 'group' ? part.parts.map((p) => p.id) : [part.id]
          sceneRef.current.meshes.filter((m) => meshIds.indexOf(m.id) > -1).forEach((m) => m.material = customizedMat)

          if (metaHash && part.material) {
            updatedParts = [...updatedParts].map((p) => {
              if (p.id === part.id) {
                return {...p, material: {...p.material, clone_id: metaHash}}
              }

              return p
            })
          }

          // Cleanup any un-used materials
          setTimeout(() => {
            Object.keys(customizedMaterialsRef.current).forEach((cref) => {
              const node = sceneRef.current.meshes.find((m) => m.material && m.material.id === cref)
              const inProject = projectRef.current.materials.find((m) => m.id === cref)
              if (!node && !inProject) {
                console.log("disposing unused material customization: ", cref)
                disposeMaterial(customizedMaterialsRef.current[cref])
                delete customizedMaterialsRef.current[cref]
              }
            })
          }, 500)
        }
        else {
          console.log("mat not found:", matToUpdateId)
        }
      }

      // Update project
      handleUpdateProject({parts: updatedParts})
      setParts(updatedParts)

      // Done
      resolve({historyEvent, updatedParts})
    })

  }, [dispatch, getPartOrGroup, parts, handleUpdateProject, selectedPartForEditMaterial, updateLastUsedMaterialMeta, lastUsedMaterials])

  // Handle updates to project name from header
  useEffect(() => {
    if (projectRef.current && selectedProjectSync && selectedProjectSync.name !== projectRef.current.name) {
      projectRef.current = {...projectRef.current, name: selectedProjectSync.name}
    }

  }, [selectedProjectSync])

  const handleCacheMaterial = (material) => {
    apiCacheRef.current = {...apiCacheRef.current, [material.id]: material}
    const filteredCacheRef = Object.entries(apiCacheRef.current).filter(([key]) => sceneRef.current.meshes.find((m) => m.material && m.material.id === key)).map(([mId, mV]) => { return {id: mV.id, personal: mV.personal} })
    dispatch(setSelectedProjectMaterials(filteredCacheRef))
  }

  const closeConfiguratorBuilder = () => {
    // setShowUVSettings(false)
    setSelectedConfiguration(false)
    setSelectedPartForEditMaterial(false)
    setSelectedParts([])
    // setShowMaterialSettingsWithPattern(false)
  }
  
  // const showViewerHelp = () => {
  //   dispatch(setShowViewerHelp(true))
  // }

  // Clear selected project on exit
  useEffect(() => {
    return () => {
      dispatch(setSelectedProject(false))
    }
  }, [dispatch])

  const handleStartDecalDrop = (decal) => {
    setDecalPicker(decal)
    setDisableZoom(true)
    setShowListMenu(false)
  }

  const handleDecalUpdate = useCallback(async (params) => {

    // Re-enable zoom
    setDisableZoom(false)
    
    // Update mesh, size, normal, angle on existing decal
    const updatedDecals = {...projectRef.current.decals}
    if (!updatedDecals[params.existingDecalId]) {
      return
    }

    console.log('update decal', params)
    const {size, position, normal, angle, mesh} = params
    updatedDecals[params.existingDecalId] = {...updatedDecals[params.existingDecalId], size, position, normal, angle, mesh}

    // Update list
    decalsRef.current = [...decalsRef.current].map((d) => {
      if (d.id === params.existingDecalId) {
        return updatedDecals[params.existingDecalId]
      }

      return d
    })

    // Update project
    const updates = {decals: updatedDecals}
    try {
      await handleUpdateProject(updates)
    }
    catch (e) {
      console.error("Error updating decal: ", e)
      dispatch(setToast({message: "Uh oh. We had an issue saving your decal, please try again.", isError: true}))
    }

  }, [handleUpdateProject, dispatch])

  const handleDecalDrop = useCallback(async (params) => {

    // Create full decal params
    const {id, src, created_ts} = decalPicker
    const decal = {id, src, created_ts, ...params}
    setDecalPicker(false)
    setDisableZoom(false)

    // Update decal in scene with id "__decal" to the existing id
    const updatedMesh = sceneRef.current.meshes.find((m) => m.id === "__decal")
    if (updatedMesh) {
      updatedMesh.id = id
    }

    // Update decal size, position, etc in project
    const updatedDecals = {...projectRef.current.decals}
    updatedDecals[decal.id] = decal

    // Update
    const updates = {decals: updatedDecals}
    
    // Add to ref
    decalsRef.current = [...decalsRef.current, decal]
    
    // Update project
    try {
      await handleUpdateProject(updates)
    }
    catch (e) {
      console.error("Error updating decal: ", e)
      dispatch(setToast({message: "Uh oh. We had an issue saving your decal, please try again.", isError: true}))
    }

  }, [decalPicker, handleUpdateProject, dispatch])

  const handleSaveReferencePhotoDimensions = async (params) => {
    // Cloud icon
    dispatch(setProjectSaving({saving: true, caller: "referencePhoto"}))
    
    // Update project
    try {
      await updateProject({projectId: projectRef.current.id, body: {reference_photo: JSON.stringify(params)}}).unwrap()
      dispatch(setProjectSaving({saving: false, caller: "referencePhoto"}))
    }
    catch (e) {
      // Fail silently...
      console.error("Error saving reference photo preferences:", e)
      dispatch(setProjectSaving({saving: false, caller: "referencePhoto"}))
    }
  }

  const updateFinishedTours = async (tours) => {
    try {
      const md = user['https://thdy/user_md'] ? {...user['https://thdy/user_md']} : {}
      md.finishedTours = tours
      if(ALL_TOURS.every(tour => tours.includes(tour))) {
        md.onboarded = true
      }
      const params = {user_metadata: md}
      await updateUserRequest({body: params}).unwrap()

      // Update session
      Session.getAccessTokenFromRefreshToken().then((res) => {
        const { access_token, user } = res
        dispatch(login({user, access_token}))
      }, (e) => {
        console.log('Error updating session: ', e)
      })
    } 
    catch (e) {
      console.log("Error updating user: ", e)

      // We won't show the user as it is not something they should worry about
    }
  }

  const joyrideCallback = (data) => {
    const { action, index, status, type, lifecycle } = data;
    /*
    Lifecycle on clicking NEXT button:
    1. "step:after" --> currentIndex/Step --> "next"
    2. "step:before" --> nextIndex/Step --> "next"
    3. "tooltip" --> nextIndex/Step --> "update"
    4. REPEAT
    */

    if ([JOYRIDE_EVENTS.STEP_AFTER, JOYRIDE_EVENTS.TARGET_NOT_FOUND].includes(type)) {
      if (activeTour === 'TOUR3' && type === 'step:after' && index === 0 && action === 'next'){
        handleToggleListMenu('createdMaterials')
      } else if (activeTour === 'TOUR3' && type === 'step:after' && index === 1 && action === 'next'){
        handleToggleListMenu('lighting')
      } else if (activeTour === 'TOUR3' && type === 'step:after' && index === 2 && action === 'next'){
        handleToggleListMenu(false)
      }
      // Update state to advance the tour
      dispatch(setTourStepIndex(index + (action === ACTIONS.PREV ? -1 : 1)))
    }
    // If skipped or finished or user clicked somewhere else during tour, then stop the tour
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status) || (type === 'beacon' && lifecycle === 'beacon')) {
      // Add Tour3 to Finished Tours + Inactivate Tour3
      if (activeTour === 'TOUR3'){
        dispatch(setFinishedTours(['TOUR3'])) // Finish TOur3
        updateFinishedTours([...(user['https://thdy/user_md'].finishedTours || []), 'TOUR3']) // Update in the backend
        dispatch(setActiveTour(false)) // No active tour
        handleToggleListMenu(false) // Close all the opened panels
      }
      // Need to set our running state to false, so we can restart if we click start again.
      dispatch(setRunTour(false))
      dispatch(setTourStepIndex(0)) // Reset tour step
    }
  }

  // Handle any changes that needs to be done after some action in Heaader
  const handleAfterEffectOfHeader = (actions) => {
    if (actions.includes(T.CLOSE_CONFIGURATOR)) {
      closeConfiguratorBuilder()
      return
    }
  }

  const replayTour = () => {
    resetTheProject()
    setIsOpen(true)
    setAskToRestartSampleTour(false)
  }

  const handleRequestNewVariation = async (newVariationParams) => {
    setShowNewVariation(false)

    // Add parts to variation params
    const variationParts = []
    parts.forEach((p) => {
      const { personal, id } = p.material
      variationParts.push({id: p.id, material: {personal, id}})
    })

    // Save
    const completeNewVariationParams = {...newVariationParams, parts: variationParts}
    const variations = projectRef.current.variations ? projectRef.current.variations : []
    const updates = {variations: [...variations, completeNewVariationParams]}
    await handleUpdateProject(updates, false, "newVariation")

    // Open menu
    if (showListMenu !== "variations") {
      handleToggleListMenu("variations")
    }
  }

  const handleUpdateVariations = (updatedVariations) => {
    projectRef.current = {...projectRef.current, variations: updatedVariations}

    // If the current selected variation does not exist anymore, we'll need to reset the model
    if (!updatedVariations.find((v) => v.id === selectedVariationId)) {
      // Default to next variation if it exists
      if (updatedVariations.length > 0) {
        handleSelectVariation(updatedVariations[updatedVariations.length-1].id)
      }
      else {
        // Otherwise, reset the model to defaults
        resetAllPartsToInitial()
      }
    }
  }

  const handleSelectVariation = (variationId) => {
    // Now set materials
    return new Promise(async (resolve) => {
      const variation = projectRef.current.variations.find((v) => v.id === variationId)
      if (!variation) {
        return resolve()
      }

      if (variation.parts && variation.parts.length > 0) {
        setLoading(true)

        for (let i = 0; i < variation.parts.length; i++) {
          const p = variation.parts[i]
          const partMat = await MiscHelperMethods.getProjectMaterialForPart(projectRef.current.materials, p.material.id, apiCacheRef.current)
          const destinationPart = getPartOrGroup(p.id)
          handleSetMaterial(destinationPart, partMat)
        }

        setLoading(false)
      }

      setSelectedVariationId(variationId)
      resolve()
    })
  }

  const resetAllPartsToInitial = () => {
    parts.forEach((p) => {
      if (initialMaterialsRef.current[p.id]) {
        const destinationPart = getPartOrGroup(p.id)
        handleSetMaterial(destinationPart, initialMaterialsRef.current[p.id])
      }
    })
  }

  // Add color material to the last used materials list
  useEffect(() => {
    if (newColorMaterial) {
      addAndUpdateLastUsedMaterial(newColorMaterial)
      setNewColorMaterial(false)
    }
  }, [newColorMaterial, addAndUpdateLastUsedMaterial])
  

  const handleSaveColorTransfer = useCallback((ctObj) => {

    return new Promise(async (resolve) => {    
      const {part, material: ctMaterial} = ctObj
      // console.log("test handleSaveColorTransfer", part, material)

      // First, we'll get the latest part
      const thePart = getPartOrGroup(part[0].id)

      try {

        const {material, color} = ctMaterial

        // Validate that the material is still valid (i.e it could have been undo'd) before saving
        if (thePart.material && thePart.material.id === material.id) {
          // await handleSaveMaterial(material, !material.meta.personal, thePart, true, true, false, undefined, true)
        }
        else {
          console.error("CT material no longer valid")
          return resolve(false)
        }

        // Save
        const {original_material_id, original_material_user_visible, personal} = material.meta
        const params = {
          name: material.name,
          original_material_id,
          original_material_user_visible,
          personal,
          model_id: projectRef.current.model_id,
          id: material.id,
          color_rgb: color
        }

        // Go
        const newColoredMaterial = await doColorTransferInCollection(params).unwrap()

        // Add to PC
        dispatch(addToPersonalCollection(material))

        // Add to Last Used Materials
        setNewColorMaterial(newColoredMaterial)
        resolve(thePart.material.id)
      }
      catch (e) {
        console.error("Error creating color transfer material: ", e)
        if (e.data?.data === 'INSUFFICIENT') {
          setShowNoSpaceAvailableModal(true)
        } else {
          dispatch(setToast({ message: "Uh oh. We had an error transferring color to the selected material. Please try again", isError: true }))
        }
        
        resolve(false)
      }
    })

  }, [dispatch, getPartOrGroup, doColorTransferInCollection])

  const handleCopy = useCallback((type, partId) => {
    copyRef.current = {type, partId}
    setSelectionMenu(false)
  }, [])
  
  const handlePaste = useCallback(async (destinationPartId) => {
    console.log("handlePaste", destinationPartId)
    setSelectionMenu(false)
    if (!copyRef.current) {
      console.error("No copyRef found")
      return
    }

    // Handle following copy actions: copyAll, copyMaterial, copyMaterialTexture, copyPattern
    const {type, partId} = copyRef.current
    const part = getPartOrGroup(partId)
    const destinationPart = getPartOrGroup(destinationPartId)
    if (!part || !destinationPart) {
      console.error("No source or dest part found")
      return
    }

    // Prepare uv settings
    const meta = part.meta ? part.meta : {}
    const {uScale, vScale, uOffset, vOffset, rotation} = meta
    const hasUV = uScale !== DEFAULT_UV_PROPERTIES.uScale || vScale !== DEFAULT_UV_PROPERTIES.vScale || uOffset !== DEFAULT_UV_PROPERTIES.uOffset || vOffset !== DEFAULT_UV_PROPERTIES.vOffset || rotation !== DEFAULT_UV_PROPERTIES.rotation
    const shouldCopyUV = hasUV && (type === 'copyAll' || type === 'copyPattern')
    const destExMeta = destinationPart.meta ? destinationPart.meta : {}
    const destMeta = {...destExMeta, uScale, vScale, uOffset, vOffset, rotation}

    // Setup history event
    const historyParams = {type: HISTORY_EVENT_TYPES.PASTE, params: {actions: []}}

    // Full material / texture
    let matId = destinationPart.material.id
    if ((type === 'copyAll' || type === 'copyMaterial' || type === 'copyMaterialTexture') && part.material) {
      matId = part.material.id

      if (type === 'copyMaterialTexture' && part.material.meta) {
        const {original_material_id} = part.material.meta
        matId = original_material_id
      }

      const mat = await MiscHelperMethods.getProjectMaterialForPart(projectRef.current.materials, matId, apiCacheRef.current)

      // Fail
      if (!mat) {
        console.error("Valid material not found")
        return
      }

      await handleSetMaterial(destinationPart, mat, destMeta, !shouldCopyUV)
      const {historyEvent} = await handleSaveMaterial(mat, false, [destinationPart], true)
      historyParams.params.actions.push(historyEvent)
    }
    // Pattern
    else if (hasUV && type === 'copyPattern') {
      const {historyEvent} = await handleSaveMaterialSettings(destinationPartId, matId, destMeta, true, true)
      historyParams.params.actions.push(historyEvent)
    }

    // Done
    if (historyParams.params.actions.length > 0) {
      dispatch(addToHistory(historyParams))
    }

  }, [dispatch, getPartOrGroup, handleSaveMaterial, handleSetMaterial, handleSaveMaterialSettings])

  const setShiftRef = useCallback((holding) => isHoldingShiftRef.current = holding, [])
  const setCmdRef = useCallback((holding) => isHoldingCmdRef.current = holding, [])
  const setCtrlRef = useCallback((holding) => isHoldingCtrlRef.current = holding, [])

  const keyBindingsParams = [
    projectRef.current, props.publicView, keysEnabled, handleToggleListMenu, setShiftRef,
    setCtrlRef, setCmdRef, setSpecialAction, setSelectionMenu,
    handlePaste, handleCopy, selectedParts, copyRef.current, sceneRef.current, lastClickOrigin.current,
    isRightClick.current
  ]

  useKeyBindings(...keyBindingsParams)

  const handleQueueColorTransfer = (ct) => {
    console.log("handleQueueColorTransfer", ct)
    colorTransferQueueRef.current.push(ct)
  }

  const handleTogglePreview = () => {
    const next = !isPreviewing
    
    if (!next) {
      document.dispatchEvent(new CustomEvent(EVENTS.SWITCH_RESOLUTION, {detail: "1k"}))
    }
    else {
      // reset any selections
      setHoveredPart(false)
      setSelectedParts([])
    }

    setTimeout(() => setIsPreviewing(next))
  }

  // Check to see if there's any pending color transfers
  useEffect(() => {

    const handleClearColorTransferQueue = async () => {
      // Find any in queue that match current parts
      if (colorTransferQueueRef.current.length > 0) {
        console.log('clearColorTransferQueue', colorTransferQueueRef.current)
        
        // Let's time it
        const p1 = performance.now()

        // Start save
        dispatch(setProjectSaving({saving: true, caller: "ct"}))

        const newMats = []
        const promises = []
        const clearedMatIds = []
        for (let i = 0; i < colorTransferQueueRef.current.length; i++) {
          const ct = colorTransferQueueRef.current[i]
          console.log('save color transfer: ', ct)
          const part = getPartOrGroup(ct.partId)
          const id = ct.material.material.id
          newMats.push({partId: ct.partId, id,  material: {id, external: true, personal: true}})
          promises.push(handleSaveColorTransfer({part: [part], material: ct.material}))
        }

        // Create materials in parallel
        const results = await Promise.all(promises)
        const successfulNewMats = []
        results.forEach((r) => {
          if (r && newMats.find((nm) => nm.id === r)) {
            successfulNewMats.push(newMats.find((nm) => nm.id === r))
            clearedMatIds.push(r)
          }
        })

        // Update project
        if (Object.keys(successfulNewMats).length > 0) {
          const updatedParts = [...parts].map((p) => {
            const aMat = successfulNewMats.find((snm) => snm.partId === p.id)
            if (aMat) {
              return {...p, material: aMat.material}
            }

            return p
          }) 

          await handleUpdateProject({parts: updatedParts})
        }
        else {
          console.log("Skipping project update...")
        }

        // Done
        dispatch(setProjectSaving({saving: false, caller: "ct"}))
        const p2 = performance.now()
        console.log("Took: " + (p2-p1) + "ms")

        if (clearedMatIds.length > 0) {
          colorTransferQueueRef.current = [...colorTransferQueueRef.current].filter((c) => clearedMatIds.indexOf(c.material.material.id) < 0)
        }
      }
    }

    document.addEventListener(EVENTS.CLEAR_COLOR_TRANSFER_QUEUE, handleClearColorTransferQueue)

    return () => document.removeEventListener(EVENTS.CLEAR_COLOR_TRANSFER_QUEUE, handleClearColorTransferQueue)

  }, [parts, getPartOrGroup, handleSaveColorTransfer, handleUpdateProject, dispatch])
    
  // Use unload event hook
  useUnload(projectSaving.saving)

  // Use undo / redo (history) hook
  useWorkspaceHistory(apiCacheRef.current, parts, handleSetMaterial, handleSaveMaterial, handleSaveMaterialSettings, projectRef.current, selectedPartForEditMaterial)

  // Listen for export
  useCreateExport(sceneRef.current, projectRef.current, access_token, handleSelectVariation)

  // Use higher res material fetching
  // useHighResMaterialFetching(apiCacheRef.current, parts, props.shared, sceneRef.current, customizedMaterialsRef.current)
  useHighResMaterialFetching(sceneRef.current, projectRef.current, parts, props.shared, customizedMaterialsRef.current)

  // Use decal dropper component
  useDecalDropper(sceneRef.current, decalPicker, handleDecalDrop)

  // Use decal mover component
  useDecalMover(sceneRef.current, decalsRef.current, handleDecalUpdate)

  return (
    <div className="toggle-editor">
      {
        runTour && activeTour === 'TOUR3' && (
          <ReactJoyride
            steps={JOYRIDE_STEPS.TOUR3}
            run={runTour}
            continuous={true}
            callback={joyrideCallback}
            showSkipButton={true}
            hideBackButton={true}
            hideCloseButton={true}
            disableScrollParentFix={true}
            stepIndex={tourStepIndex}
            styles={JOYRIDE_STYLE}
            locale={JOYRIDE_LOCALE}
            floaterProps={JOYRIDE_TOOLTIP_STYLES}
          />
        )
      }
      {/* <HeaderComponent shared={props.shared} publicView={props.publicView} onAfterChange={handleAfterEffectOfHeader} /> */}

      {/* For public viewing, let's display our options */}
      {configurations && configurations.length > 0 && 
        configurations[0].options && configurations[0].options.length > 0 && 
        (props.publicView || isPreviewing) && (
        <PublicConfiguratorOptionsComponent 
          rawMaterials={defaultMaterialsRef.current} 
          configurations={configurations} 
          onPreviewOption={handleSetMaterial} 
          selectedOptions={selectedOptions.current}
          defaultCollapsed={isPreviewing}
         /> 
      )}

      {loading && <SpinnerComponent />}
      {/* Element for wrapping the model inside the canvas so tour can recognize */}
      <div className='pseudo-model-wrapper'></div>
      { !isPreviewing && askToRestartSampleTour && <button onClick={replayTour} className="primary-btn replay-tour-btn">{replay} Replay Tour</button> }
      {showArLoader && <ARLoaderComponent project={projectRef.current} scene={sceneRef.current} />}
      {showMaterialImport && <MaterialImportModalComponent items={showMaterialImport.items} modelId={showMaterialImport.modelId} onClose={() => setShowMaterialImport(false)} />}
      {showViewerHelpSync && <ViewerHelpModalComponent />}
      {showViewerShareSync && <ViewerShareModalComponent />}
      {showViewerArSync && <ViewerARModalComponent />}
      {stillProcessing && <ModelProcessingComponent lastCheck={lastProcessingCheck} />}
      {showNewVariation && <AddVariationModalComponent onClose={() => setShowNewVariation(false)} project={projectRef.current} onRequestVariation={handleRequestNewVariation} /> }
      {didError && <div className="threedy-lab-error">{errorMessage}</div>}
      {!props.shared && !stillProcessing && !isPreviewing && projectRef.current && (
        <ReferencePhotoComponent 
          photo={projectRef.current.reference_photo} 
          projectId={projectRef.current.id}
          projectType={projectRef.current.project_type}
          onSaveDimensions={handleSaveReferencePhotoDimensions} 
        />
      )}
      
      <div className="toggle-editor-canvas">
        <canvas id="scene" touch-action="none" ref={canvasRef}/>
      </div>
      {!props.shared && specialAction && !isPreviewing && <div className="threedy-lab-workspace-special-action">{specialAction}</div>}
      {/* {!props.publicView && (
        <ul className="threedy-lab-workspace-menu">
          { !props.shared && MiscHelperMethods.isConfiguratorOr3dOrVariationsProject(projectRef.current) && 
            (
              <li>
                <p className="zoom-label fw-500">Preview</p>
                <div className="toggle-flex">
                  <div onClick={handleTogglePreview} className={isPreviewing ? "threedy-toggle selected" : "threedy-toggle"}><span><i>{isPreviewing ? "On" : "Off"}</i></span></div>
                  { MiscHelperMethods.isConfiguratorOr3dOrVariationsProject(projectRef.current) && isPreviewing && <PreviewResolutionDropdownComponent /> }
                </div>
              </li>
            )
          }
          {
            !props.shared && MiscHelperMethods.isVariationsProject(projectRef.current) && (
              <div className="variations-wrapper">
                <p className="zoom-label fw-500">Variations</p>
                <button className="variations-btn primary-btn" onClick={ () => setShowNewVariation(true)}><span className="supp-icon-plus-square"></span> Add New</button>
              </div>
            )
          }
          <li>
            <p className="zoom-label fw-500">Zoom <span className="icon-help-circle" onClick={showViewerHelp}></span></p>
            <div className="zoom-wrapper">
              <button disabled={!isZoomOutEnabled} className="zoom-btn" onClick={ () => handleZoom('decrement')}><span >&#8722;</span></button>
              <div className="zoom-percentage">{zoom.level}%</div>
              <button disabled={!isZoomInEnabled} className="zoom-btn" onClick={ () => handleZoom('increment')}><span >&#43;</span></button>
            </div>
          </li>
        </ul>
      )} */}
      {/* <ul className="threedy-lab-workspace-menu actions-menu">
        {!props.publicView && !isPreviewing && projectRef.current && MiscHelperMethods.isConfiguratorOr3dOrVariationsProject(projectRef.current) && (
          <>
            <li><button className={showListMenu === 'parts' ? 'open-parts-btn selected' : 'open-parts-btn'} onClick={() => handleToggleListMenu('parts')}>{getProjectIcon(T._3D_MODEL)}<div className="threedy-menu-tip">{showListMenu === 'parts' ? 'Hide' : 'Show'} Elements (E)</div></button></li>
            {
              MiscHelperMethods.isConfiguratorProject(projectRef.current) && (
                <li><button className={showListMenu === 'configure' ? 'selected' : ''} onClick={() => handleToggleListMenu('configure')}>{getProjectIcon(T.CONFIGURATOR)}<div className="threedy-menu-tip">{showListMenu === 'configure' ? 'Hide' : 'Show'} Options (O)</div></button></li>
              )
            }
            {
              MiscHelperMethods.isVariationsProject(projectRef.current) && (
                <li><button className={showListMenu === 'variations' ? 'selected' : ''} onClick={() => handleToggleListMenu('variations')}>{getProjectIcon(T.VARIATIONS)}<div className="threedy-menu-tip">{showListMenu === 'variations' ? 'Hide' : 'Show'} Variations (V)</div></button></li>
              )
            }
            <li><button className={showListMenu === 'tools' ? 'selected' : ''} onClick={() => handleToggleListMenu('tools')}><span className="icon-zap"></span><div className="threedy-menu-tip">{showListMenu === 'tools' ? 'Hide' : 'Show'} Scene Tools</div></button></li>
          </>
        )}
        {!props.publicView && !isPreviewing && projectRef.current && projectRef.current.project_type === 'material' && (
          <>
            <li><button className={showListMenu === 'material' ? 'open-material-builder-btn selected' : 'open-material-builder-btn'} onClick={() => handleToggleListMenu('material')}><span>{materialBuilder}</span><div className="threedy-menu-tip">{showListMenu === 'material' ? 'Hide' : 'Show'} Material Builder (M)</div></button></li>
            <li><button className={showListMenu === 'createdMaterials' ? 'open-options-list-btn selected' : 'open-options-list-btn'} onClick={() => handleToggleListMenu('createdMaterials')}><span>{createdMaterials()}</span><div className="threedy-menu-tip">{showListMenu === 'createdMaterials' ? 'Hide' : 'Show'} Created Materials</div></button></li>
          </>
        )}  
        {!props.shared && !isPreviewing && <li><button className={showListMenu === 'lighting' ? 'open-lighting-btn selected' : 'open-lighting-btn'} onClick={() => handleToggleListMenu('lighting')}><span>{lighting}</span><div className="threedy-menu-tip">{showListMenu === 'lighting' ? 'Hide' : 'Show'} Lighting (L)</div></button></li>}
      </ul> */}

      { !props.publicView && !isPreviewing && (
        <ToolsMenuComponent 
          project={projectRef.current} 
          selectedToolId={showListMenu} 
          onChangeTool={handleToggleListMenu} 
          tools={{
            elements: <PartViewerComponent
              ref={partViewerRef} 
              selections={selectedParts} 
              editing={selectedPartForEditMaterial}
              invisible={invisible} 
              hovering={hoveredPart} 
              items={parts} 
              setParts={setParts}
              saveSortedParts={saveSortedParts}
              onRequestNewConfiguration={handleCreateConfiguration}
              onSetPartHover={handleMeshHover} 
              onZoomToPart={handleZoomToPart} 
              onVisibilityChange={handleVisibilityRequest} 
              onCreateGroup={handleGroup}
              removePartFromGroup={handleRemovePartFromGroup}
              getPartOrGroup={getPartOrGroup} 
              onRequestUngroup={handleUngroup}
              onUpdateItem={handleUpdateItem}
              onSelectPart={handlePartClick}
              shared={props.shared}
              showCannotRemovePartModal={showCannotRemovePartModal}
              setShowCannotRemovePartModal={setShowCannotRemovePartModal}
              cmdKeyPressed={isHoldingCmdRef.current}
              shiftKeyPressed={isHoldingShiftRef.current}
              ctrlKeyPressed={isHoldingCtrlRef.current}
            />,
            configurator: <ConfiguratorViewerComponent
              parts={parts} 
              items={configurations} 
              onCreate={handleCreateConfiguration}
              onUpdate={handleUpdateConfiguration}
              onDeleteVariation={handleDeleteConfiguration}
              onDeleteVariant={handleDeleteConfigurationOption}
              selectedOptions={selectedOptions.current}
              onPreviewOption={handleSetMaterial}
              shared={props.shared}
              cachedMaterials={apiCacheRef.current}
              onCacheMaterial={handleCacheMaterial}
            />,
            variations: <VariationsComponent 
              variations={projectRef.current && projectRef.current.variations} 
              projectId={projectRef.current && projectRef.current.id} 
              onUpdateVariations={handleUpdateVariations} 
              onSelectVariation={handleSelectVariation}
              selectedVariationId={selectedVariationId}
              disableEditing={props.shared || props.publicView}
            />,
            lighting: <LightingPanelComponent onUpdateLighting={handleUpdateLighting} />,
            materialBuilder: <MaterialBuilderComponent 
              onRequestScreenshot={handleGenerateScreenshot} 
              onDone={handleCloseListMenu} 
              scene={sceneRef.current} 
            />,
            materialViewer: <MaterialViewerComponent 
              onRequestScreenshot={handleGenerateScreenshot} 
              createdMaterials={createdMaterialsRef.current} 
              scene={sceneRef.current}
            /> 
          }}
        /> 
      )}

      {selectionMenu && selectedParts.length > 0 && !isPreviewing && (
        <SelectionMenuComponent 
          top={selectionMenu.top} 
          left={selectionMenu.left} 
          onVisibilityChange={handleVisibilityRequest} 
          parts={parts} 
          selected={selectedParts} 
          onShowCreateGroup={handleGroup} 
          onRequestUngroup={handleUngroup} 
          onRequestCopy={handleCopy}
          onRequestPaste={handlePaste}
          copyRef={copyRef.current}
          invisible={invisible} 
          scene={sceneRef.current}
        />
      )}

      {selectedPartForEditMaterial && (
        <ElementConfigurationComponent 
          elements={selectedPartForEditMaterial} 
          project={projectRef.current} 
          materials={projectRef.current.materials} 
          apiCache={apiCacheRef.current} 
          onSaveMaterialSettings={handleSaveMaterialSettings}
          onSetMaterial={handleSetMaterial}
          onCacheMaterial={handleCacheMaterial}
          onQueueColorTransfer={handleQueueColorTransfer}
          onSaveMaterial={handleSaveMaterial}
          onDeleteDecal={handleDeleteDecal}
          onDropDecal={handleStartDecalDrop}
        />
      )}
      
      {/* {
        selectedPartForEditMaterial && !isPreviewing && (

          <ConfiguratorBuilderComponent 
            part={selectedPartForEditMaterial}
            configurations={configurations}
            onCacheMaterial={handleCacheMaterial}
            onSaveMaterial={handleSaveMaterial}
            onCreateConfiguration={handleCreateConfiguration}
            selectedOptions={selectedOptions.current}
            onCreateOption={handleCreateOption}
            onClose={closeConfiguratorBuilder}
            projectMeta={projectRef.current.meta}
            projectType={projectRef.current.project_type}
            cachedMaterials={apiCacheRef.current}
            onPreviewOption={handleSetMaterial}
            scene={sceneRef.current}
            saveMaterialSettings={handleSaveMaterialSettings}
            onSaveColorTransfer={handleSaveColorTransfer}
            onQueueColorTransfer={handleQueueColorTransfer}
            customizedMaterials={customizedMaterialsRef.current}
          />
        )
      } */}

      {/* {selectedPartForEditMaterial && !isPreviewing && !showListMenu && (
        <PartEditingLabelComponent part={selectedPartForEditMaterial} />
      )} */}

      {showNoSpaceAvailableModal && <NoSpaceAvailableModalComponent plan={plan} close={() => setShowNoSpaceAvailableModal(false)} />}
    </div>
  )
}

export default WorkspacePage
