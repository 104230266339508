import { useCallback, useState } from "react"
import { useDispatch } from "react-redux"
import { useAddNewProjectMutation } from "../../redux/api.redux.slice"
import { hideNewProjectModal, setToast } from "../../redux/ui.redux.slice"
import ModalComponent from "../modal/modal.component"
import { useNavigate } from "react-router-dom"
import { KLAVIYO_METRICS } from "../../constants"

const NewProjectModalComponent = (props) => {
  const dispatch = useDispatch()
  const [newProject, setNewProject] = useState({name: ""})
  const navigate = useNavigate()
  const [addProject, {isLoading}] = useAddNewProjectMutation() 

  const handleClose = useCallback(() => {
    dispatch(hideNewProjectModal())
  }, [dispatch])

  const handleCreateProject = async () => {
    const params = {...newProject}
    try {
      const res = await addProject(params).unwrap()
      window.klaviyo.track(KLAVIYO_METRICS.created_project)
      handleClose()

      // Navigate to project
      navigate(`/editor/${res.data.id}`)
    }
    catch (e) {
      console.log("Error adding project", e)
      dispatch(setToast({message: "Uh oh. We had an issue creating a new project, please try again.", isError: true}))
    }
  }

  const modalContent = (
    <>
      <div className="threedy-lab-text-input">
        <h4>Name: </h4>
        <input type="text" placeholder="Project Name..." value={newProject.name} autoFocus onChange={(e) => setNewProject({...newProject, name: e.target.value})} />
      </div>
    </>
  )

  const modalActions = (
    <>
      <button onClick={handleClose}>Cancel</button>
      <button className="primary-btn" disabled={!newProject.name || isLoading} onClick={handleCreateProject}>Create</button>
    </>
  )

  return (
    <ModalComponent title="New Project" actions={modalActions} content={modalContent} close={handleClose} />
  )
}

export default NewProjectModalComponent