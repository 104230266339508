import { useState } from "react"
import { useSyncAritize3DModelMutation } from "../../redux/api.redux.slice"
import ModalComponent from "../modal/modal.component"
import { useDispatch } from "react-redux"
import { setToast } from "../../redux/ui.redux.slice"

const OutdatedModelModalComponent = (props) => {
  const { close, done, lastUpload, modelId } = props
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const [doSyncRequest] = useSyncAritize3DModelMutation()

  const handleSyncAction = async (action) => {
    setLoading(true)

    try {
      const params = {
        action,
        lastUpload
      }

      await doSyncRequest({id: modelId, body: params}).unwrap()
      done(action === 'update')
    }
    catch (e) {
      console.error(e)
      setLoading(false)
      dispatch(setToast({message: "Uh oh. We had an issue making that selection. Please try again", isError: true}))
    }
  }

  const modalContent = (
    <div className={loading ? "threedy-lab-skeleton" : ""}>
      <p>It looks like the model you selected has been updated outside of Toggle3D. Would you like to continue working on your local version or update to match?</p>
    </div>
  )

  const modalActions = (
    <>
      <button disabled={loading} onClick={() => handleSyncAction('skip')}>Continue working on my version</button>
      <button disabled={loading} className="primary-btn" onClick={() => handleSyncAction('update')}>{loading ? "Loading..." : "Update my model"}</button>
    </>
  )

  return (
    <ModalComponent title="The model you selected is out of date" content={modalContent} actions={modalActions} close={close} />
  )
}

export default OutdatedModelModalComponent