const ElementTexturePane = (props) => {

  const {appliedMaterial, isShowingMaterialLibrary, onShowMaterialLibrary} = props
 
  return (
    <>
      <div className="toggle-element-configuration-section">
        <h6>Applied Texture</h6>
        <div className="toggle-element-applied-texture">
          <div className="toggle-element-applied-texture-thumb-container">
            {appliedMaterial && appliedMaterial.thumb && <img src={appliedMaterial.thumb} alt={appliedMaterial.name} />}
          </div>
          <span>{appliedMaterial && appliedMaterial.name ? appliedMaterial.name : "None"}</span>
        </div>
      </div>
      <div className="toggle-element-configuration-section">
        <h6>Texture Library</h6>
        <button className="toggle-outline-btn" onClick={() => onShowMaterialLibrary(!isShowingMaterialLibrary)}>{isShowingMaterialLibrary ? "Close" : "Open"} Library</button>
      </div>
    </>
  )
}

export default ElementTexturePane