import { COOKIE_ACCEPTANCE_KEY } from "../../constants"
import Session from "../../utils/session.util"
import ModalComponent from "../modal/modal.component"

const CookiePolicyModalComponent = (props) => {

  const content = (
    <>
      <p>This website stores cookies on your computer. These cookies are used to collect information about how you interact with our website and allow us to remember you. 
        We use this information in order to improve and customize your browsing experience and for analytics and metrics about our visitors both on this website and other media. 
        To find out more about the cookies we use, see our <a href="https://www.nextechar.com/nextech-ar-solutions-privacy-policy" target="_blank" rel="noreferrer"><strong>Privacy Policy</strong></a>.</p>
      <p>If you decline, your information won’t be tracked when you visit this website. A single cookie will be used in your browser to remember your preference not to be tracked.</p>
    </>
  )

  const modalActions = (
    <>
      <button className="toggle-default-btn" onClick={() => handleClose(false)}>Decline</button>
      <button className="toggle-primary-btn" onClick={() => handleClose(true)}>Accept</button>
    </>
  )

  const handleClose = (acceptOrReject) => {
    Session.setCookie(COOKIE_ACCEPTANCE_KEY, acceptOrReject.toString(), 6)
    props.onClose()
  }

  return (
    <ModalComponent title="Toggle3D Cookie Notice" actions={modalActions} content={content} hideClose />
  )
}

export default CookiePolicyModalComponent