import ModalComponent from "../modal/modal.component"

const SimpleAlertModalComponent = (props) => {
    const { buttonTitle } = props
    const modalContent = (
        <>
            <p>{props.subTitle}</p>
        </>
    )
    const actions = (
        <>
            <button className="toggle-default-btn" onClick={() => props.close()}>
                {buttonTitle || `Close`}
            </button>
        </>
    )
    return (
        <ModalComponent title={props.title}
            close={() => props.close()}
            content={modalContent}
            className="small"
            actions={actions} />
    )
}

export default SimpleAlertModalComponent