import { useState, useEffect } from "react"
import { Link } from "react-router-dom";
import { equalizer, image, lighting, materialBuilder, texture } from "../../assets";
import ModalComponent from "../../components/modal/modal.component"
import { APP_RESOURCES_URL } from "../../constants"
import './help.page.css';

const getVideoLinksInfo = [
  {
    id: 1,
    name: "uploading",
    title: "Uploading a File",
    subTitle: "Learn how to upload a CAD file or 3D model",
    videoLink: "help/uploading-file.mp4",
    leftImageURL: "help/1.1-upload.svg",
    rightImageURL: "help/1.2-upload.svg"
  },
  {
    id: 11,
    name: "Navigation",
    title: "Scene Navigation",
    subTitle: "Learn how to navigate the scene",
    videoLink: "help/navigation.mp4",
    leftImageSvg: materialBuilder
  },
  {
    id: 4,
    name: "grouping",
    title: "Grouping Elements",
    subTitle: "Learn how to group elements on a model",
    videoLink: "help/grouping-parts.mp4",
    leftImageURL: "help/4.1-group.svg",
    rightImageURL: "help/4.2-group.svg"
  },
  {
    id: 3,
    name: "renaming",
    title: "Selecting/Editing Elements",
    subTitle: "Learn how to select and rename elements on a model",
    videoLink: "help/select-rename.mp4",
    leftImageURL: "help/3.1-rename.svg",
    rightImageURL: "help/3.2-rename.svg"
  },
  {
    id: 5,
    name: "materials",
    title: "Updating Materials",
    subTitle: "Learn how to update/edit materials on a model",
    videoLink: "help/editing-or-adding-materials.mp4",
    leftImageURL: "help/5.1-update.svg",
    rightImageURL: "help/5.2-update.svg"
  },
  {
    id: 7,
    name: "UV Patterns",
    title: "How to edit patterns",
    subTitle: "Learn how to adjust the UV settings for a mesh",
    videoLink: "help/uv-settings.mp4",
    leftImageSvg: texture
  },
  {
    id: 8,
    name: "Lighting",
    title: "Updating the Lighting",
    subTitle: "Learn how to change the lighting in your design scene",
    videoLink: "help/lighting.mp4",
    leftImageSvg: lighting
  },
  {
    id: 9,
    name: "Advanced Material Settings",
    title: "Advanced Material Settings",
    subTitle: "Learn how to customize properties of a material",
    videoLink: "help/mat-settings.mp4",
    leftImageSvg: equalizer
  },
  {
    id: 6,
    name: "Configurator",
    title: "Creating a Configurator",
    subTitle: "Learn how to create a configuration of a model",
    videoLink: "help/creating-configurator.mp4",
    leftImageURL: "help/6.1-create.svg",
    rightImageURL: "help/6.2-create.svg"
  },
  {
    id: 10,
    name: "Material Searech",
    title: "Material Image Search",
    subTitle: "Learn how to search for a material by uploading a reference image",
    videoLink: "help/image-search.mp4",
    leftImageSvg: image
  },
]

const HelpPage = () => {

  const [showVideoModal, setShowVideoModal] = useState(false)

  const handleShowClip = (section) => {
    const params = {
      title: section.title,
      src: `${APP_RESOURCES_URL}${section.videoLink}`,
    }
    setShowVideoModal(params)
  }

    // Handle state params on load
    useEffect(() => {

      const params = new URLSearchParams(window.location.search)
      const d = params.get("d")
      if (!d) {
        return
      }

      if (d === "uploading") {
        setTimeout(() => {
          handleShowClip(getVideoLinksInfo[0])
          window.history.replaceState({}, '', window.location.pathname)
        }, 50)
        return
      }

      if (d === "Navigation") {
        setTimeout(() => {
          handleShowClip(getVideoLinksInfo[1])
          window.history.replaceState({}, '', window.location.pathname)
        }, 50)
        return
      }

      if (d === "grouping") {
        setTimeout(() => {
          handleShowClip(getVideoLinksInfo[2])
          window.history.replaceState({}, '', window.location.pathname)
        }, 50)
        return
      }

      if (d === "renaming") {
        setTimeout(() => {
          handleShowClip(getVideoLinksInfo[3])
          window.history.replaceState({}, '', window.location.pathname)
        }, 50)
        return
      }
  
    }, [])

  const modalContent = (
    <div className="video-wrapper">
      <video controls muted src={showVideoModal.src} autoPlay />
    </div>
  )

  const modalActions = <button onClick={() => setShowVideoModal(false)} className="outline-btn">Close</button>

  return (
    <>
      <div className="threedy-lab-page-content">
        {showVideoModal && <ModalComponent title={showVideoModal.title} close={() => setShowVideoModal(false)} content={modalContent} actions={modalActions} />}
        <div className="threedy-lab-page-header">
          <h1 className="pb-10 fs-24 fw-700">Help & Support</h1>
          <h4 className="primary-color fw-700 fs-16 mt-30 mb-30">Select a topic below to learn more about using Toggle3D</h4>
        </div>
        <div className="threedy-card-container">
          {getVideoLinksInfo.map((v) => {
            return (
              <div className={v.rightImageURL ? "threedy-card" : "threedy-card highlight"} key={v.id} onClick={() => handleShowClip(v)} >
                <div className="threedy-card-header background-white pt-15 pb-15 rt-9">
                  <div className="flex-image-holder">
                    {v.leftImageURL && <img className="v-middle" src={`${APP_RESOURCES_URL}${v.leftImageURL}`} alt={`${v.id}-left-${v.name}`} />}
                    {v.leftImageSvg && v.leftImageSvg }
                  </div>
                  {v.rightImageURL && (
                    <div className="flex-image-holder">
                      <img className="v-middle" src={`${APP_RESOURCES_URL}${v.rightImageURL}`} alt={`${v.id}-right-${v.name}`} />
                    </div>
                  )}
                </div>
                <div className="threedy-card-content background-grayed-dark pl-15 pt-10 pb-10 pr-15 rb-9">
                  <p className="fw-600 fs-14 m-0">{v.title}</p>
                  <p className="fw-400 fs-10 m-0 pt-4 pb-4">{v.subTitle}</p>
                  <div className="flex-image-holder">
                    <Link className="underlined fw-500 fs-10 m-0 primary-color" onClick={() => handleShowClip(v)}>Learn more</Link>
                    <img src={`${APP_RESOURCES_URL}help/arrow-right.svg`} alt={`${v.name}`} className="ml-4 mt-3" />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  )

}

export default HelpPage