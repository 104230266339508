import { defaultImage } from "../../assets";
import "./table-row.component.css";

const TableRow = (props) => {
    const { tableImage, tableItems, tableHeaders } = props
    const renderRows = () => {
        return tableItems.map((item, index) => {
            const cellCustomWidth = tableHeaders && tableHeaders[index].width ? {width: tableHeaders[index].width} : null
            if (index === 0) {
                return (
                    <div className="table-cell" key={index} style={cellCustomWidth}>
                        <div className="table-cell-content table-cell-content-flex"><div className="cell-image">{tableImage ? <img src={tableImage} alt={tableImage} /> : defaultImage}</div><div className="cell-text">{item}</div></div>
                    </div>
                )
            }
            return (
                <div className="table-cell" key={index} style={cellCustomWidth}>
                    <div className="table-cell-content">{item}</div>
                </div>
            )
        })
    }
    return (
        <div className="table-row">
            {renderRows()}
        </div>
    )
}

export default TableRow
