import React from 'react'
import ReactDOM from 'react-dom/client'
// import './index.css'
import './toggle.css'
import './styles.scss'
import Toggle from './toggle'
import { BrowserRouter } from 'react-router-dom'
import { Provider as ReduxProvider } from 'react-redux'
import reduxStore from './redux/store.redux'
import { GoogleOAuthProvider } from '@react-oauth/google';
import { GOOGLE_AUTH_KEY } from './constants'

const root = ReactDOM.createRoot(document.getElementById('toggle-root'))

root.render(
  <GoogleOAuthProvider clientId={GOOGLE_AUTH_KEY}>
  <ReduxProvider store={reduxStore}>
    <BrowserRouter>
      <Toggle />
    </BrowserRouter>
  </ReduxProvider>
  </GoogleOAuthProvider>
)
