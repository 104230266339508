import { useCallback, useEffect, useRef, useState } from "react"
import { useSelector } from "react-redux"
import "./element-material-library-search.component.css"
import { EVENTS } from "../../../../../constants"
import MaterialImageSearchComponent from "../../../../../components/material-image-search/material-image-search.component"

const ElementMaterialLibrarySearch = (props) => {
  const {category, onSelectCategory, onSearchResults} = props
  const {materials} = useSelector((state) => state.ui)
  const [search, setSearch] = useState("")
  const [imageSearch, setImageSearch] = useState()
  const [expanded, setExpanded] = useState()
  const [showPhotoSearch, setShowPhotoSearch] = useState()
  const debounceRef = useRef()
  const inputRef = useRef()
  const didResetRef = useRef()
  const lastCategorySearchRef = useRef()

  const handleSearchChange = (e) => {
    const newVal = e.target.value
    setSearch(newVal)

    if (debounceRef.current) {
      clearTimeout(debounceRef.current)
      debounceRef.current = null
    }

    debounceRef.current = setTimeout(() => handleSearch(newVal), 250)
  }

  const handleResetSearch = () => {
    setSearch("")
    onSearchResults(true)
    setImageSearch(false)

    if (inputRef.current) {
      inputRef.current.focus()
    }
  }

  const handleKeyHandler = (enabled) => {
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled}}))
  }

  const handleResetCategory = () => {
    onSelectCategory(null)
    didResetRef.current = true
    lastCategorySearchRef.current = false
    setImageSearch(false)
  }

  const handleSearch = useCallback((term) => {
    const lTerm = term.toLowerCase()

    if (category) {
      const theCategory = [...materials.categories].find((c) => c.name === category.name)
      const updatedMaterials = [...theCategory.materials].filter((m) => m.name.toLowerCase().includes(lTerm) || m.tags.includes(lTerm))
      const updated = {...theCategory, materials: updatedMaterials}

      // Propagate to parent
      onSearchResults(false, updated)
      lastCategorySearchRef.current = category.name
    }
    else {
      const updated = [...materials.categories].map((c) => {
        if (c.name.toLowerCase().includes(lTerm)) {
          return c
        }

        const materialsFound = c.materials.filter(m => m.name.toLowerCase().includes(lTerm) || m.tags.includes(lTerm))

        return {
          ...c,
          materials: materialsFound,
        }
      }).filter(c => c.materials.length > 0)

      // Propagate to parent
      onSearchResults(false, updated)
      lastCategorySearchRef.current = "all"
    }
  }, [onSearchResults, category, materials.categories])

  const handleToggleSearch = () => {
    // Reset
    if (expanded && search) {
      handleResetSearch()
    }

    setExpanded(!expanded)
  }

  // If we have a search & different category, let's do the search
  useEffect(() => {
    if (didResetRef.current && !category) {
      handleSearch(search)
      didResetRef.current = false
    }
    else if (lastCategorySearchRef.current && category && category.name !== lastCategorySearchRef.current) {
      handleSearch(search)
    }

  }, [search, category, handleSearch])

  const handlePhotoSearchResult = (results, imageName) => {
    setShowPhotoSearch(false)
    setImageSearch(imageName)

    if (category) {
      const theCategory = [...materials.categories].find((c) => c.name === category.name)
      const categoryMaterialMap = new Map()
      for (const r of results) {
        if (r[1] === theCategory.name) {
          if (!categoryMaterialMap.has(r[1])) {
            categoryMaterialMap.set(r[1], [])
          }
          categoryMaterialMap.get(r[1]).push(r[0])
        }
      }
      
      let updatedMaterials = []
      if (categoryMaterialMap.has(theCategory.name)) {
        const x = categoryMaterialMap.get(theCategory.name)
        updatedMaterials = theCategory.materials.filter((m) => x.includes(m.id))
      }

      const updated = {...theCategory, materials: updatedMaterials}

      // Propagate to parent
      onSearchResults(false, updated)
      lastCategorySearchRef.current = category.name
    }
    else {
      const categoryMaterialMap = new Map()
      for (const r of results) {
        if (!categoryMaterialMap.has(r[1])) {
          categoryMaterialMap.set(r[1], [])
        }
        categoryMaterialMap.get(r[1]).push(r[0])
      }

      const updated = [...materials.categories].map((c) => {
        if (categoryMaterialMap.has(c.name)) {
          const materialsFound = c.materials.filter((m) => categoryMaterialMap.get(c.name).includes(m.id))
          return {
            ...c, 
            materials: materialsFound
          }
        }

        return {
          ...c,
          materials: []
        }
      }).filter(c => c.materials.length > 0)

      // Propagate to parent
      onSearchResults(false, updated)
      lastCategorySearchRef.current = "photoAll"
    }
  }

  return (
    <div className="toggle-material-library-search">
      {showPhotoSearch && <MaterialImageSearchComponent setImageSearchResult={handlePhotoSearchResult} onClose={() => setShowPhotoSearch(false)} />}
      {expanded && (
        <>
          {category && <button onClick={handleResetCategory} className="toggle-material-library-search-tag">In: {category.name} <span className="icon-close" /></button>}
          {imageSearch && <button onClick={handleResetSearch} className="toggle-material-library-search-tag"><span className="icon-photo_camera" /> Image: <strong>{imageSearch}</strong> <span className="icon-close" /></button>}
          <input ref={inputRef} disabled={imageSearch} type="text" placeholder="search..." value={search} autoFocus onFocus={() => handleKeyHandler(false)} onBlur={() => handleKeyHandler(false)} onChange={handleSearchChange} />
          <button disabled={!search} className="toggle-material-library-search-btn" onClick={handleResetSearch}><span className="icon-close" /></button>
          <button disabled={!category || showPhotoSearch} className="toggle-material-library-search-btn" onClick={() => setShowPhotoSearch(true)}><span className="icon-photo_camera" /></button>
        </>
      )}
      <button className="toggle-material-library-search-btn" onClick={handleToggleSearch}><span className={expanded ? "icon-contract-menu" : "icon-search"} /></button>
    </div>
  )
}

export default ElementMaterialLibrarySearch