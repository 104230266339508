import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { useLazyGetProjectByPublicLinkQuery, useLazyGetProjectForViewingQuery } from "../../redux/api.redux.slice"
import WorkspacePage from "../workspace/workspace.page"

const PublicViewerPage = () => {
  const { public_link, project_id } = useParams()
  const [project, setProject] = useState(false)
  const [isError, setIsError] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [getProjectByLink] = useLazyGetProjectByPublicLinkQuery()
  const [getProjectByViewId] = useLazyGetProjectForViewingQuery()

  useEffect(() => {

    const getProjectByLinkQuery = async () => {
      try {
        const res = await getProjectByLink(public_link).unwrap()
        setProject(res)
        setIsLoading(false)
      }
      catch (e) {
        console.error(e)
        setIsError(true)
        setIsLoading(false)
      }
    }

    const getProjectByViewIdQuery = async () => {
      try {
        const res = await getProjectByViewId(project_id).unwrap()
        setProject(res)
        setIsLoading(false)
      }
      catch (e) {
        console.error(e)
        setIsError(true)
        setIsLoading(false)
      }
    }

    if (public_link) {
      getProjectByLinkQuery()
    }
    else if (project_id) {
      getProjectByViewIdQuery()
    }
    else {
      setIsError(true)
      setIsLoading(false)
    }

  }, [public_link, project_id, getProjectByViewId, getProjectByLink])

  return (
    !project ? (
      <div className={isLoading ? "threedy-lab-page-content threedy-skeleton" : "threedy-lab-page-content"}>
        { isError && <div className="threedy-error">There was an error fetching this project. Please try again</div> }
      </div>
    ) : (!isError && project) ? (
      <WorkspacePage project={project} publicView={project_id ? true : false} shared  />
    ) : null
  )
}

export default PublicViewerPage