import { useEffect, useRef, useState } from "react"
import PredictiveSearchComponent from "../predictive-search/predictive-search.component"
import "./context-menu.component.css"

const ContextMenuComponent = (props) => {

  const elRef = useRef(false)
  const [inputValues, setInputValues] = useState({})
  const [subItemSearch, setSubItemSearch] = useState(false)  

  useEffect(() => {
    const handleClick = (e) => {
      if (elRef.current && !elRef.current.contains(e.target)) {
        props.onClose()
      }
    }

    // Let's also make sure the context menu is visible within the confines of the screen
    const screenRect = document.body.getBoundingClientRect()
    const elRect = elRef.current.getBoundingClientRect()
    if (elRect.x + elRect.width > screenRect.width) {
      elRef.current.style.left = screenRect.width - (1.1 * elRect.width) + 'px';
    }

    if (elRect.y + elRect.height > screenRect.height) {
      elRef.current.style.top = screenRect.height - (1.2 * elRect.height) + 'px';
    }

    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  }, [props])

  const handleItemAction = (e, item) => {
    e.stopPropagation()

    if (item.subItems) {
      const rect = elRef.current.getBoundingClientRect()
      let top = rect.top
      let left = rect.left + rect.width
      if (top + 180 > window.innerHeight) {
        top = window.innerHeight - 220
      }

      if (left + 180 > window.innerWidth) {
        left = window.innerWidth - 220
      }

      setSubItemSearch({
        position: {
          left: left + 'px',
          top: top + 'px'
        },
        items: item.subItems,
        onSelect: (itemId) => {
          props.onClose()
          item.onSubItemSelection(itemId)
        }
      })

      return
    }

    if (!item.action) {
      return
    }

    if (!item.keepOpen) {
      props.onClose()
    }
    
    item.action()
  }

  const handleInputUpdate = (item, val) => {
    setInputValues({...inputValues, [item.id]: val})
  }

  const handleCloseSubMenu = (e) => {
    e.stopPropagation()
    setSubItemSearch(false)
  }

  const handleInputAction = (item) => {
    item.action(inputValues[item.id])
    props.onClose()
  }

  return (
    <div className={props.wide ? "toggle-context-menu wide" : "toggle-context-menu"} ref={elRef} style={props.position}>
      {props.header && <h4>{props.header}</h4>}
      {subItemSearch && <PredictiveSearchComponent title="Select a Project Folder"  icon="icon-folder" onSelect={subItemSearch.onSelect} onClose={handleCloseSubMenu} items={subItemSearch.items} position={subItemSearch.position} />}
      <ul>
        {
          props.items && props.items.length > 0 && (
            props.items.map((item) => {
              return (
                <li key={item.id} className={item.itemClassName ? item.itemClassName : ""}>
                  {item.isInput ? (
                    <>                 
                      <div className="toggle-context-menu-input">
                        <button className="close-btn" onClick={props.onClose}><span className="icon-x"></span></button>
                        <h2>{item.icon && item.icon}{item.label}</h2>
                        <p>{item.sublabel}</p>
                        <input autoFocus type="text" placeholder={item.placeholder} value={inputValues[item.id] ? inputValues[item.id] : ''} onChange={(e) => handleInputUpdate(item, e.target.value)} />
                        <div className="toggle-context-menu-input-actions">
                          <button onClick={props.onClose}>Cancel</button>
                          <button onClick={() => handleInputAction(item)} disabled={!inputValues[item.id]}>Create Folder</button>
                        </div>
                      </div>
                    </>
                  ) : (
                    <button className={item.className ? item.className : ""} disabled={item.disabled} onClick={(e) => handleItemAction(e, item)}>{item.label}</button>
                  )}
                </li>
              )
            })
          )
        }
      </ul>
    </div>
  )

}

export default ContextMenuComponent