import { useRef, useState } from "react"
import "./global-search.component.css"
import { Link } from "react-router-dom"
import { useSelector } from "react-redux"
import GlobalSearchResultsComponent from "./global-search-results.component"
import { systemPaths } from "./system-paths"

const GlobalSearchComponent = (props) => {
    const { close } = props
    const [userLibrary] = useSelector((state) => [state.ui.searchLibrary])
    const [designCategorySearch, setDesignCategorySearch] = useState([])
    const [materialCategorySearch, setMaterialCategorySearch] = useState([])
    const [modelCategorySearch, setModelCategorySearch] = useState([])
    const [systemCategorySearch, setSystemCategorySearch] = useState([])
    const [searchKeyword, setSearchKeyword] = useState("")
    const searchInputReference = useRef(null);


    const resetAllSearchResults = () => {
        setDesignCategorySearch([])
        setMaterialCategorySearch([])
        setModelCategorySearch([])
        setSystemCategorySearch([])
    }

    const clearSearch = () => {
        resetAllSearchResults()
        setSearchKeyword("")
        searchInputReference.current.focus();
    }
    const autoHideSearchBar = () => {
        if (searchKeyword) {
            return
        }
        close()
    }

    const searchUserAssetsByName = (keyword, categoryName, assets = []) => {
        return [
            ...assets
                .filter(x => x.name.toLowerCase().includes(keyword))
                .map(x => {
                    return {
                        id: x.id,
                        link: `editor/${x.id}`,
                        name: `${x.name}`,
                        breadcrumbs: ["Dashboard", categoryName, `${x.name}`]
                    }
                })
        ]
    }
    const search = async (keyword) => {
        const sanitizedKeyWord = keyword.replace(/[^a-zA-Z0-9 ]/gi, '') // only numbers(0-9), letters(a-Z) and spaces
        setSearchKeyword(sanitizedKeyWord)
        resetAllSearchResults()
        if (!sanitizedKeyWord) {
            return
        }

        const lKeyword = sanitizedKeyWord.toLowerCase()
        const { designs, materials, models } = userLibrary

        setDesignCategorySearch(searchUserAssetsByName(lKeyword, "Designs", designs))
        setMaterialCategorySearch(searchUserAssetsByName(lKeyword, "My Materials", materials))
        setModelCategorySearch(searchUserAssetsByName(lKeyword, "Uploads", models))
        setSystemCategorySearch([...systemPaths.filter(x => x.meta.toLowerCase().includes(lKeyword))])
    }
    const redirectToLink = (e) => {
        resetAllSearchResults()
        setSearchKeyword("")
        return true
    }
    return (
        <div className="global-search-container">
            <div className="search-input-container glow">
                <span className="icon-search"></span>
                <input type="text" className="search-input-element"
                    placeholder="Search everywhere..."
                    onChange={(e) => search(e.target.value)}
                    value={searchKeyword}
                    ref={searchInputReference} 
                    autoFocus
                    onBlur={() => autoHideSearchBar()}
                />
                {searchKeyword.length > 0 && <span className="icon-close close-btn" onClick={() => clearSearch()}></span>}
            </div>
            {(designCategorySearch.length > 0 || materialCategorySearch.length > 0 || modelCategorySearch.length > 0 || systemCategorySearch.length > 0) 
                &&
                <>
                <div className="search-results-container scrollbar-sm">
                    <ul>
                        <GlobalSearchResultsComponent categoryName="Designs" searchResults={designCategorySearch} redirectToLink={redirectToLink} />
                        <GlobalSearchResultsComponent categoryName="Uploads" searchResults={modelCategorySearch} redirectToLink={redirectToLink} />
                        <GlobalSearchResultsComponent categoryName="Materials" searchResults={materialCategorySearch} redirectToLink={redirectToLink} />
                        <GlobalSearchResultsComponent categoryName="System" searchResults={systemCategorySearch} redirectToLink={redirectToLink} />
                    </ul>
                </div>

            </>
            }
        </div>
    )
}

export default GlobalSearchComponent