import moment from "moment"
import SpinnerComponent from "../spinner/spinner.component"
import "./model-processing.component.css"

const ModelProcessingComponent = (props) => {
  return (
    <div className="toggle-processing-file-container">
      <div className="toggle-processing-file">
        <div className="threedy-lab-spinner-wrapper small">
          <SpinnerComponent inline />
        </div>
        <h2>Hang tight as we convert your file.</h2>
        <div className="toggle-processing-file-icons">
          <span className="span-to-from icon-file"></span>
          <span className="span-to-from icon-layers"></span>
          <span className="span-arrow icon-arrow-left"></span>
        </div>
        <p>Feel free to come back - your design will automatically update once your file is ready.</p>
        <h4>Last checked {moment(props.lastCheck).format("MM/DD/YYYY hh:mm a")}, {moment(props.lastCheck).format("ss")}s</h4>
      </div>
    </div>
  )
}

export default ModelProcessingComponent