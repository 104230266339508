import { useEffect, useState } from "react"
import MiscHelperMethods from "../../../helper-methods/misc.helpers"
import "./element-decals-pane.component.css"
import { API_BASE_URL } from "../../../../../constants"
import ElementAddDecalModal from "./element-add-decal.modal"

const ElementDecalsPane = (props) => {

  const {project, element, onDeleteDecal, onDropDecal} = props
  const [decals, setDecals] = useState([])
  const [showAddDecal, setShowAddDecal] = useState(false)

  // Set list on load
  useEffect(() => {
    if (!project.id || !project.decals || typeof project.decals !== "object" || Object.keys(project.decals).length < 1) { return }

    const arr = MiscHelperMethods.convertObjectToArray(project.decals).filter((d) => d.mesh === element.id).sort((a, b) => a.created_ts && b.created_ts && a.created_ts > b.created_ts ? 1 : -1)
    setDecals(arr)

  }, [project.id, project.decals, element])

  const handleDropDecal = (params) => {
    onDropDecal(params)
    setShowAddDecal(false)
  }

  const handleDeleteDecal = (did) => {
    setDecals([...decals].filter((d) => d.id !== did))
    onDeleteDecal(did)
  }
  
  return (
    <>
      {showAddDecal && <ElementAddDecalModal projectId={project.id} onClose={() => setShowAddDecal(false)} onDropDecal={handleDropDecal} />}
      <div className="toggle-element-configuration-section">
        <h6>Apply Decals</h6>
        <div className="toggle-decals-list">
          {decals.length > 0 && (
            <ul className="toggle-content-list">
              {decals.map((d) => {
                const url = `${API_BASE_URL}projects/public/${project.id}/decals/${d.id}`
                const name = d.src.substring(d.src.lastIndexOf('/')+1)

                return (
                  <li key={d.id} className="rounded">
                    <div><span className="toggle-decal-img-container"><img src={url} alt={name} /></span> <h6>{name}</h6></div>
                    <div><button onClick={() => handleDeleteDecal(d.id)}><span className="icon-cancel" /></button></div>
                  </li>
                )
              })}
            </ul>
          )}
          {decals.length < 1 && <h6 className="toggle-no-items">🤔 You haven't added any decals yet</h6>}
        </div>
        <button className="toggle-outline-filled-btn align-right" onClick={() => setShowAddDecal(true)}><span className="icon-add" /> Add</button>
      </div>
    </>
  )
}

export default ElementDecalsPane