import ModalComponent from "../modal/modal.component"

const UnacceptedFileFormatModalComponent = (props) => {

  const modalContent = (
    <>
      <p>Sorry! we do not accept this file format.</p>
      <br />
      <p>Please select a .glb, .step, .stp, .obj, .fbx, .dae file. If your model has texture files please zip the model with the textures.</p>
    </>
  )
  const actions = (
    <button className="toggle-primary-btn" onClick={() => props.close()}>OK</button>
  )
  return (
    <ModalComponent title="Unacceptable File"
      close={() => props.close()}
      content={modalContent}
      className="medium"
      actions={actions} />
  )
}

export default UnacceptedFileFormatModalComponent