import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { Link, useNavigate } from "react-router-dom"
import { useLoginUserMutation, useUpdatePasswordMutation, useUserForgotPasswordMutation, useUserValidateForgotPasswordMutation } from "../../redux/api.redux.slice"
import jwtDecode from "jwt-decode"
import moment from "moment"
import "./login.page.css"
import Session from "../../utils/session.util"
import { login } from "../../redux/auth.redux.slice"
import { KLAVIYO_METRICS } from "../../constants"
import HorizontalOrDividerComponent from "../../components/forms/horizontal-or-divider.component"
import GoogleLoginButtonComponent from "../../components/forms/google-login-button.component"
import { T } from "../../constants"

const LoginPage = (props) => {

  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [loginRequest] = useLoginUserMutation()
  const [forgotPasswordRequest] = useUserForgotPasswordMutation()
  const [validateResetRequest] = useUserValidateForgotPasswordMutation()
  const [updateUserRequest] = useUpdatePasswordMutation()
  const [userData, setUserData] = useState({ email: "", password: "" })
  const [flow, setFlow] = useState("login")
  const [error, setError] = useState(false)
  const [loading, setLoading] = useState(false)
  const [passwordValid, setPasswordValid] = useState(false)

  useEffect(() => {
    // Redirecting to dashboard if session available for user
    // Adding exp check as well
    const session = Session.read()
    if (session && session.access_token && session.refresh_token && moment(session.exp).isAfter(moment())) {
      navigate(`/dashboard`, { replace: true })
      return
    }
  }, [navigate])

  // Check for password reset
  useEffect(() => {

    const validateCode = async () => {
      try {
        setLoading(true)
        setError(false)
        await validateResetRequest({ code }).unwrap()

        // Continue to allow user to enter new password
        setFlow("password")
        setLoading(false)
      }
      catch (e) {
        console.log("Error validating code: ", e)
        setError("We had an issue validating your code. It may be expired or incorrect.")
        setLoading(false)
      }
    }

    const params = new URLSearchParams(window.location.search)
    const flow = params.get("f")
    const code = params.get("c")

    if (flow === "r" && code !== undefined) {
      // Continue reset flow
      setFlow("validation")
      validateCode()
    }

  }, [validateResetRequest])

  const handleLogin = async () => {
    if (loading || !userData.email || !userData.password) {
      return
    }
    try {
      setError(false)
      setLoading(true)

      const tokens = await loginRequest(userData).unwrap()
      if (tokens) {
        loginAndRedirect(tokens)
      }
    }
    catch (e) {
      console.error("Error logging in user: ", e)
      setError("We had an issue validating your credentials.")
      setLoading(false)
    }
  }

  const getRedirectPath = () => {
    const urlParams = new URLSearchParams(window.location.search)
    return urlParams.has('returnTo') ? urlParams.get('returnTo') : "/dashboard"
  }

  const loginAndRedirect = (tokens) => {
    const { access_token, id_token, refresh_token, expires } = tokens
    const user = jwtDecode(id_token)

    // Track with Klaviyo
    window.klaviyo.identify({ $email: userData.email, $first_name: user.name, }).then(() => {
      console.log('Identify has been completed')
      window.klaviyo.track(KLAVIYO_METRICS.logged_in)
    })

    Session.write({ id_token, access_token, refresh_token, exp: moment().add(expires, "s").valueOf() })
    dispatch(login({ user, access_token }))
    navigate(getRedirectPath(), { replace: true })
    setLoading(false)
  }

  const handleForgotPassword = async () => {
    try {
      setError(false)
      setLoading(true)
      await forgotPasswordRequest(userData).unwrap()
      setLoading(false)
      setFlow("sent")
    }
    catch (e) {
      console.error("Error generating reset link for user: ", e)
      let errorMessage = "We had an issue generating a reset link."
      if (e.status === 400) {
        errorMessage = e.data?.data
      }
      setError(errorMessage)
      setLoading(false)
    }
  }

  const validate = (val) => {

    // Validate lowercase
    const lowerCaseLetters = /[a-z]/g;
    if (!val.match(lowerCaseLetters)) {
      setPasswordValid(false)
      return false
    }

    // Validate capital letters
    const upperCaseLetters = /[A-Z]/g;
    if (!val.match(upperCaseLetters)) {
      setPasswordValid(false)
      return false
    }

    // Validate numbers
    const numbers = /[0-9]/g;
    if (!val.match(numbers)) {
      setPasswordValid(false)
      return false
    }

    // Validate special chars
    const specialChars = /[$&+,:;=?@#|'<>.^*()%!-]/g;
    if (!val.match(specialChars)) {
      setPasswordValid(false)
      return false
    }

    // Validate length
    if (val.length < 8) {
      setPasswordValid(false)
      return false
    }

    setPasswordValid(true)
    return true
  }

  const handleUpdatePassword = async () => {
    // First validate password
    const params = new URLSearchParams(window.location.search)
    const code = params.get("c")
    const valid = validate(userData.password)

    if (valid && code) {
      // Make request
      try {
        setError(false)
        setLoading(true)
        const params = { password: userData.password, resetCode: code }
        await updateUserRequest({ body: params }).unwrap()
        setFlow("done")
        setLoading(false)
      }
      catch (e) {
        console.log("Error updating password: ", e)
        setError("We had an issue updating your password.")
        setLoading(false)
      }
    }
  }

  const afterLogin = (tokens) => {
    if (tokens) {
      loginAndRedirect(tokens)
    }
  }

  return (
    <div className={loading ? "toggle-login-page threedy-skeleton" : "toggle-login-page"}>
      <div className="toggle-login-page-content">
        <img src="/images/Toggle3D_Horizontal_Dark.png" alt="Toggle3D Login" />
        {
          flow === "login" && (
            <>
              <h5 className="narrow-letters">Welcome back!</h5>
              <p>Please enter your email and password to log in</p>
              <input type="email" placeholder="Email" value={userData.email} onChange={(e) => setUserData({ ...userData, email: e.target.value })} />
              <input type="password" placeholder="Password" value={userData.password} onKeyDown={(event) => {
                if (event.key === 'Enter') {
                  handleLogin()
                }
              }} onChange={(e) => setUserData({ ...userData, password: e.target.value })} />
            </>
          )
        }
        {
          flow === "reset" && (
            <>
              <h5>Forgot Password</h5>
              <p>It happens. Enter your email below and we'll send you a reset link</p>
              <input type="email" placeholder="Email" value={userData.email} onChange={(e) => setUserData({ ...userData, email: e.target.value })} />
            </>
          )
        }
        {
          flow === "sent" && (
            <>
              <h5>Request received</h5>
              <p>All set. If that email exists in our system you will receive a reset link shortly.</p>
            </>
          )
        }
        {
          flow === "validation" && (
            <>
              <h5>Validating your reset request...</h5>
              <p>Just one moment to validate your reset request.</p>
            </>
          )
        }
        {
          flow === "password" && (
            <>
              <h5>Enter your new password</h5>
              <p>Password must contain at least 1 uppercase letter, 1 lowercase letter, 1 number, 1 special character and at least 8 characters long</p>
              <input type="password" placeholder="Password" value={userData.password} onChange={(e) => setUserData({ ...userData, password: e.target.value })} />
              {!passwordValid && <h5 className="error">Please make sure your password meets all of the above requirements</h5>}
            </>
          )
        }
        {
          flow === "done" && (
            <>
              <h5>All set.</h5>
              <p>Your password has been updated successfully!</p>
            </>
          )
        }
        {error && <h5 className="error">Uh oh. {error} Please try again</h5>}
        {flow === "login" && <Link className="action-link" onClick={() => setFlow("reset")}>Forgot Password?</Link>}
        {flow === "login" && <button className="toggle-primary-btn rectangular" onClick={handleLogin} disabled={loading || !userData.email || !userData.password}>Log In</button>}
        {flow === "reset" && <button className="toggle-primary-btn" onClick={handleForgotPassword} disabled={loading || !userData.email}>Reset</button>}
        {flow === "reset" && <p>Don't forget to check your spam folder</p>}
        {flow === "password" && <button className="toggle-primary-btn" onClick={handleUpdatePassword} disabled={loading || !userData.password}>Update Password</button>}
        {flow === "login" && <p>Don't have an account? <Link to="/sign-up?r=t3d" className="action-link">Sign Up</Link></p>}
        {flow !== "login" && <p><Link className="action-link" onClick={() => {
          setError(false)
          setFlow("login")
        }}>Back to login</Link></p>}
        {flow === "login" &&
          <>
            <HorizontalOrDividerComponent className={`m-30 small`} />
            <GoogleLoginButtonComponent mode={T.LOGIN} buttonOnly afterGoogleLogin={afterLogin} setError={setError} />
          </>
        }

      </div>
    </div>
  )
}

export default LoginPage