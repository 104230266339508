import { useLocation, useNavigate, useSearchParams } from "react-router-dom"
import { useCallback, useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { useAddNewProjectMutation, useDeleteProjectMutation, useEditProjectMutation, useGetRecentProjectsQuery, useLazyGetSampleProjectIDQuery, useLazyUpgradeUserQuery } from "../../redux/api.redux.slice"
import { setToast, showProjectThumbnailModal, setRunTour, setActiveTour } from "../../redux/ui.redux.slice"
import { toggleSidebar } from "../../redux/ui.redux.slice"
import { modifyQuota, setUserSubscription } from "../../redux/auth.redux.slice"
import { DASHBOARD_TUTORIALS, KLAVIYO_METRICS, STRIPE_PLANS, T } from "../../constants"
import { createConfigurator, createMaterial, threeDModelTemplateImage, variationsTemplate } from "../../assets"
import ContextMenuComponent from "../../components/context-menu/context-menu.component"
import ProjectTileComponent from "../../components/project-tile/project-tile.component"
import FreeLimitExhaustedModalComponent from "../../components/upgrade-to-pro/free-limit-exhausted-modal.component"
import NotAvailableForFreeModalComponent from "../../components/upgrade-to-pro/not-available-for-free-modal.component"
import SwitchToDesktopModalComponent from "../../components/alerts/switch-to-desktop-modal.component"
import DeleteConfirmModalComponent from "../../components/alerts/delete-confirm-modal.component"
import TileComponent from "../../components/tile-ui/tile.component"
import './dashboard.page.css'
import SpinnerComponent from "../../components/spinner/spinner.component"
import HeaderGroupComponent from "../../components/header-group/header-group.component"

const DashboardPage = () => {

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const {data: projects, isLoading} = useGetRecentProjectsQuery()
  const [addProject, {isLoading: newProjectLoading}] = useAddNewProjectMutation()
  const [deleteProject] = useDeleteProjectMutation()
  const [updateProject] = useEditProjectMutation()
  const {user, plan, quota} = useSelector((state) => state.auth)
  const {finishedTours, activeTour} = useSelector((state) => state.ui)
  const [itemContextMenu, setItemContextMenu] = useState(false)
  const [editedItemId, setEditedItemId] = useState(false)
  const [showFreeLimitExhaustedModal, setshowFreeLimitExhaustedModal] = useState(false)
  const [showNotAvailableForFreeModal, setShowNotAvailableForFreeModal] = useState(false)
  const [showSwitchToDesktopModal, setShowSwitchToDesktopModal] = useState(false)
  const [showDeleteProjectConfirmationModal, setshowDeleteProjectConfirmationModal] = useState(false)
  const [searchParams, setSearchParams] = useSearchParams()
  const [upgradeUser, { isLoading: upgradeLoading }] = useLazyUpgradeUserQuery()
  const [getSampleProjectID, { isLoading: fetchingSampleProjectID }] = useLazyGetSampleProjectIDQuery()
  
  const projectIDRef = useRef("")

  const handleCreateProject = useCallback(async (projectType) => {
    if (projectType === T.MATERIAL && plan && plan.type !== T.PRO) {
      setShowNotAvailableForFreeModal(true)
      return
    }

    if ((projectType === T.CONFIGURATOR || projectType === T._3D_MODEL) && plan && quota && plan.type !== T.PRO && quota.numProjects >= plan.features.numProjects) {
      setshowFreeLimitExhaustedModal(true)
      return
    }

    const projectName = projectType === T.MATERIAL ? 'New Material' : 'Untitled'
    const params = { name: projectName, project_type: projectType }
    try {
      const res = await addProject(params).unwrap()
      // Increment quota
      dispatch(modifyQuota(1))
      // Navigate to project
      navigate(`/editor/${res.data.id}`)
    }
    catch (e) {
      console.log("Error adding project", e)
      dispatch(setToast({ message: "Uh oh. We had an issue creating a new design, please try again.", isError: true }))
    }
  }, [plan, quota, addProject, dispatch, navigate])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    if (params.get("isMobile") && params.get("isMobile") === "yes") {
      setShowSwitchToDesktopModal(true)
      dispatch(toggleSidebar(true))
    }
    const { state } = location // preferring this approach, as URL params is not safe for creating project ( may reload)
    if (state && state.projectType && [T.CONFIGURATOR, T._3D_MODEL, T.VARIATIONS, T.MATERIAL].includes(state.projectType)) {
      handleCreateProject(location.state.projectType)
      const newLocation = { ...location }
      delete newLocation.state.projectType // clearing state
      window.history.replaceState(null, '', newLocation.pathname)
    }
  }, [dispatch, location, handleCreateProject, navigate])

  // Handle stripe callback
  useEffect(() => {
    const doUpgrade = async () => {
      try {
        await upgradeUser().unwrap();

        // Set plan
        dispatch(
          setUserSubscription({
            plan: STRIPE_PLANS.find((p) => p.type === "pro"),
            status: "active"
          })
        )

      } catch (e) {
        console.error("error upgrading user: ", e)
        dispatch(
          setToast({
            message:
              "Uh oh. We had an issue upgrading your plan, please try again.",
            isError: true
          })
        )
      }
    }

    if (
      searchParams.get("setup_intent") &&
      searchParams.get("redirect_status") === "succeeded"
    ) {

      // Handle stripe
      doUpgrade()

      // Show toast
      dispatch(setToast({ message: "Welcome to toggle3D pro!" }))

      // Clear params
      setSearchParams("")
    }
  }, [searchParams, setSearchParams, upgradeUser, dispatch])

  // Start Tour
  useEffect(() => {
    if (!user) { return }
    
    const isUserOnboarded = user['https://thdy/user_md'] && (user['https://thdy/user_md']?.onboarded || false)
    const isTour1Finished = user['https://thdy/user_md'] && user['https://thdy/user_md'].hasOwnProperty('finishedTours') ? user['https://thdy/user_md'].finishedTours.includes('TOUR1') : false
    const canStartTour1 = isUserOnboarded === false && isTour1Finished === false && location.pathname.indexOf("/dashboard") > -1 && !finishedTours.includes('TOUR1') && activeTour === false
    if (canStartTour1) {
      setTimeout(() => {
        dispatch(setActiveTour('TOUR1')) // Make Tour1 as active
        dispatch(setRunTour(true)) // Start Tour
      }, 400);
    }
  }, [user, location.pathname, finishedTours, activeTour, dispatch])

  const getTimeIcon = () => {
    const now = new Date()
    const hours = now.getHours()
    if (hours < 6) {
      return "icon-moon"
    }
    else if (hours >= 6 && hours < 12) {
      return "icon-coffee"
    }
    else if (hours >= 12 && hours < 18) {
      return "icon-sun"
    }

    return "icon-moon"
  }

  const welcomeMessage = user && user['https://thdy/user_md'] && user['https://thdy/user_md'].onboarded ? "Welcome back" : "Welcome"

  const handleUpdateProjectName = async (value) => {
    const requestedProject = projects.find((p) => p.id === editedItemId)
    if (value && requestedProject.name !== value) {
      await updateProject({projectId: editedItemId, body: {name: value}})
    }

    setEditedItemId(false)
  }

  const handleProjectThumbnailRequest = (projectId) => {
    const project = projects.find((p) => p.id === projectId)
    if (!project) {
      return
    }

    dispatch(showProjectThumbnailModal(project))
  }

  const handleDeleteProject = async () => {
    let projectId = projectIDRef.current
    if (!projectId) {
      return
    }

    setshowDeleteProjectConfirmationModal(false)

    try {
      await deleteProject(projectId).unwrap()

      // Decrement quota
      dispatch(modifyQuota(-1))
    }
    catch (e) {
      console.log("Error deleting project: ", e)
      dispatch(setToast({message: "Uh oh. We had an issue deleting your project, please try again.", isError: true}))
    }
  }

  const showDeleteProjectModal = (projectId) => {
    projectIDRef.current = projectId
    setshowDeleteProjectConfirmationModal(true)
  }

  const handleProjectMoreClick = (e, projectId) => {
    e.preventDefault()
    e.stopPropagation()

    const items = [
      {id: 'rename', label: <><span className="icon-file"></span> Rename</>, action: () => setEditedItemId(projectId)},
      {id: 'thumb', label: <><span className="icon-image"></span> Update Image</>, action: () => handleProjectThumbnailRequest(projectId)},
      { id: 'delete', className: "delete-btn", label: <><span className="icon-trash"></span> Delete</>, action: () => showDeleteProjectModal(projectId) }
    ]

    setItemContextMenu({
      position: {
        left: e.clientX + 'px',
        top: e.clientY + 'px'
      },
      items: items
    })
  }

//     if ((projectType === 'configurator' || projectType === '3D model') && plan && quota && plan.type !== 'pro' && quota.numProjects >= plan.features.numProjects) {
//       setshowFreeLimitExhaustedModal(true)
//       return
//     }

//     const projectName = projectType === 'material' ? 'New Material' : 'Untitled'
//     const params = {name: projectName, project_type: projectType}
//     try {
//         const res = await addProject(params).unwrap()

//         window.klaviyo.track(KLAVIYO_METRICS.created_project)

//         // Increment quota
//         dispatch(modifyQuota(1))

//         // Navigate to project
//       navigate(`/editor/${res.data.id}`)
//     }
//     catch (e) {
//       console.log("Error adding project", e)
//       dispatch(setToast({message: "Uh oh. We had an issue creating a new design, please try again.", isError: true}))
//     }
// }

const goToSampleProject = async () => {
  try {
    const sampleProjectData = await getSampleProjectID().unwrap()
    if (sampleProjectData?.sampleProjectID) {
      navigate(`/editor/${sampleProjectData.sampleProjectID}`)
    }
  } catch (error) {
    console.log({error})
    dispatch(setToast({message: "Uh oh. We had an issue creating a sample project, please try again.", isError: true}))
  }
}

const recentProjects = () => {
  if (isLoading) {
    return <div className="toggle-content-blocks empty-project-selections"><SpinnerComponent inline /></div>
  }

  if (!isLoading && projects.length > 0) {
    const mappedProjects = projects.map(project => {
      const updatedRecently = project.updated_ts ? `Updated ${moment(project.updated_ts).fromNow()}` : `Created ${moment(project.created_ts).fromNow()}`
      const projectLink = `/editor/${project.id}`
      return (
        <TileComponent
          key={project.id}
          tileHeader={project.name}
          tileImage={project.image}
          tileDesc={project.project_type}
          tileTime={updatedRecently}
          onTileClick={() => navigate(projectLink)}
          onTileActionsClick={e => handleProjectMoreClick(e, project.id)}
          projectImage
          icon="icon-deployed_code"
          tileImageClassName="tile-img-container-project-image"
        />
      )
    })

    return <div className="toggle-content-blocks">{mappedProjects}</div>
  }

  return (
    <div className="no-projects">
      <h5>You haven't started any projects yet.</h5>
      <h6>Projects you create will appear here, start your first project!</h6>
      <div className="toggle-content-blocks empty-project-selections">
          <TileComponent tileHeaderOnly="Texture a 3D Model" tileSvg={threeDModelTemplateImage} onTileClick={() => handleCreateProject("3D model")} tileWrapperClassName="with-shadow" />
          <TileComponent tileHeaderOnly="Create Material" tileSvg={createMaterial} onTileClick={() => handleCreateProject("material")} tileWrapperClassName="with-shadow" />
      </div>
    </div>
  )
}

const tutorialsList = () => {
  return DASHBOARD_TUTORIALS.map(tutorial => {
    const tutorialImage = tutorial.leftImageURL ? { tileImage: tutorial.leftImageURL } : { tileSvg: tutorial.leftImageSvg }
    return (
      // <TileComponent
      //   key={tutorial.id}
      //   tileHeader={tutorial.title}
      //   tileDesc={tutorial.subTitle}
      //   onTileClick={() => navigate(`/tutorials?d=${tutorial.name}`)}
      //   tileImageClassName="tile-img-container-tutorial-image"
      //   {...tutorialImage}
      // />
      <div className="each-video-block" key={tutorial.id} onClick={() => navigate(`/tutorials?d=${tutorial.name}`)}>
        <div className="upper-container" role="button">
          <span className="icon-play_circle play-button"></span>
        </div>
        <div className="lower-container">
          <div className="tile-title">
            <span>{tutorial.title}</span>
          </div>
          <div className="tile-subtitle">{tutorial.subTitle}</div>
        </div>
      </div>
    )
  })
}

  return (
    <>
      {showFreeLimitExhaustedModal &&
        <FreeLimitExhaustedModalComponent close={() => setshowFreeLimitExhaustedModal(false)} />}
      {showNotAvailableForFreeModal &&
        <NotAvailableForFreeModalComponent close={() => setShowNotAvailableForFreeModal(false)} />}
      {showSwitchToDesktopModal &&
        <SwitchToDesktopModalComponent close={() => setShowSwitchToDesktopModal(false)} />}
      {showDeleteProjectConfirmationModal &&
        <DeleteConfirmModalComponent
          title="project"
          close={() => setshowDeleteProjectConfirmationModal(false)}
          delete={() => handleDeleteProject()}
        />} 

      { itemContextMenu && <ContextMenuComponent wide={itemContextMenu.wide} position={itemContextMenu.position} items={itemContextMenu.items} onClose={() => setItemContextMenu(false)} /> }


      {/* Blue Banner */}
      <section className="toggle-content-group">
        <HeaderGroupComponent header="Start a New Project" level="5" />
        <div className="toggle-content-blocks">
          <TileComponent tileHeaderOnly="Texture a 3D Model" tileSvg={threeDModelTemplateImage} onTileClick={() => handleCreateProject("3D model")} tileWrapperClassName="with-shadow" />
          <TileComponent tileHeaderOnly="Build a Configurator" tileSvg={createConfigurator} onTileClick={() => handleCreateProject("configurator")} tileWrapperClassName="with-shadow" />
          <TileComponent tileHeaderOnly="Create Model Variations" tileSvg={variationsTemplate} onTileClick={() => handleCreateProject("variations")} tileWrapperClassName="with-shadow" />
          <TileComponent tileHeaderOnly="Create Material" tileSvg={createMaterial} onTileClick={() => handleCreateProject("material")} tileWrapperClassName="with-shadow" />
        </div>
      </section>
      
      <section className="toggle-content-group">
        <HeaderGroupComponent header="Recent Projects" level="5" action={{ label: "View All", path: "/projects?d=ap", className: "toggle-default-btn hover-to-primary", span: "icon-arrow_forward_ios" }} />
        {recentProjects()}
      </section>
      
      <section className="toggle-content-group">
        <HeaderGroupComponent header="Tutorials" level="5" action={{ label: "View All", path: "/tutorials", className: "toggle-default-btn hover-to-primary", span: "icon-arrow_forward_ios" }} />
        <div className="toggle-content-blocks">
          {tutorialsList()}
        </div>
      </section>
    </>
  )
}

export default DashboardPage