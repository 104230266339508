import moment from "moment"
import { useState } from "react"
import { useSelector } from "react-redux"
import { Link } from "react-router-dom"
import EnterKeyInputComponent from "../enter-key-input/enter-key-input.component"
import NoLongerAProModalComponent from "../upgrade-to-pro/no-longer-a-pro-modal.component"
import { getProjectIcon } from "../../utils/ui.util"
import { MAX_THUMBNAIL_PROJECT_NAME } from "../../constants"

const ProjectTileComponent = (props) => {
  const {project, editable, editedProjectId, onUpdateName, onMoreClick} = props
  const { plan } = useSelector((state) => state.auth)
  const [showNoLongerAProModal, setShowNoLongerAProModal] = useState(false)

  const isPro = (event) => {
    if (project.project_type === 'material' && plan && plan.type !== 'pro') {
      event.preventDefault()
      setShowNoLongerAProModal(true)
      return false
    }
  }

  const isMaterialIsEditable = (project) => {
    if (project.project_type !== 'material') {
      return true
    }
    if (plan && plan.type === 'pro') {
      return true
    }
    return false
  }

  return (
    <>
    <li key={project.id}>
        <Link to={`/editor/${project.id}`} onClick={isPro}>
        <div className="grid-thumb">
          {!project.image && <div className="grid-thumb-placeholder">{getProjectIcon(project.project_type)}</div>}
          {project.image && <img src={project.image} alt={project.name} /> }
        </div>
        <div className="grid-action">
          <div className="grid-info">
              {editedProjectId !== project.id && <h5>{project.name.length > MAX_THUMBNAIL_PROJECT_NAME ? project.name.substring(0, MAX_THUMBNAIL_PROJECT_NAME).concat(`...`) : project.name}</h5>}
            {editable && editedProjectId === project.id  && (
              <EnterKeyInputComponent value={project.name} onValueChange={onUpdateName} />
            )}
            <h6 className="project-type capitalize">{getProjectIcon(project.project_type)} {project.project_type}</h6>
            <h6>{project.updated_ts ? `Last updated ${moment(project.updated_ts).fromNow()}` : `Created ${moment(project.created_ts).fromNow()}`}</h6>
          </div>
            {editable && isMaterialIsEditable(project) &&
              <button className="more-btn" onClick={(e) => onMoreClick(e, project.id)}><span className="icon-more-vertical"></span></button>}
        </div>
      </Link>
    </li>
      {showNoLongerAProModal &&
        <NoLongerAProModalComponent close={() => setShowNoLongerAProModal(false)} />}
    </>
  )
}

export default ProjectTileComponent