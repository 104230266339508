import { useEffect, useState } from "react"
import ModalComponent from "../modal/modal.component"
import { Elements } from "@stripe/react-stripe-js"
import CheckoutFormComponent from "./checkout.component"
import { useLazyCreateUserPaymentIntentQuery } from "../../redux/api.redux.slice"
import { STRIPE_PLANS, T } from "../../constants"
import SpinnerComponent from "../spinner/spinner.component"
import { useDispatch, useSelector } from "react-redux"
import { setToast } from "../../redux/ui.redux.slice"

const UpgradeModalComponent = (props) => {

  const [getIntent, {isLoading}] = useLazyCreateUserPaymentIntentQuery()
  const user = useSelector((state) => state.auth.user)
  const [theme, setTheme] = useState(T.STRIPE_THEME_LIGHT) // default stripe theme to light
  const [clientSecret, setClientSecret] = useState(false)
  const dispatch = useDispatch()

  useEffect(() => {

    const startPayFlow = async () => {
      console.log('start pay flow')

      try {
        const proPlan = STRIPE_PLANS.find((p) => p.type === 'pro')
        const intentRes = await getIntent({item: proPlan.id}).unwrap()
        setClientSecret(intentRes.client_secret)
      }
      catch (e) {
        console.log(e);
        dispatch(setToast({message: "Uh oh. We had an issue upgrading your plan, please try again.", isError: true}))
      }
    }

    startPayFlow()
    if (user && user['https://thdy/user_md'] && user['https://thdy/user_md']['darkMode'] === true) {
      setTheme(T.STRIPE_THEME_DARK)
    }

  }, [getIntent, dispatch, user]);

  const options = {
    clientSecret,
    appearance: {
      theme: theme // use "stripe" for light and "night" for dark
    }
  };

  const modalContent = (
    <div className={isLoading ? "threedy-skeleton" : ""}>
      {clientSecret && (
        <Elements options={options} stripe={props.stripePromise}>
          <CheckoutFormComponent redirect="settings/plans" />
        </Elements>
      )}
      { isLoading && (
        <>
          <h4>Loading...</h4>
          <div className="threedy-lab-spinner-wrapper">
            <SpinnerComponent inline />
          </div>
        </>
      )}
    </div>
  )

  return <ModalComponent title="Upgrade to Pro" content={modalContent} close={props.onClose} />
}

export default UpgradeModalComponent