import { useEffect, useState } from "react"
import "./image-zoom.component.css"

const ImageZoomComponent = (props) => {

  const [isHovering, setIsHovering] = useState(false)
  const [mousePos, setMousePos] = useState({x: 0, y: 0})
  const { captureHover } = props
  let alt = "Initial"
  let grayScaled = false
  let width = 98
  if (props.width) {
    width = props.width
  }
  if (props.alt) {
    alt = props.alt
  }
  if (props.grayScaled) {
    grayScaled = true
  }

  useEffect(() => {
    if (captureHover !== undefined) {
      captureHover(isHovering)
    }
    
  }, [isHovering, captureHover])

  const handleMouseEnter = () => {
    if (!isHovering) {
      setIsHovering(true)
    }
  }

  const handleMouseLeave = () => {
    setIsHovering(false)
  }

  const handleMouseMove = (e) => {
    const bounds = e.target.getBoundingClientRect()
    const x = e.clientX - bounds.left
    const y = e.clientY - bounds.top
    setMousePos({x, y})
  }

  return (
    <div className="toggle-image-zoom">
      <img className="initial-image" src={props.initial} alt={alt} onMouseMove={handleMouseMove} onMouseOut={handleMouseLeave} onMouseOver={handleMouseEnter} width={width} />
      {isHovering && <div className={"zoom-image " + (grayScaled ? "grayscaled" : '')} style={{ backgroundPosition: `${mousePos.x}% ${mousePos.y}%`, backgroundImage: `url('${props.zoom}')` }}></div>}
    </div>
  )
}

export default ImageZoomComponent