import "./pricing.component.css"

const PricingComponent = (props) => {
  const freePlanBackground = `url('/images/free_plan.svg')`
  const premiumPlanBackground = `url('/images/premium_plan.svg')`
  return (
    <div className={props.embedded ? "pricing-component embedded" : "pricing-component"}>
      {!props.hideHeader && <h3 className="negative-margin">Select the plan that fits you</h3>}
        <div className="manage-subscription">
        {!props.hideSubheader && <h5>Manage Subscription</h5>}
          <ul className="plan-selector">
          <li style={{ backgroundImage: freePlanBackground }} className={!props.currentPlanType || props.currentPlanType === 'free' ? "plan-item free selected" : "plan-item free"}>
              <div className="plan-header">
                <h2>Free</h2>
              <h3>$0</h3>
              <h6 className="per-month free">per month</h6>
              </div>
              <p>For individual users looking <br /> to try out the platform</p>
              <hr />
              <ul>
              <li> 3 Designs</li>
              <li> Over 1000 pre-made materials </li>
              <li> 20MB upload limit per 3D file</li>
              <li> 200MB storage</li>
              <li> Basic CAD conversion</li>
              <li> Export 3D models</li>
              <li> Private Share</li>
              </ul>
            <button disabled={props.currentPlanType && props.currentPlanType === 'free'} className="toggle-secondary-btn fs-12  pt-12 pb-12 pr-8 pl-8" onClick={() => props.onSelectPlan('free')}>
              {props.currentPlanType && props.currentPlanType === 'free' ? 'Current plan' : (props.currentPlanType && props.currentPlanType === 'pro' ? 'Downgrade' : 'Get started')}
              </button>
            </li>
          <li style={{ backgroundImage: premiumPlanBackground }} className={props.currentPlanType && props.currentPlanType === 'pro' ? "plan-item premium selected" : "plan-item premium"}>
              <div className="plan-header">
                <h2>Pro</h2>
              <h3>$29</h3>
              <h6 className="per-month premium">per month</h6>
              </div>
            <p>For professional projects and access to advanced editing tools</p>
              <hr />
              <ul>
              <li> Unlimited Designs</li>
              <li> Create your own materials</li>
              <li> 2GB storage</li>
              <li> 75MB upload limit per 3D file</li>
              <li> Web AR Viewer</li>
              <li> Advanced CAD conversion</li>
              <li> Public links for website embedding*</li>
              </ul>
              <button disabled={props.currentPlanType && props.currentPlanType === 'pro'} className="primary-btn fs-12 pt-12 pb-12 pr-8 pl-8" onClick={() => props.onSelectPlan('pro')}>
              {props.currentPlanType && props.currentPlanType === 'pro' ? 'Current plan' : (props.didTrial ? 'Upgrade' : 'Limited Time: Start 30-day free trial')}
              </button>
            </li>
          </ul>
        </div>
    </div>
  )
}

export default PricingComponent