import { useEffect, useState } from "react"
import "./tab-bar.component.css"

const TabBarComponent = (props) => {

  const {onSelection, simple, default: defaultValue, activeTab, items} = props
  const [selection, setSelection] = useState(false)

  const handleItemClick = (itemKey) => {
    setSelection(itemKey)
    onSelection(itemKey)
  }

  useEffect(() => {
    if (defaultValue) {
      setSelection(defaultValue)
    }
  }, [defaultValue])

  // Update Selected Tab, if tab gets changed in the parent component
  useEffect(() => {
    if (activeTab && activeTab !== selection) {
      setSelection(activeTab)
    }
  }, [activeTab, selection])

  return (
    <ul className={simple ? "toggle-tab-bar simple" : "toggle-tab-bar"}>
      {items.map((item) => {
        return (
          <li key={item.id} className={selection === item.id ? "selected" : ""}>
            <button disabled={item?.disabled === true ? "disabled" : null} onClick={() => handleItemClick(item.id)}>
              <h6>
                {item.logo && <img src={item.logo} alt={item.label}/>}
                {item.icon && <span className={item.icon}></span>} 
                {item.label}
              </h6>
            </button>
          </li>
        )
      })}
    </ul>
  )
}

export default TabBarComponent