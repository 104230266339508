import ModalComponent from "../modal/modal.component"

const DeleteConfirmModalComponent = (props) => {
    const { note } = props
    const modalContent = (
        <>
            <h6>Are you sure you would like to delete this {props.title} ? </h6>
            <br />
            <p>Note: This action cannot be reversed</p>

            {note &&
                <>
                    <br />
                    <br />
                <p><strong>Warning:<br /></strong>{note}</p>
                </>
            }
        </>
    )
    const actions = (
        <>
            <button className="toggle-default-btn" onClick={() => props.close()}>Cancel</button>
            <button className="delete-btn toggle-outline-btn auto-width" onClick={() => props.delete()}>Delete</button>
        </>
    )
    return (
        <ModalComponent title="Delete Confirmation"
            close={() => props.close()}
            content={modalContent}
            className="small"
            actions={actions} />
    )
}

export default DeleteConfirmModalComponent