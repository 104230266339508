import { useCallback, useEffect, useState } from 'react'
import { createdMaterials } from '../../assets'
import { DEFAULT_GLTF_MATERIAL, DEFAULT_UV_PROPERTIES } from '../../constants'
import PredictiveSearchComponent from '../predictive-search/predictive-search.component'
import './selection-menu.component.css'

const SelectionMenuComponent = (props) => {

  const [menuOptions, setMenuOptions] = useState([])

  const onVisibilityClick = useCallback((p, show) => {
    const item = {visible: show, parts: p.type === 'group' ? p.parts.map((p) => p.id) : [p.id]}
    props.onVisibilityChange(item)
  }, [props])

  useEffect(() => {

    const items = []
    const plucked = [...props.parts].filter((i) => props.selected.indexOf(i.id) > -1)
    const pluckedIds = plucked[0].type === 'group' ? plucked[0].parts.map((p) => p.id) : plucked.map((p) => p.id)
    
    let hasExternalMat = false

    if (plucked[0].type === 'group') {
      if (plucked[0].material && plucked[0].material.id.indexOf(DEFAULT_GLTF_MATERIAL) < 0) {
        hasExternalMat = true
      }
      else {
        // Make sure all mats match and are not DEFAULT_GLTF_MATERIAL
        let firstMat
        let matsMatch = true
        let externalMat = true
        plucked[0].parts.forEach((p) => {
          if (externalMat) {
            if (p.material && p.material.id && p.material.id.indexOf(DEFAULT_GLTF_MATERIAL) < 0) {
              if (matsMatch) {
                if (!firstMat) {
                  firstMat = p.material.id
                }
                else {
                  matsMatch = firstMat === p.material.id
                }
              }
            }
            else {
              externalMat = false
            }
          }
        })

        hasExternalMat = externalMat
      }
    }
    else {
      hasExternalMat = plucked[0].material && plucked[0].material.id.indexOf(DEFAULT_GLTF_MATERIAL) < 0
    }

    // If the part is invisible, we'll only have two options, 'show', and clear selection
    if (props.invisible.some((i) => pluckedIds.includes(i))) {
      items.push({key: 'show', action: () => onVisibilityClick(plucked[0], true), className: "", icon: 'icon-eye', label: 'Show'})
      setMenuOptions(items)
      return
    }

    // Make sure all items are the same type
    let allowGrouping = props.selected.length > 1
    plucked.forEach((p) => {
      if (p.type === 'group' && allowGrouping) {
        allowGrouping = false
      }
    })

    if (allowGrouping) {
      items.push({key: 'group', action: props.onShowCreateGroup, className: "", icon: 'icon-folder', label: `Group (${props.selected.length} ${props.selected.length === 1 ? 'part' : 'parts'})`})
    }

    // Set ungroup
    const allowUngroup = plucked.length === 1 && plucked[0].type === 'group'

    if (allowUngroup) {
      items.push({ key: 'ungroup', action: () => props.onRequestUngroup(plucked[0].id), className: "", icon: 'icon-folder', label: `Ungroup (${plucked[0].parts.length} ${plucked[0].parts.length === 1 ? 'part' : 'parts'})` })
    }

    // Add material
    // if (!hasExternalMat) {
    //   items.push({ key: 'material', action: () => props.onRequestNewMaterial(plucked.map((p) => p.id)), className: "", svg: createdMaterials(13, 13), label: `Add material` })
    // }
    // else {
    //   items.push({ key: 'material', action: () => props.onRequestNewMaterial(plucked.map((p) => p.id)), className: "", svg: createdMaterials(13, 13), label: `Edit material` })
    // }

    // Copy
    const copyOptions = []
    if (hasExternalMat) {
      copyOptions.push({name: "Copy material", id: "copyMaterial"})
    }

    if ((plucked[0].material.meta && plucked[0].material.meta.color_rgb)) {
      copyOptions.push({name: "Copy texture only", id: "copyMaterialTexture"})
    }

    const pluckedMeta = plucked[0].meta ? plucked[0].meta : DEFAULT_UV_PROPERTIES
    const {uScale, vScale, uOffset, vOffset, rotation} = pluckedMeta
    const hasUV = uScale !== DEFAULT_UV_PROPERTIES.uScale || vScale !== DEFAULT_UV_PROPERTIES.vScale || uOffset !== DEFAULT_UV_PROPERTIES.uOffset || vOffset !== DEFAULT_UV_PROPERTIES.vOffset || rotation !== DEFAULT_UV_PROPERTIES.rotation
    if (hasUV) {
      copyOptions.push({name: "Copy pattern settings only", id: "copyPattern"})
      copyOptions.unshift({name: "Copy all", id: "copyAll"})
    }

    const copyItem = { key: 'copy', action: () =>  props.onRequestCopy('copyAll', plucked[0].id), icon: "icon-copy", label: `Copy`}
    if (copyOptions.length > 0) {
      copyItem.subitems = copyOptions
    }

    items.push(copyItem)

    // Paste 
    if (props.copyRef && props.copyRef.partId !== plucked[0].id) {
      items.push({ key: 'paste', action: () => props.onRequestPaste(plucked[0].id), icon: "icon-file", label: `Paste`})
    }

    // Hide 
    if (plucked.length === 1) {
      items.push({key: 'hide', action: () => onVisibilityClick(plucked[0]), className: "", icon: 'icon-eye-off', label: 'Hide'})
    }

    // Set
    setMenuOptions(items)
  }, [props, onVisibilityClick])

  const handleAction = (e, action) => {
    e.preventDefault()
    e.stopPropagation()
    action()
  }

  const handleSelectSubitem = (optionKey, subitemKey) => {
    const plucked = [...props.parts].filter((i) => props.selected.indexOf(i.id) > -1)

    if (optionKey === 'copy') {
      props.onRequestCopy(subitemKey, plucked[0].id)
    }
  }

  return (
    <div className="threedy-lab-selection-menu" style={{top: props.top, left: props.left}}>
      <ul>
        {
          menuOptions.map((option) => {
            return (
              <li key={option.key} className={option.subitems ? "has-subitems" : ""}>
                { !option.subitems && (
                  <button className={option.className} onClick={(e) => handleAction(e, option.action)}>{option.icon && <span className={option.icon}></span>}{option.svg && option.svg} {option.label}</button>
                )}
                { option.subitems && (
                  <button onClick={(e) => handleAction(e, option.action)}><span className={option.icon}></span> {option.label} <span className="icon-chevron-right"></span></button>
                )}
                { option.subitems && (
                  <PredictiveSearchComponent position={{top: props.top, left: props.left*2}} hideCancel hideSearch items={option.subitems} icon={option.icon} onSelect={(subitemKey) => handleSelectSubitem(option.key, subitemKey)} /> 
                )}
              </li>
            )
          })
        }
      </ul>
    </div>
  )
}

export default SelectionMenuComponent