import './modal.component.css'

const ModalComponent = ({
  className,
  modalClassName,
  menuClassName,
  headerClassName,
  title,
  subtitle,
  close,
  hideClose,
  contentClassName,
  content,
  actionClassName,
  actions,
}) => {
  return (
    <div className={"threedy-lab-workspace-modal-container " + (className || "")}>
      <div className={"threedy-lab-workspace-modal " + (modalClassName || "")}>
        <div className={"threedy-lab-workspace-modal-menu " + (menuClassName || "")}>
          <h5 className={(headerClassName || "")}>{title} {subtitle && <span>{subtitle}</span>}</h5>
          {!hideClose && <button onClick={close}><span className="icon-close"></span></button>}
        </div>
        <div className={"threedy-lab-workspace-modal-content " + (contentClassName || "")}>{content}</div>
        {actions && <div className={"threedy-lab-workspace-modal-actions " + (actionClassName || "")}>{actions}</div>}
      </div>
    </div>
  )
}

export default ModalComponent