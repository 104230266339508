import { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setToast } from "../../redux/ui.redux.slice"
import { resizeIfLarger } from "../../utils/ui.util"
import * as photon from "aritize-photon-rs"
import SpinnerComponent from "../spinner/spinner.component"
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import MaterialImageCreateComponent from "../material-image-create/material-image-create.component"
import './material-texture-expand.component.css'
import { useExpandMaterialMutation } from "../../redux/api.redux.slice"
import { upload } from "../../assets"
import FileUploadLimitMaximumModalComponent from "../alerts/file-upload-limit-maximum-modal.component"
import SupportedImageTypesModalComponent from "../alerts/supported-image-types-modal.component"
import PerspectiveToolComponent from "./perspective-tool/perspective-tool.component"
import withMaterialBuilderModal from "../material-builder/material-builder-modal/material-builder-modal.component"
import useRefPhotos from "../../pages/workspace/hooks/useRefPhotos"

const MaterialTextureExpandComponent = (props) => {

  const { albedoMaps, armMaps, normalMaps } = props
  const dispatch = useDispatch()
  const imgRef = useRef(false)
  const inputCanvasRef = useRef()
  const previewCanvasRef = useRef()
  const debounceRef = useRef()
  const heatmapRef = useRef()
  const correctedPerspectiveRef = useRef()
  const [expandMaterial] = useExpandMaterialMutation()
  const [loading, setLoading] = useState(false)
  const [imageFile, setImageFile] = useState(false)
  const [crop, setCrop] = useState(false)
  const [step, setStep] = useState(1)
  const [output, setOutput] = useState(false)

  const [imageSettings, setImageSettings] = useState({
    shadowAmt: 0.0,
    shadowTone: 0.5,
    highlightAmt: 0.0,
    highlightTone: 0.5,
    blurRadius: 2,
    patternSize: 20
  })
  const fileInputRef = useRef()
  const selectedProject = useSelector((state) => state.ui.selectedProject)
  const [showUploadLimitMaxModal, setShowUploadLimitMaxModal] = useState(false)
  const [showSupportedImageModal, setShowSupportedImageModal] = useState(false)
  const { addRefPhotos } = useRefPhotos()
  const photonRef = useRef()

  useEffect(() => {
    const handleDragOver = (e) => {
      e.preventDefault()
    }

    document.addEventListener("dragover", handleDragOver)

    return () => document.removeEventListener("dragover", handleDragOver)
  })

  const handleFileChange = (e, droppedImage = null) => {
    const file = droppedImage ? droppedImage : e.target.files[0]
    const maxFileSize = 10 * 1024 * 1024  // 10mb max

    if (file.size > maxFileSize) {
      setShowUploadLimitMaxModal(true)
      setImageFile(false)
      return
    }

    const reader = new FileReader()
    reader.onload = (e) => {
      const thumb = e.target.result
      setImageFile({src: file, name: file.name.replace(/[\W_.]+/g, "_"), thumb})
    }
    reader.readAsDataURL(file)
  }

  const handlePreviousBtnClick = () => {
    if (step === 1) {
      props.showMaterialSelection()
      return
    }
    if (step === 2) {
      setStep(1)
    }
    else if (step === 3) {
      setStep(2)
    }
    else if (step === 4) {
      setStep(3)
    }
    else if (step === 5) {
      setStep(4)

      // Re-draw output
      setTimeout(() => drawHeatMapOnCurrentSettings())
    }
    else if (step === 6) {
      setStep(5)
      setLoading(false)
    }
    else if (step === 7) {
      setStep(5)
    }
    else if (step === 8) {
      setStep(7)
    }
  }

  const handleNextBtnClick = () => {
    if (step === 1) {
      setStep(2)
      addRefPhotos(imageFile.src)
    } 
    else if (step === 2) {
      handleWarp()
    }
    else if (step === 3) {
      handleDoneCropping()
    }
    else if (step === 4) {
      setStep(5)
    }
    else if (step === 5) {
      handleCreateTexture()
    }
    else if (step === 7) {
      setStep(8)
    }
  }

  const getNextBtnDisabledState = () => {
    if (step === 1) {
      return imageFile === false || !imageFile.thumb
    }
    else if (step === 2) {
      return false
    }
    else if (step === 3) {
      return crop === false
    }
    else if (step === 4) {
      return false
    }
    else if (step === 5) {
      return false
    }
    else if (step === 7) {
      return false
    }

    return true
  }

  const handleSkipWarp = () => {
    setImageFile({...imageFile, corrected: imageFile.thumb})
    setStep(3)
  }

  const handleWarp = async () => {
    if (!correctedPerspectiveRef.current) {
      handleSkipWarp()
      return
    }

    // Use corrected perspective image
    setImageFile({...imageFile, corrected: correctedPerspectiveRef.current})
    setStep(3)
  }

  const handleDoneCropping = (cropData) => {
    const canvas = document.createElement('canvas')
    const ctx = canvas.getContext('2d')
    const image = imgRef.current

    if (!ctx || !image) {
      throw new Error('No 2d context/image')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const pixelRatio = 1

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)
    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY
    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2
    ctx.save()

    ctx.translate(-cropX, -cropY)
    ctx.translate(centerX, centerY)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
      image,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
      0,
      0,
      image.naturalWidth,
      image.naturalHeight,
    )

    ctx.restore()

    const phImage = photon.open_image(canvas, canvas.getContext("2d"))

    // Create photon image + blur
    const {width, height, img} = resizeIfLarger(phImage, canvas.width, canvas.height, 300)
    const blurImg = photon.gaussian_blur2(img, imageSettings.blurRadius)
    photonRef.current = {width, height, img, blurImg}

    // Continue
    setStep(4)
    
    // Draw image
    setTimeout(drawHeatMapOnCurrentSettings)
  }

  const drawHeatMapOnCurrentSettings = () => {
    const histogram_block_size = 40
    const settings = imageSettings

    const {img, height, width, blurImg} = photonRef.current
    const updatedImage = new photon.PhotonImage(img.get_raw_pixels(), width, height)

    // Preview
    const previewCanvas = previewCanvasRef.current
    const previewCtx = previewCanvas.getContext("2d")
    previewCanvas.width = width
    previewCanvas.height = height

    // Create heatmap
    const heatmap = photon.adjust_hlsh_with_blur_heatmap(updatedImage, blurImg, settings.shadowTone, settings.shadowAmt, settings.highlightTone, settings.highlightAmt, histogram_block_size)

    // Visualize the heatmap on an HTML canvas
    drawHeatmap(heatmap, parseInt(width/histogram_block_size), parseInt(height/histogram_block_size), 20)
   
    // Draw preview
    const previewImage = new photon.PhotonImage(updatedImage.get_raw_pixels(), width, height)
    photon.putImageData(previewCanvas, previewCtx, previewImage)

    // Set output
    setOutput(previewCanvas.toDataURL("image/png"))
  }

  const drawHeatmap = (heatmap, n_cols, n_rows, block_size) => {
    try {
      const canvas = heatmapRef.current
      const ctx = canvas.getContext("2d")
      canvas.width = n_cols * block_size
      canvas.height = n_rows * block_size;
      const max = Math.max.apply(null, heatmap)
      
      for (let x=0; x<n_cols; x++) {
        for (let y=0; y<n_rows;y++) {
          ctx.fillStyle = `rgba(50,200,150,${heatmap[y*n_cols + x]/max})`
          ctx.fillRect(x*block_size, y*block_size, block_size, block_size)
        }
      }
    }
    catch (e) {
      console.error("Error drawing heatmap:", e)
    }
  }

  const handleDebounceImageSettings = (e) => {
    const {name, value} = e.target
    const updated = {...imageSettings, [name]: value}
    setImageSettings(updated)

    if (debounceRef.current) {
      clearTimeout(debounceRef.current)
      debounceRef.current = null
    }

    debounceRef.current = setTimeout(() => {
      if (name !== 'patternSize') {
        drawHeatMapOnCurrentSettings()
      }
    }, 250)
  }

  const handleCreateTexture = async () => {
    try {
      // Show loader
      setStep(6)
      setLoading(true)

      const data = new FormData()
      const blob = await (await fetch(output)).blob()
      const file = new File([blob], 'inputPatch.png', {type:"image/png", lastModified: new Date()})

      data.set("file", file)
      data.set("block_size", parseInt(imageSettings.patternSize))

      const textureRes = await expandMaterial({projectId: selectedProject.id, body: data}).unwrap()
      const textureBlob = await (await fetch(textureRes)).blob()
      const expanded = await blobToUrl(textureBlob)
      setImageFile({...imageFile, expanded})
      setLoading(false)
      setStep(7)
    }
    catch (e) {
      console.error("Error creating texture: ", e)
      dispatch(setToast({message: "Uh oh. We had an issue creating your texture, please try again.", isError: true}))
      setStep(5)
      setLoading(false)
    }
  }

  const blobToUrl = (blob) => {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onload = (e) => resolve(e.target.result)
      reader.readAsDataURL(blob)
    })
  }

  const handleFileDrop = (e) => {
    e.preventDefault()
    e.currentTarget.classList.remove("drop-section")
    const image = e.dataTransfer.files[0]
    const acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg']
    if (acceptedTypes.includes(image.type)) {
      handleFileChange(false, image)
    } else {
      setShowSupportedImageModal(true)
      setImageFile(false)
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.currentTarget.classList.add("drop-section")
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.currentTarget.classList.remove("drop-section")
  }

  const imageUploadButton = (buttonLabel = 'Choose File') => (
    <div className="image-upload-container">
      <input style={{display: 'none'}} ref={fileInputRef} type="file" accept=".jpeg,.jpg,.png" onChange={handleFileChange} />
      <button onClick={() => fileInputRef.current.click()}>{buttonLabel}</button>
    </div>
  )

  const handlePerspectiveUpdate = (canvas) => {
    const imageUrl = canvas.toDataURL()
    correctedPerspectiveRef.current = imageUrl
  }

  return (
    <>
    <div className={loading ? "threedy-skeleton material-texture-expand" : "material-texture-expand"}>
      <div className="step-container">
        {step === 1 && (
          <>
            <div className="step-header">
              <h4>Select an image below to get started.</h4>
              <h5><span className="icon-help-circle"></span> Tip: For best results, pick an image that has uniform lighting, a repeatable pattern, minimal skewness and is at least 1k resolution</h5>
            </div>
            {
              !imageFile && (
                <div className="upload-image-section" onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleFileDrop}>
                  <span className="upload-icon">{upload}</span>
                  <p>Drag an image here or</p>
                  {imageUploadButton()}
                </div>
              )
            }
            {
              imageFile && imageFile.src && (
                <div className="uploaded-image-section">
                  <div className="preview-image pos-relative">
                    <img src={imageFile.thumb} alt="preview thumb"/>
                    <span style={{cursor: 'pointer'}} onClick={() => setImageFile(false)} className="icon-x"></span>
                  </div>
                  <div className="preview-image-details">
                    <p>{imageFile.name}</p>
                    {imageUploadButton('Choose a New File')}
                  </div>
                </div>
              )
            }
          </>
        )}
        {step === 2 && (
          <>
            <div className="step-header">
              <h4>To correct any perspective issues, please drag each circle to transform your image</h4>
            </div>
            <PerspectiveToolComponent image={imageFile.thumb} onUpdate={handlePerspectiveUpdate} />
          </>
        )}
        {step === 3 && (
          <>
            <div className="step-header">
              <h4>Crop the texture you would like to create</h4>
              <h5><span className="icon-help-circle"></span> Tips: 
                <ul className="crop-tips">
                  <li>Pick the part with the most uniform lighting</li>
                  <li>Ensure the crop only contains the patch that you want to make into a texture</li>
                  <li>Avoid imperfections</li>
                  <li>Try to pick a patch with multiple repetitions of your pattern</li>
                </ul>
              </h5>
            </div>
            <div className="crop-container">
              <ReactCrop crop={crop} onChange={c => setCrop(c)}>
                <img ref={imgRef} draggable={false} src={imageFile.corrected} alt="corrected thumb"/>
              </ReactCrop>
            </div>
          </>
        )}
        {step === 4 && (
          <>
            <div className="step-header">
              <h4>Adjust the lighting to make it uniform. We have included a heatmap to help you.</h4>
              <h5><span className="icon-help-circle"></span> Tip: Cover the heat map in a uniform green color. If the heat map is already uniform, you can skip this step</h5>
            </div>
            <div className="material-texture-lighting-adjust">
              <div className="toggle-canvas-holder">
                <canvas className="input-canvas" ref={inputCanvasRef} />
                <canvas className="preview-canvas" ref={previewCanvasRef} />
                <canvas className="heatmap-canvas" ref={heatmapRef} />
              </div>
              <div className="toggle-popup-setting">
                <h5>Shadow Amount</h5>
                <input className="slider" type="range" min="0" max="1" step="0.025" name="shadowAmt" value={imageSettings.shadowAmt} onChange={handleDebounceImageSettings} />
                <div className="slider-percentage">
                  <span>0%</span>
                  <span>100%</span>
                </div>
              </div>
              <div className="toggle-popup-setting">
                <h5>Highlight Amount</h5>
                <input className="slider" type="range" min="0" max="1" step="0.025" name="highlightAmt" value={imageSettings.highlightAmt} onChange={handleDebounceImageSettings} />
                <div className="slider-percentage">
                  <span>0%</span>
                  <span>100%</span>
                </div>
              </div>
            </div>
          </>
        )}
        {step === 5 && (
          <>
            <div className="step-header">
              <h4>Adjust the size of your output pattern</h4>
            </div>
            <div className="material-texture-lighting-adjust">
              <div className="toggle-pattern-holder" style={{backgroundImage: `url(${output})`}}>
                <div className="toggle-pattern-visual" style={{height: `${imageSettings.patternSize}%`, width: `${imageSettings.patternSize}%`}}></div>
              </div>
              <div className="toggle-popup-setting">
                <h5>Pattern Size</h5>
                <input className="slider" type="range" min="5" max="50" step="1" name="patternSize" value={imageSettings.patternSize} onChange={handleDebounceImageSettings} />
                <div className="slider-percentage">
                  <span>5%</span>
                  <span>50%</span>
                </div>
              </div>
            </div>
          </>
        )}
        {step === 6 && (
          <>
            <p className="centered">Creating texture...</p>
            <div className="threedy-lab-spinner-wrapper">
              <div className="threedy-lab-spinner-container inline"><SpinnerComponent inline /></div>
            </div>
          </>
        )}
        {step === 7 && (
          <>
            <div className="step-header">
              <h4>Here's your texture. Look good?</h4>
            </div>
            <div className="toggle-perspective-correction">
              <img draggable={false} src={imageFile.expanded} alt="expanded thumb"/>
            </div>
          </>
        )}
        {step === 8 && (
          <>
            <MaterialImageCreateComponent 
              hideUI
              onFirstBack={handlePreviousBtnClick}
              showMaterialSelection={props.showMaterialSelection} 
              image={imageFile.expanded}
              title="Create From a Reference Photo"
              onSave={props.onSave}
              showPreviewScene 
              albedoMaps={albedoMaps}
              armMaps={armMaps}
              normalMaps={normalMaps}
            />
          </>
        )}
      </div>
      <div className="material-color-create-footer">
        <button onClick={handlePreviousBtnClick} className="back-btn"><span className="icon-arrow-left"></span> Back</button>
        <button onClick={handleNextBtnClick} disabled={getNextBtnDisabledState()} className="next-btn">Next</button>
      </div>
    </div>
      {showUploadLimitMaxModal &&
        <FileUploadLimitMaximumModalComponent sizeInMB={10} close={() => setShowUploadLimitMaxModal(false)} />}
      {showSupportedImageModal &&
        <SupportedImageTypesModalComponent close={() => setShowSupportedImageModal(false)} />}
    </>
  )
}

export default withMaterialBuilderModal(MaterialTextureExpandComponent)

