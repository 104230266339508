import { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"
import { logout } from "../../../redux/auth.redux.slice"
import GlobalSearchComponent from "../../global-search/global-search-component"
import DropdownLinksComponent from "../../dropdown-links/dropdown-links.component"
import { T } from "../../../constants"

const NonProjectHeader = () => {

  const {user} = useSelector((state) => state.auth)
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const [showUseLibrarySearch, setShowUseLibrarySearch] = useState()

  const startProjectLinks = [
    { id: "create-3d-model", label: "Texture a 3D Model", onSelect: () => navigate('/dashboard', { state: { projectType: T._3D_MODEL }, replace: true }) },
    { id: "create-configurator", label: "Build a Configurator", onSelect: () => navigate('/dashboard', { state: { projectType: T.CONFIGURATOR }, replace: true }) },
    { id: "create-variations", label: "Create Model Variations", onSelect: () => navigate('/dashboard', { state: { projectType: T.VARIATIONS }, replace: true }) },
    { id: "create-material", label: "Create a Material", onSelect: () => navigate('/dashboard', { state: { projectType: T.MATERIAL }, replace: true }) },
  ]
  const userAvatarLinks = [
    { id: "account-settings", label: "Account Settings", icon: 'icon-settings', onSelect: () => navigate('/settings/profile') },
    { id: "logout", label: "Log Out", icon: 'icon-share_windows', onSelect: () => dispatch(logout()) },
  ]

  const getUserAvatar = (u) => {
    return <div className="user-avatar">{u && u.name[0]}</div>
  }

  const getCreateProjectButton = () => {
    return (
      <button className='toggle-primary-btn new-project-btn'>
        <span>Start a New Project</span>
        <span className="icon-expand_more"></span>
      </button>
    )
  }

  const getIcon = () => {
    const rootEl = document.querySelector(".toggle-root-container")
    if (rootEl) {
      const darkMode = rootEl.classList.contains("dark")
      return darkMode ? "/images/Toggle3D_Horizontal_Light.png" : "/images/Toggle3D_Horizontal_Dark.png"
    }
  }

  return (
    <>
      <div className="toggle-header-left-container">
        <img onClick={() => navigate("/dashboard")} src={getIcon()} alt="toggle3D" />
      </div>
      <div className="toggle-header-right-container">
        {showUseLibrarySearch && <GlobalSearchComponent close={() => setShowUseLibrarySearch(false)} />}
        {!showUseLibrarySearch && <button className="toggle-default-btn global-search-btn" onClick={() => setShowUseLibrarySearch(true)}><span className="icon-search"></span></button>}
        <DropdownLinksComponent element={getCreateProjectButton()} items={startProjectLinks} />
        <DropdownLinksComponent element={getUserAvatar(user)} items={userAvatarLinks} position="right" />
      </div>
    </>
  )
}

export default NonProjectHeader