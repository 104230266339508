import "./horizontal-or-divider.component.css"

const HorizontalOrDividerComponent = (props) => {
    const { className } = props
    return (
        <div className={`${className} orDivider`} >
            <span className="divider-text">or</span>
            <hr />
        </div>
    )
}

export default HorizontalOrDividerComponent