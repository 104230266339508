import { useEffect, useRef, useState } from "react"
import { Rnd } from "react-rnd"
import "./perspective-tool.component.css"
import PerspectiveUtils from "./perspective-utils"


const PerspectiveToolComponent = (props) => {

  const {image, onUpdate} = props
  const canvasRef = useRef()
  const containerRef = useRef()
  const elRef = useRef()
  const imageRef = useRef()
  const ctxRef = useRef()
  const controlRef = useRef()
  const drawingRef = useRef()
  const [controlPoints, setControlPoints] = useState([])
  const [lineCoords, setLineCoords] = useState([])

  const drawLines = (arrOfPoints) => {
    // Top line
    const top = {
      x1: arrOfPoints[0][0],
      x2: arrOfPoints[1][0],
      y1: arrOfPoints[0][1],
      y2: arrOfPoints[1][1]
    }

    // Right line
    const right = {
      x1: arrOfPoints[1][0],
      x2: arrOfPoints[4][0],
      y1: arrOfPoints[1][1],
      y2: arrOfPoints[4][1]
    }

    // Bottom line
    const bottom = {
      x1: arrOfPoints[3][0],
      x2: arrOfPoints[4][0],
      y1: arrOfPoints[3][1],
      y2: arrOfPoints[4][1]
    }

    // Left line
    const left = {
      x1: arrOfPoints[0][0],
      x2: arrOfPoints[3][0],
      y1: arrOfPoints[0][1],
      y2: arrOfPoints[3][1]
    }

    // Diag left 1
    const diagLeftOne = {
      x1: arrOfPoints[0][0],
      x2: arrOfPoints[2][0],
      y1: arrOfPoints[0][1],
      y2: arrOfPoints[2][1]
    }

    // Diag left 2
    const diagLeftTwo = {
      x1: arrOfPoints[3][0],
      x2: arrOfPoints[2][0],
      y1: arrOfPoints[3][1],
      y2: arrOfPoints[2][1]
    }

    // Diag right 1
    const diagRightOne = {
      x1: arrOfPoints[1][0],
      x2: arrOfPoints[2][0],
      y1: arrOfPoints[1][1],
      y2: arrOfPoints[2][1]
    }

    // Diag right 2
    const diagRightTwo = {
      x1: arrOfPoints[4][0],
      x2: arrOfPoints[2][0],
      y1: arrOfPoints[4][1],
      y2: arrOfPoints[2][1]
    }

    setLineCoords([top, right, bottom, left, diagLeftOne, diagLeftTwo, diagRightOne, diagRightTwo])
  }

  useEffect(() => {

    if (controlPoints.length < 1) {
      return
    }

    ctxRef.current.clearRect(0,0, drawingRef.current.container[0], drawingRef.current.container[1])
    const corners = controlPoints
    const w = drawingRef.current.dest[0]
    const h = drawingRef.current.dest[1]
    const [ofX, ofY] = drawingRef.current.offset
    const destCorners = corners.map((c) => {
      return [c[0] + ofX + 10, c[1] + ofY + 10]
    })

    PerspectiveUtils.drawTriangle(imageRef.current, ctxRef.current, drawingRef.current, [0, 0], [w/2, h/2], [0, h], destCorners[0], destCorners[2], destCorners[3])
    PerspectiveUtils.drawTriangle(imageRef.current, ctxRef.current, drawingRef.current, [0, 0], [w/2, h/2], [w, 0], destCorners[0], destCorners[2], destCorners[1])
    PerspectiveUtils.drawTriangle(imageRef.current, ctxRef.current, drawingRef.current, [w, 0], [w/2, h/2], [w, h], destCorners[1], destCorners[2], destCorners[4])
    PerspectiveUtils.drawTriangle(imageRef.current, ctxRef.current, drawingRef.current, [0, h], [w/2, h/2], [w, h], destCorners[3], destCorners[2], destCorners[4])
    drawLines(destCorners)
    onUpdate(canvasRef.current)

}, [controlPoints, onUpdate])

  useEffect(() => {


    // Load image
    const img = new Image()
    img.onload = async () => {

      const rect = elRef.current.getBoundingClientRect()
      const wRatio = rect.width  / img.naturalWidth
      const hRatio =  rect.height / img.naturalHeight
      const ratio = Math.min(wRatio, hRatio)
      const newW = img.naturalWidth * ratio
      const destW = Math.round(newW * 0.75)
      const newH = img.naturalHeight * ratio
      const destH = Math.round(newH * 0.75)
      const topLeft = [-10, -10]
      const topRight = [destW - 10, -10]
      const bottomRight = [destW - 10, destH - 10]
      const bottomLeft = [-10, destH - 10]
      const center = [destW/2, destH/2]
      setControlPoints([topLeft, topRight, center, bottomLeft, bottomRight])
      canvasRef.current.width = newW
      canvasRef.current.height = newH
      controlRef.current.style.width = destW + 'px'
      controlRef.current.style.height = destH + 'px'
      containerRef.current.style.width = newW + 'px'
      containerRef.current.style.height = newH + 'px'
      const offset = [(newW - destW)/2, (newH - destH)/2]
      imageRef.current = img
      drawingRef.current = {
        container: [newW, newH],
        source: [img.naturalWidth, img.naturalHeight],
        dest: [destW, destH],
        offset
      }
      ctxRef.current = canvasRef.current.getContext("2d")
    }

    img.src = image

  }, [image])

  const handleUpdateControl = (i, d) => {
    setControlPoints([...controlPoints].map((c, index) => {
      if (i === index) {
        return [d.x, d.y]
      }

      return c
    }))
  }

  return (
    <div className="toggle-perspective-correction" ref={elRef}>
      <div className="toggle-perspective-correction-container" ref={containerRef}>
        <canvas ref={canvasRef} />
        <svg>
          {lineCoords.map((l, i) => {
            return (
              <line key={i} x1={l.x1} x2={l.x2} y1={l.y1} y2={l.y2} />
            )
          })}
        </svg>
        <div className="toggle-perspective-control-pts" ref={controlRef}>
        { controlPoints.map((c, i) => {
          return (
            <Rnd key={i} className="toggle-perspective-control-pt" 
              enableResizing={false} 
              position={{x: c[0], y: c[1]}}
              bounds={containerRef.current}
              onDragStop={(e, d) => { handleUpdateControl(i, d) }}
            /> 
          )
        })}
        </div>
      </div>
    </div>
  )
}

export default PerspectiveToolComponent