import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { ReactSortable } from "react-sortablejs"
import { threeHorizontalLines } from "../../assets"
// import { glassSlabIcon } from "../../assets"
import { API_BASE_URL, APP_RESOURCES_URL, EVENTS } from "../../constants"
import { useLazyGetMaterialByIdQuery, useLazyGetPersonalMaterialByIdQuery, useLazyGetPublicMaterialByIdQuery, useLazyGetPublicPersonalMaterialByIdQuery } from "../../redux/api.redux.slice"
import { setToast } from "../../redux/ui.redux.slice"
import ContextMenuComponent from "../context-menu/context-menu.component"
import EnterKeyInputComponent from "../enter-key-input/enter-key-input.component"
import PredictiveSearchComponent from "../predictive-search/predictive-search.component"
import "./configurator-viewer.component.css"

const ConfiguratorViewerComponent = (props) => {

  const [workingItems, setWorkingItems] = useState([])
  const [renamingItem, setRenamingItem] = useState(false)
  const [renamingOptionItem, setRenamingOptionItem] = useState(false)
  const [showPartsSelector, setShowPartsSelector] = useState(false)
  const [previewedOptionIds, setPreviewedOptionIds] = useState([])
  const [showVariationMenu, setShowVariationMenu] = useState(false)
  const [getMaterialByIdQuery] = useLazyGetMaterialByIdQuery()
  const [getPublicMaterialByIdQuery] = useLazyGetPublicMaterialByIdQuery()
  const [getPersonalMaterialByIdQuery] = useLazyGetPersonalMaterialByIdQuery()
  const [getPublicPersonalMaterialByIdQuery] = useLazyGetPublicPersonalMaterialByIdQuery()
  const dispatch = useDispatch()
  const [expanded, setExpanded] = useState([])

  useEffect(() => {
    console.log("SET WORKING ITEMS")
    setWorkingItems(props.items)
  }, [props.items])

  const handleStartRenameItem= (itemId) => {
    if (props.shared) {
      return
    }

    setRenamingItem(itemId)
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: false}}))
  }

  const handleFinalizeItemNameChange = (pid, value) => {
    setRenamingItem(false)
    props.onUpdate(pid, {name: value})
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: true}}))
  }

  const handleStartRenameOptionItem= (optionId) => {
    setRenamingOptionItem(optionId)
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: false}}))
  }

  const handleFinalizeOptionItemNameChange = (pid, oid, val) => {
    setRenamingOptionItem(false)
    props.onUpdate(pid, {options: [...workingItems.find((p) => p.id === pid).options].map((o) => o.id !== oid ? o : {...o, name: val})})
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: true}}))
  }

  // const handleNewConfiguration = (e) => {
  //   // Let the user select the parts
  //   setShowPartsSelector({
  //     position: {left: e.clientX + 'px', top: e.clientY + 'px'}
  //   })

  //   // Pause keyboard events
  //   document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: false}}))
  // }

  const createNewConfiguration = (partId) => {
    console.log('createNewConfiguration: ', partId)
    setShowPartsSelector(false)
    props.onCreate(partId)

    // Resume keyboard events
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: true}}))
  }

  const handleCancelNewConfiguration = () => {
    setShowPartsSelector(false)

    // Resume keyboard events
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: true}}))
  }

  const handleClick = (e, pid) => {
    handleToggleExpandGroup(pid)
    switch (e.detail) {
      case 2:
        handleStartRenameItem(pid)
        break
      default: break
    }
  }

  // const handleOptionClick = (e, oid) => {
  //   switch (e.detail) {
  //     case 2:
  //       handleStartRenameOptionItem(oid)
  //       break
  //     default: break
  //   }
  // }

  const handlePreviewOption = async (item, option) => {

    try {
      const {id, personal} = option
      let mat 
      let shouldCache = true
      if (props.cachedMaterials[id]) {
        console.log('-- using api mat cache --')
        mat = props.cachedMaterials[id]
        shouldCache = false
      }
      else {
        if (!personal) {
          mat = props.shared ? await getPublicMaterialByIdQuery(id).unwrap() : await getMaterialByIdQuery(id).unwrap()
        }
        else {
          mat = props.shared ? await getPublicPersonalMaterialByIdQuery(id).unwrap() : await getPersonalMaterialByIdQuery(id).unwrap()
        }
      }

      if (!mat) {
        dispatch(setToast({message: "Uh oh. We couldn't find the material to preview, please try again.", isError: true}))
        return
      }

      if (shouldCache) {
        console.log('-- cache mat')
        props.onCacheMaterial(mat)
      }

      props.onPreviewOption(item, mat)
      // setPreviewedOptionIds((prev) => [...prev.filter(i => !i.startsWith(item.id)), item.id + id])
    }
    catch (e) {
      console.error("Error previewing option: ", e)
      dispatch(setToast({message: "Uh oh. We couldn't find the material to preview, please try again.", isError: true}))
    }
  }

  const handleShowVariationMenu = (e, pid, oid = false) => {
    e.stopPropagation()

    const items = []
    if (oid) {
      items.push({id: 'rename', label: <><span className="icon-edit-2"></span> Rename</>, action: () => handleStartRenameOptionItem(oid)})
      items.push({id: 'delete', className: "delete-btn", label: <><span className="icon-trash"></span> Delete</>, action: () => props.onDeleteVariant(pid, oid)})
    } else {
      items.push({id: 'rename', label: <><span className="icon-edit-2"></span> Rename</>, action: () => handleStartRenameItem(pid)})
      items.push({id: 'delete', className: "delete-btn", label: <><span className="icon-trash"></span> Delete</>, action: () => props.onDeleteVariation(pid)})
    }

    setShowVariationMenu({position: { left: e.clientX + 'px', top: e.clientY + 'px' }, items })
  }

  const handleToggleExpandGroup = (itemId) => {
    if (expanded.indexOf(itemId) > -1) {
      setExpanded([...expanded].filter((e) => e !== itemId))
    }
    else {
      setExpanded([...expanded, itemId])
    }
  }

  // Set selected options on load
  useEffect(() => {

    const arr = []
    if (Object.keys(props.selectedOptions).length > 0) {
      Object.keys(props.selectedOptions).forEach((sk) => {
        arr.push(sk + props.selectedOptions[sk])
      })
    }

    setPreviewedOptionIds(arr)

  }, [props.selectedOptions])

  // Sorting the options/variations
  let updatedOptionsDataAfterSort = null // Temporarily keep the data that is to be updated after sorting
  const handleSortingEnd = e => {
    if (e.newIndex !== e.oldIndex) {
      props.onUpdate(updatedOptionsDataAfterSort.pid, {options: updatedOptionsDataAfterSort.sortedOptions})
    }
  }

  const handleSorting = (pid, sortedOptions) => {
    updatedOptionsDataAfterSort = {pid, sortedOptions: sortedOptions.map(({chosen, ...rest}) => ({...rest}))}
  }

  return (
    <div className="variations-configure-container">
      {workingItems.length > 0 ?
        <ul>
          {
            workingItems.map((p) => {
              const sortedOptions = p.options.map(optItem => ({...optItem, chosen: true}))
              const setSortedOptions = (sortedOptions) => handleSorting(p.id, sortedOptions)
              return (
                
                <li key={p.id} className={props.selectedConfigurationId === p.id ? "open variation-item" : "variation-item"}>
                  {!props.single && (
                    <div className="part-or-group-for-option">
                      {renamingItem === p.id ? (
                        <EnterKeyInputComponent value={p.name} placeholder="Name..." onValueChange={(val) => handleFinalizeItemNameChange(p.id, val)} />
                      ) : (
                        <button className="name-button smaller" onClick={(e) => handleClick(e, p.id)}>
                          {expanded.indexOf(p.id) > -1 ? <i className="icon-chevron-down"></i> : <i className="icon-chevron-right"></i>}
                          {p.type === 'group' && <i className="icon-folder"></i>}
                          {p.type === 'part' && <i className="icon-layers"></i>}
                          <span className="name-label">{p.name ? p.name : <i>Untitled</i>}</span>
                        </button>
                      )}
                      {!props.shared && (
                        <>
                          <div className="button-group">
                            {/* <button className="variation-more-btn" onClick={() => props.onSelectConfiguration(p.id)}><span className="icon-box"><i className="icon-plus"></i></span></button> */}
                            <button onClick={(e) => handleShowVariationMenu(e, p.id)}><span className="icon-more-vertical"></span></button>
                          </div>
                        </>
                      )}
                      { showVariationMenu && <ContextMenuComponent items={showVariationMenu.items} position={showVariationMenu.position} onClose={() => setShowVariationMenu(false)} /> }
                    </div>
                  )}
                  {
                    expanded.includes(p.id) && (
                      <ReactSortable
                        className="variant-options"
                        tag="ul"
                        list={sortedOptions}
                        setList={setSortedOptions}
                        swap
                        animation={200}
                        delayOnTouchStart={true}
                        delay={2}
                        handle=".sorting-handle"
                        onEnd={handleSortingEnd}
                      >
                        {p.options.map((o) => {
                          let src
                          // if (!o.external) {
                            if (o.personal) {
                              src = `${API_BASE_URL}public/collections/${o.id}/thumb`
                            }
                            else {
                              src = `${APP_RESOURCES_URL}material-thumbs/${o.id}/thumb.png`
                            }
                          // }
                          // else {
                          //   src = o.thumbnail
                          // }

                          // const disableDefaultDeletion = o.optionId === 'default' ? 'disabled' : null
                          return (
                            <li key={o.id} className={previewedOptionIds.includes(p.id + o.id) ? 'selected' : null}>
                              {!props.shared && <span className="sorting-handle">{threeHorizontalLines}</span>}
                              <div className="variant-thumb" style={{backgroundImage: `url(${src})`}}></div> 
                              {renamingOptionItem === o.id ? (
                                <EnterKeyInputComponent value={o.name} placeholder="Name..." onValueChange={(val) => handleFinalizeOptionItemNameChange(p.id, o.id, val)} />
                              ) : (
                                <div className="variant-name" onClick={(e) => handlePreviewOption(p, o)}>
                                  {o.name ? o.name : <i>Untitled</i>}
                                </div>
                              )}
                              {!props.shared && (
                                <div className="variant-options-buttons">
                                  <button onClick={(e) => handleShowVariationMenu(e, p.id, o.id)}><span className="icon-more-vertical"></span></button>
                                </div>
                              )}
                            </li>
                          )
                        })}
                    </ReactSortable> 
                    )
                  }
                </li>
              )
            })
          }
        </ul> : <h5>No options yet...</h5>
      }
      { props.single && workingItems[0] && workingItems[0].options && workingItems[0].options.length < 1 && <h5>No variants just yet...</h5> }
      { props.parts && props.parts.length > 0 && showPartsSelector && <PredictiveSearchComponent position={showPartsSelector.position} noItemsLabel="No parts available to add variations for. Please make sure each part has a default material set." items={props.parts} exludeDefaultMaterials onClose={handleCancelNewConfiguration} onSelect={createNewConfiguration} /> }
      {/* { props.parts && props.parts.length > 0 && <button disabled={showPartsSelector} className="primary-btn centered" onClick={handleNewConfiguration}><span className="icon-box"></span> Add Variation</button> } */}
      {/* <div className={`configurator-viewer-footer ${props.parts && props.parts.length > 0 && showPartsSelector ? "disabled" : ""}`} onClick={handleNewConfiguration}>
        {glassSlabIcon}
        <span>Add Option</span>
      </div> */}
    </div>
  )
}

export default ConfiguratorViewerComponent