import { useCallback, useEffect, useState } from "react"
import { EVENTS } from "../../constants"
import { exportModelForThreeJS } from "../../utils/ui.util"
import "./ar-loader.component.css"
import * as THREE from 'three'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { DRACOLoader } from "three/examples/jsm/loaders/DRACOLoader"
import { USDZExporter } from "three/examples/jsm/exporters/USDZExporter"
import { arAssist } from "../../assets/index"
import { useLazyGetProjectS3UrlForAndroidQuery, useUploadGeneratedBlobFileMutation } from "../../redux/api.redux.slice"

const ARLoaderComponent = (props) => {
  const { project } = props
  console.log(project)
  // const DEFAULT_ERROR = "We had an issue loading your AR experience. Please try again"
  const [error, setError] = useState(false)
  const [loadingText, setLoadingText] = useState("Detecting your device")
  const [getS3UrlForAndroid] = useLazyGetProjectS3UrlForAndroidQuery()
  const [uploadGeneratedBlobFile] = useUploadGeneratedBlobFileMutation()
  const getMobileOperatingSystem = () => {
    const userAgent = navigator.userAgent || window.opera

    if (/android/i.test(userAgent)) {
      return "android"
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return "ios"
    }

    return "desktop"
  }

  const storeBlobForAndroid = useCallback(async (buffer) => {
    const blob = new Blob([buffer], {
      type: "model/gltf-binary"
    })
    const blobUrl = URL.createObjectURL(blob)
    const toBlob = await (await fetch(blobUrl)).blob()
    const file = new File([toBlob], 'generated.gltf', { type: "model/gltf-binary", lastModified: new Date() })

    return new Promise(async (resolve) => {
      try {
        const data = new FormData()
        data.set("blob_generated_file", file)
        const newlyGeneratedURL = await uploadGeneratedBlobFile({ modelId: project.model_id, body: data }).unwrap()
        resolve(newlyGeneratedURL)
      } catch (e) { //resolve silently
        resolve(false)
      }
    })
  }, [project, uploadGeneratedBlobFile])

  // Listen for scene loaded event
  useEffect(() => {
    if (!project) {
      return
    }
    const beginLoad = async () => {

      setLoadingText("Rendering scene")
      const buffer = await exportModelForThreeJS(props.scene)

      // Make sure we're on mobile
      const os = getMobileOperatingSystem()
      const anchor = document.createElement("a")

      // If we're on android or desktop, we don't need to create a 3JS scene
      if (os === 'android' || os === 'desktop') {
        setLoadingText("We are enhancing your AR Experience. Please wait ...")
        let s3URL = await getS3UrlForAndroid(project.project_id).unwrap()
        if (!s3URL) {
          s3URL = await storeBlobForAndroid(buffer)
        }
        if (s3URL) {
          const encodedURL = encodeURIComponent(s3URL).replace(/'/g, "%27").replace(/"/g, "%22")
          const href = (os === 'android') ? `intent://arvr.google.com/scene-viewer/1.1?mode=ar_preferred&file=${encodedURL}#Intent;scheme=https;package=com.google.android.googlequicksearchbox;action=android.intent.action.VIEW;end;` : s3URL
          setLoadingText("All set. Opening experience")
          anchor.href = href
          anchor.click()
        } else {
          alert("No S3 URL generated")
        } // else do nothing
        return
      }

      // Create scene
      try {
        const scene = new THREE.Scene()
        const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000)
        camera.position.z = 5
        camera.position.y = 1
        const renderer = new THREE.WebGLRenderer({antialias: true})
        renderer.toneMapping = THREE.ACESFilmicToneMapping
        renderer.toneMappingExposure = 1
        renderer.outputEncoding = THREE.sRGBEncoding
        renderer.setSize(window.innerWidth, window.innerHeight)
        document.body.appendChild(renderer.domElement)

        // Load GLB
        const loader = new GLTFLoader()
        const dracoLoader = new DRACOLoader()
        dracoLoader.setDecoderConfig({ type: 'js' })
        dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/')
        loader.setDRACOLoader(dracoLoader)

        // Parse
        loader.parse(buffer, '', async (gltf) => {
          console.log('ThreeJS scene loaded')
          const root = gltf.scene
          scene.add(root)
          renderer.render(scene, camera)
          setLoadingText("Updating scene to match your device requirements")
          console.log('Converting to USDZ for iOS')

          // Convert to USDZ
          const exporter = new USDZExporter()
          const arraybuffer = await exporter.parse(gltf.scene)
          const blob = new Blob([arraybuffer], {
            type: "model/vnd.usdz+zip"
          })
          const blobUrl = URL.createObjectURL(blob)

          // Add anchor attributes
          anchor.setAttribute("rel", "ar")
          anchor.setAttribute("download", "asset.usdz")
          const img = document.createElement("img")
          anchor.appendChild(img)

          // Open
          setLoadingText("All set. Opening experience")
          anchor.href = blobUrl
          anchor.click()
        })
      }
      catch (e) {
        setError(e.toString())
      }
    }


    document.addEventListener(EVENTS.SCENE_CONFIGURED, beginLoad)

    return () => document.removeEventListener(EVENTS.SCENE_CONFIGURED, beginLoad)
  }, [props.scene, getS3UrlForAndroid, project, uploadGeneratedBlobFile, storeBlobForAndroid])

  return (
    <div className="toggle-ar-loader">
      <div className="toggle-ar-loader-content">
        <img className="logo" src="/images/Toggle3D_Horizontal_Light.png" alt="Loading AR - Toggle3D" />
        <h2>Loading your AR experience....</h2>
        <h3>{loadingText}</h3>
        {arAssist}
        <h3>Aim your camera at the floor or a flat surface to anchor your object.</h3>
        <p><strong>Disclaimer</strong><br />The 3D product model is indicative only.</p>
        {error && <h4 className="error pt-20">Uh oh. {error}</h4>}
      </div>
    </div>
  )
}

export default ARLoaderComponent