import { useRef, useState } from "react"
import ModalComponent from "../../../../../components/modal/modal.component"
import { useAddProjectDecalMutation } from "../../../../../redux/api.redux.slice"
import { v4 as uuidv4 } from 'uuid'
import { upload } from "../../../../../assets"

const ElementAddDecalModal = (props) => {
  const {onClose, projectId, onDropDecal} = props
  const fileInputRef = useRef()
  const [step, setStep] = useState("initial")
  const [imageFile, setImageFile] = useState(false)
  const [loading, setLoading] = useState(false)
  const [addDecalRequest] = useAddProjectDecalMutation()

  const imageUploadButton = (buttonLabel = 'Choose an jpg/png image less than 4MB') => (
    <div className="image-upload-container">
      <input style={{display: 'none'}} ref={fileInputRef} type="file" accept=".jpeg,.jpg,.png" onChange={handleFileChange} />
      <button onClick={() => fileInputRef.current.click()}>{buttonLabel}</button>
    </div>
  )

  const handleFileChange = (e, droppedImage = null) => {
    const file = droppedImage ? droppedImage : e.target.files[0]
    const maxFileSize = 4 * 1024 * 1024  // 4mb max

    if (file.size > maxFileSize) {
      window.alert("Please select a file less than or equal to 4 MB in size")
      setImageFile(false)
      return
    }

    const reader = new FileReader()
    reader.onload = (e) => {
      const thumb = e.target.result
      const image = new Image()
      image.src = reader.result
      image.onload = async () => {
        const scaleFactor = image.width / image.height
        setImageFile({src: file, scaleFactor, name: file.name, thumb})
      }
    }

    reader.readAsDataURL(file)
  }

  const handleFileDrop = (e) => {
    e.preventDefault()
    const image = e.dataTransfer.files[0]
    const acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg']
    if (acceptedTypes.includes(image.type)) {
      handleFileChange(false, image)
    } else {
      window.alert("Please drop a .jpg or .png image")
      setImageFile(false)
    }
  }

  const handleCreateDecal = async (e) => {
    e.stopPropagation()
    e.preventDefault()

    // Upload to API
    setLoading(true)
    try {

      const id = uuidv4()
      const body = new FormData()
      body.set("file", imageFile.src)
      body.set("decal_id", id)
      const {src, srcUrl, created_ts} = await addDecalRequest({projectId, body}).unwrap()

      // Pass along decal to editor
      onDropDecal({src, srcUrl, id, scaleFactor: imageFile.scaleFactor, created_ts})
      setLoading(false)
    }
    catch (e) {
      console.error("Error uploading decal: ", e)
      setLoading(false)
      // todo: handle/show toast
    }
  }

  const getContent = () => {
    if (step === "initial") {
      return (
        <>
          {!imageFile && (
            <div className="upload-image-section" onDragOver={(e) => e.preventDefault()} onDrop={handleFileDrop}>
              <span className="upload-icon">{upload}</span>
              <p>Drag an image here or</p>
              {imageUploadButton()}
            </div>
          )}
          { imageFile && imageFile.src && (
            <div className="uploaded-image-section">
              <div className="preview-image pos-relative">
                <img src={imageFile.thumb} alt="preview thumb"/>
                <span style={{cursor: 'pointer'}} onClick={() => setImageFile(false)} className="icon-x"></span>
              </div>
              <div className="preview-image-details">
                <p>{imageFile.name}</p>
                {imageUploadButton('Choose a New File')}
              </div>
            </div>
          )}
        </>
      )
    }
    
    return (
      <>
        <h6>Great. Now, just drop this decal on the part you'd like to display it on.</h6>
        <p><span className="icon-info"></span> Tip: Use your mousewheel to resize the decal and shift + mousewheel to adjust the rotation when on the scene</p>
      </>
    )
  }

  const getActions = () => {
    if (step === "initial") {
      return (
        <div className="toggle-btn-group">
          <button className="toggle-outline-filled-btn" onClick={onClose}>Cancel</button>
          <button className="toggle-primary-btn" disabled={!imageFile} onClick={() => setStep("drop")}>Continue</button>
        </div>
      )
    }

    return (
      <div className="toggle-btn-group">
        <button className="toggle-outline-filled-btn" disabled={loading} onClick={() => setStep("initial")}>Back</button>
        <button className="toggle-primary-btn" disabled={loading} onClick={handleCreateDecal}>{loading ? "Saving..." : "Got it"}</button>
      </div>
    )
  }

  return (  
    <ModalComponent title="Add a Decal" close={onClose} content={getContent()} actions={getActions()} />
  )
}

export default ElementAddDecalModal