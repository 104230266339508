import { useCallback, useEffect, useRef } from "react"
import { ActionManager, ExecuteCodeAction, MeshBuilder, PointerEventTypes, Vector3 } from "@babylonjs/core"
import { createDecalMaterial } from "../utils/ui.util"

const useDecalDropper = (scene, decal, onDrop) => {
  const sizeRef = useRef()
  const rotationRef = useRef()
  const actionRef = useRef()
  const sceneRef = useRef()
  const validMeshesRef = useRef([])
  const decalMaterialRef = useRef()
  const lastDecalRef = useRef()
  const isDoneRef = useRef()
  const isHoldingShiftRef = useRef()
  const pointerObsRef = useRef()
   
  const handleKeyDown = useCallback((e) => {
    e.preventDefault()
    e.stopPropagation()
    const key = e.key || e.code
    if (key.toLowerCase() === 'shift') {
      isHoldingShiftRef.current = true
    }
    else {
      isHoldingShiftRef.current = false
    }
  }, [])

  const handleKeyUp = useCallback(() => {
    isHoldingShiftRef.current = false
  }, [])

  const handleScroll = useCallback((e) => {
    // Handle size vs rotation
    if (isHoldingShiftRef.current) {
      const val = -1 * (e.deltaY / 50)
      rotationRef.current += val
    }
    else {
      const val = e.deltaY >= 0 ? -0.01 : 0.01
      const scaleFactor = decal.scaleFactor ? decal.scaleFactor : sizeRef.current._x/sizeRef.current._y
      if (sizeRef.current._x + val > 0.01 && sizeRef.current._y + val > 0.01 && sizeRef.current._z + val > 0.01) {
        sizeRef.current.addInPlace(new Vector3(val, val/scaleFactor, val))
      }
    }
  }, [decal])

  const onTick = useCallback(() => {
    if (isDoneRef.current) { return }

    const pickInfo = sceneRef.current.pick(sceneRef.current.pointerX, sceneRef.current.pointerY, (m) => ["__projector", "__decal"].indexOf(m.id) < 0 && validMeshesRef.current.indexOf(m) > -1)
    if (pickInfo.hit && pickInfo.pickedMesh && pickInfo.pickedPoint && pickInfo.getNormal(true)) {

      if (lastDecalRef.current) {
        lastDecalRef.current.dispose()
      }

      // const newDecal = Mesh.CreateDecal("__decal", pickInfo.pickedMesh, pickInfo.pickedPoint, pickInfo.getNormal(true), sizeRef.current, rotationRef.current)
      // newDecal.id = "__decal"
      // newDecal.material = decalMaterialRef.current
      const decal = MeshBuilder.CreateDecal("__decal", pickInfo.pickedMesh, {position: pickInfo.pickedPoint, normal: pickInfo.getNormal(true), size: sizeRef.current, angle: rotationRef.current, cullBackFaces: true}, sceneRef.current)
      decal.id = "__decal"
			decal.material = decalMaterialRef.current
      lastDecalRef.current = decal
    }
  }, [])
  
  const handleMouseDown = useCallback(() => {
    if (!sceneRef.current) { return }

    const pickInfo = sceneRef.current.pick(sceneRef.current.pointerX, sceneRef.current.pointerY, (m) => ["__projector", "__decal"].indexOf(m.id) < 0 && validMeshesRef.current.indexOf(m) > -1)
    if (lastDecalRef.current && pickInfo.hit && pickInfo.pickedMesh && pickInfo.pickedPoint && pickInfo.getNormal(true)) {

      isDoneRef.current = true
      const mesh = pickInfo.pickedMesh
      const position = pickInfo.pickedPoint
      const normal = pickInfo.getNormal(true)
      const size = sizeRef.current.clone()
      const angle = rotationRef.current
      // const diffuseClone = decalMaterialRef.current.diffuseTexture.clone()

      // // Push decal to map
      // addDecalToMesh(sceneRef.current, mesh, {position, normal, size, angle}, diffuseClone)

      // // For some reason, it won't apply until the second time...
      // setTimeout(() => {

      //   addDecalToMesh(sceneRef.current, mesh, {position, normal, size, angle}, diffuseClone)

        // Close
        onDrop({mesh: mesh.id, position, normal, size, angle})
      // }, 250)
    }
  }, [onDrop])

  const handlePointerEvent = useCallback((pointerInfo) => {
    switch (pointerInfo.type) {
      case PointerEventTypes.POINTERDOWN:
        handleMouseDown()
        break
      default:
        break
    }
  }, [handleMouseDown])

  useEffect(() => {

    if (!scene || !decal || sceneRef.current) {
      return
    }

    console.log("useDecalDropper")
    sceneRef.current = scene
    const actionManager = new ActionManager(sceneRef.current)
    sceneRef.current.actionManager = actionManager
    actionRef.current = sceneRef.current.actionManager.registerAction(new ExecuteCodeAction({trigger: ActionManager.OnEveryFrameTrigger}, onTick))

    // Add pointer handler
    pointerObsRef.current = sceneRef.current.onPointerObservable.add(handlePointerEvent)

    // Filter meshes
    validMeshesRef.current = scene.meshes.filter((m) => m.material && m.getTotalVertices() > 0)

    // Set size
    if (decal.existingParams && decal.existingParams.size) {
      sizeRef.current = new Vector3(decal.existingParams.size._x, decal.existingParams.size._y, decal.existingParams.size._z)
    }
    else {
      sizeRef.current = new Vector3(0.1, 0.1/decal.scaleFactor, 0.1)
    }

    // Set rotation
    if (decal.existingParams && decal.existingParams.angle) {
      rotationRef.current = decal.existingParams.angle
    }
    else {
      rotationRef.current = 0
    }

    // Create decal material
    if (decal.existingMesh && decal.existingMesh.material) {
      decalMaterialRef.current = decal.existingMesh.material
    }
    else {
      const decalMaterial = createDecalMaterial(scene, "newDecalMat", decal.srcUrl)
      decalMaterialRef.current = decalMaterial
    }

    // Handle sizing scroll
    document.addEventListener('wheel', handleScroll)

    // Handle rotation trigger keys
    document.addEventListener('keydown', handleKeyDown)
    document.addEventListener('keyup', handleKeyUp)

    return () => {
      console.log("dispose useDecalDropper")
      sceneRef.current.onPointerObservable.remove(pointerObsRef.current)
      sceneRef.current.actionManager.unregisterAction(actionRef.current)
      sceneRef.current.actionManager.dispose()
      sceneRef.current.actionManager = null
      sceneRef.current = null
      pointerObsRef.current = null
      lastDecalRef.current = null
      isDoneRef.current = false
      isHoldingShiftRef.current = false
      document.removeEventListener('wheel', handleScroll)
      document.removeEventListener('keydown', handleKeyDown)
      document.removeEventListener('keyup', handleKeyUp)
    }
  }, [scene, decal, onTick, handlePointerEvent, handleKeyDown, handleKeyUp, handleScroll])
}

export default useDecalDropper