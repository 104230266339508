import { useSelector } from "react-redux"

import { useCallback, useEffect, useState } from "react"
import ProgressBarCircular from "../../../components/progress-bar-circular/progress-bar-circular"
import { useLazyGetUserInfoQuery } from "../../../redux/api.redux.slice"
import { STRIPE_PLANS } from "../../../constants"

const UsageSettingsSection = () => {
  const [getUserInfo] = useLazyGetUserInfoQuery()
  const auth = useSelector((state) => state.auth)
  const [isCalculating, setIsCalculating] = useState(true)
  const [percentage, setPercentage] = useState(0)
  const [total, setTotal] = useState(0)
  const [used, setUsed] = useState(0)
  const [usedUnit, setUsedUnit] = useState("MB")
  const [totalUnit, setTotalUnit] = useState("MB")

  const processUsage = useCallback((usedSpace) => {
    if (auth.plan) {
      const currentPlan = STRIPE_PLANS.find((p) => p.id === auth.plan.id)
      if (currentPlan) {
        const totalSpaceInMB = currentPlan.space
        const usedSpaceInMB = Number(usedSpace)

        if (totalSpaceInMB > 1024) {
          setTotalUnit("GB")
          setTotal(totalSpaceInMB / 1024)
        } else {
          setTotal(totalSpaceInMB)
        }

        if (usedSpaceInMB > 1024) {
          setUsedUnit("GB")
          setUsed((usedSpaceInMB / 1024).toFixed(2))
        } else {
          setUsed(usedSpaceInMB)
        }
        const usedPercent = Math.ceil((usedSpaceInMB / totalSpaceInMB) * 100)
        setPercentage(usedPercent)
        setIsCalculating(false)
      }
    }
  }, [auth.plan])

  const calculateUsage = useCallback(async () => {
    const { used_space_in_mb } = await getUserInfo().unwrap()
    processUsage(used_space_in_mb)
  }, [getUserInfo, processUsage])

  useEffect(() => {
    calculateUsage()
  }, [calculateUsage])

  return (

    <div className="threedy-lab-page-styled">
      <>
        <ProgressBarCircular percentage={percentage} isLoading={isCalculating} />
        <div className="usage-text">
          {!isCalculating &&
            `${used} ${usedUnit} of ${total} ${totalUnit} used`}
          {isCalculating &&
            <div className="calculating">calculating . . .</div>}
        </div>
      </>

    </div>
  )
}

export default UsageSettingsSection