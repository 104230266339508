import { useState } from "react"
import { useAdminCreateNewUserMutation } from "../../../redux/api.redux.slice"
import ModalComponent from "../../modal/modal.component"

const CreateUserModalComponent = (props) => {

  const [createNewUser, {isLoading:newUserLoading}] = useAdminCreateNewUserMutation()
  const [newUser, setNewUser] = useState({name: '', email: '', role: 'Internal'})
  const [error, setError] = useState(false)

  const handleCreate = async () => {
    setError(false)

    try {
      await createNewUser(newUser).unwrap()
      props.onComplete()
    }
    catch (e) {
      if (e.originalStatus === 409) {
        setError("Uh oh. Looks like that user already exists.")
      }
      else {
        setError("Uh oh, there was an issue creating that user. Please try again")
      }
    }
  }

  const modalContent = (
    <div className={newUserLoading ? "threedy-skeleton" : ""}>
      <h5 className="primary-color">* Please make sure the email provided is unique to the environment (i.e newuser+test@toggle3d.com vs newuser+prod@toggle3d.com)</h5>
      <div className="threedy-lab-flex">
        <div className="threedy-lab-text-input">
          <h4>Name:</h4>
          <input type="text" disabled={newUserLoading} autoFocus placeholder="Name..." value={newUser.name} onChange={(e) => setNewUser({...newUser, name: e.target.value})} />
        </div>
        <div className="threedy-lab-text-input">
          <h4>Email:</h4>
          <input type="email" disabled={newUserLoading} placeholder="Email..." value={newUser.email} onChange={(e) => setNewUser({...newUser, email: e.target.value})} />
        </div>
      </div>
      <div className="threedy-lab-flex">
        <div className="threedy-lab-text-input">
          <h4>Role:</h4>
          <select name="role" disabled={newUserLoading} value={newUser.role} onChange={(e) => setNewUser({...newUser, role: e.target.value})}>
            <option value="Internal">Internal</option>
            <option value="Admin">Admin</option>
          </select>
        </div>
      </div>
      {error && <div className="error margin-top centered">{error}</div>}
    </div>
  )

  const modalActions = (
    <>
      <button className="toggle-default-btn" onClick={props.onClose}>Cancel</button>
      <button className="toggle-primary-btn" disabled={newUserLoading || !newUser.name || !newUser.email || !newUser.role} onClick={handleCreate}>Create</button>
    </>
  )

  return <ModalComponent title="Invite a new user" content={modalContent} actions={modalActions} close={props.onClose} />
}

export default CreateUserModalComponent