import { useNavigate } from "react-router-dom"
import "./header-group.component.css"

const HeaderGroupComponent = (props) => {
  const { level, header, action, icon, headerClass } = props
  const navigate = useNavigate()
  const Tag = `h${level}`
  
  const handleClick = () => {
    if (action.path) {
      navigate(action.path)
    }
  }

  return (
    <div className={`toggle-header-group ${headerClass ?? ''}`}>
      <Tag>{ icon && <span className={`header-span ${icon}`}></span> } {header}</Tag>
      { action && <button disabled={action.disabled ? true : false} className={action.className ? action.className : ""} onClick={handleClick}>{action.label}{action.span && <span className={action.span} />}</button> }
    </div>
  )
}

export default HeaderGroupComponent