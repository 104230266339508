import { Texture, PBRMaterial, Tools, CubeTexture, Scene, Color3, SceneLoader, MeshBuilder, DynamicTexture, Constants, PointLight, Vector3, DirectionalLight, HemisphericLight, CubicEase, EasingFunction, Animation, MeshUVSpaceRenderer } from "@babylonjs/core"
import { GLTF2Export } from "@babylonjs/serializers"
import { DEFAULT_MATERIAL_PROPERTIES, DEFAULT_SPHERE_MAT_ID, DEFAULT_SPHERE_MESH_ID, API_BASE_URL, SCENE_IGNORED_NODES, SETTINGS_MIN_MAX, DEFAULT_MATERIAL_COLOR_PROPERTIES, CONVERSION_ERRORS, APP_RESOURCES_URL, DEFAULT_UV_PROPERTIES } from "../constants";
import * as photon from "aritize-photon-rs"
import InitHelperMethods from "../pages/workspace/helper-methods/init.helpers"
import { T } from "../constants"
import md5 from "md5"

const createAnimation = ({ property, from, to }) => {
    const ease = new CubicEase()
    ease.setEasingMode(EasingFunction.EASINGMODE_EASEINOUT)
    const FRAMES_PER_SECOND = 60
    const animation = Animation.CreateAnimation(
        property,
        Animation.ANIMATIONTYPE_FLOAT,
        FRAMES_PER_SECOND,
        ease
    )
    animation.setKeys([
        {
            frame: 0,
            value: from,
        },
        {
            frame: 100,
            value: to,
        },
    ])

    return animation
}

const moveActiveCamera = (scene, { radius, alpha, beta, target, position }) => {
    const camera = scene.activeCamera
    const SPEED_RATIO = 4
    const LOOP_MODE = false
    const FROM_FRAME = 0
    const TO_FRAME = 200

    camera.animations = []
    if (radius) {
        camera.animations.push(
            createAnimation({
                property: "radius",
                from: camera.radius,
                to: radius,
            })
        )
    }
    if (alpha) {
        camera.animations.push(
            createAnimation({
                property: "alpha",
                from: camera.alpha,
                to: alpha,
            })
        )
    }
    if (beta) {
        camera.animations.push(
            createAnimation({
                property: "beta",
                from: camera.beta,
                to: beta,
            })
        )
    }
    if (target) {
        camera.animations.push(
            createAnimation({
                property: "target.x",
                from: camera.target.x,
                to: target.x,
            }),
            createAnimation({
                property: "target.y",
                from: camera.target.y,
                to: target.y,
            }),
            createAnimation({
                property: "target.z",
                from: camera.target.z,
                to: target.z,
            })
        )
    }
    if (position) {
        camera.animations.push(
            createAnimation({
                property: "position.x",
                from: camera.position.x,
                to: position.x,
            }),
            createAnimation({
                property: "position.y",
                from: camera.position.y,
                to: position.y,
            }),
            createAnimation({
                property: "position.z",
                from: camera.position.z,
                to: position.z,
            })
        )
    }

    scene.beginAnimation(camera, FROM_FRAME, TO_FRAME, LOOP_MODE, SPEED_RATIO)
}

const createMaterialScene = (scene) => {
  return new Promise((resolve, reject) => {

    console.log('createMaterialScene')

    const sphere = MeshBuilder.CreateSphere(DEFAULT_SPHERE_MESH_ID, {}, scene)
    sphere.id = DEFAULT_SPHERE_MESH_ID

    // Create a default material for our sphere
    const material = new PBRMaterial(DEFAULT_SPHERE_MAT_ID, scene)
    material.albedoColor = new Color3(0.52, 0.52, 0.52)
    material.id = DEFAULT_SPHERE_MAT_ID
    material.name = DEFAULT_SPHERE_MAT_ID

    // Defaults
    material.invertNormalMapX = true
    material.invertNormalMapY = true
    material.ambientTextureStrength = 1
    material.alpha = 1
    material.metallic = 1
    material.roughness = 1
    sphere.material = material
    resolve()
  });
}

const addDecalToMesh = (scene, mesh, params, texture, projectId) => {
  return new Promise(async resolve => {
    let textureUrl
    if (!texture && projectId) {
      textureUrl = `${API_BASE_URL}projects/public/${projectId}/decals/${params.id}`
    }

    let {position, normal, size, angle} = params

    // Sanitize parameters
    if (position._x) {
      position = new Vector3(position._x, position._y, position._z)
      normal = new Vector3(normal._x, normal._y, normal._z)
      size = new Vector3(size._x, size._y, size._z)
    }

    const mat = createDecalMaterial(scene, params.id, textureUrl)
    const decal = MeshBuilder.CreateDecal("__decal", mesh, {position, normal, size, angle, cullBackFaces: true}, scene)
    decal.id = params.id
    decal.material = mat
    resolve(params)
  })
}

const addDecalMaps = (scene, meshes) => {
  console.log("init decal maps")

  // Add decal maps for applicable meshes
  meshes.filter((m) => m.material !== undefined).forEach((m) => {
    if (m.getTotalVertices() > 0 && m.material.decalMap) {
      m.decalMap = new MeshUVSpaceRenderer(m, scene, {width: 4096, height: 4096})
      m.material.decalMap.smoothAlpha = true
      m.material.decalMap.isEnabled = true
    }
  })
}

const createConfiguratorScene = (token, scene, project, shared) => {
  return new Promise((resolve, reject) => {

    console.log('createConfiguratorScene')
    
    // Get top level params ready
    const returnParams = {
      partsToUse: [],
      configurationsToUse: [],
      sampledMaterialsToUse: false,
      createdMaterialsToUse: false,
      stillProcessingToUse: false,
      doingMaterialImport: false,
      updateProjectStatus: false,
      defaultMaterialsToUse: [],
      hlClonesToUse: []
    }

    if (!project) {
      reject("No project defined")
      return
    }

    if (project.model_id && project.model_location && (project.model_status === 'ready' || project.model_status === 'readyForMaterialImport')) {

      // Prepare model src
      const { path, filename } = InitHelperMethods.getPathAndFileForModel(project.model_location)
      
      // Load model
      SceneLoader.ImportMeshAsync("", path, filename, scene).then(async (result) => {
        console.log('Model loaded...')
        
        // init decal maps (so that we can use them later)
        addDecalMaps(scene, scene.meshes)

        const hasParts = project.parts && project.parts.length > 0
        // const { parts, defaultMaterials, highlights } = InitHelperMethods.getPartsAndMaterialsInScene(scene, !shared)
        const { parts, defaultMaterials } = InitHelperMethods.getPartsAndMaterialsInScene(scene)

        // Set parts to use
        returnParams.partsToUse = hasParts ? project.parts : parts

        // The model is converted, but we want to import the materials from it
        if (project.model_status === 'readyForMaterialImport') {
          try {
            const sampledMaterials = await InitHelperMethods.handleSampleParts(returnParams.partsToUse, defaultMaterials)
            if (sampledMaterials.length > 0) {
              returnParams.sampledMaterialsToUse = sampledMaterials
              returnParams.createdMaterialsToUse = await InitHelperMethods.saveDefaultMaterialsToCollection(token, project.model_id, sampledMaterials)
            }
            
            returnParams.updateProjectStatus = true
            console.log("Done importing materials...")
          }
          catch (e) {
            console.error("Error processing converted mesh: ", e)
            reject(e)
          }
        }

        // Set configurations
        returnParams.configurationsToUse = project.variations && project.variations.length > 0 ? project.variations : []

        // Set default materials
        returnParams.defaultMaterialsToUse = defaultMaterials
        // returnParams.hlClonesToUse = highlights

        // All done
        returnParams.stillProcessingToUse = false
        returnParams.doingMaterialImport = false
        resolve(returnParams)
      }, (e) => {
        console.error("Error loading mesh: ", e)
        reject(e)
      })
    }
    else if (project.model_status === 'error') {
      if (project.model_status_code) {
        const errorFound = CONVERSION_ERRORS.find(c => c.code === project.model_status_code)
        if (errorFound) {
          const error = new Error(errorFound.message);
          error.code = errorFound.code;
          reject(error)
          return
        }
      }
      // There was an error processing the model file
      reject("Error converting file")
    }
    else {
      returnParams.stillProcessingToUse = true
      resolve(returnParams)
    }
  })
}

const createBaseScene = (engine) => {
  const el = document.querySelector(".toggle-root-container")
  const darkMode = el.classList.contains("dark")
  const bgColorSet = darkMode ? [46, 51, 73] : [246, 245, 247]
  const scene = new Scene(engine)
  scene.clearColor = new Color3(bgColorSet[0]/255, bgColorSet[1]/255, bgColorSet[2]/255)
  return scene
}

const setTexture = (scene, textureId, types, url, isDynamic = false) => {
  return new Promise(async (resolve, reject) => {

    if (isDynamic) {
      try {
        const img = await loadImage(url)
        const dpi = window.devicePixelRatio
        const newTexture = new DynamicTexture(textureId, {width: img.width * dpi, height: img.height * dpi}, scene, true, 3)
        newTexture.url = url
        newTexture.wrapU = Constants.TEXTURE_WRAP_ADDRESSMODE
        newTexture.wrapV = Constants.TEXTURE_WRAP_ADDRESSMODE
        const context = newTexture.getContext()

        // Ignore Safari monkey patch for canvas filters for non albedo canvas
        if (types.indexOf('albedoTexture') < 0) {
          context.canvas.__skipFilterPatch = true
        }

        putImageOnCanvas(img, context)
        resolve({types: types, textureId: textureId, texture: newTexture, url, context, img})
      }
      catch (e) {
        console.error(e)
        reject(e)
      }
    }
    else {
      const newTexture = new Texture(url, scene, undefined, undefined, undefined, () => {
        resolve({types: types, textureId: textureId, texture: newTexture, url})
      }, (e) => {
        reject("Error fetching texture map: " + e)
      })
    }
  })
}

// Merges the AO, Roughness, and Metalness textures into a single texture used by Babylon
const createARMTexture = (ao, rough, metal) => {
  let width
  let height
  if (ao) {
    width = ao.output.dims[2]
    height = ao.output.dims[3]
  } 
  else if (rough) {
    width = rough.output.dims[2]
    height = rough.output.dims[3]
  } 
  else if (metal) {
    width = metal.output.dims[2]
    height = metal.output.dims[3]
  }

  const imageBuffer = new Uint8ClampedArray(width * height * 4)

  let i = 0;
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
        var pos_tgt = (y * width + x) * 4 // position in buffer based on x and y
        imageBuffer[pos_tgt] = parseInt(ao ? ao.output.data[i] * 255 : 255)
        imageBuffer[pos_tgt + 1] = parseInt(rough ? rough.output.data[i] * 255 : 255)
        imageBuffer[pos_tgt + 2] = parseInt(metal ? metal.output.data[i] * 255 : 0)
        imageBuffer[pos_tgt + 3] = 255 // set alpha channel
        i++
    }
  }

  // create off-screen canvas element
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  canvas.width = width
  canvas.height = height
  const idata = ctx.createImageData(width, height)
  idata.data.set(imageBuffer)
  ctx.putImageData(idata, 0, 0)
  return canvas.toDataURL()
}

const loadCubeTexture = (scene, url) => {
  // No reject as we will just fail silently 
  return new Promise(resolve => {
    const texture = new CubeTexture(url, scene, undefined, undefined, undefined, () => {
      resolve(texture)
    }, () => {
      resolve(null)
    })
  })
}

const createMetaHash = (matId, meta) => {
  let metaStr = matId
  metaStr += meta.alpha !== undefined ? meta.alpha : DEFAULT_MATERIAL_PROPERTIES.alpha
  metaStr += meta.metallic !== undefined ? meta.metallic : DEFAULT_MATERIAL_PROPERTIES.metallic
  metaStr += meta.roughness !== undefined ? meta.roughness : DEFAULT_MATERIAL_PROPERTIES.roughness
  metaStr += meta.normalIntensity !== undefined ? meta.normalIntensity : DEFAULT_MATERIAL_PROPERTIES.normalIntensity
  metaStr += meta.saturation !== undefined ? meta.saturation : DEFAULT_MATERIAL_COLOR_PROPERTIES.saturation
  metaStr += meta.contrast !== undefined ? meta.contrast : DEFAULT_MATERIAL_COLOR_PROPERTIES.contrast
  metaStr += meta.brightness !== undefined ? meta.brightness : DEFAULT_MATERIAL_COLOR_PROPERTIES.brightness
  metaStr += meta.uScale !== undefined ? meta.uScale : DEFAULT_UV_PROPERTIES.uScale
  metaStr += meta.vScale !== undefined ? meta.vScale : DEFAULT_UV_PROPERTIES.vScale
  metaStr += meta.uOffset !== undefined ? meta.uOffset : DEFAULT_UV_PROPERTIES.uOffset
  metaStr += meta.vOffset !== undefined ? meta.vOffset : DEFAULT_UV_PROPERTIES.vOffset
  metaStr += meta.rotation !== undefined ? meta.rotation : DEFAULT_UV_PROPERTIES.rotation
  metaStr += meta.refractionIntensity !== undefined ? meta.refractionIntensity : DEFAULT_MATERIAL_PROPERTIES.refractionIntensity
  metaStr += meta.indexOfRefraction !== undefined ? meta.indexOfRefraction : DEFAULT_MATERIAL_PROPERTIES.indexOfRefraction
  return md5(metaStr)
}

const cloneMat = (inputMat, metaHash, scene) => {
  return new Promise(async(resolve, reject) => {
    try {
      const mat = new PBRMaterial(`${inputMat.id}-${metaHash}`)
      mat.id = metaHash
      mat.invertNormalMapX = true
      mat.invertNormalMapY = true
      mat.ambientTextureStrength = 1
      mat.alpha = 1
      mat.metallic = 1
      mat.roughness = 1

      if (inputMat.albedoTexture) {
        const {texture} = await setTexture(scene, `${mat.id}-albedo`, ['albedoTexture'], inputMat.albedoTexture.url, true)
        mat.albedoTexture = texture

        // Handle alpha
        if (inputMat.albedoTexture.hasAlpha) {
          mat.albedoTexture.hasAlpha = true
          mat.useAlphaFromAlbedoTexture = true
          mat.transparencyMode = PBRMaterial.MATERIAL_ALPHABLEND
        }
      }

      if (inputMat.metallicTexture) {
        const {texture} = await setTexture(scene, `${mat.id}-arm`, ['metallicTexture'], inputMat.metallicTexture.url, true)
        mat.metallicTexture = texture
        mat.metallicTexture.level = 1
        mat.useRoughnessFromMetallicTextureAlpha = false
        mat.useRoughnessFromMetallicTextureGreen = true
        mat.useMetallnessFromMetallicTextureBlue = true
        mat.useAmbientOcclusionFromMetallicTextureRed = false
      }

      if (inputMat.bumpTexture) {
        const {texture} = await setTexture(scene, `${mat.id}-normal`, ['bumpTexture'], inputMat.bumpTexture.url, true)
        mat.bumpTexture = texture
        mat.bumpTexture.level = 1
      }

      if (inputMat.reflectionTexture) {
        const {texture} = await setTexture(scene, `${mat.id}-reflection`, ['reflectionTexture'], inputMat.reflectionTexture.url, true)
        mat.reflectionTexture = texture
        mat.reflectionTexture.level = 1
      }

      if (inputMat.refractionTexture) {
        // console.log(inputMat.refractionTexture)
        // const {texture} = await setTexture(scene, `${mat.id}-refraction`, ['refractionTexture'], inputMat.refractionTexture.url, true)
        mat.refractionTexture = inputMat.refractionTexture.clone()
        mat.refractionTexture.level = 1
      }

      resolve(mat)
    }
    catch (e) {
      console.error(e)
      reject(e)
    }
  })
}

const disposeMaterial = (mat) => {
  // Dispose all textures besides refraction if it exists
  const textures = mat.getActiveTextures()
  textures.forEach((t) => {
    if (t.name && t.name !== "opaqueSceneTexture") {
      t.dispose()
    }
  })

  // Dispose mat
  mat.dispose(true)
}

const updateMaterialMeta = (scene, inputMat, meta, customizedMaterials, redraw) => {

  console.log('updateMaterialMeta: ', inputMat.id, meta)

  return new Promise(async (resolve, reject) => {
    try {
      const defaultHash = createMetaHash(inputMat.id, {...DEFAULT_MATERIAL_PROPERTIES, ...DEFAULT_MATERIAL_COLOR_PROPERTIES, ...DEFAULT_UV_PROPERTIES})
      const metaHash = createMetaHash(inputMat.id, meta)

      if (defaultHash === metaHash) {
        console.log("using default material")
        return resolve({mat: inputMat})
      }

      if (customizedMaterials[metaHash]) {
        if (!redraw) {
          console.log("using existing customized material")
          return resolve({mat: customizedMaterials[metaHash]})
        }

        console.log('redraw')
      }

      // Handle Material properties
      // Clone mat
      console.log('create material customization')
      const mat = await cloneMat(inputMat, metaHash, scene)
      mat.alpha = meta.alpha !== undefined ? (parseInt(meta.alpha)/100) : DEFAULT_MATERIAL_PROPERTIES.alpha/100
      mat.metallic = 1
      mat.roughness = 1
      
      // ARM props
      const metallic = meta.metallic !== undefined ? (parseInt(meta.metallic)/100) : DEFAULT_MATERIAL_PROPERTIES.metallic/100
      const roughness = meta.roughness !== undefined ? (parseInt(meta.roughness)/100) : DEFAULT_MATERIAL_PROPERTIES.roughness/100
      const indexOfRefraction = meta.indexOfRefraction !== undefined ? (parseInt(meta.indexOfRefraction)/100) : DEFAULT_MATERIAL_PROPERTIES.indexOfRefraction/100
      const refractionIntensity = meta.refractionIntensity !== undefined ? (parseInt(meta.refractionIntensity)/100) : DEFAULT_MATERIAL_PROPERTIES.refractionIntensity/100

      // Normal props
      const normalIntensity = meta.normalIntensity !== undefined ? (parseInt(meta.normalIntensity)/100) : DEFAULT_MATERIAL_PROPERTIES.normalIntensity/100

      // Grading
      const saturation = meta.saturation !== undefined ? parseInt(meta.saturation) : DEFAULT_MATERIAL_COLOR_PROPERTIES.saturation
      const contrast = meta.contrast !== undefined ? parseInt(meta.contrast) : DEFAULT_MATERIAL_COLOR_PROPERTIES.contrast
      const brightness = meta.brightness !== undefined ? parseInt(meta.brightness) : DEFAULT_MATERIAL_COLOR_PROPERTIES.brightness

      // Handle UV
      const uScale = meta.uScale !== undefined ? parseFloat(meta.uScale) : DEFAULT_UV_PROPERTIES.uScale
      const vScale = meta.vScale !== undefined ? parseFloat(meta.vScale) : DEFAULT_UV_PROPERTIES.vScale
      const uOffset = meta.uOffset !== undefined ? parseFloat(meta.uOffset)/100 : DEFAULT_UV_PROPERTIES.uOffset
      const vOffset = meta.vOffset !== undefined ? parseFloat(meta.vOffset)/100 : DEFAULT_UV_PROPERTIES.vOffset
      const rotation = meta.rotation !== undefined ? Tools.ToRadians(parseFloat(meta.rotation)) : DEFAULT_UV_PROPERTIES.rotation

      // Color
      if (mat.albedoTexture) {
        const textureContext = mat.albedoTexture.getContext()
        const img = await loadImage(mat.albedoTexture.url)
        mat.albedoTexture.uScale = uScale
        mat.albedoTexture.vScale = vScale
        mat.albedoTexture.uOffset = uOffset
        mat.albedoTexture.vOffset = vOffset
        mat.albedoTexture.wAng = rotation
        mat.albedoTexture.wRotationCenter = 0
        mat.albedoTexture.uRotationCenter = 0
        mat.albedoTexture.vRotationCenter = 0
        textureContext.filter = `saturate(${saturation}%) contrast(${contrast}%) brightness(${brightness}%)`
        putImageOnCanvas(img, textureContext)
        mat.albedoTexture.update(false)
      }

      // ARM
      if (mat.metallicTexture) {
        const textureContext = mat.metallicTexture.getContext()
        const updatedImage = photon.open_image(textureContext.canvas, textureContext)
        photon.adjust_metallic_roughness_factors_p(updatedImage, metallic, roughness)
        photon.putImageData(textureContext.canvas, textureContext, updatedImage)
        mat.metallicTexture.uScale = uScale
        mat.metallicTexture.vScale = vScale
        mat.metallicTexture.uOffset = uOffset
        mat.metallicTexture.vOffset = vOffset
        mat.metallicTexture.wAng = rotation
        mat.metallicTexture.wRotationCenter = 0
        mat.metallicTexture.uRotationCenter = 0
        mat.metallicTexture.vRotationCenter = 0
        mat.metallicTexture.update(false)
      }

      // Normal
      if (mat.bumpTexture) {
        const textureContext = mat.bumpTexture.getContext()
        const updatedImage = photon.open_image(textureContext.canvas, textureContext)
        photon.adjust_normal_intensity_p(updatedImage, normalIntensity)
        photon.putImageData(textureContext.canvas, textureContext, updatedImage)
        mat.bumpTexture.uScale = uScale
        mat.bumpTexture.vScale = vScale
        mat.bumpTexture.uOffset = uOffset
        mat.bumpTexture.vOffset = vOffset
        mat.bumpTexture.wAng = rotation
        mat.bumpTexture.wRotationCenter = 0
        mat.bumpTexture.uRotationCenter = 0
        mat.bumpTexture.vRotationCenter = 0
        mat.bumpTexture.update(false)
      }

      // // Refraction
      if (mat.refractionTexture) {
        mat.subSurface.indexOfRefraction = indexOfRefraction
        mat.subSurface.refractionIntensity = refractionIntensity
        mat.subSurface.isRefractionEnabled = true
      }

      // Done
      resolve({mat, metaHash})
    }
    catch (e) {
      console.error(e)
      reject(e)
    }
  })
}

const get1kResolution = (mat) => {  
  const resolutions = [...mat.resolutions].sort((a, b) => a > b ? 1 : -1) 

  // Search for 1k first
  let target = resolutions.find((r) => r >= 1000 && r <= 1024)
  if (target) {
    return target
  }

  // Find 1/2 - 1k 
  target = resolutions.find((r) => r <= 1024 && r >= 500)
  if (target) {
    return target
  }
  else {
    // Fallback to lowest
    return resolutions[0]
  }
}

const createNewMaterial = (scene, existingMaterials, mat, defaultMaterials) => {

  return new Promise((resolve, reject) => {
    
    // Check if we're dealing with a raw material, if so, return it
    if (mat.raw) {
      // console.log("Utilizing raw material")
      return resolve(defaultMaterials[mat.itemId])
    }
    
    // Check if we already have the material created
    if (existingMaterials[mat.id]) {
      // console.log("Utilizing previously created material")

      // Check if we need to update the meta
      const existing = existingMaterials[mat.id]

      // if (existing.meta !== mat.meta) {
      //   updateMaterialMeta(existing, mat.meta, materialAlbedoMaps, materialArmMaps, materialNormalMaps)
      // }

      return resolve(existing)
    }

    if (!mat.map_urls || Object.keys(mat.map_urls).length < 1) {
      return reject("No maps")
    }

    // console.log("Creating new material")
    const maps = mat.maps
    const mapUrls = mat.map_urls
    const resolution = get1kResolution(mat)
    const newMat = new PBRMaterial(mat.name, scene)
    newMat.id = mat.id
    const promises = []

    // Texture
    if (maps.indexOf('color') > -1) {
      // console.log("Set texture map: " + maps.color[resolution]);
      const colorMap = mapUrls[resolution].color[resolution]
      promises.push(setTexture(scene, mat.id + '-albedo', ["albedoTexture"], colorMap, true))
    }

    // Defaults
    newMat.invertNormalMapX = true
    newMat.invertNormalMapY = true
    newMat.ambientTextureStrength = 1
    newMat.alpha = 1
    newMat.metallic = 1
    newMat.roughness = 1

    // ARM
    if (maps.indexOf('ARM') > -1) {
      // console.log("Set arm map: " + mapUrls[resolution].ARM[resolution])
      const armMap = mapUrls[resolution].ARM[resolution]
      promises.push(setTexture(scene, mat.id + '-arm', ["metallicTexture"], armMap, true))
    }

    // Normal
    if (maps.indexOf('normal') > -1) {
      // console.log("Set normal map: " + mapUrls[resolution].normal[resolution])
      const normalMap = mapUrls[resolution].normal[resolution]
      promises.push(setTexture(scene, mat.id + '-normal', ["bumpTexture"], normalMap, true))
    }

    // Reflection
    if (maps.indexOf('reflection') > -1) {
      const reflectionMap = mapUrls[resolution].reflection[resolution]
      promises.push(setTexture(scene, mat.id + '-reflection', ["reflectionTexture"], reflectionMap, true))
    }

    // Refraction
    if (maps.indexOf('refraction') > -1) {
      const refractionMap = mapUrls[resolution].refraction[resolution]
      promises.push(setTexture(scene, mat.id + '-refraction', ["refractionTexture"], refractionMap, true))
    }


    // Check for separate ARM maps
    if (maps.indexOf('ARM') < 0 && (maps.indexOf('ao') > -1 || maps.indexOf('roughness') > -1 || maps.indexOf('metalness') > -1)) {
      const ao = maps.indexOf('ao') > -1 ? mapUrls[resolution].ao[resolution] : false
      const roughness = maps.indexOf('roughness') > -1 ? mapUrls[resolution].roughness[resolution] : false
      const metalness = maps.indexOf('metalness') > -1 ? mapUrls[resolution].metalness[resolution] : false

      if ((ao && ao.output) || (roughness && roughness.output) || (metalness && metalness.output)) {
        const textureUri = createARMTexture(ao, roughness, metalness)
        promises.push(setTexture(scene, mat.id + '-arm', ["metallicTexture"], textureUri, true))
      }
    }

    // Wait for all to resolve
    Promise.all(promises).then((loadedTextures) => {

      loadedTextures.forEach((t) => {
        t.types.forEach(async (type) => {
          newMat[type] = t.texture
          newMat[type].id = newMat[type].name = t.textureId;

          if (type === 'albedoTexture') {

            // Handle opacity
            if (mat.has_opacity) {
              newMat[type].hasAlpha = true
              newMat.useAlphaFromAlbedoTexture = true
              newMat.transparencyMode = PBRMaterial.MATERIAL_ALPHABLEND
            }

            // Update texture
            newMat[type].update(false)
            newMat[type].level = 1
          }

          if (['bumpTexture', 'refractionTexture', 'reflectionTexture'].indexOf(type) > -1) {

            // Update texture
            newMat[type].update(false)
            newMat[type].level = 1
          }

          if (type === 'refractionTexture') {
            newMat.subSurface.isRefractionEnabled = true
            newMat.subSurface.refractionIntensity = DEFAULT_MATERIAL_PROPERTIES.refractionIntensity/100
            newMat.subSurface.indexOfRefraction = DEFAULT_MATERIAL_PROPERTIES.indexOfRefraction/100
          }

 
          if (type === 'metallicTexture') {
            newMat.useRoughnessFromMetallicTextureAlpha = false
            newMat.useRoughnessFromMetallicTextureGreen = true
            newMat.useMetallnessFromMetallicTextureBlue = true
            newMat.useAmbientOcclusionFromMetallicTextureRed = false

            // Update texture
            newMat[type].update(false)
            newMat[type].level = 1
          }
        })
      })

      // Store
      // console.log("store: ", mat.id)
      existingMaterials[mat.id] = newMat
      
      // Resolve
      resolve(newMat)
    }, (err) => {
      console.error("Error creating new material: ", err)
      reject(err)
    })
  })
}

const convertDecalsToMaps = (scene, decals, token, projectId) => {
  return new Promise(async (resolve, reject) => {
    const decalTextures = {}

    try {

      for (let i = 0; i < Object.keys(decals).length; i++) {
        const key = Object.keys(decals)[i]
        const decal = decals[key]
        if (decal.id && decal.mesh && decal.size && decal.normal && decal.position) {
          const mesh = scene.meshes.find((m) => m.id === decal.mesh)
          const decalMesh = scene.meshes.find((m) => m.id === decal.id)
          if (mesh && decalMesh) {
            decalTextures[mesh.id] = true
            await addTextureToDecalMap(scene, mesh, {...decal, texture: decalMesh.material.albedoTexture})
          }
        }
      }

      // Now go through each mesh & grab it's decal texture + upload
      const promises = []
      if (Object.keys(decalTextures).length > 0) {
        const keys = Object.keys(decalTextures)
        for (let j = 0; j < keys.length; j++) {
          const meshId = keys[j]
          const mesh = scene.meshes.find((m) => m.id === meshId)
          const decalFile = await matTextureToOutput('file', `__decal-map-${meshId}`, 'png', mesh.decalMap.texture)
          promises.push(uploadDecalMap(token, projectId, meshId, decalFile))
          
          // Clear decal map
          mesh.decalMap.clear()
        }
      }

      await Promise.all(promises)
      resolve()
    }
    catch (e) {
      console.error(e)
      reject(e)
    }
  })
}

const uploadDecalMap = (token, projectId, meshId, texture) => {
  
  return new Promise(async (resolve, reject) => {
    try {

      const formData = new FormData()
      formData.append('file', texture)
      formData.append('project_id', projectId)
      formData.append('mesh_id', meshId);

      await fetch(`${API_BASE_URL}exports/decal`, {
        method: "POST",
        body: formData,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((r) => r.json())

      resolve()
    }
    catch (e) {
      console.error("error uploading decal", e)
      reject(e)
    }
  })
}

const addTextureToDecalMap = (scene, mesh, params) => {
  return new Promise(resolve => {
    let {position, normal, size, angle, texture} = params

    // Sanitize parameters
    if (position._x) {
      position = new Vector3(position._x, position._y, position._z)
      normal = new Vector3(normal._x, normal._y, normal._z)
      size = new Vector3(size._x, size._y, size._z)
    }

    // Render texture
    mesh.decalMap.renderTexture(texture, position, normal, size, angle)

    // Todo: It always takes two renderTextures for the map to update... this should be investigated further
    setTimeout(() => {
      mesh.decalMap.renderTexture(texture, position, normal, size, angle)
      resolve()
    }, 500)
  })
}

const createExportOptions = (scene) => {
  const lights = []
  scene.lights.forEach((l) => {
    lights.push(l)
  })

  const options = {
    shouldExportNode: function (node) {
      if (SCENE_IGNORED_NODES.indexOf(node.name) > -1 || node.name.indexOf("_hl_") > -1) {
        return false
      }

      // We don't want to include the lights in the exported file
      if (lights.indexOf(node) > -1) {
        return false
      }

      return true
    }
  }

  return options
}

const exportModelForThreeJS = (scene) => {
  console.log("Export scene for ThreeJS")
  return new Promise(async (resolve) => {
    const file = await GLTF2Export.GLBAsync(scene, `temp.glb`, createExportOptions(scene))
    const buff = await file.glTFFiles['temp.glb'].arrayBuffer()
    resolve(buff)
  })
}

const exportModel = (scene, project, token, params) => {
  console.log("Export scene with params: ", params)
  
  return new Promise(async (resolve, reject) => {
    try {

      // Convert decals to maps if we have them
      let hasDecals = false
      if (project.decals && typeof project.decals === 'object' && Object.keys(project.decals).length > 0) {
        await convertDecalsToMaps(scene, project.decals, token, project.id)
        hasDecals = true
      }

      // Prepare glb
      const sanitizedName = `${params.name.replace(/[\W_]+/g, "-")}`
      const glbExport = await GLTF2Export.GLBAsync(scene, `${sanitizedName}.glb`, createExportOptions(scene))

      // Push glb to backend along with export params
      const body = new FormData()
      const glbKey = Object.keys(glbExport.glTFFiles)[0]
      const glbFile = new File([glbExport.glTFFiles[glbKey]], `${sanitizedName}.glb`)
      const {name, ...restParams} = params
      restParams.resolution = params.resolution === '4k' ? 4096 : (params.resolution === '2k' ? 2048 : (params.resolution === '1k' ? 1024 : 512))
      restParams.preBaked = project.meta && project.meta.preBaked ? true : false
      restParams.formats = Object.keys(params.formats)

      // If we have decals, we need to always turn baking on
      if (hasDecals) {
        restParams.bake = true
      }

      body.append("file", glbFile)
      body.append("project_id", project.id)
      body.append("name", sanitizedName)
      body.append("params", JSON.stringify(restParams))
      body.append("destination", params.destination)

      // Make request
      const exportData = await fetch(`${API_BASE_URL}exports`, {
        method: "POST",
        body,
        headers: {
          Authorization: `Bearer ${token}`
        }
      }).then((r) => r.json())

      resolve(exportData.data.export)
    }
    catch (e) {
      console.error("Error exporting: ", e)
      reject(e)
    }
  })  
}

const createDownloadLink = (url, name) => {
  const link = document.createElement("a")
  link.href = url
  link.download = name
  document.body.appendChild(link)
  link.dispatchEvent(
    new MouseEvent('click', { 
      bubbles: true, 
      cancelable: true, 
      view: window 
    })
  )
  
  document.body.removeChild(link)
}

const matTextureToOutput = (output, filename, fileExtension, texture, color) => {
  return new Promise(async (resolve, reject) => {

    const size = texture ? texture.getSize() : {width: 1024, height: 1024}
    const canvas = document.createElement('canvas')
    canvas.width = size.width
    canvas.height = size.height
    const ctx = canvas.getContext('2d')

    // Ignore Safari monkey patch for canvas filters for non albedo canvas
    ctx.canvas.__skipFilterPatch = true

    if (texture) {
      const arr = new Uint8ClampedArray(await texture.readPixels())
      const img = new ImageData(arr, size.width, size.height)
      ctx.putImageData(img, 0, 0)
    }
    else if (color) {
      const gammaColor = color.toGammaSpace()
      ctx.fillStyle = `rgb(${gammaColor.r*255},${gammaColor.g*255},${gammaColor.b*255})`
      ctx.fillRect(0, 0, size.width, size.height)
    }
    else {
      reject("No texture or color specified")
    }

    const mime = fileExtension === 'jpg' ? 'image/jpeg' : 'image/png'
    const quality = fileExtension === 'jpg' ? 0.8 : 1

    if (output === 'file') {
      canvas.toBlob((blob) => resolve(new File([blob], `${filename}.${fileExtension}`, {type: mime})), mime, quality)
    }
    else {
      resolve(canvas.toDataURL(mime, quality))
    }
  })
}

const createMaterialManifest = (material, skipAlbedo) => {
  return new Promise(async (resolve, reject) => {
    const name = material.name
    const manifest = {name, maps: [], meta: {}}
    const files = []

    console.log("createMaterialManifest")

    if (!skipAlbedo) {
      if (material.albedoTexture) {
        const albedoFile = await matTextureToOutput('file', `__toggle__${name}__3d-color`, 'jpg', material.albedoTexture)
        const thumbFile = await matTextureToOutput('file', `__toggle__${name}__3d-thumb`, 'jpg', material.albedoTexture)
        manifest.maps.push('color')
        manifest.maps.push('thumb')
        files.push(albedoFile, thumbFile)
      }
      else if (material.albedoColor) {
        const albedoFile = await matTextureToOutput('file', `__toggle__${name}__3d-color`, 'jpg', false, material.albedoColor)
        const thumbFile = await matTextureToOutput('file', `__toggle__${name}__3d-thumb`, 'jpg', false, material.albedoColor)
        manifest.maps.push('color')
        manifest.maps.push('thumb')
        files.push(albedoFile, thumbFile)
      }
    }

    if (material.bumpTexture) {
      const bumpFile = await matTextureToOutput('file', `__toggle__${name}__3d-normal`, 'jpg', material.bumpTexture)
      manifest.maps.push('normal')
      files.push(bumpFile)
    }

    if (material.reflectionTexture) {
      const reflectionFile = await matTextureToOutput('file', `__toggle__${name}__3d-reflection`, 'jpg', material.reflectionTexture)
      manifest.maps.push('reflection')
      files.push(reflectionFile)
    }

    if (material.refractionTexture) {
      const refractionFile = await matTextureToOutput('file', `__toggle__${name}__3d-refraction`, 'jpg', material.refractionTexture)
      manifest.maps.push('refraction')
      files.push(refractionFile)
    }

    if (material.metallicTexture) {
      const armFile = await matTextureToOutput('file', `__toggle__${name}__3d-ARM`, 'png', material.metallicTexture)
      manifest.maps.push('ARM')
      files.push(armFile)
    }

    if (material.alpha !== undefined) {
      manifest.meta.alpha = material.alpha*100
    }

    if (material.metallic !== undefined) {
      manifest.meta.metallic = material.metallic*100
    }

    if (material.roughness !== undefined) {
      manifest.meta.roughness = material.roughness*100
    }

    if (material.normalIntensity !== undefined) {
      manifest.meta.normalIntensity = material.normalIntensity*100
    }

    if (material.directIntensity !== undefined) {
      manifest.meta.directIntensity = material.directIntensity*100
    }

    resolve({manifest, files})
  })
}

const getStatusClass = (status) => {
  const color = status === 'ready' ? 'green' : status === 'error' ? 'red' : 'blue';
  return `status-tag background-${color}`
}

const getCanvasForTextureImage = async (material) => {
  let res = get1kResolution(material)
  if (!material.map_urls[res]) {
    res = Object.keys(material.map_urls)[0]
  }

  const colorMap = material.map_urls[res].color[res]
  const imageData = await new Promise((resolve, reject) => {
    const img = new Image()
    img.setAttribute('crossOrigin', 'anonymous')
    img.setAttribute('src', colorMap)
    img.onload = () => {
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      canvas.width = img.width
      canvas.height = img.height
      context.drawImage(img, 0, 0)
      resolve({ canvas, context })
    }

    img.onerror = e => {
      console.error("Error fetching texture image: ", e)
    }
  })

  return {
    textureCanvas: imageData.canvas,
    textureContext: imageData.context
  }
}

// Loads an image from a url into an off-screen canvas
const loadImageToCanvas = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = "anonymous"

    img.onload = () => {
      const canvas = document.createElement("canvas")
      canvas.width = img.width
      canvas.height = img.height
      const ctx = canvas.getContext("2d")
      ctx.clearRect(0, 0, img.width, img.height)
      ctx.drawImage(img, 0, 0)
      resolve(canvas)
    }

    img.onerror = (e) => {
      reject(e)
    }

    img.src = url
  })
}

const loadImage = (url) => {
  return new Promise((resolve, reject) => {
    const img = new Image()
    img.crossOrigin = "anonymous"

    img.onload = () => {
      resolve(img)
    }

    img.onerror = (e) => {
      reject(e)
    }

    img.src = url
  })
}

const putImageOnCanvas = (img, ctx) => {
  ctx.imageSmoothingEnabled = false
  const dpi = window.devicePixelRatio
  ctx.drawImage(img, 0, 0, img.width * dpi, img.height * dpi)
}

// Resizer
const resizeIfLarger = (orgImage, orgWidth, orgHeight, maxDim) => {
  if (orgWidth > maxDim || orgHeight > maxDim) {
    let newWidth, newHeight, ratio

    if (orgWidth > orgHeight) {
      ratio = maxDim / orgWidth;
      newWidth = Math.floor(orgWidth * ratio);
      newHeight = Math.floor(orgHeight * ratio);
    } 
    else {
      ratio = maxDim / orgHeight;
      newWidth = Math.floor(orgWidth * ratio);
      newHeight = Math.floor(orgHeight * ratio);
    }

    const resizedImage = photon.resize(orgImage, newWidth, newHeight, 5)
    return {
      img: resizedImage,
      width: newWidth,
      height: newHeight
    }
  }

  return {
    img: orgImage,
    width: orgWidth,
    height: orgHeight
  }
}

const photonImage2Canvas = (phImage, width, height) => {
  const canvas = document.createElement("canvas")
  canvas.width = width
  canvas.height = height
  const ctx = canvas.getContext("2d")
  photon.putImageData(canvas, ctx, phImage)
  return canvas
}


// Loads an image drawn from a canvas into a format that can be processed by ONNX
const canvastoCWHBuffer = (canvas) => {
  const w = canvas.width
  const h = canvas.height

  const context = canvas.getContext('2d')
  const srcBuf = context.getImageData(0, 0, w, h).data

  const rBuf = new Float32Array(w * h);
  const gBuf = new Float32Array(w * h);
  const bBuf = new Float32Array(w * h);

  const tgtBuf = new Float32Array(w * h * 3);

  var ii = 0
  for (var y = 0; y < h; y++) {
      for (var x = 0; x < w; x++) {
          var pos_src = (y * w + x) * 4; // position in buffer based on x and y

          rBuf[ii] = srcBuf[pos_src] / 255.0;
          gBuf[ii] = srcBuf[pos_src + 1] / 255.0;
          bBuf[ii] = srcBuf[pos_src + 2] / 255.0;

          ii++;
      }
  }

  var jj = 0;

  for (let i = 0; i < rBuf.length; i++) {
      tgtBuf[jj] = rBuf[i];
      jj++;
  }

  for (let i = 0; i < gBuf.length; i++) {
      tgtBuf[jj] = gBuf[i];
      jj++;
  }

  for (let i = 0; i < bBuf.length; i++) {
      tgtBuf[jj] = bBuf[i];
      jj++;
  }

  return {
      buf: tgtBuf,
      dataUri: canvas.toDataURL(),
      width: w,
      height: h
  }
}

const generateMap = (inputData, session, targetState, makeDataUri) => {
  return new Promise(async (resolve) => {
    console.log(`Generating the ${targetState}...`)
    const results = await generateTexture(inputData, session)
    const output = makeDataUri ? bufToDataUri(results) : results
    resolve({[targetState]: output})
  })
}

const generateTexture = (inputData, session) => {

  return new Promise(async (resolve) => {
    const width = inputData.width
    const height = inputData.height
    const dims = [1, 3, width, height]

    const feeds = {
      input: new window.ort.Tensor('float32', new Float32Array(inputData.buf), dims)
    }

    // feed inputs and run
    const results = await session.run(feeds)
    resolve(results)
  })
}

// Convert the ONNX result to a DataURL
const bufToDataUri = (onnxResults) => {
  const width = onnxResults.output.dims[2]
  const height = onnxResults.output.dims[3]
  let imageBuffer

  if (onnxResults.output.dims[1] === 1) {
    imageBuffer = buf1to4(onnxResults.output.data, width, height)
  } 
  else {
    imageBuffer = buf3to4(onnxResults.output.data, width, height)
  }

  // create off-screen canvas element
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')
  canvas.width = width
  canvas.height = height

  const idata = ctx.createImageData(width, height)
  idata.data.set(imageBuffer)
  ctx.putImageData(idata, 0, 0)
  return canvas.toDataURL()
}

// Converts a 3D image buffer to 4D that can be used by an HTML canvas
const buf3to4 = (src, width, height) => {
  const imageBuffer = new Uint8ClampedArray(width * height * 4)
  let i = 0
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const pos_tgt = (y * width + x) * 4; // position in buffer based on x and y
      imageBuffer[pos_tgt] = parseInt(src[i] * 255)
      imageBuffer[pos_tgt + 1] = parseInt(src[width * height + i] * 255)
      imageBuffer[pos_tgt + 2] = parseInt(src[width * height * 2 + i] * 255)
      imageBuffer[pos_tgt + 3] = 255 // set alpha channel
      i++
    }
  }

  return imageBuffer
}

// Converts a 1D image buffer to 4D that can be used by an HTML canvas
const buf1to4 = (src, width, height) => {
  const imageBuffer = new Uint8ClampedArray(width * height * 4)
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const pos_src = (y * width + x) // position in buffer based on x and y
      const pos_tgt = (y * width + x) * 4 // position in buffer based on x and y
      imageBuffer[pos_tgt] = parseInt(src[pos_src] * 255)
      imageBuffer[pos_tgt + 1] = parseInt(src[pos_src] * 255)
      imageBuffer[pos_tgt + 2] = parseInt(src[pos_src] * 255)
      imageBuffer[pos_tgt + 3] = 255 // set alpha channel
    }
  }

  return imageBuffer
}

const prepareCamera = (scene) => {

  // Attach camera to canvas inputs
  if (!scene.activeCamera) {
    scene.createDefaultCamera(true)
    const camera = scene.activeCamera

    // Enable camera's behaviors
    camera.useFramingBehavior = true
    const framingBehavior = camera.getBehaviorByName("Framing")
    framingBehavior.framingTime = 0
    framingBehavior.elevationReturnTime = -1

    if (scene.meshes.length > 0) {
      camera.lowerRadiusLimit = null
      const worldExtends = scene.getWorldExtends((mesh) => {
        return mesh.isVisible && mesh.isEnabled()
      })

      framingBehavior.zoomOnBoundingInfo(worldExtends.min, worldExtends.max)
    }

    camera.pinchPrecision = 200 / camera.radius
    camera.upperRadiusLimit = Math.round(3 * camera.radius)
    camera.lowerRadiusLimit = 0.1
    camera.wheelDeltaPercentage = 0.1
    camera.pinchDeltaPercentage = 0.1
    camera.alpha += Math.PI;
    camera.attachControl()

    // Let's break down the zoom levels
    const zoom = {
      10: camera.upperRadiusLimit,
      100: Math.round(camera.radius),
      300: camera.lowerRadiusLimit
    }

    return {
      camera,
      zoom
    }
  }

  return {camera: scene.activeCamera}
}


const doArrow = (
  position,
  verticalAlign,
  horizontalAlign,
  customArrowPosition = null
) => {
  if (!position || position === 'custom') {
    return {}
  }

  const width = 16
  const height = 12
  const color = 'var(--primaryColor)'
  const isVertical = position === 'top' || position === 'bottom'
  const spaceFromSide = 10
  const opositeSide = {
    top: 'bottom',
    bottom: 'top',
    right: 'left',
    left: 'right',
  }

  const obj = {
    [`--rtp-arrow-${
      isVertical ? opositeSide[horizontalAlign] : verticalAlign
    }`]: (customArrowPosition ? customArrowPosition : (height + spaceFromSide)) + 'px',
    [`--rtp-arrow-${opositeSide[position]}`]: -height + 2 + 'px',
    [`--rtp-arrow-border-${isVertical ? 'left' : 'top'}`]: `${
      width / 2
    }px solid transparent`,
    [`--rtp-arrow-border-${isVertical ? 'right' : 'bottom'}`]: `${
      width / 2
    }px solid transparent`,
    [`--rtp-arrow-border-${position}`]: `${height}px solid ${color}`,
  }
  return obj
}

const validateUVInput = (inputValue, inputType) => {
  const afterDecimalPlaces = { scale: 2, uOffset: 2, vOffset: 2, rotation: 0 }
  let [first, last] = `${inputValue}`.split('.')
  last = last ? last.substring(0,afterDecimalPlaces[inputType]) : ''
  inputValue = [0, ''].includes(inputValue) ? 0 : +([first, last].join('.'))
  if (inputValue > SETTINGS_MIN_MAX[inputType].max) {
    inputValue = SETTINGS_MIN_MAX[inputType].max
  }
  if (inputValue < SETTINGS_MIN_MAX[inputType].min) {
    inputValue = SETTINGS_MIN_MAX[inputType].min
  }

  return inputValue
}

const  validateMatSettingsInput = (inputValue, inputType) => {
  if (inputValue > SETTINGS_MIN_MAX[inputType].max) {
    inputValue = SETTINGS_MIN_MAX[inputType].max
  }
  if (inputValue < SETTINGS_MIN_MAX[inputType].min) {
    inputValue = SETTINGS_MIN_MAX[inputType].min
  }

  return inputValue
}

const mapPatternChanges = (setting, isEditing) => {
  let editableInputs = {}
  switch(setting) {
    case 'scale':
      editableInputs = {showScaleInput: isEditing}
      break

    case 'uOffset':
      editableInputs = {showUOffsetInput: isEditing}
      break

    case 'vOffset':
      editableInputs = {showVOffsetInput: isEditing}
      break

    case 'rotation':
      editableInputs = {showRotationInput: isEditing}
      break

    case 'alpha':
      editableInputs = {showAlphaInput: isEditing}
      break

    case 'metallic':
      editableInputs = {showMetallicInput: isEditing}
      break

    case 'roughness':
      editableInputs = {showRoughnessInput: isEditing}
      break

    case 'normalIntensity':
      editableInputs = {showIntensityInput: isEditing}
      break

    case 'uScale':
      editableInputs = {showUScaleInput: isEditing}
      break

    case 'vScale':
      editableInputs = {showVScaleInput: isEditing}
      break

    case 'brightness':
      editableInputs = {showBrightnessInput: isEditing}
      break

    case 'contrast':
      editableInputs = {showContrastInput: isEditing}
      break

    case 'saturation':
      editableInputs = {showSaturationInput: isEditing}
      break
    
    case 'refractionIntensity':
      editableInputs = {showRefractionIntensityInput: isEditing}
      break

    case 'indexOfRefraction':
      editableInputs = {showIndexOfRefractionInput: isEditing}
      break

    default:
      break
  }

  return editableInputs
}

const getResolutionLabel = (r) => {
  return (r <= 1750 && r >= 960) ? '1k' : r < 960 ? '500px' : (r <= 2750 && r > 1750) ? '2k' : r > 2750 ? '4k' : r
}

const resetTheProject = () => {
  const openPartsBtn = document.querySelector('.threedy-lab-workspace-menu.actions-menu .open-parts-btn')
  if (openPartsBtn.classList.contains('selected')) {
      openPartsBtn.click()
  }
  const lightingBtn = document.querySelector('.threedy-lab-workspace-menu.actions-menu .open-lighting-btn')
  if (lightingBtn.classList.contains('selected')) {
      lightingBtn.click()
  }
  const configuratorBuilder = document.querySelector('.configurator-builder-flex.configurator-builder-materials')
  if (configuratorBuilder) {
      configuratorBuilder.querySelector('.material-actions button:last-child').click()
  }
}

const handleContinuousSelection = (keyPoints, pInd) => {
  if (keyPoints.length === 2 && pInd > keyPoints[0] && pInd < keyPoints[1]) {
    keyPoints.pop()
    keyPoints.push(pInd)
    return keyPoints
  }
  const min = Math.min(...keyPoints, pInd)
  const max = Math.max(...keyPoints, pInd)
  keyPoints = [min, max]
  return keyPoints
}

const getProjectIcon = (projectType) => {

  let className
  switch (projectType) {
    case T.CONFIGURATOR:
      className = "icon-codepen"
      break
    case T.MATERIAL:
      className = "supp-icon-codesandbox"
      break
    case T._3D_MODEL: 
      className = "icon-layers"
      break
    case T.VARIATIONS: 
      className = "supp-icon-category"
      break
    
    default: break
  }
  
  if (className) {
    return <span className={className}></span>
  }

  return null
}

const createLightingEnvironment = (scene, curLights, env) => {
  return new Promise(resolve => {

    // Do some cleanup first
    setTimeout(() => {
      // Clear any skybox meshes
      for (const m of scene.meshes) {
        if (m.name === 'skyBox') {
          console.log("Dispose skybox")
          m.setEnabled(false)
          m.dispose()
        }
      }

      // Skybox mats
      for (const m of scene.materials) {
        if (m.id === 'skyBox') {
          console.log("Dispose skybox material")
          m.dispose(true, true)
        }
      }

      // Lights
      if (curLights && curLights.length > 0) {
        curLights.forEach((light) => {
          console.log("Dispose light: ", light.name)
          light.dispose()
        })
      }
    })

    // Set up lighting
    setTimeout(async () => {
      const lightingEnv = env ? env : 'default'
      let envFile = 'toggleNeutral.env'
      const lights = []
      
      if (lightingEnv === 'studio') {
        const studioLight = new PointLight('StudioPointLight', new Vector3(0, 1, 0), scene)
        studioLight.intensity = 0.8
        lights.push(studioLight)
      }
      else if (lightingEnv === 'room') {
        const roomLight = new DirectionalLight('BedroomDirectionalLight', new Vector3(-1, 1, -2), scene)
        roomLight.intensity = 1.8
        lights.push(roomLight)
      }
      else if (lightingEnv === 'kitchen') {
        const kitchenLight = new HemisphericLight("KitchenHemiLight", new Vector3(-2, 1, -2), scene)
        kitchenLight.intensity = 0.8
        lights.push(kitchenLight)
      }
      else if (lightingEnv === 'hallway') {
        const hallLight = new HemisphericLight("HallwayHemiLight", new Vector3(-4, 2, -2), scene)
        hallLight.intensity = 1.3
        lights.push(hallLight)
      }
      else if (lightingEnv === 'studio2') {
        const studio2Light = new DirectionalLight('Studio2DirectionalLight', new Vector3(0, -1, -0.0), scene)
        studio2Light.intensity = 1.3
        envFile = 'studio.env'
        lights.push(studio2Light)
      }
      else if (lightingEnv === 'none') {
        envFile = 'environmentSpecular.env'
      }
      else if (lightingEnv === 'none2') {
        envFile = 'bblStudio.env'
      }
      else if (lightingEnv === 'outdoors') {
        envFile = 'mvNeutral.env'
      }
      else if (lightingEnv === 'bcktNeutral') {
        envFile = 'bcktNeutral.env'
      }
      else if (lightingEnv === 'bcktSunrise') {
        envFile = 'bcktSunrise.env'
      }
      else if (lightingEnv === 'nileDefault') {
        envFile = null

        const upperAmb = new HemisphericLight('UpperAmbientLight', new Vector3(0, 1, 0), scene)
        upperAmb.specular = new Color3(0, 0, 0)
        upperAmb.intensity = 1
        
        const lowerAmb = new HemisphericLight('LowerAmbientLight', new Vector3(0, -1, 0), scene)
        lowerAmb.specular = new Color3(0, 0, 0)
        lowerAmb.intensity = 1
        scene.environmentIntensity = 1
      }
      else if (lightingEnv === 'nileMetallicColor') {
        envFile = 'nileNeutral.env'
        scene.environmentIntensity = 2.4
      }
      else if (lightingEnv === 'nileMetallicValue') {
        envFile = 'nileChromatic.env'
        scene.environmentIntensity = 1.6
      }
      else if (lightingEnv === 'nileARM') {
        envFile = 'nileDirectional.env'
        scene.environmentIntensity = 1.2
      }

      // Setup sun as needed
      if (envFile === 'toggleNeutral.env') {
        const sunLight = new HemisphericLight("SunLight", new Vector3(0, 1, 0), scene)
        sunLight.intensity = 1.3
        lights.push(sunLight)
      }

      // Adjust contrast as needed
      if (envFile === 'mvNeutral.env') {
        scene.imageProcessingConfiguration.contrast = 2
      }
      else {
        scene.imageProcessingConfiguration.contrast = 1
      }

      // Load env if it's not already
      const urlPrefix = `${APP_RESOURCES_URL}env/`
      if (!scene.environmentTexture || scene.environmentTexture.url !== `${urlPrefix}${envFile}`) {

        // Clear existing env textures
        for (const e of scene.textures) {
          if (e.name.indexOf(urlPrefix) > -1) {
            console.log("Dispose env: ", e.name)
            e.dispose()
          }
        }

        if (envFile) {
          console.log("Loading env: ", envFile)
          const envTexture = await loadCubeTexture(scene, `${urlPrefix}${envFile}`)
          scene.environmentTexture = envTexture
        }
      }

      // Done
      resolve(lights)
    })
  })
}

const createDecalMaterial = (scene, matId, textureUrl) => {
  const decalMaterial = new PBRMaterial(matId, scene)
  decalMaterial.albedoTexture = new Texture(textureUrl, scene)
  decalMaterial.albedoTexture.hasAlpha = true
  decalMaterial.ambientTextureStrength = 1
  decalMaterial.alpha = 1
  decalMaterial.metallic = 1
  decalMaterial.roughness = 1
  decalMaterial.zOffset = -2
  return decalMaterial
} 

const validateLastUsedMaterials = (newMaterial, prevMaterials) => {
  if (prevMaterials && prevMaterials.length > 0) {
    if (!newMaterial || !newMaterial.id) {
      return false
    }

    const uniqueMaterials = new Set([...prevMaterials.filter((m) => m !== null && m !== undefined && m.id !== undefined).map(mat => mat.id)])
    if (uniqueMaterials.has(newMaterial.id)) {
      const [firstMatID] = uniqueMaterials 
      return (firstMatID === newMaterial.id) ? false : T.MOVE_TO_TOP
    }
    if (prevMaterials.length >= 20) { return T.LIMIT_EXCEEDED }
    return true
  }
  return true
}

const awaitToJs = promise => promise.then(res => [null, res]).catch(err => [err || true, null])

export { 
  moveActiveCamera,
  awaitToJs,
  validateMatSettingsInput,
  validateLastUsedMaterials,
  createLightingEnvironment,
  getProjectIcon,
  prepareCamera,
  handleContinuousSelection, 
  exportModelForThreeJS, 
  generateMap, 
  canvastoCWHBuffer, 
  photonImage2Canvas, 
  resizeIfLarger, 
  loadImageToCanvas, 
  createMaterialScene, 
  createConfiguratorScene, 
  createBaseScene, 
  updateMaterialMeta, 
  loadCubeTexture, 
  setTexture, 
  createNewMaterial, 
  exportModel, 
  getStatusClass, 
  getCanvasForTextureImage, 
  createMaterialManifest, 
  matTextureToOutput, 
  doArrow, 
  mapPatternChanges, 
  validateUVInput, 
  getResolutionLabel, 
  resetTheProject,
  createDownloadLink,
  createDecalMaterial,
  addDecalToMesh,
  disposeMaterial
}
