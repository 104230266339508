import { useState, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import UpgradeModalComponent from "../../../components/plan-modal/upgrade-modal.component";
import DowngradeModalComponent from "../../../components/plan-modal/downgrade-modal.component";
import {
  useLazyGetUserInvoiceQuery,
  useLazyUpgradeUserQuery,
} from "../../../redux/api.redux.slice";
import { setUserSubscription } from "../../../redux/auth.redux.slice";
import { KLAVIYO_METRICS, STRIPE_PLANS } from "../../../constants";
import PricingComponent from "../../../components/pricing/pricing.component";
import moment from "moment";
import { setToast } from "../../../redux/ui.redux.slice";

const PlansSettingsSection = (props) => {
  const dispatch = useDispatch();
  const { role, plan, didTrial } = useSelector((state) => state.auth);
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [showDowngrade, setShowDowngrade] = useState(false);
  const [invoice, setInvoice] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [upgradeUser, { isLoading }] = useLazyUpgradeUserQuery();
  const [getUserInvoice, { isLoading: invoiceLoading }] =
  useLazyGetUserInvoiceQuery();

  const getInvoice = useCallback(async () => {
    try {
      const invoice = await getUserInvoice().unwrap();
      setInvoice(invoice);
    } catch (e) {
      console.error("Error fetching upcoming invoice: ", e);
      // We'll fail silently... no need to alert the user in this case
    }
  }, [getUserInvoice]);

  useEffect(() => {
    const doUpgrade = async () => {
      try {
        await upgradeUser().unwrap();

        window.klaviyo.identify({ Plan: 'Pro'}).then(() => {
          window.klaviyo.track(KLAVIYO_METRICS.upgraded_to_pro)
        })

        // Set plan
        dispatch(
          setUserSubscription({
            plan: STRIPE_PLANS.find((p) => p.type === "pro"),
            status: "active",
          })
        );

        // Update invoice
        getInvoice();
      } catch (e) {
        console.error("error upgrading user: ", e);
        dispatch(
          setToast({
            message:
              "Uh oh. We had an issue upgrading your plan, please try again.",
            isError: true,
          })
        );
      }
    };

    if (
      searchParams.get("setup_intent") &&
      searchParams.get("redirect_status") === "succeeded"
    ) {
      // Handle stripe
      doUpgrade();

      // Show toast
      dispatch(setToast({ message: "Welcome to toggle3D pro!" }));

      // Clear params
      setSearchParams("");
    }
  }, [searchParams, setSearchParams, upgradeUser, dispatch, getInvoice]);

  // Get next invoice on load
  useEffect(() => {
    if (role === "user") {
      getInvoice();
    }
  }, [getInvoice, role]);

  const handleSelectPlan = (planType) => {
    if (planType === "pro") {
      setShowUpgrade(true);
    } else {
      setShowDowngrade(true);
    }
  };

  const handleDowngrade = () => {
    setShowDowngrade(false);
    window.klaviyo.identify({ Plan: 'Free'}).then(() => {
      window.klaviyo.track(KLAVIYO_METRICS.downgraded_to_pro)
    })
    getInvoice();
    dispatch(
      setToast({ message: "Your plan has been downgraded successfully" })
    );
  };

  return (
    <>
      {showUpgrade && (
          <UpgradeModalComponent
            stripePromise={props.stripePromise}
            onClose={() => setShowUpgrade(false)}
          />
        )}
        {showDowngrade && (
          <DowngradeModalComponent
            onDowngradeComplete={handleDowngrade}
            onClose={() => setShowDowngrade(false)}
          />
      )}
      <div
        className={
          !plan || !role || isLoading
            ? "toggle-in-page-container threedy-skeleton"
            : "toggle-in-page-container"
        }
      >
        <div className="settings-wrapper">
          <div className="full-container">
            {role && role === "user" && (
              <>
                <div className="settings-card">
                  <PricingComponent
                hideHeader
                embedded
                currentPlanType={plan && plan.type}
                onSelectPlan={handleSelectPlan}
                didTrial={didTrial}
                  />
                </div>
                <div className="settings-card">
                  <div className="upcoming-invoice">
                    <h5>Upcoming Invoice</h5>
                    <div
                      className={
                        !plan || invoiceLoading || !invoice
                          ? "threedy-skeleton"
                          : "invoice-details"
                      }
                    >
                      <ul>
                        {invoice.lines && invoice.lines.data && invoice.lines.data.length > 0 && (
                            <>
                              {invoice.lines.data.map((l) => {
                                return (
                                  <li key={l.id}>
                                    <p>{l.description}</p>
                                  </li>
                                );
                              })}
                            </>
                          )}
                      </ul>
                      <p>
                        <span className="heading">Date:</span>&nbsp;
                        <span className="sub-heading">{moment(invoice.created * 1000).format("MM/DD/YYYY")}</span>
                      </p>
                      <p>
                        <span className="heading">Total:</span>&nbsp;
                        <span className="sub-heading">${invoice.total !== 0 ? invoice.total / 100 : 0}.00</span>
                      </p>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default PlansSettingsSection;
