import { useCallback, useEffect } from "react"
import { useDispatch } from "react-redux"
import { EVENTS, HISTORY_EVENT_TYPES } from "../../../constants"
import { useEditProjectMutation } from "../../../redux/api.redux.slice"
import { setSelectedProjectProperty, setToast } from "../../../redux/ui.redux.slice"

const useWorkspaceHistory = (apiCache, parts, handleSetMaterial, handleSaveMaterial, handleSaveMaterialSettings, project, isEditingPart) => {

  const dispatch = useDispatch()
  const [updateProject] = useEditProjectMutation()

  const handleEditMaterialEvent = useCallback(async (isUndo, params) => {
    const {partIds, previousMaterialId, destinationMaterialId, isColorTransfer} = params
    const material = apiCache[(isUndo ? previousMaterialId : destinationMaterialId)]
    if (!material) {
      return
    }

    const partsArr = parts.filter((p) => partIds.indexOf(p.id) > -1)
    if (partsArr.length < 1) {
      return
    }

    await handleSetMaterial(partsArr, material)
    handleSaveMaterial(material, false, partsArr, true)

    if (isColorTransfer && !isEditingPart) {
      // Dispatch CT queue event
      setTimeout(() => document.dispatchEvent(new CustomEvent(EVENTS.CLEAR_COLOR_TRANSFER_QUEUE)))
    }

  }, [handleSetMaterial, handleSaveMaterial, parts, apiCache, isEditingPart])

  const handleEditMaterialSettingsEvent = useCallback(async (isUndo, params) => {
    const {materialId, partId, previousSettings, destinationSettings} = params
    const settings = isUndo ? previousSettings : destinationSettings
    handleSaveMaterialSettings(partId, materialId, settings, true, true)
  }, [handleSaveMaterialSettings])

  const handleEditUvSettingsEvent = useCallback(async (isUndo, params) => {
    const {partIds, previousSettings, destinationSettings} = params
    const settings = isUndo ? previousSettings : destinationSettings
    handleSaveMaterialSettings(partIds, settings, true, true)
  }, [handleSaveMaterialSettings])

  const handleRenameProjectEvent = useCallback(async (isUndo, params) => {
    if (!project) {
      return
    }

    const {previousName, destinationName} = params
    const name = isUndo ? previousName : destinationName

    try {
      await updateProject({projectId: project.id, body: {name}}).unwrap()
      dispatch(setSelectedProjectProperty({property: "name", value: name}))
    }
    catch (e) {
      dispatch(setToast({message: "Uh oh. We had an issue updating your project, please try again.", isError: true}))
    }

  }, [dispatch, updateProject, project])

  const handlePasteEvent = useCallback((isUndo, params) => {
    const {actions} = params

    // Loop through actions
    for (let i = 0; i < actions.length; i++) {
      switch (actions[i].type) {
        default: break
        case HISTORY_EVENT_TYPES.EDIT_MATERIAL: {
          handleEditMaterialEvent(isUndo, actions[i].params)
          break
        }
        case HISTORY_EVENT_TYPES.EDIT_MATERIAL_SETTINGS: {
          handleEditMaterialSettingsEvent(isUndo, actions[i].params)
          break
        }
        case HISTORY_EVENT_TYPES.EDIT_UV_SETTINGS: {
          handleEditUvSettingsEvent(isUndo, actions[i].params)
          break
        }
      }     
    }
  }, [handleEditMaterialEvent, handleEditMaterialSettingsEvent, handleEditUvSettingsEvent])

  useEffect(() => {

    const handleUndoRedo = (e) => {
      const {historyAction, type, params} = e.detail
      const isUndo = historyAction === EVENTS.HISTORY_UNDO

      switch (type) {
        default: break
        case HISTORY_EVENT_TYPES.PASTE: {
          handlePasteEvent(isUndo, params)
          break
        }
        case HISTORY_EVENT_TYPES.EDIT_MATERIAL: {
          handleEditMaterialEvent(isUndo, params)
          break
        }
        case HISTORY_EVENT_TYPES.EDIT_MATERIAL_SETTINGS: {
          handleEditMaterialSettingsEvent(isUndo, params)
          break
        }
        case HISTORY_EVENT_TYPES.EDIT_UV_SETTINGS: {
          handleEditUvSettingsEvent(isUndo, params)
          break
        }
        case HISTORY_EVENT_TYPES.RENAME_PROJECT: {
          handleRenameProjectEvent(isUndo, params)
          break
        }
      }      
    }

    document.addEventListener(EVENTS.HISTORY_UNDO, handleUndoRedo)
    document.addEventListener(EVENTS.HISTORY_REDO, handleUndoRedo)

    return () => {
      document.removeEventListener(EVENTS.HISTORY_UNDO, handleUndoRedo)
      document.removeEventListener(EVENTS.HISTORY_REDO, handleUndoRedo)
    }
  }, [handlePasteEvent, handleEditMaterialEvent, handleEditMaterialSettingsEvent, handleEditUvSettingsEvent, handleRenameProjectEvent])
}

export default useWorkspaceHistory