import { useDispatch } from "react-redux"
import { STRIPE_PLANS } from "../../constants"
import { useLazyDowngradeUserQuery } from "../../redux/api.redux.slice"
import { setUserSubscription } from "../../redux/auth.redux.slice"
import ModalComponent from "../modal/modal.component"
import { setToast } from "../../redux/ui.redux.slice"

const DowngradeModalComponent = (props) => {

  const dispatch = useDispatch()
  const [downgradeUser, {isLoading}] = useLazyDowngradeUserQuery()

  const handleConfirmDowngrade = async () => {
    try {
      await downgradeUser().unwrap()

      // Set plan
      dispatch(setUserSubscription({plan: STRIPE_PLANS.find((p) => p.type === 'free'), status: 'active'}))

      // Close
      props.onDowngradeComplete()
    }
    catch (e) {
      console.error("Error downgrading user", e)
      dispatch(setToast({message: "Uh oh. We had an issue downgrading your plan, please try again.", isError: true}))
    }
  }

  const modalContent = (
    <div className={isLoading ? "threedy-skeleton" : ""}>
      <h6>Are you sure you would like to go back to the free plan?</h6>
      <p>Changing your plan will go into effect immediately</p>
    </div>
  )

  const modalActions = (
    <>
      <button className="toggle-default-btn" onClick={props.onClose} disabled={isLoading}>Cancel</button>
      <button className="delete-btn toggle-outline-btn auto-width" disabled={isLoading} onClick={handleConfirmDowngrade}>Confirm</button>
    </>
  )

  return <ModalComponent title="Cancel your Pro plan" content={modalContent} actions={modalActions} close={props.onClose} />
}

export default DowngradeModalComponent