import { useCallback, useEffect, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { addMaterials } from "../../../redux/materials.redux.slice"
import { useLazyGetMaterialMapsAtResolutionByIdQuery, useLazyGetPersonalMaterialMapsAtResolutionByIdQuery } from "../../../redux/api.redux.slice"
import { awaitToJs, setTexture, updateMaterialMeta } from "../../../utils/ui.util"
import { EVENTS } from "../../../constants"
import MiscHelperMethods from "../helper-methods/misc.helpers"

const useHighResMaterialFetching = (scene, project, parts, shared, customizedMaterials) => {

  const dispatch = useDispatch()
  const cachedMaterials = useSelector((state) => state.materials.materials)
  const [fetchMaterialAtRes] = useLazyGetMaterialMapsAtResolutionByIdQuery()
  const [fetchPersonalMaterialAtRes] = useLazyGetPersonalMaterialMapsAtResolutionByIdQuery()
  const currentResRef = useRef("1k")
  const isFetchingRef = useRef()
  const timerRef = useRef()

  const fetchMapsForMaterialAtRes = useCallback((mat, resolution) => {
    return new Promise(async (resolve) => {
      try {
        const params = {id: mat.id, resolution}
        if (mat.personal) {
          const [err, res] = await awaitToJs(fetchPersonalMaterialAtRes(params).unwrap())
          if (err) {
            return resolve(false)
          }

          resolve(res)
        }
        else {
          const [err, res] = await awaitToJs(fetchMaterialAtRes(params).unwrap())

          if (err) {
            return resolve(false)
          }

          resolve(res)
        }
      }
      catch (e) {
        console.error(e)

        // Fail silently
        resolve(false)
      }
    })
  }, [fetchPersonalMaterialAtRes, fetchMaterialAtRes])

  // Fetch additional maps
  useEffect(() => {
    if (shared || !scene || !scene.materials || scene.materials.length < 1 || isFetchingRef.current) {
      return
    }

    const fetchAdditionalMaterials = async () => {

      console.log("fetchAdditionalMaterials")
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }

      isFetchingRef.current = true

      // First, get available resolutions in project
      const {resolutions, materials} = await MiscHelperMethods.getProjectResolutions(project.id)
      let hasDisabled = false

      // Next, we'll fetch maps at the available resolutions
      if (materials.length < 1 || scene.materials.length < materials.length) { 
        return
      }

      // Fetch higher resolution maps if they are ready
      const matsToAdd = {}
      for (let i = 0; i < resolutions.length; i++) {
        const res = resolutions[i]
        if (res.disabled) { 
          hasDisabled = true
          continue 
        }

        for (let j = 0; j < materials.length; j++) {
          const mat = materials[j]
          const sceneMat = scene.materials.find((m) => m.id === mat.id)
          let isNew = false

          if (!sceneMat) { 
            continue
          }

          if (!cachedMaterials[mat.id]) {
            isNew = true
            matsToAdd[mat.id] = {
              defaults: {
                color: sceneMat.albedoTexture,
                ARM: sceneMat.metallicTexture,
                normal: sceneMat.bumpTexture
              },
              highRes: {} 
            }
          }

          // Fetch maps if this material supports it & we haven't grabbed them already
          if (res.r > 1024 && mat.resolutions.indexOf(res.r) > -1 && ((!isNew && !cachedMaterials[mat.id].highRes[res.id]) || isNew)) {
            const maps = await fetchMapsForMaterialAtRes(mat, res.id)
            console.log('fetching maps for ' + mat.id + " at res " + res.id)
            const base = isNew ? matsToAdd[mat.id] : cachedMaterials[mat.id]
            if (maps) {
              matsToAdd[mat.id] = {...base, highRes: {...base.highRes, [res.id]: maps}}
            }
          }
        }
      }

      // todo: Maybe clear out any un-used materials in store?
      if (Object.keys(matsToAdd).length > 0) {
        // Update redux
        dispatch(addMaterials(matsToAdd))
      }

      // Re-check in 5s if we have any disabled
      if (hasDisabled) {
        console.log("re-checking high res materials in 5s")
        timerRef.current = setTimeout(fetchAdditionalMaterials, 5000)
      }

      isFetchingRef.current = false
    }

    fetchAdditionalMaterials()

    // Clear timer if it exists
    return () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
        timerRef.current = null
      }
    }

  }, [dispatch, parts, project, shared, scene, cachedMaterials, fetchMapsForMaterialAtRes])


  const swapMapsForMaterial = useCallback((mat, maps, targetRes) => {

    return new Promise(async resolve => {
      if (maps.color) {
        const resolution = Object.keys(maps.color)[0]
        const { texture } = await setTexture(scene, mat.id + '-albedoHi', ["albedoTexture"], maps.color[resolution], true)
        mat.albedoTexture = texture
        mat.albedoTexture.update(false)
      }

      // ARM
      if (maps.ARM) {
        const resolution = Object.keys(maps.ARM)[0]
        const { texture } = await setTexture(scene, mat.id + '-armHi', ["metallicTexture"], maps.ARM[resolution], true)
        mat.metallicTexture = texture
        mat.metallicTexture.update(false)
      }

      // Normal
      if (maps.normal) {
        const resolution = Object.keys(maps.normal)[0]
        const { texture } = await setTexture(scene, mat.id + '-normalHi', ["bumpTexture"], maps.normal[resolution], true)
        mat.bumpTexture = texture
        mat.bumpTexture.update(false)
      }

      resolve()
    })

  }, [scene])

  const revertMapsForMaterial = useCallback(async (mat, maps) => {
    if (maps.color) {
      const texture = scene.getTextureByName(mat.id + '-albedo')
      const textureHi = scene.getTextureByName(mat.id + '-albedoHi')
      if (textureHi) {
        textureHi.dispose()
        mat.albedoTexture = texture
        mat.albedoTexture.update(false)
      }
    }

    // ARM
    if (maps.ARM) {
      const texture = scene.getTextureByName(mat.id + '-arm')
      const textureHi = scene.getTextureByName(mat.id + '-armHi')
      if (textureHi) {
        textureHi.dispose()
        mat.metallicTexture = texture
        mat.metallicTexture.update(false)
      }
    }

    // Normal
    if (maps.normal) {
      const texture = scene.getTextureByName(mat.id + '-normal')
      const textureHi = scene.getTextureByName(mat.id + '-normalHi')
      if (textureHi) {
        textureHi.dispose()
        mat.bumpTexture = texture
        mat.bumpTexture.update(false)
      }
    }

  }, [scene])

  const setMaterialForPart = useCallback(async (p, sceneMat) => {
    const partIds = p.type === 'group' ? p.parts.map((p) => p.id) : [p.id]
    let mat = sceneMat
    if (p.meta && Object.keys(p.meta).length > 0) {
      const {mat: customizedMat, metaHash} = await updateMaterialMeta(scene, sceneMat, p.meta, customizedMaterials, true)
      if (metaHash) {
        mat = customizedMat
        customizedMaterials[metaHash].dispose(true, true)
        customizedMaterials[metaHash] = customizedMat
      }
    }

    scene.meshes.filter((m) => partIds.indexOf(m.id) > -1).forEach((f) => f.material = mat)
  }, [customizedMaterials, scene])

  // Listen for resolution change event
  useEffect(() => {

    const handleResolutionChange = async (e) => {
      const resolution = e.detail
      if (resolution === currentResRef.current) {
        return
      }

      currentResRef.current = resolution
      console.log("Switch to " + resolution + " resolution")

      if (MiscHelperMethods.parts.size < 1) {
        return
      }

      const swapped = {}
      for (const pk of MiscHelperMethods.parts.entries()) {
        const p = pk[1]
        if (p.material && cachedMaterials[p.material.id]) {
          const cachedMat = cachedMaterials[p.material.id]
          const sceneMat = scene.materials.find((m) => m.id === p.material.id)

          // todo only swap once
          if (sceneMat) {
            let doSet = false
            if (resolution !== '1k' && cachedMat.highRes && cachedMat.highRes[resolution]) {
              if (!swapped[p.material.id]) {
                console.log('Swap maps for ',p.material.id)
                await swapMapsForMaterial(sceneMat, cachedMat.highRes[resolution], resolution)
                swapped[p.material.id] = true
              }

              doSet = true
            }
            else {
              if (!swapped[p.material.id]) {
                console.log('Revert to 1k for ', p.material.id)
                revertMapsForMaterial(sceneMat, cachedMat.defaults)
                swapped[p.material.id] = true
              }

              doSet = true
            }

            if (doSet) {
              setMaterialForPart(p, sceneMat)
            }
          }
        }
      }
    }

    document.addEventListener(EVENTS.SWITCH_RESOLUTION, handleResolutionChange)

    return () => document.removeEventListener(EVENTS.SWITCH_RESOLUTION, handleResolutionChange)

  }, [cachedMaterials, scene, swapMapsForMaterial, revertMapsForMaterial, customizedMaterials, setMaterialForPart])

}

export default useHighResMaterialFetching