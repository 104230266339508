import { useState } from "react";
import { useBetween } from "use-between"

const useVisibleMaterialIDs = () => {
    const [matIDs, setMatIDs] = useState(new Set())
    return { matIDs, setMatIDs }
}

const useJustImportedVisibleMaterials = () => useBetween(useVisibleMaterialIDs);
export default useJustImportedVisibleMaterials
