import React from 'react'
import { Link } from 'react-router-dom'
import "./upgrade-card.component.css"

const UpgradeCardComponent = () => {
    return (
        <div className="upgrade-card">
            <div className="title">
                Activate Pro
            </div>
            <div className="text">
                Unlock the best of Toggle3D with quad conversions, decals and more advanced features.
            </div>
            <Link to="/settings/plans" className="toggle-outline-btn primary-btn rectangular-btn">
                Upgrade
            </Link>
        </div>
    )
}

export default UpgradeCardComponent