import { useCallback, useEffect, useRef, useState, useReducer } from "react"
import { DEBOUNCE_DELAY, DEFAULT_UV_PROPERTIES, EVENTS, SETTINGS_MIN_MAX } from "../../constants"
import { mapPatternChanges, validateUVInput } from "../../utils/ui.util"
import "../popup-settings/popup-settings.css"

const UVSettingsComponent = (props) => {

  const [workingMeta, setWorkingMeta] = useState(false)
  const workingItems = useRef(false)
  const debounceRef = useRef()
  const { part, onSave } = props
  const [uvInputs, updateUvInputs] = useReducer((prev, next) => {
    const newUvinputs = {...prev, ...next}
    newUvinputs.uScale = validateUVInput(newUvinputs.uScale, 'scale')
    newUvinputs.vScale = validateUVInput(newUvinputs.vScale, 'scale')
    newUvinputs.uOffset = validateUVInput(newUvinputs.uOffset, 'offset')
    newUvinputs.vOffset = validateUVInput(newUvinputs.vOffset, 'offset')
    newUvinputs.rotation = validateUVInput(newUvinputs.rotation, 'rotation')
    return newUvinputs
  }, {showUScaleInput: false, uScale: 1, showVScaleInput: false, vScale: 1, showUOffsetInput: false, uOffset: 0, showVOffsetInput: false, vOffset: 0, showRotationInput: false, rotation: 0})

  // Clean up debounce
  useEffect(() => {
    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current)
        debounceRef.current = null
      }
    }
  }, [])

  useEffect(() => {

    if (!part) {
      return
    }

    const meta = part.meta ? {...DEFAULT_UV_PROPERTIES, ...part.meta} : DEFAULT_UV_PROPERTIES
    setWorkingMeta(meta)

  }, [part])

  const handleSliderChange = (target) => {
    const {name, value} = target
    const updatedMeta = {...workingMeta, [name]: value}
    setWorkingMeta(updatedMeta)

    // Save after debounce
    if (onSave) {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current)
        debounceRef.current = null
      }

      debounceRef.current = setTimeout(() => {
        onSave(updatedMeta)
      }, DEBOUNCE_DELAY)
    }
  }

  const handleSetDefaults = useCallback(() => {
    
    const updated = DEFAULT_UV_PROPERTIES
    setWorkingMeta(updated)

    // Save after debounce
    if (onSave) {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current)
        debounceRef.current = null
      }

      debounceRef.current = setTimeout(() => {
        onSave(updated)
      }, DEBOUNCE_DELAY)
    }
  }, [onSave])

  // Listen for reset event
  useEffect(() => {
    document.addEventListener(EVENTS.RESET_UV_SETTINGS, handleSetDefaults)

    return () => document.removeEventListener(EVENTS.RESET_UV_SETTINGS, handleSetDefaults)
  }, [handleSetDefaults])

  const handleEditUvSettings = (setting) => {
    const currValue = workingMeta[setting]
    const editableInputs = {...mapPatternChanges(setting, true), [setting]: currValue}
    updateUvInputs(editableInputs)
  }

  const handleKeyDown = (e) => {
    const { key, keyCode } = e
    if (key === 'Enter' || keyCode === 13) {
      e.target.blur()
    }
  }

  const handleBlur = (setting) => {
    updateUvInputs({...mapPatternChanges(setting, false)})
    if (workingMeta[setting] !== uvInputs[setting]) {
      const newValue = uvInputs[setting]
      handleSliderChange({name: setting, value: newValue || DEFAULT_UV_PROPERTIES[setting]})
    }
  }

  if (!workingMeta) {
    return null
  }

  const uvSettingsHeaders = {
    uScale: (
      <h6>
        Horizontal Scale: {
          uvInputs.showUScaleInput ? (
            <input
              className="uv-settings-input"
              type="number"
              value={uvInputs.uScale}
              onChange={e => updateUvInputs({uScale: e.target.value})}
              onKeyDown={handleKeyDown}
              onBlur={() => handleBlur('uScale')}
              autoFocus
            />
          ) : <strong className="pattern-value">{workingMeta.uScale}<span onClick={() => handleEditUvSettings('uScale')} className="icon-edit-2"></span></strong>
        }
      </h6>
    ),
    vScale: (
      <h6>
        Vertical Scale: {
          uvInputs.showVScaleInput ? (
            <input
              className="uv-settings-input"
              type="number"
              value={uvInputs.vScale}
              onChange={e => updateUvInputs({vScale: e.target.value})}
              onKeyDown={handleKeyDown}
              onBlur={() => handleBlur('vScale')}
              autoFocus
            />
          ) : <strong className="pattern-value">{workingMeta.vScale}<span onClick={() => handleEditUvSettings('vScale')} className="icon-edit-2"></span></strong>
        }
      </h6>
    ),
    uOffset: (
      <h6>
        Move Horizontally: {
          uvInputs.showUOffsetInput ? (
            <input
              className="uv-settings-input"
              type="number"
              value={uvInputs.uOffset}
              onChange={e => updateUvInputs({uOffset: e.target.value})}
              onKeyDown={handleKeyDown}
              onBlur={() => handleBlur('uOffset')}
              autoFocus
            />
          ) : <strong className="pattern-value">{workingMeta.uOffset}<span onClick={() => handleEditUvSettings('uOffset')} className="icon-edit-2"></span></strong>
        }
      </h6>
    ),
    vOffset: (
      <h6>
        Move Vertically : {
          uvInputs.showVOffsetInput ? (
            <input
              className="uv-settings-input"
              type="number"
              value={uvInputs.vOffset}
              onChange={e => updateUvInputs({vOffset: e.target.value})}
              onKeyDown={handleKeyDown}
              onBlur={() => handleBlur('vOffset')}
              autoFocus
            />
          ) : <strong className="pattern-value">{workingMeta.vOffset}<span onClick={() => handleEditUvSettings('vOffset')} className="icon-edit-2"></span></strong>
        }
      </h6>
    ),
    rotation: (
      <h6>
        Rotation: {
          uvInputs.showRotationInput ? (
            <input
              className="uv-settings-input"
              type="number"
              value={uvInputs.rotation}
              onChange={e => updateUvInputs({rotation: e.target.value})}
              onKeyDown={handleKeyDown}
              onBlur={() => handleBlur('rotation')}
              autoFocus
            />
          ) : <strong className="pattern-value">{workingMeta.rotation}<span onClick={() => handleEditUvSettings('rotation')} className="icon-edit-2"></span></strong>
        }
      </h6>
    ),
  }

  return (
    <>
      <div className="material-adjuster-settings">
        <div className="toggle-popup-setting">
          {uvSettingsHeaders.uScale}
          <input className="slider" type="range" min={SETTINGS_MIN_MAX.scale.min} max={SETTINGS_MIN_MAX.scale.max} step={SETTINGS_MIN_MAX.scale.step} name="uScale" value={workingMeta.uScale} onChange={(e) => handleSliderChange(e.target)} />
          <div className="slider-percentage">
            <span>{SETTINGS_MIN_MAX.scale.min}</span>
            <span>{SETTINGS_MIN_MAX.scale.max}</span>
          </div>
        </div>
        <div className="toggle-popup-setting">
          {uvSettingsHeaders.vScale}
          <input className="slider" type="range" min={SETTINGS_MIN_MAX.scale.min} max={SETTINGS_MIN_MAX.scale.max} step={SETTINGS_MIN_MAX.scale.step} name="vScale" value={workingMeta.vScale} onChange={(e) => handleSliderChange(e.target)} />
          <div className="slider-percentage">
            <span>{SETTINGS_MIN_MAX.scale.min}</span>
            <span>{SETTINGS_MIN_MAX.scale.max}</span>
          </div>
        </div>
        <div className="toggle-popup-setting">
          {uvSettingsHeaders.uOffset}
          <input className="slider" type="range" min={SETTINGS_MIN_MAX.offset.min} max={SETTINGS_MIN_MAX.offset.max} step={SETTINGS_MIN_MAX.offset.step} name="uOffset" value={workingMeta.uOffset} onChange={(e) => handleSliderChange(e.target)} />
          <div className="slider-percentage">
            <span>{SETTINGS_MIN_MAX.offset.min}</span>
            <span>{SETTINGS_MIN_MAX.offset.max}</span>
          </div>
        </div>
        <div className="toggle-popup-setting">
          {uvSettingsHeaders.vOffset}
          <input className="slider" type="range" min={SETTINGS_MIN_MAX.offset.min} max={SETTINGS_MIN_MAX.offset.max} step={SETTINGS_MIN_MAX.offset.step} name="vOffset" value={workingMeta.vOffset} onChange={(e) => handleSliderChange(e.target)} />
          <div className="slider-percentage">
            <span>{SETTINGS_MIN_MAX.offset.min}</span>
            <span>{SETTINGS_MIN_MAX.offset.max}</span>
          </div>
        </div>
        <div className="toggle-popup-setting">
          {uvSettingsHeaders.rotation}
          <input className="slider" type="range" min={SETTINGS_MIN_MAX.rotation.min} max={SETTINGS_MIN_MAX.rotation.max} step={SETTINGS_MIN_MAX.rotation.step} name="rotation" value={workingMeta.rotation} onChange={(e) => handleSliderChange(e.target)} />
          <div className="slider-percentage">
            <span>{SETTINGS_MIN_MAX.rotation.min}</span>
            <span>{SETTINGS_MIN_MAX.rotation.max}</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default UVSettingsComponent