import { useCallback, useEffect, useRef, useState, useReducer } from "react"
import { DEBOUNCE_DELAY, DEFAULT_MATERIAL_PROPERTIES, EVENTS, SETTINGS_MIN_MAX } from "../../constants"
import { mapPatternChanges, validateMatSettingsInput } from "../../utils/ui.util"
import "../popup-settings/popup-settings.css"

const MaterialAdjusterComponent = (props) => {
  const [workingMeta, setWorkingMeta] = useState(false)
  const debounceRef = useRef()
  const [materialInputs, updateMaterialInputs] = useReducer((prev, next) => {
    const newMaterialInputs = {...prev, ...next}
    newMaterialInputs.alpha = validateMatSettingsInput(newMaterialInputs.alpha, 'alpha')
    newMaterialInputs.metallic = validateMatSettingsInput(newMaterialInputs.metallic, 'metallic')
    newMaterialInputs.roughness = validateMatSettingsInput(newMaterialInputs.roughness, 'roughness')
    newMaterialInputs.normalIntensity = validateMatSettingsInput(newMaterialInputs.normalIntensity, 'normalIntensity')
    newMaterialInputs.refractionIntensity = validateMatSettingsInput(newMaterialInputs.refractionIntensity, 'refractionIntensity')
    newMaterialInputs.indexOfRefraction = validateMatSettingsInput(newMaterialInputs.indexOfRefraction, 'indexOfRefraction')
    return newMaterialInputs
  }, {
    showAlphaInput: false, alpha: 0,
    showMetallicInput: false, metallic: 0,
    showRoughnessInput: false, roughness: 0,
    showNormalIntensityInput: false, normalIntensity: 0,
    showRefractionIntensityInput: false, refractionIntensity: 0,
    showIndexOfRefractionInput: false, indexOfRefraction: 0,
  })
  const { onSave, part } = props

  useEffect(() => {

    if (!part) {
      return
    }

    // console.log("load mat:", material.id, material.meta)
    const meta = part.meta ? {...DEFAULT_MATERIAL_PROPERTIES, ...part.meta} : DEFAULT_MATERIAL_PROPERTIES
    setWorkingMeta(meta)

    if (props.includeName) {
      document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: false}}))
    }

    return () => {
      if (props.includeName) {
        document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled: true}}))
      }
    }

  }, [part, props.includeName])

  const handleSliderChange = (target) => {
    const {name, value} = target
    const updatedMeta = {...workingMeta, [name]: parseInt(value)}
    setWorkingMeta(updatedMeta)

    if (debounceRef.current) {
      clearTimeout(debounceRef.current)
      debounceRef.current = null
    }

    debounceRef.current = setTimeout(() => {
      if (onSave) {
        onSave(updatedMeta)
      }
    }, DEBOUNCE_DELAY)

    if (props.onChange) {
      props.onChange(updatedMeta)
    }
  }

  const handleNameChange = (e) => {
    const updates = {...workingMeta, name: e.target.value}
    setWorkingMeta(updates)

    if (props.onChange) {
      props.onChange(updates)
    }
  }

  const handleKeyDown = (e) => {
    if (!workingMeta.name) { return }
    const { key, keyCode, code } = e
    if (key === 'Enter' || keyCode === 13 || code === 'Enter') {
      e.target.blur()
    }
  }

  const handleSetDefaults = useCallback(() => {
    const updated = DEFAULT_MATERIAL_PROPERTIES
    setWorkingMeta(updated)

    // Save after debounce
    if (onSave) {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current)
        debounceRef.current = null
      }

      debounceRef.current = setTimeout(() => {
        onSave(updated)
      }, DEBOUNCE_DELAY)
    }
  }, [onSave])

  // Listen for reset event
  useEffect(() => {
    document.addEventListener(EVENTS.RESET_MAT_SETTINGS, handleSetDefaults)

    return () => document.removeEventListener(EVENTS.RESET_MAT_SETTINGS, handleSetDefaults)
  }, [handleSetDefaults])

  // Clean up debounce
  useEffect(() => {
    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current)
        debounceRef.current = null
      }
    }
  }, [])

  const handleBlur = (setting) => {
    updateMaterialInputs({...mapPatternChanges(setting, false)})
    if (workingMeta[setting] !== materialInputs[setting]) {
      let newValue = materialInputs[setting]
      handleSliderChange({name: setting, value: newValue || DEFAULT_MATERIAL_PROPERTIES[setting]})
    }
  }

  const handleEditMaterialSettings = (setting) => {
    let currValue = workingMeta[setting]
    const editableInputs = {...mapPatternChanges(setting, true), [setting]: currValue}
    updateMaterialInputs(editableInputs)
  }
  
  const materialSettingsHeaders = {
    alpha: (
      <h6>
        Opacity: {
          materialInputs.showAlphaInput ? (
            <input
              className="uv-settings-input"
              type="number"
              value={materialInputs.alpha}
              onChange={e => updateMaterialInputs({alpha: e.target.value})}
              onKeyDown={handleKeyDown}
              onBlur={() => handleBlur('alpha')}
              autoFocus
            />
          ) : <strong className="pattern-value">{workingMeta.alpha}<span onClick={() => handleEditMaterialSettings('alpha')} className="icon-edit"></span></strong>
        }
      </h6>
    ),
    metallic: (
      <h6>
        Metallic: {
          materialInputs.showMetallicInput ? (
            <input
              className="uv-settings-input"
              type="number"
              value={materialInputs.metallic}
              onChange={e => updateMaterialInputs({metallic: e.target.value})}
              onKeyDown={handleKeyDown}
              onBlur={() => handleBlur('metallic')}
              autoFocus
            />
          ) : <strong className="pattern-value">{workingMeta.metallic}<span onClick={() => handleEditMaterialSettings('metallic')} className="icon-edit"></span></strong>
        }
      </h6>
    ),
    roughness: (
      <h6>
        Roughness: {
          materialInputs.showRoughnessInput ? (
            <input
              className="uv-settings-input"
              type="number"
              value={materialInputs.roughness}
              onChange={e => updateMaterialInputs({roughness: e.target.value})}
              onKeyDown={handleKeyDown}
              onBlur={() => handleBlur('roughness')}
              autoFocus
            />
          ) : <strong className="pattern-value">{workingMeta.roughness}<span onClick={() => handleEditMaterialSettings('roughness')} className="icon-edit"></span></strong>
        }
      </h6>
    ),
    normalIntensity: (
      <h6>
        Normal Intensity: {
          materialInputs.showNormalIntensityInput ? (
            <input
              className="uv-settings-input"
              type="number"
              value={materialInputs.normalIntensity}
              onChange={e => updateMaterialInputs({normalIntensity: e.target.value})}
              onKeyDown={handleKeyDown}
              onBlur={() => handleBlur('normalIntensity')}
              autoFocus
            />
          ) : <strong className="pattern-value">{workingMeta.normalIntensity}<span onClick={() => handleEditMaterialSettings('normalIntensity')} className="icon-edit"></span></strong>
        }
      </h6>
    ),
    refractionIntensity: (
      <h6>
        Refraction Intensity: {
          materialInputs.showRefractionIntensityInput ? (
            <input
              className="uv-settings-input"
              type="number"
              value={materialInputs.refractionIntensity}
              onChange={e => updateMaterialInputs({refractionIntensity: e.target.value})}
              onKeyDown={handleKeyDown}
              onBlur={() => handleBlur('refractionIntensity')}
              autoFocus
            />
          ) : <strong className="pattern-value">{workingMeta.refractionIntensity}<span onClick={() => handleEditMaterialSettings('refractionIntensity')} className="icon-edit"></span></strong>
        }
      </h6>
    ),
    indexOfRefraction: (
      <h6>
        Index of Refraction: {
          materialInputs.showIndexOfRefractionInput ? (
            <input
              className="uv-settings-input"
              type="number"
              value={materialInputs.indexOfRefraction}
              onChange={e => updateMaterialInputs({indexOfRefraction: e.target.value})}
              onKeyDown={handleKeyDown}
              onBlur={() => handleBlur('indexOfRefraction')}
              autoFocus
            />
          ) : <strong className="pattern-value">{workingMeta.indexOfRefraction}<span onClick={() => handleEditMaterialSettings('indexOfRefraction')} className="icon-edit"></span></strong>
        }
      </h6>
    )
  }

  if (!workingMeta) {
    return null
  } 

  return (
    <>
      {/* {props.includeName && (
        <div className="threedy-lab-text-input material-adjuster-input">
          <h4>Name your <strong className="selected-material-name">{props.selectedPresetName || 'material'}</strong></h4>
          <input type="text" autoFocus name="name" placeholder="New Material" value={workingMeta.name ? workingMeta.name : ''} onKeyDown={e => handleKeyDown(e)} onChange={handleNameChange} />
        </div>
      )} */}
      <div className="material-adjuster-settings">
        <div className="toggle-popup-setting">
          {materialSettingsHeaders.alpha}
          <input className="slider" type="range" min={SETTINGS_MIN_MAX.alpha.min} max={SETTINGS_MIN_MAX.alpha.max} step="1" name="alpha" value={workingMeta.alpha} onChange={(e) => handleSliderChange(e.target)} />
          <div className="slider-percentage">
            <span>{SETTINGS_MIN_MAX.alpha.min}%</span>
            <span>{SETTINGS_MIN_MAX.alpha.max}%</span>
          </div>
        </div>
        <div className="toggle-popup-setting">
          {materialSettingsHeaders.metallic}
          <input className="slider" type="range" min={SETTINGS_MIN_MAX.metallic.min} max={SETTINGS_MIN_MAX.metallic.max} step="1" name="metallic" value={workingMeta.metallic} onChange={(e) => handleSliderChange(e.target)} />
          <div className="slider-percentage">
            <span>{SETTINGS_MIN_MAX.metallic.min}%</span>
            <span>{SETTINGS_MIN_MAX.metallic.max}%</span>
          </div>
        </div>
        <div className="toggle-popup-setting">
        {materialSettingsHeaders.roughness}
          <input className="slider" type="range" min={SETTINGS_MIN_MAX.roughness.min} max={SETTINGS_MIN_MAX.roughness.max} step="1" name="roughness" value={workingMeta.roughness} onChange={(e) => handleSliderChange(e.target)} />
          <div className="slider-percentage">
            <span>{SETTINGS_MIN_MAX.roughness.min}%</span>
            <span>{SETTINGS_MIN_MAX.roughness.max}%</span>
          </div>
        </div>
        <div className="toggle-popup-setting">
          {materialSettingsHeaders.normalIntensity}
          <input className="slider" type="range" min={SETTINGS_MIN_MAX.normalIntensity.min} max={SETTINGS_MIN_MAX.normalIntensity.max} step="1" name="normalIntensity" value={workingMeta.normalIntensity} onChange={(e) => handleSliderChange(e.target)} />
          <div className="slider-percentage">
            <span>{SETTINGS_MIN_MAX.normalIntensity.min}%</span>
            <span>{SETTINGS_MIN_MAX.normalIntensity.max}%</span>
          </div>
        </div>
        <div className="toggle-popup-setting">
          {materialSettingsHeaders.refractionIntensity}
          <input className="slider" type="range" min={SETTINGS_MIN_MAX.refractionIntensity.min} max={SETTINGS_MIN_MAX.refractionIntensity.max} step="1" name="refractionIntensity" value={workingMeta.refractionIntensity} onChange={(e) => handleSliderChange(e.target)} />
          <div className="slider-percentage">
            <span>{SETTINGS_MIN_MAX.refractionIntensity.min}%</span>
            <span>{SETTINGS_MIN_MAX.refractionIntensity.max}%</span>
          </div>
        </div>
        <div className="toggle-popup-setting">
          {materialSettingsHeaders.indexOfRefraction}
          <input className="slider" type="range" min={SETTINGS_MIN_MAX.indexOfRefraction.min} max={SETTINGS_MIN_MAX.indexOfRefraction.max} step="1" name="indexOfRefraction" value={workingMeta.indexOfRefraction} onChange={(e) => handleSliderChange(e.target)} />
          <div className="slider-percentage">
            <span>{SETTINGS_MIN_MAX.indexOfRefraction.min}%</span>
            <span>{SETTINGS_MIN_MAX.indexOfRefraction.max}%</span>
          </div>
        </div>
      </div>
    </>
  )
}

export default MaterialAdjusterComponent