import { useState, useEffect, useRef } from "react";
import "./list-ui.component.css";
import DropdownLinksComponent from "../dropdown-links/dropdown-links.component";

const DEFAULT_FILTER = { name: 'All Files', key: '', value: 'all' }
const DEFAULT_SORT = { name: 'Sort', key: '', value: 'asc' }
const UI_TYPES = {
    table: 'table',
    grid: 'grid',
}

const ListUIComponent = (props) => {
    const { list, titleKey, listFilters, listSortBy, listItem: ListItem, tableHeaders, listUIType } = props
    const listItemType = ListItem && ( typeof ListItem === 'function' ? 'component' : 'string' ) 
    const [localList, setLocalList] = useState([])
    const [filterBy, setFilterBy] = useState(DEFAULT_FILTER)
    const [sortBy, setSortBy] = useState(DEFAULT_SORT)
    const [listType, setListType] = useState(UI_TYPES.grid)
    const filterListRef = useRef(null)
    const sortListRef = useRef(null)

    useEffect(() => {
      setLocalList(list)
    }, [list])

    useEffect(() => {
        if (listUIType === UI_TYPES.table && tableHeaders) {
            setListType(listUIType)
        }
    }, [listUIType])

    const handleFilterBy = (filterData) => {
        if (filterData.value === filterBy.value) { return }
        setFilterBy(filterData)
        updateList({filter: filterData, sort: sortBy})
    }

    const handleSortBy = (sortData) => {
        if (sortData.name === sortBy.name && sortData.value === sortBy.value) { return }
        setSortBy(sortData)
        updateList({filter: filterBy, sort: sortData})
    }

    const toggleDropdown = (type) => {
        switch (type) {
            case 'filter':
                filterListRef.current.classList.toggle('list-ops-active')
                break

            case 'filter_onBlur':
                filterListRef.current.classList.remove('list-ops-active')
                break
                
            case 'sort':
                sortListRef.current.classList.toggle('list-ops-active')
                break
                    
            case 'sort_onBlur':
                sortListRef.current.classList.remove('list-ops-active')
                break

            default:
                break
        }
    }

    const updateList = (updateData) => {
        let currentList = [...list]

        if (updateData.filter.value !== DEFAULT_FILTER.value) {
            currentList = currentList.filter(item => item[updateData.filter.key] === updateData.filter.value)
        }

        if (updateData.sort.name !== DEFAULT_SORT.name) {
            const valueType = updateData.sort.type || 'string'
            currentList.sort((a,b) => {
                switch(valueType.toLowerCase()) {
                    case 'number':
                        return a[updateData.sort.key] - b[updateData.sort.key]

                    case 'date':
                        // NOTE: Today's date is greater thean yesterday's date
                        // -1 --> [a,b] and 1 --> [b,a]
                        const dateA = new Date(a[updateData.sort.key])
                        const dateB = new Date(b[updateData.sort.key])
                        if (dateA < dateB) {
                            return updateData.sort.value === DEFAULT_SORT.value ? -1 : 1
                        }

                        if (dateA > dateB) {
                            return updateData.sort.value === DEFAULT_SORT.value ? 1 : -1
                        }
                    
                        return 0

                    default:
                        const nameA = a[updateData.sort.key].toLowerCase()
                        const nameB = b[updateData.sort.key].toLowerCase()
                        if (nameA < nameB) {
                            return updateData.sort.value === DEFAULT_SORT.value ? -1 : 1
                        }

                        if (nameA > nameB) {
                            return updateData.sort.value === DEFAULT_SORT.value ? 1 : -1
                        }
                    
                        return 0
                }
            })
        }

        setLocalList(currentList)
    }
    
    const renderList = () => {
        let listItems = null
        const listContentWrapperClass = 'toggle-content-blocks unlimited-height'
        const listItemWrapperClass = tableHeaders && listType === UI_TYPES.table ? 'list-row-wrapper' : 'list-item-wrapper'
        switch (listItemType) {
            case 'component':
                listItems = localList.map((item, index) => <div key={index} className={listItemWrapperClass}><ListItem {...item} /></div>)
                break
            case 'string':
                listItems = localList.map((_, index) => <div key={index} className={listItemWrapperClass}>{ListItem}</div>)
                break
            default:
                listItems = localList.map((item, index) => <div key={index} className={listItemWrapperClass}>{item[titleKey]}</div>)
                break
        }

        if (tableHeaders && listType === UI_TYPES.table) {
            const mappedHeaders = tableHeaders.map((item, index) => {
                const cellCustomWidth = item.width ? {width: item.width} : null
                return <div key={index} className="table-cell" style={cellCustomWidth}>{item.name}</div>
            })
            return (
                <div className="table-ui">
                    <div className="table-header">{mappedHeaders}</div>
                    {listItems}
                </div>
            )
        }

        return (
            <div className={listContentWrapperClass}>
                {listItems}
            </div>
        )
    }

    const isFiltersAvailable = listFilters && listFilters.length > 0
    const isSortByAvailable = listSortBy && listSortBy.length > 0
    const filtersList = isFiltersAvailable ? [DEFAULT_FILTER, ...listFilters] : [DEFAULT_FILTER]
    const sortList = isSortByAvailable ? [DEFAULT_SORT, ...listSortBy] : [DEFAULT_SORT]
    const sortArrow = sortBy.name !== DEFAULT_SORT.name ? (sortBy.value === DEFAULT_SORT.value ? <span className="icon-arrow_drop_up"></span> : <span className="icon-arrow_drop_down"></span>) : null
    const filterListOptions = filtersList.map((lfItem, index) => {
        return { id: index, label: <span className="ellipsis-container">{lfItem.name}</span>, onSelect: () => handleFilterBy(lfItem) }
    })
    const filterByComponent = (
        <div className="list-ops">
            <div className="list-ops-content">
                <h6 title={filterBy.name}><span className="ellipsis-container">{filterBy.name}</span></h6>
                <span className="icon-expand_more"></span>
            </div>
        </div>
    )
    const sortListOptions = sortList.map((lfItem, index) => {
        const itemArrow = lfItem.name !== DEFAULT_SORT.name ? (lfItem.value === DEFAULT_SORT.value ? <span className="icon-arrow_drop_up"></span> : <span className="icon-arrow_drop_down"></span>) : null
        return { id: index, label: <><span className="ellipsis-container">{lfItem.name}</span>&nbsp;{itemArrow}</>, onSelect: () => handleSortBy(lfItem) }

    })
    const sortByComponent = (
        <div class="list-ops">
            <div className="list-ops-content">
                <h6 title={sortBy.name}><span className="ellipsis-container">{sortBy.name}</span>&nbsp;{sortArrow}</h6>
                <span className="icon-expand_more"></span>
            </div>
        </div>
    )

    return (
        <div className="list-ui-wrapper">
            <div className="list-header">
                <div className="list-header-left-content"></div>
                <div className="list-header-right-content">
                    <DropdownLinksComponent element={filterByComponent} items={filterListOptions} fullWidth />
                    <DropdownLinksComponent element={sortByComponent} items={sortListOptions} position="right" />
                </div>
            </div>
            {renderList()}
        </div>
    );
};

export default ListUIComponent;
