import { useCallback, useEffect, useRef, useState } from "react"
import moment from "moment"
import SpinnerComponent from '../../components/spinner/spinner.component'
import { useLazyGetExportsListQuery, useRetryExportMutation } from '../../redux/api.redux.slice'
import { createDownloadLink } from "../../utils/ui.util"
import './notifications.page.css'
import { useDispatch, useSelector } from "react-redux"
import { setUnreadExport } from "../../redux/ui.redux.slice"

const NotificationsPage = () => {
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    const [exports, setExports] = useState([])
    const hasUnreadExport = useSelector((state) => state.ui.hasUnreadExport)
    const dispatch = useDispatch()
    const [fetchExports] = useLazyGetExportsListQuery()
    const [retryExportQuery] = useRetryExportMutation()
    const shouldPoll = useRef(true)
    const timerRef = useRef()

    const getExports = useCallback(async () => {
        try {
            const res = await fetchExports().unwrap()
            setExports(res)
            setLoading(false)
        }  
        catch (e) {
            console.error("Error fetching exports: ", e)
            setError(true)
            setLoading(false)
        }
    }, [fetchExports])

    const handleTimer = useCallback(() => {
        getExports()

        if (shouldPoll.current) {
            timerRef.current = setTimeout(handleTimer, 10000)
        }

    }, [getExports])

    // Fetch data on load & set polling
    useEffect(() => {

        handleTimer()

        return () => {
            if (timerRef.current) {
                clearTimeout(timerRef.current)
                timerRef.current = null
            }
        }

    }, [handleTimer])

    useEffect(() => {
        const noExportsList = exports && exports.length < 1
        // If atleast one of the exports have a status code of 100, it will keep on polling, else will stop
        const allExportsCompleted = exports && exports.length > 0 && !exports.some(exportItem => exportItem.status_code === 100)
        if (noExportsList || allExportsCompleted) {
            shouldPoll.current = false
        }
        else {
            shouldPoll.current = true
        }
    }, [exports])

    // On unload, mark all exports as "viewed"
    useEffect(() => {
        return () => dispatch(setUnreadExport(false))
    }, [dispatch])  

    const hasTimedOut = (exportDetails) => {
        const createdEpoch = parseInt(exportDetails.created_ts)
        const now = moment()
        const diff = now.diff(moment(createdEpoch), "minutes")
        return diff >= 15
    }

    const handleRetry = async (exportId) => {
        setLoading(true)
        try {
            const res = await retryExportQuery(exportId).unwrap()
            setExports([...exports].map((e) => {
                if (e.id === exportId) {
                    return {...e, ...res.data}
                }

                return e
            }).sort((a, b) => a.created_ts > b.created_ts ? -1 : 1))

            setLoading(false)
        }
        catch (e) {
            console.error("Error retrying export: ", e)
            setLoading(false)
        }
    }

    const downloadCol = (exportDetails) => {
        let label = "Download"
        let disableBtn = true
        let didSucceed = false
        let className = "primary-btn"
        const didTimeout = hasTimedOut(exportDetails)
        
        if (exportDetails.status_code === 200) {
            label = "Download again"
            disableBtn = false
            didSucceed = true
        }
        else if ((exportDetails.status_code === 100 && didTimeout) || exportDetails.status_code === 500) {
            // Try again
            label = "Try again"
            disableBtn = false
            className = "outline-btn"
        }

        const handleButtonClick = () => {
            if (!disableBtn) {
                if (didSucceed) {
                    createDownloadLink(exportDetails.src, exportDetails.name)
                }
                else {
                    handleRetry(exportDetails.id)
                }
            }
        }

        return (
            <button className={className} disabled={disableBtn} onClick={handleButtonClick}>{label}</button>
        )
    }

    const getStatus = (exportDetails) => {
        let label = exportDetails.status
        let badgeClass = 'admin-info-tag'
        const didTimeout = hasTimedOut(exportDetails)

        if (exportDetails.status_code === 200) {
            badgeClass += ' green'
        }
        else if ([100,200].indexOf(exportDetails.status_code) < 0 || didTimeout) {
            badgeClass += ' red'
            label = "Failed to export"
        }

        return <span className={badgeClass}>{label}</span>
    }

    const getLocalTime = (created_ts) => {
        const epoch = parseInt(created_ts)
        return moment(epoch).fromNow()
    }

    return (
        <div className="threedy-lab-page-content notifications-page">
            <div className="threedy-lab-page-header">
                <h2>Notifications</h2>
            </div>
            <h4>Exports</h4>
            {loading ? (
                <div className="threedy-lab-spinner-wrapper">
                    <SpinnerComponent inline />
                </div>
            ) : (
                <>
                    {error && <h5 className="error">Error while fetching exports. Please, try again.</h5>}
                    {!error && exports && exports.length < 1 && <h5 className="no-results mt-20">You haven't created any exports yet</h5>}
                    <ul className="toggle-admin-list">
                        {
                            exports && exports.map((exportDetails) => {
                                return (
                                    <li key={exportDetails.id}>
                                        <div className="col"><h4>{exportDetails.name}</h4></div>
                                        <div className="col">{getStatus(exportDetails)}</div>
                                        <div className="col">{downloadCol(exportDetails)}</div>
                                        <div className="col"><h5>{getLocalTime(exportDetails.created_ts)}{hasUnreadExport === exportDetails.id && <span className="has-notifications"></span>}</h5></div>
                                    </li>
                                )
                            })
                        }
                    </ul>
                </>
            )}
        </div>
    )
}

export default NotificationsPage