import { useState } from "react"
import "./dropdown.component.css"

const DropdownComponent = (props) => {
  const [expanded, setExpanded] = useState(false)

  const handleOpen = (e) => {
    if (props.stopPropagation && e) {
      e.stopPropagation()
    }

    setExpanded(true)
  }

  const handleClose = (e) => {
    e.stopPropagation()
    setExpanded(false)
  }

  const handleSelectOption = (e, value) => {
    e.stopPropagation()

    if (props.onChange) {
      handleClose(e)
      props.onChange(e, props.id, value)
    }
  }

  return (
    <div className={expanded ? "toggle-dropdown-component expanded" : (props.disabled ? "toggle-dropdown-component disabled" :  "toggle-dropdown-component")} onClick={(e) => handleOpen(e)} tabIndex={0} onBlur={(e) => handleClose(e)}>
      <div className="toggle-dropdown-component-header">
        {props.label && <h6 className="toggle-dropdown-component-header-label">{props.label}</h6>}
        <h6 className={!props.label ? "no-margin" : ""}>{props.value ? (props.valueLabel ? props.valueLabel : props.value) : <i>{!props.disablePlaceholderPrefix && "Please select a"} {props.placeholder ? props.placeholder : "value"}</i>} <span className="icon-expand_more"></span></h6>
      </div>
      {expanded && (
        <div className={!props.label ? "toggle-dropdown-component-expanded left" : "toggle-dropdown-component-expanded"}>
          <ul>
            {
              props.options.map((o) => {
                return (
                  <li key={o.id} onClick={(e) => handleSelectOption(e, o.id)} className={o.disabled ? "disabled" : ""}>
                    <h6>{o.label} {props.value === o.id && <span className="icon-check_small"></span>}</h6>
                  </li>
                )
              })
            }
          </ul>
        </div>
      )}
    </div>
  )
}

export default DropdownComponent