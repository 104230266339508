import { useCallback, useEffect, useRef, useState } from 'react'
import { CustomPicker } from 'react-color'
import { Saturation, Hue, EditableInput } from 'react-color/lib/components/common'
import { eyeDropper } from '../../assets'
import SimpleAlertModalComponent from '../alerts/simple-alert-modal.component'
import "./color-picker.component.css"

const ColorPickerComponent = (props) => {
  
  const {hex, rgb, onSetColor, onSelectColor} = props
  const debounceRef = useRef()
  const lastColorRef = useRef()
  const isSaturationColorRef = useRef()
  const [showBrowserNotSupportedModal, setShowBrowserNotSupportedModal] = useState(false)
 
  // Clear debounce
  useEffect(() => {
    return () => {
      if (debounceRef.current) {
        clearTimeout(debounceRef.current)
        debounceRef.current = null
      }
    }
  }, [])


  const openEyeDropper = async () => {
    if (window.EyeDropper === undefined) {
      setShowBrowserNotSupportedModal(true)
      return
    }

    const eyeDropper = new window.EyeDropper()
    try {
      const pickedColor = await eyeDropper.open()
      isSaturationColorRef.current = true
      onSetColor(pickedColor.sRGBHex)
    } 
    catch (error) {
      console.error(error)
      setShowBrowserNotSupportedModal(true)
    }
  }

  useEffect(() => {
    const {r, g, b} = rgb

    if (!lastColorRef.current || lastColorRef.current.r !== r || lastColorRef.current.g !== g || lastColorRef.current.b !== b) {
      lastColorRef.current = {r, g, b}
      
      // Debounce save
      if (debounceRef.current) {
        clearTimeout(debounceRef.current)
        debounceRef.current = null
      }

      debounceRef.current = setTimeout(() => {
        if (isSaturationColorRef.current) {
          onSelectColor({r,g,b})
        }
      }, 500)
    }
  }, [rgb, onSelectColor])

  const handleSaturationChange = useCallback((color) => {
    onSetColor(color)
    isSaturationColorRef.current = true
  }, [onSetColor])

  const handleHueChange = useCallback((color) => {
    onSetColor(color)
    isSaturationColorRef.current = false
  }, [onSetColor])

  const handleRGBChange = useCallback((val) => {
    let {r, g, b} = rgb
    if (val.R) {
      r = val.R
    }
    else if (val.G) {
      g = val.G
    }
    else {
      b = val.B
    }

    onSetColor({r, g, b})
    isSaturationColorRef.current = true
  }, [rgb, onSetColor])

  const handleHexChange = useCallback((val) => {
    onSetColor(val.Hex)
    isSaturationColorRef.current = true
  }, [onSetColor])

  return (
    <>
      <div className="toggle-color-picker">

        <div className="toggle-color-picker-saturation">
          <Saturation {...props} onChange={handleSaturationChange} />
        </div>
        <div className="toggle-color-picker-hue">
          <Hue {...props} onChange={handleHueChange} /> ``
        </div>
        <div className="toggle-color-picker-tools">
          <span className="toggle-color-picker-swatch" style={{backgroundColor: hex}} />
          <button className="toggle-color-dropper" onClick={openEyeDropper}>{eyeDropper()}</button>
          <EditableInput label="Hex" value={hex} onChange={handleHexChange} />
          <EditableInput label="R" value={rgb.r} onChange={handleRGBChange} />
          <EditableInput label="G" value={rgb.g} onChange={handleRGBChange} />
          <EditableInput label="B" value={rgb.b} onChange={handleRGBChange} />
        </div>
      </div>
      {showBrowserNotSupportedModal &&
        <SimpleAlertModalComponent
          title="Browser Unsupported"
          subTitle="This browser doesn't support this feature yet. Please use chrome based browsers like Brave, Google Chrome, Chromium, Microsoft Edge, Opera etc..."
          close={() => setShowBrowserNotSupportedModal(false)} />
      }
    </>
  )
}

export default CustomPicker(ColorPickerComponent)