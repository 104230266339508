import "./progress-bar-circular.css"

const ProgressBarCircular = (props) => {
    const { isLoading, percentage } = props
    return (
        <div className="wrap-circles">
            <div className={`circle per-${percentage}`}>
                <div className="inner">
                    {!isLoading && `${percentage}%`}
                    {isLoading && `...`}
                </div>
            </div>
        </div>
    )
}

export default ProgressBarCircular