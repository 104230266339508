import { useCallback, useEffect, useRef, useState } from "react"
import { Color3, HighlightLayer, PointerEventTypes } from "@babylonjs/core"
import useDecalDropper from "./useDecalDropper"
import { EVENTS } from "../constants"

const useDecalMover = (scene, decalsList, onUpdateDecal) => {

  const sceneRef = useRef()
  const meshIdsRef = useRef()
  const decalsListRef = useRef()
  const pickedMeshRef = useRef()
  const timerRef = useRef()
  const hlRef = useRef()
  const pointerObsRef = useRef()
  const [pickedDecalParams, setPickedDecalParams]  = useState(false)

  const beginDragging = useCallback(() => {
    if (!pickedMeshRef.current) { return}

    console.log("begin dragging: ", pickedMeshRef.current.id)

    // Create a new decal and attach to mouse
    const existingParams = decalsListRef.current.find((d) => d.id === pickedMeshRef.current.id)
    if (existingParams) {
      setPickedDecalParams({existingParams, existingMesh: pickedMeshRef.current})
      document.dispatchEvent(new CustomEvent(EVENTS.DISABLE_ZOOM, {detail: true}))
      pickedMeshRef.current.dispose()
      pickedMeshRef.current = null
    }
  }, [])

  const handleMouseDown = useCallback(() => {
    const pickInfo = sceneRef.current.pick(sceneRef.current.pointerX, sceneRef.current.pointerY, (m) => meshIdsRef.current.indexOf(m.id) > -1)
    if (pickInfo.hit && pickInfo.pickedMesh) {
      pickedMeshRef.current = pickInfo.pickedMesh
      hlRef.current.addMesh(pickInfo.pickedMesh, new Color3(0.2, 0.2, 1))
      timerRef.current = setTimeout(beginDragging, 500)
    }
  }, [beginDragging])

  const handleMouseUp = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current)
      timerRef.current = null
      pickedMeshRef.current = null
      hlRef.current.removeAllMeshes()
    }
  }, [])

  const handleUpdateDecal = useCallback((params) => {
    onUpdateDecal({...params, existingDecalId: pickedDecalParams.existingParams.id})
    setPickedDecalParams(false)

    // Update decal in scene with id "__decal" to the existing id
    const updatedMesh = sceneRef.current.meshes.find((m) => m.id === "__decal")
    if (updatedMesh) {
      updatedMesh.id = pickedDecalParams.existingParams.id
    }

  }, [onUpdateDecal, pickedDecalParams])

  const handlePointerEvent = useCallback((pointerInfo) => {
    switch (pointerInfo.type) {
      case PointerEventTypes.POINTERDOWN:
        handleMouseDown()
        break
      case PointerEventTypes.POINTERUP:
        handleMouseUp()
        break
      default:
        break
    }
  }, [handleMouseDown, handleMouseUp])

  useEffect(() => {
    if (!scene || !decalsList || decalsList.length < 1) { 
      return
    }

    // Add decal mesh ids
    const meshIds = decalsList.map((d) => d.id).sort()

    // Define scene etc
    console.log('useDecalMover')
    sceneRef.current = scene
    meshIdsRef.current = meshIds
    decalsListRef.current = decalsList
    hlRef.current = new HighlightLayer("hl_decal", scene, {
      isStroke: true, mainTextureRatio: 1.5,
      blurHorizontalSize: 1, blurVerticalSize: 1
    })

    hlRef.current.innerGlow = true
    hlRef.current.outerGlow = false

    // Add pointer handler
    pointerObsRef.current = sceneRef.current.onPointerObservable.add(handlePointerEvent)

    return () => {
      sceneRef.current.onPointerObservable.remove(pointerObsRef.current)
      sceneRef.current = null
      pointerObsRef.current = null
      hlRef.current.dispose()
      hlRef.current = null
      console.log('dispose useDecalMover')
    }

  }, [scene, decalsList, handlePointerEvent])

  useDecalDropper(sceneRef.current, pickedDecalParams, handleUpdateDecal)
}

export default useDecalMover