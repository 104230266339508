import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useGetExportsListQuery } from "../redux/api.redux.slice";
import { setObserveCompletion, setOngoingExportsEmpty } from "../redux/ui.redux.slice";
import { createDownloadLink } from "../utils/ui.util";

const useAutomaticExportDownload = () => {
    const dispatch = useDispatch()
    const [stopPolling, setStopPolling] = useState(0)
    const [skip, setSkip] = useState(true)
    const { data: exports } = useGetExportsListQuery(null, {pollingInterval: stopPolling, skip})
    const {observeExportCompletion, ongoingExports} = useSelector((state) => state.ui)

    // Start Observing Exports, when new Export is created
    useEffect(() => {
      if (ongoingExports.length > 0 && observeExportCompletion === false) {
        dispatch(setObserveCompletion(true))
      }
    }, [ongoingExports, dispatch, observeExportCompletion])

    // Observe Exports and download the currently created successfull ones
    useEffect(() => {
      if (observeExportCompletion && ongoingExports.length > 0 && exports && exports.length > 0) {
        const optimizedOngoingExports = new Set(ongoingExports)
        // Select the export which gets complete irrespective of the creation time
        const currentlyExported = exports.find(exportItem => optimizedOngoingExports.has(exportItem.id) && exportItem.status_code !== 100)
        if (currentlyExported) {
          // Downlod the export only if success
          if (currentlyExported.status_code === 200) {
            createDownloadLink(currentlyExported.src, currentlyExported.name)
          }
          dispatch(setOngoingExportsEmpty(currentlyExported.id)) // Remove once the export is completed(error/success)
        }
      }
    }, [exports, ongoingExports, dispatch, observeExportCompletion])
    
    // Stop Observing and polling when all of the exports is completed
    useEffect(() => {
      const noExportsList = exports && exports.length < 1
      // If atleast one of the exports have a status code of 100, it will keep on polling, else will stop
      const allExportsCompleted = exports && exports.length > 0 && !exports.some(exportItem => exportItem.status_code === 100)
      if (noExportsList || allExportsCompleted) {
          setStopPolling(0)
          setSkip(true)
          dispatch(setObserveCompletion(false))
      }
    }, [exports, dispatch])
    
    // Start Polling Exports for observation
    useEffect(() => {
        const observeExports = () => {
            setSkip(false)
            setStopPolling(10000)
        };

        if (observeExportCompletion) {
            observeExports()
        }
    }, [observeExportCompletion]);
};

export default useAutomaticExportDownload;
