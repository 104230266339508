import { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import { useBetween } from "use-between"
import { REFERENCE_PHOTO_STATUSES } from "../../../constants"
import { useEditProjectReferencePhotoMutation } from "../../../redux/api.redux.slice"

const useSharedRefPhotos = () => {
    const [status, setStatus] = useState(REFERENCE_PHOTO_STATUSES.INIT)
    const [loading, setLoading] = useState(false)
    const [photos, setPhotos] = useState([]);
    const addPhotos = (photoData) => {
        if (photos.find(photo => photo.name === photoData.name)) { return }
        setStatus(REFERENCE_PHOTO_STATUSES.ADDED_FROM_REF_COMP)
        setPhotos(prev => ([...prev, photoData]))
    }
    const deleteRefPhotos = (index) => {
        const updatedPhotos = photos.filter((p, i) => i !== index)
        setStatus(REFERENCE_PHOTO_STATUSES.DELETED)
        setPhotos([...updatedPhotos])
    }

    return {
        status,
        setStatus,
        photos,
        setPhotos,
        loading,
        setLoading,
        addPhotos,
        deleteRefPhotos,
    };
};

const useSharedState = () => useBetween(useSharedRefPhotos);

const useRefPhotos = () => {
    const {
        photos: refPhotos, setPhotos: setRefPhotos, loading: uploading, setLoading, status: refPhotosStatus, setStatus,
        addPhotos: addUploadedPhotos, deleteRefPhotos
    } = useSharedState()
    const selectedProject = useSelector((state) => state.ui.selectedProject)
    const [doUploadRequest] = useEditProjectReferencePhotoMutation()

    const addRefPhotos = async (imageSrc) => {
        if (refPhotos.find(photo => photo.name === imageSrc.name)) {
            return
        }
        setLoading(true)
        try {
            const formData = new FormData()
            formData.append("file", imageSrc)
            formData.append("position", JSON.stringify({x: window.innerWidth - 455, y: window.innerHeight - 285, width: 300, height: 240}))
            formData.append("scale", 1)
            const res = await doUploadRequest({projectId: selectedProject.id, body: formData}).unwrap()
            const { src, srcUrl } = res.data
            setStatus(REFERENCE_PHOTO_STATUSES.ADDED_FROM_MAT_TEX_COMP)
            setRefPhotos(prev => ([...prev, {name: imageSrc.name, src, srcUrl}]))
        } catch (e) {
            // Fail silently...
            console.error("Error saving reference photo preferences:", e)
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        if (refPhotosStatus === REFERENCE_PHOTO_STATUSES.INIT && selectedProject.reference_photo && selectedProject.reference_photo.photos && selectedProject.reference_photo.photos.length > 0) {
            setRefPhotos(selectedProject.reference_photo.photos)
        }
    }, [selectedProject, setRefPhotos, refPhotosStatus])


    return {refPhotos, uploading, addRefPhotos, refPhotosStatus, addUploadedPhotos, deleteRefPhotos}
}

export default useRefPhotos;
