import { useEffect, useState } from "react"
import { EVENTS } from "../../constants"
import MaterialAdjusterComponent from "../material-adjuster/material-adjuster.component"
import MaterialColorAdjusterComponent from "../material-color-adjuster/material-color-adjuster.component"
import TabBarComponent from "../tab-bar/tab-bar.component"
import UVSettingsComponent from "../uv-settings/uv-settings.component"
import "./material-settings.component.css"

const MaterialSettingsComponent = (props) => {
    const {projectMeta, part, tab: providedTab, onSaveMaterialSettings} = props
    const [tab, setTab] = useState("texture")
    const [tabItems, setTabItems] = useState([])

    useEffect(() => {

        let tabItemsArr = []
        let destTab = "texture"
        if (providedTab === "textures") {
            tabItemsArr = [{
                id: 'texture',
                label: 'Texture'
            }]

            if (!projectMeta || !projectMeta.preBaked) {
                tabItemsArr.push({
                    id: 'pattern',
                    label: 'Pattern'
                })
            }
        }
        else if (providedTab === "color") {
            tabItemsArr = [{
                id: 'color',
                label: 'Color'
            }]
            destTab = "color"
        }

        setTabItems(tabItemsArr)
        setTab(destTab)

    }, [projectMeta, providedTab])

    const handleTabChange = (tab) => {
        setTab(tab)
    }

    const resetSettings = () => {
        if (tab === 'texture') {
            document.dispatchEvent(new CustomEvent(EVENTS.RESET_MAT_SETTINGS))
        }
        else if (tab === 'color') {
            document.dispatchEvent(new CustomEvent(EVENTS.RESET_MAT_COLOR_SETTINGS))
        }
        else {
            document.dispatchEvent(new CustomEvent(EVENTS.RESET_UV_SETTINGS))
        }
    }

    return (
        tabItems.length < 1 ? null : (
            <div className="toggle-material-settings">
                {tabItems.length > 1 && <TabBarComponent simple items={tabItems} default={tab} onSelection={handleTabChange} />}
                <div className="toggle-material-settings-content">
                    {tab === 'texture' && (
                        <MaterialAdjusterComponent
                            onSave={onSaveMaterialSettings}
                            part={part}
                        />
                    )}
                    {tab === 'color' && (
                        <MaterialColorAdjusterComponent
                            part={part}
                            onSave={onSaveMaterialSettings}
                        />
                    )}
                    {tab === 'pattern' && (
                        <UVSettingsComponent
                            part={part}
                            onSave={onSaveMaterialSettings}
                        />
                    )}
                    <button className="toggle-outline-filled-btn" onClick={resetSettings}>Reset All Values</button>
                </div>
            </div>
        )
    )
}

export default MaterialSettingsComponent