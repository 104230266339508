import { Link } from "react-router-dom"

const GlobalSearchResultsComponent = (props) => {
    const { categoryName, searchResults, redirectToLink } = props

    return (
        searchResults.length > 0 ?
            <>
                <li key={`${categoryName}-heading`} className="category-heading">{categoryName}</li>
                {
                    searchResults.map(x =>
                        <li key={x.id} className="global-search-result">
                            <div className="result-text">
                                <div className={`result-name`}>
                                    {x.name}
                                </div>
                                <div className="result-breadcrumbs">
                                    {x.breadcrumbs.map((b, index) => {
                                        return (
                                            <>
                                                <span key={b} className="chunk">
                                                    {b}
                                                </span>
                                                {index < (x.breadcrumbs.length - 1) && <span className="chunk" key={`${b}-arrow-${index}`}>&#10140;</span>}
                                            </>
                                        )

                                    })}
                                </div>
                            </div>
                            <div className="result-link">
                                <Link className="link-btn" to={`${x.link}`} replace={true} onClick={(e) => redirectToLink(e)}>&#10132;</Link>
                            </div>
                        </li >)
                }
            </> : <></>
    )


}

export default GlobalSearchResultsComponent