import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from "react"
import { EVENTS } from "../../constants"
import EnterKeyInputComponent from "../enter-key-input/enter-key-input.component"
import { ReactSortable } from "react-sortablejs"
import ContextMenuComponent from "../context-menu/context-menu.component"
import "./part-viewer.component.css"
import SimpleAlertModalComponent from "../alerts/simple-alert-modal.component"
import { handleContinuousSelection } from "../../utils/ui.util"

const PartViewerComponent = forwardRef((props, ref) => {

  const { showCannotRemovePartModal, setShowCannotRemovePartModal, editing, onCreateGroup, onRequestUngroup, removePartFromGroup, onSetPartHover, invisible, hovering, onSelectPart, selections, getPartOrGroup, items, saveSortedParts, onVisibilityChange, shared, onUpdateItem, cmdKeyPressed, shiftKeyPressed, ctrlKeyPressed } = props
  const [workingItems, setWorkingItems] = useState([])
  const [renamingItem, setRenamingItem] = useState(false)
  const [expanded, setExpanded] = useState([])
  const [showMenu, setShowMenu] = useState(false)
  const cmdKeyPoints = useRef([])
  const partsElRefs = useRef({})
  const doesIncludeGroupRef = useRef()

  useEffect(() => {
    let doesInclude = false
    for (let i = 0; i < selections.length; i++) {
      const item = getPartOrGroup(selections[i], false)
      if (item.type === 'group') {
        doesInclude = true
        break
      }
    }

    doesIncludeGroupRef.current = doesInclude
  }, [selections, getPartOrGroup])

  useEffect(() => {
    setWorkingItems(items)
  }, [items])

  useImperativeHandle(ref, () => ({
    scrollToPartOrGroup(partId) {
      const el = partsElRefs.current[partId]
      if (el) {
        el.scrollIntoView({behavior: 'smooth', block: 'nearest', inline: 'start'})
        return
      }
      console.error("part not found: ", partId)
    }
  }))
  const onVisibilityClick = (p, isVisible) => {
    const item = { visible: !isVisible, parts: p.type === 'group' ? p.parts.map((p) => p.id) : [p.id] }
    onVisibilityChange(item)
  }

  const handleToggleExpandGroup = (itemId) => {
    if (expanded.indexOf(itemId) > -1) {
      setExpanded([...expanded].filter((e) => e !== itemId))
    }
    else {
      setExpanded([...expanded, itemId])
    }
  }

  const handleStartRenameItem = (itemId) => {
    if (shared) {
      return
    }

    setRenamingItem(itemId)
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, { detail: { enabled: false } }))
  }

  const handleFinalizeItemNameChange = (pid, value) => {
    setRenamingItem(false)
    onUpdateItem(pid, { name: value })
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, { detail: { enabled: true } }))
  }

  const handleSelectPart = (pid, pInd) => {
    let partID = pid
    const isRangeSet = cmdKeyPoints.current.length > 0
    // If nothing is selected
    if (!isRangeSet) {
      cmdKeyPoints.current.push(pInd)
    } 
    // If selected with Shift key
    else if (selections.length > 0 && shiftKeyPressed && isRangeSet) {
      cmdKeyPoints.current = handleContinuousSelection(cmdKeyPoints.current, pInd)
      partID = []
      for(let i=cmdKeyPoints.current[0]; i<=cmdKeyPoints.current[1]; i++) {
        partID.push(workingItems[i].id)
      }
    } 
    // Selecting a unselected part (This is bascially, internal range updations, so that next selections with Shift works as expected)
    else if (!selections.includes(pid) && isRangeSet) {
      const inRange = cmdKeyPoints.current.length === 2 && pInd >= cmdKeyPoints.current[0] && pInd <= cmdKeyPoints.current[1] ? true : false
      if ((cmdKeyPressed || ctrlKeyPressed) && !inRange) {
        cmdKeyPoints.current = handleContinuousSelection(cmdKeyPoints.current, pInd)
      }
      if (!(cmdKeyPressed || ctrlKeyPressed)) {
        cmdKeyPoints.current = [pInd]
      }
    }

    onSelectPart(partID, 'partViewer')
  }

  // List dragging to custom sort ends
  const handleShowMenu = (e, pid) => {
    e.stopPropagation()

    if (shared) { return }

    let menuItems = []
    const item = items.find((i) => i.id === pid)

    if (selections.length > 1 && !doesIncludeGroupRef.current) {
      menuItems = [{id: 'group', label: <><span className="icon-create_new_folder" /> Group</>, action: onCreateGroup}]
    }
    else if (selections.length <= 1) {
      menuItems = [{
        id: 'rename', label: <>Rename</>, action: () => handleStartRenameItem(pid)
      }]

      if (doesIncludeGroupRef.current || item.type === 'group') {
        menuItems.push({id: 'ungroup', label: <><span className="icon-folder" /> Ungroup</>, action: () => onRequestUngroup(item.id)})
      }
    }

    if (menuItems.length < 1) { return }

    setShowMenu({
      position: {
        left: e.clientX + 'px', top: e.clientY + 'px'
      },
      items: menuItems
    })
  }

  // List dragging to custom sort starts
  let updatedPartsAfterSort = null // Temporarily keep the data that is to be updated after sorting
  const handleSortingEnd = e => {
    if (e.oldIndex !== e.newIndex) {
      saveSortedParts(updatedPartsAfterSort)
    }
  }

  const handleSorting = (sortedParts) => {
    updatedPartsAfterSort = sortedParts.map(({ chosen, ...rest }) => ({ ...rest }))
  }

  const createPartRefs = (element, itemId) => {
    partsElRefs.current[itemId] = element
  }

  const handleItemRightClick = (e, itemId) => {
    e.preventDefault()
    e.stopPropagation()
    handleShowMenu(e, itemId)
  }

  return (
    <>
      <div className="toggle-part-viewer">
        {items.length > 0 ?
          <>
            {showMenu && <ContextMenuComponent items={showMenu.items} position={showMenu.position} onClose={() => setShowMenu(false)} />}
            <ReactSortable className="toggle-part-viewer-list" list={workingItems.map(x => ({ ...x, chosen: true }))} setList={handleSorting}
              animation={200}
              delayOnTouchStart={true}
              delay={2}
              handle=".sorting-handle"
              onEnd={handleSortingEnd}
            >
              {
                workingItems.map((p, pInd) => {
                  let classes = []
                  let isVisible = true
                  let partIds = p.type === 'group' ? p.parts.map((p) => p.id) : [p.id]
                  partIds.forEach((pid) => {
                    if (isVisible && invisible.indexOf(pid) > -1) {
                      isVisible = false
                    }
                  })

                  const isHovering = hovering === p.id
                  if (isHovering) {
                    classes.push('hovering')
                  }

                  if (selections.indexOf(p.id) > -1) {
                    classes.push('selected')
                  }

                  const isEditing = editing && editing.find((ep) => ep.id === p.id)

                  return (
                    <li className={classes.join(' ')} key={p.id} ref={(e) => createPartRefs(e, p.id)} onMouseEnter={() => onSetPartHover(false, p.id)} onMouseLeave={() => onSetPartHover(false, false)} onContextMenu={(e) => handleItemRightClick(e, p.id)}>
                      <div className="toggle-part-viewer-item">
                        <div className="toggle-part-viewer-name">
                          {renamingItem === p.id ? (
                            <EnterKeyInputComponent className="border-transparent" value={p.name} placeholder="Name..." onValueChange={(val) => handleFinalizeItemNameChange(p.id, val)} />
                          ) :
                            <>
                              {p.type === 'group' ? (
                                <>
                                  <button onClick={() => handleToggleExpandGroup(p.id)}><span className={expanded.indexOf(p.id) > -1 ? "icon-expand_less" : "icon-expand_more"} /></button>
                                  <button onDoubleClick={() => handleStartRenameItem(p.id)} onClick={() => handleSelectPart(p.id, pInd)}><span className="icon-folder" /> {p.name}</button>
                                </>
                              ) : (
                                <>
                                  {!shared && <button className="sorting-handle"><span className="icon-menu" /></button>}
                                  <button onDoubleClick={() => handleStartRenameItem(p.id)} onClick={() => handleSelectPart(p.id, pInd)}>{p.name}</button>
                                </>
                              )}
                            </>
                          }
                        </div>
                        <div className="toggle-part-viewer-actions">
                          {isEditing && <span className="icon-edit"></span> }
                          <button onClick={() => onVisibilityClick(p, isVisible)} className="visibility-btn">
                            <span className={!isVisible ? "icon-visibility_off" : "icon-visibility"}></span>
                          </button>
                          {!shared && (
                            <button onClick={(e) => handleShowMenu(e, p.id)}>
                              <span className="icon-more_vert" />
                            </button>
                          )}
                        </div>
                      </div>
                        {expanded.indexOf(p.id) > -1 && p.parts && p.parts.length > 0 && (
                          <ul>
                            {p.parts.map((subPart) => {
                              return (
                                <li key={subPart.id}>
                                  <div className="toggle-part-viewer-item subitem">
                                    <div className="toggle-part-viewer-name">
                                      {subPart.name}
                                    </div>
                                    <div className="toggle-part-viewer-actions">
                                      <button onClick={() => removePartFromGroup(p.id, subPart.id)}><span className="icon-remove"></span></button>
                                    </div>
                                  </div>
                                </li>
                              )
                            })}
                          </ul>
                        )}
                    </li>
                  )
                })
              }
            </ReactSortable>
          </> : <h6 className="toggle-no-items">No parts just yet...</h6>
        }
      </div>
      {showCannotRemovePartModal &&
        <SimpleAlertModalComponent
          title="Not Allowed"
          subTitle="All parts in a group cannot be deleted. Ungroup this to move this part"
          close={() => setShowCannotRemovePartModal(false)} />}
    </>
  )
})

export default PartViewerComponent