import { useEffect, useRef, useState } from "react"
import ColorPickerComponent from "../../../../../components/color-picker/color-picker.component"

const ElementColorPane = (props) => {

  const {appliedMaterial, onColorTransfer, onClearQueue} = props
  const [color, setColor] = useState(appliedMaterial && appliedMaterial.meta && appliedMaterial.meta.color_rgb ? appliedMaterial.meta.color_rgb : "#333")
  const hasPendingColorTransferRef = useRef()

  const handleOnSelectColor = (color) => {
    hasPendingColorTransferRef.current = true
    onColorTransfer(color)
  }

  // Clear queue on unload
  useEffect(() => {
    return () => {
      if (hasPendingColorTransferRef.current) {
        hasPendingColorTransferRef.current = null
        onClearQueue()
      }
    }
  }, [onClearQueue])

  return (
    <>
    <div className="toggle-element-configuration-section">
      <h6>Apply Color</h6>
      <ColorPickerComponent
        showSwatch
        color={color}
        onSetColor={setColor}
        onSelectColor={handleOnSelectColor}
      />
    </div>
    </>
  )
}

export default ElementColorPane