import { useEffect, useState, useRef, useCallback } from "react"
import { useDispatch } from "react-redux"
import { APP_RESOURCES_URL, EVENTS, MATERIAL_IMAGE_BUILDER_CATEGORIES } from "../../constants"
import { setToast } from "../../redux/ui.redux.slice"
import { loadImageToCanvas, resizeIfLarger, photonImage2Canvas, canvastoCWHBuffer, generateMap, setTexture, createNewMaterial } from "../../utils/ui.util"
import * as photon from "aritize-photon-rs"
import { useLazyGetMaterialModelsByIdQuery } from "../../redux/api.redux.slice"
import SpinnerComponent from "../spinner/spinner.component"
import ImageZoomComponent from "../image-zoom/image-zoom.component"
import "./material-image-create.component.css"
import { upload } from "../../assets"
import FileUploadLimitMaximumModalComponent from "../alerts/file-upload-limit-maximum-modal.component"
import SupportedImageTypesModalComponent from "../alerts/supported-image-types-modal.component"
import withMaterialBuilderModal from "../material-builder/material-builder-modal/material-builder-modal.component"
import useRefPhotos from "../../pages/workspace/hooks/useRefPhotos"

const MaterialImageCreateComponent = (props) => {

  const dispatch = useDispatch()
  const [imageFile, setImageFile] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [selectedCategory, setSelectedCategory] = useState(false)
  const [selectedMaterial, setSelectedMaterial] = useState(false)
  const [matName, setMatName] = useState("")
  const [getMaterialModels] = useLazyGetMaterialModelsByIdQuery()
  const [step, setStep] = useState(1)
  const fileInputRef = useRef()
  const createdMatRef = useRef()
  const isFirstLoad = useRef(true)
  const [showUploadLimitMaxModal, setShowUploadLimitMaxModal] = useState(false)
  const [showSupportedImageModal, setShowSupportedImageModal] = useState(false)
  const { material, scene, onSwapMaterial, onSave } = props
  const { addRefPhotos } = useRefPhotos()

  const updateMaterialAlbedo = useCallback(async (image) => {

    // Update material
    const { texture } = await setTexture(scene, new Date().valueOf().toString(), ['albedoTexture'], image, true)
    material.albedoTexture = texture
    material.albedoTexture.level = 3
    material.albedoTexture.update(false)
  }, [material, scene])

  useEffect(() => {
    const doSetup = async () => {
      const orgCanvas = await loadImageToCanvas(props.image)

      // Convert the canvas to a Photon image
      const phImage = photon.open_image(orgCanvas, orgCanvas.getContext("2d"))

      // Resize the Photon image if one of the dimensions is larger than maxDim
      const maxSize = 1024
      const resizedImage = resizeIfLarger(phImage, orgCanvas.width, orgCanvas.height, maxSize)
      const { img, width, height } = resizedImage

      // We'll also resize to 512 for all of the other maps besides texture/color
      const reducedImageRes = resizeIfLarger(phImage, orgCanvas.width, orgCanvas.height, 512)
      const { img: reducedImage, width: reducedWidth, height: reducedHeight } = reducedImageRes
      setImageFile({resizedFile: img, resizedWidth: width, resizedHeight: height, reducedImage, reducedWidth,  reducedHeight, src: "provided", name: "provided", thumb: props.image})
      setStep(2)
      updateMaterialAlbedo(props.image)
    }

    if (props.image && isFirstLoad.current) {
      doSetup()
      isFirstLoad.current = false
    }

  }, [props.image, updateMaterialAlbedo])

  const handleFileChange = (e, droppedImage = null) => {
    const file = droppedImage ? droppedImage : e.target.files[0]
    const maxFileSize = 4 * 1024 * 1024  // 4mb max

    if (file.size > maxFileSize) {
      e.target.value = null
      setShowUploadLimitMaxModal(true)
      setImageFile(false)
      return
    }

    const reader = new FileReader()
    reader.onload = (e) => {
      const thumb = e.target.result
      setImageFile({src: file, name: file.name.replace(/[\W_.]+/g, "_"), thumb})
    }
    reader.readAsDataURL(file)
  }

  const handleConfirmImage = async () => {
    // Load image in canvas
    try {
      setIsLoading(true)
      const orgCanvas = await loadImageToCanvas(imageFile.thumb)

      // Convert the canvas to a Photon image
      const phImage = photon.open_image(orgCanvas, orgCanvas.getContext("2d"))

      // Resize the Photon image if one of the dimensions is larger than maxDim
      const maxSize = 1024
      const resizedImage = resizeIfLarger(phImage, orgCanvas.width, orgCanvas.height, maxSize)
      const { img, width, height } = resizedImage

      // We'll also resize to 512 for all of the other maps besides texture/color
      const reducedImageRes = resizeIfLarger(phImage, orgCanvas.width, orgCanvas.height, 512)
      const { img: reducedImage, width: reducedWidth, height: reducedHeight } = reducedImageRes
      setImageFile({...imageFile, resizedFile: img, reducedImage, reducedWidth, reducedHeight, resizedWidth: width, resizedHeight: height})
      addRefPhotos(imageFile.src)
      setStep(2)
      setIsLoading(false)
      updateMaterialAlbedo(imageFile.thumb)
    }
    catch (e) {
      setIsLoading(false)
      console.error("Error confirming image: ", e)
      dispatch(setToast({message: "Uh oh, we had an issue resizing that image. Please try again.", isError: true}))
    }
  }

  const handleCreateMaterial = async (m) => {
    try {

      setIsLoading(true)
      setStep(4)

      // Measure performance
      const t1 = performance.now()

      // Update runtime
      window.ort.env.wasm.proxy = true
      const referenceMaterial = {id: m.id, maps: []}
      const materialRes = await getMaterialModels(m.id).unwrap()
      referenceMaterial.category = materialRes.category
      const sessions = {}
      let inputData
      let colorTextureUri

      // Create session for each map
      for (let i = 0; i < m.maps.length; i++) {
        const map = m.maps[i]

        if (map !== "texture_stats") {
          sessions[map] = await createSession(materialRes.models[map])
        }
      }

      // Clone photon image
      const reducedClone = new photon.PhotonImage(imageFile.reducedImage.get_raw_pixels(), imageFile.reducedImage.get_width(), imageFile.reducedImage.get_height())
      const resizedClone = new photon.PhotonImage(imageFile.resizedFile.get_raw_pixels(), imageFile.resizedFile.get_width(), imageFile.resizedFile.get_height())

      // Fetch stats if they exist & create input
      if (m.maps.indexOf("texture_stats")) {
        const {mus, stds} = await fetchTextureStats(materialRes.models.texture_stats)
        referenceMaterial.maps.texture_stats = true

        // Do color transfer
        let ctImage = photon.color_transfer_stat2im(reducedClone, mus[0], mus[1], mus[2], stds[0], stds[1], stds[2], true)

        // Write the resulting Photon image to an off-screen canvas
        const ctCanvas = photonImage2Canvas(ctImage, imageFile.reducedWidth, imageFile.reducedHeight)

        // Convert the canvas to an ArrayBuffer that can be processed by ONNX.
        // This color-adjusted image is only used as the input to the ONNX model.
        inputData = canvastoCWHBuffer(ctCanvas)

        // Save the resized Photon image (and not the color-adjusted) as texture URI that
        // can be loaded as a Babylon texture
        const resizedCanvas = photonImage2Canvas(resizedClone, imageFile.resizedWidth, imageFile.resizedHeight)
        colorTextureUri = resizedCanvas.toDataURL()
      } 
      else {
        const reducedCanvas = photonImage2Canvas(reducedClone, imageFile.reducedWidth, imageFile.reducedHeight)
        const resizedCanvas = photonImage2Canvas(resizedClone, imageFile.resizedWidth, imageFile.resizedHeight)
        colorTextureUri = resizedCanvas.toDataURL()
        inputData = canvastoCWHBuffer(reducedCanvas)
      }

      // Generate what we need
      const promises = []
      if (sessions.normal) {
        promises.push(generateMap(inputData, sessions.normal, 'normal', true))
        referenceMaterial.maps.push('normal')
      }

      if (sessions.ao) {
        promises.push(generateMap(inputData, sessions.ao, 'ao'))
        referenceMaterial.maps.push('ao')
      }

      if (sessions.roughness) {
        promises.push(generateMap(inputData, sessions.roughness, 'roughness'))
        referenceMaterial.maps.push('roughness')
      }

      if (sessions.metalness) {
        promises.push(generateMap(inputData, sessions.metalness, 'metalness'))
        referenceMaterial.maps.push('metalness')
      }

      const matId = new Date().valueOf()
      const matDefinition = {
        id: matId,
        name: matId,
        maps: ['color', ...referenceMaterial.maps],
        map_urls: {
          1024: {
            color: {1024: colorTextureUri}
          }
        },
        resolutions: [1024]
      }

      const finalResults = await Promise.all(promises)
      finalResults.forEach((res) => {
        const keys = Object.keys(res)
        const key = keys[0]
        matDefinition.map_urls[1024][key] = {1024: res[key]} // We're storing as "1024" but they are actually 512 for performance reasons
      })

      console.log("material definition ready: ", matDefinition)
      
      const createdMat = await createNewMaterial(scene, {}, matDefinition, {})
      onSwapMaterial(createdMat)
      const t2 = performance.now()
      console.log("material generation took: ", (t2-t1) + " ms")
      createdMatRef.current = {raw: createdMat, ...matDefinition, referenceMaterial}
      setIsLoading(false)
      setStep(5)
    }
    catch (e) {
      setStep(3)
      setIsLoading(false)
      console.error("Error selecting material: ", e)
      dispatch(setToast({message: "Uh oh, we had an issue selecting that material, please try again.", isError: true}))
    }
  }

  const fetchTextureStats = (url) => {
    return new Promise(async(resolve, reject) => {
      try {
        const textResult = await fetch(url).then((r) => r.text())
        const lines = textResult.trim().split('\n')
    
        if (lines.length !== 2) {
          console.error('Texture stats file is corrupted!')
          return reject('Texture stats file is corrupted!')
        }
    
        const mus_str = lines[0].split(' ')
        const stds_str = lines[1].split(' ')
        const mus = [parseFloat(mus_str[0]) * 100.0 / 255.0, parseFloat(mus_str[1]) - 128.0, parseFloat(mus_str[2]) - 128.0]
        const stds = [parseFloat(stds_str[0]) * 100.0 / 255.0, parseFloat(stds_str[1]), parseFloat(stds_str[2])]
    
        resolve({
          mus: mus,
          stds: stds
        })
      }
      catch (e) {
        reject(e)
      }
    })
  }

  const createSession = (modelURL) => {
    return new Promise(async (resolve, reject) => {
      try {
        const session = await window.ort.InferenceSession.create(modelURL, {
          graphOptimizationLevel: 'all'
        })
        resolve(session)
      }
      catch (e) {
        reject(e)
      }
    })
  }

  const handlePreviousBtnClick = () => {
    if (step === 2 && props.image && props.onFirstBack) {
      props.onFirstBack()
      return
    }
    
    if (step === 1) {
      props.showMaterialSelection()
      return
    }
    if (step === 2) {
      setStep(1)
    }
    else if (step === 3) {
      setStep(2)
    }
    else if (step === 5) {
      setStep(3)
    }
  }

  const getNextBtnDisabledState = () => {
    if (step === 1) {
      return imageFile === false
    }
    else if (step === 2) {
      return selectedCategory === false
    }
    else if (step === 3) {
      return selectedMaterial === false
    }
    else if (step === 5) {
      return !matName
    }

    return true
  }

  const handleNextBtnClick = () => {
    if (getNextBtnDisabledState()) { return }

    if (step === 1) {
      handleConfirmImage()
    } 
    else if (step === 2) {
      setStep(3)
    }
    else if (step === 3) {
      handleCreateMaterial(selectedMaterial)
    }
    else if (step === 5) {
      const { raw, referenceMaterial } = createdMatRef.current
      onSave(raw, {name: matName}, imageFile.thumb, referenceMaterial)
    }
  }

  const handleSelectCategory = (c) => {
    setSelectedCategory(c)

    if (props.autonavigate) {
      setStep(3)
    }
  }

  const handleSelectMaterial = (m) => {
    setSelectedMaterial(m)

    if (props.autonavigate) {
      handleCreateMaterial(m)
    }
  }

  const handleFileDrop = (e) => {
    e.preventDefault()
    e.currentTarget.classList.remove("drop-section")
    const image = e.dataTransfer.files[0]
    const acceptedTypes = ['image/png', 'image/jpeg', 'image/jpg']
    if (acceptedTypes.includes(image.type)) {
      handleFileChange(false, image)
    } else {
      setShowSupportedImageModal(true)
      setImageFile(false)
    }
  }

  const handleDragOver = (e) => {
    e.preventDefault()
    e.currentTarget.classList.add("drop-section")
  }

  const handleDragLeave = (e) => {
    e.preventDefault()
    e.currentTarget.classList.remove("drop-section")
  }
  
  const imageUploadButton = (buttonLabel = 'Choose File') => (
    <div className="image-upload-container">
      <input style={{display: 'none'}} ref={fileInputRef} type="file" accept=".jpeg,.jpg,.png" onChange={handleFileChange} />
      <button onClick={() => fileInputRef.current.click()}>{buttonLabel}</button>
    </div>
  )

  const handleToggleKeys = (enabled) => {
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled}}))
  }

  return (
    <>
    <div className={isLoading ? "threedy-skeleton toggle-material-image-create" : "toggle-material-image-create"}>
      {props.hideUI && step < 2 && (
        <>
          <p>Loading texture...</p>
          <div className="threedy-lab-spinner-wrapper">
            <div className="threedy-lab-spinner-container inline"><SpinnerComponent inline /></div>
          </div>
        </>
      )}
      <div className="step-container">
        {step === 1 && !props.hideUI && (
          <>
            {
              !imageFile && (
                <div className="upload-image-section" onDragOver={handleDragOver} onDragLeave={handleDragLeave} onDrop={handleFileDrop}>
                  <span className="upload-icon">{upload}</span>
                  <p>Drag an image here or</p>
                  {imageUploadButton()}
                </div>
              )
            }
            {
              imageFile && imageFile.src && (
                <div className="uploaded-image-section">
                  <div className="preview-image pos-relative">
                    <img src={imageFile.thumb} alt="preview thumb"/>
                    <span style={{cursor: 'pointer'}} onClick={() => setImageFile(false)} className="icon-x"></span>
                  </div>
                  <div className="preview-image-details">
                    <p>{imageFile.name}</p>
                    {imageUploadButton('Choose a New File')}
                  </div>
                </div>
              )
            }
            <p>Please select a .jpg or .png file that is less than or equal to 4 MB in size</p>
          </>
        )}
        {step === 2 && (
          <>
            <p>What category is your texture?</p>
            <ul>
              {MATERIAL_IMAGE_BUILDER_CATEGORIES.map((c) => {
                return (
                  <li key={c.id} className={selectedCategory && selectedCategory.id === c.id ? "selected full" : "full"} onClick={() => handleSelectCategory(c)}>
                    <h5>{c.name}</h5>
                  </li>
                )
              })}
            </ul>
          </>
        )}
        {step === 3 && (
          <>
            <p>Select a <strong>{selectedCategory.name}</strong> finish</p>
            <ul>
              {
                selectedCategory.materials.map((m, index) => {
                  const key = m.id
                  let className
                  if (selectedMaterial && selectedMaterial.id === key) {
                    className = "selected"
                  }

                  const thumbnail = `${APP_RESOURCES_URL}material-thumbs/${key}/thumb.png`

                  return (
                    <li key={key} className={className} onClick={() => handleSelectMaterial(m)}>
                      <ImageZoomComponent initial={thumbnail} zoom={thumbnail} grayScaled />
                      <h5>{`Finish ${index+1}`}</h5>
                    </li>
                  )
                })
              }
            </ul>
          </>
        )}
        {step === 4 && (
          <>
            <p>Creating material...</p>
            <div className="threedy-lab-spinner-wrapper">
              <div className="threedy-lab-spinner-container inline"><SpinnerComponent inline /></div>
            </div>
          </>
        )}
        {step === 5 && (
          <>
            <div className="threedy-lab-text-input material-adjuster-input">
              <h4>Name your <strong className="selected-material-name">{selectedCategory.name || 'material'}</strong></h4>
              <input type="text" autoFocus name="name" placeholder="New Material" value={matName} onFocus={() => handleToggleKeys(false)} onBlur={() => handleToggleKeys(true)} onChange={(e) => setMatName(e.target.value)} />
            </div>
          </>
        )}
      </div>
      {
        !props.autonavigate && (
          <div className="material-color-create-footer">
            <button onClick={handlePreviousBtnClick} className="back-btn"><span className="icon-arrow-left"></span> Back</button>
            <button onClick={handleNextBtnClick} disabled={getNextBtnDisabledState()} className="next-btn">{step === 5 ? "Save" : "Next"}</button>
          </div>
        )
      }
    </div>
      {showUploadLimitMaxModal &&
        <FileUploadLimitMaximumModalComponent close={() => setShowUploadLimitMaxModal(false)} />}
      {showSupportedImageModal &&
        <SupportedImageTypesModalComponent close={() => setShowSupportedImageModal(false)} />}
    </>
  )
}

export default withMaterialBuilderModal(MaterialImageCreateComponent)

