import { materialBuilder } from "./assets"

const isLocal = window.location.hostname.indexOf('localhost') > -1
const testEnvironments = ['test.toggle3d.com', 'view-test.toggle3d.com', 'app-test.toggle3d.com', 'playground.toggle3d.com', 'launch.toggle3d.com']
const isTest = testEnvironments.indexOf(window.location.hostname) > -1

export const GOOGLE_AUTH_KEY = '316130025579-opev2jvie1i7p3fdlhg2gpcnjmehdrdc.apps.googleusercontent.com'

export const EVENTS = {
  TOGGLE_KEY_HANDLER: 'disableKeyHandler',
  REFRESH_SCENE: 'refreshScene',
  SHOW_DEBUG: 'showDebug',
  SCENE_LOADED: 'sceneLoaded',
  SCENE_CONFIGURED: 'sceneConfigured',
  PROJECT_THUMB_UPDATED: 'projectThumbUpdated',
  RESET_MAT_SETTINGS: 'resetMatSettings',
  RESET_MAT_COLOR_SETTINGS: 'resetMatColorSettings',
  RESET_UV_SETTINGS: 'resetUvSettings',
  HISTORY_UNDO: 'historyUndo',
  HISTORY_REDO: 'historyRedo',
  CLEAR_COLOR_TRANSFER_QUEUE: 'clearColorTransferQueue',
  START_EXPORT: 'startExport',
  EXPORT_STARTED: 'exportStarted',
  START_ARITIZE3D_EXPORT: 'startAritize3dExport',
  ARITIZE3D_EXPORT_STARTED: 'aritize3dExportStarted',
  SWITCH_RESOLUTION: 'switchResolution',
  DISABLE_ZOOM: 'disableZoom'
}

export const HISTORY_EVENT_TYPES = {
  PASTE: "paste",
  EDIT_MATERIAL: 'editMaterial',
  EDIT_MATERIAL_SETTINGS: 'editMaterialSettings',
  EDIT_UV_SETTINGS: 'editUvSettings',
  RENAME_PROJECT: 'renameProject'
}

export const PERSONAL_LIBRARY_CATEGORY = {key: 'personal', name: "My Materials"}
export const LAST_USED_CATEGORY = {key: 'last_used', name: "Last Used Materials"}

export const SCENE_IGNORED_NODES = ['DirectionalLight', 'BackgroundHelper', '__root__', 'hdrSkyBox']

export const AUTH0 = {
  CLIENT_ID: '4MoWZL83qHQD9nh39ITyfmuMW1Hj04Yy',
  DOMAIN: 'https://threedy.auth0.com',
  AUD: 'https://pipeline-api.aarwild.com'
}

export const IS_DEBUG_ENV = isTest || isLocal 

export const IS_PROD_ENV = window.location.hostname === "app.toggle3d.com"

export const HIDDEN_SIDEBAR_PATHS = ['integrations', 'editor']

export const APP_URL = isLocal  ? 'http://localhost:3000' : (isTest ? 'https://app-test.toggle3d.com' : 'https://app.toggle3d.com')

export const APP_VIEW_URL = isLocal  ? 'http://view.localhost:3000' : (isTest ? 'https://view-test.toggle3d.com' : 'https://view.toggle3d.com')

export const API_BASE_URL = isLocal ? 'http://localhost:8080/api/' : (isTest ? 'https://poly-api-test.nextechar.com/api/' : 'https://poly-api.nextechar.com/api/')

export const APP_RESOURCES_URL = 'https://public.toggle3d.com/'

const stripeTestPlans = [
  {
    name: "Free",
    type: 'free',
    id: "price_1Lg5ybH311pq3KMIrRSjJGxW",
    billing: "Always free",
    features: {
      numProjects: 3,
      numMaterials: 35,
      uploadSize: 20
    },
    space: 200, // in MB
  },
  {
    name: "Pro",
    type: 'pro',
    id: "price_1Lg64bH311pq3KMIbff9Dftx",
    billing: "Billed monthly ($29/month)",
    features: {
      numProjects: 100000000,
      numMaterials: 100000000,
      uploadSize: 75
    },
    space: 2048, // in MB
  }
]

const stripeProdPlans = [{
  name: "Free",
  type: 'free',
  id: "price_1LiEF3H311pq3KMId7dLdXu1",
  billing: "Always free",
  features: {
    numProjects: 3,
    numMaterials: 35,
    uploadSize: 20
  },
  space: 200, // in MB
},
{
  name: "Pro",
  type: 'pro',
  id: "price_1LiEFVH311pq3KMIclQcgiG9",
  billing: "Billed monthly ($29/month)",
  features: {
    numProjects: 100000000,
    numMaterials: 100000000,
    uploadSize: 75
  },
  space: 2048, // in MB
}]

export const STRIPE_PLANS = (isLocal || isTest) ? stripeTestPlans : stripeProdPlans

export const STRIPE_PK = (isLocal || isTest) ? "pk_test_51KnPzkH311pq3KMIMPa8diFym8O7X5FN00p9yqvTikY7hoKxDGWhwdiltGTQiPmh4GTgioRJ9tN8k3yxy45hl83t000WBKE7zL" : "pk_live_51KnPzkH311pq3KMIKZJNNasFyoDV8JqKqexpNVPOO9iAXgmRay4I6zIhwU6Xe4qvEcDWcpL7nEBi3I4NMBiuOrIJ00ucA9eluE"

export const DROP_DUPLICATE_PROPS = ['id', 'model_location', 'model_land_location', 'model_status_code', 'model_type', 'model_name', 'status', 'updated_ts', 'created_ts', 'public_link', 'shared', 'did_import_materials', 'published_config_id', 'published_ds', 'published_job_id', 'staging_config_id', 'staging_job_id', 'converter_task_id', 'land_location', 'model_meta_location', 'model_status', 'external_meta', 'external_related_jobs', 'materials']

export const DEFAULT_GLTF_MATERIAL = '__GLTFLoader._default'

export const DEFAULT_SPHERE_MESH_ID = 'sphere'

export const DEFAULT_SPHERE_MAT_ID = 'sphereDefaultMat'

export const DEFAULT_MATERIAL_PROPERTIES = {
  name: '',
  alpha: 100,
  metallic: 100,
  roughness: 100,
  directIntensity: 100,
  normalIntensity: 100,
  refractionIntensity: 100,
  indexOfRefraction: 100
}

export const DEFAULT_MATERIAL_COLOR_PROPERTIES = {
  contrast: 100,
  brightness: 100,
  saturation: 100,
  hue: 30,
  hueIntensity: 0
}

export const DEFAULT_UV_PROPERTIES = {
  uScale: 1,
  vScale: 1,
  uOffset: 0,
  vOffset: 0,
  rotation: 0
}

export const SETTINGS_MIN_MAX = {
  scale: {
    min: 0.1,
    max: 15,
    step: 0.01
  },
  offset: {
    min: -50,
    max: 50,
    step: 0.1
  },
  rotation: {
    min: -180,
    max: 180,
    step: 1
  },
  alpha: {
    min: 0,
    max: 100
  },
  metallic: {
    min: 0,
    max: 200
  },
  roughness: {
    min: 0,
    max: 200
  },
  normalIntensity: {
    min: 0,
    max: 200
  },
  brightness: {
    min: 0,
    max: 200
  },
  contrast: {
    min: 0,
    max: 200
  },
  saturation: {
    min: 0,
    max: 200
  },
  refractionIntensity: {
    min: 0,
    max: 100
  },
  indexOfRefraction: {
    min: 100,
    max: 300
  }
}

export const EXPORT_FILE_FORMATS = [
  {id: "glb", label: "glb"}, 
  {id: "gltf", label: "gltf"}, 
  {id: "fbx", label: "fbx"},
  {id: "obj", label: "obj"},
  {id: "dae", label: "dae"}
]

// todo: These should be stored in an API somewhere
export const MATERIAL_IMAGE_BUILDER_CATEGORIES = [
  {
    name: "Wood",
    id: "Wood",
    materials: [
      {
        id: "m_2aa301",
        maps: ['normal', 'roughness', 'texture_stats']
      },
      {
        id: "m_d4ffb3",
        maps: ['normal', 'roughness', 'ao', 'texture_stats']
      },
      {
        id: "m_f0e9b1",
        maps: ['normal', 'roughness', 'ao', 'texture_stats']
      },
      {
        id: "m_397c23",
        maps: ['normal', 'roughness', 'texture_stats']
      },
    ]
  },
  {
    name: "Metal",
    id: "Metal",
    materials: [
      {
        id: "m_49a497",
        maps: ['normal', 'roughness']
      },
      {
        id: "m_7f2cbf",
        maps: ['normal', 'roughness']
      },
      {
        id: "m_816764",
        maps: ['normal', 'roughness', 'metalness']
      },
      {
        id: "m_75bd6e",
        maps: ['normal', 'roughness', 'metalness', 'texture_stats']
      },
      {
        id: "m_058821",
        maps: ['normal', 'roughness', 'metalness', 'texture_stats']
      },
    ]
  },
  {
    name: "Plastic",
    id: "Plastic",
    materials: [
      {
        id: "m_c92a9f",
        maps: ['normal', 'roughness', 'texture_stats']
      },
    ]
  },
  {
    name: "Leather",
    id: "Leather",
    materials: [
      {
        id: "m_c71954",
        maps: ['normal', 'roughness', 'ao', 'texture_stats']
      },
      {
        id: "m_7902bb",
        maps: ['normal', 'roughness', 'ao', 'texture_stats']
      },
      {
        id: "m_383921",
        maps: ['normal', 'roughness', 'texture_stats']
      },
      {
        id: "m_d26b0f",
        maps: ['normal', 'roughness', 'texture_stats']
      },
    ]
  },
  {
    name: "Fabric",
    id: "Fabric",
    materials: [
      {
        id: "m_f03a35",
        maps: ['normal', 'roughness', 'texture_stats']
      },
      {
        id: "m_6ca2dc",
        maps: ['normal', 'roughness', 'texture_stats']
      },
      {
        id: "m_ea0d27",
        maps: ['normal', 'roughness', 'ao', 'texture_stats']
      },
      {
        id: "m_4885db",
        maps: ['normal', 'roughness', 'ao', 'texture_stats']
      },
      {
        id: "m_3e1465",
        maps: ['normal', 'roughness', 'texture_stats']
      },
      {
        id: "m_cc7f7a",
        maps: ['normal', 'roughness', 'texture_stats']
      },
      {
        id: "m_e9c3f5",
        maps: ['normal', 'roughness', 'texture_stats']
      },
    ]
  },
  {
    name: "Marble",
    id: "Marble",
    materials: [
      {
        id: "m_960bba",
        maps: ['normal', 'roughness', 'texture_stats']
      },
      {
        id: "m_3d1749",
        maps: ['normal', 'roughness', 'texture_stats']
      },
      {
        id: "m_c003f7",
        maps: ['normal', 'roughness', 'texture_stats']
      },
      {
        id: "m_b8a150",
        maps: ['normal', 'roughness', 'texture_stats']
      },
    ]
  },
]

// todo: These should be stored in an API somewhere
export const MATERIAL_PRESETS = [
  {
    name: "Glossy Plastic",
    id: "gp",
    props: {
      metallic: 0,
      roughness: 8,
      ior: 146,
      alpha: 100
    }
  }, {
    name: "Matte Plastic",
    id: "mp",
    props: {
      metallic: 0,
      roughness: 25,
      ior: 146,
      alpha: 100
    }
  },
  {
    name: "Metal",
    id: "me",
    props: {
      metallic: 100,
      roughness: 38,
      ior: 240,
      alpha: 100
    }
  },
  {
    name: "Clear Glass",
    id: "cg",
    props: {
      metallic: 80,
      roughness: 5,
      ior: 150,
      alpha: 20
    }
  },
  {
    name: "Tinted Glass",
    id: "tg",
    props: {
      metallic: 8,
      roughness: 5,
      ior: 150,
      alpha: 65
    }
  }
]

export const COOKIE_ACCEPTANCE_KEY = "__togl__ca"
export const PAUSE_DURATION = 800
export const MOBILE_WINDOW_WIDTH = 768
export const MOBILE_WINDOW_HEIGHT = 428

const getTourTitle = (currentStep, totalStep, title) => (
  <div className="tour-step-title-wrapper">
    <span>{title}</span>
    <span>Step {currentStep}/{totalStep}</span>
  </div>
)

const getTourContent = (content) => (
  <span className="tour-step-content-wrapper">{content}</span>
)

export const ALL_TOURS = ['TOUR1', 'TOUR3']
export const JOYRIDE_STEPS = {
  TOUR1: [
    {
      target: '.threedy-lab-sidebar-menu ul',
      title: getTourTitle(1,4,'Getting Started Tour'),
      content: getTourContent('The global navigation allows you to quickly access the right section from anywhere. This gives you immediate access to your projects, templates and more.'),
      disableBeacon: true,
      placement: 'right',
    },
    {
      target: '.toggle-empty-label',
      title: getTourTitle(2,4,'Getting Started Tour'),
      content: 'From the home page you can jump straight into texturing a 3D model, building a configurator, or creating model variations. These will be the start of your projects',
      placement: 'bottom',
    },
    {
      target: '.project-page-wrapper .threedy-tab-bar',
      title: getTourTitle(3,4,'Getting Started Tour'),
      content: "The Projects page is where all your folders, designs and uploads live. They're neatly organized into their own sections",
      placement: 'right',
    },
    {
      target: '.project-page-wrapper .projects-page-actions',
      title: getTourTitle(4,4,'Getting Started Tour'),
      content: 'From these buttons here you can create a new folder or import a 3D file to start organizing your designs',
      placement: 'right',
    }
  ],
  TOUR3: [
    {
      target: '.material-tour-selector',
      title: getTourTitle(1,3,'Creating a Material'),
      content: 'In this template you can create a new material from any of the three AI-powered creation methods here. Once you’ve selected a starting point, you will be taken through a series of steps to create your new material.',
      placement: 'left',
      disableBeacon: true,
    },
    {
      target: '.material-tour-selector.top-145',
      title: getTourTitle(2,3,'Creating a Material'),
      content: 'All of your newly saved materials will show up in the Created Materials panel. This will allow you to make multiple materials in series. Created materials will also save to the My Materials folder in the Material Library.',
      placement: 'left',
    },
    {
      target: '.material-tour-selector.top-200',
      title: getTourTitle(3,3,'Creating a Material'),
      content: 'Inside the Lighting panel you can preview how different types of light effect your Material. This will help to get the best representation of how the Material will look when applied to a model with Lighting.',
      placement: 'left',
    },
  ]
}
export const JOYRIDE_LOCALE = { back: 'Back', close: 'Close', last: 'Done', next: 'Next', open: 'Open the dialog', skip: 'Skip Tour' }
export const JOYRIDE_STYLE = {
  buttonNext: {
    backgroundColor: 'var(--primaryColor)',
    color: '#fff'
  },
  tooltipContent: {
    padding: '20px 0px',
    textAlign: 'left'
  },
  overlay: {
    zIndex: 3335
  }
}
export const JOYRIDE_TOOLTIP_STYLES = {
  styles: {
    floater: {
      zIndex: 3336
    }
  }
}

export const ZIP_FILE_RULE_SET = {
  dae: {
    must: [],
    optional: ["jpg", "png"],
    validateMustIfAssetsAvailable: false
  },
  fbx: {
    must: [],
    optional: ["jpg", "png"],
    validateMustIfAssetsAvailable: false
  },
  glb: {
    must: [],
    optional: [],
    validateMustIfAssetsAvailable: false
  },
  gltf: {
    must: ["bin"],
    optional: ["jpg", "png"],
    validateMustIfAssetsAvailable: false
  },
  stp: {
    must: [],
    optional: [],
    validateMustIfAssetsAvailable: false
  },
  step: {
    must: [],
    optional: [],
    validateMustIfAssetsAvailable: false
  },
  obj: {
    must: ["mtl"],
    optional: ["jpg", "png"],
    validateMustIfAssetsAvailable: true
  }
}

export const errorMessages = {
  INVALID_DIRECTORY: "Invalid sub directory found. Master file must be in root directory of zip.",
  MULTIPLE_MASTER_FILES: "Found more than 1 master file in the zip.",
  ZERO_MASTER_FILES: "No master files found",
  MUST_FILE_MISSING: "file missing in this zip",
  INVALID_FILES_FOUND: "Invalid files found in the zip"
}

export const T = {
  MATERIAL: 'material',
  CONFIGURATOR: 'configurator',
  _3D_MODEL: '3D model',
  VARIATIONS: 'variations',
  CLOSE_CONFIGURATOR: 'CLOSE_CONFIGURATOR',
  LIMIT_EXCEEDED: 'limit_exceeded',
  MOVE_TO_TOP: 'move_to_top',
  PRO: 'pro',
  FREE: 'free',
  STRIPE_THEME_LIGHT: 'stripe',
  STRIPE_THEME_DARK: 'night',
  GOOGLE: 'google',
  LOGIN: `login`,
  SIGNUP: `signup`,
}

export const REFERENCE_PHOTO_STATUSES = {
  INIT: 'INIT',
  ADDED_FROM_MAT_TEX_COMP: 'ADDED_FROM_MAT_TEX_COMP',
  ADDED_FROM_REF_COMP: 'ADDED_FROM_REF_COMP',
  DELETED: 'DELETED',
}

export const CONVERSION_ERRORS = [
  {
    code: 10,
    description: "Too many parts in the STEP file",
    message: "uh oh! We are sorry. It looks like there are too many parts in your STEP file. Please try another file",
  },
  {
    code: 20,
    description: "Empty file",
    message: "uh oh! It seems that your file is empty. Please try another file",
  },
  {
    code: 30,
    description: "Corrupt file",
    message: "uh oh! It seems the file you are trying to convert is corrupt. Please try another file",
  },
  {
    code: 40,
    description: "Aggregating STEP meshes failed",
    message: "“uh oh! We are sorry. We ran into an error aggregating STEP meshes. Please try another file",
  },
  {
    code: 200,
    description: "Unknown error",
    message: "Uh oh! Something went wrong. Please try another file",
  }
]

export const KLAVIYO_METRICS = {
  logged_in: 'Logged in',
  signup_wo_verification: 'Sign up without verification',
  signup_and_verified: 'Sign up and verified',
  signup_with_pro_verification: 'Sign up, verified and pro',
  welcome_email: 'Welcome email',
  model_uploaded: 'Uploaded a model',
  applied_material: 'Applied a material',
  shared_project: 'Shared a project',
  model_variation_created: 'Created a model variation',
  created_material: 'Created a material',
  created_project: 'Created a project',
  upgraded_to_pro: 'Upgraded to pro',
  downgraded_to_pro: 'Downgraded to pro',
}
export const MAX_PROJECT_NAME = 70 // configurator page
export const MAX_THUMBNAIL_PROJECT_NAME = 16 
export const DASHBOARD_TUTORIALS = [
  {
    id: 1,
    name: "uploading",
    title: "Uploading a File",
    subTitle: "Learn how to upload a CAD file or 3D model",
    videoLink: "help/uploading-file.mp4",
    leftImageURL: `${APP_RESOURCES_URL}help/1.1-upload.svg`,
    rightImageURL: `${APP_RESOURCES_URL}help/1.2-upload.svg`
  },
  {
    id: 11,
    name: "Navigation",
    title: "Scene Navigation",
    subTitle: "Learn how to navigate the scene",
    videoLink: "help/navigation.mp4",
    leftImageSvg: materialBuilder
  },
  {
    id: 4,
    name: "grouping",
    title: "Grouping Elements",
    subTitle: "Learn how to group elements on a model",
    videoLink: "help/grouping-parts.mp4",
    leftImageURL: `${APP_RESOURCES_URL}help/4.1-group.svg`,
    rightImageURL: `${APP_RESOURCES_URL}help/4.2-group.svg`
  },
  {
    id: 3,
    name: "renaming",
    title: "Selecting/Editing Elements",
    subTitle: "Learn how to select and rename elements on a model",
    videoLink: "help/select-rename.mp4",
    leftImageURL: `${APP_RESOURCES_URL}help/3.1-rename.svg`,
    rightImageURL: `${APP_RESOURCES_URL}help/3.2-rename.svg`
  },
]

export const TUTORIAL_LINKS = [
  {
    id: 1,
    order:1,
    name: "uploading",
    title: "Uploading a File",
    subTitle: "Learn how to upload a CAD file or 3D model",
    videoLink: "help/uploading-file.mp4",
  },
  {
    id: 11,
    name: "Navigation",
    order:2,
    title: "Scene Navigation",
    subTitle: "Learn how to navigate the scene",
    videoLink: "help/navigation.mp4",
  },
  {
    id: 4,
    name: "grouping",
    order:3,
    title: "Grouping Elements",
    subTitle: "Learn how to group elements on a model",
    videoLink: "help/grouping-parts.mp4",
  },
  {
    id: 3,
    name: "renaming",
    order:4,
    title: "Selecting & Editing",
    subTitle: "Learn how to select and rename elements on a model",
    videoLink: "help/select-rename.mp4",
  },
  {
    id: 5,
    name: "materials",
    order:5,
    title: "Updating Materials",
    subTitle: "Learn how to update/edit materials on a model",
    videoLink: "help/editing-or-adding-materials.mp4",
  },
  {
    id: 7,
    name: "UV Patterns",
    order:6,
    title: "How to edit patterns",
    subTitle: "Learn how to adjust the UV settings for a mesh",
    videoLink: "help/uv-settings.mp4",
  },
  {
    id: 8,
    name: "Lighting",
    order:8,
    title: "Updating the Lighting",
    subTitle: "Learn how to change the lighting in your design scene",
    videoLink: "help/lighting.mp4",
  },
  {
    id: 9,
    name: "Advanced Material Settings",
    order:9,
    title: "Advanced Settings",
    subTitle: "Learn how to customize properties of a material",
    videoLink: "help/mat-settings.mp4",
  },
  {
    id: 6,
    name: "Configurator",
    order:10,
    title: "Creating Configurator",
    subTitle: "Learn how to create a configuration of a model",
    videoLink: "help/creating-configurator.mp4",
  },
  {
    id: 10,
    name: "Material Search",
    order:11,
    title: "Material Image Search",
    subTitle: "Learn how to search for a material by uploading a reference image",
    videoLink: "help/image-search.mp4",
  },
]
export const DEBOUNCE_DELAY = 25
