import ModalComponent from "../modal/modal.component"

const SupportedImageTypesModalComponent = (props) => {

  let extensions = [".jpg", ".png"]
  if (props.extensions) {
    extensions = props.extensions
  }

  const modalContent = (
    <p>Please drop a {extensions.join(" or ")} image</p>
  )
  const actions = (
    <button className="toggle-default-btn" onClick={() => props.close()}>Close</button>
  )
  return (
    <ModalComponent title="Invalid Image"
      close={() => props.close()}
      content={modalContent}
      actions={actions} />
  )
}

export default SupportedImageTypesModalComponent
