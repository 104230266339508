import { createSlice } from "@reduxjs/toolkit"
import Session from "../utils/session.util"

const initialState = {
  user: false,
  access_token: false,
  refresh_token: false,
  isLoggingOut: false,
  status: false,
  planStatus: false,
  isVerified: true,
  quota: {
    numProjects: 0,
    numModels: 0
  },
  role: false,
  external_applications: false
}

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (state, action) => {
      const { user, access_token, refresh_token } = action.payload
      state.user = user
      state.access_token = access_token
      state.refresh_token = refresh_token
      state.isLoggingOut = false
    },
    logout: (state) => {
      state.isLoggingOut = true
      state.user = false
      Session.clear()
    },
    updateUser: (state, action) => {
      state.user = action.payload
    },
    setUserSubscription: (state, action) => {
      const { plan, status, quota, didTrial, role, used_space_in_mb } = action.payload
      state.plan = plan
      state.planStatus = status
      state.didTrial = didTrial
      state.used_space_in_mb = used_space_in_mb
      if (quota) {
        state.quota = quota
      }
      if (role) {
        state.role = role
      }
    },
    modifyQuota: (state, action) => {
      state.quota.numProjects += parseInt(action.payload)
    },
    setIsVerified: (state, action) => {
      state.isVerified = action.payload
    },
    setExternalApplications: (state, action) => {
      state.external_applications = action.payload
    }
  }
})

export const { login, logout, updateUser, setUserSubscription, modifyQuota, setIsVerified, setExternalApplications } = authSlice.actions
export default authSlice.reducer