import UpgradeToProModalComponent from "./upgrade-to-pro-modal.component"

const NotAvailableForFreeModalComponent = (props) => {

  const modalContent = (
    <>
      <p class="content-text">Feature not available with this plan. Upgrade to PRO?</p>
    </>
  )
  return (
    <UpgradeToProModalComponent
      close={() => props.close()}
      modalContent={modalContent}
    />
  )
}

export default NotAvailableForFreeModalComponent