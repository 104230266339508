import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { useLazyAdminGetUserDashboardQuery } from "../../../redux/api.redux.slice"
import { setToast } from "../../../redux/ui.redux.slice"
import SpinnerComponent from "../../../components/spinner/spinner.component"
import { CartesianGrid, Legend, Area, ResponsiveContainer, Tooltip, XAxis, YAxis, ComposedChart, Bar, Line } from "recharts"
import "./admin.dashboard.section.css"

const AdminDashboardSection = (props) => {

  const dispatch = useDispatch()
  const [getAdminUserDashboardRequest] = useLazyAdminGetUserDashboardQuery() 
  const [data, setData] = useState({})
  const [loading, setLoading] = useState(true)

  useEffect(() => {

    const getReportQuery = async () => {
      try {
        const report = await getAdminUserDashboardRequest().unwrap()
        console.log(report)
        setData(report)
        setLoading(false)
      }
      catch (e) {
        console.error(e)
        dispatch(setToast({message: "Uh oh. There was an error generating this report. Please try again", isError: true}))
        setLoading(false)
      }
    }

    getReportQuery()

  }, [dispatch, getAdminUserDashboardRequest])

  const chart = (
    <>
      <div className="toggle-admin-user-report-content-vis-set">
        <div className="toggle-admin-user-report-content-vis">
          <h4>Signups by Month</h4>
          <ResponsiveContainer width="95%" height="95%">
            <ComposedChart
              data={data.signups}
            >
              <defs>
                <linearGradient id="colorPurple" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#571fff" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="#8005ff" stopOpacity={0.4}/>
                </linearGradient>
                <linearGradient id="colorBlue" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#1ac9c4" stopOpacity={0.9}/>
                  <stop offset="95%" stopColor="#0bade3" stopOpacity={0.5}/>
                </linearGradient>
                <linearGradient id="colorPink" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#eb099c" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="#eb095c" stopOpacity={0.4}/>
                </linearGradient>
                <linearGradient id="colorDarkBlue" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#326ba8" stopOpacity={0.8}/>
                  <stop offset="95%" stopColor="#0a30f2" stopOpacity={0.4}/>
                </linearGradient>
                <linearGradient id="colorRed" x1="0" y1="0" x2="0" y2="1">
                  <stop offset="5%" stopColor="#eb1737" stopOpacity={0.9}/>
                  <stop offset="95%" stopColor="#f50273" stopOpacity={0.8}/>
                </linearGradient>
              </defs>
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis dataKey="date_segment" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area type="monotone" dataKey="count" name="# Signups" stroke="#571fff" fillOpacity={1} fill="url(#colorPurple)" dot={{ stroke: '#571fff', strokeWidth: 2 }} />
              <Line type="monotone" dataKey="cumulative" stroke="#02bf7d" name="Cumulative"/>
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
      <div className="toggle-admin-user-report-content-vis-set">
        <div className="toggle-admin-user-report-content-vis">
          <h4>Models Imported</h4>
          <ResponsiveContainer width="95%" height="95%">
            <ComposedChart data={data.imports}>
              <XAxis dataKey="date_segment" />
              <YAxis />
              <Tooltip />
              <Legend />
              <CartesianGrid strokeDasharray="2 2" />
              <Bar dataKey="glb" barSize={20} fill="#413ea0" stackId={1} name="glb" />
              <Bar dataKey="fbx" barSize={20} fill="#d60987" stackId={1} name="fbx" />
              <Bar dataKey="dae" barSize={20} fill="#9209d6" stackId={1} name="dae" />
              <Bar dataKey="obj" barSize={20} fill="#f2b20f" stackId={1} name="obj" />
              <Bar dataKey="zip" barSize={20} fill="#624aff" stackId={1} name="zip" />
              <Bar dataKey="stp" barSize={20} fill="#0ff2ae" stackId={1} name="stp" />
              <Line type="monotone" dataKey="totals" stroke="#571fff" name="Total" />
              <Area type="monotone" dataKey="success_rate" fill="#09eb6b" stroke="#02bf7d" name="% Success"/>
              <Line type="monotone" dataKey="error_rate" stroke="#ff0066" name="% Error" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className="toggle-admin-user-report-content-vis">
          <ul className="toggle-admin-chart-list">
            {data.imports && data.imports.length > 0 && data.imports.map((p) => {
              return (
                <li key={p.date_segment}>
                  <h5>{p.date_segment}</h5>
                  <h6>Type</h6>
                  <ul>
                    <li><i>glb</i> {p.glb}</li>
                    <li><i>fbx</i> {p.fbx}</li>
                    <li><i>dae</i> {p.dae}</li>
                    <li><i>obj</i> {p.obj}</li>
                    <li><i>stp</i> {p.stp}</li>
                    <li><i>zip</i> {p.zip}</li>
                    <li><strong>{p.totals}</strong></li>
                  </ul>
                  <h6>Status</h6>
                  <ul>
                    <li className="success"><span className="icon-check" /> {p.success_rate}%</li>
                    <li className="error"><span className="icon-code" /> {p.error_rate}%</li>
                  </ul>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="toggle-admin-user-report-content-vis-set">
        <div className="toggle-admin-user-report-content-vis">
          <h4>Projects Created</h4>
          <ResponsiveContainer width="95%" height="95%">
            <ComposedChart data={data.projects}>
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis dataKey="date_segment" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area type="monotone" dataKey="3D model" stackId="1" stroke="#0a30f2" fill="url(#colorDarkBlue)" name="3D Model" />
              <Area type="monotone" dataKey="configurator" stackId="1" stroke="#f50273" fill="url(#colorRed)" name="Configurator" />
              <Area type="monotone" dataKey="material" stackId="1" stroke="#571fff" fill="url(#colorPurple)" name="Material" />
              <Area type="monotone" dataKey="variations" stackId="1" stroke="#1ac9c4" fill="url(#colorGreen)" name="Variations" />
              <Line type="monotone" dataKey="totals" stroke="#ff0066" name="Total" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className="toggle-admin-user-report-content-vis">
          <ul className="toggle-admin-chart-list">
            {data.projects && data.projects.length > 0 && data.projects.map((p) => {
              return (
                <li key={p.date_segment}>
                  <h5>{p.date_segment}</h5>
                  <h6>Type</h6>
                  <ul>
                    <li><span className="icon-layers" /> {p["3D model"]}</li>
                    <li><span className="icon-codepen" /> {p.configurator}</li>
                    <li><span className="supp-icon-codesandbox" /> {p.material}</li>
                    <li><span className="supp-icon-category" /> {p.variations}</li>
                    <li><strong>{p.totals}</strong></li>
                  </ul>
                  <h6># Shares</h6>
                  <ul>
                    <li><i>Private</i> {p.private}</li>
                    <li><i>Public</i> {p.public}</li>
                  </ul>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="toggle-admin-user-report-content-vis-set">
        <div className="toggle-admin-user-report-content-vis">
          <h4>Exports Created</h4>
          <ResponsiveContainer width="95%" height="95%">
            <ComposedChart data={data.exports}>
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis dataKey="date_segment" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area type="monotone" dataKey="halfK" stackId="1" stroke="#0a30f2" fill="url(#colorDarkBlue)" name="512px" />
              <Area type="monotone" dataKey="oneK" stackId="1" stroke="#f50273" fill="url(#colorRed)" name="1024px" />
              <Area type="monotone" dataKey="twoK" stackId="1" stroke="#571fff" fill="url(#colorPurple)" name="2048px" />
              <Area type="monotone" dataKey="fourK" stackId="1" stroke="#1ac9c4" fill="url(#colorGreen)" name="4096px" />
              <Line type="monotone" dataKey="totals" stroke="#ff0066" name="Total" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
        <div className="toggle-admin-user-report-content-vis">
          <ul className="toggle-admin-chart-list">
            {data.exports && data.exports.length > 0 && data.exports.map((p) => {
              return (
                <li key={p.date_segment}>
                  <h5>{p.date_segment}</h5>
                  <h6>Resolution</h6>
                  <ul>
                    <li><span className="supp-icon-sd" /> {p.halfK}</li>
                    <li><span className="supp-icon-1k" /> {p.oneK}</li>
                    <li><span className="supp-icon-2k" /> {p.twoK}</li>
                    <li><span className="supp-icon-4k" /> {p.fourK}</li>
                    <li><strong>{p.totals}</strong></li>
                  </ul>
                  <h6>Baked</h6>
                  <ul>
                    <li><i>Yes</i> {p.baked}</li>
                    <li><i>No</i> {p.totals - p.baked}</li>
                  </ul>
                  <h6>Formats</h6>
                  <ul>
                    <li><i>glb</i> {p.glb}</li>
                    <li><i>gltf</i> {p.gltf}</li>
                    <li><i>fbx</i> {p.fbx}</li>
                    <li><i>dae</i> {p.dae}</li>
                    <li><i>obj</i> {p.obj}</li>
                  </ul>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
      <div className="toggle-admin-user-report-content-vis-set">
        <div className="toggle-admin-user-report-content-vis">
          <h4>Materials Created</h4>
          <ResponsiveContainer width="95%" height="95%">
            <ComposedChart data={data.materials}>
              <CartesianGrid strokeDasharray="2 2" />
              <XAxis dataKey="date_segment" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Area type="monotone" dataKey="ct" stackId="1" stroke="#1ac9c4" fill="url(#colorBlue)" name="Color Transferred" />
              <Area type="monotone" dataKey="nonCt" stackId="1" stroke="#eb095c" fill="url(#colorPink)" name="From Scratch" />
              <Line type="monotone" dataKey="totals" stroke="#571fff" name="Total" />
            </ComposedChart>
          </ResponsiveContainer>
        </div>
      </div>
    </>
  )

  const content = loading ? (
    <div className="threedy-lab-spinner-wrapper">
      <SpinnerComponent inline />
    </div>
  ) : chart

  return (
    <div className={loading ? "toggle-admin-user-report threedy-skeleton" : "toggle-admin-user-report"}>
      <h4 className="margin-top">Dashboard</h4>
      <div className="toggle-admin-user-report-content">
        <div className="toggle-admin-user-report-content-vis-container">
          {content}
        </div>
      </div>
    </div>
  )
}

export default AdminDashboardSection