import { useCallback, useEffect, useRef, useState } from "react"
import DropdownComponent from "../dropdown/dropdown.component"
import ModalComponent from "../modal/modal.component"
import { v4 as uuidv4 } from 'uuid'
import moment from "moment"
import { EVENTS } from "../../constants"

const AddVariationModalComponent = (props) => {
  const { onClose, project, onRequestVariation } = props
  const [selectedAritizeOption, setSelectedAritizeOption] = useState({value: false, label: ""})
  const [availableAritizeOptions, setAvailableAritizeOptions] = useState([])
  const [variationName, setVariationName] = useState("")
  const isAritizeVariation = useRef()

  // Populate options
  useEffect(() => {
    if (project.external_meta && project.external_meta.type === 'aritize3D' && project.external_meta.model_option_id && project.external_related_jobs && project.external_related_jobs.length > 0) {
      const parentOption = {id: project.external_meta.model_id, option_id: project.external_meta.model_option_id, label: project.external_meta.model_option_id}
      const options = [parentOption].concat(project.external_related_jobs.map((j) => {
        return {id: j.job_id, option_id: j.option_id, label: j.option_id}
      }))

      setAvailableAritizeOptions(options)
      isAritizeVariation.current = true
    }
    else {
      isAritizeVariation.current = false
    }
  }, [project])

  const handleToggleKeys = (enabled) => {
    document.dispatchEvent(new CustomEvent(EVENTS.TOGGLE_KEY_HANDLER, {detail: {enabled}}))
  }

  const handleUpdateOption = useCallback((e, dropdownId, valueId) => {
    const selectedOption = availableAritizeOptions.find((o) => o.id === valueId)
    if (selectedOption) {
      setSelectedAritizeOption({value: valueId, label: selectedOption.label})
    }
  }, [availableAritizeOptions])

  const getContent = useCallback(() => {

    let aritizeContent

    if (isAritizeVariation.current) {
      aritizeContent = (
        <div className="threedy-lab-text-input">
          <h4>Select a <img src="/images/aritize-logo.svg" alt="ARitize3D" /> ARitize3D option to map to</h4>
          <DropdownComponent label="Option" id="option" placeholder="Option" value={selectedAritizeOption.value} valueLabel={selectedAritizeOption.label} onChange={handleUpdateOption} options={availableAritizeOptions}></DropdownComponent>
        </div>
      )
    }

    const content = (
      <div className="threedy-lab-text-input">
        <h4>Name your variation</h4>
        <input type="text" placeholder="Name..." autoFocus onFocus={() => handleToggleKeys(false)} onBlur={() => handleToggleKeys(true)} value={variationName} onChange={(e) => setVariationName(e.target.value)} />
      </div>
    )

    return (
      <div className="toggle-add-variation-content">
        {content}
        {aritizeContent}
      </div>
    )

  }, [selectedAritizeOption, availableAritizeOptions, handleUpdateOption, variationName])

  const handleSave = () => {
    const params = {
      id: uuidv4(),
      name: variationName,
      isAritizeVariation: isAritizeVariation.current,
      created_ts: moment().valueOf()
    }

    if (params.isAritizeVariation) {
      const theOption = availableAritizeOptions.find((o) => o.id === selectedAritizeOption.value)
      if (theOption) {
        const { id, option_id } = theOption
        params.aritizeParams = {id, option_id}
      }
    }

    onRequestVariation(params)
  }

  const actions = (
    <>
      <button className="toggle-default-btn" onClick={onClose}>Cancel</button>
      <button className="toggle-primary-btn" disabled={!variationName || (isAritizeVariation.current && !selectedAritizeOption.value)} onClick={handleSave}>Save</button>
    </>
  )

  return (
    <ModalComponent title="Add New Variation" content={getContent()} actions={actions} close={onClose} />
  )
}

export default AddVariationModalComponent