import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import { API_BASE_URL } from "../constants"
import Session from "../utils/session.util"
import { login } from "./auth.redux.slice"
import jwtDecode from "jwt-decode"

const baseQuery = fetchBaseQuery({ 
  baseUrl: API_BASE_URL,
  prepareHeaders: async (headers, { getState }) => {
    const access_token = await getState().auth.access_token
    headers.set('Authorization', `Bearer ${access_token}`)
    return headers
  }
})

const apiReduxSlice = createApi({
  reducerPath: 'api',
  baseQuery: async (args, api, extraOptions) => {
    let result = await baseQuery(args, api, extraOptions)
    if (result.error && result.error.originalStatus === 401) {
      console.log('401 ERROR CAUGHT, REFRESHING TOKEN')
      try {
        // try to get a new token
        const refreshTokenRes = await Session.getAccessTokenFromRefreshToken()
        const { access_token, refresh_token, id_token } = refreshTokenRes
        const user = jwtDecode(id_token)
        api.dispatch(login({user, access_token, refresh_token}))

        // retry the initial query
        result = await baseQuery(args, api, extraOptions)        
      } catch (e) {
        console.log("Error getting updated access_token: ", e)
        Session.forceLogin()
      }
    }
    return result
  },
  tagTypes: ['project', 'model', 'materialCategory', 'material', 'collectionItem', 'user', 'folder', 'quota'],
  endpoints: builder => ({
    getProjects: builder.query({
      query: () => '/projects',
      providesTags: (result) => {
        return [
          'project',
          ...result.map((p) => ({ type: 'project', id: p.id }))
        ]
      },
      transformResponse: responseData => {
        return responseData.data.projects
      },
    }),
    getFolders: builder.query({
      query: () => '/folders',
      providesTags: (result) => {
        return [
          'folder',
          ...result.map((p) => ({ type: 'folder', id: p.id }))
        ]
      },
      transformResponse: responseData => {
        return responseData.data.folders
      },
    }),
    getRecentProjects: builder.query({
      query: () => '/projects?sort=updated_ts&dir=desc&limit=8&status=created',
      providesTags: (result) => {
        return [
          'project',
          ...result.map((p) => ({ type: 'project', id: p.id }))
        ]
      },
      transformResponse: responseData => {
        return responseData.data.projects
      },
    }),
    getProjectById: builder.query({
      query: id => `/projects/${id}`,
      transformResponse: responseData => {
        return responseData.data.project
      },
    }),
    getProjectCountByModel: builder.query({
      query: id => `/models/${id}/project-count`,
      transformResponse: responseData => {
        return responseData.data.project_count
      },
    }),
    getProjectOrModelCountByMaterial: builder.query({
      query: id => `/collections/${id}/project-model-count`,
      transformResponse: responseData => {
        return responseData.data.attachments
      },
    }),
    getFolderById: builder.query({
      query: id => `/folders/${id}`,
      providesTags: (result) => {
        return [
          'project',
          ...result.map((p) => ({ type: 'project', id: p.id }))
        ]
      },
      transformResponse: responseData => {
        return responseData.data.contents
      },
    }),
    getProjectByPublicLink: builder.query({
      query: publicLink => `/shared/${publicLink}`,
      transformResponse: responseData => {
        return responseData.data.project
      },
    }),
    getProjectForViewing: builder.query({
      query: viewId => `/view/${viewId}/latest`,
      transformResponse: responseData => {
        return responseData.data.project
      },
    }),
    getProjectS3UrlForAndroid: builder.query({
      query: id => `/view/${id}/generated-s3-url-for-android`,
      transformResponse: responseData => {
        return responseData.data.url
      },
    }),
    uploadGeneratedBlobFile: builder.mutation({
      query: params => ({
        url: `/models/${params.modelId}/upload_blob_generated`,
        method: 'POST',
        body: params.body,
      }),
      transformResponse: responseData => {
        return responseData.data.url
      }
    }),
    getProjectByAritizeJobId: builder.query({
      query: jobId => `/external/aritize3D/project/${jobId}`,
      transformResponse: responseData => {
        return responseData.data
      },
    }),
    addNewProject: builder.mutation({
      query: params => ({
        url: '/projects',
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['project', 'quota']
    }),
    addNewFolder: builder.mutation({
      query: params => ({
        url: '/folders',
        method: 'POST',
        body: params
      }),
      transformResponse: responseData => {
        return responseData.data.folder
      },
      invalidatesTags: ['folder']
    }),
    addProjectDecal: builder.mutation({
      query: params => ({
        url: `/projects/${params.projectId}/decal`,
        method: 'POST',
        body: params.body
      }),
      transformResponse: responseData => {
        return responseData.data
      },
      invalidatesTags: (result, error, params) => [{ type: 'project', id: params.projectId }]
    }),
    editProject: builder.mutation({
      query: params => ({
        url: `/projects/${params.projectId}`,
        method: 'PATCH',
        body: params.body
      }),
      invalidatesTags: (result, error, params) => [{ type: 'project', id: params.projectId }]
    }),
    editProjectReferencePhoto: builder.mutation({
      query: params => ({
        url: `/projects/${params.projectId}/reference-photo`,
        method: 'PATCH',
        body: params.body
      }),
      invalidatesTags: (result, error, params) => [{ type: 'project', id: params.projectId }]
    }),
    editProjectReferencePresignedPhotos: builder.mutation({
      query: params => ({
        url: `/projects/${params.projectId}/presigned-reference-photos`,
        method: 'PATCH',
        body: params.body
      }),
      invalidatesTags: (result, error, params) => [{ type: 'project', id: params.projectId }]
    }),
    editModel: builder.mutation({
      query: params => ({
        url: `/models/${params.modelId}`,
        method: 'PATCH',
        body: params.body
      }),
      invalidatesTags: (result, error, params) => [{ type: 'model', id: params.modelId }]
    }),
    editFolder: builder.mutation({
      query: params => ({
        url: `/folders/${params.folderId}`,
        method: 'PATCH',
        body: params.body
      }),
      invalidatesTags: (result, error, params) => [{ type: 'folder', id: params.folderId }]
    }),
    addToFolder: builder.mutation({
      query: params => ({
        url: `/folders/${params.folderId}`,
        method: 'POST',
        body: params.body
      }),
      invalidatesTags: (result, error, params) => [{ type: 'folder', id: params.folderId }, {type: 'project', id: params.body.projectId}]
    }),
    editProjectImage: builder.mutation({
      query: params => ({
        url: `/projects/${params.projectId}/image`,
        method: 'PATCH',
        body: params.body
      }),
      invalidatesTags: (result, error, params) => [{ type: 'project', id: params.projectId }]
    }),
    publishProject: builder.mutation({
      query: id => ({
        url: `/projects/${id}/publish`,
        method: 'POST'
      }),
      invalidatesTags: (result, error, id) => [{ type: 'project', id: id }],
      transformResponse: responseData => {
        return responseData.data
      }
    }),
    deleteProject: builder.mutation({
      query: id => ({
        url: `/projects/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, id) => [{ type: 'project', id: id }]
    }),
    deleteFolder: builder.mutation({
      query: id => ({
        url: `/folders/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, id) => [{ type: 'folder', id: id }, { type: 'project'}]
    }),
    deleteModel: builder.mutation({
      query: id => ({
        url: `/models/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, id) => [{ type: 'model', id: id }]
    }),
    getModels: builder.query({
      query: () => '/models',
      providesTags: (result) => {
        return [
          'model',
          ...result.map((m) => ({ type: 'model', id: m.id }))
        ]
      },
      transformResponse: responseData => {
        return responseData.data.models
      },
    }),
    getSampleModels: builder.query({
      query: () => '/models?samples=all',
      transformResponse: responseData => {
        return responseData.data.models
      },
    }),
    getMaterialsForModel: builder.query({
      query: (id) => `/collections/model/${id}`,
      providesTags: (result) => {
        return [
          'model',
          ...result.map((m) => ({ type: 'collectionItem', id: m.id }))
        ]
      },
      transformResponse: responseData => {
        return responseData.data.materials
      },
    }),
    getRecentModels: builder.query({
      query: () => '/models?sort=created_ts&dir=desc&limit=4',
      providesTags: (result) => {
        return [
          'model',
          ...result.map((m) => ({ type: 'model', id: m.id }))
        ]
      },
      transformResponse: responseData => {
        return responseData.data.models
      },
    }),
    addNewModel: builder.mutation({
      query: params => ({
        url: '/models',
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['model']
    }),
    addToPersonalCollections: builder.mutation({
      query: params => ({
        url: `/collections`,
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['collectionItem']
    }),
    updatePersonalCollectionItem: builder.mutation({
      query: params => ({
        url: `/collections/${params.id}`,
        method: 'POST',
        body: params.body
      }),
      invalidatesTags: ['collectionItem']
    }),
    makePersonalCollectionItemsVisible: builder.mutation({
      query: params => ({
        url: `/collections/visible`,
        method: 'POST',
        body: params.body
      }),
      invalidatesTags: ['collectionItem']
    }),
    deletePersonalCollectionItem: builder.mutation({
      query: params => ({
        url: `/collections/${params.materialId}`,
        method: 'DELETE'
      }),
      invalidatesTags: (result, error, params) => [{ type: 'collectionItem', id: params.materialId }]
    }),
    colorTransferExistingMaterial: builder.mutation({
      query: params => ({
        url: `/collections/transfer`,
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['collectionItem'],
      transformResponse: responseData => {
        return responseData.data
      }
    }),
    expandMaterial: builder.mutation({
      query: params => ({
        url: `/collections/expand/${params.projectId}`,
        method: 'POST',
        body: params.body
      }),
      transformResponse: responseData => {
        return responseData.data.expandedUrl
      }
    }),
    getPersonalCollections: builder.query({
      query: () => '/collections',
      providesTags: (result) => {
        return [
          'collectionItem',
          ...result.map((c) => ({ type: 'collectionItem', id: c.id }))
        ]
      },
      transformResponse: responseData => {
        return responseData.data.materials
      },
    }),
    getUserInfo: builder.query({
      query: () => '/user',
      transformResponse: responseData => {
        return responseData.data.user
      },
      providesTags: ['quota']
    }),
    getUserInvoice: builder.query({
      query: () => ({
        url: '/user/invoice',
        method: 'POST'
      }),
      keepUnusedDataFor: 1,
      transformResponse: responseData => {
        return responseData.data.invoice
      },
    }),
    loginUser: builder.mutation({
      query: (params) => ({
        url: '/user/login',
        method: 'POST',
        body: params
      }),
      transformResponse: responseData => {
        return responseData.data
      },
    }),
    loginSocialUser: builder.mutation({
      query: (params) => ({
        url: '/user/login/social',
        method: 'POST',
        body: params
      }),
      transformResponse: responseData => {
        return responseData.data
      },
    }),
    userForgotPassword: builder.mutation({
      query: (params) => ({
        url: '/user/forgot',
        method: 'POST',
        body: params
      }),
      transformResponse: responseData => {
        return responseData.data
      },
    }),
    userValidateForgotPassword: builder.mutation({
      query: (params) => ({
        url: '/user/validate',
        method: 'POST',
        body: params
      }),
      transformResponse: responseData => {
        return responseData.data
      },
    }),
    createUserPaymentIntent: builder.query({
      query: params => ({
        url: '/user/pay',
        method: 'POST',
        body: params
      }),
      transformResponse: responseData => {
        return responseData.data
      }
    }),
    upgradeUser: builder.query({
      query: () => ({
        url: '/user/upgrade',
        method: 'POST'
      }),
    }),
    getSearchUserLibrary: builder.query({
      query: params => ({
        url: `/user/searchLibrary`,
        method: 'GET',
      }),
      transformResponse: responseData => {
        return responseData.data
      },
    }),
    downgradeUser: builder.query({
      query: () => ({
        url: '/user/downgrade',
        method: 'POST'
      })
    }),
    createUser: builder.mutation({
      query: params => ({
        url: '/user',
        method: 'POST',
        body: params
      })
    }),
    createSocialUser: builder.mutation({
      query: params => ({
        url: `/user/social`,
        method: 'POST',
        body: params
      })
    }),
    verifyUser: builder.mutation({
      query: params => ({
        url: '/user/verify',
        method: 'POST',
        body: params
      })
    }),
    refreshCodeForUser: builder.mutation({
      query: params => ({
        url: '/user/verify/refresh',
        method: 'POST',
        body: params
      })
    }),
    editUser: builder.mutation({
      query: params => ({
        url: '/user/profile',
        method: 'PATCH',
        body: params.body
      })
    }),
    updatePassword: builder.mutation({
      query: params => ({
        url: '/user/updatePassword',
        method: 'POST',
        body: params.body
      })
    }),
    verifyOldPassword: builder.query({
      query: params => ({
        url: '/user/verifyOldPassword',
        method: 'POST',
        body: params.body
      }),
      transformResponse: responseData => {
        return responseData.data
      },
    }),
    getMaterials: builder.query({
      query: () => '/materials',
      providesTags: (result) => {
        return [
          'material',
          ...result.map((c) => c.materials.map((m) => ({ type: 'material', id: m.id })))
        ]
      },
      transformResponse: responseData => {
        return responseData.data.categories
      },
    }),
    getMaterialCategories: builder.query({
      query: () => '/materials/categories',
      providesTags: (result) => {
        return [
          'materialCategory',
          ...result.map((m) => ({ type: 'materialCategory', id: m.category }))
        ]
      },
      transformResponse: responseData => {
        return responseData.data.categories
      },
    }),
    getMaterialsForCategory: builder.query({
      query: params => `/materials/categories/${params.category}`,
      providesTags: (result) => {
        return [
          'material',
          ...result.map((m) => ({ type: 'material', id: m.id }))
        ]
      },
      transformResponse: responseData => {
        return responseData.data.materials
      },
    }),
    getMaterialsForCategoryWithTags: builder.query({
      query: params => `/materials/categories/${params.category}/${params.tags}/${params.sort}`,
      providesTags: (result) => {
        return [
          'material',
          ...result.map((m) => ({ type: 'material', id: m.id }))
        ]
      },
      transformResponse: responseData => {
        return responseData.data.materials
      },
    }),
    getMaterialById: builder.query({
      query: id => `/materials/${id}`,
      transformResponse: responseData => {
        return responseData.data
      },
    }),
    getPublicMaterialById: builder.query({
      query: id => `/public/materials/${id}`,
      transformResponse: responseData => {
        return responseData.data
      },
    }),
    getMaterialModelsById: builder.query({
      query: id => `/materials/${id}/models`,
      transformResponse: responseData => {
        return responseData.data
      },
    }),
    getPersonalMaterialById: builder.query({
      query: id => `/collections/${id}`,
      transformResponse: responseData => {
        return responseData.data
      },
    }),
    getMaterialMapsAtResolutionById: builder.query({
      query: params => `/materials/${params.id}/maps/${params.resolution}`,
      transformResponse: responseData => {
        return responseData.data.map_urls
      },
    }),
    getPersonalMaterialMapsAtResolutionById: builder.query({
      query: params => `/collections/${params.id}/maps/${params.resolution}`,
      transformResponse: responseData => {
        return responseData.data.map_urls
      },
    }),
    getPublicPersonalMaterialById: builder.query({
      query: id => `/public/collections/${id}`,
      transformResponse: responseData => {
        return responseData.data
      },
    }),
    getPersonalMaterialByModel: builder.query({
      query: params => ({
        url: `/collections/model/${params.id}`,
        method: 'POST',
        body: params.body
      }),
      transformResponse: responseData => {
        return responseData.data
      },
    }),
    adminGetUsers: builder.query({
      query: () => ({
        url: '/admin/users'
      }),
      transformResponse: responseData => {
        return responseData.data.users
      },
      providesTags: (result) => {
        return [
          'user',
          ...result.map((u) => ({ type: 'user', id: u.user_id }))
        ]
      },
    }),
    adminGetUserById: builder.query({
      query: (uid) => ({
        url: `/admin/users/${uid}`
      }),
      transformResponse: responseData => {
        return responseData.data.user
      }
    }),
    adminGenerateUserReport: builder.mutation({
      query: params => ({
        url: '/admin/users/report',
        method: 'POST',
        body: params
      }),
    }),
    adminClearMaterialCache: builder.mutation({
      query: () => ({
        url: '/admin/cache',
        method: 'DELETE'
      }),
    }),
    adminGetUserDashboard: builder.query({
      query: () => ({
        url: '/admin/users/dashboard'
      }),
      transformResponse: responseData => {
        return responseData.data.report
      }
    }),
    adminCreateNewUser: builder.mutation({
      query: params => ({
        url: '/admin/users',
        method: 'POST',
        body: params
      }),
      transformResponse: responseData => {
        return responseData.data
      },
      invalidatesTags: ['user']
    }),
    adminSearchUserByEmail: builder.mutation({
      query: params => ({
        url: '/admin/users/search',
        method: 'POST',
        body: params
      }),
      transformResponse: responseData => {
        return responseData.data.user
      }
    }),
    attachExternalApplication: builder.mutation({
      query: params => ({
        url: '/user/external',
        method: 'POST',
        body: params
      }),
      invalidatesTags: ['user']
    }),
    revokeExternalApplication: builder.mutation({
      query: params => ({
        url: '/user/external',
        method: 'DELETE',
        body: params
      }),
      invalidatesTags: ['user']
    }),
    getAritize3DModels: builder.query({
      query: () => `/external/aritize3D`,
      transformResponse: responseData => {
        return responseData.data.models
      },
    }),
    getAritize3DModel: builder.query({
      query: id => `/external/aritize3D/${id}`,
      transformResponse: responseData => {
        return responseData.data
      },
    }),
    syncAritize3DModel: builder.mutation({
      query: params => ({
        url: `/external/aritize3D/project/${params.id}/sync`,
        method: 'PATCH',
        body: params.body
      })
    }),
    getAvailableIntegrations: builder.query({
      query: () => `/external/integrations`,
      transformResponse: responseData => {
        return responseData.data.integrations
      },
    }),
    getSampleProjectID: builder.query({
      query: () => '/projects/sample-project-id',
      transformResponse: responseData => {
        return responseData.data
      },
    }),
    getExportsList: builder.query({
      query: () => '/exports',
      transformResponse: responseData => {
        return responseData.data.exports
      },
    }),
    retryExport: builder.mutation({
      query: exportId => ({
        url: `/exports/${exportId}/retry`,
        method: 'POST'
      })
    })
  })
})

export const { useGetProjectsQuery, useGetProjectByIdQuery, useLazyGetProjectS3UrlForAndroidQuery, useLazyGetProjectOrModelCountByMaterialQuery, useLazyGetProjectCountByModelQuery, useGetRecentProjectsQuery, useLazyGetProjectByIdQuery, useGetProjectByPublicLinkQuery, useAddNewProjectMutation, 
  useEditProjectMutation, useEditProjectImageMutation, useUploadGeneratedBlobFileMutation, usePublishProjectMutation, useLazyGetFoldersQuery, useLazyGetProjectsQuery,
  useDeleteProjectMutation, useDeleteModelMutation, useLazyGetModelsQuery, useGetModelsQuery, useGetRecentModelsQuery, useCreateUserMutation, useCreateSocialUserMutation,
  useAddNewModelMutation, useEditUserMutation, useGetUserInfoQuery, useLazyGetUserInfoQuery, useLazyGetMaterialCategoriesQuery, 
  useLazyGetPersonalCollectionsQuery, useLazyGetUserInvoiceQuery, useVerifyUserMutation, useRefreshCodeForUserMutation,
  useAddToPersonalCollectionsMutation, useGetPersonalCollectionsQuery, useLazyCreateUserPaymentIntentQuery, useLazyUpgradeUserQuery, useLazyDowngradeUserQuery,
  useColorTransferExistingMaterialMutation, useLazyGetPersonalMaterialByModelQuery, useLazyGetMaterialsQuery, useLoginUserMutation, useLoginSocialUserMutation,
  useAdminGetUsersQuery, useAdminCreateNewUserMutation, useUpdatePersonalCollectionItemMutation, useDeletePersonalCollectionItemMutation,
  useLazyGetPublicMaterialByIdQuery, useLazyGetPersonalMaterialMapsAtResolutionByIdQuery, useLazyGetMaterialMapsAtResolutionByIdQuery,
  useExpandMaterialMutation, useLazyGetMaterialsForModelQuery,
  useAddNewFolderMutation, useEditFolderMutation, useDeleteFolderMutation, useAddToFolderMutation, useLazyGetFolderByIdQuery,
  useUserForgotPasswordMutation, useUserValidateForgotPasswordMutation, useUpdatePasswordMutation,
  useMakePersonalCollectionItemsVisibleMutation, useEditModelMutation, useEditProjectReferencePhotoMutation, useAddProjectDecalMutation,
  useLazyGetMaterialModelsByIdQuery, useGetProjectForViewingQuery, useLazyGetProjectByPublicLinkQuery, useLazyGetProjectForViewingQuery, useLazyGetPublicPersonalMaterialByIdQuery,
  useLazyGetMaterialsForCategoryQuery, useLazyGetSearchUserLibraryQuery, useLazyGetMaterialsForCategoryWithTagsQuery, useLazyGetMaterialByIdQuery, useLazyGetPersonalMaterialByIdQuery, useLazyVerifyOldPasswordQuery,
  useLazyGetSampleModelsQuery, useLazyGetProjectByAritizeJobIdQuery, useAttachExternalApplicationMutation, useRevokeExternalApplicationMutation, useLazyGetAritize3DModelsQuery, useLazyGetAritize3DModelQuery,
  useLazyGetAvailableIntegrationsQuery, useLazyAdminGetUserByIdQuery, useRetryExportMutation,
  useAdminSearchUserByEmailMutation, useLazyGetSampleProjectIDQuery, useEditProjectReferencePresignedPhotosMutation, useGetExportsListQuery, useLazyGetExportsListQuery,
  useLazyAdminGetUserDashboardQuery, useAdminGenerateUserReportMutation, useAdminClearMaterialCacheMutation,
  useSyncAritize3DModelMutation

} = apiReduxSlice
export default apiReduxSlice