import { useDispatch, useSelector } from "react-redux"
import moment from "moment"
import { useCallback, useEffect, useState } from "react"
import { useEditUserMutation } from "../../../redux/api.redux.slice"
import Session from "../../../utils/session.util"
import { login } from "../../../redux/auth.redux.slice"
import { setToast } from "../../../redux/ui.redux.slice"
import PreferencesSettingsSection from "./preferences.settings.section"
import UsageSettingsSection from "./usage.settings.section"
import DropdownComponent from "../../../components/dropdown/dropdown.component"
import DropdownLinksComponent from "../../../components/dropdown-links/dropdown-links.component"

const ProfileSettingsSection = () => {

  const dispatch = useDispatch()
  const {role, user} = useSelector((state) => state.auth)
  const [workingUser, setWorkingUser] = useState({})
  const [didChange, setDidChange] = useState(false)
  const [updateUserRequest, {isLoading}] = useEditUserMutation()
  const [tileSize, setTileSize] = useState("Medium")

  const options = ["Small", "Medium", 'Large']
  const materialTileOptions = options.map((x) => {
    return { id: x, label: <span className="ellipsis-container">{x}</span>, onSelect: () => handleUpdateOption(x) }
  })

  const getUserDetail = useCallback((key) => {
    return workingUser && workingUser['https://thdy/user_md'] && workingUser['https://thdy/user_md'][key]
  }, [workingUser])

  useEffect(() => {
    const tileSize = getUserDetail('tileSize');
    if (tileSize) {
      setTileSize(user['https://thdy/user_md'].tileSize)
    }
  }, [getUserDetail, user])

  useEffect(() => {
    if (user) {
      setWorkingUser(user)
    }
  }, [user])


  const handleTextChange = (e) => {
    const {id, value} = e.target
    setWorkingUser({...workingUser, [id]: value})
    setDidChange(true)
  }

  const handleMetaTextChange = (e) => {
    const {id, value} = e.target
    setWorkingUser({...workingUser, 'https://thdy/user_md': {...workingUser['https://thdy/user_md'], [id]: value}})
    setDidChange(true)
  }

  const handleUpdateUser = async () => {

    try {
      const {name} = workingUser
      const params = {name, user_metadata: workingUser['https://thdy/user_md']}
      const res = await updateUserRequest({body: params}).unwrap()
      const updateRes = {...res.data.update}

      if ('user_metadata' in updateRes) {
        updateRes['https://thdy/user_md'] = res.data.update.user_metadata
      }

      setWorkingUser(updateRes)
      setDidChange(false)

      Session.getAccessTokenFromRefreshToken().then((res) => {
        const { access_token, user } = res
        dispatch(login({user, access_token}))
      }, (e) => {
        console.log('Error updating session: ', e)
      })
    } 
    catch (e) {
      console.log("Error updating user: ", e)
      dispatch(setToast({message: "Uh oh. We had an issue updating your profile, please try again.", isError: true}))
    }
  }

  const handleUpdateOption = async (valueId) => {
    setTileSize(valueId)

    // Update prefs
    try {
      const newMeta = { ...user['https://thdy/user_md'], tileSize: valueId }
      const params = { user_metadata: newMeta }
      await updateUserRequest({ body: params }).unwrap()

      Session.getAccessTokenFromRefreshToken().then((res) => {
        const { access_token, user } = res
        dispatch(login({ user, access_token }))
      }, (e) => {
        console.log('Error updating session: ', e)
      })
    }
    catch (e) {
      console.log("Error updating user: ", e)
      dispatch(setToast({ message: "Uh oh. We had an issue updating your preferences, please try again.", isError: true }))
    }
  }
  const materialTileDropdownBtn = (
    <button className="toggle-default-btn rectangular-btn">{tileSize}&nbsp;<span className="icon-expand_more"></span></button>
  )
  return (
    <div className={isLoading ? "threedy-skeleton" : "toggle-in-page-container"}>
      <div className="settings-wrapper">
        <div className="left-container flex-basis-30">
          {role && role === "user" && (
            <>
              <div className="settings-card">
                <div className="each-setting">
                  <span className="heading">Usage</span>
                  <UsageSettingsSection />
                </div>
              </div>
            </>
          )}
          <div className="settings-card hidden">
            <div className="each-setting">
              <span className="heading">Product Settings</span>
              <span className="sub-heading"><PreferencesSettingsSection /></span>
            </div>
            <div className="each-setting">
              <span className="heading">Material Tile Size</span>
              <span className="sub-heading">
                <DropdownLinksComponent element={materialTileDropdownBtn} items={materialTileOptions} position="center" />
              </span>
            </div>
          </div>
        </div>
        <div className="right-container flex-basis-70">
          <div className="settings-card inline">
            <div className="each-setting inline">
              <span className="heading">Email: </span>
              <span className="sub-heading">{workingUser?.email}</span>
            </div>
          </div>
          <div className="settings-card">
            <div className="each-setting">
              <span className="heading">Profile Information</span>
              <div className="form-input">
                <span className="sub-heading">Full Name</span>
                <input
                  id="name"
                  value={workingUser?.name}
                  placeholder="Full name..."
                  onChange={handleTextChange} />
              </div>
              <div className="form-input">
                <span className="sub-heading">Position</span>
                <input
                  id="position"
                  value={getUserDetail("position") ?? ""}
                  placeholder="i.e Product Designer..."
                  onChange={handleMetaTextChange} />
              </div>
              <div className="form-input">
                <span className="sub-heading">Company Name</span>
                <input
                  id="company_name"
                  value={getUserDetail("company_name") ?? ""}
                  placeholder="i.e Microsoft"
                  onChange={handleMetaTextChange} />
              </div>
              <div className="form-input">
                <span className="sub-heading last-updated-text">Last updated {moment(user.updated_at).from(moment().add(10, 'seconds'))}</span>
                <button className="toggle-primary-btn save-info-btn" disabled={!didChange} onClick={handleUpdateUser}>
                  Update Information
                </button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>
  );
}

export default ProfileSettingsSection